import WizardHeader from '@components/header/WizardHeader';
import { DreamerlyUser } from '@src/providers/AuthProvider';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

function Completed({ user }: { user: DreamerlyUser }) {
  // Header loading states
  const [isExitLoading, setIsExitLoading] = useState(false);

  const router = useRouter();

  const onExitClick = () => {
    setIsExitLoading(true);
    // Change loading state before changing stage to avoid unmounted setState
    setIsExitLoading(false);
    router.push('/');
  };

  return (
    <div>
      <WizardHeader
        isHideExitButton={true}
        handleOnExitClick={onExitClick}
        isExitLoading={isExitLoading}
      />
      <div className="flex w-full justify-center pb-24">
        <div className="flex-col w-152">
          <div className="mt-16 mb-4">
            <span className="text-l font-medium">Chúc mừng bạn ❤️</span>
          </div>
          <h1 className="text-8xl mb-6 font-semibold">Bắt đầu mở commission ngay</h1>
          <p className="text-l-tall">
            Bạn đã chính thức trở thành hoạ sĩ trong cộng đồng Dreamerly. Bây giờ bạn có thể vào Chợ
            tìm commission để tìm khách hàng, hoặc xem Trang cá nhân của mình.
          </p>
          <div className="flex flex-row mt-12">
            <Link href="/jobs" passHref>
              <a
                href="replace"
                className="flex items-center bg-primary-p4 py-2 px-4 rounded-full mr-4"
              >
                <span className="text-white text-m font-medium">Chợ commission</span>
              </a>
            </Link>
            <Link href={`/users/${user.username}`} passHref>
              <a href="replace" className="flex items-center py-2 px-2">
                <span className="text-m font-semibold underline">Xem trang cá nhân</span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Completed;
