import dayjs from 'dayjs';

const SystemLocale = dayjs.locale();
export const VietnameseLocale = 'vi-vn';

/**
 * Helper function to add CURRENT time to an input date string (format YYYY-MM-DD)
 * in order to form a new Date object.
 *
 * @param date input date string (format YYYY-MM-DD)
 * @returns ISO date string
 */
export const convertInputDateStringToDateTimeWithCurrentTime = (date: string) => {
  const currentDate = new Date();
  const [year, month, day] = date.split('-');
  currentDate.setFullYear(Number(year));
  currentDate.setMonth(Number(month) - 1);
  currentDate.setDate(Number(day));
  return currentDate;
};

/**
 * Helper function to add current time to an input date string (format YYYY-MM-DD)
 * in order to form ISO date string.
 * @param date input date string (format YYYY-MM-DD)
 * @returns ISO date string
 */
export const convertInputDateStringToISOStringWithCurrentTime = (date: string) => {
  const dateWithCurrentTime = convertInputDateStringToDateTimeWithCurrentTime(date);
  return dateWithCurrentTime.toISOString();
};

export const getAge = (birthDateStr: string) => {
  const birthRemoveZ = birthDateStr.replace('Z', '');
  const today = new Date();
  const birthDate = new Date(birthRemoveZ);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

/**
 * Helper functiont to convert ISO date string to input date string (format YYYY-MM-DD in local time)
 */
export const convertISOStringToInputDateString = (isoDateString: string) => {
  const localeDateString = dayjs(isoDateString).locale(SystemLocale).format('YYYY-MM-DD');
  return localeDateString;
};

export const convertISOStringToReadableDateString = (
  isoDateString: string | undefined | null,
  format = 'DD MMMM, YYYY',
  locale: string = SystemLocale,
) => {
  if (!isoDateString) {
    return '';
  }
  return dayjs(isoDateString).locale(locale).format(format);
};
