import { CreatePriceInput, Price, UpdatePriceInput } from '@src/API';
import { PriceStatus } from '@src/customGraphql/customModels';
import { ListData } from '@src/libs/models';
import { IPriceRepo, RealPriceRepo } from '@src/repositories/priceRepo';
import { CurrencyCode } from '@src/utils/enums';
import captureException from './loggerService';

export type CreatePriceServiceInput = {
  id?: string;
  productId: string;
  displayName: string;
  description?: string;
  type: 'CORE' | 'EXTRA';
  category: 'FIXED' | 'RANGE' | 'PERCENTAGE';
  fixedAmount?: string;
  fixedCentiAmount?: string;
  minAmount?: string;
  minCentiAmount?: string;
  maxAmount?: string;
  maxCentiAmount?: string;
  percentage?: number;
  index?: number;
  currencyCode?: string;
};

export type UpdatePriceServiceInput = {
  id: string;
  productId?: string;
  displayName?: string;
  description?: string;
  type?: 'CORE' | 'EXTRA';
  category?: 'FIXED' | 'RANGE' | 'PERCENTAGE';
  fixedAmount?: string;
  fixedCentiAmount?: string;
  minAmount?: string;
  minCentiAmount?: string;
  maxAmount?: string;
  maxCentiAmount?: string;
  percentage?: number;
  index?: number;
  currencyCode?: string;
};
export interface IPriceService {
  createPrice({
    id,
    productId,
    displayName,
    description,
    type,
    category,
    fixedAmount,
    fixedCentiAmount,
    minAmount,
    minCentiAmount,
    maxAmount,
    maxCentiAmount,
    percentage,
    index,
    currencyCode,
  }: {
    id?: string;
    productId: string;
    displayName: string;
    description?: string;
    type: 'CORE' | 'EXTRA';
    category: 'FIXED' | 'RANGE' | 'PERCENTAGE';
    fixedAmount?: string;
    fixedCentiAmount?: string;
    minAmount?: string;
    minCentiAmount?: string;
    maxAmount?: string;
    maxCentiAmount?: string;
    percentage?: number;
    index?: number;
    currencyCode?: string;
  }): Promise<Price>;
  updatePrice({
    id,
    productId,
    displayName,
    description,
    type,
    category,
    fixedAmount,
    fixedCentiAmount,
    minAmount,
    minCentiAmount,
    maxAmount,
    maxCentiAmount,
    percentage,
    index,
    currencyCode,
  }: {
    id: string;
    productId?: string;
    displayName?: string;
    description?: string;
    type?: 'CORE' | 'EXTRA';
    category?: 'FIXED' | 'RANGE' | 'PERCENTAGE';
    fixedAmount?: string;
    fixedCentiAmount?: string;
    minAmount?: string;
    minCentiAmount?: string;
    maxAmount?: string;
    maxCentiAmount?: string;
    percentage?: number;
    index?: number;
    currencyCode?: string;
  }): Promise<Price>;
  updatePriceStatus(id: string, status: 'ACTIVE' | 'ARCHIVED'): Promise<Price>;
  deletePrice(id: string): Promise<void>;
  getListPriceByProductIdStatus(
    productId: string,
    status?: string,
  ): Promise<ListData<Price> | null>;
}

export class RealPriceService implements IPriceService {
  private priceRepo: IPriceRepo;

  public constructor() {
    this.priceRepo = new RealPriceRepo();
  }

  public async createPrice({
    id,
    productId,
    displayName,
    description,
    type,
    category,
    fixedAmount,
    fixedCentiAmount,
    minAmount,
    minCentiAmount,
    maxAmount,
    maxCentiAmount,
    percentage,
    index,
    currencyCode,
  }: {
    id?: string;
    productId: string;
    displayName: string;
    description?: string;
    type: 'CORE' | 'EXTRA';
    category: 'FIXED' | 'RANGE' | 'PERCENTAGE';
    fixedAmount?: string;
    fixedCentiAmount?: string;
    minAmount?: string;
    minCentiAmount?: string;
    maxAmount?: string;
    maxCentiAmount?: string;
    percentage?: number;
    index?: number;
    currencyCode?: string;
  }): Promise<Price> {
    const createPriceInput: CreatePriceInput = {
      id: id,
      product_id: productId,
      display_name: displayName,
      description: description,
      status: PriceStatus.ACTIVE,
      type: type,
      category: category,
      fixed_amount: fixedAmount,
      fixed_centi_amount: fixedCentiAmount,
      min_amount: minAmount,
      min_centi_amount: minCentiAmount,
      max_amount: maxAmount,
      max_centi_amount: maxCentiAmount,
      currency_code: currencyCode || CurrencyCode.VND,
      percentage: percentage,
      index: index,
    };
    console.log('QQ createPriceInput', createPriceInput);
    const result: Price = await this.priceRepo.create(createPriceInput);
    return result;
  }

  public async updatePrice({
    id,
    productId,
    displayName,
    description,
    type,
    category,
    fixedAmount,
    fixedCentiAmount,
    minAmount,
    minCentiAmount,
    maxAmount,
    maxCentiAmount,
    percentage,
    index,
    currencyCode,
  }: {
    id: string;
    productId?: string;
    displayName?: string;
    description?: string;
    type?: 'CORE' | 'EXTRA';
    category?: 'FIXED' | 'RANGE' | 'PERCENTAGE';
    fixedAmount?: string;
    fixedCentiAmount?: string;
    minAmount?: string;
    minCentiAmount?: string;
    maxAmount?: string;
    maxCentiAmount?: string;
    percentage?: number;
    index?: number;
    currencyCode?: string;
  }): Promise<Price> {
    const updatePriceInput: UpdatePriceInput = {
      id,
      product_id: productId,
      display_name: displayName,
      description: description,
      status: PriceStatus.ACTIVE,
      type: type,
      category: category,
      fixed_amount: fixedAmount,
      fixed_centi_amount: fixedCentiAmount,
      min_amount: minAmount,
      min_centi_amount: minCentiAmount,
      max_amount: maxAmount,
      max_centi_amount: maxCentiAmount,
      currency_code: currencyCode || CurrencyCode.VND,
      percentage: percentage,
      index: index,
    };
    console.log('QQ updatePriceInput', updatePriceInput);
    const result: Price = await this.priceRepo.update(updatePriceInput);
    return result;
  }

  public async updatePriceStatus(id: string, status: 'ACTIVE' | 'ARCHIVED'): Promise<Price> {
    const updatePriceInput: UpdatePriceInput = {
      id,
      status,
    };
    const result: Price = await this.priceRepo.update(updatePriceInput);
    return result;
  }

  public async deletePrice(id: string): Promise<void> {
    await this.priceRepo.delete(id);
  }

  public async getListPriceByProductIdStatus(
    productId: string,
    status?: string,
  ): Promise<ListData<Price> | null> {
    try {
      const result = await this.priceRepo.getPricesByProductIdStatus(productId, status);
      return result;
    } catch (err) {
      captureException(err);
      return null;
    }
  }
}
