/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createTag = /* GraphQL */ `mutation CreateTag(
  $input: CreateTagInput!
  $condition: ModelTagConditionInput
) {
  createTag(input: $input, condition: $condition) {
    id
    tag_name
    display_name
    status
    total_artwork_quantity
    tag_artwork_items {
      items {
        id
        tag_id
        artwork_id
        tag_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagMutationVariables,
  APITypes.CreateTagMutation
>;
export const updateTag = /* GraphQL */ `mutation UpdateTag(
  $input: UpdateTagInput!
  $condition: ModelTagConditionInput
) {
  updateTag(input: $input, condition: $condition) {
    id
    tag_name
    display_name
    status
    total_artwork_quantity
    tag_artwork_items {
      items {
        id
        tag_id
        artwork_id
        tag_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagMutationVariables,
  APITypes.UpdateTagMutation
>;
export const deleteTag = /* GraphQL */ `mutation DeleteTag(
  $input: DeleteTagInput!
  $condition: ModelTagConditionInput
) {
  deleteTag(input: $input, condition: $condition) {
    id
    tag_name
    display_name
    status
    total_artwork_quantity
    tag_artwork_items {
      items {
        id
        tag_id
        artwork_id
        tag_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagMutationVariables,
  APITypes.DeleteTagMutation
>;
export const createTagItem = /* GraphQL */ `mutation CreateTagItem(
  $input: CreateTagItemInput!
  $condition: ModelTagItemConditionInput
) {
  createTagItem(input: $input, condition: $condition) {
    id
    tag_id
    tag {
      id
      tag_name
      display_name
      status
      total_artwork_quantity
      tag_artwork_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    tag_type
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagItemMutationVariables,
  APITypes.CreateTagItemMutation
>;
export const updateTagItem = /* GraphQL */ `mutation UpdateTagItem(
  $input: UpdateTagItemInput!
  $condition: ModelTagItemConditionInput
) {
  updateTagItem(input: $input, condition: $condition) {
    id
    tag_id
    tag {
      id
      tag_name
      display_name
      status
      total_artwork_quantity
      tag_artwork_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    tag_type
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagItemMutationVariables,
  APITypes.UpdateTagItemMutation
>;
export const deleteTagItem = /* GraphQL */ `mutation DeleteTagItem(
  $input: DeleteTagItemInput!
  $condition: ModelTagItemConditionInput
) {
  deleteTagItem(input: $input, condition: $condition) {
    id
    tag_id
    tag {
      id
      tag_name
      display_name
      status
      total_artwork_quantity
      tag_artwork_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    tag_type
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagItemMutationVariables,
  APITypes.DeleteTagItemMutation
>;
export const createArtwork = /* GraphQL */ `mutation CreateArtwork(
  $input: CreateArtworkInput!
  $condition: ModelArtworkConditionInput
) {
  createArtwork(input: $input, condition: $condition) {
    id
    cover_image_height
    cover_image_width
    cover_image_src_url
    cover_image_thumbnail_url
    title
    likes_count
    comments_count
    description
    publication_date_time
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    is_removed
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        artwork_id
        user_id
        is_removed
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    product_artworks {
      items {
        id
        product_id
        artwork_id
        is_removed
        index
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    is_buyer_showcase
    is_seller_showcase
    tag_items {
      items {
        id
        tag_id
        artwork_id
        tag_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateArtworkMutationVariables,
  APITypes.CreateArtworkMutation
>;
export const updateArtwork = /* GraphQL */ `mutation UpdateArtwork(
  $input: UpdateArtworkInput!
  $condition: ModelArtworkConditionInput
) {
  updateArtwork(input: $input, condition: $condition) {
    id
    cover_image_height
    cover_image_width
    cover_image_src_url
    cover_image_thumbnail_url
    title
    likes_count
    comments_count
    description
    publication_date_time
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    is_removed
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        artwork_id
        user_id
        is_removed
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    product_artworks {
      items {
        id
        product_id
        artwork_id
        is_removed
        index
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    is_buyer_showcase
    is_seller_showcase
    tag_items {
      items {
        id
        tag_id
        artwork_id
        tag_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateArtworkMutationVariables,
  APITypes.UpdateArtworkMutation
>;
export const deleteArtwork = /* GraphQL */ `mutation DeleteArtwork(
  $input: DeleteArtworkInput!
  $condition: ModelArtworkConditionInput
) {
  deleteArtwork(input: $input, condition: $condition) {
    id
    cover_image_height
    cover_image_width
    cover_image_src_url
    cover_image_thumbnail_url
    title
    likes_count
    comments_count
    description
    publication_date_time
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    is_removed
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        artwork_id
        user_id
        is_removed
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    product_artworks {
      items {
        id
        product_id
        artwork_id
        is_removed
        index
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    is_buyer_showcase
    is_seller_showcase
    tag_items {
      items {
        id
        tag_id
        artwork_id
        tag_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteArtworkMutationVariables,
  APITypes.DeleteArtworkMutation
>;
export const createProductArtwork = /* GraphQL */ `mutation CreateProductArtwork(
  $input: CreateProductArtworkInput!
  $condition: ModelProductArtworkConditionInput
) {
  createProductArtwork(input: $input, condition: $condition) {
    id
    product_id
    product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    is_removed
    index
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductArtworkMutationVariables,
  APITypes.CreateProductArtworkMutation
>;
export const updateProductArtwork = /* GraphQL */ `mutation UpdateProductArtwork(
  $input: UpdateProductArtworkInput!
  $condition: ModelProductArtworkConditionInput
) {
  updateProductArtwork(input: $input, condition: $condition) {
    id
    product_id
    product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    is_removed
    index
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductArtworkMutationVariables,
  APITypes.UpdateProductArtworkMutation
>;
export const deleteProductArtwork = /* GraphQL */ `mutation DeleteProductArtwork(
  $input: DeleteProductArtworkInput!
  $condition: ModelProductArtworkConditionInput
) {
  deleteProductArtwork(input: $input, condition: $condition) {
    id
    product_id
    product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    is_removed
    index
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductArtworkMutationVariables,
  APITypes.DeleteProductArtworkMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    display_name
    status
    products {
      items {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    display_name
    status
    products {
      items {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    display_name
    status
    products {
      items {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const createImage = /* GraphQL */ `mutation CreateImage(
  $input: CreateImageInput!
  $condition: ModelImageConditionInput
) {
  createImage(input: $input, condition: $condition) {
    id
    height
    width
    file_name
    src_url
    thumbnail_url
    raw_src_url
    raw_thumbnail_url
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    revision_id
    revision {
      id
      index
      status
      request_message
      request_rejection_message
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      additional_deadline_number
      additional_deadline_type
      start_date_time
      end_date_time
      images {
        nextToken
        __typename
      }
      request_revision_images {
        nextToken
        __typename
      }
      delivery_date_time
      deadline_extensions
      created_at
      updated_at
      __typename
    }
    revision_request_revision_id
    revision_request_revision {
      id
      index
      status
      request_message
      request_rejection_message
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      additional_deadline_number
      additional_deadline_type
      start_date_time
      end_date_time
      images {
        nextToken
        __typename
      }
      request_revision_images {
        nextToken
        __typename
      }
      delivery_date_time
      deadline_extensions
      created_at
      updated_at
      __typename
    }
    message_id
    message {
      id
      channel_id
      channel {
        id
        name
        created_at
        updated_at
        __typename
      }
      sender_id
      sender {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      content
      image_url
      publication_date_time
      available_to
      images {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateImageMutationVariables,
  APITypes.CreateImageMutation
>;
export const updateImage = /* GraphQL */ `mutation UpdateImage(
  $input: UpdateImageInput!
  $condition: ModelImageConditionInput
) {
  updateImage(input: $input, condition: $condition) {
    id
    height
    width
    file_name
    src_url
    thumbnail_url
    raw_src_url
    raw_thumbnail_url
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    revision_id
    revision {
      id
      index
      status
      request_message
      request_rejection_message
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      additional_deadline_number
      additional_deadline_type
      start_date_time
      end_date_time
      images {
        nextToken
        __typename
      }
      request_revision_images {
        nextToken
        __typename
      }
      delivery_date_time
      deadline_extensions
      created_at
      updated_at
      __typename
    }
    revision_request_revision_id
    revision_request_revision {
      id
      index
      status
      request_message
      request_rejection_message
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      additional_deadline_number
      additional_deadline_type
      start_date_time
      end_date_time
      images {
        nextToken
        __typename
      }
      request_revision_images {
        nextToken
        __typename
      }
      delivery_date_time
      deadline_extensions
      created_at
      updated_at
      __typename
    }
    message_id
    message {
      id
      channel_id
      channel {
        id
        name
        created_at
        updated_at
        __typename
      }
      sender_id
      sender {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      content
      image_url
      publication_date_time
      available_to
      images {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateImageMutationVariables,
  APITypes.UpdateImageMutation
>;
export const deleteImage = /* GraphQL */ `mutation DeleteImage(
  $input: DeleteImageInput!
  $condition: ModelImageConditionInput
) {
  deleteImage(input: $input, condition: $condition) {
    id
    height
    width
    file_name
    src_url
    thumbnail_url
    raw_src_url
    raw_thumbnail_url
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    revision_id
    revision {
      id
      index
      status
      request_message
      request_rejection_message
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      additional_deadline_number
      additional_deadline_type
      start_date_time
      end_date_time
      images {
        nextToken
        __typename
      }
      request_revision_images {
        nextToken
        __typename
      }
      delivery_date_time
      deadline_extensions
      created_at
      updated_at
      __typename
    }
    revision_request_revision_id
    revision_request_revision {
      id
      index
      status
      request_message
      request_rejection_message
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      additional_deadline_number
      additional_deadline_type
      start_date_time
      end_date_time
      images {
        nextToken
        __typename
      }
      request_revision_images {
        nextToken
        __typename
      }
      delivery_date_time
      deadline_extensions
      created_at
      updated_at
      __typename
    }
    message_id
    message {
      id
      channel_id
      channel {
        id
        name
        created_at
        updated_at
        __typename
      }
      sender_id
      sender {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      content
      image_url
      publication_date_time
      available_to
      images {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteImageMutationVariables,
  APITypes.DeleteImageMutation
>;
export const createLike = /* GraphQL */ `mutation CreateLike(
  $input: CreateLikeInput!
  $condition: ModelLikeConditionInput
) {
  createLike(input: $input, condition: $condition) {
    id
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    is_removed
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLikeMutationVariables,
  APITypes.CreateLikeMutation
>;
export const updateLike = /* GraphQL */ `mutation UpdateLike(
  $input: UpdateLikeInput!
  $condition: ModelLikeConditionInput
) {
  updateLike(input: $input, condition: $condition) {
    id
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    is_removed
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLikeMutationVariables,
  APITypes.UpdateLikeMutation
>;
export const deleteLike = /* GraphQL */ `mutation DeleteLike(
  $input: DeleteLikeInput!
  $condition: ModelLikeConditionInput
) {
  deleteLike(input: $input, condition: $condition) {
    id
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    is_removed
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLikeMutationVariables,
  APITypes.DeleteLikeMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    display_name
    first_name
    last_name
    username
    email_address
    birth_date
    phone_number
    country_code
    subscriber_number
    bio
    is_artist
    seller_onboarding_stage
    buyer_jobs {
      items {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    seller_jobs {
      items {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    term_of_service
    cccd
    mst
    cccd_images
    mst_stage
    seller_proposals {
      items {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    profile_picture_url
    cover_picture_url
    balance_amount
    balance_centi_amount
    balance_currency_code
    usd_balance_amount
    usd_balance_centi_amount
    contract_paid_quantity
    seller_star_quantity
    seller_review_quantity
    buyer_star_quantity
    buyer_review_quantity
    commision_started_quantity
    commission_completed_quantity
    commission_completed_on_time_quantity
    commission_cancelled_seller_fault_quantity
    commission_cancelled_buyer_fault_quantity
    commission_cancelled_mutually_quantity
    commission_cumulative_late_seconds
    commission_max_late_seconds
    device_tokens
    is_selling
    is_selling_abroad
    products {
      items {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    last_time_open_list_notification
    discount_code
    artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buyer_artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        artwork_id
        user_id
        is_removed
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    user_channels {
      items {
        id
        channel_id
        user_id
        update_date_time
        status
        next_time_should_receive_an_email
        next_time_should_receive_a_late_response
        request_start_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    messages {
      items {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payouts {
      items {
        id
        amount
        centi_amount
        currency
        billing_details
        seller_id
        description
        arrival_date_time
        status
        payment_provider
        balance_transaction_id
        request_amount
        request_centi_amount
        request_date_time
        bank_account_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    selling_refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buying_refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    seller_contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buyer_contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    given_reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    sent_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transactions {
      items {
        id
        type
        amount
        centi_amount
        currency_code
        note
        publication_date_time
        user_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    follower_quantity
    leader_quantity
    followers {
      items {
        id
        leader_id
        follower_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    leaders {
      items {
        id
        leader_id
        follower_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        user_id
        notification_sender_id
        title
        body
        publication_date_time
        is_read
        is_removed
        notification_type
        underlying_object_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    notification_senders {
      items {
        id
        user_id
        notification_sender_id
        title
        body
        publication_date_time
        is_read
        is_removed
        notification_type
        underlying_object_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    push_tokens {
      items {
        id
        user_id
        push_token
        device_type
        last_used_at
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    has_unread_commissions
    has_unread_jobs
    message_cumulative_response_seconds
    message_response_on_time_quantity
    message_response_late_quantity
    characters {
      items {
        id
        display_name
        charname
        character_type
        user_id
        description
        strengths
        flaws
        gender
        race
        birth_date
        age
        role
        hobbies
        dislikes
        profile_picture_url
        cover_photo_url
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    bank_name
    account_holder_name
    account_last_four
    address_country_code
    city
    address_line1
    additional_info
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    display_name
    first_name
    last_name
    username
    email_address
    birth_date
    phone_number
    country_code
    subscriber_number
    bio
    is_artist
    seller_onboarding_stage
    buyer_jobs {
      items {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    seller_jobs {
      items {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    term_of_service
    cccd
    mst
    cccd_images
    mst_stage
    seller_proposals {
      items {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    profile_picture_url
    cover_picture_url
    balance_amount
    balance_centi_amount
    balance_currency_code
    usd_balance_amount
    usd_balance_centi_amount
    contract_paid_quantity
    seller_star_quantity
    seller_review_quantity
    buyer_star_quantity
    buyer_review_quantity
    commision_started_quantity
    commission_completed_quantity
    commission_completed_on_time_quantity
    commission_cancelled_seller_fault_quantity
    commission_cancelled_buyer_fault_quantity
    commission_cancelled_mutually_quantity
    commission_cumulative_late_seconds
    commission_max_late_seconds
    device_tokens
    is_selling
    is_selling_abroad
    products {
      items {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    last_time_open_list_notification
    discount_code
    artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buyer_artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        artwork_id
        user_id
        is_removed
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    user_channels {
      items {
        id
        channel_id
        user_id
        update_date_time
        status
        next_time_should_receive_an_email
        next_time_should_receive_a_late_response
        request_start_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    messages {
      items {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payouts {
      items {
        id
        amount
        centi_amount
        currency
        billing_details
        seller_id
        description
        arrival_date_time
        status
        payment_provider
        balance_transaction_id
        request_amount
        request_centi_amount
        request_date_time
        bank_account_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    selling_refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buying_refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    seller_contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buyer_contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    given_reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    sent_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transactions {
      items {
        id
        type
        amount
        centi_amount
        currency_code
        note
        publication_date_time
        user_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    follower_quantity
    leader_quantity
    followers {
      items {
        id
        leader_id
        follower_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    leaders {
      items {
        id
        leader_id
        follower_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        user_id
        notification_sender_id
        title
        body
        publication_date_time
        is_read
        is_removed
        notification_type
        underlying_object_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    notification_senders {
      items {
        id
        user_id
        notification_sender_id
        title
        body
        publication_date_time
        is_read
        is_removed
        notification_type
        underlying_object_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    push_tokens {
      items {
        id
        user_id
        push_token
        device_type
        last_used_at
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    has_unread_commissions
    has_unread_jobs
    message_cumulative_response_seconds
    message_response_on_time_quantity
    message_response_late_quantity
    characters {
      items {
        id
        display_name
        charname
        character_type
        user_id
        description
        strengths
        flaws
        gender
        race
        birth_date
        age
        role
        hobbies
        dislikes
        profile_picture_url
        cover_photo_url
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    bank_name
    account_holder_name
    account_last_four
    address_country_code
    city
    address_line1
    additional_info
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    display_name
    first_name
    last_name
    username
    email_address
    birth_date
    phone_number
    country_code
    subscriber_number
    bio
    is_artist
    seller_onboarding_stage
    buyer_jobs {
      items {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    seller_jobs {
      items {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    term_of_service
    cccd
    mst
    cccd_images
    mst_stage
    seller_proposals {
      items {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    profile_picture_url
    cover_picture_url
    balance_amount
    balance_centi_amount
    balance_currency_code
    usd_balance_amount
    usd_balance_centi_amount
    contract_paid_quantity
    seller_star_quantity
    seller_review_quantity
    buyer_star_quantity
    buyer_review_quantity
    commision_started_quantity
    commission_completed_quantity
    commission_completed_on_time_quantity
    commission_cancelled_seller_fault_quantity
    commission_cancelled_buyer_fault_quantity
    commission_cancelled_mutually_quantity
    commission_cumulative_late_seconds
    commission_max_late_seconds
    device_tokens
    is_selling
    is_selling_abroad
    products {
      items {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    last_time_open_list_notification
    discount_code
    artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buyer_artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        artwork_id
        user_id
        is_removed
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    user_channels {
      items {
        id
        channel_id
        user_id
        update_date_time
        status
        next_time_should_receive_an_email
        next_time_should_receive_a_late_response
        request_start_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    messages {
      items {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payouts {
      items {
        id
        amount
        centi_amount
        currency
        billing_details
        seller_id
        description
        arrival_date_time
        status
        payment_provider
        balance_transaction_id
        request_amount
        request_centi_amount
        request_date_time
        bank_account_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    selling_refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buying_refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    seller_contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buyer_contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    given_reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    sent_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transactions {
      items {
        id
        type
        amount
        centi_amount
        currency_code
        note
        publication_date_time
        user_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    follower_quantity
    leader_quantity
    followers {
      items {
        id
        leader_id
        follower_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    leaders {
      items {
        id
        leader_id
        follower_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        user_id
        notification_sender_id
        title
        body
        publication_date_time
        is_read
        is_removed
        notification_type
        underlying_object_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    notification_senders {
      items {
        id
        user_id
        notification_sender_id
        title
        body
        publication_date_time
        is_read
        is_removed
        notification_type
        underlying_object_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    push_tokens {
      items {
        id
        user_id
        push_token
        device_type
        last_used_at
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    has_unread_commissions
    has_unread_jobs
    message_cumulative_response_seconds
    message_response_on_time_quantity
    message_response_late_quantity
    characters {
      items {
        id
        display_name
        charname
        character_type
        user_id
        description
        strengths
        flaws
        gender
        race
        birth_date
        age
        role
        hobbies
        dislikes
        profile_picture_url
        cover_photo_url
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    bank_name
    account_holder_name
    account_last_four
    address_country_code
    city
    address_line1
    additional_info
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createBankAccount = /* GraphQL */ `mutation CreateBankAccount(
  $input: CreateBankAccountInput!
  $condition: ModelBankAccountConditionInput
) {
  createBankAccount(input: $input, condition: $condition) {
    id
    user_id
    bank_name
    account_holder_name
    account_number
    currency_code
    country_code
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBankAccountMutationVariables,
  APITypes.CreateBankAccountMutation
>;
export const updateBankAccount = /* GraphQL */ `mutation UpdateBankAccount(
  $input: UpdateBankAccountInput!
  $condition: ModelBankAccountConditionInput
) {
  updateBankAccount(input: $input, condition: $condition) {
    id
    user_id
    bank_name
    account_holder_name
    account_number
    currency_code
    country_code
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBankAccountMutationVariables,
  APITypes.UpdateBankAccountMutation
>;
export const deleteBankAccount = /* GraphQL */ `mutation DeleteBankAccount(
  $input: DeleteBankAccountInput!
  $condition: ModelBankAccountConditionInput
) {
  deleteBankAccount(input: $input, condition: $condition) {
    id
    user_id
    bank_name
    account_holder_name
    account_number
    currency_code
    country_code
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBankAccountMutationVariables,
  APITypes.DeleteBankAccountMutation
>;
export const createPushToken = /* GraphQL */ `mutation CreatePushToken(
  $input: CreatePushTokenInput!
  $condition: ModelPushTokenConditionInput
) {
  createPushToken(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    push_token
    device_type
    last_used_at
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePushTokenMutationVariables,
  APITypes.CreatePushTokenMutation
>;
export const updatePushToken = /* GraphQL */ `mutation UpdatePushToken(
  $input: UpdatePushTokenInput!
  $condition: ModelPushTokenConditionInput
) {
  updatePushToken(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    push_token
    device_type
    last_used_at
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePushTokenMutationVariables,
  APITypes.UpdatePushTokenMutation
>;
export const deletePushToken = /* GraphQL */ `mutation DeletePushToken(
  $input: DeletePushTokenInput!
  $condition: ModelPushTokenConditionInput
) {
  deletePushToken(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    push_token
    device_type
    last_used_at
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePushTokenMutationVariables,
  APITypes.DeletePushTokenMutation
>;
export const createUserBlock = /* GraphQL */ `mutation CreateUserBlock(
  $input: CreateUserBlockInput!
  $condition: ModelUserBlockConditionInput
) {
  createUserBlock(input: $input, condition: $condition) {
    id
    user_give_block_id
    user_receive_block_id
    type
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserBlockMutationVariables,
  APITypes.CreateUserBlockMutation
>;
export const updateUserBlock = /* GraphQL */ `mutation UpdateUserBlock(
  $input: UpdateUserBlockInput!
  $condition: ModelUserBlockConditionInput
) {
  updateUserBlock(input: $input, condition: $condition) {
    id
    user_give_block_id
    user_receive_block_id
    type
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserBlockMutationVariables,
  APITypes.UpdateUserBlockMutation
>;
export const deleteUserBlock = /* GraphQL */ `mutation DeleteUserBlock(
  $input: DeleteUserBlockInput!
  $condition: ModelUserBlockConditionInput
) {
  deleteUserBlock(input: $input, condition: $condition) {
    id
    user_give_block_id
    user_receive_block_id
    type
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserBlockMutationVariables,
  APITypes.DeleteUserBlockMutation
>;
export const createProduct = /* GraphQL */ `mutation CreateProduct(
  $input: CreateProductInput!
  $condition: ModelProductConditionInput
) {
  createProduct(input: $input, condition: $condition) {
    id
    artist_id
    artist {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    display_name
    description
    status
    prices {
      items {
        id
        product_id
        display_name
        description
        status
        type
        category
        fixed_amount
        fixed_centi_amount
        index
        min_amount
        min_centi_amount
        max_amount
        max_centi_amount
        currency_code
        percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    slug
    category_id
    category {
      id
      display_name
      status
      products {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    min_price_amount
    max_price_amount
    currency_code
    product_star_quantity
    product_review_quantity
    commission_paid_quantity
    artist_other_artworks {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    reviews {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      reviewer_id
      reviewer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      rating
      comment
      type
      publication_date_time
      created_at
      updated_at
      __typename
    }
    contract_products {
      items {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    product_artworks {
      items {
        id
        product_id
        artwork_id
        is_removed
        index
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    dreambox_response_time
    dreambox_complete_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductMutationVariables,
  APITypes.CreateProductMutation
>;
export const updateProduct = /* GraphQL */ `mutation UpdateProduct(
  $input: UpdateProductInput!
  $condition: ModelProductConditionInput
) {
  updateProduct(input: $input, condition: $condition) {
    id
    artist_id
    artist {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    display_name
    description
    status
    prices {
      items {
        id
        product_id
        display_name
        description
        status
        type
        category
        fixed_amount
        fixed_centi_amount
        index
        min_amount
        min_centi_amount
        max_amount
        max_centi_amount
        currency_code
        percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    slug
    category_id
    category {
      id
      display_name
      status
      products {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    min_price_amount
    max_price_amount
    currency_code
    product_star_quantity
    product_review_quantity
    commission_paid_quantity
    artist_other_artworks {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    reviews {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      reviewer_id
      reviewer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      rating
      comment
      type
      publication_date_time
      created_at
      updated_at
      __typename
    }
    contract_products {
      items {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    product_artworks {
      items {
        id
        product_id
        artwork_id
        is_removed
        index
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    dreambox_response_time
    dreambox_complete_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductMutationVariables,
  APITypes.UpdateProductMutation
>;
export const deleteProduct = /* GraphQL */ `mutation DeleteProduct(
  $input: DeleteProductInput!
  $condition: ModelProductConditionInput
) {
  deleteProduct(input: $input, condition: $condition) {
    id
    artist_id
    artist {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    display_name
    description
    status
    prices {
      items {
        id
        product_id
        display_name
        description
        status
        type
        category
        fixed_amount
        fixed_centi_amount
        index
        min_amount
        min_centi_amount
        max_amount
        max_centi_amount
        currency_code
        percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    slug
    category_id
    category {
      id
      display_name
      status
      products {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    min_price_amount
    max_price_amount
    currency_code
    product_star_quantity
    product_review_quantity
    commission_paid_quantity
    artist_other_artworks {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    reviews {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      reviewer_id
      reviewer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      rating
      comment
      type
      publication_date_time
      created_at
      updated_at
      __typename
    }
    contract_products {
      items {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    product_artworks {
      items {
        id
        product_id
        artwork_id
        is_removed
        index
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    dreambox_response_time
    dreambox_complete_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductMutationVariables,
  APITypes.DeleteProductMutation
>;
export const createPrice = /* GraphQL */ `mutation CreatePrice(
  $input: CreatePriceInput!
  $condition: ModelPriceConditionInput
) {
  createPrice(input: $input, condition: $condition) {
    id
    product_id
    Product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    display_name
    description
    status
    type
    category
    fixed_amount
    fixed_centi_amount
    index
    min_amount
    min_centi_amount
    max_amount
    max_centi_amount
    currency_code
    percentage
    contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offer_prices {
      items {
        id
        extra_offer_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePriceMutationVariables,
  APITypes.CreatePriceMutation
>;
export const updatePrice = /* GraphQL */ `mutation UpdatePrice(
  $input: UpdatePriceInput!
  $condition: ModelPriceConditionInput
) {
  updatePrice(input: $input, condition: $condition) {
    id
    product_id
    Product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    display_name
    description
    status
    type
    category
    fixed_amount
    fixed_centi_amount
    index
    min_amount
    min_centi_amount
    max_amount
    max_centi_amount
    currency_code
    percentage
    contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offer_prices {
      items {
        id
        extra_offer_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePriceMutationVariables,
  APITypes.UpdatePriceMutation
>;
export const deletePrice = /* GraphQL */ `mutation DeletePrice(
  $input: DeletePriceInput!
  $condition: ModelPriceConditionInput
) {
  deletePrice(input: $input, condition: $condition) {
    id
    product_id
    Product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    display_name
    description
    status
    type
    category
    fixed_amount
    fixed_centi_amount
    index
    min_amount
    min_centi_amount
    max_amount
    max_centi_amount
    currency_code
    percentage
    contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offer_prices {
      items {
        id
        extra_offer_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePriceMutationVariables,
  APITypes.DeletePriceMutation
>;
export const createJob = /* GraphQL */ `mutation CreateJob(
  $input: CreateJobInput!
  $condition: ModelJobConditionInput
) {
  createJob(input: $input, condition: $condition) {
    id
    title
    description
    min_budget
    min_centi_budget
    max_budget
    max_centi_budget
    currency_code
    status
    is_exclusive
    publication_date_time
    deadline_date_time
    expiration_date_time
    is_comercial_artwork
    is_dreambox
    is_private_artwork
    file_format
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    proposals {
      items {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contacted_seller_ids
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobMutationVariables,
  APITypes.CreateJobMutation
>;
export const updateJob = /* GraphQL */ `mutation UpdateJob(
  $input: UpdateJobInput!
  $condition: ModelJobConditionInput
) {
  updateJob(input: $input, condition: $condition) {
    id
    title
    description
    min_budget
    min_centi_budget
    max_budget
    max_centi_budget
    currency_code
    status
    is_exclusive
    publication_date_time
    deadline_date_time
    expiration_date_time
    is_comercial_artwork
    is_dreambox
    is_private_artwork
    file_format
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    proposals {
      items {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contacted_seller_ids
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobMutationVariables,
  APITypes.UpdateJobMutation
>;
export const deleteJob = /* GraphQL */ `mutation DeleteJob(
  $input: DeleteJobInput!
  $condition: ModelJobConditionInput
) {
  deleteJob(input: $input, condition: $condition) {
    id
    title
    description
    min_budget
    min_centi_budget
    max_budget
    max_centi_budget
    currency_code
    status
    is_exclusive
    publication_date_time
    deadline_date_time
    expiration_date_time
    is_comercial_artwork
    is_dreambox
    is_private_artwork
    file_format
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    proposals {
      items {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contacted_seller_ids
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobMutationVariables,
  APITypes.DeleteJobMutation
>;
export const createProposal = /* GraphQL */ `mutation CreateProposal(
  $input: CreateProposalInput!
  $condition: ModelProposalConditionInput
) {
  createProposal(input: $input, condition: $condition) {
    id
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_comment
    status
    publication_date_time
    contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    proposal_type
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProposalMutationVariables,
  APITypes.CreateProposalMutation
>;
export const updateProposal = /* GraphQL */ `mutation UpdateProposal(
  $input: UpdateProposalInput!
  $condition: ModelProposalConditionInput
) {
  updateProposal(input: $input, condition: $condition) {
    id
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_comment
    status
    publication_date_time
    contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    proposal_type
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProposalMutationVariables,
  APITypes.UpdateProposalMutation
>;
export const deleteProposal = /* GraphQL */ `mutation DeleteProposal(
  $input: DeleteProposalInput!
  $condition: ModelProposalConditionInput
) {
  deleteProposal(input: $input, condition: $condition) {
    id
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_comment
    status
    publication_date_time
    contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    proposal_type
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProposalMutationVariables,
  APITypes.DeleteProposalMutation
>;
export const createChannel = /* GraphQL */ `mutation CreateChannel(
  $input: CreateChannelInput!
  $condition: ModelChannelConditionInput
) {
  createChannel(input: $input, condition: $condition) {
    id
    name
    user_channels {
      items {
        id
        channel_id
        user_id
        update_date_time
        status
        next_time_should_receive_an_email
        next_time_should_receive_a_late_response
        request_start_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    messages {
      items {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChannelMutationVariables,
  APITypes.CreateChannelMutation
>;
export const updateChannel = /* GraphQL */ `mutation UpdateChannel(
  $input: UpdateChannelInput!
  $condition: ModelChannelConditionInput
) {
  updateChannel(input: $input, condition: $condition) {
    id
    name
    user_channels {
      items {
        id
        channel_id
        user_id
        update_date_time
        status
        next_time_should_receive_an_email
        next_time_should_receive_a_late_response
        request_start_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    messages {
      items {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChannelMutationVariables,
  APITypes.UpdateChannelMutation
>;
export const deleteChannel = /* GraphQL */ `mutation DeleteChannel(
  $input: DeleteChannelInput!
  $condition: ModelChannelConditionInput
) {
  deleteChannel(input: $input, condition: $condition) {
    id
    name
    user_channels {
      items {
        id
        channel_id
        user_id
        update_date_time
        status
        next_time_should_receive_an_email
        next_time_should_receive_a_late_response
        request_start_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    messages {
      items {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChannelMutationVariables,
  APITypes.DeleteChannelMutation
>;
export const createUserChannel = /* GraphQL */ `mutation CreateUserChannel(
  $input: CreateUserChannelInput!
  $condition: ModelUserChannelConditionInput
) {
  createUserChannel(input: $input, condition: $condition) {
    id
    channel_id
    channel {
      id
      name
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    update_date_time
    status
    next_time_should_receive_an_email
    next_time_should_receive_a_late_response
    request_start_date_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserChannelMutationVariables,
  APITypes.CreateUserChannelMutation
>;
export const updateUserChannel = /* GraphQL */ `mutation UpdateUserChannel(
  $input: UpdateUserChannelInput!
  $condition: ModelUserChannelConditionInput
) {
  updateUserChannel(input: $input, condition: $condition) {
    id
    channel_id
    channel {
      id
      name
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    update_date_time
    status
    next_time_should_receive_an_email
    next_time_should_receive_a_late_response
    request_start_date_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserChannelMutationVariables,
  APITypes.UpdateUserChannelMutation
>;
export const deleteUserChannel = /* GraphQL */ `mutation DeleteUserChannel(
  $input: DeleteUserChannelInput!
  $condition: ModelUserChannelConditionInput
) {
  deleteUserChannel(input: $input, condition: $condition) {
    id
    channel_id
    channel {
      id
      name
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    update_date_time
    status
    next_time_should_receive_an_email
    next_time_should_receive_a_late_response
    request_start_date_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserChannelMutationVariables,
  APITypes.DeleteUserChannelMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    channel_id
    channel {
      id
      name
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    sender_id
    sender {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    content
    image_url
    publication_date_time
    available_to
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    channel_id
    channel {
      id
      name
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    sender_id
    sender {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    content
    image_url
    publication_date_time
    available_to
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    channel_id
    channel {
      id
      name
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    sender_id
    sender {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    content
    image_url
    publication_date_time
    available_to
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createPaymentIntent = /* GraphQL */ `mutation CreatePaymentIntent(
  $input: CreatePaymentIntentInput!
  $condition: ModelPaymentIntentConditionInput
) {
  createPaymentIntent(input: $input, condition: $condition) {
    id
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_payment_contract_id
    extra_payment_contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_offer_id
    extra_offer {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency_code
      status
      payment_intents {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    amount
    centi_amount
    currency
    payout_owing_to_seller_amount
    payout_owing_to_seller_centi_amount
    fee_owing_to_momo_amount
    fee_owing_to_momo_centi_amount
    dreamerly_fee_balance_amount
    dreamerly_fee_balance_centi_amount
    display_fee_owing_to_momo_amount
    display_fee_owing_to_momo_centi_amount
    display_dreamerly_fee_balance_amount
    display_dreamerly_fee_balance_centi_amount
    payment_purpose
    billing_details
    description
    last_payment_error
    next_action
    disputed
    disputed_at
    refunded
    refunded_amount
    refunded_centi_amount
    refunded_at
    status
    payment_provider
    payment_method
    paid_at
    cancelled_at
    momo_trans_id
    momo_request_id
    momo_payment_url
    momo_payment_url_expires_at
    stripe_payment_intent_id
    stripe_payment_method_country
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intent_events {
      items {
        id
        payment_intent_id
        event_id
        payment_intent_status
        payment_intent_amount
        payment_intent_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentIntentMutationVariables,
  APITypes.CreatePaymentIntentMutation
>;
export const updatePaymentIntent = /* GraphQL */ `mutation UpdatePaymentIntent(
  $input: UpdatePaymentIntentInput!
  $condition: ModelPaymentIntentConditionInput
) {
  updatePaymentIntent(input: $input, condition: $condition) {
    id
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_payment_contract_id
    extra_payment_contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_offer_id
    extra_offer {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency_code
      status
      payment_intents {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    amount
    centi_amount
    currency
    payout_owing_to_seller_amount
    payout_owing_to_seller_centi_amount
    fee_owing_to_momo_amount
    fee_owing_to_momo_centi_amount
    dreamerly_fee_balance_amount
    dreamerly_fee_balance_centi_amount
    display_fee_owing_to_momo_amount
    display_fee_owing_to_momo_centi_amount
    display_dreamerly_fee_balance_amount
    display_dreamerly_fee_balance_centi_amount
    payment_purpose
    billing_details
    description
    last_payment_error
    next_action
    disputed
    disputed_at
    refunded
    refunded_amount
    refunded_centi_amount
    refunded_at
    status
    payment_provider
    payment_method
    paid_at
    cancelled_at
    momo_trans_id
    momo_request_id
    momo_payment_url
    momo_payment_url_expires_at
    stripe_payment_intent_id
    stripe_payment_method_country
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intent_events {
      items {
        id
        payment_intent_id
        event_id
        payment_intent_status
        payment_intent_amount
        payment_intent_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentIntentMutationVariables,
  APITypes.UpdatePaymentIntentMutation
>;
export const deletePaymentIntent = /* GraphQL */ `mutation DeletePaymentIntent(
  $input: DeletePaymentIntentInput!
  $condition: ModelPaymentIntentConditionInput
) {
  deletePaymentIntent(input: $input, condition: $condition) {
    id
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_payment_contract_id
    extra_payment_contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_offer_id
    extra_offer {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency_code
      status
      payment_intents {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    amount
    centi_amount
    currency
    payout_owing_to_seller_amount
    payout_owing_to_seller_centi_amount
    fee_owing_to_momo_amount
    fee_owing_to_momo_centi_amount
    dreamerly_fee_balance_amount
    dreamerly_fee_balance_centi_amount
    display_fee_owing_to_momo_amount
    display_fee_owing_to_momo_centi_amount
    display_dreamerly_fee_balance_amount
    display_dreamerly_fee_balance_centi_amount
    payment_purpose
    billing_details
    description
    last_payment_error
    next_action
    disputed
    disputed_at
    refunded
    refunded_amount
    refunded_centi_amount
    refunded_at
    status
    payment_provider
    payment_method
    paid_at
    cancelled_at
    momo_trans_id
    momo_request_id
    momo_payment_url
    momo_payment_url_expires_at
    stripe_payment_intent_id
    stripe_payment_method_country
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intent_events {
      items {
        id
        payment_intent_id
        event_id
        payment_intent_status
        payment_intent_amount
        payment_intent_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentIntentMutationVariables,
  APITypes.DeletePaymentIntentMutation
>;
export const createPayout = /* GraphQL */ `mutation CreatePayout(
  $input: CreatePayoutInput!
  $condition: ModelPayoutConditionInput
) {
  createPayout(input: $input, condition: $condition) {
    id
    amount
    centi_amount
    currency
    billing_details
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    arrival_date_time
    status
    payment_provider
    balance_transaction_id
    request_amount
    request_centi_amount
    request_date_time
    bank_account_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePayoutMutationVariables,
  APITypes.CreatePayoutMutation
>;
export const updatePayout = /* GraphQL */ `mutation UpdatePayout(
  $input: UpdatePayoutInput!
  $condition: ModelPayoutConditionInput
) {
  updatePayout(input: $input, condition: $condition) {
    id
    amount
    centi_amount
    currency
    billing_details
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    arrival_date_time
    status
    payment_provider
    balance_transaction_id
    request_amount
    request_centi_amount
    request_date_time
    bank_account_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePayoutMutationVariables,
  APITypes.UpdatePayoutMutation
>;
export const deletePayout = /* GraphQL */ `mutation DeletePayout(
  $input: DeletePayoutInput!
  $condition: ModelPayoutConditionInput
) {
  deletePayout(input: $input, condition: $condition) {
    id
    amount
    centi_amount
    currency
    billing_details
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    arrival_date_time
    status
    payment_provider
    balance_transaction_id
    request_amount
    request_centi_amount
    request_date_time
    bank_account_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePayoutMutationVariables,
  APITypes.DeletePayoutMutation
>;
export const createRefund = /* GraphQL */ `mutation CreateRefund(
  $input: CreateRefundInput!
  $condition: ModelRefundConditionInput
) {
  createRefund(input: $input, condition: $condition) {
    id
    amount
    centi_amount
    currency
    payout_owing_to_seller_amount
    payout_owing_to_seller_centi_amount
    fee_owing_to_momo_amount
    fee_owing_to_momo_centi_amount
    dreamerly_fee_balance_amount
    dreamerly_fee_balance_centi_amount
    billing_details
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    payment_intent_id
    payment_intent {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    reason
    status
    response
    payment_provider
    momo_trans_id
    approved_at
    rejected_at
    refunded_at
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRefundMutationVariables,
  APITypes.CreateRefundMutation
>;
export const updateRefund = /* GraphQL */ `mutation UpdateRefund(
  $input: UpdateRefundInput!
  $condition: ModelRefundConditionInput
) {
  updateRefund(input: $input, condition: $condition) {
    id
    amount
    centi_amount
    currency
    payout_owing_to_seller_amount
    payout_owing_to_seller_centi_amount
    fee_owing_to_momo_amount
    fee_owing_to_momo_centi_amount
    dreamerly_fee_balance_amount
    dreamerly_fee_balance_centi_amount
    billing_details
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    payment_intent_id
    payment_intent {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    reason
    status
    response
    payment_provider
    momo_trans_id
    approved_at
    rejected_at
    refunded_at
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRefundMutationVariables,
  APITypes.UpdateRefundMutation
>;
export const deleteRefund = /* GraphQL */ `mutation DeleteRefund(
  $input: DeleteRefundInput!
  $condition: ModelRefundConditionInput
) {
  deleteRefund(input: $input, condition: $condition) {
    id
    amount
    centi_amount
    currency
    payout_owing_to_seller_amount
    payout_owing_to_seller_centi_amount
    fee_owing_to_momo_amount
    fee_owing_to_momo_centi_amount
    dreamerly_fee_balance_amount
    dreamerly_fee_balance_centi_amount
    billing_details
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    payment_intent_id
    payment_intent {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    reason
    status
    response
    payment_provider
    momo_trans_id
    approved_at
    rejected_at
    refunded_at
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRefundMutationVariables,
  APITypes.DeleteRefundMutation
>;
export const createContract = /* GraphQL */ `mutation CreateContract(
  $input: CreateContractInput!
  $condition: ModelContractConditionInput
) {
  createContract(input: $input, condition: $condition) {
    id
    status
    publication_date_time
    is_private_artwork
    is_comercial_artwork
    file_formats
    description
    amount
    centi_amount
    currency_code
    term_of_service
    state_name
    current_milestone_id
    current_milestone_index
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    proposal_id
    proposal {
      id
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_comment
      status
      publication_date_time
      contracts {
        nextToken
        __typename
      }
      proposal_type
      created_at
      updated_at
      __typename
    }
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_products {
      items {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_payment_payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    milestones {
      items {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contract_events {
      items {
        id
        contract_id
        event_id
        contract_status
        contract_amount
        contract_currency
        extra_offer_status
        extra_offer_amount
        extra_offer_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offers {
      items {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContractMutationVariables,
  APITypes.CreateContractMutation
>;
export const updateContract = /* GraphQL */ `mutation UpdateContract(
  $input: UpdateContractInput!
  $condition: ModelContractConditionInput
) {
  updateContract(input: $input, condition: $condition) {
    id
    status
    publication_date_time
    is_private_artwork
    is_comercial_artwork
    file_formats
    description
    amount
    centi_amount
    currency_code
    term_of_service
    state_name
    current_milestone_id
    current_milestone_index
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    proposal_id
    proposal {
      id
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_comment
      status
      publication_date_time
      contracts {
        nextToken
        __typename
      }
      proposal_type
      created_at
      updated_at
      __typename
    }
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_products {
      items {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_payment_payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    milestones {
      items {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contract_events {
      items {
        id
        contract_id
        event_id
        contract_status
        contract_amount
        contract_currency
        extra_offer_status
        extra_offer_amount
        extra_offer_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offers {
      items {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContractMutationVariables,
  APITypes.UpdateContractMutation
>;
export const deleteContract = /* GraphQL */ `mutation DeleteContract(
  $input: DeleteContractInput!
  $condition: ModelContractConditionInput
) {
  deleteContract(input: $input, condition: $condition) {
    id
    status
    publication_date_time
    is_private_artwork
    is_comercial_artwork
    file_formats
    description
    amount
    centi_amount
    currency_code
    term_of_service
    state_name
    current_milestone_id
    current_milestone_index
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    proposal_id
    proposal {
      id
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_comment
      status
      publication_date_time
      contracts {
        nextToken
        __typename
      }
      proposal_type
      created_at
      updated_at
      __typename
    }
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_products {
      items {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_payment_payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    milestones {
      items {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contract_events {
      items {
        id
        contract_id
        event_id
        contract_status
        contract_amount
        contract_currency
        extra_offer_status
        extra_offer_amount
        extra_offer_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offers {
      items {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContractMutationVariables,
  APITypes.DeleteContractMutation
>;
export const createContractProduct = /* GraphQL */ `mutation CreateContractProduct(
  $input: CreateContractProductInput!
  $condition: ModelContractProductConditionInput
) {
  createContractProduct(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    product_id
    product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    product_display_name
    product_description
    contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContractProductMutationVariables,
  APITypes.CreateContractProductMutation
>;
export const updateContractProduct = /* GraphQL */ `mutation UpdateContractProduct(
  $input: UpdateContractProductInput!
  $condition: ModelContractProductConditionInput
) {
  updateContractProduct(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    product_id
    product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    product_display_name
    product_description
    contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContractProductMutationVariables,
  APITypes.UpdateContractProductMutation
>;
export const deleteContractProduct = /* GraphQL */ `mutation DeleteContractProduct(
  $input: DeleteContractProductInput!
  $condition: ModelContractProductConditionInput
) {
  deleteContractProduct(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    product_id
    product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    product_display_name
    product_description
    contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContractProductMutationVariables,
  APITypes.DeleteContractProductMutation
>;
export const createContractProductPrice = /* GraphQL */ `mutation CreateContractProductPrice(
  $input: CreateContractProductPriceInput!
  $condition: ModelContractProductPriceConditionInput
) {
  createContractProductPrice(input: $input, condition: $condition) {
    id
    contract_product_id
    contract_product {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      product_display_name
      product_description
      contract_product_prices {
        nextToken
        __typename
      }
      extra_contract_product_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_contract_product_id
    extra_contract_product {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      product_display_name
      product_description
      contract_product_prices {
        nextToken
        __typename
      }
      extra_contract_product_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_id
    price {
      id
      product_id
      Product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      type
      category
      fixed_amount
      fixed_centi_amount
      index
      min_amount
      min_centi_amount
      max_amount
      max_centi_amount
      currency_code
      percentage
      contract_product_prices {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_quantity
    price_display_name
    price_description
    price_type
    price_category
    price_fixed_amount
    price_fixed_centi_amount
    price_currency_code
    price_percentage
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContractProductPriceMutationVariables,
  APITypes.CreateContractProductPriceMutation
>;
export const updateContractProductPrice = /* GraphQL */ `mutation UpdateContractProductPrice(
  $input: UpdateContractProductPriceInput!
  $condition: ModelContractProductPriceConditionInput
) {
  updateContractProductPrice(input: $input, condition: $condition) {
    id
    contract_product_id
    contract_product {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      product_display_name
      product_description
      contract_product_prices {
        nextToken
        __typename
      }
      extra_contract_product_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_contract_product_id
    extra_contract_product {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      product_display_name
      product_description
      contract_product_prices {
        nextToken
        __typename
      }
      extra_contract_product_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_id
    price {
      id
      product_id
      Product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      type
      category
      fixed_amount
      fixed_centi_amount
      index
      min_amount
      min_centi_amount
      max_amount
      max_centi_amount
      currency_code
      percentage
      contract_product_prices {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_quantity
    price_display_name
    price_description
    price_type
    price_category
    price_fixed_amount
    price_fixed_centi_amount
    price_currency_code
    price_percentage
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContractProductPriceMutationVariables,
  APITypes.UpdateContractProductPriceMutation
>;
export const deleteContractProductPrice = /* GraphQL */ `mutation DeleteContractProductPrice(
  $input: DeleteContractProductPriceInput!
  $condition: ModelContractProductPriceConditionInput
) {
  deleteContractProductPrice(input: $input, condition: $condition) {
    id
    contract_product_id
    contract_product {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      product_display_name
      product_description
      contract_product_prices {
        nextToken
        __typename
      }
      extra_contract_product_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_contract_product_id
    extra_contract_product {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      product_display_name
      product_description
      contract_product_prices {
        nextToken
        __typename
      }
      extra_contract_product_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_id
    price {
      id
      product_id
      Product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      type
      category
      fixed_amount
      fixed_centi_amount
      index
      min_amount
      min_centi_amount
      max_amount
      max_centi_amount
      currency_code
      percentage
      contract_product_prices {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_quantity
    price_display_name
    price_description
    price_type
    price_category
    price_fixed_amount
    price_fixed_centi_amount
    price_currency_code
    price_percentage
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContractProductPriceMutationVariables,
  APITypes.DeleteContractProductPriceMutation
>;
export const createExtraOffer = /* GraphQL */ `mutation CreateExtraOffer(
  $input: CreateExtraOfferInput!
  $condition: ModelExtraOfferConditionInput
) {
  createExtraOffer(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    amount
    centi_amount
    currency_code
    status
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offer_prices {
      items {
        id
        extra_offer_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExtraOfferMutationVariables,
  APITypes.CreateExtraOfferMutation
>;
export const updateExtraOffer = /* GraphQL */ `mutation UpdateExtraOffer(
  $input: UpdateExtraOfferInput!
  $condition: ModelExtraOfferConditionInput
) {
  updateExtraOffer(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    amount
    centi_amount
    currency_code
    status
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offer_prices {
      items {
        id
        extra_offer_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExtraOfferMutationVariables,
  APITypes.UpdateExtraOfferMutation
>;
export const deleteExtraOffer = /* GraphQL */ `mutation DeleteExtraOffer(
  $input: DeleteExtraOfferInput!
  $condition: ModelExtraOfferConditionInput
) {
  deleteExtraOffer(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    amount
    centi_amount
    currency_code
    status
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offer_prices {
      items {
        id
        extra_offer_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExtraOfferMutationVariables,
  APITypes.DeleteExtraOfferMutation
>;
export const createExtraOfferPrice = /* GraphQL */ `mutation CreateExtraOfferPrice(
  $input: CreateExtraOfferPriceInput!
  $condition: ModelExtraOfferPriceConditionInput
) {
  createExtraOfferPrice(input: $input, condition: $condition) {
    id
    extra_offer_id
    extra_offer {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency_code
      status
      payment_intents {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_id
    price {
      id
      product_id
      Product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      type
      category
      fixed_amount
      fixed_centi_amount
      index
      min_amount
      min_centi_amount
      max_amount
      max_centi_amount
      currency_code
      percentage
      contract_product_prices {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_quantity
    price_display_name
    price_description
    price_type
    price_category
    price_fixed_amount
    price_fixed_centi_amount
    price_currency_code
    price_percentage
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExtraOfferPriceMutationVariables,
  APITypes.CreateExtraOfferPriceMutation
>;
export const updateExtraOfferPrice = /* GraphQL */ `mutation UpdateExtraOfferPrice(
  $input: UpdateExtraOfferPriceInput!
  $condition: ModelExtraOfferPriceConditionInput
) {
  updateExtraOfferPrice(input: $input, condition: $condition) {
    id
    extra_offer_id
    extra_offer {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency_code
      status
      payment_intents {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_id
    price {
      id
      product_id
      Product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      type
      category
      fixed_amount
      fixed_centi_amount
      index
      min_amount
      min_centi_amount
      max_amount
      max_centi_amount
      currency_code
      percentage
      contract_product_prices {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_quantity
    price_display_name
    price_description
    price_type
    price_category
    price_fixed_amount
    price_fixed_centi_amount
    price_currency_code
    price_percentage
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExtraOfferPriceMutationVariables,
  APITypes.UpdateExtraOfferPriceMutation
>;
export const deleteExtraOfferPrice = /* GraphQL */ `mutation DeleteExtraOfferPrice(
  $input: DeleteExtraOfferPriceInput!
  $condition: ModelExtraOfferPriceConditionInput
) {
  deleteExtraOfferPrice(input: $input, condition: $condition) {
    id
    extra_offer_id
    extra_offer {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency_code
      status
      payment_intents {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_id
    price {
      id
      product_id
      Product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      type
      category
      fixed_amount
      fixed_centi_amount
      index
      min_amount
      min_centi_amount
      max_amount
      max_centi_amount
      currency_code
      percentage
      contract_product_prices {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_quantity
    price_display_name
    price_description
    price_type
    price_category
    price_fixed_amount
    price_fixed_centi_amount
    price_currency_code
    price_percentage
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExtraOfferPriceMutationVariables,
  APITypes.DeleteExtraOfferPriceMutation
>;
export const createMilestone = /* GraphQL */ `mutation CreateMilestone(
  $input: CreateMilestoneInput!
  $condition: ModelMilestoneConditionInput
) {
  createMilestone(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    step_index
    step_name
    deadline_number
    deadline_type
    start_date_time
    end_date_time
    delivery_date_time
    free_revision_count
    current_revision_index
    current_revision_id
    description
    deadline_extensions
    price_percentage
    amount
    centi_amount
    currency_code
    status
    milestone_events {
      items {
        id
        milestone_id
        event_id
        milestone_status
        milestone_step_index
        milestone_step_name
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    revisions {
      items {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMilestoneMutationVariables,
  APITypes.CreateMilestoneMutation
>;
export const updateMilestone = /* GraphQL */ `mutation UpdateMilestone(
  $input: UpdateMilestoneInput!
  $condition: ModelMilestoneConditionInput
) {
  updateMilestone(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    step_index
    step_name
    deadline_number
    deadline_type
    start_date_time
    end_date_time
    delivery_date_time
    free_revision_count
    current_revision_index
    current_revision_id
    description
    deadline_extensions
    price_percentage
    amount
    centi_amount
    currency_code
    status
    milestone_events {
      items {
        id
        milestone_id
        event_id
        milestone_status
        milestone_step_index
        milestone_step_name
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    revisions {
      items {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMilestoneMutationVariables,
  APITypes.UpdateMilestoneMutation
>;
export const deleteMilestone = /* GraphQL */ `mutation DeleteMilestone(
  $input: DeleteMilestoneInput!
  $condition: ModelMilestoneConditionInput
) {
  deleteMilestone(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    step_index
    step_name
    deadline_number
    deadline_type
    start_date_time
    end_date_time
    delivery_date_time
    free_revision_count
    current_revision_index
    current_revision_id
    description
    deadline_extensions
    price_percentage
    amount
    centi_amount
    currency_code
    status
    milestone_events {
      items {
        id
        milestone_id
        event_id
        milestone_status
        milestone_step_index
        milestone_step_name
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    revisions {
      items {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMilestoneMutationVariables,
  APITypes.DeleteMilestoneMutation
>;
export const createRevision = /* GraphQL */ `mutation CreateRevision(
  $input: CreateRevisionInput!
  $condition: ModelRevisionConditionInput
) {
  createRevision(input: $input, condition: $condition) {
    id
    index
    status
    request_message
    request_rejection_message
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    additional_deadline_number
    additional_deadline_type
    start_date_time
    end_date_time
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    request_revision_images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    delivery_date_time
    deadline_extensions
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRevisionMutationVariables,
  APITypes.CreateRevisionMutation
>;
export const updateRevision = /* GraphQL */ `mutation UpdateRevision(
  $input: UpdateRevisionInput!
  $condition: ModelRevisionConditionInput
) {
  updateRevision(input: $input, condition: $condition) {
    id
    index
    status
    request_message
    request_rejection_message
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    additional_deadline_number
    additional_deadline_type
    start_date_time
    end_date_time
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    request_revision_images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    delivery_date_time
    deadline_extensions
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRevisionMutationVariables,
  APITypes.UpdateRevisionMutation
>;
export const deleteRevision = /* GraphQL */ `mutation DeleteRevision(
  $input: DeleteRevisionInput!
  $condition: ModelRevisionConditionInput
) {
  deleteRevision(input: $input, condition: $condition) {
    id
    index
    status
    request_message
    request_rejection_message
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    additional_deadline_number
    additional_deadline_type
    start_date_time
    end_date_time
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    request_revision_images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    delivery_date_time
    deadline_extensions
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRevisionMutationVariables,
  APITypes.DeleteRevisionMutation
>;
export const createContractEvent = /* GraphQL */ `mutation CreateContractEvent(
  $input: CreateContractEventInput!
  $condition: ModelContractEventConditionInput
) {
  createContractEvent(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    contract_status
    contract_amount
    contract_currency
    extra_offer_status
    extra_offer_amount
    extra_offer_currency
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContractEventMutationVariables,
  APITypes.CreateContractEventMutation
>;
export const updateContractEvent = /* GraphQL */ `mutation UpdateContractEvent(
  $input: UpdateContractEventInput!
  $condition: ModelContractEventConditionInput
) {
  updateContractEvent(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    contract_status
    contract_amount
    contract_currency
    extra_offer_status
    extra_offer_amount
    extra_offer_currency
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContractEventMutationVariables,
  APITypes.UpdateContractEventMutation
>;
export const deleteContractEvent = /* GraphQL */ `mutation DeleteContractEvent(
  $input: DeleteContractEventInput!
  $condition: ModelContractEventConditionInput
) {
  deleteContractEvent(input: $input, condition: $condition) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    contract_status
    contract_amount
    contract_currency
    extra_offer_status
    extra_offer_amount
    extra_offer_currency
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContractEventMutationVariables,
  APITypes.DeleteContractEventMutation
>;
export const createPaymentIntentEvent = /* GraphQL */ `mutation CreatePaymentIntentEvent(
  $input: CreatePaymentIntentEventInput!
  $condition: ModelPaymentIntentEventConditionInput
) {
  createPaymentIntentEvent(input: $input, condition: $condition) {
    id
    payment_intent_id
    payment_intent {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    payment_intent_status
    payment_intent_amount
    payment_intent_currency
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentIntentEventMutationVariables,
  APITypes.CreatePaymentIntentEventMutation
>;
export const updatePaymentIntentEvent = /* GraphQL */ `mutation UpdatePaymentIntentEvent(
  $input: UpdatePaymentIntentEventInput!
  $condition: ModelPaymentIntentEventConditionInput
) {
  updatePaymentIntentEvent(input: $input, condition: $condition) {
    id
    payment_intent_id
    payment_intent {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    payment_intent_status
    payment_intent_amount
    payment_intent_currency
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentIntentEventMutationVariables,
  APITypes.UpdatePaymentIntentEventMutation
>;
export const deletePaymentIntentEvent = /* GraphQL */ `mutation DeletePaymentIntentEvent(
  $input: DeletePaymentIntentEventInput!
  $condition: ModelPaymentIntentEventConditionInput
) {
  deletePaymentIntentEvent(input: $input, condition: $condition) {
    id
    payment_intent_id
    payment_intent {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    payment_intent_status
    payment_intent_amount
    payment_intent_currency
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentIntentEventMutationVariables,
  APITypes.DeletePaymentIntentEventMutation
>;
export const createMilestoneEvent = /* GraphQL */ `mutation CreateMilestoneEvent(
  $input: CreateMilestoneEventInput!
  $condition: ModelMilestoneEventConditionInput
) {
  createMilestoneEvent(input: $input, condition: $condition) {
    id
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    milestone_status
    milestone_step_index
    milestone_step_name
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMilestoneEventMutationVariables,
  APITypes.CreateMilestoneEventMutation
>;
export const updateMilestoneEvent = /* GraphQL */ `mutation UpdateMilestoneEvent(
  $input: UpdateMilestoneEventInput!
  $condition: ModelMilestoneEventConditionInput
) {
  updateMilestoneEvent(input: $input, condition: $condition) {
    id
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    milestone_status
    milestone_step_index
    milestone_step_name
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMilestoneEventMutationVariables,
  APITypes.UpdateMilestoneEventMutation
>;
export const deleteMilestoneEvent = /* GraphQL */ `mutation DeleteMilestoneEvent(
  $input: DeleteMilestoneEventInput!
  $condition: ModelMilestoneEventConditionInput
) {
  deleteMilestoneEvent(input: $input, condition: $condition) {
    id
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    milestone_status
    milestone_step_index
    milestone_step_name
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMilestoneEventMutationVariables,
  APITypes.DeleteMilestoneEventMutation
>;
export const createEvent = /* GraphQL */ `mutation CreateEvent(
  $input: CreateEventInput!
  $condition: ModelEventConditionInput
) {
  createEvent(input: $input, condition: $condition) {
    id
    type
    note
    contract_events {
      items {
        id
        contract_id
        event_id
        contract_status
        contract_amount
        contract_currency
        extra_offer_status
        extra_offer_amount
        extra_offer_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intent_events {
      items {
        id
        payment_intent_id
        event_id
        payment_intent_status
        payment_intent_amount
        payment_intent_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    milestone_events {
      items {
        id
        milestone_id
        event_id
        milestone_status
        milestone_step_index
        milestone_step_name
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventMutationVariables,
  APITypes.CreateEventMutation
>;
export const updateEvent = /* GraphQL */ `mutation UpdateEvent(
  $input: UpdateEventInput!
  $condition: ModelEventConditionInput
) {
  updateEvent(input: $input, condition: $condition) {
    id
    type
    note
    contract_events {
      items {
        id
        contract_id
        event_id
        contract_status
        contract_amount
        contract_currency
        extra_offer_status
        extra_offer_amount
        extra_offer_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intent_events {
      items {
        id
        payment_intent_id
        event_id
        payment_intent_status
        payment_intent_amount
        payment_intent_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    milestone_events {
      items {
        id
        milestone_id
        event_id
        milestone_status
        milestone_step_index
        milestone_step_name
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventMutationVariables,
  APITypes.UpdateEventMutation
>;
export const deleteEvent = /* GraphQL */ `mutation DeleteEvent(
  $input: DeleteEventInput!
  $condition: ModelEventConditionInput
) {
  deleteEvent(input: $input, condition: $condition) {
    id
    type
    note
    contract_events {
      items {
        id
        contract_id
        event_id
        contract_status
        contract_amount
        contract_currency
        extra_offer_status
        extra_offer_amount
        extra_offer_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intent_events {
      items {
        id
        payment_intent_id
        event_id
        payment_intent_status
        payment_intent_amount
        payment_intent_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    milestone_events {
      items {
        id
        milestone_id
        event_id
        milestone_status
        milestone_step_index
        milestone_step_name
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventMutationVariables,
  APITypes.DeleteEventMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview(
  $input: CreateReviewInput!
  $condition: ModelReviewConditionInput
) {
  createReview(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    reviewer_id
    reviewer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    rating
    comment
    type
    publication_date_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview(
  $input: UpdateReviewInput!
  $condition: ModelReviewConditionInput
) {
  updateReview(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    reviewer_id
    reviewer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    rating
    comment
    type
    publication_date_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview(
  $input: DeleteReviewInput!
  $condition: ModelReviewConditionInput
) {
  deleteReview(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    reviewer_id
    reviewer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    rating
    comment
    type
    publication_date_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createUserFollow = /* GraphQL */ `mutation CreateUserFollow(
  $input: CreateUserFollowInput!
  $condition: ModelUserFollowConditionInput
) {
  createUserFollow(input: $input, condition: $condition) {
    id
    leader_id
    follower_id
    leader {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    follower {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserFollowMutationVariables,
  APITypes.CreateUserFollowMutation
>;
export const updateUserFollow = /* GraphQL */ `mutation UpdateUserFollow(
  $input: UpdateUserFollowInput!
  $condition: ModelUserFollowConditionInput
) {
  updateUserFollow(input: $input, condition: $condition) {
    id
    leader_id
    follower_id
    leader {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    follower {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserFollowMutationVariables,
  APITypes.UpdateUserFollowMutation
>;
export const deleteUserFollow = /* GraphQL */ `mutation DeleteUserFollow(
  $input: DeleteUserFollowInput!
  $condition: ModelUserFollowConditionInput
) {
  deleteUserFollow(input: $input, condition: $condition) {
    id
    leader_id
    follower_id
    leader {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    follower {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserFollowMutationVariables,
  APITypes.DeleteUserFollowMutation
>;
export const createDreamerlyBalance = /* GraphQL */ `mutation CreateDreamerlyBalance(
  $input: CreateDreamerlyBalanceInput!
  $condition: ModelDreamerlyBalanceConditionInput
) {
  createDreamerlyBalance(input: $input, condition: $condition) {
    id
    currency_code
    sent_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDreamerlyBalanceMutationVariables,
  APITypes.CreateDreamerlyBalanceMutation
>;
export const updateDreamerlyBalance = /* GraphQL */ `mutation UpdateDreamerlyBalance(
  $input: UpdateDreamerlyBalanceInput!
  $condition: ModelDreamerlyBalanceConditionInput
) {
  updateDreamerlyBalance(input: $input, condition: $condition) {
    id
    currency_code
    sent_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDreamerlyBalanceMutationVariables,
  APITypes.UpdateDreamerlyBalanceMutation
>;
export const deleteDreamerlyBalance = /* GraphQL */ `mutation DeleteDreamerlyBalance(
  $input: DeleteDreamerlyBalanceInput!
  $condition: ModelDreamerlyBalanceConditionInput
) {
  deleteDreamerlyBalance(input: $input, condition: $condition) {
    id
    currency_code
    sent_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDreamerlyBalanceMutationVariables,
  APITypes.DeleteDreamerlyBalanceMutation
>;
export const createBalanceTransfer = /* GraphQL */ `mutation CreateBalanceTransfer(
  $input: CreateBalanceTransferInput!
  $condition: ModelBalanceTransferConditionInput
) {
  createBalanceTransfer(input: $input, condition: $condition) {
    id
    reason
    amount
    centi_amount
    dreamerly_fee
    dreamerly_centi_fee
    payment_provider_fee
    payment_provider_centi_fee
    net_amount
    net_centi_amount
    payment_provider
    currency_code
    note
    publication_date_time
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    from_user_id
    from_user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    to_user_id
    to_user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    from_dreamerly_balance_id
    from_dreamerly_balance {
      id
      currency_code
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    to_dreamerly_balance_id
    to_dreamerly_balance {
      id
      currency_code
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBalanceTransferMutationVariables,
  APITypes.CreateBalanceTransferMutation
>;
export const updateBalanceTransfer = /* GraphQL */ `mutation UpdateBalanceTransfer(
  $input: UpdateBalanceTransferInput!
  $condition: ModelBalanceTransferConditionInput
) {
  updateBalanceTransfer(input: $input, condition: $condition) {
    id
    reason
    amount
    centi_amount
    dreamerly_fee
    dreamerly_centi_fee
    payment_provider_fee
    payment_provider_centi_fee
    net_amount
    net_centi_amount
    payment_provider
    currency_code
    note
    publication_date_time
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    from_user_id
    from_user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    to_user_id
    to_user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    from_dreamerly_balance_id
    from_dreamerly_balance {
      id
      currency_code
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    to_dreamerly_balance_id
    to_dreamerly_balance {
      id
      currency_code
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBalanceTransferMutationVariables,
  APITypes.UpdateBalanceTransferMutation
>;
export const deleteBalanceTransfer = /* GraphQL */ `mutation DeleteBalanceTransfer(
  $input: DeleteBalanceTransferInput!
  $condition: ModelBalanceTransferConditionInput
) {
  deleteBalanceTransfer(input: $input, condition: $condition) {
    id
    reason
    amount
    centi_amount
    dreamerly_fee
    dreamerly_centi_fee
    payment_provider_fee
    payment_provider_centi_fee
    net_amount
    net_centi_amount
    payment_provider
    currency_code
    note
    publication_date_time
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    from_user_id
    from_user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    to_user_id
    to_user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    from_dreamerly_balance_id
    from_dreamerly_balance {
      id
      currency_code
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    to_dreamerly_balance_id
    to_dreamerly_balance {
      id
      currency_code
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBalanceTransferMutationVariables,
  APITypes.DeleteBalanceTransferMutation
>;
export const createPaymentProviderFeePayment = /* GraphQL */ `mutation CreatePaymentProviderFeePayment(
  $input: CreatePaymentProviderFeePaymentInput!
  $condition: ModelPaymentProviderFeePaymentConditionInput
) {
  createPaymentProviderFeePayment(input: $input, condition: $condition) {
    id
    amount
    centi_amount
    currency_code
    publication_date_time
    payment_provider
    description
    invoice_number
    from_dreamerly_balance_id
    to_dreamerly_balance_id
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentProviderFeePaymentMutationVariables,
  APITypes.CreatePaymentProviderFeePaymentMutation
>;
export const updatePaymentProviderFeePayment = /* GraphQL */ `mutation UpdatePaymentProviderFeePayment(
  $input: UpdatePaymentProviderFeePaymentInput!
  $condition: ModelPaymentProviderFeePaymentConditionInput
) {
  updatePaymentProviderFeePayment(input: $input, condition: $condition) {
    id
    amount
    centi_amount
    currency_code
    publication_date_time
    payment_provider
    description
    invoice_number
    from_dreamerly_balance_id
    to_dreamerly_balance_id
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentProviderFeePaymentMutationVariables,
  APITypes.UpdatePaymentProviderFeePaymentMutation
>;
export const deletePaymentProviderFeePayment = /* GraphQL */ `mutation DeletePaymentProviderFeePayment(
  $input: DeletePaymentProviderFeePaymentInput!
  $condition: ModelPaymentProviderFeePaymentConditionInput
) {
  deletePaymentProviderFeePayment(input: $input, condition: $condition) {
    id
    amount
    centi_amount
    currency_code
    publication_date_time
    payment_provider
    description
    invoice_number
    from_dreamerly_balance_id
    to_dreamerly_balance_id
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentProviderFeePaymentMutationVariables,
  APITypes.DeletePaymentProviderFeePaymentMutation
>;
export const createBalanceTransaction = /* GraphQL */ `mutation CreateBalanceTransaction(
  $input: CreateBalanceTransactionInput!
  $condition: ModelBalanceTransactionConditionInput
) {
  createBalanceTransaction(input: $input, condition: $condition) {
    id
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payouts {
      items {
        id
        amount
        centi_amount
        currency
        billing_details
        seller_id
        description
        arrival_date_time
        status
        payment_provider
        balance_transaction_id
        request_amount
        request_centi_amount
        request_date_time
        bank_account_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_provider_fee_payments {
      items {
        id
        amount
        centi_amount
        currency_code
        publication_date_time
        payment_provider
        description
        invoice_number
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    type
    amount
    centi_amount
    currency_code
    note
    publication_date_time
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBalanceTransactionMutationVariables,
  APITypes.CreateBalanceTransactionMutation
>;
export const updateBalanceTransaction = /* GraphQL */ `mutation UpdateBalanceTransaction(
  $input: UpdateBalanceTransactionInput!
  $condition: ModelBalanceTransactionConditionInput
) {
  updateBalanceTransaction(input: $input, condition: $condition) {
    id
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payouts {
      items {
        id
        amount
        centi_amount
        currency
        billing_details
        seller_id
        description
        arrival_date_time
        status
        payment_provider
        balance_transaction_id
        request_amount
        request_centi_amount
        request_date_time
        bank_account_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_provider_fee_payments {
      items {
        id
        amount
        centi_amount
        currency_code
        publication_date_time
        payment_provider
        description
        invoice_number
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    type
    amount
    centi_amount
    currency_code
    note
    publication_date_time
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBalanceTransactionMutationVariables,
  APITypes.UpdateBalanceTransactionMutation
>;
export const deleteBalanceTransaction = /* GraphQL */ `mutation DeleteBalanceTransaction(
  $input: DeleteBalanceTransactionInput!
  $condition: ModelBalanceTransactionConditionInput
) {
  deleteBalanceTransaction(input: $input, condition: $condition) {
    id
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payouts {
      items {
        id
        amount
        centi_amount
        currency
        billing_details
        seller_id
        description
        arrival_date_time
        status
        payment_provider
        balance_transaction_id
        request_amount
        request_centi_amount
        request_date_time
        bank_account_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_provider_fee_payments {
      items {
        id
        amount
        centi_amount
        currency_code
        publication_date_time
        payment_provider
        description
        invoice_number
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    type
    amount
    centi_amount
    currency_code
    note
    publication_date_time
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBalanceTransactionMutationVariables,
  APITypes.DeleteBalanceTransactionMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    notification_sender_id
    notification_sender {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    title
    body
    publication_date_time
    is_read
    is_removed
    notification_type
    underlying_object_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    notification_sender_id
    notification_sender {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    title
    body
    publication_date_time
    is_read
    is_removed
    notification_type
    underlying_object_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    notification_sender_id
    notification_sender {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    title
    body
    publication_date_time
    is_read
    is_removed
    notification_type
    underlying_object_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const createCharacter = /* GraphQL */ `mutation CreateCharacter(
  $input: CreateCharacterInput!
  $condition: ModelCharacterConditionInput
) {
  createCharacter(input: $input, condition: $condition) {
    id
    display_name
    charname
    character_type
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    strengths
    flaws
    gender
    race
    birth_date
    age
    role
    hobbies
    dislikes
    profile_picture_url
    cover_photo_url
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCharacterMutationVariables,
  APITypes.CreateCharacterMutation
>;
export const updateCharacter = /* GraphQL */ `mutation UpdateCharacter(
  $input: UpdateCharacterInput!
  $condition: ModelCharacterConditionInput
) {
  updateCharacter(input: $input, condition: $condition) {
    id
    display_name
    charname
    character_type
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    strengths
    flaws
    gender
    race
    birth_date
    age
    role
    hobbies
    dislikes
    profile_picture_url
    cover_photo_url
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCharacterMutationVariables,
  APITypes.UpdateCharacterMutation
>;
export const deleteCharacter = /* GraphQL */ `mutation DeleteCharacter(
  $input: DeleteCharacterInput!
  $condition: ModelCharacterConditionInput
) {
  deleteCharacter(input: $input, condition: $condition) {
    id
    display_name
    charname
    character_type
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    strengths
    flaws
    gender
    race
    birth_date
    age
    role
    hobbies
    dislikes
    profile_picture_url
    cover_photo_url
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCharacterMutationVariables,
  APITypes.DeleteCharacterMutation
>;
export const createCharacterItem = /* GraphQL */ `mutation CreateCharacterItem(
  $input: CreateCharacterItemInput!
  $condition: ModelCharacterItemConditionInput
) {
  createCharacterItem(input: $input, condition: $condition) {
    id
    item_type
    character_id
    character {
      id
      display_name
      charname
      character_type
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      strengths
      flaws
      gender
      race
      birth_date
      age
      role
      hobbies
      dislikes
      profile_picture_url
      cover_photo_url
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCharacterItemMutationVariables,
  APITypes.CreateCharacterItemMutation
>;
export const updateCharacterItem = /* GraphQL */ `mutation UpdateCharacterItem(
  $input: UpdateCharacterItemInput!
  $condition: ModelCharacterItemConditionInput
) {
  updateCharacterItem(input: $input, condition: $condition) {
    id
    item_type
    character_id
    character {
      id
      display_name
      charname
      character_type
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      strengths
      flaws
      gender
      race
      birth_date
      age
      role
      hobbies
      dislikes
      profile_picture_url
      cover_photo_url
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCharacterItemMutationVariables,
  APITypes.UpdateCharacterItemMutation
>;
export const deleteCharacterItem = /* GraphQL */ `mutation DeleteCharacterItem(
  $input: DeleteCharacterItemInput!
  $condition: ModelCharacterItemConditionInput
) {
  deleteCharacterItem(input: $input, condition: $condition) {
    id
    item_type
    character_id
    character {
      id
      display_name
      charname
      character_type
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      strengths
      flaws
      gender
      race
      birth_date
      age
      role
      hobbies
      dislikes
      profile_picture_url
      cover_photo_url
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCharacterItemMutationVariables,
  APITypes.DeleteCharacterItemMutation
>;
export const dreamerlySaveBankAccount = /* GraphQL */ `mutation DreamerlySaveBankAccount(
  $user_id: String!
  $bank_name: String!
  $account_holder_name: String!
  $account_number: String!
) {
  dreamerlySaveBankAccount(
    user_id: $user_id
    bank_name: $bank_name
    account_holder_name: $account_holder_name
    account_number: $account_number
  ) {
    bank_account_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DreamerlySaveBankAccountMutationVariables,
  APITypes.DreamerlySaveBankAccountMutation
>;
export const dreamerlySavePayoutRequest = /* GraphQL */ `mutation DreamerlySavePayoutRequest(
  $seller_id: String!
  $amount: String!
  $currency_code: String
) {
  dreamerlySavePayoutRequest(
    seller_id: $seller_id
    amount: $amount
    currency_code: $currency_code
  ) {
    payout_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DreamerlySavePayoutRequestMutationVariables,
  APITypes.DreamerlySavePayoutRequestMutation
>;
