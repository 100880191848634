import { UserFollow } from '@src/API';
import useIsMounted from '@src/hooks/useIsMounted';
import captureException from '@src/services/loggerService';
import { IUserService, RealUserService } from '@src/services/userService';
import { retry } from '@src/utils/retry';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { User as FirebaseUser, getAuth } from 'firebase/auth';
import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

type AuthProviderProps = {
  children: React.ReactNode;
};

export type DreamerlyUser = {
  id: string;
  username: string;
  displayName: string;
  phoneNumber: string;
  countryCode: string;
  subscriberNumber: string;
  isArtist?: boolean;
  sellerOnboardingStage?: string | null;
  bio?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  birthDate?: string;
  profile_picture_url?: string;
  discount_code?: string;
  followers?: UserFollow[]; // Your followers
  leaders?: UserFollow[]; // The people you follow
  last_time_open_list_notification?: string;
  hasUnreadCommissions?: boolean;
  hasUnReadJobs?: boolean;
  balance_amount?: string | null;
  usd_balance_amount?: string | null;
  termOfService?: string;
  is_selling_abroad?: boolean;
  mst_stage?: string;
};

export type AuthContextValue = {
  isFetchingDreamerlyUser: boolean;
  isAuthenticated: boolean;
  isVerifiedSessionToken: boolean;
  user: DreamerlyUser | null | undefined;
  refreshUser: () => Promise<DreamerlyUser | null | undefined>;
  signOut: () => Promise<void>;
  userSessionId?: string | null;
};

const defaultUser = {
  id: '',
  username: '',
  displayName: '',
  phoneNumber: '',
  countryCode: '',
  subscriberNumber: '',
  isArtist: false,
  sellerOnboardingStage: undefined,
  bio: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  birthDate: '',
  discount_code: '',
  profile_picture_url: '',
  followers: [],
  leaders: [],
  last_time_open_list_notification: '',
  hasUnreadCommissions: false,
  hasUnReadJobs: false,
  balance_amount: '',
  usd_balance_amount: '',
  is_selling_abroad: false,
  mst_stage: '',
};

const defaultAuthValue = {
  isFetchingDreamerlyUser: true,
  isAuthenticated: false,
  isVerifiedSessionToken: false,
  user: defaultUser,
  refreshUser: async () => defaultUser,
  signOut: async () => {
    return;
  },
  userSessionId: null,
};

const AuthContext = createContext<AuthContextValue>(defaultAuthValue);

export default function AuthProvider({ children }: AuthProviderProps) {
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null | undefined>(undefined);
  const [dreamerlyUser, setDreamerlyUser] = useState<DreamerlyUser | null>(null);
  const [userSessionId, setUserSessionId] = useState<string | null>(null); // TODO: [Future] Use this to track user session [https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/bp-sort-keys.html
  const [isVerifiedSessionToken, setIsVerifiedSessionToken] = useState<boolean>(false);
  const router = useRouter();
  const isMounted = useIsMounted();

  /* Functions */

  // API
  const loginApi = async (idToken: string) => {
    try {
      const body = {
        idToken,
      };
      const res = await axios.post('/api/session/login', body, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      setIsVerifiedSessionToken(res.status === 200);
      return res.status === 200;
    } catch (error) {
      captureException(error);
      return false;
    }
  };

  const logoutApi = async () => {
    const res = await axios.post('/api/session/logout', null, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    return res.status === 200;
  };

  const getUser = useCallback(async (userId: string) => {
    const userService: IUserService = new RealUserService();
    try {
      const userDDB = await userService.getUser(userId);
      if (userDDB) {
        const isArtist = userDDB?.is_artist === 1;
        const user: DreamerlyUser = {
          id: userId,
          username: userDDB.username || '',
          displayName: userDDB.display_name || '',
          phoneNumber: userDDB?.phone_number || '',
          countryCode: userDDB?.country_code || '',
          subscriberNumber: userDDB?.subscriber_number || '',
          isArtist,
          sellerOnboardingStage: userDDB?.seller_onboarding_stage,
          bio: userDDB?.bio || '',
          firstName: userDDB?.first_name || '',
          lastName: userDDB?.last_name || '',
          emailAddress: userDDB?.email_address || '',
          birthDate: userDDB?.birth_date || '',
          profile_picture_url: userDDB?.profile_picture_url || '',
          discount_code: userDDB?.discount_code || '',
          followers:
            (userDDB?.followers?.items?.filter((userFollow) => !!userFollow) as UserFollow[]) || [],
          leaders:
            (userDDB?.leaders?.items?.filter((userFollow) => !!userFollow) as UserFollow[]) || [],
          last_time_open_list_notification: userDDB?.last_time_open_list_notification || '',
          hasUnreadCommissions: userDDB?.has_unread_commissions === 1,
          hasUnReadJobs: userDDB?.has_unread_jobs === 1,
          balance_amount: userDDB?.balance_amount || '',
          usd_balance_amount: userDDB?.usd_balance_amount || '',
          termOfService: userDDB?.term_of_service || '',
          is_selling_abroad: userDDB?.is_selling_abroad === 1,
          mst_stage: userDDB?.mst_stage || '',
        };
        return user;
      }
    } catch (e) {
      // We have a case where the user already signup with Firebase but the user is not in the database
      // captureException(e);
      return null;
    }
  }, []);

  const refreshUser = async () => {
    try {
      if (!firebaseUser) {
        return null;
      }
      const user = await getUser(firebaseUser.uid);
      if (user) {
        // Only update if the component is still mounted
        if (isMounted()) {
          setDreamerlyUser(user);
        }
        return user;
      }
      if (isMounted()) {
        // If there is no userDDB, update dreamerlyUser to null
        setDreamerlyUser(null);
      }
      return null;
    } catch (e) {
      captureException(e);
      if (isMounted()) {
        // If error, update dreamerlyUser to null
        setDreamerlyUser(null);
      }
      return null;
    }
  };

  const signOut = useCallback(async () => {
    await getAuth().signOut();
    await logoutApi();
    if (isMounted()) {
      // Only update if the component is still mounted
      setFirebaseUser(null);
      setDreamerlyUser(null);
      localStorage.removeItem('dreamerlyUser');
    }
  }, [setFirebaseUser, setDreamerlyUser, isMounted]);

  /* Effects */

  useEffect(() => {
    // if (!window.Intercom) return;
    // // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/dyr60apc'
    // if (['/', '/jobs', '/artists'].includes(router.pathname)) {
    //   window.intercomSettings = {
    //     api_base: 'https://api-iam.intercom.io',
    //     app_id: 'dyr60apc',
    //     vertical_padding: 54,
    //   };
    //   if (firebaseUser) {
    //     window.Intercom('update', {
    //       user_id: firebaseUser.uid, // User ID
    //       user_email: firebaseUser.email, // User email address
    //     });
    //   } else {
    //     window.Intercom('boot');
    //   }
    // } else {
    //   window.Intercom('shutdown');
    // }
  }, [firebaseUser, router.pathname]);

  useEffect(() => {
    if (firebaseUser) {
      // TODO: I concern about using localStorage here or sessionStorage???
      // Restore Dreamerly's User from Local Storage
      const dreamerlyUserStr = localStorage.getItem('dreamerlyUser');
      if (dreamerlyUserStr) {
        setDreamerlyUser(JSON.parse(dreamerlyUserStr));
      }
    } else {
      localStorage.removeItem('dreamerlyUser');
    }
  }, [firebaseUser]);

  // Force refresh the token every 10 minutes
  // useEffect(() => {
  //   const handle = setInterval(async () => {
  //     const user = getAuth().currentUser;
  //     if (user) {
  //       await promiseRetry(async () => {
  //         const token = await user.getIdToken(true);
  //         const success = await loginApi(token);
  //         if (!success) {
  //           throw new Error('Login API failed');
  //         } else {
  //           console.log('QQ: Refresh API success', token);
  //         }
  //       });
  //     }
  //   }, 10 * 60 * 1000); // 10 minutes = 10 (minutes) * 60 (seconds) * 1000 (miliseconds)

  //   // clean up setInterval
  //   return () => clearInterval(handle);
  // }, []);

  useEffect(() => {
    const onAuthStateChanged = async (data: FirebaseUser | null) => {
      if (!data) {
        if (isMounted()) {
          setFirebaseUser(null);
        }
      } else {
        if (data.emailVerified || data.phoneNumber) {
          if (isMounted()) {
            setFirebaseUser(data);
          }
          // Mixpanel
          try {
            const { MixpanelTracking } = await import('@src/utils/mixpanelService');
            MixpanelTracking.getInstance().trackLogIn({
              userId: data.uid,
            });
          } catch (error) {
            captureException(error);
          }

          const options = { retries: 3, retryIntervalMs: 200 };
          await retry(async () => {
            const token = await data.getIdToken(true);
            const success = await loginApi(token);
            if (!success) {
              throw new Error('Login API failed');
            }
          }, options);
        }
      }
    };

    getAuth().onAuthStateChanged(onAuthStateChanged);
  }, [setFirebaseUser, isMounted, signOut]);

  const { isFetched, data } = useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['AuthProvider', firebaseUser?.uid],
    queryFn: async () => {
      if (!firebaseUser?.uid) return null;
      const user = await getUser(firebaseUser.uid);
      return user;
    },
    enabled: firebaseUser !== undefined,
  });

  useEffect(() => {
    if (window.sessionStorage) {
      let sessionId = window.sessionStorage.getItem('userSessionId');
      if (!sessionId) {
        sessionId = uuidv4();
        window.sessionStorage.setItem('userSessionId', sessionId);
      }
      setUserSessionId(sessionId);
    }
  }, []);

  useEffect(() => {
    console.log('AuthProvider: isFetched, data', isFetched, data);
    if (!isFetched) return;
    setDreamerlyUser(data || null);
    if (data) {
      localStorage.setItem('dreamerlyUser', JSON.stringify(data));
    } else {
      localStorage.removeItem('dreamerlyUser');
    }
  }, [data, isFetched]);

  const stateValues = {
    isFetchingDreamerlyUser: !isFetched,
    isAuthenticated: dreamerlyUser !== null,
    isVerifiedSessionToken,
    user: dreamerlyUser,
    refreshUser,
    signOut,
    userSessionId,
  };

  return <AuthContext.Provider value={stateValues}>{children}</AuthContext.Provider>;
}

export const useAuthState = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (!context) {
    throw Error('Use useAuthState in AuthProvider');
  }
  return context;
};
