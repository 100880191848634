const formatCurrency = (price: string | undefined | null, currencyCode = 'VND') => {
  if (!price) {
    return '';
  }
  const isInteger = Number.isInteger(Number(price));
  return Number(price).toLocaleString('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: isInteger ? 0 : 1,
    maximumFractionDigits: isInteger ? 0 : 2,
  });
};

const formatNumberToCurrency = (price: number | undefined | null, currencyCode = 'VND') => {
  if (!price) {
    return '';
  }
  const isInteger = Number.isInteger(price);
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: isInteger ? 0 : 1,
    maximumFractionDigits: isInteger ? 0 : 2,
  });
};

const replaceWithBr = (text: string) => {
  return text.replace(/\n/g, '<br />');
};

const SHORT_DISPLAY_TEXT_LENGTH = 16;
const MEDIUM_DISPLAY_TEXT_LENGTH = 60;
const SHORT_INPUT_TEXT_LENGTH = 20;
const MEDIUM_INPUT_TEXT_LENGTH = 100;
const LONG_INPUT_TEXT_LENGTH = 500;

/**
 * Helper function to truncate text by number of characters, add ellipsis at the end.
 */
const truncateText = (
  text: string | undefined | null,
  maxLength: number = SHORT_DISPLAY_TEXT_LENGTH,
) => {
  if (!text) {
    return '';
  }
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.slice(0, maxLength)}...`;
};

/**
 * Helper function to format input text (digits only) to thousand-separator number.
 */
const formatDigitOnlyText = (text: string) => {
  // Validate if text consists only of numbers and comma separators
  if (text.match(/^[0-9,]*$/)) {
    // Remove any existing comma separators
    let value = text.replace(/,/g, '');
    // Add comma separators to the proper positions
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value;
  }
  return undefined;
};

/**
 * Helper function to convert thousand-separator number string to digit-only text.
 * This is the opposite of formatDigitOnlyText().
 */
const convertToDigitOnlyText = (text: string) => {
  // Validate if text consists only of numbers and comma separators
  if (text.match(/^[0-9,]*$/)) {
    // Remove any existing comma separators
    const value = text.replace(/,/g, '');
    return value;
  }
  return undefined;
};

/**
 * Helper function to format file name to truncate the middle part, leaving the beginning and file type intact.
 */
const truncateFileName = (fileName: string, maxLength: number = SHORT_DISPLAY_TEXT_LENGTH) => {
  // Check if filename length is less than maximum length
  if (fileName.length <= maxLength) {
    return fileName;
  }
  const separator = '...';
  const extension = fileName.includes('.') ? `.${fileName.split('.').pop()}` : '';
  const name = fileName.replace(extension, '');

  const size = maxLength - extension.length;
  const start = name.substring(0, size / 2);
  const end = name.substring(name.length - size / 2);

  return `${start}${separator}${end}${extension}`;
};
function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export {
  LONG_INPUT_TEXT_LENGTH,
  MEDIUM_DISPLAY_TEXT_LENGTH,
  MEDIUM_INPUT_TEXT_LENGTH,
  SHORT_DISPLAY_TEXT_LENGTH,
  SHORT_INPUT_TEXT_LENGTH,
  capitalizeFirstLetter,
  convertToDigitOnlyText,
  formatCurrency,
  formatDigitOnlyText,
  formatNumberToCurrency,
  replaceWithBr,
  truncateFileName,
  truncateText,
};
