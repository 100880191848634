/* eslint-disable max-len */
export const customGetProposal = /* GraphQL */ `
  query GetProposal($id: ID!) {
    getProposal(id: $id) {
      id
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        is_comercial_artwork
        is_private_artwork
        file_format
        buyer_id
        buyer {
          id
          display_name
          username
          email_address
          bio
          is_artist
          profile_picture_url
          cover_picture_url
          is_selling
          created_at
          updated_at
        }
        created_at
        updated_at
      }
      seller_id
      seller {
        id
        display_name
        username
        bio
        is_artist
        profile_picture_url
        cover_picture_url
        is_selling
        artworks {
          items {
            id
            cover_image_height
            cover_image_width
            cover_image_src_url
            cover_image_thumbnail_url
            title
            likes_count
            comments_count
            description
            user_id
            created_at
            updated_at
          }
          nextToken
        }
        created_at
        updated_at
      }
      seller_comment
      status
      publication_date_time
      contracts {
        items {
          id
          status
          publication_date_time
          is_private_artwork
          job_id
          proposal_id
          amount
          centi_amount
          currency_code
          buyer_id
          seller_id
          created_at
          updated_at
        }
        nextToken
      }
      created_at
      updated_at
    }
  }
`;
export const customProductByArtistIdStatus = /* GraphQL */ `
  query ProductByArtistIdStatus(
    $artist_id: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByArtistIdStatus(
      artist_id: $artist_id
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        artist_id
        dreambox_complete_time
        dreambox_response_time
        min_price_amount
        artist {
          id
          display_name
          username
          email_address
          subscriber_number
          bio
          is_artist
          profile_picture_url
          cover_picture_url
          is_selling
          created_at
          updated_at
        }
        category_id
        display_name
        slug
        description
        status
        currency_code
        prices {
          items {
            id
            product_id
            display_name
            description
            status
            type
            category
            index
            fixed_amount
            fixed_centi_amount
            min_amount
            min_centi_amount
            max_amount
            max_centi_amount
            currency_code
            percentage
            created_at
            updated_at
          }
          nextToken
        }
        contract_products {
          nextToken
        }
        product_artworks {
          items {
            id
            product_id
            artwork_id
            artwork {
              id
              cover_image_height
              cover_image_width
              cover_image_src_url
              cover_image_thumbnail_url
              title
              likes_count
              comments_count
              description
              publication_date_time
              user_id
              is_removed
            }
            is_removed
            index
            created_at
            updated_at
          }
          nextToken
        }
        created_at
        updated_at
      }
      nextToken
    }
  }
`;

export const customProposalBySellerIdStatusPublicationDateTime = /* GraphQL */ `
  query ProposalBySellerIdStatusPublicationDateTime(
    $seller_id: ID!
    $statusPublication_date_time: ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    proposalBySellerIdStatusPublicationDateTime(
      seller_id: $seller_id
      statusPublication_date_time: $statusPublication_date_time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        job_id
        job {
          id
          title
          description
          min_budget
          min_centi_budget
          max_budget
          max_centi_budget
          currency_code
          status
          is_exclusive
          publication_date_time
          deadline_date_time
          is_comercial_artwork
          is_private_artwork
          file_format
          buyer_id
          buyer {
            id
            display_name
            username
            bio
            is_artist
            profile_picture_url
            cover_picture_url
            is_selling
            created_at
            updated_at
          }
          created_at
          updated_at
        }
        seller_id
        seller {
          id
          display_name
          username
          bio
          is_artist
          profile_picture_url
          cover_picture_url
          is_selling
          created_at
          updated_at
        }
        seller_comment
        status
        publication_date_time
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
export const customMilestoneByContractId = /* GraphQL */ `
  query MilestoneByContractId(
    $contract_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMilestoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    milestoneByContractId(
      contract_id: $contract_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contract_id
        contract {
          id
          status
          publication_date_time
          is_private_artwork
          is_comercial_artwork
          file_formats
          description
          amount
          centi_amount
          currency_code
          state_name
          current_milestone_id
          current_milestone_index
          job_id
          proposal_id
          buyer_id
          seller_id
          created_at
          updated_at
        }
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        price_percentage
        amount
        centi_amount
        currency_code
        status
        milestone_events {
          nextToken
        }
        revisions {
          items {
            id
            index
            status
            request_message
            request_rejection_message
            milestone_id
            additional_deadline_number
            additional_deadline_type
            start_date_time
            end_date_time
            delivery_date_time
            created_at
            updated_at
            images {
              nextToken
              items {
                id
                height
                width
                src_url
                thumbnail_url
                file_name
                artwork_id
                job_id
                milestone_id
                revision_id
                message_id
                created_at
                updated_at
              }
            }
          }
          nextToken
        }
        images {
          nextToken
          items {
            id
            height
            width
            src_url
            thumbnail_url
            file_name
            artwork_id
            job_id
            milestone_id
            revision_id
            message_id
            created_at
            updated_at
          }
        }
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
export const customMessageByChannelIdPublicationDateTime = /* GraphQL */ `
  query MessageByChannelIdPublicationDateTime(
    $channel_id: ID!
    $publication_date_time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByChannelIdPublicationDateTime(
      channel_id: $channel_id
      publication_date_time: $publication_date_time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channel_id
        channel {
          id
          name
          created_at
          updated_at
        }
        sender_id
        sender {
          id
          display_name
          first_name
          last_name
          username
          email_address
          birth_date
          phone_number
          country_code
          subscriber_number
          bio
          is_artist
          seller_onboarding_stage
          profile_picture_url
          cover_picture_url
          balance_amount
          balance_centi_amount
          balance_currency_code
          contract_paid_quantity
          seller_star_quantity
          seller_review_quantity
          commision_started_quantity
          commission_completed_quantity
          commission_completed_on_time_quantity
          commission_cancelled_seller_fault_quantity
          commission_cancelled_buyer_fault_quantity
          commission_cancelled_mutually_quantity
          is_selling
          discount_code
          follower_quantity
          leader_quantity
          created_at
          updated_at
        }
        content
        image_url
        publication_date_time
        images {
          nextToken
          items {
            id
            height
            width
            src_url
            thumbnail_url
            file_name
            artwork_id
            job_id
            milestone_id
            revision_id
            message_id
            created_at
            updated_at
          }
        }
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
