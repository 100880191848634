import WizardBottomAppBar from '@components/bottom/WizardBottomAppBar';
import WizardHeader from '@components/header/WizardHeader';
import ProductNameFreeSoloInput from '@components/product/ProductNameFreeSoloInput';
import { Menu } from '@mui/material';
import { Product, ProductArtwork } from '@src/API';
import { ProductStatus } from '@src/customGraphql/customModels';
import { S3UploadedImageFile } from '@src/customGraphql/customType';
import { SellerOnboardingStage } from '@src/pages/become-a-seller';
import { DreamerlyUser } from '@src/providers/AuthProvider';
import { IArtworkService, RealArtworkService } from '@src/services/artworkService';
import captureException from '@src/services/loggerService';
import { IProductService, RealProductService } from '@src/services/productService';
import { IUserService, RealUserService } from '@src/services/userService';
import { category_id_display_name_map } from '@src/utils/constants';
import { SHORT_INPUT_TEXT_LENGTH } from '@src/utils/formatText';
import { customImageLoader } from '@src/utils/media';
import { useQueryClient } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const UploadProductArtworkButton = dynamic(
  () => import('@components/upload/UploadProductArtworkButton'),
);

type CreateProductsProps = {
  user: DreamerlyUser;
  refreshUser: () => Promise<DreamerlyUser | null | undefined>;
  setStage: (stage: SellerOnboardingStage) => void;
  productToAddPrices?: Product | undefined;
  setProductToAddPrices: (product: Product) => void;
};

const getImageFilesFromProductArtworks = (productArtworks: (ProductArtwork | null)[]) => {
  const imageList =
    (
      productArtworks.filter(
        (productArtwork) => !!productArtwork && productArtwork.is_removed === 0,
      ) as ProductArtwork[]
    ).map((productArtwork) => {
      return {
        name: productArtwork.artwork?.title || '',
        srcUrl: productArtwork.artwork?.cover_image_src_url || '',
        thumbnailUrl: productArtwork.artwork?.cover_image_thumbnail_url || '',
        height: productArtwork.artwork?.cover_image_height || 0,
        width: productArtwork.artwork?.cover_image_width || 0,
        title: productArtwork.artwork?.title || '',
        description: productArtwork.artwork?.description || '',
        productArtworkId: productArtwork.id,
        artworkId: productArtwork.artwork_id,
        index: productArtwork.index || 0,
      } as S3UploadedImageFile;
    }) || [];

  return imageList;
};

function CreateProducts(props: CreateProductsProps) {
  const { user, refreshUser, setStage, productToAddPrices } = props;
  const [productDisplayName, setProductDisplayName] = useState<string>('');
  const [productDisplayNameErrorMessage, setProductDisplayNameErrorMessage] = useState<string>('');

  // Images: We maintain 2 lists of images: The existing list, and an updated
  // list after user's actions. The updated list is then compared with the
  // existing list to determine which images to add and which images to remove.
  const [updatedImageFiles, setUpdatedImageFiles] = useState<S3UploadedImageFile[]>([]);
  const existingImageFiles = getImageFilesFromProductArtworks(
    productToAddPrices?.product_artworks?.items || [],
  );
  const [updatedImageFilesErrorMessage, setUpdatedImageFilesErrorMessage] = useState<string>('');

  // Header and BottomAppBar loading states
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isBackLoading, setIsBackLoading] = useState(false);
  const [isExitLoading, setIsExitLoading] = useState(false);

  // Category
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(null);
  const isCategorySelectionMenuOpen = Boolean(categoryAnchorEl);
  const [categoryId, setCategoryId] = useState<string>('other');

  // Services
  const productService: IProductService = new RealProductService();
  const userService: IUserService = new RealUserService();
  const artworkService: IArtworkService = new RealArtworkService();

  const router = useRouter();
  const queryClient = useQueryClient();

  // useEffect to set data
  useEffect(() => {
    if (productToAddPrices) {
      setProductDisplayName(productToAddPrices.display_name);
      // In the start, updatedImageFiles is the same as existingImageFiles
      setUpdatedImageFiles(
        getImageFilesFromProductArtworks(productToAddPrices.product_artworks?.items || []),
      );
    }
  }, [productToAddPrices]);

  // If updatedImageFiles is not empty, clear the error message
  useEffect(() => {
    if (updatedImageFiles.length > 0) {
      setUpdatedImageFilesErrorMessage('');
    }
  }, [updatedImageFiles]);

  /**
   * Helper function to save/update ProductArtwork
   */
  const saveProductArtworksToDDB = async (productId: string) => {
    // add index to updatedImageFiles
    const updatedImageFilestWithIndex = updatedImageFiles.map((productArtworkFile, index) => {
      return {
        ...productArtworkFile,
        index,
      } as S3UploadedImageFile;
    });
    // create list of pendingToAdd, pendingToUpdate and pendingToRemove of productArtworks
    const pendingToAdd = updatedImageFilestWithIndex.filter(
      (productArtworkFile) =>
        existingImageFiles.findIndex((image) => image.srcUrl === productArtworkFile.srcUrl) === -1,
    );
    const pendingToUpdate = updatedImageFilestWithIndex.filter(
      (productArtworkFile) =>
        existingImageFiles.findIndex((image) => image.srcUrl === productArtworkFile.srcUrl) !== -1,
    );
    const pendingToRemove = existingImageFiles.filter(
      (image) =>
        updatedImageFilestWithIndex.findIndex(
          (productArtworkFile) => productArtworkFile.srcUrl === image.srcUrl,
        ) === -1,
    );

    console.log('pendingToAdd', pendingToAdd);
    console.log('pendingToUpdate', pendingToUpdate);
    console.log('pendingToRemove', pendingToRemove);

    // Remove product artworks by updating is_removed to 1
    await Promise.all(
      pendingToRemove.map((productArtworkFile) => {
        if (productArtworkFile.productArtworkId) {
          return productService.updateProductArtwork({
            id: productArtworkFile.productArtworkId,
            is_removed: 1,
          });
        }
      }),
    );
    console.log('finish removing product artworks');

    // Update product artworks index
    await Promise.all(
      pendingToUpdate.map((productArtworkFile) => {
        if (productArtworkFile.productArtworkId) {
          return productService.updateProductArtwork({
            id: productArtworkFile.productArtworkId,
            index: productArtworkFile.index,
          });
        }
      }),
    );
    console.log('finish update product artworks');

    // Add product artworks
    await Promise.all(
      pendingToAdd.map(async (productArtworkFile) => {
        let artworkIdToUse = uuidv4();
        if (productArtworkFile.artworkId) {
          artworkIdToUse = productArtworkFile.artworkId;
        } else {
          const artwork = await artworkService.create({
            userId: user.id,
            srcUrl: productArtworkFile.srcUrl,
            thumbnailUrl: productArtworkFile.thumbnailUrl,
            height: productArtworkFile.height,
            width: productArtworkFile.width,
            title: productArtworkFile.title,
            description: productArtworkFile.description,
            artworkId: artworkIdToUse,
          });
        }
        // we will always create a new productArtwork. Existing productArtwork that has been removed
        // for the same product and artwork id will be there but is_removed will always be 1
        const productArtwork = await productService.createProductArtwork({
          artwork_id: artworkIdToUse,
          product_id: productId,
          index: productArtworkFile.index,
          is_removed: 0,
        });
      }),
    );
    console.log('finish saving product artworks');
  };

  /**
   * Helper function to update DDB based on the current inputs
   */
  const saveToDDB = async () => {
    let product = productToAddPrices;
    // Step 1: Create product or update product.display_name in DDB
    // If productToAddPrices exists, update its display name. Otherwise, create a new product
    if (productToAddPrices) {
      product = await productService.updateProductDisplayName({
        id: productToAddPrices.id,
        displayName: productDisplayName,
        categoryId,
      });
    } else {
      product = await productService.createProduct(
        user.id,
        productDisplayName,
        undefined,
        undefined,
        categoryId,
      );
    }
    // Step 2: Update product artworks
    await saveProductArtworksToDDB(product.id);
  };

  const handleRemoveImageFile = async (imageFileToRemove: S3UploadedImageFile) => {
    // Remove the image file from updatedImageFiles
    setUpdatedImageFiles((prevImageFiles) =>
      prevImageFiles.filter((imageFile) => imageFile.srcUrl !== imageFileToRemove.srcUrl),
    );
  };

  const validateDisplayNameInput = () => {
    // Validate input
    let isValid = true;
    // Check if product name is empty
    if (productDisplayName.trim() === '') {
      setProductDisplayNameErrorMessage('Tên loại tranh không được để trống.');
      isValid = false;
    }
    if (productDisplayName.length > SHORT_INPUT_TEXT_LENGTH) {
      setProductDisplayNameErrorMessage(
        `Tên loại tranh không được quá ${SHORT_INPUT_TEXT_LENGTH} ký tự.`,
      );
      isValid = false;
    }
    return isValid;
  };

  const onNextClick = async () => {
    // Validate input
    let isValid = validateDisplayNameInput();
    // Check if updatedImageFiles is empty
    if (updatedImageFiles.length === 0) {
      setUpdatedImageFilesErrorMessage('Bạn cần đính kèm ít nhất 1 sample.');
      isValid = false;
    }
    if (!isValid) return;

    // Button state
    setIsNextLoading(true);

    try {
      // Save current inputs to DDB
      await saveToDDB();

      await Promise.all([
        // Refetch productToAddPrices by invalidating the query
        queryClient.invalidateQueries([
          'productService',
          'getProductsByArtistIdStatus',
          user.id,
          ProductStatus.ACTIVE,
        ]),
        // Update User table
        userService.updateSellerOnboardingStage(
          user.id,
          SellerOnboardingStage.CreatePrices.toString(),
        ),
      ]);

      // Change loading state before changing stage to avoid unmounted setState
      setIsNextLoading(false);

      // Move to the next screen
      setStage(SellerOnboardingStage.CreatePrices);
    } catch (error) {
      captureException(error);
      // Change loading state before changing stage to avoid unmounted setState
      setIsNextLoading(false);
    }
  };

  const onBackClick = async () => {
    setIsBackLoading(true);
    try {
      // Update User table
      await userService.updateSellerOnboardingStage(
        user.id,
        SellerOnboardingStage.Intro.toString(),
      );
    } catch (error) {
      captureException(error);
    }

    // Change loading state before changing stage to avoid unmounted setState
    setIsBackLoading(false);
    // Navigate back to the previous screen
    setStage(SellerOnboardingStage.Intro);
  };

  const onExitClick = async () => {
    // Validate input (no need to upload images)
    const isValid = validateDisplayNameInput();
    if (!isValid) return;

    setIsExitLoading(true);

    try {
      // Save current inputs to DDB
      await saveToDDB();

      await Promise.all([
        // Invalidate the query to clear the cache in case user returns to
        // the onboarding flow
        queryClient.invalidateQueries([
          'productService',
          'getProductsByArtistIdStatus',
          user.id,
          ProductStatus.ACTIVE,
        ]),
        // Refresh user, so that the onboarding stage is updated in AuthProvider
        refreshUser(),
      ]);
    } catch (error) {
      captureException(error);
    }

    // Change loading state before changing stage to avoid unmounted setState
    setIsExitLoading(false);
    // Do not use router.back() here in case this is a new tab (no history)
    router.push('/');
  };

  const handleOpenCategorySelectionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setCategoryAnchorEl(event.currentTarget);
  };
  const handleCloseCategorySelectionMenu = () => {
    setCategoryAnchorEl(null);
  };

  return (
    <div>
      <WizardHeader handleOnExitClick={onExitClick} isExitLoading={isExitLoading} />
      <div className="flex w-full justify-center pb-24">
        <div className="flex-col w-152">
          <h1 className="text-4xl mb-8 font-medium">Bạn muốn mở loại commission nào?</h1>
          <p className="text-l mb-4 text-content-t200">
            Bạn có thể sửa tên và samples bất cứ lúc nào.
          </p>
          <div className="flex flex-col mt-8 mb-4 space-y-4">
            <div className="flex flex-col">
              <label className="text-m font-semibold mb-2">Tên loại tranh</label>
              <ProductNameFreeSoloInput
                value={productDisplayName}
                setValue={setProductDisplayName}
                errorMessage={productDisplayNameErrorMessage}
                setErrorMessage={setProductDisplayNameErrorMessage}
                isInOnboarding
              />
            </div>
            <div className="flex flex-col">
              <label className="text-m font-semibold mb-2">Phân loại</label>
              <div className="w-full">
                <button
                  className="rounded-lg border border-1 border-greyscale-g3
                    hover:shadow-input-focus py-2 w-full text-left px-3"
                  onClick={(e) => handleOpenCategorySelectionMenu(e)}
                >
                  <span className="text-s-tall">{category_id_display_name_map[categoryId]}</span>
                </button>
              </div>
              <Menu
                anchorEl={categoryAnchorEl}
                open={isCategorySelectionMenuOpen}
                onClose={handleCloseCategorySelectionMenu}
                onClick={handleCloseCategorySelectionMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.20))',
                    mt: 1,
                    borderRadius: '8px',
                    maxHeight: '252px',
                  },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              >
                <div className="flex flex-col max-w-lg w-screen">
                  {Object.entries(category_id_display_name_map).map(
                    ([categoryId, categoryDisplayName]) => (
                      <button
                        key={categoryId}
                        className="text-s py-2 pl-3 pr-6 hover:bg-greyscale-g1 w-full text-left"
                        onClick={() => setCategoryId(categoryId)}
                      >
                        {categoryDisplayName}
                      </button>
                    ),
                  )}
                </div>
              </Menu>
            </div>
            <div className="flex flex-col mt-10">
              <label className="text-m font-semibold mb-2">Samples</label>
              {user && (
                <div className="flex flex-col space-y-2">
                  {updatedImageFiles.length > 0 && (
                    <div className="grid grid-cols-3 gap-3">
                      {updatedImageFiles.map((image) => (
                        <div key={image.srcUrl} className="relative">
                          <div className="relative w-full h-40 rounded-lg overflow-hidden">
                            <Image
                              objectFit="cover"
                              src={image.srcUrl || ''}
                              loader={customImageLoader}
                              alt="New job preview photo"
                              layout="fill"
                              sizes="200px"
                            />
                          </div>
                          <button
                            className="absolute -top-1.5 -right-1.5 flex items-center justify-center p-0.5
                      rounded-full bg-white border border-greyscale-g2 z-10 hover:shadow-md"
                            onClick={() => {
                              handleRemoveImageFile(image);
                            }}
                          >
                            <Image src="/ic-close.svg" alt="Remove file" height={12} width={12} />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                  {!!updatedImageFilesErrorMessage && (
                    <span className="text-s text-status-error">
                      {updatedImageFilesErrorMessage}
                    </span>
                  )}
                  <div>
                    <UploadProductArtworkButton
                      existingImageList={updatedImageFiles}
                      setImageList={setUpdatedImageFiles}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <WizardBottomAppBar
          progressValue={30}
          handleOnBackClick={onBackClick}
          isBackLoading={isBackLoading}
          handleOnNextClick={onNextClick}
          isNextLoading={isNextLoading}
        />
      </div>
    </div>
  );
}

export default CreateProducts;
