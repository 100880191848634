import {
  Artwork,
  ArtworkByBuyerIdIsRemovedQuery,
  ArtworkByBuyerIdIsRemovedQueryVariables,
  ArtworkByIsRemovedPublicationDateTimeQuery,
  ArtworkByIsRemovedPublicationDateTimeQueryVariables,
  ArtworkByUserIdQuery,
  ArtworkByUserIdQueryVariables,
  CreateArtworkInput,
  CreateArtworkMutation,
  CreateArtworkMutationVariables,
  GetArtworkQuery,
  GetArtworkQueryVariables,
  ModelIntKeyConditionInput,
  ModelSortDirection,
  UpdateArtworkInput,
  UpdateArtworkMutation,
  UpdateArtworkMutationVariables,
} from '@src/API';
import {
  customArtworkByIsRemovedPublicationDateTimeForSearch,
  customArtworkByUserId,
  customGetArtwork,
} from '@src/customGraphql/customArtworkQueries';
import { createArtwork, updateArtwork } from '@src/graphql/mutations';
import { artworkByBuyerIdIsRemoved } from '@src/graphql/queries';
import { ListData } from '@src/libs/models';
import captureException from '@src/services/loggerService';
import { API } from 'aws-amplify';
import axios from 'axios';

export interface IArtworkRepo {
  get(artworkId: string): Promise<Artwork | null | undefined>;
  getListArtwork(limit: number, nextToken?: string): Promise<ListData<Artwork>>;
  create(artwork: CreateArtworkInput): Promise<Artwork>;
  update(artwork: UpdateArtworkInput): Promise<Artwork>;

  getListArtworkBySeller({
    sellerId,
    nextToken,
    limit,
  }: {
    sellerId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Artwork>>;
  getListArtworkByBuyer({
    buyerId,
    nextToken,
    limit,
  }: {
    buyerId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Artwork>>;

  getListArtworkByTag({
    sessionId,
    tagId,
    limit,
    nextToken,
  }: {
    sessionId: string;
    tagId: string;
    limit: number;
    nextToken?: string;
  }): Promise<ListData<Artwork> | null>;
}

export class RealArtworkRepo implements IArtworkRepo {
  public async get(artworkId: string): Promise<Artwork | null | undefined> {
    const variables: GetArtworkQueryVariables = {
      id: artworkId,
    };
    const res = (await API.graphql({
      query: customGetArtwork,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: GetArtworkQuery };

    return res.data.getArtwork as Artwork | null | undefined;
  }

  public async getListArtwork(limit: number, nextToken?: string): Promise<ListData<Artwork>> {
    const variables: ArtworkByIsRemovedPublicationDateTimeQueryVariables = {
      limit: limit,
      nextToken,
      is_removed: 0,
      sortDirection: ModelSortDirection.DESC,
    };

    const res = (await API.graphql({
      query: customArtworkByIsRemovedPublicationDateTimeForSearch,
      authMode: 'AWS_IAM',
      variables,
    })) as {
      data: ArtworkByIsRemovedPublicationDateTimeQuery;
    };

    const result: ListData<Artwork> = {
      list: res.data.artworkByIsRemovedPublicationDateTime?.items as Artwork[],
      nextToken: res.data.artworkByIsRemovedPublicationDateTime?.nextToken as string,
    };

    return result;
  }

  public async getListArtworkBySeller({
    sellerId,
    nextToken,
    limit,
  }: {
    sellerId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Artwork>> {
    const limitQuery = limit || 500;
    const conditionInput: ModelIntKeyConditionInput = {
      eq: 0,
    };
    const variables: ArtworkByUserIdQueryVariables = {
      limit: limitQuery as number,
      nextToken,
      user_id: sellerId,
      is_removed: conditionInput,
      filter: {
        // Use ne: 0 to include is_seller_showcase = null/undefined (when seller posts on portfolio)
        // and is_seller_show_case = 1 (when seller showcases artwork from contract)
        is_seller_showcase: { ne: 0 },
      },
    };

    const res = (await API.graphql({
      query: customArtworkByUserId,
      authMode: 'AWS_IAM',
      variables,
    })) as {
      data: ArtworkByUserIdQuery;
    };

    const sortedArtworks = res.data.artworkByUserId?.items?.sort((a, b) => {
      const aDate = new Date(a?.publication_date_time || '');
      const bDate = new Date(b?.publication_date_time || '');
      return bDate.getTime() - aDate.getTime();
    });

    const result: ListData<Artwork> = {
      list: sortedArtworks as Artwork[],
      nextToken: res.data.artworkByUserId?.nextToken as string,
    };

    return result;
  }

  public async getListArtworkByBuyer({
    buyerId,
    nextToken,
    limit,
  }: {
    buyerId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Artwork>> {
    const limitQuery = limit || 500;
    const conditionInput: ModelIntKeyConditionInput = {
      eq: 0,
    };
    const variables: ArtworkByBuyerIdIsRemovedQueryVariables = {
      limit: limitQuery as number,
      nextToken,
      buyer_id: buyerId,
      is_removed: conditionInput,
      filter: {
        // Use ne: 0 to include is_buyer_showcase = null/undefined (when buyer posts on their collection)
        // and is_buyer_show_case = 1 (when buyer showcases artwork from contract)
        is_buyer_showcase: { ne: 0 },
      },
    };

    const res = (await API.graphql({
      query: artworkByBuyerIdIsRemoved,
      authMode: 'AWS_IAM',
      variables,
    })) as {
      data: ArtworkByBuyerIdIsRemovedQuery;
    };

    const sortedArtworks = res.data.artworkByBuyerIdIsRemoved?.items?.sort((a, b) => {
      const aDate = new Date(a?.publication_date_time || '');
      const bDate = new Date(b?.publication_date_time || '');
      return bDate.getTime() - aDate.getTime();
    });

    const result: ListData<Artwork> = {
      list: sortedArtworks as Artwork[],
      nextToken: res.data.artworkByBuyerIdIsRemoved?.nextToken as string,
    };

    return result;
  }

  public async create(artwork: CreateArtworkInput): Promise<Artwork> {
    const variables: CreateArtworkMutationVariables = {
      input: artwork,
    };

    let res = { data: {} } as { data: CreateArtworkMutation };
    try {
      res = (await API.graphql({
        query: createArtwork,
        authMode: 'AWS_IAM',
        variables,
      })) as { data: CreateArtworkMutation };
    } catch (e) {
      captureException(e);
    }

    return res.data.createArtwork as Artwork;
  }

  public async update(artwork: UpdateArtworkInput): Promise<Artwork> {
    const variables: UpdateArtworkMutationVariables = {
      input: artwork,
    };

    let res = { data: {} } as { data: UpdateArtworkMutation };
    try {
      res = (await API.graphql({
        query: updateArtwork,
        authMode: 'AWS_IAM',
        variables,
      })) as { data: UpdateArtworkMutation };
    } catch (e) {
      captureException(e);
    }

    return res.data.updateArtwork as Artwork;
  }

  public async getListArtworkByTag({
    sessionId,
    tagId,
    limit,
    nextToken,
  }: {
    sessionId: string;
    tagId: string;
    limit: number;
    nextToken?: string;
  }): Promise<ListData<Artwork> | null> {
    const url = `${process.env.NEXT_PUBLIC_API_GATEWAY_BASE_URL || ''}/list-artworks-by-tag`;
    const res = await axios.get(url, {
      params: {
        sessionId,
        tagId,
        limit,
        nextToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const parseData = res.data as ListData<Artwork>;

    const filteredList = parseData?.list?.filter((artwork: Artwork) => {
      // Filter out field cover_image_src_url is not GIF
      const isGif = artwork.cover_image_src_url?.toLowerCase().includes('gif') || false;
      return !isGif;
    });

    const total = filteredList?.length || 0;
    const result: ListData<Artwork> = {
      list: total > 0 ? filteredList : undefined,
      nextToken: parseData?.nextToken,
      allTags: parseData?.allTags,
    };
    return result;
  }
}
