import PrimaryInputField from '@components/input/PrimaryInputField';
import DEFAULT_PRODUCT_NAME_LIST from '@src/utils/products';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

const Menu = dynamic(() => import('@mui/material/Menu'));
const unselectedOptionClass = 'flex hover:bg-greyscale-g1 py-1 px-4 text-s-tall';
const selectedOptionClass = 'flex bg-primary-p4 text-white py-1 px-4 text-s-tall';

type ProductNameFreeSoloInputProps = {
  value: string;
  setValue: (value: string) => void;
  errorMessage: string;
  setErrorMessage: (value: string) => void;
  isInOnboarding?: boolean;
};

/**
 * A free solo autocomplete input field for product display name. As user types,
 * it will show a dropdown of default product names that match the user input.
 *
 * References: https://mui.com/material-ui/react-autocomplete/#free-solo
 */
function ProductNameFreeSoloInput(props: ProductNameFreeSoloInputProps) {
  const { value, setValue, errorMessage, setErrorMessage, isInOnboarding } = props;
  const [filteredDisplayNames, setFilteredDisplayNames] = useState<string[]>([]);
  const [selectedDisplayName, setSelectedDisplayName] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // Open menu when the anchorEl is present, and there are filteredDisplayNames
  const isMenuOpen = Boolean(anchorEl) && filteredDisplayNames.length > 0;

  // On input click, set the anchorEl to the input element
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // To close the menu, set anchorEl to null
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  /**
   * Handler when user selects a dropdown option.
   */
  const onSelectDefaultDisplayName = (defaultName: string) => {
    // Set anchorEl to null to close
    setAnchorEl(null);
    // Update the value to the selected dropdown name
    setValue(defaultName);
    // Clear error message
    setErrorMessage('');
    // Store the selectedDisplayName state
    setSelectedDisplayName(defaultName);
  };

  /**
   * Handler when user types
   */
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Set the anchorEl to the input element
    setAnchorEl(e.currentTarget);
    // Clear the selectedDisplayName state
    setSelectedDisplayName('');
    // Clear error message
    setErrorMessage('');
    // Update the value to the user input
    const { value } = e.target;
    setValue(value);
  };

  // Update the filtered list of product names, depending on the textSearch
  useEffect(() => {
    // If there is no anchorEl, just return
    if (!anchorEl) {
      return;
    }
    // If the text input is empty, show all default product names
    if (!value) {
      setFilteredDisplayNames(DEFAULT_PRODUCT_NAME_LIST);
      return;
    }
    // If user has selected a dropdown option, show all default product names
    if (selectedDisplayName) {
      setFilteredDisplayNames(DEFAULT_PRODUCT_NAME_LIST);
      return;
    }

    const filteredList = DEFAULT_PRODUCT_NAME_LIST.filter((productName) => {
      return productName.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredDisplayNames(filteredList);
  }, [value, selectedDisplayName, anchorEl]);

  return (
    <div>
      <PrimaryInputField
        type="text"
        placeholder="Chọn từ danh sách, hoặc nhập tên mới"
        value={value}
        onChange={onInputChange}
        onClick={onClick}
        sizeType="xs"
        errorMessage={errorMessage}
      />
      <Menu
        anchorEl={anchorEl}
        id="product-name-free-solo-input-menu"
        open={isMenuOpen}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        disableAutoFocus // Need this to keep the focus in the input field when menu opens
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.20))',
            mt: 1,
            borderRadius: '8px',
            maxHeight: '252px',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div
          className={
            isInOnboarding ? 'grid grid-cols-1 w-152' : 'grid grid-cols-1 max-w-lg w-screen'
          }
        >
          {filteredDisplayNames.map((defaultName) => {
            const isSelected = defaultName === selectedDisplayName;
            return (
              <button
                key={defaultName}
                onClick={() => onSelectDefaultDisplayName(defaultName)}
                className={isSelected ? selectedOptionClass : unselectedOptionClass}
              >
                {defaultName}
              </button>
            );
          })}
        </div>
      </Menu>
    </div>
  );
}

export default ProductNameFreeSoloInput;
