import { useCallback, useEffect, useRef } from 'react';

/**
 * A hook to avoid React state update on an unmounted component.
 *
 * Reference: https://usehooks-ts.com/react-hook/use-is-mounted
 */
export default function useIsMounted() {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return useCallback(() => isMountedRef.current, []);
}
