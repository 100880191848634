export const customArtworkByIsRemovedPublicationDateTime = /* GraphQL */ `
  query ArtworkByIsRemovedPublicationDateTime(
    $is_removed: Int!
    $publication_date_time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArtworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    artworkByIsRemovedPublicationDateTime(
      is_removed: $is_removed
      publication_date_time: $publication_date_time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        likes_count
        publication_date_time
        is_removed
      }
      nextToken
    }
  }
`;

export const customArtworkByIsRemovedPublicationDateTimeForSearch = /* GraphQL */ `
  query ArtworkByIsRemovedPublicationDateTime(
    $is_removed: Int!
    $publication_date_time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArtworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    artworkByIsRemovedPublicationDateTime(
      is_removed: $is_removed
      publication_date_time: $publication_date_time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        likes_count
        publication_date_time
        user_id
        user {
          id
          display_name
          username
          is_artist
          profile_picture_url
          discount_code
        }
        tag_items {
          items {
            id
            tag_id
            tag {
              id
              tag_name
              display_name
              status
              total_artwork_quantity
              tag_artwork_items {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            artwork_id
            tag_type
            created_at
            updated_at
            __typename
          }
          nextToken
          __typename
        }
        is_removed
      }
      nextToken
    }
  }
`;

export const customArtworkByUserId = /* GraphQL */ `
  query ArtworkByUserId(
    $user_id: ID!
    $is_removed: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArtworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    artworkByUserId(
      user_id: $user_id
      is_removed: $is_removed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        user {
          id
          display_name
          first_name
          last_name
          username
          email_address
          is_artist
          profile_picture_url
          cover_picture_url
          is_selling
          created_at
          updated_at
          __typename

          seller_star_quantity
          seller_review_quantity
          buyer_star_quantity
          buyer_review_quantity
          follower_quantity
          leader_quantity
        }
        product_artworks {
          items {
            id
            product_id
            is_removed
            index
            product {
              id
              display_name
              slug
              status
              created_at
            }
          }
          nextToken
          __typename
        }
        is_buyer_showcase
        is_seller_showcase
        contract_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const customArtworkByBuyerIdIsRemoved = /* GraphQL */ `
  query ArtworkByBuyerIdIsRemoved(
    $buyer_id: ID!
    $is_removed: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArtworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    artworkByBuyerIdIsRemoved(
      buyer_id: $buyer_id
      is_removed: $is_removed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        user {
          id
          display_name
          first_name
          last_name
          username
          email_address
          is_artist
          profile_picture_url
          cover_picture_url
          is_selling
          created_at
          updated_at
          __typename

          seller_star_quantity
          seller_review_quantity
          buyer_star_quantity
          buyer_review_quantity
          follower_quantity
          leader_quantity
        }
        product_artworks {
          items {
            id
            product_id
            is_removed
            index
            product {
              id
              display_name
              slug
              status
              created_at
            }
          }
          nextToken
          __typename
        }
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const customGetArtwork = /* GraphQL */ `
  query GetArtwork($id: ID!) {
    getArtwork(id: $id) {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        # first_name
        # last_name
        username
        email_address
        is_artist
        profile_picture_url
        cover_picture_url
        is_selling
        created_at
        updated_at
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        follower_quantity
        leader_quantity
        __typename

        # contract_paid_quantity
        # commision_started_quantity
        # commission_completed_quantity
        # commission_completed_on_time_quantity
        # commission_cancelled_seller_fault_quantity
        # commission_cancelled_buyer_fault_quantity
        # commission_cancelled_mutually_quantity
        # commission_cumulative_late_seconds
        # commission_max_late_seconds
      }
      buyer_id
      buyer {
        id
        display_name
        # first_name
        # last_name
        username
        email_address
        is_artist
        profile_picture_url
        cover_picture_url
        is_selling
        created_at
        updated_at
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        follower_quantity
        leader_quantity
        __typename

        # contract_paid_quantity
        # commision_started_quantity
        # commission_completed_quantity
        # commission_completed_on_time_quantity
        # commission_cancelled_seller_fault_quantity
        # commission_cancelled_buyer_fault_quantity
        # commission_cancelled_mutually_quantity
        # commission_cumulative_late_seconds
        # commission_max_late_seconds
      }
      is_removed
      # images {
      #   items {
      #     id
      #     height
      #     width
      #     src_url
      #     thumbnail_url
      #     file_name
      #     artwork_id
      #     job_id
      #     milestone_id
      #     revision_id
      #     revision_request_revision_id
      #     message_id
      #     created_at
      #     updated_at
      #     __typename
      #   }
      #   nextToken
      #   __typename
      # }
      # likes {
      #   items {
      #     id
      #     artwork_id
      #     user_id
      #     is_removed
      #     created_at
      #     updated_at
      #     __typename
      #   }
      #   nextToken
      #   __typename
      # }
      product_artworks {
        items {
          id
          product_id
          artwork_id
          is_removed
          index
          created_at
          updated_at
          __typename
        }
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        # publication_date_time
        is_private_artwork
        is_comercial_artwork
        # file_formats
        # description
        # amount
        # centi_amount
        # currency_code
        # term_of_service
        state_name
        # current_milestone_id
        # current_milestone_index
        job_id
        job {
          id
          title
          description
          # min_budget
          # min_centi_budget
          # max_budget
          # max_centi_budget
          # currency_code
          status
          is_exclusive
          # publication_date_time
          # deadline_date_time
          # expiration_date_time
          is_comercial_artwork
          is_private_artwork
          # file_format
          buyer_id
          seller_id
          contacted_seller_ids
          created_at
          updated_at
          __typename
        }
        proposal_id
        proposal {
          id
          job_id
          seller_id
          seller_comment
          status
          publication_date_time
          proposal_type
          created_at
          updated_at
          __typename
        }
        buyer_id
        buyer {
          id
          display_name
          first_name
          last_name
          username
          email_address
          is_artist
          profile_picture_url
          cover_picture_url
          is_selling
          created_at
          updated_at
          seller_star_quantity
          seller_review_quantity
          buyer_star_quantity
          buyer_review_quantity
          follower_quantity
          leader_quantity
          created_at
          updated_at
          __typename
        }
        seller_id
        seller {
          id
          display_name
          first_name
          last_name
          username
          email_address
          is_artist
          profile_picture_url
          cover_picture_url
          is_selling
          created_at
          updated_at
          seller_star_quantity
          seller_review_quantity
          buyer_star_quantity
          buyer_review_quantity
          follower_quantity
          leader_quantity
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        items {
          id
          tag_id
          tag {
            id
            tag_name
            display_name
            status
            total_artwork_quantity
            created_at
            updated_at
            __typename
          }
          artwork_id
          tag_type
          created_at
          updated_at
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
