import { Message } from '@src/API';
import axios from 'axios';
import Pusher, { Channel } from 'pusher-js';

export interface IPusherClient {
  joinChannel(channelName: string): Channel;
  leaveChannel(channelName: string): void;
  disconnect(): void;
  sendMessage(receiverId: string, message: Message): Promise<void>;
}

export class RealPusherClient implements IPusherClient {
  private pusher: Pusher;

  constructor() {
    this.pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_APP_KEY || '', {
      cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER || '',
      forceTLS: true,
      userAuthentication: {
        transport: 'ajax',
        endpoint: '/api/pusher/auth-user',
      },
      channelAuthorization: {
        transport: 'ajax',
        endpoint: '/api/pusher/auth-channel',
      },
    });
    this.pusher.signin();
    this.pusher.connection.bind('private-general', () => {
      console.log('Pusher connected');
    });
    // TODO: Remove on Production
    // Pusher.logToConsole = true;
  }

  public joinChannel(channelName: string) {
    return this.pusher.subscribe(channelName);
  }

  public leaveChannel(channelName: string) {
    return this.pusher.unsubscribe(channelName);
  }

  public disconnect() {
    return this.pusher.disconnect();
  }

  public async sendMessage(receiverId: string, message: Message): Promise<void> {
    const data = {
      receiver_id: receiverId,
      message,
    };
    return await axios.post('/api/inbox/send-message', data, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
  }
}
