import { Channel, Image, Message, UserChannel } from '@src/API';
import { ListData } from '@src/libs/models';
import { useAuthState } from '@src/providers/AuthProvider';
import {
  getGetUserChannelsByUserIdQueryKeys,
  getListMessagesByChannelIdQueryKeys,
  useGetUserChannelsByUserIdQuery,
  useListMessagesByChannelIdInfiniteQuery,
} from '@src/services/chatHooks';
import { RealChatService } from '@src/services/chatService';
import captureException from '@src/services/loggerService';
import { RealUserService } from '@src/services/userService';
import {
  isUserChannelRead,
  sortUserChannelsByLastMessagePublicationDateTime,
} from '@src/utils/commonFunction';
import {
  DELAY_HOURS_TO_SEND_UNREAD_MESSAGE_IN_HOURS,
  MESSAGE_LIMIT_TO_COUNT_AS_LATE_IN_HOURS,
  MESSAGE_RESPONSE_TIME_UPPER_BOUND_IN_HOURS,
  TIME_FROM_RECEIVER_REQUEST_TO_COUNT_IN_STATISTICS_IN_MINUTES,
} from '@src/utils/constants';
import { IPusherClient, RealPusherClient } from '@src/utils/pusherClient';
import { InfiniteData, QueryClient, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 } from 'uuid';

export type ChatContextValue = {
  // Data states
  userChannels: UserChannel[];
  isUserChannelsInitialLoading: boolean;
  widgetUserChannelIds: string[];
  selectedUserChannelId: string | undefined;
  messages: Message[];
  isMessagesInitialLoading: boolean;
  hasMessagesNextPage: boolean | undefined;
  isMessagesFetchingNextPage: boolean;
  pendingMessageIds: string[];
  unreadUserChannelsQuantity: number;
  // Core functions
  selectUserChannelByUserChannelId: (userChannelId: string) => Promise<void>;
  createOrSelectUserChannelByReceiverId: (receiverId: string) => Promise<UserChannel | undefined>;
  clearSelectedUserChannel: () => void;
  removeWidgetUserChannelId: (userChannelIdToRemove: string) => void;
  sendMessage: ({
    messageId,
    text,
    images,
    userChannel,
  }: {
    messageId?: string;
    text: string;
    images: Image[];
    userChannel?: UserChannel;
  }) => Promise<Message | undefined>;
  fetchMessagesNextPage: () => void;
};

const defaultValue = {
  // Data states
  userChannels: [],
  isUserChannelsInitialLoading: false,
  widgetUserChannelIds: [],
  selectedUserChannelId: undefined,
  messages: [],
  isMessagesInitialLoading: false,
  hasMessagesNextPage: false,
  isMessagesFetchingNextPage: false,
  pendingMessageIds: [],
  unreadUserChannelsQuantity: 0,
  // Core functions
  selectUserChannelByUserChannelId: async () => {
    return;
  },
  createOrSelectUserChannelByReceiverId: async () => {
    return undefined;
  },
  clearSelectedUserChannel: () => {
    return;
  },
  removeWidgetUserChannelId: () => {
    return;
  },
  sendMessage: async () => {
    return undefined;
  },
  fetchMessagesNextPage: () => {
    return;
  },
};

const ChatContext = createContext<ChatContextValue>(defaultValue);

export default function ChatProvider({ children }: { children: ReactNode }) {
  // const messagesEndRef = useRef<HTMLDivElement>();
  const { user, isVerifiedSessionToken } = useAuthState();

  /* States */
  // The ids of the userChannels shown in the chat widget
  const [widgetUserChannelIds, setWidgetUserChannelIds] = useState<string[]>([]);
  // The id of the userChannel currently selected
  const [selectedUserChannelId, setSelectedUserChannelId] = useState<string>();
  // Pending message ids (messages that are being sent)
  const [pendingMessageIds, setPendingMessageIds] = useState<string[]>([]);

  /** Services */
  const chatService = useMemo(() => new RealChatService(), []);
  const userService = useMemo(() => new RealUserService(), []);

  /** Data fetching using query hooks */
  // Fetch list of user channels and derive other channel-related data
  const {
    data: userChannelsData,
    isInitialLoading: isUserChannelsInitialLoading, // Use isInitialLoading for the first time loading
  } = useGetUserChannelsByUserIdQuery(user?.id);
  // Derive userChannels, selectedUserChannel, selectedChannelId from userChannelsData;
  // use useMemo because these are used as dependencies in other logics.
  const userChannels = useMemo(() => {
    // Sort userChannels by the last message 's publication date time, most recent first
    const userChannels = sortUserChannelsByLastMessagePublicationDateTime(
      userChannelsData?.list || [],
      'DESC',
    );
    return userChannels;
  }, [userChannelsData?.list]);
  const { selectedUserChannel, selectedChannelId } = useMemo(() => {
    const selectedUserChannel = userChannels.find((userChannel) => {
      return userChannel.id === selectedUserChannelId;
    });
    const selectedChannelId = selectedUserChannel?.channel_id;
    return {
      userChannels,
      selectedUserChannel,
      selectedChannelId,
    };
  }, [userChannels, selectedUserChannelId]);

  // Fetch messages of selected channel id using infinite query
  const {
    data: messagesInfiniteData,
    isInitialLoading: isMessagesInitialLoading,
    hasNextPage: hasMessagesNextPage,
    isFetchingNextPage: isMessagesFetchingNextPage,
    fetchNextPage: fetchMessagesNextPage,
  } = useListMessagesByChannelIdInfiniteQuery({
    channelId: selectedChannelId,
  });
  // Derive messages from messagesInfiniteData: use useMemo because messages are used as
  // dependencies in other logics.
  const messages = useMemo(
    () =>
      (messagesInfiniteData?.pages || [])
        .map((page) => page.list || [])
        .flat()
        .reverse(),
    [messagesInfiniteData?.pages],
  ); // Reverse so that the latest message is at the bottom;

  /** Client */
  // Pusher client to send message: Initialize only when isVerifiedSessionToken is true
  const pusherClient = useMemo(() => {
    if (isVerifiedSessionToken) {
      const client: IPusherClient = new RealPusherClient();
      return client;
    }
  }, [isVerifiedSessionToken]);
  // Query client to update data
  const queryClient = useQueryClient();

  // Count the number of unread user channels
  const unreadUserChannelsQuantity = (userChannelsData?.list || []).filter((userChannel) => {
    return !isUserChannelRead(user, userChannel);
  }).length;

  /******************************************
   * Core functions
   ******************************************/

  /**
   * Select an existing user channel, fetch messages, and update the last read date time.
   */
  const selectUserChannelByUserChannelId = async (userChannelId: string) => {
    // First, find if a userChannel exists
    const userChannel = userChannels.find((userChannel) => userChannel.id === userChannelId);
    if (!userChannel) {
      captureException(new Error(`Cannot find userChannel with id: ${userChannelId}`));
      return;
    }
    // Update selectedUserChannelId; the react query hook will automatically
    // fetch the messages of the channel
    setSelectedUserChannelId(userChannelId);
    // Update widgetUserChannelIds
    addWidgetUserChannelId(userChannelId);

    // Update the update_date_time of the userChannel to reflect the last read
    // date time
    await saveUserChannelLastReadDateTime(userChannelId);
  };

  /**
   * Create a new user channel and select it.
   */
  const createOrSelectUserChannelByReceiverId = async (receiverId: string) => {
    // If user is not logged in, do nothing
    if (!user?.id) return;
    const userChannel = await chatService.startChat(user.id, receiverId);
    // console.log('QQ: userChannel', userChannel);
    // Update query data in useGetUserChannelsByUserIdQuery: If the new user channel
    // is already in the list, do nothing. Otherwise, add it to userChannels
    queryClient.setQueryData(
      getGetUserChannelsByUserIdQueryKeys(user?.id),
      (oldUserChannelsData: ListData<UserChannel> | null | undefined) => {
        const oldUserChannels = oldUserChannelsData?.list || [];

        // console.log('QQ: oldUserChannels', oldUserChannels);

        // const existingUserChannel = oldUserChannels.find((item) => userChannel.id === item.id);

        // console.log('QQ: existingUserChannel', existingUserChannel);

        // Check if the new user channel is already in the list
        const isUserChannelInList = oldUserChannels.some((item) => userChannel.id === item.id);
        // console.log('QQ: isUserChannelInList', isUserChannelInList);

        if (isUserChannelInList) return oldUserChannelsData;
        // If not, add it to the list at index 0
        return {
          ...oldUserChannelsData,
          list: [userChannel, ...(oldUserChannels || [])],
        };
      },
    );

    // Update selectedUserChannelId
    setSelectedUserChannelId(userChannel.id);

    // Update widgetUserChannelIds
    addWidgetUserChannelId(userChannel.id);

    // Update the update_date_time of the userChannel to reflect the last read
    // date time
    await saveUserChannelLastReadDateTime(userChannel.id);
    return userChannel;
  };

  /**
   * Unselect the selected user channel. Use useCallback because /inbox has an useEffect
   * that depends on this function.
   */
  const clearSelectedUserChannel = useCallback(() => {
    setSelectedUserChannelId(undefined);
  }, []);

  /**
   * Remove a given user channel id from the list of widget user channel ids.
   */
  const removeWidgetUserChannelId = (userChannelIdToRemove: string) => {
    // Update widgetUserChannelIds to remove the user channel id
    setWidgetUserChannelIds((prev) => {
      const newList = prev.filter((userChannelId) => userChannelId !== userChannelIdToRemove);
      return newList;
    });
    // If the user channel is selected, unselect it
    if (selectedUserChannelId === userChannelIdToRemove) {
      clearSelectedUserChannel();
    }
  };

  /**
   * Send a message to either the selected user channel or a given user channel.
   */
  const sendMessage = async ({
    messageId,
    text,
    images,
    userChannel,
  }: {
    messageId?: string;
    text: string;
    images: Image[];
    userChannel?: UserChannel;
  }) => {
    try {
      // Store the last message before this message
      const channelLastMessage: Message | undefined = messages[messages.length - 1];

      // Step 1: Send message
      // Get the sender's userChannel to use
      const messageUserChannel = userChannel || selectedUserChannel;
      // Validation: Check if userChannelToUse, user, pusherClient exist
      if (!messageUserChannel) throw new Error('userChannelToUse is empty.');
      if (!user) throw new Error('User is empty.');
      if (!pusherClient) throw new Error('Misisng pusherClient.');

      // Validation: Check if message content is empty
      let messageContent = text;
      messageContent = messageContent?.replace(/<[^>]*>?/gm, '').trim();
      if (!messageContent && images.length === 0) throw new Error('Message content is empty.');

      // Get receiverId
      const receiverId = messageUserChannel.channel?.user_channels?.items?.find(
        (userChannel) => userChannel?.user?.id !== user?.id,
      )?.user_id;
      if (!receiverId) throw new Error('receiverId is empty.');

      const msgId = messageId || v4().toString();
      // Create Message
      const message: Message = {
        __typename: 'Message',
        id: msgId,
        channel_id: messageUserChannel.channel_id,
        sender_id: user.id,
        content: messageContent,
        image_url: '',
        images: {
          __typename: 'ModelImageConnection',
          items: images,
        },
        publication_date_time: new Date().toISOString(),
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      // Optimistically update messagesInfiniteData: Add new message to index 0 of the first page
      optimisticallyAddNewMessageToMessages(queryClient, message, selectedChannelId, false);
      // Optimistically update userChannelsData: Update update_date_time and last message
      // TODO (long): Revert the optimistic update if the message fails to send
      queryClient.setQueryData(
        getGetUserChannelsByUserIdQueryKeys(user?.id),
        (oldUserChannelsData: ListData<UserChannel> | null | undefined) => {
          const oldUserChannels = oldUserChannelsData?.list || [];
          const newUserChannels = oldUserChannels.map((userChannel) => {
            if (userChannel.id === messageUserChannel.id) {
              const newChannel = {
                ...userChannel.channel,
                messages: {
                  __typename: 'ModelMessageConnection',
                  items: [message],
                },
              } as Channel;
              return {
                ...userChannel,
                channel: newChannel,
                update_date_time: message.publication_date_time,
              };
            }
            return userChannel;
          });
          return {
            ...oldUserChannelsData,
            list: newUserChannels,
          };
        },
      );

      // Set pending message id
      setPendingMessageIds((prev) => [...prev, message.id]);

      // Send message via Pusher
      await pusherClient.sendMessage(receiverId, message);

      // Remove message from the list of pending messages
      setPendingMessageIds((prev) => {
        return prev.filter((item) => item !== message.id);
      });

      // Step 2: Update user channels' data and sender's response statistics

      // We have to refetch sender and receiver's userChannels to get the
      // latest data (because each user can update both userChannels)
      const userChannelsToUse = await chatService.getUserChannelsByChannelIdUserId({
        channelId: messageUserChannel.channel_id,
      });
      const receiverUserChannelToUse = userChannelsToUse.list?.find(
        (userChannel) => userChannel.user_id === receiverId,
      );
      const senderUserChannelToUse = userChannelsToUse.list?.find(
        (userChannel) => userChannel.user_id === user.id,
      );

      if (!receiverUserChannelToUse || !senderUserChannelToUse) {
        throw new Error('receiverUserChannel or senderUserChannel is null');
      }

      // Update data in parallel
      await Promise.all([
        updateReceiverNextTimeShouldReceiveAnEmail({
          receiverUserChannel: receiverUserChannelToUse,
          message: message,
        }),
        updateUserChannelsRequestData({
          senderUserChannel: senderUserChannelToUse,
          receiverUserChannel: receiverUserChannelToUse,
          message: message,
          channelLastMessage: channelLastMessage,
        }),
        updateSenderResponseStatistics({
          receiverUserChannel: receiverUserChannelToUse,
          message: message,
          channelLastMessage: channelLastMessage,
        }),
      ]);

      return message;
    } catch (error) {
      captureException(error);
    }
  };

  /**********************************
   * Helper functions
   **********************************/

  /**
   * Helper function to add new message to messagesInfiniteData.
   *
   * @param checkDuplicate Whether to check if the message already exists the data.
   */
  const optimisticallyAddNewMessageToMessages = useCallback(
    (
      queryClient: QueryClient,
      message: Message,
      channelId: string | null | undefined,
      checkDuplicate?: boolean,
    ) => {
      queryClient.setQueryData(
        getListMessagesByChannelIdQueryKeys(channelId),
        (oldInfiniteData: InfiniteData<ListData<Message>> | undefined) => {
          // If we need to check duplicate, and the message already exists in the data, do nothing
          if (checkDuplicate) {
            const hasDuplicate = oldInfiniteData?.pages.some((page) =>
              page.list?.some((item) => item.id === message.id),
            );
            if (hasDuplicate) return oldInfiniteData;
          }
          // Insert the new message to the first page
          const firstPage = oldInfiniteData?.pages[0];
          if (!firstPage) return oldInfiniteData;
          // Otherwise, simply add the message to the first page
          const newFirstPage = {
            ...firstPage,
            list: [message, ...(firstPage.list || [])],
          };
          // Update the first page
          const newMessagesInfiniteData = {
            ...oldInfiniteData,
            pages: [newFirstPage, ...(oldInfiniteData?.pages || []).slice(1)],
          };
          return newMessagesInfiniteData;
        },
      );
    },
    [],
  );

  /**
   * Helper function to save the last read time of a user channel.
   */
  const saveUserChannelLastReadDateTime = async (userChannelId: string) => {
    const userChannel = userChannels.find((userChannel) => userChannel.id === userChannelId);
    if (!userChannel) return;
    try {
      await chatService.saveDateTimeReadMessage(userChannel.id);
      // In the userChannels state, update the update_date_time of the
      // corresponding UserChannel to reflect the last read date time
      queryClient.setQueryData(
        getGetUserChannelsByUserIdQueryKeys(user?.id),
        (oldUserChannelsData: ListData<UserChannel> | null | undefined) => {
          const oldUserChannels = oldUserChannelsData?.list || [];
          const newUserChannels = (oldUserChannels || []).map((userChannel) => {
            if (userChannel.id === selectedUserChannelId) {
              return {
                ...userChannel,
                update_date_time: new Date().toISOString(),
              };
            }
            return userChannel;
          });
          return {
            ...oldUserChannelsData,
            list: newUserChannels,
          };
        },
      );
    } catch (error) {
      captureException(error);
    }
  };

  /**
   * Helper function to store the timestamp to receive email reminder for the receiver channel.
   * This timestamp = the message date time + delayHours.
   *
   * At this timestamp, we will send an email reminder to the receiver channel, if
   * the receiver has not read the message yet.
   *
   * TODO (long): Move this to the backend
   */
  const updateReceiverNextTimeShouldReceiveAnEmail = async ({
    receiverUserChannel,
    message,
  }: {
    receiverUserChannel: UserChannel;
    message: Message;
  }) => {
    // Save next_time_should_receive_an_email = message.publication_date_time + delayHours
    const nextTimeShouldReceiveAnEmail = receiverUserChannel.next_time_should_receive_an_email;
    // Only set a datetime to send email if nextTimeShouldReceiveAnEmail does
    // not already exist (This is because once receiver reads the message,
    // receiverUserChannel.next_time_should_receive_an_email will be cleared)
    if (!nextTimeShouldReceiveAnEmail) {
      const publicationDateTime = dayjs(message.publication_date_time);
      const delayHours = Number(
        process.env.NEXT_PUBLIC_DELAY_HOURS_TO_SEND_UNREAD_MESSAGE ||
          DELAY_HOURS_TO_SEND_UNREAD_MESSAGE_IN_HOURS,
      );
      const shouldReceiveEmailDateTime = publicationDateTime.add(delayHours, 'hours');
      await chatService.updateNextTimeShouldReceiveAnEmail({
        receiverUserChannelId: receiverUserChannel.id,
        dateTime: shouldReceiveEmailDateTime.toISOString(),
      });
    }
  };

  /**
   * Helper function to update request_start_date_time and next_time_should_receive_a_late_response
   * for sender's and receiver's channels.
   *
   * If the channel's last message is sent by the receiver, or if message is the
   * first message in the channel, then do the following:
   *
   * 1) set senderUserChannel.request_start_date_time to message.publication_date_time,
   * 2) clear senderUserChannel.next_time_should_receive_a_late_response,
   * 3) clear receiverUserChannel.request_start_date_time (because sender has responded to receiver's request)
   * 4) set receiverUserChannel.next_time_should_receive_an_email = message.publication_date_time + 72 hours
   *
   * Otherwise, do nothing (because sender's request_start_date_time is already set).
   *
   * TODO (long): Move this to the backend
   */
  const updateUserChannelsRequestData = async ({
    senderUserChannel,
    receiverUserChannel,
    message,
    channelLastMessage,
  }: {
    senderUserChannel: UserChannel;
    receiverUserChannel: UserChannel;
    message: Message;
    channelLastMessage: Message | null | undefined;
  }) => {
    const messagePublicationDateTime = dayjs(message.publication_date_time);
    if (!channelLastMessage || channelLastMessage?.sender_id === receiverUserChannel.user_id) {
      // Update senderUserChannel
      const updateSenderUserChannelPromise = chatService.updateUserChannel({
        id: senderUserChannel.id,
        request_start_date_time: message.publication_date_time,
        next_time_should_receive_a_late_response: null,
      });
      // Update receiverUserChannel
      const shouldReceiveLateResponseDateTime = messagePublicationDateTime.add(
        MESSAGE_RESPONSE_TIME_UPPER_BOUND_IN_HOURS,
        'hours',
      );
      const updateReceiverUserChannelPromise = chatService.updateUserChannel({
        id: receiverUserChannel.id,
        request_start_date_time: null,
        // Set an upper bound to count as late response
        next_time_should_receive_a_late_response: shouldReceiveLateResponseDateTime.toISOString(),
      });
      // Run in parallel
      await Promise.all([updateSenderUserChannelPromise, updateReceiverUserChannelPromise]);
    }
  };

  /**
   * Helper function to update sender's response statistics. This happens if:
   *
   * 1) the channel's last message is sent by the receiver
   * 2) receiverUserChannel has request_start_date_time (should automatically be true because of condition b)
   * 3) the sender is an artist
   * 4) difference between message.publication_date_time and receiverUserChannel.request_start_date_time >= 15 mins
   *
   * Condition 4) is necessary because we don't want to count every messages
   * back and forth between 2 users (which would inflate the on-time statistics).
   * The ideal implementation is to define a "conversation" concept, and calculate
   * late response statistics by conversations. However, because it is difficult to achieve,
   * we use a proximation of only counting messages sent 15 minutes after the other user sent.
   *
   * TODO (long): Move this to the backend
   */
  const updateSenderResponseStatistics = async ({
    receiverUserChannel,
    message,
    channelLastMessage,
  }: {
    receiverUserChannel: UserChannel;
    message: Message;
    channelLastMessage: Message | null | undefined;
  }) => {
    const messagePublicationDateTime = dayjs(message.publication_date_time);
    const receiverRequestStartDateTime = dayjs(receiverUserChannel.request_start_date_time);
    const diffFromReceiverRequestSeconds = messagePublicationDateTime.diff(
      receiverRequestStartDateTime,
      'second',
    );

    // Condition 1: the channel's last message is sent by the receiver
    if (channelLastMessage?.sender_id !== receiverUserChannel.user_id) return;
    // Condition 2: receiverUserChannel has request_start_date_time
    if (!receiverUserChannel.request_start_date_time) return;
    // Condition 3: the sender is an artist
    if (!user?.isArtist) return;
    // Condition 4: difference between message.publication_date_time and
    // receiverUserChannel.request_start_date_time >= 15 mins
    if (
      diffFromReceiverRequestSeconds <
      TIME_FROM_RECEIVER_REQUEST_TO_COUNT_IN_STATISTICS_IN_MINUTES * 60
    ) {
      return;
    }

    // Fetch user data
    const senderUser = await userService.getUser(user.id);

    // Calculate the updated quantities
    const currentTotalResponseSeconds = senderUser?.message_cumulative_response_seconds || 0;
    const newTotalResponseSeconds = currentTotalResponseSeconds + diffFromReceiverRequestSeconds;
    const currentResponseOnTimeCount = senderUser?.message_response_on_time_quantity || 0;
    const currentResponseLateCount = senderUser?.message_response_late_quantity || 0;
    // If the response time is less than 24 hours, we count it as on time
    const isOnTime =
      diffFromReceiverRequestSeconds < MESSAGE_LIMIT_TO_COUNT_AS_LATE_IN_HOURS * 60 * 60;
    const newResponseOnTimeCount = isOnTime
      ? currentResponseOnTimeCount + 1
      : currentResponseOnTimeCount;
    const newResponseLateCount = isOnTime ? currentResponseLateCount : currentResponseLateCount + 1;
    // Update user
    await userService.updateUser({
      id: user.id,
      message_cumulative_response_seconds: newTotalResponseSeconds,
      message_response_on_time_quantity: newResponseOnTimeCount,
      message_response_late_quantity: newResponseLateCount,
    });
  };

  /**
   * Helper function to add userChannelId to widgetUserChannelIds.
   */
  const addWidgetUserChannelId = (userChannelId: string) => {
    setWidgetUserChannelIds((prev) => {
      // Check if the user channel id is already in widget
      const isUserChannelInWidget = prev.some((prevUserChannelId) => {
        return prevUserChannelId === userChannelId;
      });
      if (isUserChannelInWidget) {
        return prev;
      }

      return [userChannelId, ...prev];
    });
  };

  /**********************************
   * Pusher listener
   **********************************/

  /**
   * Subscribe to Pusher channel to listen to new messages from both other users and
   * the current user. Subscription to the current user is necessary to sync user's own
   * messages across devices & tabs.
   */
  useEffect(() => {
    if (!user || !pusherClient) {
      return;
    }
    const chnsStr = `private-${user.id}`;
    const channel = pusherClient?.joinChannel(chnsStr);
    channel?.bind('client-message', (data: any) => {
      if (data as Message) {
        // If data in selectedChannelId, update messagesInfiniteData
        if (data.channel_id === selectedChannelId) {
          optimisticallyAddNewMessageToMessages(queryClient, data, selectedChannelId, true);
        }

        // Check if data is not in any existing channel
        const isMessageInUserChannels = userChannels.some((userChannel) => {
          return userChannel.channel_id === data.channel_id;
        });
        // If the data is not present, it means a new user sends message, so refresh userChannels
        if (!isMessageInUserChannels) {
          queryClient.invalidateQueries(getGetUserChannelsByUserIdQueryKeys(user?.id));
          return;
        }
        // If the data is present, overwrite the last message in the corresponding userChannel
        // if its publication_date_time is more recent. This is to update the last message
        // in userChannels list.
        queryClient.setQueryData(
          getGetUserChannelsByUserIdQueryKeys(user?.id),
          (oldUserChannelsData: ListData<UserChannel> | null | undefined) => {
            const oldUserChannels = oldUserChannelsData?.list || [];
            const newUserChannels = oldUserChannels.map((userChannel) => {
              if (userChannel.channel_id !== data.channel_id) return userChannel;
              // If data.publication_date_time is not more recent, overwrite the last message
              const dataDateTime = dayjs(data.publication_date_time);
              const lastMessage = (userChannel.channel?.messages?.items || [])[0];
              const lastMessageDateTime = dayjs(lastMessage?.publication_date_time);
              if (!lastMessage || dataDateTime.isBefore(lastMessageDateTime)) return userChannel;
              // Otherwise, overwrite the last message
              return {
                ...userChannel,
                channel: {
                  ...userChannel.channel,
                  messages: {
                    __typename: 'ModelMessageConnection',
                    items: [data],
                  },
                } as Channel,
              };
            });
            return {
              ...oldUserChannelsData,
              list: newUserChannels,
            };
          },
        );
      }
    });

    return () => {
      channel.unbind('client-message');
    };
  }, [
    pusherClient,
    queryClient,
    user,
    userChannels,
    selectedChannelId,
    optimisticallyAddNewMessageToMessages,
  ]);

  /* Context value to return */
  const stateValues = {
    // Data states
    userChannels,
    isUserChannelsInitialLoading,
    widgetUserChannelIds,
    selectedUserChannelId,
    messages,
    isMessagesInitialLoading,
    hasMessagesNextPage,
    isMessagesFetchingNextPage,
    pendingMessageIds,
    unreadUserChannelsQuantity,
    // Core functions
    selectUserChannelByUserChannelId,
    createOrSelectUserChannelByReceiverId,
    clearSelectedUserChannel,
    removeWidgetUserChannelId,
    sendMessage,
    fetchMessagesNextPage,
  };
  return <ChatContext.Provider value={stateValues}>{children}</ChatContext.Provider>;
}

export const useChatState = (): ChatContextValue => {
  const context = useContext(ChatContext);
  if (!context) {
    throw Error('Use useChatState in ChatProvider');
  }
  return context;
};
