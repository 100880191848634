import {
  CreateProductArtworkInput,
  CreateProductArtworkMutation,
  CreateProductArtworkMutationVariables,
  CreateProductInput,
  CreateProductMutation,
  CreateProductMutationVariables,
  DeleteProductMutationVariables,
  GetProductQuery,
  GetProductQueryVariables,
  ListProductsQuery,
  ListProductsQueryVariables,
  Product,
  ProductArtwork,
  ProductArtworkByProductIdQuery,
  ProductArtworkByProductIdQueryVariables,
  ProductByArtistIdStatusQuery,
  ProductByArtistIdStatusQueryVariables,
  ProductBySlugQuery,
  ProductBySlugQueryVariables,
  UpdateProductArtworkInput,
  UpdateProductArtworkMutation,
  UpdateProductArtworkMutationVariables,
  UpdateProductInput,
  UpdateProductMutation,
  UpdateProductMutationVariables,
} from '@src/API';
import {
  customGetProduct,
  customListAllProducts,
  customListProducts,
  customProductBySlug,
} from '@src/customGraphql/customProductQueries';
import { customProductByArtistIdStatus } from '@src/customGraphql/customQueries';
import {
  createProduct,
  createProductArtwork,
  deleteProduct,
  updateProduct,
  updateProductArtwork,
} from '@src/graphql/mutations';
import { productArtworkByProductId } from '@src/graphql/queries';
import { ListData } from '@src/libs/models';
import captureException from '@src/services/loggerService';
import { API } from 'aws-amplify';
import axios from 'axios';

export interface IProductRepo {
  get(id: string): Promise<Product | null>;
  create(product: CreateProductInput): Promise<Product>;
  update(product: UpdateProductInput): Promise<Product>;
  delete(id: string): Promise<void>;
  getProductBySlug(slug: string): Promise<Product | null>;
  getProductsByArtistIdStatus(artistId: string, status?: string): Promise<ListData<Product>>;
  getListProductRandomize({
    sessionId,
    filterIsSelling,
    filterMinPrice,
    filterMaxPrice,
    limit,
    categoryId,
    isFixedSessionResult,
    page,
  }: {
    sessionId: string;
    filterIsSelling: string;
    filterMinPrice: number;
    filterMaxPrice: number;
    limit: number;
    categoryId: string;
    isFixedSessionResult: string;
    page: number;
  }): Promise<ListData<Product> | null>;
  // ProductArtwork
  createProductArtwork(input: CreateProductArtworkInput): Promise<ProductArtwork>;
  getProductArtworksByProductId(
    productId: string,
    is_removed: number,
    nextToken?: string,
  ): Promise<ListData<ProductArtwork>>;
  updateProductArtwork(
    updateProductArtworkInput: UpdateProductArtworkInput,
  ): Promise<ProductArtwork | null>;
  list(limit?: number, nextToken?: string): Promise<ListData<Product>>;
  listAllProducts(): Promise<Product[]>;
}

export class RealProductRepo implements IProductRepo {
  public async get(id: string): Promise<Product | null> {
    const variables: GetProductQueryVariables = {
      id: id,
    };
    const res = (await API.graphql({
      query: customGetProduct,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: GetProductQuery };

    if (!res.data.getProduct) return null;

    return res.data.getProduct as Product;
  }

  public async create(product: CreateProductInput): Promise<Product> {
    const variables: CreateProductMutationVariables = {
      input: product,
    };

    let res = { data: {} } as { data: CreateProductMutation };
    try {
      res = (await API.graphql({
        query: createProduct,
        authMode: 'AWS_IAM',
        variables,
      })) as { data: CreateProductMutation };
    } catch (e) {
      captureException(e);
    }

    return res.data.createProduct as Product;
  }

  public async update(product: UpdateProductInput): Promise<Product> {
    const variables: UpdateProductMutationVariables = {
      input: product,
    };

    const res = (await API.graphql({
      query: updateProduct,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: UpdateProductMutation };

    return res.data.updateProduct as Product;
  }

  public async delete(id: string) {
    const variables: DeleteProductMutationVariables = {
      input: { id: id },
    };

    await API.graphql({
      query: deleteProduct,
      authMode: 'AWS_IAM',
      variables,
    });
  }

  public async getProductBySlug(slug: string): Promise<Product | null> {
    const variables: ProductBySlugQueryVariables = {
      slug: slug,
    };

    const res = (await API.graphql({
      query: customProductBySlug,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: ProductBySlugQuery };

    if (!!res.data.productBySlug?.items?.length && !!res.data.productBySlug.items[0]) {
      // If found more than 1 product with the same slug, log error
      if (res.data.productBySlug.items.length > 1) {
        captureException(`Found more than 1 product with slug ${slug}.`);
      }

      return res.data.productBySlug.items[0] as Product;
    }

    return null;
  }

  public async getProductsByArtistIdStatus(
    artistId: string,
    status?: string,
  ): Promise<ListData<Product>> {
    let variables: ProductByArtistIdStatusQueryVariables = {
      artist_id: artistId,
      status: { eq: status },
    };
    if (!status) {
      variables = {
        artist_id: artistId,
      };
    }
    const res = (await API.graphql({
      query: customProductByArtistIdStatus,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: ProductByArtistIdStatusQuery };

    // Filter out null items
    const filteredItems = (res.data.productByArtistIdStatus?.items || []).filter(
      (item) => item !== null,
    ) as Product[];
    // Sort by created_at
    filteredItems.sort((a, b) => {
      if (a.created_at === undefined || b.created_at === undefined) return 0;
      if (a.created_at < b.created_at) return 1;
      if (a.created_at > b.created_at) return -1;
      return 0;
    });

    const result: ListData<Product> = {
      list: filteredItems,
      nextToken: res.data.productByArtistIdStatus?.nextToken as string,
    };

    return result;
  }

  public async createProductArtwork(input: CreateProductArtworkInput): Promise<ProductArtwork> {
    const variables: CreateProductArtworkMutationVariables = {
      input,
    };

    const res = (await API.graphql({
      query: createProductArtwork,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: CreateProductArtworkMutation };

    return res.data.createProductArtwork as ProductArtwork;
  }

  public async getProductArtworksByProductId(
    productId: string,
    is_removed: number,
    nextToken?: string,
  ): Promise<ListData<ProductArtwork>> {
    const limit = 100;
    const variables: ProductArtworkByProductIdQueryVariables = {
      product_id: productId,
      is_removed: {
        eq: is_removed,
      },
      limit: limit,
      nextToken,
    };

    const res = (await API.graphql({
      query: productArtworkByProductId,
      authMode: 'AWS_IAM',
      variables,
    })) as {
      data: ProductArtworkByProductIdQuery;
    };

    // Filter out artwork with is_removed = 1
    const filteredItems = (res.data.productArtworkByProductId?.items || []).filter(
      (item) => item?.artwork?.is_removed !== 1,
    ) as ProductArtwork[];

    const result: ListData<ProductArtwork> = {
      list: filteredItems,
      nextToken: res.data.productArtworkByProductId?.nextToken as string,
    };

    return result;
  }

  public async updateProductArtwork(
    updateProductArtworkInput: UpdateProductArtworkInput,
  ): Promise<ProductArtwork> {
    const variables: UpdateProductArtworkMutationVariables = {
      input: updateProductArtworkInput,
    };

    const res = (await API.graphql({
      query: updateProductArtwork,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: UpdateProductArtworkMutation };

    return res.data.updateProductArtwork as ProductArtwork;
  }

  public async getListProductRandomize({
    sessionId,
    filterIsSelling,
    filterMinPrice,
    filterMaxPrice,
    limit,
    categoryId,
    isFixedSessionResult,
    page,
  }: {
    sessionId: string;
    filterIsSelling: string;
    filterMinPrice: number;
    filterMaxPrice: number;
    limit: number;
    categoryId: string;
    isFixedSessionResult: string;
    page: number;
  }): Promise<ListData<Product> | null> {
    const url = `${process.env.NEXT_PUBLIC_API_GATEWAY_BASE_URL || ''}/list-product-randomized`;
    console.log(
      'thanh0823 - getListProductRandomize',
      url,
      sessionId,
      limit,
      categoryId,
      isFixedSessionResult,
      page,
    );
    const res = await axios.get(url, {
      params: {
        sessionId,
        filterIsSelling,
        filterMinPrice,
        filterMaxPrice,
        limit,
        categoryId,
        isFixedSessionResult,
        page,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('thanh0823 - getListProductRandomize res', res);
    const parseData = res.data as ListData<Product>;

    const filteredList = parseData?.list;

    const total = filteredList?.length || 0;
    const result: ListData<Product> = {
      list: total > 0 ? filteredList : undefined,
      nextToken: parseData?.nextToken,
    };
    return result;
  }

  public async list(limit?: number, nextToken?: string): Promise<ListData<Product>> {
    const limitToUse = limit || 1000;
    const variables: ListProductsQueryVariables = {
      limit: limitToUse,
      nextToken,
    };
    const res = (await API.graphql({
      query: customListProducts,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: ListProductsQuery };

    const result: ListData<Product> = {
      list: (res.data.listProducts?.items || []) as Product[],
      nextToken: res.data.listProducts?.nextToken as string,
    };

    return result;
  }

  public async listAllProducts(): Promise<Product[]> {
    let nextToken: string | undefined = undefined;
    let result: Product[] = [];
    do {
      const limitToUse = 1000;
      const variables: ListProductsQueryVariables = {
        limit: limitToUse,
        nextToken,
      };
      const res = (await API.graphql({
        query: customListAllProducts,
        authMode: 'AWS_IAM',
        variables: variables,
      })) as { data: ListProductsQuery };

      result = result.concat(((res.data.listProducts?.items || []) as Product[]) || []);
      nextToken = res.data.listProducts?.nextToken as string;
    } while (nextToken);
    return result;
  }
}
