import Link from 'next/link';
import { MouseEvent, ReactNode } from 'react';

export type PrimaryButtonInputType = {
  children: ReactNode;
  isLink?: boolean;
  isOpenNewTab?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  href?: string;
  sizeType: 'xs' | 'sm' | 'md' | 'lg';
  isFullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
};

/**
 * Primary button component.
 *
 * There are 3 size types: xs, sm, md. If you want to make the button full
 * width, set isFullWidth to true.
 */
export default function PrimaryButton({
  children,
  isLink,
  isOpenNewTab = false,
  onClick,
  href,
  sizeType,
  isFullWidth = false,
  disabled = false,
  isLoading = false,
  ...props
}: PrimaryButtonInputType & React.HTMLAttributes<HTMLButtonElement>) {
  const baseClassString = `flex flex-row items-center justify-center font-semibold text-white rounded-md`;
  const baseSpinnerString =
    'animate-spin rounded-full border-2 border-white border-r-transparent absolute';

  const getCustomClassString = () => {
    switch (sizeType) {
      case 'xs':
        return `px-3 py-1.5 text-s`;
      case 'sm':
        return `px-3 py-1.5 text-m`;
      case 'md':
        return `px-4 py-2 text-m`;
      case 'lg':
        return `px-4 py-3 text-m`;
    }
  };

  const getCustomSpinnerString = () => {
    switch (sizeType) {
      case 'xs':
        return 'h-4 w-4';
      case 'sm':
        return 'h-5 w-5';
      case 'md':
        return 'h-6 w-6';
      case 'lg':
        return 'h-6 w-6';
    }
  };

  let className = `${baseClassString} ${getCustomClassString()}
    bg-primary-p4 hover:bg-primary-p5 disabled:bg-primary-p2`;
  const spinnerClassName = `${baseSpinnerString} ${getCustomSpinnerString()}`;

  if (isLoading) {
    className = `${className} disabled:bg-primary-p4`;
  }

  if (isFullWidth) {
    className = `${className} w-full`;
  }

  if (isLink && href && isOpenNewTab) {
    return (
      <Link href={href} passHref>
        <a
          href="replace"
          target="_blank"
          rel="noopener noreferrer"
          className={`${className} ${disabled ? 'pointer-events-none opacity-50' : ''}`}
          onClick={(e) => disabled && e.preventDefault()}
        >
          {isLoading ? <div className={spinnerClassName} /> : children}
        </a>
      </Link>
    );
  }

  if (isLink && href && !isOpenNewTab) {
    return (
      <Link href={href} passHref>
        <a
          href="replace"
          className={`${className} ${disabled ? 'pointer-events-none opacity-50' : ''}`}
        >
          {isLoading ? <div className={spinnerClassName} /> : children}
        </a>
      </Link>
    );
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      {...props}
      className={`${className} ${props.className || ''}`}
    >
      {/* If is loading, keep the children class invisible to keep the same button size */}
      <div className={isLoading ? 'invisible' : ''}>{children}</div>
      {/* Loading animation */}
      {isLoading && <div className={spinnerClassName} />}
    </button>
  );
}
