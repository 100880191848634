import WizardBottomAppBar from '@components/bottom/WizardBottomAppBar';
import WizardHeader from '@components/header/WizardHeader';
import PrimaryInputField from '@components/input/PrimaryInputField';
import { AuthContextValue, useAuthState } from '@providers/AuthProvider';
import { isValidName } from '@src/libs/validationUtil';
import { SellerOnboardingStage } from '@src/pages/become-a-seller';
import { DreamerlyUser } from '@src/providers/AuthProvider';
import captureException from '@src/services/loggerService';
import { IUserService, RealUserService } from '@src/services/userService';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getAge } from 'src/utils/dates';

type BetaUserProps = {
  user: DreamerlyUser;
  setStage: (stage: SellerOnboardingStage) => void;
  isRequestedArtistCode?: boolean;
};

const DISCOUNT_CODES = [
  '61EVS1LU',
  'TDEJX3RO',
  'F3WJE2QP',
  'D4ETGYOH',
  'CW723D03',
  'YO4ZEU3Y',
  'V4RBT1RA',
  '1KTK77M7',
  'SZCNBYFT',
  'X2EFLMBR',
  'B00RIHA2',
  'K8C7NDSZ',
  'LT3EHN9D',
  '127UEEGD',
  'MKSASO7C',
  'L02MH67Q',
  'M74I1WEI',
  'RRLZMPYS',
  'EVNZWMF1',
  'VEGS81NU',
  'OX9CDVWI',
  'IX9SHLG9',
  'GM6TWZF5',
  'AMRUWUD5',
  'UQ5LCU8D',
  'SAU7OIUG',
  'JYTGDXP2',
  'U270ZGAB',
  'JUIBHASG',
  'LEFAQZQT',
  'ZQLKZHKI',
  '89TMTKDB',
  'A7YHRCJ4',
  'H2AUS11I',
  'TF5W8H7S',
  'K2FECAOY',
  'NK52FMRK',
  'RZ9XN9EC',
  'NQZQ18V8',
  'H3R89YBZ',
  'NBK31MUJ',
  '2D7ZUYMG',
  'IY9OOOGW',
  'QDEMNEME',
  'VY9IGNJH',
  '57X44I9R',
  '13XQTOKN',
  'ISIWQEBB',
  'I1UG89NJ',
  'YKXGD8YX',
  'MX3ZBGLG',
  'IB2GRIT8',
  '5PIG7X4U',
  'HJKAHFQN',
  '8VS2TOVG',
  '9PJYVF4O',
  'Y82U05AO',
  '7DA6RJH2',
  '7NAG1K8W',
  'B2T94QGZ',
  'WFENOT5N',
  'ILYAGA83',
  'RFUN0X5W',
  '2Q884US2',
  '69YCWREN',
  'KV1YJJI8',
  '46C4QRZ2',
  'MNGVA17Q',
  'SXZZSX9J',
  'RKQH3MRZ',
  '13JDU35G',
  '9ZZUSM7R',
  'S9FXQJD3',
  '6WQWPQ7D',
  'KSRV6DZF',
  'RO41M9UA',
  'IZSNX61Z',
  '8ISOC4VO',
  '09V3O3SS',
  'GZXXTV9F',
  'TDGTACNT',
  'H6Q80JAN',
  'V9HCJ1BE',
  '0UGJ66PH',
  'K7571D19',
  '902SHLH0',
  'MZ3E7EVM',
  'OVCAUSQF',
  'YD15QTRK',
  '2K800L18',
  'MU0X8KW3',
  'NKURPLSW',
  'Z6K2VENS',
  'FZ4J6ODR',
  'S7GNG4N6',
  '2WDYTQ19',
  'GZLMRVKQ',
  'YBHTVDPZ',
  '6XNO4VAA',
  'EHFBCVZE',
  '8Z1K9NHN',
  'CB6VV0IR',
  'QJL7WLGA',
  'CS7GC76N',
  '9KX72S4H',
  'EPLILHLB',
  'H5HUVJ1U',
  'L9SG3CDF',
  '57A85EVJ',
  'TEU49HPY',
  'HZDUB8RM',
  'ZNW5UGZY',
  'GOSF8RK8',
  'Z6YJM2XB',
  'LB1WKKHW',
  '4ZP70RXH',
  '7RBAAHXJ',
  '4SD23EFK',
  '9IFB7BR2',
  'GS6EHFQW',
  '753NB6TZ',
  'FG0O9ROM',
  'BBA6H5VP',
  '0ZCW3F56',
  'W08FR29U',
  '3YTBV84H',
  'Q733U8FI',
  '2R6KTMSW',
  'KKYDFYTN',
  '8801BAPY',
  'PYDDWXP4',
  'OZGCCP1N',
  'PX7RVL37',
  'ECCTWYSQ',
  '0PEP5UR9',
  '4XK2SJTA',
  'YV6ZRTAK',
  'UBCOTTMM',
  '9CHXZYX2',
  'SAN5OEI6',
  'HL34KPFD',
  'Z9H0ZIHN',
  'BHMO0986',
  '76F33S6T',
  'S2B8HPZO',
  'WWOWV9JI',
  '5TO7RV0A',
  'V9R3FMWC',
  'LDBEX1VI',
  'EQ8LFTMY',
  'T2VHSBLR',
  'N95VSHE3',
  'V2HHJBFA',
  'J5XZ7RH5',
  '4NG2362Z',
  'P200QKWW',
  '2WWYDHEG',
  'P3ZCPCH1',
  'V23KD8XH',
  'CA2NHFLP',
  '2MFB9ORG',
  'IAB01MVK',
  'Z1W73WJ7',
  '5N11PML5',
  'IHUMKQ38',
  'UPJ7WKGO',
  '3S1WKDP9',
  'FRP7SK5M',
  '1NNS1AB6',
  'CSLTNA9Z',
  'SK99US7V',
  'LYJJO0FF',
  'XRJDRAMB',
  '419AZNVI',
  'EOOOU86R',
  'NS4XXR2L',
  'MUQK7608',
  'QTDNVJA6',
  'SQST7RH6',
  '5O8KHJ7Q',
  'BRGS55BL',
  'MN0SQJDD',
  'DJX6I1CA',
  '7MW5AXV3',
  'YQ7BMORY',
  'JYLWGRKB',
  '6YA57K2N',
  'X9VSDD42',
  '4YOUNGPG',
  '87MD25C9',
  '48GO3M86',
  '37S0NEDT',
  'RRVNAY1O',
  '55D6YFDB',
  'FWS2S3MO',
  'WWMKI5RO',
  'O6JJZHCY',
  'CPH64J5H',
  'WIXU34K6',
  'YNAIJJVO',
];

// 7-character codes for artists who we are not so sure about
const ON_THE_FENCE_DISCOUNT_CODES = [
  'NEINJZZ',
  'W3UH84K',
  'FXAMM6O',
  'UAX64IJ',
  'WYLGF3C',
  'H2FGXL5',
  '4H0PZ6U',
  'ZBDZ8MO',
  'RYYQOL5',
  '5CW2OD9',
  '7OZ9HNS',
  '3MJ2O0P',
  'YGUCIPU',
  'MQ9C0W5',
  'YBMAKUF',
  'ZX3YXLS',
  '3LS24JE',
  'KDS6401',
  'HVKTJ42',
  'Y8ANEAL',
  'INQWYUU',
  'BIFH9LP',
  'N4UJCFD',
  '1XWQ2T6',
  'JTJYI2H',
  'PYERFCC',
  'LVNVGVG',
  'HF676IM',
  'XLTBSK5',
  '8EAVA85',
  '9Z6L6S0',
  'HXZEVVR',
  'UI03FFR',
  'L0XA0EY',
  'FUNJXM2',
  '6LDJISW',
  '9A739S0',
  'H48YPTN',
  'MTZV6X5',
  'LAN8NZV',
  'PU8A05N',
  'L2Q9OTW',
  'PZ45GFO',
  '25UXV33',
  'HCG18SK',
  'P3NNS5R',
  'ACS9BQG',
  'XNJTKM2',
  'HXU6EU6',
  'WYBQDJI',
  '557ATB4',
  'ZRGPS42',
  'KT7TJ6N',
  '02PCFT0',
  'TXXLLAB',
  'CZC2IBE',
  '9LFG78D',
  'T3G4BD7',
  '6B0MFQF',
  '49HZURJ',
  'F37QWTZ',
  '96UYI9J',
  'XWOFU08',
  'V8WKSA4',
  'U6RE7GV',
  'KU2AED5',
  '6XJN8G0',
  'MDUUGKW',
  'BN7I97Z',
  'OR6PJU4',
  'XPF279Z',
  '6KRQD1J',
  'CGF46X2',
  'IIHTRX2',
  '7YDHM98',
  '9B0M85L',
  'RPPKSSE',
  'G5LX22F',
  'TG4JCS5',
  'WVBXLV9',
  'A8UST1V',
  'RVPI1HP',
  '3GYJH8U',
  'GJX7VZY',
  'D44YTDP',
  'O3Y0LB8',
  'AXVGGRZ',
  'NOU0RJK',
  'J7DZPRB',
  'MPY023J',
  'CXSBI5C',
  'ZNXKVMF',
  '0SJ3OKY',
  'KRGDPW3',
  'BE6QPKJ',
  'AEI7FGN',
  'JK7FDCT',
  'V2034SR',
  'D2AC532',
  'GE4ZXO6',
  '643R7YS',
  'TRMD4RC',
  '3FMA60P',
  'XUZI977',
  'PWYF4WT',
  'ULGGVWM',
  '9C561I4',
  '4F34P10',
  'P8G9HKZ',
  'SXNPBZF',
  '7YDM5FU',
  '7YMF4A8',
  '3GKDQIG',
  'TT01STT',
  'ITR2IOB',
  '18XUVHQ',
  'A3BSLPM',
  'GKDKAZM',
  '4JMOUKN',
  'XUXMT8A',
  'O9GFIAO',
  'B1N91M3',
  '5W6SHH4',
  'KB2LW1W',
  'JODSW8D',
  '3HZ4WDF',
  '7WLA7LS',
  'EEZ77FH',
  'DF6QVRH',
  'VZBFA8Z',
  'VGDNO82',
  'N57VJ5I',
  '0JIPCNN',
  'K60ZC3N',
  'WAI4BO8',
  'DBU7H66',
  'IP9Y2HQ',
  '3KOYCEX',
  'TM44F3F',
  'ZXW3CTY',
  '5RCBUWP',
  '6UWVOH1',
  '1INKCMM',
  'T8HZMFB',
  '62F7TQF',
  'KF2MI9Z',
  'OE2J2ZT',
  'XOO6SVH',
  '7VG6R3M',
  'B8UTOIQ',
  '48RSI5V',
  'PC4U2S5',
  'AU2T3H7',
  'DMA3GQX',
  'BXCUBLQ',
  'QVXH5JB',
  '1UILCFI',
  '9KYD6TK',
  'AU40RWB',
  'G8LH00G',
  'RO68LI5',
  'RSVO8AS',
  'O9FECB4',
  'FR034B3',
  'Y2GZ0FV',
  '6I1ZEPM',
  'FQRU8WP',
  'GKCKE8A',
  'ZIVL1S9',
  '86I4K2J',
  '5M1OO84',
  'XT9BWUO',
  'Z1LPZ1N',
  'YF8ZMHC',
  'NX6VXG8',
  '0TSCQNK',
  'WYTTDN3',
  'NQO9IL3',
  'U8N4WS8',
  'T0FXIEW',
  'PTYAPP8',
  'MFLTS7B',
  'HQIVJEC',
  '1EJURTJ',
  'BHHLPMW',
  'EPSPINR',
  '9CC8OPK',
  'RWNAEO5',
  '2AZVDAV',
  'LSUHF7O',
  'W3TU93K',
  'CRPHRR2',
  'LF31GX4',
  'D3T3M9J',
  'ZWCMIG4',
  '1EI2DLU',
  '103ALHK',
  'PHX3HQ3',
  'XF1H47O',
  '5STNMZE',
];

export enum BetaUserState {
  Loading = 'LOADING',
  RequestCode = 'REQUEST_CODE',
  RequestCodeCompleted = 'REQUEST_CODE_COMPLETED',
  AgeIsNotValid = 'AGE_IS_NOT_VALID',
}

function BetaUser(props: BetaUserProps) {
  const { user, setStage, isRequestedArtistCode } = props;
  const { refreshUser } = useAuthState() as AuthContextValue;

  // Header and BottomAppBar loading states
  const [title, setTitle] = useState('');
  const [isNextLoading, setIsNextLoading] = useState(false);

  const [discountCode, setDiscountCode] = useState(user.discount_code || '');
  const [discountCodeErrorMessage, setDiscountCodeErrorMessage] = useState('');
  const [birthday, setBirthday] = useState('');
  const [birthdayError, setBirthdayError] = useState('');

  const [currentBirthday, setCurrentBirthday] = useState('');

  const [screenState, setScreenState] = useState<BetaUserState>(BetaUserState.Loading);

  // Remove leading & trailing white spaces
  const discountCodeTrimmed = discountCode.trim();

  const router = useRouter();
  const userService: IUserService = new RealUserService();

  const inputClassName = `border border-1 border-greyscale-g3
    hover:shadow-input-focus focus:shadow-input-focus
    rounded-lg py-3 px-4 w-full
    placeholder:text-content-t300 outline-none`;
  const errorInputClassName = `border border-1 border-status-error
    hover:shadow-input-focus-error focus:shadow-input-focus-error
    rounded-lg py-3 px-4 w-full
    placeholder:text-content-t300 outline-none`;
  const errorMessageClassName = 'mt-2 text-s text-status-error';
  const inputMessageClassName = 'mt-2 text-s text-content-t300';

  const onDiscountCodeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(e.target.value);
    setDiscountCodeErrorMessage('');
  };

  /**
   * Helper function to save the current inputs to DDB
   */
  const saveToDDB = async () => {
    setDiscountCode(discountCodeTrimmed);
    await userService.updateUserDiscountCode(user.id, discountCodeTrimmed);
  };

  const onExitClick = async () => {
    router.push('/');
  };

  function isDiscountCode(code: string): boolean {
    // Check whether the code is in either list of discount codes
    if (code && code.length > 0) {
      return (
        DISCOUNT_CODES.includes(code.toUpperCase()) ||
        ON_THE_FENCE_DISCOUNT_CODES.includes(code.toUpperCase())
      );
    }
    return false;
  }

  const validateBirthday = (date: string) => {
    const today = new Date();
    const birthDate = new Date(date);
    return Boolean(birthDate < today);
  };

  const onNextClick = async () => {
    setDiscountCodeErrorMessage('');
    setBirthdayError('');

    // Button state
    setIsNextLoading(true);

    if (screenState === BetaUserState.AgeIsNotValid) {
      await updateDOB();
    } else {
      await updateSellerOnboardingStage();
    }

    // Change loading state before changing stage to avoid unmounted setState
    setIsNextLoading(false);
  };

  const updateDOB = async () => {
    // Check if birthday is valid
    if (!validateBirthday(birthday)) {
      setBirthdayError('Ngày sinh không hợp lệ.');
      return;
    }
    const userAge = getAge(birthday);
    if (userAge < 18) {
      setBirthdayError('Bạn chưa đủ 18 tuổi nên không thể tiếp tục.');
    }
    try {
      await userService.updateUserBirthday(user.id, `${birthday}Z`);
      await refreshUser();
    } catch (error) {
      captureException(error);
    }
  };

  const updateSellerOnboardingStage = async () => {
    if (!isValidName(discountCodeTrimmed)) {
      setDiscountCodeErrorMessage('Vui lòng điền mã giới thiệu.');
      return;
    }

    if (!isDiscountCode(discountCodeTrimmed)) {
      setDiscountCodeErrorMessage('Mã giới thiệu không chính xác.');
      return;
    }

    try {
      await saveToDDB();

      // Update User table to the next stage and navigate there
      await userService.updateSellerOnboardingStage(
        user.id,
        SellerOnboardingStage.Intro.toString(),
      );
      setStage(SellerOnboardingStage.Intro);
    } catch (error) {
      captureException(error);
    }
  };

  const onBackClick = async () => {
    // Navigate back to the previous page
    router.push('/');
  };

  /* Effects */

  useEffect(() => {
    if (!user.birthDate) {
      setScreenState(BetaUserState.AgeIsNotValid);
      return;
    }
    const parseCurrentBirthday = dayjs(new Date(user.birthDate)).format('DD/MM/YYYY');
    setCurrentBirthday(parseCurrentBirthday);
    // Validate user age is from 18 years old
    const userAge = getAge(user.birthDate);
    console.log('QQ userAge', {
      userAge,
      birthDate: user.birthDate,
    });
    const isValidAge = userAge >= 18;

    // Update State
    if (isValidAge) {
      setScreenState(
        isRequestedArtistCode ? BetaUserState.RequestCodeCompleted : BetaUserState.RequestCode,
      );
    } else {
      setScreenState(BetaUserState.AgeIsNotValid);
    }
  }, [user, isRequestedArtistCode]);

  useEffect(() => {
    switch (screenState) {
      case BetaUserState.AgeIsNotValid:
        setTitle('Giới hạn tuổi hoạ sĩ');
        break;
      case BetaUserState.RequestCode:
        setTitle('Mã tham gia của bạn là?');
        break;
      case BetaUserState.RequestCodeCompleted:
        setTitle('Mã tham gia của bạn là?');
        break;
      case BetaUserState.Loading:
        setTitle('Mã tham gia của bạn là?');
        break;
      default:
        break;
    }
  }, [screenState]);

  return (
    <div>
      <WizardHeader isHideExitButton={true} handleOnExitClick={onExitClick} isExitLoading={false} />
      <div className="flex w-full justify-center pb-24">
        <div className="flex-col w-152">
          <h1 className="text-4xl mb-4 font-medium">{title}</h1>

          {screenState === BetaUserState.AgeIsNotValid && (
            <p className="text-l mb-4 text-content-t200">
              Ở giai đoạn beta, Dreamerly chỉ cho phép các hoạ sĩ từ 18 tuổi trở lên được bán
              commission. Nếu ngày sinh bạn cung cấp ({currentBirthday}) chưa chính xác, bạn có thể
              sửa lại ở đây.
            </p>
          )}

          {screenState === BetaUserState.RequestCodeCompleted && (
            <p className="text-l mb-4 text-content-t200">
              Đội ngũ phát triển Dreamerly đang tiến hành review thông tin bạn cung cấp và sẽ gửi mã
              tham gia tới email của bạn trong thời gian sớm nhất nhé.
            </p>
          )}

          {screenState === BetaUserState.RequestCode && (
            <p className="text-l mb-4 text-content-t200">
              Nếu chưa có mã, bạn có thể đăng ký tại{' '}
              <button
                onClick={() => {
                  setStage(SellerOnboardingStage.RequestCode);
                }}
                className="text-primary-p4 hover:text-content-t100 font-semibold"
              >
                đây
              </button>
              . Chúng tôi sẽ gửi mã cho bạn trong thời gian sớm nhất.
            </p>
          )}

          {screenState === BetaUserState.AgeIsNotValid && (
            <PrimaryInputField
              label="Ngày sinh"
              type="date"
              placeholder="Ngày sinh"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
              sizeType="md"
              errorMessage={birthdayError}
              autoFocus={false}
            />
          )}

          {screenState !== BetaUserState.AgeIsNotValid && (
            <div className="flex flex-col mt-8 mb-4">
              <div className="grid grid-cols-1">
                <div className="flex flex-col">
                  <input
                    type="text"
                    className={discountCodeErrorMessage ? errorInputClassName : inputClassName}
                    autoFocus
                    required
                    placeholder="Mã tham gia"
                    onChange={(e) => onDiscountCodeInputChange(e)}
                    value={discountCode}
                  />
                  {discountCodeErrorMessage && (
                    <span className={errorMessageClassName}>{discountCodeErrorMessage}</span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <WizardBottomAppBar
        progressValue={0}
        handleOnBackClick={onBackClick}
        isBackLoading={false}
        handleOnNextClick={onNextClick}
        isNextLoading={isNextLoading}
      />
    </div>
  );
}

export default BetaUser;
