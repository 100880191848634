// eslint-disable-next-line max-classes-per-file
import HttpStatusCodes from '@libs/httpStatusCodes';

export class BaseHttpError extends Error {
  public errorCode!: number;

  public message!: string;
}

export class NotFoundError extends BaseHttpError {
  public constructor(message: string) {
    super();
    this.errorCode = HttpStatusCodes.NotFound;
    this.message = message;
  }
}

export class BadRequestError extends BaseHttpError {
  public constructor(message: string) {
    super();
    this.errorCode = HttpStatusCodes.BadRequest;
    this.message = message;
  }
}

export class UnauthorizedError extends BaseHttpError {
  public constructor(message: string) {
    super();
    this.errorCode = HttpStatusCodes.Unauthorized;
    this.message = message;
  }
}

export class PermissionError extends BaseHttpError {
  public constructor(message: string) {
    super();
    this.errorCode = HttpStatusCodes.Forbidden;
    this.message = message;
  }
}

export class ConflictError extends BaseHttpError {
  public constructor(message: string) {
    super();
    this.errorCode = HttpStatusCodes.Conflict;
    this.message = message;
  }
}

export class ServerError extends BaseHttpError {
  public constructor(message: string) {
    super();
    this.errorCode = HttpStatusCodes.InternalServerError;
    this.message = message;
  }
}
