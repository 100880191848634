import { Artwork, Product, User } from '@src/API';
import { ListData } from '@src/libs/models';
import { IUserService, RealUserService } from '@src/services/userService';
import { ALL_CATEGORY_ID } from '@src/utils/enums';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

/**
 * React Query hook to get user.
 */
export const useGetUserQuery = (userId: string | null | undefined) => {
  const userService: IUserService = new RealUserService();
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['userService', 'getUser', userId],
    queryFn: async () => {
      if (!userId) return null; // Have to return null, not undefined
      return await userService.getUser(userId);
    },
    enabled: !!userId,
  });
};

export const useGetUserDetailQuery = (
  userId: string | null | undefined,
  initialData: { user: User; artworks: ListData<Artwork>; products: ListData<Product> },
) => {
  const userService: IUserService = new RealUserService();
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['userService', 'getUserDetail', userId],
    queryFn: async () => {
      if (!userId) return null; // Have to return null, not undefined
      return await userService.getUserDetail(userId);
    },
    initialData,
    enabled: !!userId,
  });
};

/**
 * React Query hook to get user's bank and address information.
 */
export const useGetUserSecretInfoQuery = (userId: string | null | undefined) => {
  const userService: IUserService = new RealUserService();
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['userService', 'getUserSecretInfo', userId],
    queryFn: async () => {
      if (!userId) return null; // Have to return null, not undefined
      return await userService.getUserSecretInfo(userId);
    },
    enabled: !!userId,
  });
};

export const useGetUserDetailByUsernameQuery = (
  username: string | null | undefined,
  initialData: { user: User; artworks: ListData<Artwork>; products: ListData<Product> },
) => {
  const userService: IUserService = new RealUserService();
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['userService', 'getUserDetailByUsername', username],
    queryFn: async () => {
      console.log('thanh2 username', username);
      if (!username) return null; // Have to return null, not undefined
      const result = await userService.getUserDetailByUsername(username);
      console.log('thanh2 result', result);
      return result;
    },
    initialData,
    enabled: !!username,
  });
};

export const useGetListUserQuery = ({
  initialData,
  enabled,
}: {
  initialData?: ListData<User>;
  enabled: boolean;
}) => {
  const userService: IUserService = new RealUserService();
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['userService', 'getListUser'],
    queryFn: async () => {
      return await userService.getListUser();
    },
    initialData,
    enabled,
  });
};

export const useListAllUsersQuery = ({
  initialData,
  enabled,
}: {
  initialData?: User[];
  enabled: boolean;
}) => {
  const userService: IUserService = new RealUserService();
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['userService', 'listAllUsers'],
    queryFn: async () => {
      return await userService.listAllUsers();
    },
    initialData,
    enabled,
  });
};

export const useListUsersRandomizedQuery = (
  sessionIdWithCategoryId: string,
  limit: number,
  initialData: User[],
) => {
  const userService: IUserService = new RealUserService();
  return useInfiniteQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['userService', 'getListArtistRandomize', sessionIdWithCategoryId],
    queryFn: async ({ pageParam }) => {
      console.log('QQ: listProductsRandomized - pageParam', pageParam);
      const [sessionId, categoryId] = sessionIdWithCategoryId.split('_');
      const data = await userService.getListArtistRandomize({
        sessionId,
        limit,
        categoryId: categoryId.toLowerCase(),
      });
      // If data is undefined, return list with undefined to make sure
      // the query is not re-triggered
      if (data?.list === undefined || data?.list === null) {
        const listData: ListData<User> = {
          list: undefined,
          nextToken: undefined,
        };
        return listData;
      }

      return data;
    },
    getNextPageParam: (lastPage) => {
      const canLoadMore = lastPage?.list !== null && lastPage?.list !== undefined;
      return canLoadMore;
    },
    initialData: () => {
      /* 
        If sessionIdWithCategoryId include _ALL then we will append initialData 
        to the first page
      */
      const data: ListData<User> = {
        list: initialData,
        nextToken: undefined,
      };
      if (sessionIdWithCategoryId.includes(ALL_CATEGORY_ID)) {
        return { pages: [data], pageParams: [] };
      }
      /*
        Otherwise, return empty data and 
        let the query fetch the first page for specific category
      */
      const emptyData: ListData<User> = {
        list: [],
        nextToken: undefined,
      };
      return { pages: [emptyData], pageParams: [] };
    },
    enabled: !!sessionIdWithCategoryId,
    /*
      If we don't set staleTime because when user open this page, 
      tanstack will not include first page is initialData
    */
    staleTime: 500,
  });
};
