import { NotFoundError } from '@libs/httpErrors';
import { Artwork, Product, UpdateUserInput, User } from '@src/API';
import { ProductStatus } from '@src/customGraphql/customModels';
import { ListData } from '@src/libs/models';
import { SellerOnboardingStage } from '@src/pages/become-a-seller';
import { IArtworkRepo, RealArtworkRepo } from '@src/repositories/artworkRepo';
import { IProductRepo, RealProductRepo } from '@src/repositories/productRepo';
import { IUserRepo, RealUserRepo } from '@src/repositories/userRepo';
import BlacklistedUserIds from '@src/utils/blacklist';
import captureException from './loggerService';

export interface IUserService {
  getUser(userId: string): Promise<User | undefined>;
  getUserByUsername(username: string): Promise<User | null>;
  getUserDetail(userId: string): Promise<{
    user: User;
    artworks: ListData<Artwork>;
    products: ListData<Product>;
  }>;
  getUserDetailByUsername(username: string): Promise<{
    user: User;
    artworks: ListData<Artwork>;
    products: ListData<Product>;
  }>;
  getUserSecretInfo(userId: string): Promise<User | undefined>;
  getListUser(limit?: number, nextToken?: string): Promise<ListData<User> | null>;
  getListUserByListUserId(userIds: string[]): Promise<ListData<User> | null>;
  listAllUsers(): Promise<User[]>;
  updateUserProfilePhoto(userId: string, photoUrl: string): Promise<User>;
  updateUserCoverPhoto(userId: string, photoUrl: string): Promise<User>;
  updateUserInfo(
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    countryCode: string,
    subscriberNumber: string,
    phoneNumber: string,
    isSelling: number,
  ): Promise<void>;
  updateUserFirstName(userId: string, firstName: string): Promise<User>;
  updateUserLastName(userId: string, lastName: string): Promise<User>;
  updateSellerOnboardingStage(userId: string, stage: string): Promise<User>;
  updateIsSelling(userId: string, isSelling: boolean): Promise<User>;
  updateUserDiscountCode(userId: string, discountCode: string): Promise<User>;
  updateUserAddress({
    userId,
    address,
    city,
  }: {
    userId: string;
    address: string;
    city: string;
  }): Promise<User>;
  updateUserLastTimeOpenListNotification(userId: string): Promise<User>;
  updateUserReadTheLatestCommissions(userId: string): Promise<User>;
  updateUserReadTheLatestJobs(userId: string): Promise<User>;
  updateUserTermOfService(userId: string, termOfService: string): Promise<User>;

  updateUserAdditionalInfo({
    userId,
    firstName,
    lastName,
    email,
    subscriberNumber,
    phoneNumber,
    city,
    address,
    facebookProfileUrl,
    portfolioUrl,
    isSelling,
    countryCode,
    isArtist,
  }: {
    userId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    subscriberNumber?: string;
    phoneNumber?: string;
    city?: string;
    address?: string;
    facebookProfileUrl?: string;
    portfolioUrl?: string;
    isSelling?: number;
    countryCode?: string;
    isArtist?: number;
  }): Promise<User>;

  updateUserBirthday(userId: string, birthday: string): Promise<User>;
  updateUser(input: UpdateUserInput): Promise<User>;

  getListArtistRandomize({
    sessionId,
    limit,
    categoryId,
  }: {
    sessionId: string;
    limit: number;
    categoryId: string;
  }): Promise<ListData<User> | null>;
}

export class RealUserService implements IUserService {
  private userRepo: IUserRepo;
  private artworkRepo: IArtworkRepo;
  private productRepo: IProductRepo;
  private blacklistedUserSet: Set<string>;

  public constructor() {
    this.userRepo = new RealUserRepo();
    this.artworkRepo = new RealArtworkRepo();
    this.productRepo = new RealProductRepo();
    this.blacklistedUserSet = new Set(BlacklistedUserIds);
  }

  public async getUser(userId: string): Promise<User | undefined> {
    return this.userRepo.get(userId);
  }

  public async getUserSecretInfo(userId: string): Promise<User | undefined> {
    return this.userRepo.getUserSecretInfo(userId);
  }

  public async getUserByUsername(username: string): Promise<User | null> {
    const result = await this.userRepo.getByUsername(username);
    return result;
  }

  public async getUserDetail(userId: string): Promise<{
    user: User;
    artworks: ListData<Artwork>;
    products: ListData<Product>;
  }> {
    const getUserPromise = this.userRepo.get(userId);

    const getArtworksPromise = this.artworkRepo.getListArtworkBySeller({ sellerId: userId });
    const getProductsPromise = this.productRepo.getProductsByArtistIdStatus(
      userId,
      ProductStatus.ACTIVE,
    );

    const [user, artworks, products] = await Promise.all([
      getUserPromise,
      getArtworksPromise,
      getProductsPromise,
    ]);

    return {
      user,
      artworks,
      products,
    };
  }

  public async getUserDetailByUsername(username: string): Promise<{
    user: User;
    artworks: ListData<Artwork>;
    products: ListData<Product>;
  }> {
    const user = await this.userRepo.getByUsername(username);

    if (!user) {
      throw new NotFoundError(`User not found for username=${username}.`);
    }

    // Filter out blacklisted users
    if (this.blacklistedUserSet.has(user.id)) {
      throw new NotFoundError(
        `User not found: user in blacklist. username ${username} and id ${user.id}.`,
      );
    }

    const getArtworksPromise = this.artworkRepo.getListArtworkBySeller({ sellerId: user.id });
    // Get all products of the user, then filter out products that are ARCHIVED
    const getProductsPromise = this.productRepo.getProductsByArtistIdStatus(user.id);

    const [artworks, products] = await Promise.all([getArtworksPromise, getProductsPromise]);
    const filteredProducts = (products?.list || []).filter(
      (product) => product.status !== 'ARCHIVED',
    );

    return {
      user,
      artworks,
      products: { list: filteredProducts },
    };
  }

  public async getListUser(limit?: number, nextToken?: string): Promise<ListData<User> | null> {
    try {
      const result = await this.userRepo.getListUser(limit ? limit : 1000, nextToken);
      return result;
    } catch (error) {
      captureException(error);
      return null;
    }
  }

  public async getListUserByListUserId(userIds: string[]): Promise<ListData<User> | null> {
    try {
      const getUserPromises = userIds.map((userId) => this.userRepo.get(userId));
      const users = await Promise.all(getUserPromises);
      const result: ListData<User> = {
        list: users,
        nextToken: undefined,
      };
      return result;
    } catch (error) {
      captureException(error);
      return null;
    }
  }

  public async listAllUsers(): Promise<User[]> {
    const result = await this.userRepo.listAllUsers();
    // Filter out blacklisted users
    const filteredResult = result.filter((user) => !this.blacklistedUserSet.has(user.id));
    return filteredResult;
  }

  public async updateUserProfilePhoto(userId: string, photoUrl: string): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      profile_picture_url: photoUrl,
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUserCoverPhoto(userId: string, photoUrl: string): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      cover_picture_url: photoUrl,
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUserTermOfService(userId: string, termOfService: string): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      term_of_service: termOfService,
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUserInfo(
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    countryCode: string,
    subscriberNumber: string,
    phoneNumber: string,
    isSelling: number, // 0 or 1
  ) {
    let updateUserInput: UpdateUserInput;

    if (!email && subscriberNumber) {
      updateUserInput = {
        id: userId,
        first_name: firstName,
        last_name: lastName,
        country_code: countryCode,
        subscriber_number: subscriberNumber,
        phone_number: phoneNumber,
        is_selling: isSelling,
      };
      await this.userRepo.update(updateUserInput);
    } else if (!subscriberNumber && email) {
      updateUserInput = {
        id: userId,
        first_name: firstName,
        last_name: lastName,
        email_address: email,
        is_selling: isSelling,
      };
      await this.userRepo.update(updateUserInput);
    }
  }

  public async updateUserFirstName(userId: string, firstName: string): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      first_name: firstName,
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUserLastName(userId: string, lastName: string): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      last_name: lastName,
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateSellerOnboardingStage(userId: string, stage: string): Promise<User> {
    let updateUserInput: UpdateUserInput = {
      id: userId,
      seller_onboarding_stage: stage,
    };

    // If stage is COMPLETED, set is_artist to 1 and is_selling to 1
    if (stage === SellerOnboardingStage.Completed.toString()) {
      updateUserInput = {
        id: userId,
        seller_onboarding_stage: stage,
        is_artist: 1,
        is_selling: 1,
      };
    }

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateIsSelling(userId: string, isSelling: boolean): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      is_selling: isSelling ? 1 : 0,
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUserDiscountCode(userId: string, discountCode: string): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      discount_code: discountCode,
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUserAddress({
    userId,
    address,
    city,
  }: {
    userId: string;
    address: string;
    city: string;
  }): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      address_line1: address,
      city,
      address_country_code: 'VN',
    };
    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUserLastTimeOpenListNotification(userId: string): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      last_time_open_list_notification: new Date().toISOString(),
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUserReadTheLatestCommissions(userId: string): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      has_unread_commissions: 0,
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUserReadTheLatestJobs(userId: string): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      has_unread_jobs: 0,
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUserAdditionalInfo({
    userId,
    firstName,
    lastName,
    email,
    subscriberNumber,
    phoneNumber,
    city,
    address,
    isSelling,
    facebookProfileUrl,
    portfolioUrl,
    countryCode,
    isArtist,
  }: {
    userId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    subscriberNumber?: string;
    phoneNumber?: string;
    city?: string;
    address?: string;
    facebookProfileUrl?: string;
    portfolioUrl?: string;
    isSelling?: number;
    countryCode?: string;
    isArtist?: number;
  }): Promise<User> {
    const additionalInfo = JSON.stringify({
      facebook_profile_url: facebookProfileUrl,
      portfolio_url: portfolioUrl,
    });
    const updateUserInput: UpdateUserInput = {
      id: userId,
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      subscriber_number: subscriberNumber,
      phone_number: phoneNumber,
      city,
      address_line1: address,
      is_selling: isSelling,
      country_code: countryCode,
      additional_info: additionalInfo,
      is_artist: isArtist,
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUserBirthday(userId: string, birthday: string): Promise<User> {
    const updateUserInput: UpdateUserInput = {
      id: userId,
      birth_date: birthday,
    };

    const result: User = await this.userRepo.update(updateUserInput);
    return result;
  }

  public async updateUser(input: UpdateUserInput): Promise<User> {
    const result: User = await this.userRepo.update(input);
    return result;
  }

  public async getListArtistRandomize({
    sessionId,
    limit,
    categoryId,
  }: {
    sessionId: string;
    limit: number;
    categoryId: string;
  }): Promise<ListData<User> | null> {
    return this.userRepo.getListArtistRandomize({ sessionId, limit, categoryId });
  }
}
