import WizardBottomAppBar from '@components/bottom/WizardBottomAppBar';
import WizardHeader from '@components/header/WizardHeader';
import { SellerOnboardingStage } from '@src/pages/become-a-seller';
import { DreamerlyUser } from '@src/providers/AuthProvider';
import captureException from '@src/services/loggerService';
import { IUserService, RealUserService } from '@src/services/userService';
import { useRouter } from 'next/router';
import { useState } from 'react';

type IntroProps = {
  user: DreamerlyUser;
  refreshUser: () => Promise<DreamerlyUser | null | undefined>;
  setStage: (stage: SellerOnboardingStage) => void;
};

function Intro(props: IntroProps) {
  const { user, refreshUser, setStage } = props;
  // Header and BottomAppBar loading states
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isBackLoading, setIsBackLoading] = useState(false);
  const [isExitLoading, setIsExitLoading] = useState(false);

  const router = useRouter();
  const userService: IUserService = new RealUserService();

  const onExitClick = async () => {
    setIsExitLoading(true);

    try {
      // Update User table and exit the flow
      await userService.updateSellerOnboardingStage(
        user.id,
        SellerOnboardingStage.Intro.toString(),
      );
      // Refresh user, so that the onboarding stage is updated in AuthProvider
      await refreshUser();
    } catch (error) {
      captureException(error);
    }
    // Change loading state before changing stage to avoid unmounted setState
    setIsExitLoading(false);
    // Do not use router.back() here in case this is a new tab (no history)
    router.push('/');
  };

  const onNextClick = async () => {
    setIsNextLoading(true);
    try {
      // Update User table to the next stage and navigate there
      await userService.updateSellerOnboardingStage(
        user.id,
        SellerOnboardingStage.CreateProducts.toString(),
      );
    } catch (error) {
      captureException(error);
    }
    // Change loading state before changing stage to avoid unmounted setState
    setIsNextLoading(false);
    setStage(SellerOnboardingStage.CreateProducts);
  };

  const onBackClick = async () => {
    setIsBackLoading(true);
    try {
      // Update User table and exit the flow
      await userService.updateSellerOnboardingStage(
        user.id,
        SellerOnboardingStage.Intro.toString(),
      );
    } catch (error) {
      captureException(error);
    }
    // Change loading state before changing stage to avoid unmounted setState
    setIsBackLoading(false);
    // Navigate back to the previous page
    router.back();
  };

  return (
    <div>
      <WizardHeader handleOnExitClick={onExitClick} isExitLoading={isExitLoading} />
      <div className="flex w-full justify-center pb-24">
        <div className="flex-col w-152">
          <div className="mt-16 mb-4">
            <span className="text-l font-medium">Bắt đầu hành trình Dreamerly</span>
          </div>
          <h1 className="text-8xl mb-6 font-semibold">Thiết kế bảng giá 🎨</h1>
          <p className="text-l-tall">
            Dreamerly sẽ dẫn bạn qua các bước tạo bảng giá cho một loại commission nhé! Sau này bạn
            có thể làm tương tự với các loại commission khác nè.
          </p>
        </div>
      </div>
      <WizardBottomAppBar
        progressValue={0}
        handleOnBackClick={onBackClick}
        isBackLoading={isBackLoading}
        handleOnNextClick={onNextClick}
        isNextLoading={isNextLoading}
      />
    </div>
  );
}

export default Intro;
