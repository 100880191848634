export enum PaymentIntentStatus {
  REQUIRES_PAYMENT_METHOD = 'REQUIRES_PAYMENT_METHOD',
  REQUIRES_CONFIRMATION = 'REQUIRES_CONFIRMATION',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  PROCESSING = 'PROCESSING',
  SUCCEEDED = 'SUCCEEDED',
  CANCELLED = 'CANCELLED',
}

export enum CurrencyCode {
  VND = 'VND',
  USD = 'USD',
}

export enum PaymentProviderCode {
  MOMO = 'MOMO',
  INTERNET_BANKING = 'INTERNET_BANKING',
  STRIPE = 'STRIPE',
}

export enum PaymentMethodCode {
  MOMO_WALLET = 'MOMO.WALLET',
  MOMO_ATM = 'MOMO.ATM',
  MOMO_CREDIT_CARD = 'MOMO.CREDIT_CARD',
  STRIPE_CARD = 'STRIPE.CARD',
}

export enum ScreenSizeType {
  MOBILE,
  TABLET,
  DESKTOP,
}

export enum GeneralStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

//  value between "CORE_CONTRACT", "EXTRA_OFFER", "TIP"
export const PAYMENT_PURPOSE = {
  CORE_CONTRACT: 'CORE_CONTRACT',
  EXTRA_OFFER: 'EXTRA_OFFER',
  TIP: 'TIP',
};

export enum AuthScreenType {
  MAIN,
  PASSWORD_VERIFICATION,
  SENT_EMAIL_VERIFICATION_SUCCESSFUL,
  SENT_EMAIL_RESET_PASSWORD_SUCCESSFUL,
  PHONE_VERIFICATION,
  EMAIL_VERIFICATION,
  COMPLETION,
}

export const ALL_CATEGORY_ID = 'ALL';
