/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getTag = /* GraphQL */ `query GetTag($id: ID!) {
  getTag(id: $id) {
    id
    tag_name
    display_name
    status
    total_artwork_quantity
    tag_artwork_items {
      items {
        id
        tag_id
        artwork_id
        tag_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTagQueryVariables, APITypes.GetTagQuery>;
export const listTags = /* GraphQL */ `query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
  listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag_name
      display_name
      status
      total_artwork_quantity
      tag_artwork_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTagsQueryVariables, APITypes.ListTagsQuery>;
export const tagByTagNameStatus = /* GraphQL */ `query TagByTagNameStatus(
  $tag_name: String!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTagFilterInput
  $limit: Int
  $nextToken: String
) {
  tagByTagNameStatus(
    tag_name: $tag_name
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tag_name
      display_name
      status
      total_artwork_quantity
      tag_artwork_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TagByTagNameStatusQueryVariables,
  APITypes.TagByTagNameStatusQuery
>;
export const getTagItem = /* GraphQL */ `query GetTagItem($id: ID!) {
  getTagItem(id: $id) {
    id
    tag_id
    tag {
      id
      tag_name
      display_name
      status
      total_artwork_quantity
      tag_artwork_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    tag_type
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagItemQueryVariables,
  APITypes.GetTagItemQuery
>;
export const listTagItems = /* GraphQL */ `query ListTagItems(
  $filter: ModelTagItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag_id
      tag {
        id
        tag_name
        display_name
        status
        total_artwork_quantity
        created_at
        updated_at
        __typename
      }
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      tag_type
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagItemsQueryVariables,
  APITypes.ListTagItemsQuery
>;
export const tagItemByTagId = /* GraphQL */ `query TagItemByTagId(
  $tag_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTagItemFilterInput
  $limit: Int
  $nextToken: String
) {
  tagItemByTagId(
    tag_id: $tag_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tag_id
      tag {
        id
        tag_name
        display_name
        status
        total_artwork_quantity
        created_at
        updated_at
        __typename
      }
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      tag_type
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TagItemByTagIdQueryVariables,
  APITypes.TagItemByTagIdQuery
>;
export const tagItemByArtworkId = /* GraphQL */ `query TagItemByArtworkId(
  $artwork_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTagItemFilterInput
  $limit: Int
  $nextToken: String
) {
  tagItemByArtworkId(
    artwork_id: $artwork_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tag_id
      tag {
        id
        tag_name
        display_name
        status
        total_artwork_quantity
        created_at
        updated_at
        __typename
      }
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      tag_type
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TagItemByArtworkIdQueryVariables,
  APITypes.TagItemByArtworkIdQuery
>;
export const getArtwork = /* GraphQL */ `query GetArtwork($id: ID!) {
  getArtwork(id: $id) {
    id
    cover_image_height
    cover_image_width
    cover_image_src_url
    cover_image_thumbnail_url
    title
    likes_count
    comments_count
    description
    publication_date_time
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    is_removed
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        artwork_id
        user_id
        is_removed
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    product_artworks {
      items {
        id
        product_id
        artwork_id
        is_removed
        index
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    is_buyer_showcase
    is_seller_showcase
    tag_items {
      items {
        id
        tag_id
        artwork_id
        tag_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetArtworkQueryVariables,
  APITypes.GetArtworkQuery
>;
export const listArtworks = /* GraphQL */ `query ListArtworks(
  $filter: ModelArtworkFilterInput
  $limit: Int
  $nextToken: String
) {
  listArtworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListArtworksQueryVariables,
  APITypes.ListArtworksQuery
>;
export const artworkByUserId = /* GraphQL */ `query ArtworkByUserId(
  $user_id: ID!
  $is_removed: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArtworkFilterInput
  $limit: Int
  $nextToken: String
) {
  artworkByUserId(
    user_id: $user_id
    is_removed: $is_removed
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ArtworkByUserIdQueryVariables,
  APITypes.ArtworkByUserIdQuery
>;
export const artworkByBuyerIdIsRemoved = /* GraphQL */ `query ArtworkByBuyerIdIsRemoved(
  $buyer_id: ID!
  $is_removed: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArtworkFilterInput
  $limit: Int
  $nextToken: String
) {
  artworkByBuyerIdIsRemoved(
    buyer_id: $buyer_id
    is_removed: $is_removed
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ArtworkByBuyerIdIsRemovedQueryVariables,
  APITypes.ArtworkByBuyerIdIsRemovedQuery
>;
export const artworkByIsRemovedPublicationDateTime = /* GraphQL */ `query ArtworkByIsRemovedPublicationDateTime(
  $is_removed: Int!
  $publication_date_time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArtworkFilterInput
  $limit: Int
  $nextToken: String
) {
  artworkByIsRemovedPublicationDateTime(
    is_removed: $is_removed
    publication_date_time: $publication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ArtworkByIsRemovedPublicationDateTimeQueryVariables,
  APITypes.ArtworkByIsRemovedPublicationDateTimeQuery
>;
export const artworkByContractId = /* GraphQL */ `query ArtworkByContractId(
  $contract_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelArtworkFilterInput
  $limit: Int
  $nextToken: String
) {
  artworkByContractId(
    contract_id: $contract_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ArtworkByContractIdQueryVariables,
  APITypes.ArtworkByContractIdQuery
>;
export const getProductArtwork = /* GraphQL */ `query GetProductArtwork($id: ID!) {
  getProductArtwork(id: $id) {
    id
    product_id
    product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    is_removed
    index
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductArtworkQueryVariables,
  APITypes.GetProductArtworkQuery
>;
export const listProductArtworks = /* GraphQL */ `query ListProductArtworks(
  $filter: ModelProductArtworkFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductArtworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      is_removed
      index
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductArtworksQueryVariables,
  APITypes.ListProductArtworksQuery
>;
export const productArtworkByProductId = /* GraphQL */ `query ProductArtworkByProductId(
  $product_id: ID!
  $is_removed: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductArtworkFilterInput
  $limit: Int
  $nextToken: String
) {
  productArtworkByProductId(
    product_id: $product_id
    is_removed: $is_removed
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      is_removed
      index
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductArtworkByProductIdQueryVariables,
  APITypes.ProductArtworkByProductIdQuery
>;
export const productArtworkByArtworkId = /* GraphQL */ `query ProductArtworkByArtworkId(
  $artwork_id: ID!
  $is_removed: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductArtworkFilterInput
  $limit: Int
  $nextToken: String
) {
  productArtworkByArtworkId(
    artwork_id: $artwork_id
    is_removed: $is_removed
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      is_removed
      index
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductArtworkByArtworkIdQueryVariables,
  APITypes.ProductArtworkByArtworkIdQuery
>;
export const getCategory = /* GraphQL */ `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    display_name
    status
    products {
      items {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryQueryVariables,
  APITypes.GetCategoryQuery
>;
export const listCategories = /* GraphQL */ `query ListCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      display_name
      status
      products {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoriesQueryVariables,
  APITypes.ListCategoriesQuery
>;
export const getImage = /* GraphQL */ `query GetImage($id: ID!) {
  getImage(id: $id) {
    id
    height
    width
    file_name
    src_url
    thumbnail_url
    raw_src_url
    raw_thumbnail_url
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    revision_id
    revision {
      id
      index
      status
      request_message
      request_rejection_message
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      additional_deadline_number
      additional_deadline_type
      start_date_time
      end_date_time
      images {
        nextToken
        __typename
      }
      request_revision_images {
        nextToken
        __typename
      }
      delivery_date_time
      deadline_extensions
      created_at
      updated_at
      __typename
    }
    revision_request_revision_id
    revision_request_revision {
      id
      index
      status
      request_message
      request_rejection_message
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      additional_deadline_number
      additional_deadline_type
      start_date_time
      end_date_time
      images {
        nextToken
        __typename
      }
      request_revision_images {
        nextToken
        __typename
      }
      delivery_date_time
      deadline_extensions
      created_at
      updated_at
      __typename
    }
    message_id
    message {
      id
      channel_id
      channel {
        id
        name
        created_at
        updated_at
        __typename
      }
      sender_id
      sender {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      content
      image_url
      publication_date_time
      available_to
      images {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.GetImageQueryVariables, APITypes.GetImageQuery>;
export const listImages = /* GraphQL */ `query ListImages(
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      height
      width
      file_name
      src_url
      thumbnail_url
      raw_src_url
      raw_thumbnail_url
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      revision_id
      revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      revision_request_revision_id
      revision_request_revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      message_id
      message {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListImagesQueryVariables,
  APITypes.ListImagesQuery
>;
export const imageByArtworkId = /* GraphQL */ `query ImageByArtworkId(
  $artwork_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  imageByArtworkId(
    artwork_id: $artwork_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      height
      width
      file_name
      src_url
      thumbnail_url
      raw_src_url
      raw_thumbnail_url
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      revision_id
      revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      revision_request_revision_id
      revision_request_revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      message_id
      message {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImageByArtworkIdQueryVariables,
  APITypes.ImageByArtworkIdQuery
>;
export const imageByJobId = /* GraphQL */ `query ImageByJobId(
  $job_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  imageByJobId(
    job_id: $job_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      height
      width
      file_name
      src_url
      thumbnail_url
      raw_src_url
      raw_thumbnail_url
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      revision_id
      revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      revision_request_revision_id
      revision_request_revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      message_id
      message {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImageByJobIdQueryVariables,
  APITypes.ImageByJobIdQuery
>;
export const imageByMilestoneId = /* GraphQL */ `query ImageByMilestoneId(
  $milestone_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  imageByMilestoneId(
    milestone_id: $milestone_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      height
      width
      file_name
      src_url
      thumbnail_url
      raw_src_url
      raw_thumbnail_url
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      revision_id
      revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      revision_request_revision_id
      revision_request_revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      message_id
      message {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImageByMilestoneIdQueryVariables,
  APITypes.ImageByMilestoneIdQuery
>;
export const imageByRevisionId = /* GraphQL */ `query ImageByRevisionId(
  $revision_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  imageByRevisionId(
    revision_id: $revision_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      height
      width
      file_name
      src_url
      thumbnail_url
      raw_src_url
      raw_thumbnail_url
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      revision_id
      revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      revision_request_revision_id
      revision_request_revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      message_id
      message {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImageByRevisionIdQueryVariables,
  APITypes.ImageByRevisionIdQuery
>;
export const imageByRevisionRequestRevisionId = /* GraphQL */ `query ImageByRevisionRequestRevisionId(
  $revision_request_revision_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  imageByRevisionRequestRevisionId(
    revision_request_revision_id: $revision_request_revision_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      height
      width
      file_name
      src_url
      thumbnail_url
      raw_src_url
      raw_thumbnail_url
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      revision_id
      revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      revision_request_revision_id
      revision_request_revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      message_id
      message {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImageByRevisionRequestRevisionIdQueryVariables,
  APITypes.ImageByRevisionRequestRevisionIdQuery
>;
export const imageByMessageId = /* GraphQL */ `query ImageByMessageId(
  $message_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  imageByMessageId(
    message_id: $message_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      height
      width
      file_name
      src_url
      thumbnail_url
      raw_src_url
      raw_thumbnail_url
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      revision_id
      revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      revision_request_revision_id
      revision_request_revision {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      message_id
      message {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ImageByMessageIdQueryVariables,
  APITypes.ImageByMessageIdQuery
>;
export const getLike = /* GraphQL */ `query GetLike($id: ID!) {
  getLike(id: $id) {
    id
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    is_removed
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLikeQueryVariables, APITypes.GetLikeQuery>;
export const listLikes = /* GraphQL */ `query ListLikes(
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListLikesQueryVariables, APITypes.ListLikesQuery>;
export const likeByArtworkIdUserIdIsRemoved = /* GraphQL */ `query LikeByArtworkIdUserIdIsRemoved(
  $artwork_id: ID!
  $user_idIs_removed: ModelLikeByArtworkIdUserIdIsRemovedCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  likeByArtworkIdUserIdIsRemoved(
    artwork_id: $artwork_id
    user_idIs_removed: $user_idIs_removed
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LikeByArtworkIdUserIdIsRemovedQueryVariables,
  APITypes.LikeByArtworkIdUserIdIsRemovedQuery
>;
export const likeByUserId = /* GraphQL */ `query LikeByUserId(
  $user_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  likeByUserId(
    user_id: $user_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LikeByUserIdQueryVariables,
  APITypes.LikeByUserIdQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    display_name
    first_name
    last_name
    username
    email_address
    birth_date
    phone_number
    country_code
    subscriber_number
    bio
    is_artist
    seller_onboarding_stage
    buyer_jobs {
      items {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    seller_jobs {
      items {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    term_of_service
    cccd
    mst
    cccd_images
    mst_stage
    seller_proposals {
      items {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    profile_picture_url
    cover_picture_url
    balance_amount
    balance_centi_amount
    balance_currency_code
    usd_balance_amount
    usd_balance_centi_amount
    contract_paid_quantity
    seller_star_quantity
    seller_review_quantity
    buyer_star_quantity
    buyer_review_quantity
    commision_started_quantity
    commission_completed_quantity
    commission_completed_on_time_quantity
    commission_cancelled_seller_fault_quantity
    commission_cancelled_buyer_fault_quantity
    commission_cancelled_mutually_quantity
    commission_cumulative_late_seconds
    commission_max_late_seconds
    device_tokens
    is_selling
    is_selling_abroad
    products {
      items {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    last_time_open_list_notification
    discount_code
    artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buyer_artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        artwork_id
        user_id
        is_removed
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    user_channels {
      items {
        id
        channel_id
        user_id
        update_date_time
        status
        next_time_should_receive_an_email
        next_time_should_receive_a_late_response
        request_start_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    messages {
      items {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payouts {
      items {
        id
        amount
        centi_amount
        currency
        billing_details
        seller_id
        description
        arrival_date_time
        status
        payment_provider
        balance_transaction_id
        request_amount
        request_centi_amount
        request_date_time
        bank_account_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    selling_refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buying_refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    seller_contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    buyer_contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    given_reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    sent_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transactions {
      items {
        id
        type
        amount
        centi_amount
        currency_code
        note
        publication_date_time
        user_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    follower_quantity
    leader_quantity
    followers {
      items {
        id
        leader_id
        follower_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    leaders {
      items {
        id
        leader_id
        follower_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    notifications {
      items {
        id
        user_id
        notification_sender_id
        title
        body
        publication_date_time
        is_read
        is_removed
        notification_type
        underlying_object_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    notification_senders {
      items {
        id
        user_id
        notification_sender_id
        title
        body
        publication_date_time
        is_read
        is_removed
        notification_type
        underlying_object_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    push_tokens {
      items {
        id
        user_id
        push_token
        device_type
        last_used_at
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    has_unread_commissions
    has_unread_jobs
    message_cumulative_response_seconds
    message_response_on_time_quantity
    message_response_late_quantity
    characters {
      items {
        id
        display_name
        charname
        character_type
        user_id
        description
        strengths
        flaws
        gender
        race
        birth_date
        age
        role
        hobbies
        dislikes
        profile_picture_url
        cover_photo_url
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    bank_name
    account_holder_name
    account_last_four
    address_country_code
    city
    address_line1
    additional_info
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const userByUsername = /* GraphQL */ `query UserByUsername(
  $username: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  userByUsername(
    username: $username
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByUsernameQueryVariables,
  APITypes.UserByUsernameQuery
>;
export const userByEmailAddress = /* GraphQL */ `query UserByEmailAddress(
  $email_address: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  userByEmailAddress(
    email_address: $email_address
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByEmailAddressQueryVariables,
  APITypes.UserByEmailAddressQuery
>;
export const userByPhoneNumber = /* GraphQL */ `query UserByPhoneNumber(
  $phone_number: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  userByPhoneNumber(
    phone_number: $phone_number
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByPhoneNumberQueryVariables,
  APITypes.UserByPhoneNumberQuery
>;
export const getBankAccount = /* GraphQL */ `query GetBankAccount($id: ID!) {
  getBankAccount(id: $id) {
    id
    user_id
    bank_name
    account_holder_name
    account_number
    currency_code
    country_code
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBankAccountQueryVariables,
  APITypes.GetBankAccountQuery
>;
export const listBankAccounts = /* GraphQL */ `query ListBankAccounts(
  $filter: ModelBankAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listBankAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user_id
      bank_name
      account_holder_name
      account_number
      currency_code
      country_code
      status
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBankAccountsQueryVariables,
  APITypes.ListBankAccountsQuery
>;
export const bankAccountByUserIdStatus = /* GraphQL */ `query BankAccountByUserIdStatus(
  $user_id: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBankAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  bankAccountByUserIdStatus(
    user_id: $user_id
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      bank_name
      account_holder_name
      account_number
      currency_code
      country_code
      status
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BankAccountByUserIdStatusQueryVariables,
  APITypes.BankAccountByUserIdStatusQuery
>;
export const getPushToken = /* GraphQL */ `query GetPushToken($id: ID!) {
  getPushToken(id: $id) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    push_token
    device_type
    last_used_at
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPushTokenQueryVariables,
  APITypes.GetPushTokenQuery
>;
export const listPushTokens = /* GraphQL */ `query ListPushTokens(
  $filter: ModelPushTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  listPushTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      push_token
      device_type
      last_used_at
      status
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPushTokensQueryVariables,
  APITypes.ListPushTokensQuery
>;
export const pushTokenByUserId = /* GraphQL */ `query PushTokenByUserId(
  $user_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPushTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  pushTokenByUserId(
    user_id: $user_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      push_token
      device_type
      last_used_at
      status
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PushTokenByUserIdQueryVariables,
  APITypes.PushTokenByUserIdQuery
>;
export const getUserBlock = /* GraphQL */ `query GetUserBlock($id: ID!) {
  getUserBlock(id: $id) {
    id
    user_give_block_id
    user_receive_block_id
    type
    status
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserBlockQueryVariables,
  APITypes.GetUserBlockQuery
>;
export const listUserBlocks = /* GraphQL */ `query ListUserBlocks(
  $filter: ModelUserBlockFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user_give_block_id
      user_receive_block_id
      type
      status
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserBlocksQueryVariables,
  APITypes.ListUserBlocksQuery
>;
export const userBlockByUserGiveBlockId = /* GraphQL */ `query UserBlockByUserGiveBlockId(
  $user_give_block_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserBlockFilterInput
  $limit: Int
  $nextToken: String
) {
  userBlockByUserGiveBlockId(
    user_give_block_id: $user_give_block_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_give_block_id
      user_receive_block_id
      type
      status
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserBlockByUserGiveBlockIdQueryVariables,
  APITypes.UserBlockByUserGiveBlockIdQuery
>;
export const userBlockByUserReceiveBlockId = /* GraphQL */ `query UserBlockByUserReceiveBlockId(
  $user_receive_block_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserBlockFilterInput
  $limit: Int
  $nextToken: String
) {
  userBlockByUserReceiveBlockId(
    user_receive_block_id: $user_receive_block_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_give_block_id
      user_receive_block_id
      type
      status
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserBlockByUserReceiveBlockIdQueryVariables,
  APITypes.UserBlockByUserReceiveBlockIdQuery
>;
export const getProduct = /* GraphQL */ `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    artist_id
    artist {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    display_name
    description
    status
    prices {
      items {
        id
        product_id
        display_name
        description
        status
        type
        category
        fixed_amount
        fixed_centi_amount
        index
        min_amount
        min_centi_amount
        max_amount
        max_centi_amount
        currency_code
        percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    slug
    category_id
    category {
      id
      display_name
      status
      products {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    min_price_amount
    max_price_amount
    currency_code
    product_star_quantity
    product_review_quantity
    commission_paid_quantity
    artist_other_artworks {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    reviews {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      reviewer_id
      reviewer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      rating
      comment
      type
      publication_date_time
      created_at
      updated_at
      __typename
    }
    contract_products {
      items {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    product_artworks {
      items {
        id
        product_id
        artwork_id
        is_removed
        index
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    dreambox_response_time
    dreambox_complete_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductQueryVariables,
  APITypes.GetProductQuery
>;
export const listProducts = /* GraphQL */ `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsQueryVariables,
  APITypes.ListProductsQuery
>;
export const productByArtistIdStatus = /* GraphQL */ `query ProductByArtistIdStatus(
  $artist_id: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productByArtistIdStatus(
    artist_id: $artist_id
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductByArtistIdStatusQueryVariables,
  APITypes.ProductByArtistIdStatusQuery
>;
export const productBySlug = /* GraphQL */ `query ProductBySlug(
  $slug: String!
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productBySlug(
    slug: $slug
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductBySlugQueryVariables,
  APITypes.ProductBySlugQuery
>;
export const productByCategoryId = /* GraphQL */ `query ProductByCategoryId(
  $category_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productByCategoryId(
    category_id: $category_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductByCategoryIdQueryVariables,
  APITypes.ProductByCategoryIdQuery
>;
export const getPrice = /* GraphQL */ `query GetPrice($id: ID!) {
  getPrice(id: $id) {
    id
    product_id
    Product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    display_name
    description
    status
    type
    category
    fixed_amount
    fixed_centi_amount
    index
    min_amount
    min_centi_amount
    max_amount
    max_centi_amount
    currency_code
    percentage
    contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offer_prices {
      items {
        id
        extra_offer_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPriceQueryVariables, APITypes.GetPriceQuery>;
export const listPrices = /* GraphQL */ `query ListPrices(
  $filter: ModelPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      product_id
      Product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      type
      category
      fixed_amount
      fixed_centi_amount
      index
      min_amount
      min_centi_amount
      max_amount
      max_centi_amount
      currency_code
      percentage
      contract_product_prices {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPricesQueryVariables,
  APITypes.ListPricesQuery
>;
export const priceByProductIdStatus = /* GraphQL */ `query PriceByProductIdStatus(
  $product_id: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  priceByProductIdStatus(
    product_id: $product_id
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_id
      Product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      type
      category
      fixed_amount
      fixed_centi_amount
      index
      min_amount
      min_centi_amount
      max_amount
      max_centi_amount
      currency_code
      percentage
      contract_product_prices {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PriceByProductIdStatusQueryVariables,
  APITypes.PriceByProductIdStatusQuery
>;
export const getJob = /* GraphQL */ `query GetJob($id: ID!) {
  getJob(id: $id) {
    id
    title
    description
    min_budget
    min_centi_budget
    max_budget
    max_centi_budget
    currency_code
    status
    is_exclusive
    publication_date_time
    deadline_date_time
    expiration_date_time
    is_comercial_artwork
    is_dreambox
    is_private_artwork
    file_format
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    proposals {
      items {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contacted_seller_ids
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.GetJobQueryVariables, APITypes.GetJobQuery>;
export const listJobs = /* GraphQL */ `query ListJobs($filter: ModelJobFilterInput, $limit: Int, $nextToken: String) {
  listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListJobsQueryVariables, APITypes.ListJobsQuery>;
export const jobByStatusIsExclusivePublicationDateTime = /* GraphQL */ `query JobByStatusIsExclusivePublicationDateTime(
  $status: String!
  $is_exclusivePublication_date_time: ModelJobByStatusIsExclusivePublicationDateTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobByStatusIsExclusivePublicationDateTime(
    status: $status
    is_exclusivePublication_date_time: $is_exclusivePublication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobByStatusIsExclusivePublicationDateTimeQueryVariables,
  APITypes.JobByStatusIsExclusivePublicationDateTimeQuery
>;
export const jobByStatusIsExclusiveExpirationDateTime = /* GraphQL */ `query JobByStatusIsExclusiveExpirationDateTime(
  $status: String!
  $is_exclusiveExpiration_date_time: ModelJobByStatusIsExclusiveExpirationDateTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobByStatusIsExclusiveExpirationDateTime(
    status: $status
    is_exclusiveExpiration_date_time: $is_exclusiveExpiration_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobByStatusIsExclusiveExpirationDateTimeQueryVariables,
  APITypes.JobByStatusIsExclusiveExpirationDateTimeQuery
>;
export const jobByBuyerIdStatusPublicationDateTime = /* GraphQL */ `query JobByBuyerIdStatusPublicationDateTime(
  $buyer_id: ID!
  $statusPublication_date_time: ModelJobByBuyerIdStatusPublicationDateTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobByBuyerIdStatusPublicationDateTime(
    buyer_id: $buyer_id
    statusPublication_date_time: $statusPublication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobByBuyerIdStatusPublicationDateTimeQueryVariables,
  APITypes.JobByBuyerIdStatusPublicationDateTimeQuery
>;
export const jobBySellerIdStatusPublicationDateTime = /* GraphQL */ `query JobBySellerIdStatusPublicationDateTime(
  $seller_id: ID!
  $statusPublication_date_time: ModelJobBySellerIdStatusPublicationDateTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobBySellerIdStatusPublicationDateTime(
    seller_id: $seller_id
    statusPublication_date_time: $statusPublication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobBySellerIdStatusPublicationDateTimeQueryVariables,
  APITypes.JobBySellerIdStatusPublicationDateTimeQuery
>;
export const getProposal = /* GraphQL */ `query GetProposal($id: ID!) {
  getProposal(id: $id) {
    id
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_comment
    status
    publication_date_time
    contracts {
      items {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    proposal_type
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProposalQueryVariables,
  APITypes.GetProposalQuery
>;
export const listProposals = /* GraphQL */ `query ListProposals(
  $filter: ModelProposalFilterInput
  $limit: Int
  $nextToken: String
) {
  listProposals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_comment
      status
      publication_date_time
      contracts {
        nextToken
        __typename
      }
      proposal_type
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProposalsQueryVariables,
  APITypes.ListProposalsQuery
>;
export const proposalByJobIdSellerId = /* GraphQL */ `query ProposalByJobIdSellerId(
  $job_id: ID!
  $seller_id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProposalFilterInput
  $limit: Int
  $nextToken: String
) {
  proposalByJobIdSellerId(
    job_id: $job_id
    seller_id: $seller_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_comment
      status
      publication_date_time
      contracts {
        nextToken
        __typename
      }
      proposal_type
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProposalByJobIdSellerIdQueryVariables,
  APITypes.ProposalByJobIdSellerIdQuery
>;
export const proposalBySellerIdStatusPublicationDateTime = /* GraphQL */ `query ProposalBySellerIdStatusPublicationDateTime(
  $seller_id: ID!
  $statusPublication_date_time: ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProposalFilterInput
  $limit: Int
  $nextToken: String
) {
  proposalBySellerIdStatusPublicationDateTime(
    seller_id: $seller_id
    statusPublication_date_time: $statusPublication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_comment
      status
      publication_date_time
      contracts {
        nextToken
        __typename
      }
      proposal_type
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProposalBySellerIdStatusPublicationDateTimeQueryVariables,
  APITypes.ProposalBySellerIdStatusPublicationDateTimeQuery
>;
export const getChannel = /* GraphQL */ `query GetChannel($id: ID!) {
  getChannel(id: $id) {
    id
    name
    user_channels {
      items {
        id
        channel_id
        user_id
        update_date_time
        status
        next_time_should_receive_an_email
        next_time_should_receive_a_late_response
        request_start_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    messages {
      items {
        id
        channel_id
        sender_id
        content
        image_url
        publication_date_time
        available_to
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChannelQueryVariables,
  APITypes.GetChannelQuery
>;
export const listChannels = /* GraphQL */ `query ListChannels(
  $filter: ModelChannelFilterInput
  $limit: Int
  $nextToken: String
) {
  listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChannelsQueryVariables,
  APITypes.ListChannelsQuery
>;
export const getUserChannel = /* GraphQL */ `query GetUserChannel($id: ID!) {
  getUserChannel(id: $id) {
    id
    channel_id
    channel {
      id
      name
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    update_date_time
    status
    next_time_should_receive_an_email
    next_time_should_receive_a_late_response
    request_start_date_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserChannelQueryVariables,
  APITypes.GetUserChannelQuery
>;
export const listUserChannels = /* GraphQL */ `query ListUserChannels(
  $filter: ModelUserChannelFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      channel_id
      channel {
        id
        name
        created_at
        updated_at
        __typename
      }
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      update_date_time
      status
      next_time_should_receive_an_email
      next_time_should_receive_a_late_response
      request_start_date_time
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserChannelsQueryVariables,
  APITypes.ListUserChannelsQuery
>;
export const userChannelByChannelIdStatus = /* GraphQL */ `query UserChannelByChannelIdStatus(
  $channel_id: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserChannelFilterInput
  $limit: Int
  $nextToken: String
) {
  userChannelByChannelIdStatus(
    channel_id: $channel_id
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      channel_id
      channel {
        id
        name
        created_at
        updated_at
        __typename
      }
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      update_date_time
      status
      next_time_should_receive_an_email
      next_time_should_receive_a_late_response
      request_start_date_time
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserChannelByChannelIdStatusQueryVariables,
  APITypes.UserChannelByChannelIdStatusQuery
>;
export const userChannelByUserIdStatus = /* GraphQL */ `query UserChannelByUserIdStatus(
  $user_id: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserChannelFilterInput
  $limit: Int
  $nextToken: String
) {
  userChannelByUserIdStatus(
    user_id: $user_id
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      channel_id
      channel {
        id
        name
        created_at
        updated_at
        __typename
      }
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      update_date_time
      status
      next_time_should_receive_an_email
      next_time_should_receive_a_late_response
      request_start_date_time
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserChannelByUserIdStatusQueryVariables,
  APITypes.UserChannelByUserIdStatusQuery
>;
export const getMessage = /* GraphQL */ `query GetMessage($id: ID!) {
  getMessage(id: $id) {
    id
    channel_id
    channel {
      id
      name
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    sender_id
    sender {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    content
    image_url
    publication_date_time
    available_to
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageQueryVariables,
  APITypes.GetMessageQuery
>;
export const listMessages = /* GraphQL */ `query ListMessages(
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      channel_id
      channel {
        id
        name
        created_at
        updated_at
        __typename
      }
      sender_id
      sender {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      content
      image_url
      publication_date_time
      available_to
      images {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessagesQueryVariables,
  APITypes.ListMessagesQuery
>;
export const messageByChannelIdPublicationDateTime = /* GraphQL */ `query MessageByChannelIdPublicationDateTime(
  $channel_id: ID!
  $publication_date_time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messageByChannelIdPublicationDateTime(
    channel_id: $channel_id
    publication_date_time: $publication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      channel_id
      channel {
        id
        name
        created_at
        updated_at
        __typename
      }
      sender_id
      sender {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      content
      image_url
      publication_date_time
      available_to
      images {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessageByChannelIdPublicationDateTimeQueryVariables,
  APITypes.MessageByChannelIdPublicationDateTimeQuery
>;
export const messageBySenderId = /* GraphQL */ `query MessageBySenderId(
  $sender_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messageBySenderId(
    sender_id: $sender_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      channel_id
      channel {
        id
        name
        created_at
        updated_at
        __typename
      }
      sender_id
      sender {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      content
      image_url
      publication_date_time
      available_to
      images {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessageBySenderIdQueryVariables,
  APITypes.MessageBySenderIdQuery
>;
export const getPaymentIntent = /* GraphQL */ `query GetPaymentIntent($id: ID!) {
  getPaymentIntent(id: $id) {
    id
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_payment_contract_id
    extra_payment_contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_offer_id
    extra_offer {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency_code
      status
      payment_intents {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    amount
    centi_amount
    currency
    payout_owing_to_seller_amount
    payout_owing_to_seller_centi_amount
    fee_owing_to_momo_amount
    fee_owing_to_momo_centi_amount
    dreamerly_fee_balance_amount
    dreamerly_fee_balance_centi_amount
    display_fee_owing_to_momo_amount
    display_fee_owing_to_momo_centi_amount
    display_dreamerly_fee_balance_amount
    display_dreamerly_fee_balance_centi_amount
    payment_purpose
    billing_details
    description
    last_payment_error
    next_action
    disputed
    disputed_at
    refunded
    refunded_amount
    refunded_centi_amount
    refunded_at
    status
    payment_provider
    payment_method
    paid_at
    cancelled_at
    momo_trans_id
    momo_request_id
    momo_payment_url
    momo_payment_url_expires_at
    stripe_payment_intent_id
    stripe_payment_method_country
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intent_events {
      items {
        id
        payment_intent_id
        event_id
        payment_intent_status
        payment_intent_amount
        payment_intent_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentIntentQueryVariables,
  APITypes.GetPaymentIntentQuery
>;
export const listPaymentIntents = /* GraphQL */ `query ListPaymentIntents(
  $filter: ModelPaymentIntentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentIntents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentIntentsQueryVariables,
  APITypes.ListPaymentIntentsQuery
>;
export const paymentIntentByBuyerId = /* GraphQL */ `query PaymentIntentByBuyerId(
  $buyer_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentIntentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentIntentByBuyerId(
    buyer_id: $buyer_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentIntentByBuyerIdQueryVariables,
  APITypes.PaymentIntentByBuyerIdQuery
>;
export const paymentIntentByContractId = /* GraphQL */ `query PaymentIntentByContractId(
  $contract_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentIntentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentIntentByContractId(
    contract_id: $contract_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentIntentByContractIdQueryVariables,
  APITypes.PaymentIntentByContractIdQuery
>;
export const paymentIntentByExtraPaymentContractId = /* GraphQL */ `query PaymentIntentByExtraPaymentContractId(
  $extra_payment_contract_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentIntentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentIntentByExtraPaymentContractId(
    extra_payment_contract_id: $extra_payment_contract_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentIntentByExtraPaymentContractIdQueryVariables,
  APITypes.PaymentIntentByExtraPaymentContractIdQuery
>;
export const paymentIntentByExtraOfferId = /* GraphQL */ `query PaymentIntentByExtraOfferId(
  $extra_offer_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentIntentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentIntentByExtraOfferId(
    extra_offer_id: $extra_offer_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentIntentByExtraOfferIdQueryVariables,
  APITypes.PaymentIntentByExtraOfferIdQuery
>;
export const paymentIntentByBalanceTransactionId = /* GraphQL */ `query PaymentIntentByBalanceTransactionId(
  $balance_transaction_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentIntentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentIntentByBalanceTransactionId(
    balance_transaction_id: $balance_transaction_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentIntentByBalanceTransactionIdQueryVariables,
  APITypes.PaymentIntentByBalanceTransactionIdQuery
>;
export const getPayout = /* GraphQL */ `query GetPayout($id: ID!) {
  getPayout(id: $id) {
    id
    amount
    centi_amount
    currency
    billing_details
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    arrival_date_time
    status
    payment_provider
    balance_transaction_id
    request_amount
    request_centi_amount
    request_date_time
    bank_account_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPayoutQueryVariables, APITypes.GetPayoutQuery>;
export const listPayouts = /* GraphQL */ `query ListPayouts(
  $filter: ModelPayoutFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      amount
      centi_amount
      currency
      billing_details
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      arrival_date_time
      status
      payment_provider
      balance_transaction_id
      request_amount
      request_centi_amount
      request_date_time
      bank_account_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPayoutsQueryVariables,
  APITypes.ListPayoutsQuery
>;
export const payoutBySellerId = /* GraphQL */ `query PayoutBySellerId(
  $seller_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPayoutFilterInput
  $limit: Int
  $nextToken: String
) {
  payoutBySellerId(
    seller_id: $seller_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      centi_amount
      currency
      billing_details
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      arrival_date_time
      status
      payment_provider
      balance_transaction_id
      request_amount
      request_centi_amount
      request_date_time
      bank_account_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PayoutBySellerIdQueryVariables,
  APITypes.PayoutBySellerIdQuery
>;
export const payoutByBalanceTransactionId = /* GraphQL */ `query PayoutByBalanceTransactionId(
  $balance_transaction_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPayoutFilterInput
  $limit: Int
  $nextToken: String
) {
  payoutByBalanceTransactionId(
    balance_transaction_id: $balance_transaction_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      centi_amount
      currency
      billing_details
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      arrival_date_time
      status
      payment_provider
      balance_transaction_id
      request_amount
      request_centi_amount
      request_date_time
      bank_account_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PayoutByBalanceTransactionIdQueryVariables,
  APITypes.PayoutByBalanceTransactionIdQuery
>;
export const getRefund = /* GraphQL */ `query GetRefund($id: ID!) {
  getRefund(id: $id) {
    id
    amount
    centi_amount
    currency
    payout_owing_to_seller_amount
    payout_owing_to_seller_centi_amount
    fee_owing_to_momo_amount
    fee_owing_to_momo_centi_amount
    dreamerly_fee_balance_amount
    dreamerly_fee_balance_centi_amount
    billing_details
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    payment_intent_id
    payment_intent {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    reason
    status
    response
    payment_provider
    momo_trans_id
    approved_at
    rejected_at
    refunded_at
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRefundQueryVariables, APITypes.GetRefundQuery>;
export const listRefunds = /* GraphQL */ `query ListRefunds(
  $filter: ModelRefundFilterInput
  $limit: Int
  $nextToken: String
) {
  listRefunds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      billing_details
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      payment_intent_id
      payment_intent {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      reason
      status
      response
      payment_provider
      momo_trans_id
      approved_at
      rejected_at
      refunded_at
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRefundsQueryVariables,
  APITypes.ListRefundsQuery
>;
export const refundByBuyerId = /* GraphQL */ `query RefundByBuyerId(
  $buyer_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRefundFilterInput
  $limit: Int
  $nextToken: String
) {
  refundByBuyerId(
    buyer_id: $buyer_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      billing_details
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      payment_intent_id
      payment_intent {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      reason
      status
      response
      payment_provider
      momo_trans_id
      approved_at
      rejected_at
      refunded_at
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RefundByBuyerIdQueryVariables,
  APITypes.RefundByBuyerIdQuery
>;
export const refundBySellerId = /* GraphQL */ `query RefundBySellerId(
  $seller_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRefundFilterInput
  $limit: Int
  $nextToken: String
) {
  refundBySellerId(
    seller_id: $seller_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      billing_details
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      payment_intent_id
      payment_intent {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      reason
      status
      response
      payment_provider
      momo_trans_id
      approved_at
      rejected_at
      refunded_at
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RefundBySellerIdQueryVariables,
  APITypes.RefundBySellerIdQuery
>;
export const refundByPaymentIntentId = /* GraphQL */ `query RefundByPaymentIntentId(
  $payment_intent_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRefundFilterInput
  $limit: Int
  $nextToken: String
) {
  refundByPaymentIntentId(
    payment_intent_id: $payment_intent_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      billing_details
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      payment_intent_id
      payment_intent {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      reason
      status
      response
      payment_provider
      momo_trans_id
      approved_at
      rejected_at
      refunded_at
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RefundByPaymentIntentIdQueryVariables,
  APITypes.RefundByPaymentIntentIdQuery
>;
export const refundByContractId = /* GraphQL */ `query RefundByContractId(
  $contract_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRefundFilterInput
  $limit: Int
  $nextToken: String
) {
  refundByContractId(
    contract_id: $contract_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      billing_details
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      payment_intent_id
      payment_intent {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      reason
      status
      response
      payment_provider
      momo_trans_id
      approved_at
      rejected_at
      refunded_at
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RefundByContractIdQueryVariables,
  APITypes.RefundByContractIdQuery
>;
export const refundByBalanceTransactionId = /* GraphQL */ `query RefundByBalanceTransactionId(
  $balance_transaction_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRefundFilterInput
  $limit: Int
  $nextToken: String
) {
  refundByBalanceTransactionId(
    balance_transaction_id: $balance_transaction_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      billing_details
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      payment_intent_id
      payment_intent {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      reason
      status
      response
      payment_provider
      momo_trans_id
      approved_at
      rejected_at
      refunded_at
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RefundByBalanceTransactionIdQueryVariables,
  APITypes.RefundByBalanceTransactionIdQuery
>;
export const getContract = /* GraphQL */ `query GetContract($id: ID!) {
  getContract(id: $id) {
    id
    status
    publication_date_time
    is_private_artwork
    is_comercial_artwork
    file_formats
    description
    amount
    centi_amount
    currency_code
    term_of_service
    state_name
    current_milestone_id
    current_milestone_index
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    proposal_id
    proposal {
      id
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_comment
      status
      publication_date_time
      contracts {
        nextToken
        __typename
      }
      proposal_type
      created_at
      updated_at
      __typename
    }
    buyer_id
    buyer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    seller_id
    seller {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_products {
      items {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_payment_payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    milestones {
      items {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    contract_events {
      items {
        id
        contract_id
        event_id
        contract_status
        contract_amount
        contract_currency
        extra_offer_status
        extra_offer_amount
        extra_offer_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    reviews {
      items {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offers {
      items {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    artworks {
      items {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContractQueryVariables,
  APITypes.GetContractQuery
>;
export const listContracts = /* GraphQL */ `query ListContracts(
  $filter: ModelContractFilterInput
  $limit: Int
  $nextToken: String
) {
  listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContractsQueryVariables,
  APITypes.ListContractsQuery
>;
export const contractByJobId = /* GraphQL */ `query ContractByJobId(
  $job_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContractFilterInput
  $limit: Int
  $nextToken: String
) {
  contractByJobId(
    job_id: $job_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractByJobIdQueryVariables,
  APITypes.ContractByJobIdQuery
>;
export const contractByProposalId = /* GraphQL */ `query ContractByProposalId(
  $proposal_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContractFilterInput
  $limit: Int
  $nextToken: String
) {
  contractByProposalId(
    proposal_id: $proposal_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractByProposalIdQueryVariables,
  APITypes.ContractByProposalIdQuery
>;
export const contractByBuyerIdStatusPublicationDateTime = /* GraphQL */ `query ContractByBuyerIdStatusPublicationDateTime(
  $buyer_id: ID!
  $statusPublication_date_time: ModelContractByBuyerIdStatusPublicationDateTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContractFilterInput
  $limit: Int
  $nextToken: String
) {
  contractByBuyerIdStatusPublicationDateTime(
    buyer_id: $buyer_id
    statusPublication_date_time: $statusPublication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractByBuyerIdStatusPublicationDateTimeQueryVariables,
  APITypes.ContractByBuyerIdStatusPublicationDateTimeQuery
>;
export const contractBySellerIdStatusPublicationDateTime = /* GraphQL */ `query ContractBySellerIdStatusPublicationDateTime(
  $seller_id: ID!
  $statusPublication_date_time: ModelContractBySellerIdStatusPublicationDateTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContractFilterInput
  $limit: Int
  $nextToken: String
) {
  contractBySellerIdStatusPublicationDateTime(
    seller_id: $seller_id
    statusPublication_date_time: $statusPublication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractBySellerIdStatusPublicationDateTimeQueryVariables,
  APITypes.ContractBySellerIdStatusPublicationDateTimeQuery
>;
export const getContractProduct = /* GraphQL */ `query GetContractProduct($id: ID!) {
  getContractProduct(id: $id) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    product_id
    product {
      id
      artist_id
      artist {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      prices {
        nextToken
        __typename
      }
      slug
      category_id
      category {
        id
        display_name
        status
        created_at
        updated_at
        __typename
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      reviews {
        id
        user_id
        reviewer_id
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      dreambox_response_time
      dreambox_complete_time
      created_at
      updated_at
      __typename
    }
    product_display_name
    product_description
    contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_contract_product_prices {
      items {
        id
        contract_product_id
        extra_contract_product_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContractProductQueryVariables,
  APITypes.GetContractProductQuery
>;
export const listContractProducts = /* GraphQL */ `query ListContractProducts(
  $filter: ModelContractProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listContractProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      product_display_name
      product_description
      contract_product_prices {
        nextToken
        __typename
      }
      extra_contract_product_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContractProductsQueryVariables,
  APITypes.ListContractProductsQuery
>;
export const contractProductByContractId = /* GraphQL */ `query ContractProductByContractId(
  $contract_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContractProductFilterInput
  $limit: Int
  $nextToken: String
) {
  contractProductByContractId(
    contract_id: $contract_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      product_display_name
      product_description
      contract_product_prices {
        nextToken
        __typename
      }
      extra_contract_product_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractProductByContractIdQueryVariables,
  APITypes.ContractProductByContractIdQuery
>;
export const contractProductByProductId = /* GraphQL */ `query ContractProductByProductId(
  $product_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContractProductFilterInput
  $limit: Int
  $nextToken: String
) {
  contractProductByProductId(
    product_id: $product_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      product_display_name
      product_description
      contract_product_prices {
        nextToken
        __typename
      }
      extra_contract_product_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractProductByProductIdQueryVariables,
  APITypes.ContractProductByProductIdQuery
>;
export const getContractProductPrice = /* GraphQL */ `query GetContractProductPrice($id: ID!) {
  getContractProductPrice(id: $id) {
    id
    contract_product_id
    contract_product {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      product_display_name
      product_description
      contract_product_prices {
        nextToken
        __typename
      }
      extra_contract_product_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    extra_contract_product_id
    extra_contract_product {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      product_id
      product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      product_display_name
      product_description
      contract_product_prices {
        nextToken
        __typename
      }
      extra_contract_product_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_id
    price {
      id
      product_id
      Product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      type
      category
      fixed_amount
      fixed_centi_amount
      index
      min_amount
      min_centi_amount
      max_amount
      max_centi_amount
      currency_code
      percentage
      contract_product_prices {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_quantity
    price_display_name
    price_description
    price_type
    price_category
    price_fixed_amount
    price_fixed_centi_amount
    price_currency_code
    price_percentage
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContractProductPriceQueryVariables,
  APITypes.GetContractProductPriceQuery
>;
export const listContractProductPrices = /* GraphQL */ `query ListContractProductPrices(
  $filter: ModelContractProductPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  listContractProductPrices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contract_product_id
      contract_product {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      extra_contract_product_id
      extra_contract_product {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      price_id
      price {
        id
        product_id
        display_name
        description
        status
        type
        category
        fixed_amount
        fixed_centi_amount
        index
        min_amount
        min_centi_amount
        max_amount
        max_centi_amount
        currency_code
        percentage
        created_at
        updated_at
        __typename
      }
      price_quantity
      price_display_name
      price_description
      price_type
      price_category
      price_fixed_amount
      price_fixed_centi_amount
      price_currency_code
      price_percentage
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContractProductPricesQueryVariables,
  APITypes.ListContractProductPricesQuery
>;
export const contractProductPriceByContractProductId = /* GraphQL */ `query ContractProductPriceByContractProductId(
  $contract_product_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContractProductPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  contractProductPriceByContractProductId(
    contract_product_id: $contract_product_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contract_product_id
      contract_product {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      extra_contract_product_id
      extra_contract_product {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      price_id
      price {
        id
        product_id
        display_name
        description
        status
        type
        category
        fixed_amount
        fixed_centi_amount
        index
        min_amount
        min_centi_amount
        max_amount
        max_centi_amount
        currency_code
        percentage
        created_at
        updated_at
        __typename
      }
      price_quantity
      price_display_name
      price_description
      price_type
      price_category
      price_fixed_amount
      price_fixed_centi_amount
      price_currency_code
      price_percentage
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractProductPriceByContractProductIdQueryVariables,
  APITypes.ContractProductPriceByContractProductIdQuery
>;
export const contractProductPriceByExtraContractProductId = /* GraphQL */ `query ContractProductPriceByExtraContractProductId(
  $extra_contract_product_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContractProductPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  contractProductPriceByExtraContractProductId(
    extra_contract_product_id: $extra_contract_product_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contract_product_id
      contract_product {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      extra_contract_product_id
      extra_contract_product {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      price_id
      price {
        id
        product_id
        display_name
        description
        status
        type
        category
        fixed_amount
        fixed_centi_amount
        index
        min_amount
        min_centi_amount
        max_amount
        max_centi_amount
        currency_code
        percentage
        created_at
        updated_at
        __typename
      }
      price_quantity
      price_display_name
      price_description
      price_type
      price_category
      price_fixed_amount
      price_fixed_centi_amount
      price_currency_code
      price_percentage
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractProductPriceByExtraContractProductIdQueryVariables,
  APITypes.ContractProductPriceByExtraContractProductIdQuery
>;
export const contractProductPriceByPriceId = /* GraphQL */ `query ContractProductPriceByPriceId(
  $price_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContractProductPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  contractProductPriceByPriceId(
    price_id: $price_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contract_product_id
      contract_product {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      extra_contract_product_id
      extra_contract_product {
        id
        contract_id
        product_id
        product_display_name
        product_description
        created_at
        updated_at
        __typename
      }
      price_id
      price {
        id
        product_id
        display_name
        description
        status
        type
        category
        fixed_amount
        fixed_centi_amount
        index
        min_amount
        min_centi_amount
        max_amount
        max_centi_amount
        currency_code
        percentage
        created_at
        updated_at
        __typename
      }
      price_quantity
      price_display_name
      price_description
      price_type
      price_category
      price_fixed_amount
      price_fixed_centi_amount
      price_currency_code
      price_percentage
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractProductPriceByPriceIdQueryVariables,
  APITypes.ContractProductPriceByPriceIdQuery
>;
export const getExtraOffer = /* GraphQL */ `query GetExtraOffer($id: ID!) {
  getExtraOffer(id: $id) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    amount
    centi_amount
    currency_code
    status
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    extra_offer_prices {
      items {
        id
        extra_offer_id
        price_id
        price_quantity
        price_display_name
        price_description
        price_type
        price_category
        price_fixed_amount
        price_fixed_centi_amount
        price_currency_code
        price_percentage
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExtraOfferQueryVariables,
  APITypes.GetExtraOfferQuery
>;
export const listExtraOffers = /* GraphQL */ `query ListExtraOffers(
  $filter: ModelExtraOfferFilterInput
  $limit: Int
  $nextToken: String
) {
  listExtraOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency_code
      status
      payment_intents {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExtraOffersQueryVariables,
  APITypes.ListExtraOffersQuery
>;
export const extraOfferByContractId = /* GraphQL */ `query ExtraOfferByContractId(
  $contract_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelExtraOfferFilterInput
  $limit: Int
  $nextToken: String
) {
  extraOfferByContractId(
    contract_id: $contract_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency_code
      status
      payment_intents {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ExtraOfferByContractIdQueryVariables,
  APITypes.ExtraOfferByContractIdQuery
>;
export const getExtraOfferPrice = /* GraphQL */ `query GetExtraOfferPrice($id: ID!) {
  getExtraOfferPrice(id: $id) {
    id
    extra_offer_id
    extra_offer {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency_code
      status
      payment_intents {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_id
    price {
      id
      product_id
      Product {
        id
        artist_id
        display_name
        description
        status
        slug
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        dreambox_response_time
        dreambox_complete_time
        created_at
        updated_at
        __typename
      }
      display_name
      description
      status
      type
      category
      fixed_amount
      fixed_centi_amount
      index
      min_amount
      min_centi_amount
      max_amount
      max_centi_amount
      currency_code
      percentage
      contract_product_prices {
        nextToken
        __typename
      }
      extra_offer_prices {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    price_quantity
    price_display_name
    price_description
    price_type
    price_category
    price_fixed_amount
    price_fixed_centi_amount
    price_currency_code
    price_percentage
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExtraOfferPriceQueryVariables,
  APITypes.GetExtraOfferPriceQuery
>;
export const listExtraOfferPrices = /* GraphQL */ `query ListExtraOfferPrices(
  $filter: ModelExtraOfferPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  listExtraOfferPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      price_id
      price {
        id
        product_id
        display_name
        description
        status
        type
        category
        fixed_amount
        fixed_centi_amount
        index
        min_amount
        min_centi_amount
        max_amount
        max_centi_amount
        currency_code
        percentage
        created_at
        updated_at
        __typename
      }
      price_quantity
      price_display_name
      price_description
      price_type
      price_category
      price_fixed_amount
      price_fixed_centi_amount
      price_currency_code
      price_percentage
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExtraOfferPricesQueryVariables,
  APITypes.ListExtraOfferPricesQuery
>;
export const extraOfferPriceByExtraOfferId = /* GraphQL */ `query ExtraOfferPriceByExtraOfferId(
  $extra_offer_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelExtraOfferPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  extraOfferPriceByExtraOfferId(
    extra_offer_id: $extra_offer_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      price_id
      price {
        id
        product_id
        display_name
        description
        status
        type
        category
        fixed_amount
        fixed_centi_amount
        index
        min_amount
        min_centi_amount
        max_amount
        max_centi_amount
        currency_code
        percentage
        created_at
        updated_at
        __typename
      }
      price_quantity
      price_display_name
      price_description
      price_type
      price_category
      price_fixed_amount
      price_fixed_centi_amount
      price_currency_code
      price_percentage
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ExtraOfferPriceByExtraOfferIdQueryVariables,
  APITypes.ExtraOfferPriceByExtraOfferIdQuery
>;
export const extraOfferPriceByPriceId = /* GraphQL */ `query ExtraOfferPriceByPriceId(
  $price_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelExtraOfferPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  extraOfferPriceByPriceId(
    price_id: $price_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      price_id
      price {
        id
        product_id
        display_name
        description
        status
        type
        category
        fixed_amount
        fixed_centi_amount
        index
        min_amount
        min_centi_amount
        max_amount
        max_centi_amount
        currency_code
        percentage
        created_at
        updated_at
        __typename
      }
      price_quantity
      price_display_name
      price_description
      price_type
      price_category
      price_fixed_amount
      price_fixed_centi_amount
      price_currency_code
      price_percentage
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ExtraOfferPriceByPriceIdQueryVariables,
  APITypes.ExtraOfferPriceByPriceIdQuery
>;
export const getMilestone = /* GraphQL */ `query GetMilestone($id: ID!) {
  getMilestone(id: $id) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    step_index
    step_name
    deadline_number
    deadline_type
    start_date_time
    end_date_time
    delivery_date_time
    free_revision_count
    current_revision_index
    current_revision_id
    description
    deadline_extensions
    price_percentage
    amount
    centi_amount
    currency_code
    status
    milestone_events {
      items {
        id
        milestone_id
        event_id
        milestone_status
        milestone_step_index
        milestone_step_name
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    revisions {
      items {
        id
        index
        status
        request_message
        request_rejection_message
        milestone_id
        additional_deadline_number
        additional_deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        deadline_extensions
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMilestoneQueryVariables,
  APITypes.GetMilestoneQuery
>;
export const listMilestones = /* GraphQL */ `query ListMilestones(
  $filter: ModelMilestoneFilterInput
  $limit: Int
  $nextToken: String
) {
  listMilestones(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMilestonesQueryVariables,
  APITypes.ListMilestonesQuery
>;
export const milestoneByContractId = /* GraphQL */ `query MilestoneByContractId(
  $contract_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMilestoneFilterInput
  $limit: Int
  $nextToken: String
) {
  milestoneByContractId(
    contract_id: $contract_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MilestoneByContractIdQueryVariables,
  APITypes.MilestoneByContractIdQuery
>;
export const getRevision = /* GraphQL */ `query GetRevision($id: ID!) {
  getRevision(id: $id) {
    id
    index
    status
    request_message
    request_rejection_message
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    additional_deadline_number
    additional_deadline_type
    start_date_time
    end_date_time
    images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    request_revision_images {
      items {
        id
        height
        width
        file_name
        src_url
        thumbnail_url
        raw_src_url
        raw_thumbnail_url
        artwork_id
        job_id
        milestone_id
        revision_id
        revision_request_revision_id
        message_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    delivery_date_time
    deadline_extensions
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRevisionQueryVariables,
  APITypes.GetRevisionQuery
>;
export const listRevisions = /* GraphQL */ `query ListRevisions(
  $filter: ModelRevisionFilterInput
  $limit: Int
  $nextToken: String
) {
  listRevisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      index
      status
      request_message
      request_rejection_message
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      additional_deadline_number
      additional_deadline_type
      start_date_time
      end_date_time
      images {
        nextToken
        __typename
      }
      request_revision_images {
        nextToken
        __typename
      }
      delivery_date_time
      deadline_extensions
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRevisionsQueryVariables,
  APITypes.ListRevisionsQuery
>;
export const revisionByMilestoneId = /* GraphQL */ `query RevisionByMilestoneId(
  $milestone_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRevisionFilterInput
  $limit: Int
  $nextToken: String
) {
  revisionByMilestoneId(
    milestone_id: $milestone_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      index
      status
      request_message
      request_rejection_message
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      additional_deadline_number
      additional_deadline_type
      start_date_time
      end_date_time
      images {
        nextToken
        __typename
      }
      request_revision_images {
        nextToken
        __typename
      }
      delivery_date_time
      deadline_extensions
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RevisionByMilestoneIdQueryVariables,
  APITypes.RevisionByMilestoneIdQuery
>;
export const getContractEvent = /* GraphQL */ `query GetContractEvent($id: ID!) {
  getContractEvent(id: $id) {
    id
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    contract_status
    contract_amount
    contract_currency
    extra_offer_status
    extra_offer_amount
    extra_offer_currency
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContractEventQueryVariables,
  APITypes.GetContractEventQuery
>;
export const listContractEvents = /* GraphQL */ `query ListContractEvents(
  $filter: ModelContractEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listContractEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      event_id
      event {
        id
        type
        note
        created_at
        updated_at
        __typename
      }
      contract_status
      contract_amount
      contract_currency
      extra_offer_status
      extra_offer_amount
      extra_offer_currency
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContractEventsQueryVariables,
  APITypes.ListContractEventsQuery
>;
export const contractEventByContractId = /* GraphQL */ `query ContractEventByContractId(
  $contract_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContractEventFilterInput
  $limit: Int
  $nextToken: String
) {
  contractEventByContractId(
    contract_id: $contract_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      event_id
      event {
        id
        type
        note
        created_at
        updated_at
        __typename
      }
      contract_status
      contract_amount
      contract_currency
      extra_offer_status
      extra_offer_amount
      extra_offer_currency
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractEventByContractIdQueryVariables,
  APITypes.ContractEventByContractIdQuery
>;
export const contractEventByEventId = /* GraphQL */ `query ContractEventByEventId(
  $event_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContractEventFilterInput
  $limit: Int
  $nextToken: String
) {
  contractEventByEventId(
    event_id: $event_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      event_id
      event {
        id
        type
        note
        created_at
        updated_at
        __typename
      }
      contract_status
      contract_amount
      contract_currency
      extra_offer_status
      extra_offer_amount
      extra_offer_currency
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractEventByEventIdQueryVariables,
  APITypes.ContractEventByEventIdQuery
>;
export const getPaymentIntentEvent = /* GraphQL */ `query GetPaymentIntentEvent($id: ID!) {
  getPaymentIntentEvent(id: $id) {
    id
    payment_intent_id
    payment_intent {
      id
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_payment_contract_id
      extra_payment_contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      extra_offer_id
      extra_offer {
        id
        contract_id
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      amount
      centi_amount
      currency
      payout_owing_to_seller_amount
      payout_owing_to_seller_centi_amount
      fee_owing_to_momo_amount
      fee_owing_to_momo_centi_amount
      dreamerly_fee_balance_amount
      dreamerly_fee_balance_centi_amount
      display_fee_owing_to_momo_amount
      display_fee_owing_to_momo_centi_amount
      display_dreamerly_fee_balance_amount
      display_dreamerly_fee_balance_centi_amount
      payment_purpose
      billing_details
      description
      last_payment_error
      next_action
      disputed
      disputed_at
      refunded
      refunded_amount
      refunded_centi_amount
      refunded_at
      status
      payment_provider
      payment_method
      paid_at
      cancelled_at
      momo_trans_id
      momo_request_id
      momo_payment_url
      momo_payment_url_expires_at
      stripe_payment_intent_id
      stripe_payment_method_country
      refunds {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    payment_intent_status
    payment_intent_amount
    payment_intent_currency
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentIntentEventQueryVariables,
  APITypes.GetPaymentIntentEventQuery
>;
export const listPaymentIntentEvents = /* GraphQL */ `query ListPaymentIntentEvents(
  $filter: ModelPaymentIntentEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentIntentEvents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      payment_intent_id
      payment_intent {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      event_id
      event {
        id
        type
        note
        created_at
        updated_at
        __typename
      }
      payment_intent_status
      payment_intent_amount
      payment_intent_currency
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentIntentEventsQueryVariables,
  APITypes.ListPaymentIntentEventsQuery
>;
export const paymentIntentEventByPaymentIntentId = /* GraphQL */ `query PaymentIntentEventByPaymentIntentId(
  $payment_intent_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentIntentEventFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentIntentEventByPaymentIntentId(
    payment_intent_id: $payment_intent_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      payment_intent_id
      payment_intent {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      event_id
      event {
        id
        type
        note
        created_at
        updated_at
        __typename
      }
      payment_intent_status
      payment_intent_amount
      payment_intent_currency
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentIntentEventByPaymentIntentIdQueryVariables,
  APITypes.PaymentIntentEventByPaymentIntentIdQuery
>;
export const paymentIntentEventByEventId = /* GraphQL */ `query PaymentIntentEventByEventId(
  $event_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentIntentEventFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentIntentEventByEventId(
    event_id: $event_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      payment_intent_id
      payment_intent {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      event_id
      event {
        id
        type
        note
        created_at
        updated_at
        __typename
      }
      payment_intent_status
      payment_intent_amount
      payment_intent_currency
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentIntentEventByEventIdQueryVariables,
  APITypes.PaymentIntentEventByEventIdQuery
>;
export const getMilestoneEvent = /* GraphQL */ `query GetMilestoneEvent($id: ID!) {
  getMilestoneEvent(id: $id) {
    id
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    event_id
    event {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    milestone_status
    milestone_step_index
    milestone_step_name
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMilestoneEventQueryVariables,
  APITypes.GetMilestoneEventQuery
>;
export const listMilestoneEvents = /* GraphQL */ `query ListMilestoneEvents(
  $filter: ModelMilestoneEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listMilestoneEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      event_id
      event {
        id
        type
        note
        created_at
        updated_at
        __typename
      }
      milestone_status
      milestone_step_index
      milestone_step_name
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMilestoneEventsQueryVariables,
  APITypes.ListMilestoneEventsQuery
>;
export const milestoneEventByMilestoneId = /* GraphQL */ `query MilestoneEventByMilestoneId(
  $milestone_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMilestoneEventFilterInput
  $limit: Int
  $nextToken: String
) {
  milestoneEventByMilestoneId(
    milestone_id: $milestone_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      event_id
      event {
        id
        type
        note
        created_at
        updated_at
        __typename
      }
      milestone_status
      milestone_step_index
      milestone_step_name
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MilestoneEventByMilestoneIdQueryVariables,
  APITypes.MilestoneEventByMilestoneIdQuery
>;
export const milestoneEventByEventId = /* GraphQL */ `query MilestoneEventByEventId(
  $event_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMilestoneEventFilterInput
  $limit: Int
  $nextToken: String
) {
  milestoneEventByEventId(
    event_id: $event_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      event_id
      event {
        id
        type
        note
        created_at
        updated_at
        __typename
      }
      milestone_status
      milestone_step_index
      milestone_step_name
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MilestoneEventByEventIdQueryVariables,
  APITypes.MilestoneEventByEventIdQuery
>;
export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    type
    note
    contract_events {
      items {
        id
        contract_id
        event_id
        contract_status
        contract_amount
        contract_currency
        extra_offer_status
        extra_offer_amount
        extra_offer_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_intent_events {
      items {
        id
        payment_intent_id
        event_id
        payment_intent_status
        payment_intent_amount
        payment_intent_currency
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    milestone_events {
      items {
        id
        milestone_id
        event_id
        milestone_status
        milestone_step_index
        milestone_step_name
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const listEvents = /* GraphQL */ `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      note
      contract_events {
        nextToken
        __typename
      }
      payment_intent_events {
        nextToken
        __typename
      }
      milestone_events {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const getReview = /* GraphQL */ `query GetReview($id: ID!) {
  getReview(id: $id) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    reviewer_id
    reviewer {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    rating
    comment
    type
    publication_date_time
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const listReviews = /* GraphQL */ `query ListReviews(
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      reviewer_id
      reviewer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      rating
      comment
      type
      publication_date_time
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsQueryVariables,
  APITypes.ListReviewsQuery
>;
export const reviewByUserIdTypePublicationDateTime = /* GraphQL */ `query ReviewByUserIdTypePublicationDateTime(
  $user_id: ID!
  $typePublication_date_time: ModelReviewByUserIdTypePublicationDateTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewByUserIdTypePublicationDateTime(
    user_id: $user_id
    typePublication_date_time: $typePublication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      reviewer_id
      reviewer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      rating
      comment
      type
      publication_date_time
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewByUserIdTypePublicationDateTimeQueryVariables,
  APITypes.ReviewByUserIdTypePublicationDateTimeQuery
>;
export const reviewByReviewerIdTypePublicationDateTime = /* GraphQL */ `query ReviewByReviewerIdTypePublicationDateTime(
  $reviewer_id: ID!
  $typePublication_date_time: ModelReviewByReviewerIdTypePublicationDateTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewByReviewerIdTypePublicationDateTime(
    reviewer_id: $reviewer_id
    typePublication_date_time: $typePublication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      reviewer_id
      reviewer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      rating
      comment
      type
      publication_date_time
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewByReviewerIdTypePublicationDateTimeQueryVariables,
  APITypes.ReviewByReviewerIdTypePublicationDateTimeQuery
>;
export const reviewByContractId = /* GraphQL */ `query ReviewByContractId(
  $contract_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewByContractId(
    contract_id: $contract_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      reviewer_id
      reviewer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      rating
      comment
      type
      publication_date_time
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewByContractIdQueryVariables,
  APITypes.ReviewByContractIdQuery
>;
export const getUserFollow = /* GraphQL */ `query GetUserFollow($id: ID!) {
  getUserFollow(id: $id) {
    id
    leader_id
    follower_id
    leader {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    follower {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserFollowQueryVariables,
  APITypes.GetUserFollowQuery
>;
export const listUserFollows = /* GraphQL */ `query ListUserFollows(
  $filter: ModelUserFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserFollows(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      leader_id
      follower_id
      leader {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      follower {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserFollowsQueryVariables,
  APITypes.ListUserFollowsQuery
>;
export const userFollowByLeaderIdCreatedAt = /* GraphQL */ `query UserFollowByLeaderIdCreatedAt(
  $leader_id: ID!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  userFollowByLeaderIdCreatedAt(
    leader_id: $leader_id
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      leader_id
      follower_id
      leader {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      follower {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserFollowByLeaderIdCreatedAtQueryVariables,
  APITypes.UserFollowByLeaderIdCreatedAtQuery
>;
export const userFollowByLeaderIdFollowerId = /* GraphQL */ `query UserFollowByLeaderIdFollowerId(
  $leader_id: ID!
  $follower_id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  userFollowByLeaderIdFollowerId(
    leader_id: $leader_id
    follower_id: $follower_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      leader_id
      follower_id
      leader {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      follower {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserFollowByLeaderIdFollowerIdQueryVariables,
  APITypes.UserFollowByLeaderIdFollowerIdQuery
>;
export const userFollowByFollowerIdCreatedAt = /* GraphQL */ `query UserFollowByFollowerIdCreatedAt(
  $follower_id: ID!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  userFollowByFollowerIdCreatedAt(
    follower_id: $follower_id
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      leader_id
      follower_id
      leader {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      follower {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserFollowByFollowerIdCreatedAtQueryVariables,
  APITypes.UserFollowByFollowerIdCreatedAtQuery
>;
export const getDreamerlyBalance = /* GraphQL */ `query GetDreamerlyBalance($id: ID!) {
  getDreamerlyBalance(id: $id) {
    id
    currency_code
    sent_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    received_balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDreamerlyBalanceQueryVariables,
  APITypes.GetDreamerlyBalanceQuery
>;
export const listDreamerlyBalances = /* GraphQL */ `query ListDreamerlyBalances(
  $filter: ModelDreamerlyBalanceFilterInput
  $limit: Int
  $nextToken: String
) {
  listDreamerlyBalances(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      currency_code
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDreamerlyBalancesQueryVariables,
  APITypes.ListDreamerlyBalancesQuery
>;
export const getBalanceTransfer = /* GraphQL */ `query GetBalanceTransfer($id: ID!) {
  getBalanceTransfer(id: $id) {
    id
    reason
    amount
    centi_amount
    dreamerly_fee
    dreamerly_centi_fee
    payment_provider_fee
    payment_provider_centi_fee
    net_amount
    net_centi_amount
    payment_provider
    currency_code
    note
    publication_date_time
    milestone_id
    milestone {
      id
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      milestone_events {
        nextToken
        __typename
      }
      revisions {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    contract_id
    contract {
      id
      status
      publication_date_time
      is_private_artwork
      is_comercial_artwork
      file_formats
      description
      amount
      centi_amount
      currency_code
      term_of_service
      state_name
      current_milestone_id
      current_milestone_index
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      proposal_id
      proposal {
        id
        job_id
        seller_id
        seller_comment
        status
        publication_date_time
        proposal_type
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      contract_products {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      extra_payment_payment_intents {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      milestones {
        nextToken
        __typename
      }
      contract_events {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      extra_offers {
        nextToken
        __typename
      }
      artworks {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    from_user_id
    from_user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    to_user_id
    to_user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    from_dreamerly_balance_id
    from_dreamerly_balance {
      id
      currency_code
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    to_dreamerly_balance_id
    to_dreamerly_balance {
      id
      currency_code
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBalanceTransferQueryVariables,
  APITypes.GetBalanceTransferQuery
>;
export const listBalanceTransfers = /* GraphQL */ `query ListBalanceTransfers(
  $filter: ModelBalanceTransferFilterInput
  $limit: Int
  $nextToken: String
) {
  listBalanceTransfers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      reason
      amount
      centi_amount
      dreamerly_fee
      dreamerly_centi_fee
      payment_provider_fee
      payment_provider_centi_fee
      net_amount
      net_centi_amount
      payment_provider
      currency_code
      note
      publication_date_time
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      from_user_id
      from_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      to_user_id
      to_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      from_dreamerly_balance_id
      from_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      to_dreamerly_balance_id
      to_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBalanceTransfersQueryVariables,
  APITypes.ListBalanceTransfersQuery
>;
export const balanceTransferByMilestoneIdPublicationDateTime = /* GraphQL */ `query BalanceTransferByMilestoneIdPublicationDateTime(
  $milestone_id: ID!
  $publication_date_time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBalanceTransferFilterInput
  $limit: Int
  $nextToken: String
) {
  balanceTransferByMilestoneIdPublicationDateTime(
    milestone_id: $milestone_id
    publication_date_time: $publication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reason
      amount
      centi_amount
      dreamerly_fee
      dreamerly_centi_fee
      payment_provider_fee
      payment_provider_centi_fee
      net_amount
      net_centi_amount
      payment_provider
      currency_code
      note
      publication_date_time
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      from_user_id
      from_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      to_user_id
      to_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      from_dreamerly_balance_id
      from_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      to_dreamerly_balance_id
      to_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BalanceTransferByMilestoneIdPublicationDateTimeQueryVariables,
  APITypes.BalanceTransferByMilestoneIdPublicationDateTimeQuery
>;
export const balanceTransferByContractIdPublicationDateTime = /* GraphQL */ `query BalanceTransferByContractIdPublicationDateTime(
  $contract_id: ID!
  $publication_date_time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBalanceTransferFilterInput
  $limit: Int
  $nextToken: String
) {
  balanceTransferByContractIdPublicationDateTime(
    contract_id: $contract_id
    publication_date_time: $publication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reason
      amount
      centi_amount
      dreamerly_fee
      dreamerly_centi_fee
      payment_provider_fee
      payment_provider_centi_fee
      net_amount
      net_centi_amount
      payment_provider
      currency_code
      note
      publication_date_time
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      from_user_id
      from_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      to_user_id
      to_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      from_dreamerly_balance_id
      from_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      to_dreamerly_balance_id
      to_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BalanceTransferByContractIdPublicationDateTimeQueryVariables,
  APITypes.BalanceTransferByContractIdPublicationDateTimeQuery
>;
export const balanceTransferByFromUserIdPublicationDateTime = /* GraphQL */ `query BalanceTransferByFromUserIdPublicationDateTime(
  $from_user_id: ID!
  $publication_date_time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBalanceTransferFilterInput
  $limit: Int
  $nextToken: String
) {
  balanceTransferByFromUserIdPublicationDateTime(
    from_user_id: $from_user_id
    publication_date_time: $publication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reason
      amount
      centi_amount
      dreamerly_fee
      dreamerly_centi_fee
      payment_provider_fee
      payment_provider_centi_fee
      net_amount
      net_centi_amount
      payment_provider
      currency_code
      note
      publication_date_time
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      from_user_id
      from_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      to_user_id
      to_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      from_dreamerly_balance_id
      from_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      to_dreamerly_balance_id
      to_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BalanceTransferByFromUserIdPublicationDateTimeQueryVariables,
  APITypes.BalanceTransferByFromUserIdPublicationDateTimeQuery
>;
export const balanceTransferByToUserIdPublicationDateTime = /* GraphQL */ `query BalanceTransferByToUserIdPublicationDateTime(
  $to_user_id: ID!
  $publication_date_time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBalanceTransferFilterInput
  $limit: Int
  $nextToken: String
) {
  balanceTransferByToUserIdPublicationDateTime(
    to_user_id: $to_user_id
    publication_date_time: $publication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reason
      amount
      centi_amount
      dreamerly_fee
      dreamerly_centi_fee
      payment_provider_fee
      payment_provider_centi_fee
      net_amount
      net_centi_amount
      payment_provider
      currency_code
      note
      publication_date_time
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      from_user_id
      from_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      to_user_id
      to_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      from_dreamerly_balance_id
      from_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      to_dreamerly_balance_id
      to_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BalanceTransferByToUserIdPublicationDateTimeQueryVariables,
  APITypes.BalanceTransferByToUserIdPublicationDateTimeQuery
>;
export const balanceTransferByFromDreamerlyBalanceIdTypePublicationDateTime = /* GraphQL */ `query BalanceTransferByFromDreamerlyBalanceIdTypePublicationDateTime(
  $from_dreamerly_balance_id: ID!
  $publication_date_time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBalanceTransferFilterInput
  $limit: Int
  $nextToken: String
) {
  balanceTransferByFromDreamerlyBalanceIdTypePublicationDateTime(
    from_dreamerly_balance_id: $from_dreamerly_balance_id
    publication_date_time: $publication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reason
      amount
      centi_amount
      dreamerly_fee
      dreamerly_centi_fee
      payment_provider_fee
      payment_provider_centi_fee
      net_amount
      net_centi_amount
      payment_provider
      currency_code
      note
      publication_date_time
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      from_user_id
      from_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      to_user_id
      to_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      from_dreamerly_balance_id
      from_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      to_dreamerly_balance_id
      to_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BalanceTransferByFromDreamerlyBalanceIdTypePublicationDateTimeQueryVariables,
  APITypes.BalanceTransferByFromDreamerlyBalanceIdTypePublicationDateTimeQuery
>;
export const balanceTransferByToDreamerlyBalanceIdPublicationDateTime = /* GraphQL */ `query BalanceTransferByToDreamerlyBalanceIdPublicationDateTime(
  $to_dreamerly_balance_id: ID!
  $publication_date_time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBalanceTransferFilterInput
  $limit: Int
  $nextToken: String
) {
  balanceTransferByToDreamerlyBalanceIdPublicationDateTime(
    to_dreamerly_balance_id: $to_dreamerly_balance_id
    publication_date_time: $publication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reason
      amount
      centi_amount
      dreamerly_fee
      dreamerly_centi_fee
      payment_provider_fee
      payment_provider_centi_fee
      net_amount
      net_centi_amount
      payment_provider
      currency_code
      note
      publication_date_time
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      from_user_id
      from_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      to_user_id
      to_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      from_dreamerly_balance_id
      from_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      to_dreamerly_balance_id
      to_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BalanceTransferByToDreamerlyBalanceIdPublicationDateTimeQueryVariables,
  APITypes.BalanceTransferByToDreamerlyBalanceIdPublicationDateTimeQuery
>;
export const balanceTransferByBalanceTransactionId = /* GraphQL */ `query BalanceTransferByBalanceTransactionId(
  $balance_transaction_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBalanceTransferFilterInput
  $limit: Int
  $nextToken: String
) {
  balanceTransferByBalanceTransactionId(
    balance_transaction_id: $balance_transaction_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reason
      amount
      centi_amount
      dreamerly_fee
      dreamerly_centi_fee
      payment_provider_fee
      payment_provider_centi_fee
      net_amount
      net_centi_amount
      payment_provider
      currency_code
      note
      publication_date_time
      milestone_id
      milestone {
        id
        contract_id
        step_index
        step_name
        deadline_number
        deadline_type
        start_date_time
        end_date_time
        delivery_date_time
        free_revision_count
        current_revision_index
        current_revision_id
        description
        deadline_extensions
        price_percentage
        amount
        centi_amount
        currency_code
        status
        created_at
        updated_at
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      from_user_id
      from_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      to_user_id
      to_user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      from_dreamerly_balance_id
      from_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      to_dreamerly_balance_id
      to_dreamerly_balance {
        id
        currency_code
        created_at
        updated_at
        __typename
      }
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BalanceTransferByBalanceTransactionIdQueryVariables,
  APITypes.BalanceTransferByBalanceTransactionIdQuery
>;
export const getPaymentProviderFeePayment = /* GraphQL */ `query GetPaymentProviderFeePayment($id: ID!) {
  getPaymentProviderFeePayment(id: $id) {
    id
    amount
    centi_amount
    currency_code
    publication_date_time
    payment_provider
    description
    invoice_number
    from_dreamerly_balance_id
    to_dreamerly_balance_id
    balance_transaction_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentProviderFeePaymentQueryVariables,
  APITypes.GetPaymentProviderFeePaymentQuery
>;
export const listPaymentProviderFeePayments = /* GraphQL */ `query ListPaymentProviderFeePayments(
  $filter: ModelPaymentProviderFeePaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentProviderFeePayments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      centi_amount
      currency_code
      publication_date_time
      payment_provider
      description
      invoice_number
      from_dreamerly_balance_id
      to_dreamerly_balance_id
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentProviderFeePaymentsQueryVariables,
  APITypes.ListPaymentProviderFeePaymentsQuery
>;
export const paymentProviderFeePaymentByBalanceTransactionId = /* GraphQL */ `query PaymentProviderFeePaymentByBalanceTransactionId(
  $balance_transaction_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentProviderFeePaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentProviderFeePaymentByBalanceTransactionId(
    balance_transaction_id: $balance_transaction_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      centi_amount
      currency_code
      publication_date_time
      payment_provider
      description
      invoice_number
      from_dreamerly_balance_id
      to_dreamerly_balance_id
      balance_transaction_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentProviderFeePaymentByBalanceTransactionIdQueryVariables,
  APITypes.PaymentProviderFeePaymentByBalanceTransactionIdQuery
>;
export const getBalanceTransaction = /* GraphQL */ `query GetBalanceTransaction($id: ID!) {
  getBalanceTransaction(id: $id) {
    id
    payment_intents {
      items {
        id
        buyer_id
        contract_id
        extra_payment_contract_id
        extra_offer_id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        display_fee_owing_to_momo_amount
        display_fee_owing_to_momo_centi_amount
        display_dreamerly_fee_balance_amount
        display_dreamerly_fee_balance_centi_amount
        payment_purpose
        billing_details
        description
        last_payment_error
        next_action
        disputed
        disputed_at
        refunded
        refunded_amount
        refunded_centi_amount
        refunded_at
        status
        payment_provider
        payment_method
        paid_at
        cancelled_at
        momo_trans_id
        momo_request_id
        momo_payment_url
        momo_payment_url_expires_at
        stripe_payment_intent_id
        stripe_payment_method_country
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payouts {
      items {
        id
        amount
        centi_amount
        currency
        billing_details
        seller_id
        description
        arrival_date_time
        status
        payment_provider
        balance_transaction_id
        request_amount
        request_centi_amount
        request_date_time
        bank_account_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    refunds {
      items {
        id
        amount
        centi_amount
        currency
        payout_owing_to_seller_amount
        payout_owing_to_seller_centi_amount
        fee_owing_to_momo_amount
        fee_owing_to_momo_centi_amount
        dreamerly_fee_balance_amount
        dreamerly_fee_balance_centi_amount
        billing_details
        buyer_id
        seller_id
        description
        payment_intent_id
        contract_id
        reason
        status
        response
        payment_provider
        momo_trans_id
        approved_at
        rejected_at
        refunded_at
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    balance_transfers {
      items {
        id
        reason
        amount
        centi_amount
        dreamerly_fee
        dreamerly_centi_fee
        payment_provider_fee
        payment_provider_centi_fee
        net_amount
        net_centi_amount
        payment_provider
        currency_code
        note
        publication_date_time
        milestone_id
        contract_id
        from_user_id
        to_user_id
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    payment_provider_fee_payments {
      items {
        id
        amount
        centi_amount
        currency_code
        publication_date_time
        payment_provider
        description
        invoice_number
        from_dreamerly_balance_id
        to_dreamerly_balance_id
        balance_transaction_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    type
    amount
    centi_amount
    currency_code
    note
    publication_date_time
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBalanceTransactionQueryVariables,
  APITypes.GetBalanceTransactionQuery
>;
export const listBalanceTransactions = /* GraphQL */ `query ListBalanceTransactions(
  $filter: ModelBalanceTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listBalanceTransactions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      payment_provider_fee_payments {
        nextToken
        __typename
      }
      type
      amount
      centi_amount
      currency_code
      note
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBalanceTransactionsQueryVariables,
  APITypes.ListBalanceTransactionsQuery
>;
export const balanceTransactionByUserIdTypePublicationDateTime = /* GraphQL */ `query BalanceTransactionByUserIdTypePublicationDateTime(
  $user_id: ID!
  $typePublication_date_time: ModelBalanceTransactionByUserIdTypePublicationDateTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBalanceTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  balanceTransactionByUserIdTypePublicationDateTime(
    user_id: $user_id
    typePublication_date_time: $typePublication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      refunds {
        nextToken
        __typename
      }
      balance_transfers {
        nextToken
        __typename
      }
      payment_provider_fee_payments {
        nextToken
        __typename
      }
      type
      amount
      centi_amount
      currency_code
      note
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BalanceTransactionByUserIdTypePublicationDateTimeQueryVariables,
  APITypes.BalanceTransactionByUserIdTypePublicationDateTimeQuery
>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    id
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    notification_sender_id
    notification_sender {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    title
    body
    publication_date_time
    is_read
    is_removed
    notification_type
    underlying_object_id
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      notification_sender_id
      notification_sender {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      title
      body
      publication_date_time
      is_read
      is_removed
      notification_type
      underlying_object_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const notificationByUserIdPublicationDateTime = /* GraphQL */ `query NotificationByUserIdPublicationDateTime(
  $user_id: ID!
  $publication_date_time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationByUserIdPublicationDateTime(
    user_id: $user_id
    publication_date_time: $publication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      notification_sender_id
      notification_sender {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      title
      body
      publication_date_time
      is_read
      is_removed
      notification_type
      underlying_object_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationByUserIdPublicationDateTimeQueryVariables,
  APITypes.NotificationByUserIdPublicationDateTimeQuery
>;
export const notificationByUserIdIsReadPublicationDateTime = /* GraphQL */ `query NotificationByUserIdIsReadPublicationDateTime(
  $user_id: ID!
  $is_readPublication_date_time: ModelNotificationByUserIdIsReadPublicationDateTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationByUserIdIsReadPublicationDateTime(
    user_id: $user_id
    is_readPublication_date_time: $is_readPublication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      notification_sender_id
      notification_sender {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      title
      body
      publication_date_time
      is_read
      is_removed
      notification_type
      underlying_object_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationByUserIdIsReadPublicationDateTimeQueryVariables,
  APITypes.NotificationByUserIdIsReadPublicationDateTimeQuery
>;
export const notificationByNotificationSenderIdPublicationDateTime = /* GraphQL */ `query NotificationByNotificationSenderIdPublicationDateTime(
  $notification_sender_id: ID!
  $publication_date_time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationByNotificationSenderIdPublicationDateTime(
    notification_sender_id: $notification_sender_id
    publication_date_time: $publication_date_time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      notification_sender_id
      notification_sender {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      title
      body
      publication_date_time
      is_read
      is_removed
      notification_type
      underlying_object_id
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationByNotificationSenderIdPublicationDateTimeQueryVariables,
  APITypes.NotificationByNotificationSenderIdPublicationDateTimeQuery
>;
export const getCharacter = /* GraphQL */ `query GetCharacter($id: ID!) {
  getCharacter(id: $id) {
    id
    display_name
    charname
    character_type
    user_id
    user {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      is_artist
      seller_onboarding_stage
      buyer_jobs {
        nextToken
        __typename
      }
      seller_jobs {
        nextToken
        __typename
      }
      term_of_service
      cccd
      mst
      cccd_images
      mst_stage
      seller_proposals {
        nextToken
        __typename
      }
      profile_picture_url
      cover_picture_url
      balance_amount
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      buyer_star_quantity
      buyer_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      commission_cumulative_late_seconds
      commission_max_late_seconds
      device_tokens
      is_selling
      is_selling_abroad
      products {
        nextToken
        __typename
      }
      last_time_open_list_notification
      discount_code
      artworks {
        nextToken
        __typename
      }
      buyer_artworks {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      user_channels {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      payment_intents {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      selling_refunds {
        nextToken
        __typename
      }
      buying_refunds {
        nextToken
        __typename
      }
      seller_contracts {
        nextToken
        __typename
      }
      buyer_contracts {
        nextToken
        __typename
      }
      received_reviews {
        nextToken
        __typename
      }
      given_reviews {
        nextToken
        __typename
      }
      sent_balance_transfers {
        nextToken
        __typename
      }
      received_balance_transfers {
        nextToken
        __typename
      }
      balance_transactions {
        nextToken
        __typename
      }
      follower_quantity
      leader_quantity
      followers {
        nextToken
        __typename
      }
      leaders {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      notification_senders {
        nextToken
        __typename
      }
      push_tokens {
        nextToken
        __typename
      }
      has_unread_commissions
      has_unread_jobs
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      characters {
        nextToken
        __typename
      }
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
      additional_info
      created_at
      updated_at
      __typename
    }
    description
    strengths
    flaws
    gender
    race
    birth_date
    age
    role
    hobbies
    dislikes
    profile_picture_url
    cover_photo_url
    character_items {
      items {
        id
        item_type
        character_id
        artwork_id
        job_id
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCharacterQueryVariables,
  APITypes.GetCharacterQuery
>;
export const listCharacters = /* GraphQL */ `query ListCharacters(
  $filter: ModelCharacterFilterInput
  $limit: Int
  $nextToken: String
) {
  listCharacters(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      display_name
      charname
      character_type
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      strengths
      flaws
      gender
      race
      birth_date
      age
      role
      hobbies
      dislikes
      profile_picture_url
      cover_photo_url
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCharactersQueryVariables,
  APITypes.ListCharactersQuery
>;
export const characterByCharname = /* GraphQL */ `query CharacterByCharname(
  $charname: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCharacterFilterInput
  $limit: Int
  $nextToken: String
) {
  characterByCharname(
    charname: $charname
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      display_name
      charname
      character_type
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      strengths
      flaws
      gender
      race
      birth_date
      age
      role
      hobbies
      dislikes
      profile_picture_url
      cover_photo_url
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CharacterByCharnameQueryVariables,
  APITypes.CharacterByCharnameQuery
>;
export const characterByUserId = /* GraphQL */ `query CharacterByUserId(
  $user_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCharacterFilterInput
  $limit: Int
  $nextToken: String
) {
  characterByUserId(
    user_id: $user_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      display_name
      charname
      character_type
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      strengths
      flaws
      gender
      race
      birth_date
      age
      role
      hobbies
      dislikes
      profile_picture_url
      cover_photo_url
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CharacterByUserIdQueryVariables,
  APITypes.CharacterByUserIdQuery
>;
export const getCharacterItem = /* GraphQL */ `query GetCharacterItem($id: ID!) {
  getCharacterItem(id: $id) {
    id
    item_type
    character_id
    character {
      id
      display_name
      charname
      character_type
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      description
      strengths
      flaws
      gender
      race
      birth_date
      age
      role
      hobbies
      dislikes
      profile_picture_url
      cover_photo_url
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    artwork_id
    artwork {
      id
      cover_image_height
      cover_image_width
      cover_image_src_url
      cover_image_thumbnail_url
      title
      likes_count
      comments_count
      description
      publication_date_time
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      is_removed
      images {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      product_artworks {
        nextToken
        __typename
      }
      contract_id
      contract {
        id
        status
        publication_date_time
        is_private_artwork
        is_comercial_artwork
        file_formats
        description
        amount
        centi_amount
        currency_code
        term_of_service
        state_name
        current_milestone_id
        current_milestone_index
        job_id
        proposal_id
        buyer_id
        seller_id
        created_at
        updated_at
        __typename
      }
      is_buyer_showcase
      is_seller_showcase
      tag_items {
        nextToken
        __typename
      }
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    job_id
    job {
      id
      title
      description
      min_budget
      min_centi_budget
      max_budget
      max_centi_budget
      currency_code
      status
      is_exclusive
      publication_date_time
      deadline_date_time
      expiration_date_time
      is_comercial_artwork
      is_dreambox
      is_private_artwork
      file_format
      buyer_id
      buyer {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      seller_id
      seller {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        cccd_images
        mst_stage
        profile_picture_url
        cover_picture_url
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        is_selling_abroad
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        bank_name
        account_holder_name
        account_last_four
        address_country_code
        city
        address_line1
        additional_info
        created_at
        updated_at
        __typename
      }
      proposals {
        nextToken
        __typename
      }
      images {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      contacted_seller_ids
      character_items {
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCharacterItemQueryVariables,
  APITypes.GetCharacterItemQuery
>;
export const listCharacterItems = /* GraphQL */ `query ListCharacterItems(
  $filter: ModelCharacterItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listCharacterItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      item_type
      character_id
      character {
        id
        display_name
        charname
        character_type
        user_id
        description
        strengths
        flaws
        gender
        race
        birth_date
        age
        role
        hobbies
        dislikes
        profile_picture_url
        cover_photo_url
        created_at
        updated_at
        __typename
      }
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCharacterItemsQueryVariables,
  APITypes.ListCharacterItemsQuery
>;
export const characterItemByCharacterIdItemType = /* GraphQL */ `query CharacterItemByCharacterIdItemType(
  $character_id: ID!
  $item_type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCharacterItemFilterInput
  $limit: Int
  $nextToken: String
) {
  characterItemByCharacterIdItemType(
    character_id: $character_id
    item_type: $item_type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      item_type
      character_id
      character {
        id
        display_name
        charname
        character_type
        user_id
        description
        strengths
        flaws
        gender
        race
        birth_date
        age
        role
        hobbies
        dislikes
        profile_picture_url
        cover_photo_url
        created_at
        updated_at
        __typename
      }
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CharacterItemByCharacterIdItemTypeQueryVariables,
  APITypes.CharacterItemByCharacterIdItemTypeQuery
>;
export const characterItemByArtworkId = /* GraphQL */ `query CharacterItemByArtworkId(
  $artwork_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCharacterItemFilterInput
  $limit: Int
  $nextToken: String
) {
  characterItemByArtworkId(
    artwork_id: $artwork_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      item_type
      character_id
      character {
        id
        display_name
        charname
        character_type
        user_id
        description
        strengths
        flaws
        gender
        race
        birth_date
        age
        role
        hobbies
        dislikes
        profile_picture_url
        cover_photo_url
        created_at
        updated_at
        __typename
      }
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CharacterItemByArtworkIdQueryVariables,
  APITypes.CharacterItemByArtworkIdQuery
>;
export const characterItemByJobId = /* GraphQL */ `query CharacterItemByJobId(
  $job_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCharacterItemFilterInput
  $limit: Int
  $nextToken: String
) {
  characterItemByJobId(
    job_id: $job_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      item_type
      character_id
      character {
        id
        display_name
        charname
        character_type
        user_id
        description
        strengths
        flaws
        gender
        race
        birth_date
        age
        role
        hobbies
        dislikes
        profile_picture_url
        cover_photo_url
        created_at
        updated_at
        __typename
      }
      artwork_id
      artwork {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        buyer_id
        is_removed
        contract_id
        is_buyer_showcase
        is_seller_showcase
        created_at
        updated_at
        __typename
      }
      job_id
      job {
        id
        title
        description
        min_budget
        min_centi_budget
        max_budget
        max_centi_budget
        currency_code
        status
        is_exclusive
        publication_date_time
        deadline_date_time
        expiration_date_time
        is_comercial_artwork
        is_dreambox
        is_private_artwork
        file_format
        buyer_id
        seller_id
        contacted_seller_ids
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CharacterItemByJobIdQueryVariables,
  APITypes.CharacterItemByJobIdQuery
>;
