export const customGetUserChannel = /* GraphQL */ `
  query GetUserChannel($id: ID!) {
    getUserChannel(id: $id) {
      id
      channel_id
      channel {
        id
        name
        created_at
        updated_at
        user_channels(limit: 10) {
          nextToken
          items {
            id
            channel_id
            user_id
            user {
              id
              display_name
              first_name
              last_name
              username
              email_address
              birth_date
              phone_number
              country_code
              subscriber_number
              bio
              is_artist
              profile_picture_url
              cover_picture_url
              is_selling
              created_at
              updated_at
            }
            update_date_time
            status
          }
        }
        messages(limit: 1, sortDirection: DESC) {
          nextToken
          items {
            id
            channel_id
            channel {
              id
              name
              created_at
              updated_at
            }
            sender_id
            content
            image_url
            publication_date_time
            created_at
            updated_at
            images {
              nextToken
              items {
                id
                height
                width
                src_url
                thumbnail_url
                file_name
                artwork_id
                job_id
                milestone_id
                revision_id
                message_id
                created_at
                updated_at
              }
            }
          }
        }
      }
      user_id
      user {
        id
        display_name
        first_name
        last_name
        username
        email_address
        birth_date
        phone_number
        country_code
        subscriber_number
        bio
        is_artist
        seller_onboarding_stage
        profile_picture_url
        cover_picture_url
        seller_star_quantity
        seller_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        is_selling
        created_at
        updated_at
      }
      update_date_time
      request_start_date_time
      status
      created_at
      updated_at
    }
  }
`;

export const customUserChannelByChannelIdStatus = /* GraphQL */ `
  query UserChannelByChannelIdStatus(
    $channel_id: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userChannelByChannelIdStatus(
      channel_id: $channel_id
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channel_id
        channel {
          id
          name
          created_at
          updated_at
          user_channels(limit: 10) {
            nextToken
            items {
              id
              channel_id
              user_id
              user {
                id
                display_name
                username
                email_address
                birth_date
                bio
                is_artist
                profile_picture_url
                cover_picture_url
                is_selling
                created_at
                updated_at
              }
              update_date_time
              status
            }
          }
          messages(limit: 10, sortDirection: DESC) {
            nextToken
            items {
              id
              channel_id
              channel {
                id
                name
                created_at
                updated_at
              }
              sender_id
              content
              image_url
              publication_date_time
              created_at
              updated_at
              images {
                nextToken
                items {
                  id
                  height
                  width
                  src_url
                  thumbnail_url
                  file_name
                  artwork_id
                  job_id
                  milestone_id
                  revision_id
                  message_id
                  created_at
                  updated_at
                }
              }
            }
          }
        }
        user_id
        user {
          id
          display_name
          username
          email_address
          bio
          is_artist
          seller_onboarding_stage
          profile_picture_url
          cover_picture_url
          seller_star_quantity
          seller_review_quantity
          commision_started_quantity
          commission_completed_quantity
          commission_completed_on_time_quantity
          commission_cancelled_seller_fault_quantity
          commission_cancelled_buyer_fault_quantity
          commission_cancelled_mutually_quantity
          is_selling
          created_at
          updated_at
        }
        update_date_time
        request_start_date_time
        status
        created_at
        updated_at
      }
      nextToken
    }
  }
`;

export const customUserChannelByUserIdStatus = /* GraphQL */ `
  query UserChannelByUserIdStatus(
    $user_id: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userChannelByUserIdStatus(
      user_id: $user_id
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channel_id
        channel {
          id
          name
          created_at
          updated_at
          user_channels(limit: 10) {
            nextToken
            items {
              id
              channel_id
              user_id
              user {
                id
                display_name
                username
                email_address
                phone_number
                country_code
                subscriber_number
                bio
                is_artist
                profile_picture_url
                cover_picture_url
                is_selling
                created_at
                updated_at
              }
              update_date_time
              status
            }
          }
          # Get only the latest message in the channel
          messages(limit: 1, sortDirection: DESC) {
            nextToken
            items {
              id
              channel_id
              channel {
                id
                name
                created_at
                updated_at
              }
              sender_id
              content
              image_url
              publication_date_time
              created_at
              updated_at
              images {
                nextToken
                items {
                  id
                  height
                  width
                  src_url
                  thumbnail_url
                  file_name
                  artwork_id
                  job_id
                  milestone_id
                  revision_id
                  message_id
                  created_at
                  updated_at
                }
              }
            }
          }
        }
        user_id
        user {
          id
          display_name
          username
          email_address
          bio
          is_artist
          seller_onboarding_stage
          profile_picture_url
          cover_picture_url
          seller_star_quantity
          seller_review_quantity
          commision_started_quantity
          commission_completed_quantity
          commission_completed_on_time_quantity
          commission_cancelled_seller_fault_quantity
          commission_cancelled_buyer_fault_quantity
          commission_cancelled_mutually_quantity
          is_selling
          created_at
          updated_at
        }
        update_date_time
        request_start_date_time
        status
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
