import { getApps, initializeApp } from 'firebase/app';
import { browserLocalPersistence, initializeAuth } from 'firebase/auth';

const initAuth = () => {
  if (getApps().length === 0) {
    const defaultApp = initializeApp({
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY || '',
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || '',
    });
    initializeAuth(defaultApp, {
      persistence: browserLocalPersistence,
    });
  }
};

export default initAuth;
