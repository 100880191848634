import { Image, UserChannel } from '@src/API';
import { DreamerlyUser } from '@src/providers/AuthProvider';

/**
 * Helper function that get list of all images for all revision request and return list of images
 * for the last revision request with the lastest timestamp within a small period of time (5 minutes)
 * @param images list of Image object
 */
export const getImagesForLastRevisionRequest = (images: (Image | null)[] | null | undefined) => {
  if (!images) {
    return null;
  }
  // Filter out null images from images
  images.filter((image) => image);
  // Sort images by created_at in descending order
  const sortedImages = images.sort((a, b) => {
    if (a && b) {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    }
    return 0;
  });
  // Get the lastest created_at as last timestamp
  const lastTimestamp = sortedImages[0]?.created_at || '0'; // This value can't be 0 as we have filtered out null images
  // Get list of images that have created_at within a small period of time (5 minutes) from the last timestamp
  const imagesForLastRevisionRequest = sortedImages.filter((image) => {
    if (image) {
      // Get the different between image.created_at and lastTimestamp in minute
      const diff =
        Math.abs(new Date(image.created_at).getTime() - new Date(lastTimestamp).getTime()) / 60000;
      return diff <= 5;
    }
    return false;
  });
  return imagesForLastRevisionRequest;
};

export const getAverageResponseTimeText = (
  averageResponseTimeInHours: number | null | undefined,
) => {
  if (averageResponseTimeInHours === null || averageResponseTimeInHours === undefined) {
    return 'Chưa có';
  }
  if (averageResponseTimeInHours < 1) {
    return '<1h';
  }
  if (averageResponseTimeInHours > 24) {
    return '>24h';
  }
  return `${averageResponseTimeInHours}h`;
};

export const getAverageRating = (
  starQuantity: number | null | undefined,
  reviewQuantity: number | null | undefined,
) => {
  if (!starQuantity || !reviewQuantity) {
    return '-';
  }
  const averageRating = starQuantity / reviewQuantity;
  return averageRating.toFixed(1);
};

/**
 * Helper function to check if a user channel is read.
 */
export const isUserChannelRead = (
  user: DreamerlyUser | null | undefined,
  userChannel: UserChannel,
): boolean => {
  // In the user channel, we only store the last message
  const lastMessage = userChannel?.channel?.messages?.items?.[0];
  // Compare the last message date time and the last update date time
  const lastUpdateDateTime = new Date(userChannel?.update_date_time || '');
  const lastMessageDateTime = new Date(lastMessage?.publication_date_time || '');
  // If there is no last message or last update date time, then the channel is read
  if (!lastMessage || !lastMessageDateTime || !lastUpdateDateTime) return true;

  // If the last message is sent by the user, then the channel is read
  if (lastMessage?.sender_id === user?.id) return true;

  // Check if the last message is sent before the last update date time
  return lastMessageDateTime <= lastUpdateDateTime;
};

/**
 * Given a list of user channels, sort them by last message publication date time.
 *
 * Assuming that userChannel.channel.messages.items has only 1 item, which is the latest message.
 *
 * @param userChannels List of user channels
 */
export const sortUserChannelsByLastMessagePublicationDateTime = (
  userChannels: UserChannel[],
  sortDirection: 'ASC' | 'DESC' = 'DESC',
): UserChannel[] => {
  // Sort user channels by last message publication date time
  return userChannels.sort((a, b) => {
    const aLastMessage = a.channel?.messages?.items?.[0];
    const bLastMessage = b.channel?.messages?.items?.[0];

    // If missing publication_date_time, put it at the end
    if (!aLastMessage?.publication_date_time) return 1;
    if (!bLastMessage?.publication_date_time) return -1;

    return sortDirection === 'DESC'
      ? new Date(bLastMessage?.publication_date_time).getTime() -
          new Date(aLastMessage?.publication_date_time).getTime()
      : new Date(aLastMessage?.publication_date_time).getTime() -
          new Date(bLastMessage?.publication_date_time).getTime();
  });
};
