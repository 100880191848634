import PrimaryInputField from '@components/input/PrimaryInputField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { convertToDigitOnlyText, formatDigitOnlyText } from '@src/utils/formatText';
import { CoreInputType } from '@src/utils/prices';
import Image from 'next/image';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

const DefaultPriceInputClass = `flex flex-row items-center h-10 px-3 rounded-lg text-s-tall
  border border-1 border-greyscale-g3
  hover:shadow-input-focus focus:shadow-input-focus outline-none`;
const ErrorPriceInputClass = `flex flex-row items-center h-10 px-3 rounded-lg text-s-tall
  border border-1 border-status-error
  hover:shadow-input-focus-error focus:shadow-input-focus-error outline-none`;

type CreatePricesCoreTableProps = {
  coreList: CoreInputType[];
  setCoreList: (coreList: CoreInputType[]) => void;
};

function CreatePricesCoreTable(props: CreatePricesCoreTableProps) {
  const { coreList, setCoreList } = props;

  const onAddCoreClick = () => {
    const newPriceList = coreList.concat([
      {
        id: uuidv4(),
        displayName: '',
        formattedFixedAmount: '',
        unformattedFixedAmount: '',
        errorMessage: '',
        index: coreList.length - 1,
      },
    ]);
    // console.log('QQ newPriceList: ', newPriceList);
    setCoreList(newPriceList);
  };

  const onRemoveCoreClick = (id: string) => {
    const newPriceList = coreList.filter((core) => core.id !== id);
    setCoreList(newPriceList);
  };

  const onCoreFieldChange = (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>,
    field: 'display_name' | 'fixed_amount',
  ) => {
    const newValue = e.target.value;
    // Update coreList with specified field at the given index
    const newCoreList = coreList.map((core) => {
      if (core.id === id) {
        if (field === 'display_name') {
          return { ...core, displayName: newValue };
        } else {
          const formattedNumber = formatDigitOnlyText(newValue);
          const unformattedNumber = convertToDigitOnlyText(newValue);
          if (formattedNumber !== undefined && unformattedNumber !== undefined) {
            // Update input value and clear error message
            return {
              ...core,
              formattedFixedAmount: formattedNumber,
              unformattedFixedAmount: unformattedNumber,
              errorMessage: '',
            };
          }
          return core;
        }
      }
      return core;
    });
    setCoreList(newCoreList);
  };

  const firstErrorMessage = useMemo(() => {
    const firstErrorCore = coreList.find((core) => core.errorMessage !== '');
    return firstErrorCore?.errorMessage;
  }, [coreList]);

  return (
    <div className="flex flex-col">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={400} padding="none" sx={{ borderColor: '#CCCCCC' }}>
                <p className="text-m font-medium">Tên mẫu</p>
              </TableCell>
              <TableCell sx={{ borderColor: '#CCCCCC' }}>
                <p className="text-m font-sans font-medium">Giá</p>
              </TableCell>
              <TableCell padding="none" width={'38px'} sx={{ borderColor: '#CCCCCC' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {coreList
              .sort((a, b) => {
                return (a.index || 0) - (b.index || 0);
              })
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    padding="none"
                    sx={{ paddingY: 2, borderColor: '#CCCCCC', borderStyle: 'dotted' }}
                  >
                    <div className="ml-0.5">
                      <PrimaryInputField
                        type="text"
                        placeholder="Headshot, Full body, Sketch, v.v."
                        value={row.displayName}
                        onChange={(e) => onCoreFieldChange(row.id, e, 'display_name')}
                        sizeType="xs"
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    padding="none"
                    sx={{ paddingLeft: 2, borderColor: '#CCCCCC', borderStyle: 'dotted' }}
                  >
                    {/* Do not use PriceInputField because of potential conflict with MUItable */}
                    <div
                      className={row.errorMessage ? ErrorPriceInputClass : DefaultPriceInputClass}
                    >
                      <input
                        // CRITICAL! AVOID type="number" because of scrolling & auto-incrementing issue.
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        className="outline-none placeholder:text-content-t300"
                        value={row.formattedFixedAmount}
                        onChange={(e) => onCoreFieldChange(row.id, e, 'fixed_amount')}
                        placeholder="0"
                      />
                      <span className="ml-2">₫</span>
                    </div>
                  </TableCell>
                  <TableCell padding="none" sx={{ borderColor: '#CCCCCC', borderStyle: 'dotted' }}>
                    {row.id !== coreList[0]?.id && (
                      <button
                        className="flex items-center shrink-0 p-2"
                        onClick={() => onRemoveCoreClick(row.id)}
                      >
                        <Image src="/ic_close.svg" width={20} height={20} />
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Display the first error message, if there is any error */}
      {!!firstErrorMessage && (
        <span className="text-s text-status-error pt-2">{firstErrorMessage}</span>
      )}
      <button
        className="text-start text-s font-semibold mt-6 mb-4 text-primary-p4"
        onClick={onAddCoreClick}
      >
        + Thêm mẫu giá
      </button>
    </div>
  );
}

export default CreatePricesCoreTable;
