import {
  CreateMessageInput,
  CreateMessageMutation,
  CreateMessageMutationVariables,
  GetMessageQuery,
  GetMessageQueryVariables,
  Message,
  MessageByChannelIdPublicationDateTimeQuery,
  MessageByChannelIdPublicationDateTimeQueryVariables,
  ModelSortDirection,
  UpdateMessageInput,
  UpdateMessageMutation,
  UpdateMessageMutationVariables,
} from '@src/API';
import { customMessageByChannelIdPublicationDateTime } from '@src/customGraphql/customQueries';
import { createMessage, updateMessage } from '@src/graphql/mutations';
import { getMessage } from '@src/graphql/queries';
import { ListData } from '@src/libs/models';
import { API } from 'aws-amplify';

export interface IMessageRepo {
  create(message: CreateMessageInput): Promise<Message>;
  update(message: UpdateMessageInput): Promise<Message>;
  get(messageId: string): Promise<Message>;
  listMessages(channelId: string, nextToken?: string): Promise<ListData<Message>>;
}

export class RealMessageRepo implements IMessageRepo {
  public async create(message: CreateMessageInput): Promise<Message> {
    const variables: CreateMessageMutationVariables = {
      input: message,
    };

    let res = { data: {} } as { data: CreateMessageMutation };
    res = (await API.graphql({
      query: createMessage,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: CreateMessageMutation };

    return res.data.createMessage as Message;
  }

  public async get(messageId: string): Promise<Message> {
    const variables: GetMessageQueryVariables = {
      id: messageId,
    };
    const res = (await API.graphql({
      query: getMessage,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: GetMessageQuery };
    return res.data.getMessage as Message;
  }

  public async update(message: UpdateMessageInput): Promise<Message> {
    const variables: UpdateMessageMutationVariables = {
      input: message,
    };

    const res = (await API.graphql({
      query: updateMessage,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: UpdateMessageMutation };

    return res.data.updateMessage as Message;
  }

  public async listMessages(channelId: string, nextToken?: string): Promise<ListData<Message>> {
    // TODO (long): If these messages do not fill the height of the div, there is no scrolling,
    // which prevents fetching old messages. We need to fix this. Use a big limit (30 messages) for now.
    const limit = 30;
    const variables: MessageByChannelIdPublicationDateTimeQueryVariables = {
      limit: limit as number,
      nextToken,
      channel_id: channelId,
      sortDirection: ModelSortDirection.DESC,
    };
    const res = (await API.graphql({
      query: customMessageByChannelIdPublicationDateTime,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: MessageByChannelIdPublicationDateTimeQuery };

    const result: ListData<Message> = {
      list: res.data.messageByChannelIdPublicationDateTime?.items as Message[],
      nextToken: res.data.messageByChannelIdPublicationDateTime?.nextToken as string,
    };

    return result;
  }
}
