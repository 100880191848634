const DEFAULT_PRODUCT_NAME_LIST: string[] = [
  'Chibi',
  'Anime',
  'Vtuber',
  'Comic',
  'Illustration',
  'Traditional',
  'YCH',
  '3D',
  'Emote',
  'Design',
  'Inking',
  'Sticker',
];

export default DEFAULT_PRODUCT_NAME_LIST;
