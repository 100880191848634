import AppBar from '@mui/material/AppBar';
import Image from 'next/image';
import Link from 'next/link';

type WizardHeaderProps = {
  handleOnExitClick: () => void;
  isExitLoading: boolean;
  isHideExitButton?: boolean;
};

function WizardHeader(props: WizardHeaderProps) {
  const { handleOnExitClick, isExitLoading, isHideExitButton } = props;
  return (
    <AppBar
      position="sticky"
      color="inherit"
      elevation={0}
      sx={{
        zIndex: 1000,
      }}
    >
      <div className="bg-cover px-12 py-4">
        <div className="flex flex-row items-center w-full justify-between">
          <Link href="/" passHref prefetch={false}>
            <a href="replace">
              <Image src="/ic-logomark.svg" height={32} width={32} alt="Dreamerly logo" />
            </a>
          </Link>
          {!isHideExitButton && (
            <button
              className="flex flex-row items-center justify-center border border-greyscale-g3 hover:border-greyscale-g5
            text-s py-3 px-4 rounded-full font-semibold"
              onClick={handleOnExitClick}
              disabled={isExitLoading}
            >
              {/* If is loading, keep the text invisible to keep the same button size */}
              <div className={isExitLoading ? 'invisible' : ''}>
                <span>Lưu & thoát</span>
              </div>
              {/* Loading animation */}
              {isExitLoading && (
                <div
                  className="h-5 w-5 animate-spin rounded-full border-2
                border-greyscale-g5 border-r-transparent absolute"
                />
              )}
            </button>
          )}
        </div>
      </div>
    </AppBar>
  );
}

export default WizardHeader;
