import Uppy, { BasePlugin, DefaultPluginOptions } from '@uppy/core';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

class UppyS3UploaderPlugin extends BasePlugin {
  constructor(uppy: Uppy, opts?: DefaultPluginOptions | undefined) {
    super(uppy, opts);
    this.id = 'S3Uploader';
    this.type = 'uploader';
    this.upload = this.upload.bind(this);
  }

  async upload(fileIDs: string[]) {
    const { uppy } = this;
    this.uppy.emit('upload', fileIDs);
    const files = fileIDs.map((fileID) => uppy.getFile(fileID));
    this.uppy.emit('upload-start', files);
    const results = await Promise.all(
      files.map(async (file) => {
        const { name, type: contentType } = file;
        const { data } = file;
        // Add suffix to uniquely identify the file in s3
        const uniqueName = `${uuidv4()}-prefix-${name}`;
        // Remove non-iso character in uniqueName
        const uniqueNameWithoutNonIsoCharacter = uniqueName.replace(/[^a-zA-Z0-9-_.]/g, '');

        const result = await Storage.put(uniqueNameWithoutNonIsoCharacter, data, {
          contentType,
          metadata: { uniqueNameWithoutNonIsoCharacter },
          resumable: false,
          // Emit progress event
          progressCallback: (progress) => {
            this.uppy.emit('upload-progress', file, {
              uploader: this,
              bytesUploaded: progress.loaded,
              bytesTotal: progress.total,
            });
          },
        });

        // Emit upload-success event for each file (necessary for ProgressBar/StatusBar to work)
        this.uppy.emit('upload-success', file, result);

        return result;
      }),
    );
    // console.log(results);
    const completeEventResponse = {
      s3Keys: results,
      type: 'custom',
    };
    // Emit complete event to inform Uppy that all uploads are completed
    this.uppy.emit('complete', completeEventResponse);
  }

  install() {
    this.uppy.addUploader(this.upload);
  }

  uninstall() {
    this.uppy.removeUploader(this.upload);
  }
}

export default UppyS3UploaderPlugin;
