export const customGetUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      display_name
      first_name
      last_name
      username
      email_address
      birth_date
      phone_number
      country_code
      subscriber_number
      bio
      mst_stage
      is_artist
      seller_onboarding_stage
      profile_picture_url
      cover_picture_url
      balance_amount
      term_of_service
      balance_centi_amount
      balance_currency_code
      usd_balance_amount
      usd_balance_centi_amount
      contract_paid_quantity
      seller_star_quantity
      seller_review_quantity
      commision_started_quantity
      commission_completed_quantity
      commission_completed_on_time_quantity
      commission_cancelled_seller_fault_quantity
      commission_cancelled_buyer_fault_quantity
      commission_cancelled_mutually_quantity
      is_selling
      is_selling_abroad
      discount_code
      follower_quantity
      leader_quantity
      last_time_open_list_notification
      message_cumulative_response_seconds
      message_response_on_time_quantity
      message_response_late_quantity
      has_unread_commissions
      has_unread_jobs
      followers {
        items {
          id
          leader_id
          follower_id
          created_at
          updated_at
          follower {
            id
            display_name
            username
            profile_picture_url
          }
        }
        nextToken
      }
      leaders {
        items {
          id
          leader_id
          follower_id
          created_at
          updated_at
          leader {
            id
            display_name
            username
            profile_picture_url
          }
        }
        nextToken
      }
      created_at
      updated_at

      city
      address_line1
      additional_info
    }
  }
`;

export const customGetUserSecretInfo = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      bank_name
      account_holder_name
      account_last_four
      address_country_code
      city
      address_line1
    }
  }
`;

export const customUserByUsername = /* GraphQL */ `
  query UserByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        display_name
        username
        email_address
        bio
        is_artist
        seller_onboarding_stage
        term_of_service
        cccd
        mst
        mst_stage
        balance_amount
        balance_centi_amount
        balance_currency_code
        usd_balance_amount
        usd_balance_centi_amount
        profile_picture_url
        cover_picture_url
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        device_tokens
        is_selling
        last_time_open_list_notification
        discount_code
        follower_quantity
        leader_quantity
        has_unread_commissions
        has_unread_jobs
        message_cumulative_response_seconds
        message_response_on_time_quantity
        message_response_late_quantity
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const customListUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        display_name
        username
        is_artist
        email_address
        profile_picture_url
        seller_star_quantity
        seller_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        is_selling
        discount_code
        artworks {
          items {
            id
            cover_image_height
            cover_image_width
            cover_image_src_url
            cover_image_thumbnail_url
            publication_date_time
            is_removed
          }
          nextToken
        }
        products {
          items {
            id
            artist_id
            display_name
            description
            status
            created_at
            updated_at
          }
          nextToken
        }
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
