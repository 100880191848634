import initAuth from '@libs/initAuth';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ChatProvider from '@src/providers/ChatProvider';
import NotificationProvider from '@src/providers/NotificationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify } from 'aws-amplify';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import relativeTime from 'dayjs/plugin/relativeTime';
import i18next from 'i18next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { I18nextProvider } from 'react-i18next';
import awsExports from '../aws-exports';

// add custom validation
import '../utils/customYup';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import 'nprogress/nprogress.css';
import '../styles/globals.css';

import common_en from '../translations/en/common.json';
import common_vi from '../translations/vi/common.json';

import glossary_en from '../translations/en/glossary.json';
import glossary_vi from '../translations/vi/glossary.json';

import validation_en from '../translations/en/validation.json';
import validation_vi from '../translations/vi/validation.json';

const AuthProvider = dynamic(() => import('@src/providers/AuthProvider'));
const ModalProvider = dynamic(() => import('@src/providers/ModalProvider'));
const InboxWidget = dynamic(() => import('@src/components/widget/InboxWidget'));

Amplify.configure({
  ...awsExports,
  ssr: true,
});
initAuth();
dayjs.extend(relativeTime);
dayjs.locale('vi');
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
NProgress.configure({
  showSpinner: false,
});

const muiTheme = createTheme({
  typography: {
    fontFamily: `"Sarabun", sans-serif, -apple-system, system-ui`,
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});

let language = 'vi';
if (typeof window !== 'undefined') {
  language = localStorage.getItem('language') || 'vi';
}

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: language, // language to use
  fallbackLng: 'vi',
  resources: {
    vi: {
      common: common_vi,
      glossary: glossary_vi,
      validation: validation_vi,
    },
    en: {
      common: common_en,
      glossary: glossary_en,
      validation: validation_en,
    },
  },
});

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <I18nextProvider i18n={i18next}>
          <NotificationProvider>
            <ChatProvider>
              <div id="recaptcha-container" className="hidden" />
              <ModalProvider>
                <ThemeProvider theme={muiTheme}>
                  <Component {...pageProps} key={router.asPath} />

                  <InboxWidget />

                  {/* <Script strategy="afterInteractive" id="intercom">
                  {`
                    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){
                      ic('reattach_activator');ic('update',w.intercomSettings);
                    }else{var d=document;var i=function(){i.c(arguments);};
                    i.q=[];i.c=function(args){i.q.push(args);};
                    w.Intercom=i;var l=function(){var s=d.createElement('script');
                    s.type='text/javascript';s.async=false;s.src='https://widget.intercom.io/widget/dyr60apc';
                    var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};
                    if(document.readyState==='complete'){l();}
                    else if(w.attachEvent){w.attachEvent('onload',l);}
                    else{w.addEventListener('load',l,false);}}})();
                  `}
                </Script> */}
                </ThemeProvider>
              </ModalProvider>
            </ChatProvider>
          </NotificationProvider>
        </I18nextProvider>
      </AuthProvider>
      {process.env.DEV_TOOL && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}
