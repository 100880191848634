import { CurrencyCode } from './enums';

// Dreamerly service fees to display to user
export const DREAMERLY_DEFAULT_FEE_IN_PERCENTAGE = 2.2;
export const DREAMERLY_BETA_USER_FEE_IN_PERCENTAGE = 1.5;
export const DREAMERLY_INTERNATIONAL_FEE_IN_PERCENTAGE = 4.0;

// Transaction fee rate to display to user
export const DISPLAY_PAYMENT_FEE_IN_PERCENTAGE_MOMO = 2.2;
export const DISPLAY_PAYMENT_FEE_IN_PERCENTAGE_STRIPE = 4.9;
export const DISPLAY_PAYMENT_FEE_IN_FIXED_CENT_STRIPE = 50;
export const DISPLAY_PAYMENT_FEE_IN_FIXED_USD_STRIPE = 0.5;

// MoMo transaction fees (These are the actual fees that MoMo charges us)
export const MOMO_WALLET_FEE = {
  percentageFeeInPercentage: 2.2,
  flatFee: 0,
  flatFeeCurrencyCode: CurrencyCode.VND,
};
export const MOMO_ATM_FEE = {
  percentageFeeInPercentage: 1.1,
  flatFee: 550,
  flatFeeCurrencyCode: CurrencyCode.VND,
};
export const MOMO_CREDIT_CARD_FEE = {
  percentageFeeInPercentage: 2.4,
  flatFee: 2200,
  flatFeeCurrencyCode: CurrencyCode.VND,
};

export const XS_SCREEN_IN_PX = 375;
export const SM_SCREEN_IN_PX = 576;
export const MD_SCREEN_IN_PX = 768;
export const LG_SCREEN_IN_PX = 1024;
export const XL_SCREEN_IN_PX = 1280;
export const XXL_SCREEN_IN_PX = 1536;

// TODO: Consider varying these for different screen sizes.
export const DEFAULT_ARTWORK_QUALITY = 75;
export const DEFAULT_ARTWORK_WIDTH = 2048;
export const DEFAULT_THUMBNAIL_QUALITY = 75;
export const DEFAULT_THUMBNAIL_WIDTH = 300;

export const MIN_PRODUCT_PRICE = 100000;
export const MIN_CONTRACT_AMOUNT = 100000;
export const MIN_PRODUCT_PRICE_USD = 10;
export const MIN_CONTRACT_AMOUNT_USD = 10;

// The number of artworks per paginated page
export const DEFAULT_NUM_ARTWORKS_PER_PAGE = 50;
// The number of artworks to pre-fetch during ISR
// Temporary solution for not having to do probabilistic ranking: 5000 artworks.
// Once we do probabilistic ranking, this can be reduced.
export const DEFAULT_PREFETCH_NUM_ARTWORKS = 500;

export const DEFAULT_NUM_RECOMMENDED_ARTISTS = 20;

export const NONE_STRING_VALUE = 'none';

export const devsing_category_id_map: { [key: string]: string } = {
  Vtuber: 'vtuber',
  Anime: 'anime',
  Traditional: 'traditional',
  Illustration: 'illustration',
  Chibi: 'chibi',
  Other: 'other',
  Dreambox: 'dreambox',
};

export const category_id_map = {
  Dreambox: 'dreambox',
};

export const search_tab_map: { [key: string]: object } = {
  All: {
    id: 'ALL',
    displayName: 'Tất cả',
    queryUrl: 'searchAll',
  },
  User: {
    id: 'USER',
    displayName: 'Người dùng',
    queryUrl: 'searchUser',
  },
  Product: {
    id: 'PRODUCT',
    displayName: 'Loại commission',
    queryUrl: 'searchProduct',
  },
  Artwork: {
    id: 'ARTWORK',
    displayName: 'Tranh',
    queryUrl: 'searchArtwork',
  },
};

export const devsing_category_id_icon_map: { [key: string]: object } = {
  All: {
    id: 'ALL',
    displayName: 'Tất cả',
    icon: '/ic-category-all.svg',
    hoverIcon: '/ic-category-all-hover.svg',
  },
  Dreambox: {
    id: 'dreambox',
    displayName: 'Dreambox',
    icon: '/ic-dreambox-grey.svg',
    hoverIcon: '/ic-dreambox-dark.svg',
    isNew: true,
  },
  Anime: {
    id: 'anime',
    displayName: 'Anime',
    icon: '/ic-category-anime.svg',
    hoverIcon: '/ic-category-anime-hover.svg',
  },
  Chibi: {
    id: 'chibi',
    displayName: 'Chibi',
    icon: '/ic-category-chibi.svg',
    hoverIcon: '/ic-category-chibi-hover.svg',
  },
  Comic: {
    id: 'NONE-Comic',
    displayName: 'Comic',
    icon: '/ic-category-comic.svg',
    hoverIcon: '/ic-category-comic-hover.svg',
  },
  Character: {
    id: 'character',
    displayName: 'Character',
    icon: '/ic-category-character.svg',
    hoverIcon: '/ic-category-character-hover.svg',
  },
  Sketch: {
    id: 'sketch',
    displayName: 'Sketch',
    icon: '/ic-category-sketch.svg',
    hoverIcon: '/ic-category-sketch-hover.svg',
  },
  Traditional: {
    id: 'traditional',
    displayName: 'Traditional',
    icon: '/ic-category-traditional.svg',
    hoverIcon: '/ic-category-traditional-hover.svg',
  },
  Illustration: {
    id: 'illustration',
    displayName: 'Illustration',
    icon: '/ic-category-illustration.svg',
    hoverIcon: '/ic-category-illustration-hover.svg',
  },
  Design: {
    id: 'NONE-Design',
    displayName: 'Design',
    icon: '/ic-category-design.svg',
    hoverIcon: '/ic-category-design-hover.svg',
  },
  Emote: {
    id: 'NONE-Emote',
    displayName: 'Emote',
    icon: '/ic-category-emote.svg',
    hoverIcon: '/ic-category-emote-hover.svg',
  },
  Gacha: {
    id: 'gacha',
    displayName: 'Gacha',
    icon: '/ic-category-gacha.svg',
    hoverIcon: '/ic-category-gacha-hover.svg',
  },
  Sticker: {
    id: 'NONE-Sticker',
    displayName: 'Sticker',
    icon: '/ic-category-sticker.svg',
    hoverIcon: '/ic-category-sticker-hover.svg',
  },
  Vtuber: {
    id: 'vtuber',
    displayName: 'Vtuber',
    icon: '/ic-category-vtuber.svg',
    hoverIcon: '/ic-category-vtuber-hover.svg',
  },
  YCH: {
    id: 'NONE-YCH',
    displayName: 'YCH',
    icon: '/ic-category-ych.svg',
    hoverIcon: '/ic-category-ych-hover.svg',
  },
  Other: {
    id: 'other',
    displayName: 'Khác',
    icon: '/ic-category-other.svg',
    hoverIcon: '/ic-category-other-hover.svg',
  },
};

export const category_id_keyword_map: { [key: string]: Array<string> } = {
  anime: ['anime', 'cartoon', 'animation'],
  chibi: ['chibi'],
  comic: ['comic', 'manga', 'doujin', 'webtoon', 'fanart'],
  design: ['design'],
  emote: ['emote'],
  illustration: ['illustration', 'illu', 'concept'],
  inking: ['inking', 'ink'],
  other: [],
  sticker: ['sticker'],
  traditional: ['traditional'],
  vtuber: ['vtuber', 'vtub'],
  ych: ['ych'],
  gacha: ['gacha'],
  character: ['character', 'oc'],
  sketch: ['sketch', 'line', 'sket'],
};

export const prodsing_category_id_icon_map: { [key: string]: object } = {
  All: {
    id: 'ALL',
    displayName: 'Tất cả',
    icon: '/ic-category-all.svg',
    hoverIcon: '/ic-category-all-hover.svg',
  },
  Dreambox: {
    id: 'dreambox',
    displayName: 'Dreambox',
    icon: '/ic-dreambox-grey.svg',
    hoverIcon: '/ic-dreambox-dark.svg',
    isNew: true,
  },
  Anime: {
    id: 'anime',
    displayName: 'Anime',
    icon: '/ic-category-anime.svg',
    hoverIcon: '/ic-category-anime-hover.svg',
  },
  Chibi: {
    id: 'chibi',
    displayName: 'Chibi',
    icon: '/ic-category-chibi.svg',
    hoverIcon: '/ic-category-chibi-hover.svg',
  },
  Comic: {
    id: 'comic',
    displayName: 'Comic',
    icon: '/ic-category-comic.svg',
    hoverIcon: '/ic-category-comic-hover.svg',
  },
  Character: {
    id: 'character',
    displayName: 'Character',
    icon: '/ic-category-character.svg',
    hoverIcon: '/ic-category-character-hover.svg',
  },
  Sketch: {
    id: 'sketch',
    displayName: 'Sketch',
    icon: '/ic-category-sketch.svg',
    hoverIcon: '/ic-category-sketch-hover.svg',
  },
  Traditional: {
    id: 'traditional',
    displayName: 'Traditional',
    icon: '/ic-category-traditional.svg',
    hoverIcon: '/ic-category-traditional-hover.svg',
  },
  Illustration: {
    id: 'illustration',
    displayName: 'Illustration',
    icon: '/ic-category-illustration.svg',
    hoverIcon: '/ic-category-illustration-hover.svg',
  },
  Design: {
    id: 'design',
    displayName: 'Design',
    icon: '/ic-category-design.svg',
    hoverIcon: '/ic-category-design-hover.svg',
  },
  Emote: {
    id: 'emote',
    displayName: 'Emote',
    icon: '/ic-category-emote.svg',
    hoverIcon: '/ic-category-emote-hover.svg',
  },
  Gacha: {
    id: 'gacha',
    displayName: 'Gacha',
    icon: '/ic-category-gacha.svg',
    hoverIcon: '/ic-category-gacha-hover.svg',
  },
  Sticker: {
    id: 'sticker',
    displayName: 'Sticker',
    icon: '/ic-category-sticker.svg',
    hoverIcon: '/ic-category-sticker-hover.svg',
  },
  Vtuber: {
    id: 'vtuber',
    displayName: 'Vtuber',
    icon: '/ic-category-vtuber.svg',
    hoverIcon: '/ic-category-vtuber-hover.svg',
  },
  YCH: {
    id: 'ych',
    displayName: 'YCH',
    icon: '/ic-category-ych.svg',
    hoverIcon: '/ic-category-ych-hover.svg',
  },
  Other: {
    id: 'other',
    displayName: 'Khác',
    icon: '/ic-category-other.svg',
    hoverIcon: '/ic-category-other-hover.svg',
  },
};

export const prodsing_search_category_name_map: { [key: string]: object } = {
  All: {
    displayName: 'Tất cả',
    filterValue: 'all',
  },
  Dreambox: {
    displayName: 'Dreambox',
    filterValue: 'Dreambox',
  },
  Vtuber: {
    displayName: 'Vtuber',
    filterValue: 'Vtuber',
  },
  Anime: {
    displayName: 'Anime',
    filterValue: 'Anime',
  },
  Traditional: {
    displayName: 'Traditional',
    filterValue: 'Traditional',
  },
  Illustration: {
    displayName: 'Illustration',
    filterValue: 'Illustration',
  },
  Chibi: {
    displayName: 'Chibi',
    filterValue: 'Chibi',
  },
  Comic: {
    displayName: 'Comic',
    filterValue: 'Comic',
  },
  Design: {
    displayName: 'Design',
    filterValue: 'Design',
  },
  Emote: {
    displayName: 'Emote',
    filterValue: 'Emote',
  },
  Sticker: {
    displayName: 'Sticker',
    filterValue: 'Sticker',
  },
  YCH: {
    displayName: 'YCH',
    filterValue: 'YCH',
  },
  Other: {
    displayName: 'Khác',
    filterValue: 'Other',
  },
  Gacha: {
    displayName: 'Gacha',
    filterValue: 'Gacha',
  },
  Character: {
    displayName: 'Character',
    filterValue: 'Character',
  },
  Sketch: {
    displayName: 'Sketch',
    filterValue: 'Sketch',
  },
};

export const prodsing_category_id_map: { [key: string]: string } = {
  Anime: 'anime',
  Chibi: 'chibi',
  Comic: 'comic',
  Design: 'design',
  Emote: 'emote',
  Illustration: 'illustration',
  Inking: 'inking',
  Other: 'other',
  Sticker: 'sticker',
  Traditional: 'traditional',
  Vtuber: 'vtuber',
  YCH: 'ych',
  Gacha: 'gacha',
  Character: 'character',
  Sketch: 'sketch',
  Dreambox: 'dreambox',
};

export const category_id_display_name_map: { [key: string]: string } = {
  other: 'Khác',
  '3d': '3D',
  anime: 'Anime',
  chibi: 'Chibi',
  comic: 'Comic',
  character: 'Character',
  design: 'Design',
  emote: 'Emote',
  gacha: 'Gacha',
  inking: 'Inking',
  illustration: 'Illustration',
  sketch: 'Sketch',
  sticker: 'Sticker',
  traditional: 'Traditional',
  vtuber: 'Vtuber',
  ych: 'YCH',
  dreambox: 'Dreambox',
};

export const deadlineTypeStr: { [key: string]: string } = {
  DAY: 'ngày',
  WEEK: 'tuần',
  MONTH: 'tháng',
};

// The upper bound of late hours (without this upper bound, the average response time can become infinite)
export const MESSAGE_RESPONSE_TIME_UPPER_BOUND_IN_HOURS = 72;
// The number of hours before a reply from artist is considered late for buyer
export const MESSAGE_LIMIT_TO_COUNT_AS_LATE_IN_HOURS = 24;
// The number of minutes after a another user's request start date time to start
// counting into the user's statistics
export const TIME_FROM_RECEIVER_REQUEST_TO_COUNT_IN_STATISTICS_IN_MINUTES = 15;

export const REPLY_WAIT_THRESHOLD_MINUTES = 30;
export const DELAY_HOURS_TO_SEND_UNREAD_MESSAGE_IN_HOURS = 2;
