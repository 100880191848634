import { Artwork } from '@src/API';
import { ImageLoaderProps } from 'next/image';
import {
  DEFAULT_ARTWORK_QUALITY,
  DEFAULT_ARTWORK_WIDTH,
  DEFAULT_THUMBNAIL_QUALITY,
  DEFAULT_THUMBNAIL_WIDTH,
} from './constants';

export const isGifArt = (artwork: Artwork) => {
  return (
    artwork.cover_image_thumbnail_url?.match(/\.gif$/) ||
    artwork.cover_image_src_url?.match(/\.gif$/)
  );
};

const S3_IMG_REGEX = /(http[s]?:\/\/)?([^\/\s]+).s3.amazonaws.com\/(.*)/i;
// TODO: Don't hardcode the dev endpoint...
const CLOUDFRONT_ENDPOINT =
  process.env.NEXT_PUBLIC_CLOUD_FRONT_BASE_URL || 'https://d3c7zyce6jjmbb.cloudfront.net';

/**
 * Custom image loader function. NO raw S3 image url should be used without the custom loader.
 *
 * - Most image types are served from a Cloudfront-based Serverless Image Handler. Benefits provided
 *   by the handler: caching, url obfuscation, image processing.
 * - File types unsupported by the handler (gif, jfif etc) are served directly from S3. These won't get
 *   any caching benefit.
 * - Non-S3 files are also served as is.
 */
export const customImageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  // Next Image requires custom loaders to include width in the query string.
  // https://nextjs.org/docs/messages/next-image-missing-loader-width
  // HACK: Append this to the end to silence the warning (no other way).
  // In fact none of our urls actually supports this format (S3 doesn't support resizing;
  // Cloudfront Handler gets sizing info from the encoded request string).
  const widthQueryStr = `?w=${width}`;

  // Load unsupported file types directly from S3.
  if (!isImage(src) || src.match(/\.gif$/)) return src + widthQueryStr;

  // If src string can't be parsed (i.e. the url isn't a S3 url), return as is.
  const parsedSrc = src.match(S3_IMG_REGEX);
  if (!parsedSrc) return src + widthQueryStr;

  const [str, scheme, bucket, key] = parsedSrc;
  const imageRequest = {
    bucket,
    key,
    edits: {
      rotate: null, // automatically rotate per EXIF orientation
      resize: {
        width,
      },
      toFormat: 'webp',
      webp: {
        quality: quality || 75,
      },
    },
  };
  const endcodedRequest = Buffer.from(JSON.stringify(imageRequest)).toString('base64');

  return `${CLOUDFRONT_ENDPOINT}/${endcodedRequest}${widthQueryStr}`;
};

/* Given the raw url (e.g. S3), returns the corresponding Cloudfront Image Handler url
 * when available. */
export const getImageSrcUrl = (
  src: string | null | undefined,
  width?: number,
  quality?: number,
) => {
  return src
    ? customImageLoader({
        src,
        width: width || DEFAULT_ARTWORK_WIDTH,
        quality: quality || DEFAULT_ARTWORK_QUALITY,
      })
    : '';
};

/* Similar to getImageSrcUrl, but with different width/quality defaults for loading thumbnails. */
export const getThumbnailUrl = (
  src: string | null | undefined,
  width?: number,
  quality?: number,
) => {
  return src
    ? customImageLoader({
        src,
        width: width || DEFAULT_THUMBNAIL_WIDTH,
        quality: quality || DEFAULT_THUMBNAIL_QUALITY,
      })
    : '';
};

const IMAGE_REGEX = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;

export const isImage = (name?: string | null) => {
  return name && IMAGE_REGEX.test(name);
};
