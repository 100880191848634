import { getMeta } from '@components/buttons/UppyUploadPhotoButton';
import { Artwork, CreateArtworkInput, Image, Tag, UpdateArtworkInput } from '@src/API';
import { ListData } from '@src/libs/models';
import { IArtworkRepo, RealArtworkRepo } from '@src/repositories/artworkRepo';
import { IImageRepo, RealImageRepo } from '@src/repositories/imageRepo';
import { DEFAULT_NUM_ARTWORKS_PER_PAGE, DEFAULT_PREFETCH_NUM_ARTWORKS } from '@src/utils/constants';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import captureException from './loggerService';

export interface IArtworkService {
  get(artworkId: string): Promise<Artwork | null | undefined>;
  getListArtwork({
    limit,
    nextToken,
  }: {
    limit?: number;
    nextToken?: string;
  }): Promise<ListData<Artwork> | null>;
  listAllArtworks(): Promise<Artwork[]>;
  getListArtworkBySeller({
    sellerId,
    nextToken,
    limit,
  }: {
    sellerId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Artwork>>;
  getListArtworkByBuyer({
    buyerId,
    nextToken,
    limit,
  }: {
    buyerId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Artwork>>;
  create({
    userId,
    buyerId,
    srcUrl,
    thumbnailUrl,
    height,
    width,
    title,
    description,
    artworkId,
    contractId,
    isBuyerShowcase,
    isSellerShowcase,
    rawSrcUrl,
    rawThumbnailUrl,
  }: {
    userId: string;
    buyerId?: string;
    srcUrl: string;
    thumbnailUrl: string;
    height: number;
    width: number;
    title: string;
    description: string;
    artworkId?: string;
    contractId?: string;
    isBuyerShowcase?: number | null | undefined;
    isSellerShowcase?: number | null | undefined;
    rawSrcUrl?: string | null | undefined;
    rawThumbnailUrl?: string | null | undefined;
  }): Promise<Artwork>;
  createFromSrcUrl({
    userId,
    srcUrl,
    title,
    description,
    artworkId,
    contractId,
    isBuyerShowcase,
    isSellerShowcase,
    rawSrcUrl,
    rawThumbnailUrl,
  }: {
    userId: string;
    srcUrl: string;
    title: string;
    description: string;
    artworkId?: string;
    contractId?: string;
    isBuyerShowcase?: number;
    isSellerShowcase?: number;
    rawSrcUrl?: string | null | undefined;
    rawThumbnailUrl?: string | null | undefined;
  }): Promise<Artwork>;
  updateLikesCount(artworkId: string, likesCount: number): Promise<Artwork>;
  updateCommentsCount(artworkId: string, commentsCount: number): Promise<Artwork>;
  remove(artworkId: string): Promise<Artwork>;

  updateShowcase({
    artworkId,
    isBuyerShowcase,
    isSellerShowcase,
    isRemoved,
  }: {
    artworkId: string;
    isBuyerShowcase?: number | null | undefined;
    isSellerShowcase?: number | null | undefined;
    isRemoved?: number;
  }): Promise<Artwork>;
  getListArtworkByTag({
    sessionId,
    tagId,
    limit,
    nextToken,
  }: {
    sessionId: string;
    tagId: string;
    limit: number;
    nextToken?: string;
  }): Promise<ListData<Artwork> | null>;

  getAllActiveTags(): Promise<Tag[]>;
  updateTags(artworkId: string, tags: string[]): Promise<void>;
}

export class RealArtworkService implements IArtworkService {
  private artworkRepo: IArtworkRepo;
  private imageRepo: IImageRepo;

  public constructor() {
    this.artworkRepo = new RealArtworkRepo();
    this.imageRepo = new RealImageRepo();
  }

  public async get(artworkId: string): Promise<Artwork | null | undefined> {
    const result = await this.artworkRepo.get(artworkId);

    return result;
  }

  public async getListArtworkBySeller({
    sellerId,
    nextToken,
    limit,
  }: {
    sellerId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Artwork>> {
    const result: ListData<Artwork> = await this.artworkRepo.getListArtworkBySeller({
      sellerId,
      nextToken,
      limit,
    });
    const filterArtworks = (result?.list || []).filter((artwork) => {
      if (!artwork.contract_id) {
        return true;
      }
      if (artwork.is_seller_showcase === 1 && artwork.contract_id) {
        return true;
      }
    });
    return {
      list: filterArtworks,
      nextToken: result.nextToken,
    };
  }

  public async getListArtworkByBuyer({
    buyerId,
    nextToken,
    limit,
  }: {
    buyerId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Artwork>> {
    const result: ListData<Artwork> = await this.artworkRepo.getListArtworkByBuyer({
      buyerId,
      nextToken,
      limit,
    });
    const filterArtworks = (result?.list || []).filter((artwork) => {
      if (!artwork.contract_id) {
        return true;
      }
      if (artwork.is_buyer_showcase === 1 && artwork.contract_id) {
        return true;
      }
    });
    return {
      list: filterArtworks,
      nextToken: result.nextToken,
    };
  }

  public async getListArtwork({
    limit,
    nextToken,
  }: {
    limit?: number;
    nextToken?: string;
  }): Promise<ListData<Artwork> | null> {
    try {
      const result: ListData<Artwork> = await this.artworkRepo.getListArtwork(
        limit || DEFAULT_NUM_ARTWORKS_PER_PAGE,
        nextToken,
      );
      return {
        list: result.list,
        nextToken: result.nextToken,
      } as ListData<Artwork>;
    } catch (error) {
      captureException(error);
      return null;
    }
  }

  /**
   * List all artworks by continuously calling getListArtwork until there is no nextToken
   */
  public async listAllArtworks(): Promise<Artwork[]> {
    let data = await this.getListArtwork({ limit: DEFAULT_PREFETCH_NUM_ARTWORKS });
    const result = data?.list || [];

    while (data?.nextToken) {
      data = await this.getListArtwork({
        limit: DEFAULT_PREFETCH_NUM_ARTWORKS,
        nextToken: data.nextToken,
      });
      result.push(...(data?.list || []));
    }

    return result;
  }

  public async create({
    userId,
    buyerId,
    srcUrl,
    thumbnailUrl,
    height,
    width,
    title,
    description,
    artworkId,
    contractId,
    isBuyerShowcase,
    isSellerShowcase,
    rawSrcUrl,
    rawThumbnailUrl,
  }: {
    userId: string;
    buyerId?: string;
    srcUrl: string;
    thumbnailUrl: string;
    height: number;
    width: number;
    title: string;
    description: string;
    artworkId?: string;
    contractId?: string;
    isBuyerShowcase?: number | null | undefined;
    isSellerShowcase?: number | null | undefined;
    rawSrcUrl?: string | null | undefined;
    rawThumbnailUrl?: string | null | undefined;
  }): Promise<Artwork> {
    const artworkIdToUse = artworkId || uuidv4();
    const artworkInput: CreateArtworkInput = {
      id: artworkIdToUse,
      user_id: userId,
      buyer_id: buyerId,
      cover_image_height: height,
      cover_image_width: width,
      cover_image_src_url: srcUrl,
      cover_image_thumbnail_url: thumbnailUrl,
      title,
      description,
      likes_count: 0, // init likes count with 0
      comments_count: 0, // init comments count with 0
      is_removed: 0,
      publication_date_time: new Date().toISOString(),
    };

    // Create artwork when contract is completed
    if (isBuyerShowcase !== undefined) {
      artworkInput.is_buyer_showcase = isBuyerShowcase;
    }
    if (contractId) {
      artworkInput.contract_id = contractId;
    }
    if (isSellerShowcase !== undefined) {
      // is_seller_showcase can be 0
      artworkInput.is_seller_showcase = isSellerShowcase;
    }

    const imageInput = {
      src_url: srcUrl,
      thumbnail_url: thumbnailUrl,
      height,
      width,
      artwork_id: artworkIdToUse,
      raw_src_url: rawSrcUrl,
      raw_thumbnail_url: rawThumbnailUrl,
    };
    // Run in parallel
    const createArtworkPromise = this.artworkRepo.create(artworkInput);
    let createImagePromise: Promise<Image>[] = [];
    if (!contractId) {
      createImagePromise = [this.imageRepo.create(imageInput)];
    }
    const [artwork] = await Promise.all([createArtworkPromise, ...createImagePromise]);

    return artwork;
  }

  public async createFromSrcUrl({
    artworkId,
    userId,
    buyerId,
    srcUrl,
    thumbnailUrl,
    title,
    description,
    contractId,
    isBuyerShowcase,
    isSellerShowcase,
    rawSrcUrl,
    rawThumbnailUrl,
  }: {
    artworkId?: string;
    userId: string;
    buyerId?: string;
    srcUrl: string;
    thumbnailUrl: string;
    title: string;
    description: string;
    contractId?: string;
    isBuyerShowcase?: number;
    isSellerShowcase?: number;
    rawSrcUrl?: string | null | undefined;
    rawThumbnailUrl?: string | null | undefined;
  }): Promise<Artwork> {
    let width: number | undefined = undefined;
    let height: number | undefined = undefined;
    try {
      const imageMeta = await getMeta(srcUrl);
      width = imageMeta.width;
      height = imageMeta.height;
    } catch (error) {
      captureException(error);
    }
    return this.create({
      userId,
      buyerId,
      srcUrl,
      thumbnailUrl,
      height: height || 0,
      width: width || 0,
      title,
      description,
      artworkId,
      contractId,
      isBuyerShowcase,
      isSellerShowcase,
      rawSrcUrl,
      rawThumbnailUrl,
    });
  }

  public async updateLikesCount(artworkId: string, likesCount: number): Promise<Artwork> {
    const artworkInput: UpdateArtworkInput = {
      id: artworkId,
      likes_count: likesCount,
    };

    const result: Artwork = await this.artworkRepo.update(artworkInput);
    return result;
  }

  public async remove(artworkId: string): Promise<Artwork> {
    const artworkInput: UpdateArtworkInput = {
      id: artworkId,
      is_removed: 1,
    };

    const result: Artwork = await this.artworkRepo.update(artworkInput);
    return result;
  }

  public async updateCommentsCount(artworkId: string, commentsCount: number): Promise<Artwork> {
    const artworkInput: UpdateArtworkInput = {
      id: artworkId,
      comments_count: commentsCount,
    };

    const result: Artwork = await this.artworkRepo.update(artworkInput);
    return result;
  }
  public async updateShowcase({
    artworkId,
    isBuyerShowcase,
    isSellerShowcase,
    isRemoved,
  }: {
    artworkId: string;
    isBuyerShowcase?: number | null | undefined;
    isSellerShowcase?: number | null | undefined;
    isRemoved?: number;
  }): Promise<Artwork> {
    const artworkInput: UpdateArtworkInput = {
      id: artworkId,
    };
    if (isBuyerShowcase !== undefined) {
      artworkInput.is_buyer_showcase = isBuyerShowcase; // buyer_id can be null
    }
    if (isSellerShowcase !== undefined) {
      artworkInput.is_seller_showcase = isSellerShowcase; // is_seller_showcase can be 0
    }
    if (isRemoved !== undefined) {
      artworkInput.is_removed = isRemoved;
    }

    const result: Artwork = await this.artworkRepo.update(artworkInput);
    return result;
  }

  public async getListArtworkByTag({
    sessionId,
    tagId,
    limit,
    nextToken,
  }: {
    sessionId: string;
    tagId: string;
    limit: number;
    nextToken?: string;
  }): Promise<ListData<Artwork> | null> {
    return this.artworkRepo.getListArtworkByTag({ sessionId, tagId, limit, nextToken });
  }

  public async getAllActiveTags(): Promise<Tag[]> {
    const url = `${process.env.NEXT_PUBLIC_API_GATEWAY_BASE_URL || ''}/list-all-active-tags`;
    const res = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const parseData = res.data as { allTags: Tag[] };
    const { allTags } = parseData;
    const orderedTags = allTags.sort((a, b) => {
      if ((a.total_artwork_quantity || 0) > (b.total_artwork_quantity || 0)) {
        return -1;
      }
      return 1;
    });

    return orderedTags;
  }

  public async updateTags(artworkId: string, tags: string[]): Promise<void> {
    const url = `${process.env.NEXT_PUBLIC_API_GATEWAY_BASE_URL || ''}/save-tags-for-artwork`;
    const webappAccessApiKey = process.env.NEXT_PUBLIC_API_GATEWAY_ACCESS_KEY || '';
    const body = {
      artworkId,
      tags: tags.join(','), // Convert array to string
    };
    await axios.post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': webappAccessApiKey,
      },
    });
  }
}
