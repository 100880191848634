export const customGetProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      artist_id
      artist {
        id
        display_name
        username
        email_address
        bio
        is_artist
        seller_onboarding_stage
        profile_picture_url
        cover_picture_url
        contract_paid_quantity
        seller_star_quantity
        seller_review_quantity
        buyer_star_quantity
        buyer_review_quantity
        commision_started_quantity
        commission_completed_quantity
        commission_completed_on_time_quantity
        commission_cancelled_seller_fault_quantity
        commission_cancelled_buyer_fault_quantity
        commission_cancelled_mutually_quantity
        commission_cumulative_late_seconds
        commission_max_late_seconds
        is_selling
        term_of_service
        follower_quantity
        leader_quantity
        created_at
        updated_at
      }
      display_name
      slug
      description
      status
      category_id
      dreambox_complete_time
      dreambox_response_time
      prices {
        items {
          id
          product_id
          display_name
          description
          status
          type
          category
          fixed_amount
          fixed_centi_amount
          index
          min_amount
          min_centi_amount
          max_amount
          max_centi_amount
          currency_code
          percentage
          created_at
          updated_at
        }
        nextToken
      }
      min_price_amount
      max_price_amount
      currency_code
      product_star_quantity
      product_review_quantity
      commission_paid_quantity
      artist_other_artworks {
        id
        cover_image_height
        cover_image_width
        cover_image_src_url
        cover_image_thumbnail_url
        title
        likes_count
        comments_count
        description
        publication_date_time
        user_id
        is_removed
        created_at
        updated_at
      }
      reviews {
        id
        user_id
        user {
          id
          display_name
          username
        }
        reviewer_id
        reviewer {
          id
          display_name
          username
        }
        contract_id
        rating
        comment
        type
        publication_date_time
        created_at
        updated_at
      }
      product_artworks {
        items {
          id
          product_id
          artwork_id
          artwork {
            id
            cover_image_height
            cover_image_width
            cover_image_src_url
            cover_image_thumbnail_url
            title
            likes_count
            comments_count
            description
            publication_date_time
            user_id
            is_removed
          }
          is_removed
          index
          created_at
          updated_at
        }
        nextToken
      }
      created_at
      updated_at
    }
  }
`;

export const customProductBySlug = /* GraphQL */ `
  query ProductBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        artist_id
        artist {
          id
          display_name
          username
          email_address
          bio
          is_artist
          seller_onboarding_stage
          profile_picture_url
          cover_picture_url
          contract_paid_quantity
          seller_star_quantity
          seller_review_quantity
          buyer_star_quantity
          buyer_review_quantity
          commision_started_quantity
          commission_completed_quantity
          commission_completed_on_time_quantity
          commission_cancelled_seller_fault_quantity
          commission_cancelled_buyer_fault_quantity
          commission_cancelled_mutually_quantity
          commission_cumulative_late_seconds
          commission_max_late_seconds
          is_selling
          term_of_service
          follower_quantity
          leader_quantity
          created_at
          updated_at
        }
        display_name
        slug
        description
        status
        prices {
          items {
            id
            product_id
            display_name
            description
            status
            type
            category
            fixed_amount
            fixed_centi_amount
            index
            min_amount
            min_centi_amount
            max_amount
            max_centi_amount
            currency_code
            percentage
            created_at
            updated_at
          }
          nextToken
        }
        min_price_amount
        max_price_amount
        dreambox_complete_time
        dreambox_response_time
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        artist_other_artworks {
          id
          cover_image_height
          cover_image_width
          cover_image_src_url
          cover_image_thumbnail_url
          title
          likes_count
          comments_count
          description
          publication_date_time
          user_id
          is_removed
          created_at
          updated_at
        }
        reviews {
          id
          user_id
          user {
            id
            display_name
            username
          }
          reviewer_id
          reviewer {
            id
            display_name
            username
          }
          contract_id
          rating
          comment
          type
          publication_date_time
          created_at
          updated_at
        }
        category_id
        product_artworks {
          items {
            id
            product_id
            artwork_id
            artwork {
              id
              cover_image_height
              cover_image_width
              cover_image_src_url
              cover_image_thumbnail_url
              title
              likes_count
              comments_count
              description
              publication_date_time
              user_id
              is_removed
            }
            is_removed
            index
            created_at
            updated_at
          }
          nextToken
        }
        created_at
        updated_at
      }
    }
  }
`;

export const customListProducts = /* GraphQL */ `
  query ListProducts($filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        artist_id
        display_name
        slug
        description
        status
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const customListAllProducts = /* GraphQL */ `
  query ListProducts($filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        artist_id
        artist {
          id
          display_name
          username
          profile_picture_url
          seller_star_quantity
          seller_review_quantity
          is_artist
          is_selling
          artworks {
            nextToken
            items {
              cover_image_src_url
              is_removed
            }
          }
        }
        display_name
        description
        status
        category_id
        min_price_amount
        max_price_amount
        currency_code
        product_star_quantity
        product_review_quantity
        commission_paid_quantity
        slug
        prices {
          items {
            id
            product_id
            display_name
            description
            status
            type
            category
            fixed_amount
            fixed_centi_amount
            index
            min_amount
            min_centi_amount
            max_amount
            max_centi_amount
            currency_code
            percentage
            created_at
            updated_at
          }
          nextToken
        }
        product_artworks {
          items {
            id
            artwork_id
            artwork {
              id
              cover_image_src_url
              is_removed
            }
          }
          nextToken
        }
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;
