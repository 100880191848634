import Meta from '@components/meta/Meta';
import BetaUser from '@components/onboarding/seller/BetaUser';
import Completed from '@components/onboarding/seller/Completed';
import CreatePrices from '@components/onboarding/seller/CreatePrices';
import CreateProducts from '@components/onboarding/seller/CreateProducts';
import Intro from '@components/onboarding/seller/Intro';
import RequestCodeCompleted from '@components/onboarding/seller/RequestCodeCompleted';
import { Product } from '@src/API';
import { ProductStatus } from '@src/customGraphql/customModels';
import { useAuthState } from '@src/providers/AuthProvider';
import { useGetProductsByArtistIdStatus } from '@src/services/productHooks';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

const UpdateInfo = dynamic(() => import('@components/onboarding/seller/UpdateInfo'));
const RequestCode = dynamic(() => import('@components/onboarding/seller/RequestCode'));
const MetaDescription = `Tạo trang cá nhân và bảng giá để bắt đầu bán commission.`;

export enum SellerOnboardingStage {
  BetaUser = 'BETA_USER', // 0%,
  Intro = 'INTRO', // 0%
  CreateProducts = 'CREATE_PRODUCTS', // 30%
  CreatePrices = 'CREATE_PRICES', // 70%
  ReviewProducts = 'REVIEW_PRODUCTS', // Deprecated
  UploadPortfolio = 'UPLOAD_PORTFOLIO', // Deprecated
  UpdateInfo = 'UPDATE_INFO', // 100%
  Completed = 'COMPLETED', // 100%
  RequestCode = 'REQUEST_CODE', // 0%
  RequestCodeCompleted = 'REQUEST_CODE_COMPLETED', // 100%
  FinishRequestArtistCode = 'FINISH_REQUEST_ARTIST_CODE', // 100%
}

function getLastCreatedAtProduct(products: Product[]): Product | undefined {
  // Sort descending by created_at
  const sortedExistingProducts = products.sort((a, b) => {
    if (a.created_at > b.created_at) {
      return -1;
    }
    if (a.created_at < b.created_at) {
      return 1;
    }
    return 0;
  });

  // Product to add prices is the latest product created
  if (sortedExistingProducts.length === 0) return undefined;
  return sortedExistingProducts[0];
}

function BecomeASeller() {
  const [stage, setStage] = useState<SellerOnboardingStage | undefined>();
  const { user, refreshUser } = useAuthState();
  const [productToAddPrices, setProductToAddPrices] = useState<Product>();

  const router = useRouter();
  const fullPathUrl = new URL(router.asPath, process.env.NEXT_PUBLIC_DOMAIN || '').href;

  // Fetch product list
  const { data: productListData } = useGetProductsByArtistIdStatus(user?.id, ProductStatus.ACTIVE);

  // Get the last created product
  useEffect(() => {
    const lastCreatedAtProduct = getLastCreatedAtProduct(productListData?.list || []);
    setProductToAddPrices(lastCreatedAtProduct);
  }, [productListData]);

  // Change component depending on stage
  const pageView = useMemo(() => {
    if (!user) return <></>;
    switch (stage) {
      // TODO: Remove BetaUser stage when we're out of beta
      case SellerOnboardingStage.BetaUser:
        return <BetaUser user={user} setStage={setStage} isRequestedArtistCode={false} />;
      case SellerOnboardingStage.RequestCode:
        return <RequestCode user={user} refreshUser={refreshUser} setStage={setStage} />;
      case SellerOnboardingStage.RequestCodeCompleted:
        return <RequestCodeCompleted user={user} />;
      case SellerOnboardingStage.FinishRequestArtistCode:
        return <BetaUser user={user} setStage={setStage} isRequestedArtistCode={true} />;
      case SellerOnboardingStage.Intro:
        return <Intro user={user} refreshUser={refreshUser} setStage={setStage} />;
      case SellerOnboardingStage.CreateProducts:
        return (
          <CreateProducts
            user={user}
            refreshUser={refreshUser}
            setStage={setStage}
            productToAddPrices={productToAddPrices}
            setProductToAddPrices={setProductToAddPrices}
          />
        );
      case SellerOnboardingStage.CreatePrices:
        if (!productToAddPrices) return <></>;
        // If productToAddPrices has no productArtwork, go to CreateProducts
        if (
          !productToAddPrices.product_artworks?.items ||
          productToAddPrices.product_artworks.items.length === 0
        ) {
          return (
            <CreateProducts
              user={user}
              refreshUser={refreshUser}
              setStage={setStage}
              productToAddPrices={productToAddPrices}
              setProductToAddPrices={setProductToAddPrices}
            />
          );
        }
        // Otherwise, go to CreatePrices
        return (
          <CreatePrices
            user={user}
            refreshUser={refreshUser}
            setStage={setStage}
            productToAddPrices={productToAddPrices}
            setProductToAddPrices={setProductToAddPrices}
          />
        );
      case SellerOnboardingStage.ReviewProducts: // ReviewProducts is deprecated, go to CreateProducts
        return (
          <CreateProducts
            user={user}
            refreshUser={refreshUser}
            setStage={setStage}
            productToAddPrices={productToAddPrices}
            setProductToAddPrices={setProductToAddPrices}
          />
        );
      case SellerOnboardingStage.UploadPortfolio: // UploadPortfolio is deprecated, go to CreateProducts
        return (
          <CreateProducts
            user={user}
            refreshUser={refreshUser}
            setStage={setStage}
            productToAddPrices={productToAddPrices}
            setProductToAddPrices={setProductToAddPrices}
          />
        );
      case SellerOnboardingStage.UpdateInfo:
        return <UpdateInfo user={user} refreshUser={refreshUser} setStage={setStage} />;
      case SellerOnboardingStage.Completed:
        return <Completed user={user} />;
    }
  }, [stage, user, refreshUser, productToAddPrices]);

  // Set the initial stage based on the user's onboarding status
  useEffect(() => {
    if (!user) {
      // There is no user, return early
      return;
    }
    switch (user.sellerOnboardingStage) {
      case SellerOnboardingStage.BetaUser.toString():
        setStage(SellerOnboardingStage.BetaUser);
        return;
      case SellerOnboardingStage.Intro.toString():
        setStage(SellerOnboardingStage.Intro);
        return;
      case SellerOnboardingStage.FinishRequestArtistCode.toString():
        setStage(SellerOnboardingStage.FinishRequestArtistCode);
        return;
      case SellerOnboardingStage.CreateProducts.toString():
        setStage(SellerOnboardingStage.CreateProducts);
        return;
      case SellerOnboardingStage.CreatePrices.toString(): {
        setStage(SellerOnboardingStage.CreatePrices);
        return;
      }
      case SellerOnboardingStage.ReviewProducts.toString(): {
        // ReviewProducts is deprecated, go to CreateProducts
        setStage(SellerOnboardingStage.CreateProducts);
        return;
      }
      case SellerOnboardingStage.UploadPortfolio.toString(): {
        // UploadPortfolio is deprecated, go to CreateProducts
        setStage(SellerOnboardingStage.CreatePrices);
        return;
      }
      case SellerOnboardingStage.UpdateInfo.toString():
        setStage(SellerOnboardingStage.UpdateInfo);
        return;
      case SellerOnboardingStage.Completed.toString():
        setStage(SellerOnboardingStage.Completed);
        return;
      case undefined:
      case null:
        // If the user has no onboarding stage, go to BetaUser
        setStage(SellerOnboardingStage.BetaUser);
    }
  }, [user]);

  return (
    <div>
      <Meta
        title="Tạo trang cá nhân & Bảng giá | Dreamerly"
        url={fullPathUrl}
        description={MetaDescription}
      />
      {pageView}
    </div>
  );
}

export default BecomeASeller;
