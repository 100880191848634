import * as httpErr from '@libs/httpErrors';
import {
  CreateUserInput,
  CreateUserMutation,
  CreateUserMutationVariables,
  GetUserQuery,
  GetUserQueryVariables,
  ListUsersQuery,
  ListUsersQueryVariables,
  UpdateUserInput,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
  UserByPhoneNumberQuery,
  UserByPhoneNumberQueryVariables,
  UserByUsernameQuery,
  UserByUsernameQueryVariables,
} from '@src/API';
import {
  customGetUser,
  customGetUserSecretInfo,
  customListUsers,
  customUserByUsername,
} from '@src/customGraphql/customUserQueries';
import { createUser, updateUser } from '@src/graphql/mutations';
import { userByPhoneNumber } from '@src/graphql/queries';
import { ListData } from '@src/libs/models';
import captureException from '@src/services/loggerService';
import { API } from 'aws-amplify';
import axios from 'axios';

export interface IUserRepo {
  create(user: CreateUserInput): Promise<User>;
  update(user: UpdateUserInput): Promise<User>;
  get(userId: string): Promise<User>;
  getByUsername(username: string): Promise<User | null>;
  getListUser(limit?: number, nextToken?: string): Promise<ListData<User>>;
  listAllUsers(): Promise<User[]>;
  checkUserExistById(userId: string): Promise<boolean>;
  checkUserExistByPhoneNumber(phoneNumber: string): Promise<boolean>;
  getUserSecretInfo(userId: string): Promise<User>;

  getListArtistRandomize({
    sessionId,
    limit,
    categoryId,
  }: {
    sessionId: string;
    limit: number;
    categoryId: string;
  }): Promise<ListData<User> | null>;
}

export class RealUserRepo implements IUserRepo {
  public async create(user: CreateUserInput): Promise<User> {
    const variables: CreateUserMutationVariables = {
      input: user,
    };

    // TODO: Move this logic to Service
    const isExistUser = await this.checkUserExistById(user.id || '');
    if (isExistUser) {
      throw new httpErr.ConflictError(`User ${user.id} already exist!`);
    }

    let res = { data: {} } as { data: CreateUserMutation };
    try {
      res = (await API.graphql({
        query: createUser,
        authMode: 'AWS_IAM',
        variables,
      })) as { data: CreateUserMutation };
    } catch (e) {
      captureException(e);
    }

    return res.data.createUser as User;
  }

  public async get(userId: string): Promise<User> {
    const variables: GetUserQueryVariables = {
      id: userId,
    };
    const res = (await API.graphql({
      query: customGetUser,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: GetUserQuery };
    return res.data.getUser as User;
  }

  public async getUserSecretInfo(userId: string): Promise<User> {
    const variables: GetUserQueryVariables = {
      id: userId,
    };
    const res = (await API.graphql({
      query: customGetUserSecretInfo,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: GetUserQuery };
    return res.data.getUser as User;
  }

  public async getByUsername(username: string): Promise<User | null> {
    const variables: UserByUsernameQueryVariables = {
      username: username,
    };
    const res = (await API.graphql({
      query: customUserByUsername,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: UserByUsernameQuery };

    const users = res.data.userByUsername?.items;

    // If there is no user with the given username
    if (!users || users.length === 0 || !users[0]) {
      return null;
    }
    // If there are duplicate usernames
    if (users.length > 1) {
      throw new httpErr.NotFoundError(`Duplicate username ${username}.`);
    }
    return users[0] as User;
  }

  public async getListUser(limit?: number, nextToken?: string): Promise<ListData<User>> {
    const limitToUse = limit || 1000;
    const variables: ListUsersQueryVariables = {
      limit: limitToUse,
      nextToken,
    };
    const res = (await API.graphql({
      query: customListUsers,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: ListUsersQuery };

    const result: ListData<User> = {
      list: res.data.listUsers?.items as User[],
      nextToken: res.data.listUsers?.nextToken as string,
    };

    return result;
  }

  public async listAllUsers(): Promise<User[]> {
    let nextToken: string | undefined;
    let users: User[] = [];
    do {
      const res = await this.getListUser(5000, nextToken);
      users = users.concat(res.list || []);
      nextToken = res.nextToken;
    } while (nextToken);
    return users;
  }

  public async update(user: UpdateUserInput): Promise<User> {
    const variables: UpdateUserMutationVariables = {
      input: user,
    };

    const res = (await API.graphql({
      query: updateUser,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: UpdateUserMutation };

    return res.data.updateUser as User;
  }

  public async checkUserExistById(userId: string): Promise<boolean> {
    const variables: GetUserQueryVariables = {
      id: userId,
    };
    const res = (await API.graphql({
      query: customGetUser,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: GetUserQuery };

    const user = res.data.getUser as User;

    return Boolean(user);
  }

  public async checkUserExistByPhoneNumber(phoneNumber: string): Promise<boolean> {
    const variables: UserByPhoneNumberQueryVariables = {
      phone_number: phoneNumber,
    };
    const res = (await API.graphql({
      query: userByPhoneNumber,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: UserByPhoneNumberQuery };

    const users = res.data?.userByPhoneNumber?.items as User[];

    return users.length > 0;
  }

  public async getListArtistRandomize({
    sessionId,
    limit,
    categoryId,
  }: {
    sessionId: string;
    limit: number;
    categoryId: string;
  }): Promise<ListData<User> | null> {
    const url = `${process.env.NEXT_PUBLIC_API_GATEWAY_BASE_URL || ''}/list-artist-randomized`;
    const res = await axios.get(url, {
      params: {
        sessionId,
        limit,
        categoryId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data as ListData<User>;
  }
}
