import { useMemo } from 'react';

export type PrimaryInputFieldProps = {
  type: 'text' | 'email' | 'password' | 'date';
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  sizeType: 'xs' | 'sm' | 'md';
  label?: string;
  errorMessage?: string;
  defaultValue?: string;
  autoFocus?: boolean;
};

const XsInputClassName = `border border-1 border-greyscale-g3
    hover:shadow-input-focus focus:shadow-input-focus
    rounded-lg py-2 px-3 w-full text-s-tall
    placeholder:text-content-t300 outline-none`;
const XsErrorInputClassName = `border border-1 border-status-error
    hover:shadow-input-focus-error focus:shadow-input-focus-error
    rounded-lg py-2 px-3 w-full text-s-tall
    placeholder:text-content-t300 outline-none`;
const SmInputClassName = `border border-1 border-greyscale-g3
    hover:shadow-input-focus focus:shadow-input-focus
    rounded-lg py-2 px-3 w-full text-m
    placeholder:text-content-t300 outline-none`;
const SmErrorInputClassName = `border border-1 border-status-error
    hover:shadow-input-focus-error focus:shadow-input-focus-error
    rounded-lg py-2 px-3 w-full text-m
    placeholder:text-content-t300 outline-none`;
const MdInputClassName = `border border-1 border-greyscale-g3
    hover:shadow-input-focus focus:shadow-input-focus
    rounded-lg py-4 px-4 w-full text-m
    placeholder:text-content-t300 outline-none`;
const MdErrorInputClassName = `border border-1 border-status-error
    hover:shadow-input-focus-error focus:shadow-input-focus-error
    rounded-lg py-4 px-4 w-full text-m
    placeholder:text-content-t300 outline-none`;

export default function PrimaryInputField(props: PrimaryInputFieldProps) {
  const { label, type, placeholder, value, onChange, onClick, sizeType, errorMessage, autoFocus } =
    props;

  // Convert to useMemo
  const inputClassName = useMemo(() => {
    switch (sizeType) {
      case 'xs':
        if (errorMessage) {
          return XsErrorInputClassName;
        }
        return XsInputClassName;
      case 'sm':
        if (errorMessage) {
          return SmErrorInputClassName;
        }
        return SmInputClassName;
      case 'md':
        if (errorMessage) {
          return MdErrorInputClassName;
        }
        return MdInputClassName;
    }
  }, [sizeType, errorMessage]);

  return (
    <div className="flex flex-col">
      {!!label && <label className="pb-4 font-semibold">{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onClick={onClick}
        className={inputClassName}
        autoFocus={autoFocus}
      />
      {!!errorMessage && <span className="text-s text-status-error pt-2">{errorMessage}</span>}
    </div>
  );
}
