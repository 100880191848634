import {
  CreateUserChannelInput,
  CreateUserChannelMutation,
  CreateUserChannelMutationVariables,
  GetUserChannelQuery,
  GetUserChannelQueryVariables,
  ModelSortDirection,
  UpdateUserChannelInput,
  UpdateUserChannelMutation,
  UpdateUserChannelMutationVariables,
  UserChannel,
  UserChannelByChannelIdStatusQuery,
  UserChannelByChannelIdStatusQueryVariables,
  UserChannelByUserIdStatusQuery,
  UserChannelByUserIdStatusQueryVariables,
} from '@src/API';
import { customCreateUserChannel } from '@src/customGraphql/customUserChannelMutations';
import {
  customGetUserChannel,
  customUserChannelByChannelIdStatus,
  customUserChannelByUserIdStatus,
} from '@src/customGraphql/customUserChannelQueries';
import { updateUserChannel } from '@src/graphql/mutations';
import { ListData } from '@src/libs/models';
import { API } from 'aws-amplify';

export interface IUserChannelRepo {
  create(userChannel: CreateUserChannelInput): Promise<UserChannel>;
  update(userChannel: UpdateUserChannelInput): Promise<UserChannel>;
  get(userChannelId: string): Promise<UserChannel>;
  getUserChannelsByUserId(userId: string, nextToken?: string): Promise<ListData<UserChannel>>;
  getUserChannelsByChannelIdUserId({
    channelId,
    userId,
  }: {
    channelId: string;
    userId?: string | null | undefined;
  }): Promise<ListData<UserChannel>>;
  hasUserChannel({ channelId, userId }: { channelId: string; userId: string }): Promise<boolean>;
}

export class RealUserChannelRepo implements IUserChannelRepo {
  public async create(userChannel: CreateUserChannelInput): Promise<UserChannel> {
    const variables: CreateUserChannelMutationVariables = {
      input: userChannel,
    };

    let res = { data: {} } as { data: CreateUserChannelMutation };
    res = (await API.graphql({
      query: customCreateUserChannel,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: CreateUserChannelMutation };

    return res.data.createUserChannel as UserChannel;
  }

  public async get(userChannelId: string): Promise<UserChannel> {
    const variables: GetUserChannelQueryVariables = {
      id: userChannelId,
    };
    const res = (await API.graphql({
      query: customGetUserChannel,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: GetUserChannelQuery };
    return res.data.getUserChannel as UserChannel;
  }

  public async update(userChannel: UpdateUserChannelInput): Promise<UserChannel> {
    const variables: UpdateUserChannelMutationVariables = {
      input: userChannel,
    };

    const res = (await API.graphql({
      query: updateUserChannel,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: UpdateUserChannelMutation };

    return res.data.updateUserChannel as UserChannel;
  }

  /**
   * Query user channels by user_id. For each user channel's channel, only get the latest message.
   */
  public async getUserChannelsByUserId(
    userId: string,
    nextToken?: string,
  ): Promise<ListData<UserChannel>> {
    const limit = 500;
    const variables: UserChannelByUserIdStatusQueryVariables = {
      limit: limit as number,
      nextToken,
      user_id: userId,
      sortDirection: ModelSortDirection.DESC,
    };
    const res = (await API.graphql({
      query: customUserChannelByUserIdStatus,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: UserChannelByUserIdStatusQuery };

    const result: ListData<UserChannel> = {
      list: res.data.userChannelByUserIdStatus?.items as UserChannel[],
      nextToken: res.data.userChannelByUserIdStatus?.nextToken as string,
    };

    return result;
  }

  /**
   * Query user channels by channel_id. If user_id is provided, filter by user_id as well.
   * @param param0
   */
  public async getUserChannelsByChannelIdUserId({
    channelId,
    userId,
  }: {
    channelId: string;
    userId?: string | null | undefined;
  }): Promise<ListData<UserChannel>> {
    // Query by channel_id (there should be only 2 results, then filtered by user_id)
    const variables: UserChannelByChannelIdStatusQueryVariables = {
      limit: 50,
      channel_id: channelId,
      filter: {
        user_id: {
          eq: userId,
        },
      },
    };

    const res = (await API.graphql({
      query: customUserChannelByChannelIdStatus,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: UserChannelByChannelIdStatusQuery };

    const result: ListData<UserChannel> = {
      list: (res.data.userChannelByChannelIdStatus?.items || []).filter(
        (userChannel) => userChannel,
      ) as UserChannel[],
      nextToken: res.data.userChannelByChannelIdStatus?.nextToken as string,
    };
    return result;
  }

  public async hasUserChannel({
    channelId,
    userId,
  }: {
    channelId: string;
    userId: string;
  }): Promise<boolean> {
    // First, get user channels by channel_id and user_id
    const res = await this.getUserChannelsByChannelIdUserId({ channelId, userId });
    // Check if there is any result
    return Boolean((res.list || []).length > 0);
  }
}
