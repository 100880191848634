import { getMeta } from '@components/buttons/UppyUploadPhotoButton';
import {
  CreateImageInput,
  CreateImageMutation,
  CreateImageMutationVariables,
  DeleteImageInput,
  DeleteImageMutation,
  DeleteImageMutationVariables,
  Image,
  ImageByMilestoneIdQuery,
  ImageByMilestoneIdQueryVariables,
  ImageByRevisionIdQuery,
  ImageByRevisionIdQueryVariables,
  UpdateImageInput,
  UpdateImageMutation,
  UpdateImageMutationVariables,
} from '@src/API';
import { createImage, deleteImage, updateImage } from '@src/graphql/mutations';
import { imageByMilestoneId, imageByRevisionId } from '@src/graphql/queries';
import { ListData } from '@src/libs/models';
import captureException from '@src/services/loggerService';
import { API } from 'aws-amplify';
import axios from 'axios';

export interface IImageRepo {
  create(image: CreateImageInput): Promise<Image>;
  delete(image: DeleteImageInput): Promise<Image>;
  update(image: UpdateImageInput): Promise<Image>;
  listImageByMilestoneId(milestoneId: string): Promise<ListData<Image>>;
  listImageByRevisionId(revisionId: string): Promise<ListData<Image>>;

  generateWatermark({
    imageUrl,
    imageName,
    text,
  }: {
    imageUrl: string;
    imageName: string;
    text: string;
  }): Promise<{
    src_url: string;
    width: number;
    height: number;
  }>;
}

export class RealImageRepo implements IImageRepo {
  public async create(image: CreateImageInput): Promise<Image> {
    const variables: CreateImageMutationVariables = {
      input: image,
    };

    let res = { data: {} } as { data: CreateImageMutation };
    try {
      res = (await API.graphql({
        query: createImage,
        authMode: 'AWS_IAM',
        variables,
      })) as { data: CreateImageMutation };
    } catch (e) {
      captureException(e);
    }

    return res.data.createImage as Image;
  }

  public async update(image: UpdateImageInput): Promise<Image> {
    const variables: UpdateImageMutationVariables = {
      input: image,
    };

    let res = { data: {} } as { data: UpdateImageMutation };
    try {
      res = (await API.graphql({
        query: updateImage,
        authMode: 'AWS_IAM',
        variables,
      })) as { data: UpdateImageMutation };
    } catch (e) {
      captureException(e);
    }

    return res.data.updateImage as Image;
  }

  public async delete(image: DeleteImageInput): Promise<Image> {
    const variables: DeleteImageMutationVariables = {
      input: image,
    };

    let res = { data: {} } as { data: DeleteImageMutation };
    try {
      res = (await API.graphql({
        query: deleteImage,
        authMode: 'AWS_IAM',
        variables,
      })) as { data: DeleteImageMutation };
    } catch (e) {
      captureException(e);
    }

    return res.data.deleteImage as Image;
  }

  public async listImageByMilestoneId(
    milestoneId: string,
    nextToken?: string,
  ): Promise<ListData<Image>> {
    const limit = 500;
    const variables: ImageByMilestoneIdQueryVariables = {
      limit: limit,
      milestone_id: milestoneId,
      nextToken,
    };

    const res = (await API.graphql({
      query: imageByMilestoneId,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: ImageByMilestoneIdQuery };

    const result: ListData<Image> = {
      list: res.data.imageByMilestoneId?.items as Image[],
      nextToken: res.data.imageByMilestoneId?.nextToken as string,
    };

    return result;
  }

  public async listImageByRevisionId(
    revisionId: string,
    nextToken?: string,
  ): Promise<ListData<Image>> {
    const limit = 500;
    const variables: ImageByRevisionIdQueryVariables = {
      limit: limit,
      revision_id: revisionId,
      nextToken,
    };

    const res = (await API.graphql({
      query: imageByRevisionId,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: ImageByRevisionIdQuery };

    const result: ListData<Image> = {
      list: res.data.imageByRevisionId?.items as Image[],
      nextToken: res.data.imageByRevisionId?.nextToken as string,
    };

    return result;
  }

  public async generateWatermark({
    imageUrl,
    imageName,
    text,
  }: {
    imageUrl: string;
    imageName: string;
    text: string;
  }): Promise<{
    src_url: string;
    width: number;
    height: number;
  }> {
    const url = `${process.env.NEXT_PUBLIC_API_GATEWAY_BASE_URL || ''}/add-watermark-to-artwork`;
    const webappAccessApiKey = process.env.NEXT_PUBLIC_API_GATEWAY_ACCESS_KEY || '';
    const body = {
      originalImageName: imageName,
      originalImageUrl: imageUrl,
      textLabel: text,
      isCommission: 0,
    };
    const result = await axios.post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': webappAccessApiKey,
      },
    });
    const watermark = result.data as {
      src_url: string;
      width: number;
      height: number;
    };
    if (!watermark || !watermark.src_url) {
      const imageMeta = await getMeta(imageUrl);
      const width = imageMeta.width;
      const height = imageMeta.height;
      return {
        src_url: imageUrl,
        width,
        height,
      };
    }
    return watermark;
  }
}
