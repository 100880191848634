import { captureException as logError } from '@sentry/nextjs';
import { CaptureContext } from '@sentry/types';

const captureException = (error: any, captureContext?: CaptureContext | undefined) => {
  const environment = process.env.NEXT_PUBLIC_ENV_NAME || 'local';
  if (environment !== 'local') {
    logError(error, captureContext);
  } else {
    console.error(error);
  }
};

export default captureException;
