import { captureException } from '@sentry/nextjs';
import {
  CreateProductArtworkInput,
  CreateProductInput,
  Product,
  ProductArtwork,
  UpdateProductArtworkInput,
  UpdateProductInput,
} from '@src/API';
import { ProductStatus } from '@src/customGraphql/customModels';
import { ListData } from '@src/libs/models';
import { IProductRepo, RealProductRepo } from '@src/repositories/productRepo';
import { CurrencyCode } from '@src/utils/enums';
import generateRandomSlugSuffix, { DEFAULT_SLUG_SUFFIX_LENGTH } from '@src/utils/random';
import slugify from 'slugify';
import { v4 as uuidv4 } from 'uuid';

const environment = process.env.NEXT_PUBLIC_ENV_NAME || 'local';
export type CreateProductObjectInput = Pick<
  CreateProductInput,
  | 'artist_id'
  | 'display_name'
  | 'description'
  | 'category_id'
  | 'min_price_amount'
  | 'currency_code'
  | 'dreambox_response_time'
  | 'dreambox_complete_time'
>;

export type UpdateProductObjectInput = {
  id: string;
  artistId: string;
  displayName: string;
  status: string;
  slug?: string | null | undefined;
  description?: string;
  categoryId?: string;
  currencyCode?: string;
  dreamboxResponseTime?: number;
  dreamboxComleteTime?: number;
  minPriceAmount?: string;
};

export interface IProductService {
  getProduct(id: string): Promise<Product | null>;
  getProductBySlug(slug: string): Promise<Product | null>;
  createProductObject(product: CreateProductObjectInput): Promise<Product>;
  createProduct(
    artistId: string,
    displayName: string,
    productId?: string,
    description?: string,
    categoryId?: string,
    currencyCode?: string,
  ): Promise<Product>;
  updateProduct(body: UpdateProductObjectInput): Promise<Product>;
  updateProductDisplayName({
    id,
    displayName,
    slug,
    categoryId,
    currencyCode,
  }: {
    id: string;
    displayName: string;
    slug?: string | null | undefined;
    categoryId?: string;
    currencyCode?: string;
  }): Promise<Product>;
  updateProductStatus(id: string, status: ProductStatus): Promise<Product>;
  deleteProduct(id: string): Promise<void>;
  getProductsByArtistIdStatus(artistId: string, status: string): Promise<ListData<Product>>;
  getListProductRandomize({
    sessionId,
    filterIsSelling,
    filterMinPrice,
    filterMaxPrice,
    limit,
    categoryId,
    isFixedSessionResult,
    page,
  }: {
    sessionId: string;
    filterIsSelling: string;
    filterMinPrice: number;
    filterMaxPrice: number;
    limit: number;
    categoryId: string;
    isFixedSessionResult: string;
    page: number;
  }): Promise<ListData<Product> | null>;
  // ProductArtwork
  createProductArtwork(input: CreateProductArtworkInput): Promise<ProductArtwork | null>;
  getProductArtworksByProductId(
    productId: string,
    is_removed: number,
    nextToken?: string,
  ): Promise<ListData<ProductArtwork> | null>;
  updateProductArtwork(
    updateProductArtworkInput: UpdateProductArtworkInput,
  ): Promise<ProductArtwork | null>;
  getProductArtworkByProductIdArtworkId(
    productId: string,
    artworkId: string,
  ): Promise<ProductArtwork | null>;
  listProducts(limit?: number, nextToken?: string): Promise<ListData<Product> | null>;
  listAllProducts(): Promise<Product[]>;
}

export class RealProductService implements IProductService {
  private productRepo: IProductRepo;
  private slugOptions = {
    lower: true,
    locale: 'vi',
    remove: /[*+~()/'"!:@]/g,
  };

  public constructor() {
    this.productRepo = new RealProductRepo();
  }

  public async getProduct(id: string): Promise<Product | null> {
    return await this.productRepo.get(id);
  }

  public async getProductBySlug(slug: string): Promise<Product | null> {
    return await this.productRepo.getProductBySlug(slug);
  }

  public async createProduct(
    artistId: string,
    displayName: string,
    productId?: string,
    description?: string,
    categoryId?: string,
    currencyCode?: string,
  ): Promise<Product> {
    const productIdToUse = productId || uuidv4();
    // Generate a slug using product.display_name, and a random numeric suffix
    // Example of a slug: Chibi-Rh1gAzQH
    const slug =
      slugify(displayName, this.slugOptions) +
      '-' +
      generateRandomSlugSuffix(DEFAULT_SLUG_SUFFIX_LENGTH);
    const createProductInput: CreateProductInput = {
      id: productIdToUse,
      artist_id: artistId,
      display_name: displayName,
      slug: slug,
      status: 'ACTIVE',
      description: description,
      category_id: categoryId ? categoryId : 'other',
      currency_code: currencyCode ? currencyCode : CurrencyCode.VND,
    };
    const result: Product = await this.productRepo.create(createProductInput);
    return result;
  }

  public async createProductObject({
    artist_id,
    display_name,
    description,
    category_id,
    min_price_amount,
    currency_code,
    dreambox_response_time,
    dreambox_complete_time,
  }: CreateProductObjectInput): Promise<Product> {
    const slug =
      slugify(display_name, this.slugOptions) +
      '-' +
      generateRandomSlugSuffix(DEFAULT_SLUG_SUFFIX_LENGTH);

    const createProductInput: CreateProductInput = {
      artist_id: artist_id,
      display_name: display_name,
      slug: slug,
      status: 'ACTIVE',
      description: description,
      category_id: category_id || 'other',
      currency_code: currency_code || CurrencyCode.VND,
      min_price_amount,
      dreambox_complete_time,
      dreambox_response_time,
    };
    const result: Product = await this.productRepo.create(createProductInput);
    return result;
  }
  public async updateProduct({
    id,
    artistId,
    displayName,
    status,
    slug,
    description,
    categoryId,
    currencyCode,
    dreamboxComleteTime,
    dreamboxResponseTime,
    minPriceAmount,
  }: UpdateProductObjectInput): Promise<Product> {
    // If slug is provided, keep the suffix portion and change the display_name portion.
    // If slug is not provided, generate a new slug using product.display_name,
    // and a random numeric suffix
    const suffix = slug ? slug.split('-').slice(-1)[0] : generateRandomSlugSuffix(8);
    const slugToUse = slugify(displayName, this.slugOptions) + '-' + suffix;

    const updateProductInput: UpdateProductInput = {
      id,
      artist_id: artistId,
      display_name: displayName,
      status: status,
      slug: slugToUse,
      description,
      category_id: categoryId ? categoryId : 'other',
      currency_code: currencyCode ? currencyCode : CurrencyCode.VND,
      min_price_amount: minPriceAmount,
      dreambox_complete_time: dreamboxComleteTime,
      dreambox_response_time: dreamboxResponseTime,
    };
    const result: Product = await this.productRepo.update(updateProductInput);
    return result;
  }

  public async updateProductDisplayName({
    id,
    displayName,
    slug,
    categoryId,
    currencyCode,
  }: {
    id: string;
    displayName: string;
    slug?: string | null | undefined;
    categoryId?: string;
    currencyCode?: string;
  }): Promise<Product> {
    // If slug is provided, keep the suffix portion and change the display_name portion.
    // If slug is not provided, generate a new slug using product.display_name,
    // and a random numeric suffix
    const suffix = slug ? slug.split('-').slice(-1)[0] : generateRandomSlugSuffix(8);
    const slugToUse = slugify(displayName, this.slugOptions) + '-' + suffix;

    const updateProductInput: UpdateProductInput = {
      id,
      display_name: displayName,
      slug: slugToUse,
      category_id: categoryId ? categoryId : 'other',
      currency_code: currencyCode ? currencyCode : CurrencyCode.VND,
    };
    const result: Product = await this.productRepo.update(updateProductInput);
    return result;
  }

  public async updateProductStatus(id: string, status: ProductStatus): Promise<Product> {
    const updateProductInput: UpdateProductInput = {
      id,
      status: status,
    };
    const result: Product = await this.productRepo.update(updateProductInput);
    return result;
  }

  public async deleteProduct(id: string): Promise<void> {
    await this.productRepo.delete(id);
  }

  public async getProductsByArtistIdStatus(
    artistId: string,
    status: string,
  ): Promise<ListData<Product>> {
    const result = await this.productRepo.getProductsByArtistIdStatus(artistId, status);
    return result;
  }

  public async getListProductRandomize({
    sessionId,
    filterIsSelling,
    filterMinPrice,
    filterMaxPrice,
    limit,
    categoryId,
    isFixedSessionResult,
    page,
  }: {
    sessionId: string;
    filterIsSelling: string;
    filterMinPrice: number;
    filterMaxPrice: number;
    limit: number;
    categoryId: string;
    isFixedSessionResult: string;
    page: number;
  }): Promise<ListData<Product> | null> {
    return this.productRepo.getListProductRandomize({
      sessionId,
      filterIsSelling,
      filterMinPrice,
      filterMaxPrice,
      limit,
      categoryId,
      isFixedSessionResult,
      page,
    });
  }

  // ProductArtwork

  public async createProductArtwork(
    input: CreateProductArtworkInput,
  ): Promise<ProductArtwork | null> {
    try {
      const result = await this.productRepo.createProductArtwork(input);
      return result;
    } catch (err) {
      captureException(err);
      return null;
    }
  }

  public async getProductArtworksByProductId(
    productId: string,
    is_removed: number,
    nextToken?: string,
  ): Promise<ListData<ProductArtwork> | null> {
    try {
      const result = await this.productRepo.getProductArtworksByProductId(
        productId,
        is_removed,
        nextToken,
      );
      return result;
    } catch (err) {
      captureException(err);
      return null;
    }
  }

  public async updateProductArtwork(
    updateProductArtworkInput: UpdateProductArtworkInput,
  ): Promise<ProductArtwork | null> {
    try {
      const result = await this.productRepo.updateProductArtwork(updateProductArtworkInput);
      console.log('thanh2 success updateProductArtwork', result);
      return result;
    } catch (err) {
      console.log('thanh1 errror updateProductArtwork', err);
      captureException(err);
      return null;
    }
  }

  public async getProductArtworkByProductIdArtworkId(
    productId: string,
    artworkId: string,
  ): Promise<ProductArtwork | null> {
    try {
      const result: ProductArtwork[] = [];
      const removedProductArtworks = await this.productRepo.getProductArtworksByProductId(
        productId,
        1,
      );
      result.push(...(removedProductArtworks.list || []));
      const notRemovedProductArtworks = await this.productRepo.getProductArtworksByProductId(
        productId,
        0,
      );
      result.push(...(notRemovedProductArtworks.list || []));
      return result.filter((productArtwork) => productArtwork.artwork_id === artworkId)[0];
    } catch (err) {
      captureException(err);
      return null;
    }
  }

  public async listProducts(limit?: number, nextToken?: string): Promise<ListData<Product> | null> {
    try {
      const result = await this.productRepo.list(limit ? limit : 5000, nextToken);
      return result;
    } catch (error) {
      captureException(error);
      return null;
    }
  }

  public async listAllProducts(): Promise<Product[]> {
    try {
      const result = await this.productRepo.listAllProducts();
      return result;
    } catch (error) {
      captureException(error);
      return [];
    }
  }
}
