import { Product, ProductArtwork } from '@src/API';
import { ListData } from '@src/libs/models';
import { NONE_STRING_VALUE } from '@src/utils/constants';
import { ALL_CATEGORY_ID } from '@src/utils/enums';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  CreateProductObjectInput,
  IProductService,
  RealProductService,
  UpdateProductObjectInput,
} from './productService';

const productService: IProductService = new RealProductService();

/**
 * React Query hook to get product by id.
 */
export const useGetProductQuery = (id: string | null | undefined, initialData?: Product) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['productService', 'getProduct', id],
    queryFn: async () => {
      if (!id) return undefined;
      return await productService.getProduct(id);
    },
    initialData,
    enabled: !!id,
  });
};

export const useGetProductBySlugQuery = (
  slug: string | null | undefined,
  initialData?: Product,
) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['productService', 'getProductBySlug', slug],
    queryFn: async () => {
      if (!slug) return undefined;
      return await productService.getProductBySlug(slug);
    },
    initialData,
    enabled: !!slug,
  });
};

/**
 * React Query hook to get products by user_id and status.
 */
export const useGetProductsByArtistIdStatus = (
  userId: string | null | undefined,
  status: string,
  initialProducts?: ListData<Product> | undefined,
) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['productService', 'getProductsByArtistIdStatus', userId, status],
    queryFn: async () => {
      if (!userId) return { list: [] } as ListData<Product>;
      return await productService.getProductsByArtistIdStatus(userId, status);
    },
    enabled: !!userId,
    initialData: initialProducts,
  });
};

export const useGetProductArtworksByProductId = (productId: string | null | undefined) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: ['productService', 'getProductArtworksByProductId', productId],
    queryFn: async () => {
      if (!productId) return { list: [] } as ListData<ProductArtwork>;
      return await productService.getProductArtworksByProductId(productId, 0);
    },
    enabled: !!productId,
  });
};

export const useListProductsRandomizedQuery = (
  sessionIdWithCategoryId: string,
  categorySessionKey: string,
  filterIsSelling: string,
  filterMinPrice: number,
  filterMaxPrice: number,
  limit: number,
  pageCursor: number | null | undefined,
  initialData: Product[],
) => {
  const productService: IProductService = new RealProductService();
  return useInfiniteQuery({
    // Query keys: name of service, name of method, arguments
    // Use categorySessionKey to make sure the query is re-triggered when user change category instead of using old data
    // This is to solve bug where user change category and the data is cache so scroll and pagination not working
    queryKey: [
      'productService',
      'getListProductRandomize',
      sessionIdWithCategoryId,
      categorySessionKey,
    ],
    queryFn: async ({ pageParam }) => {
      console.log('QQ: listProductsRandomized - pageCursor', pageCursor);
      const [sessionId, categoryId] = sessionIdWithCategoryId.split('_');
      console.log('thanh0823 - sessionId', sessionId, categoryId, pageCursor, categorySessionKey);
      if (categoryId === NONE_STRING_VALUE || pageCursor === null || pageCursor === undefined) {
        return;
      }
      const data = await productService.getListProductRandomize({
        sessionId,
        filterIsSelling,
        filterMinPrice,
        filterMaxPrice,
        limit,
        categoryId: categoryId.toLowerCase(),
        isFixedSessionResult: 'true',
        page: pageCursor,
      });
      console.log('thanh0823 - data', data, pageCursor);
      // If data is undefined, return list with undefined to make sure
      // the query is not re-triggered
      if (data?.list === undefined || data?.list === null) {
        const listData: ListData<Product> = {
          list: undefined,
          nextToken: undefined,
        };
        return listData;
      }

      return data;
    },
    getNextPageParam: (lastPage) => {
      console.log('thanh0823 - lastPage', lastPage);
      const canLoadMore = lastPage?.list !== null && lastPage?.list !== undefined;
      return canLoadMore;
    },
    initialData: () => {
      /*
        If sessionIdWithCategoryId include _ALL then we will append initialData
        to the first page
      */
      const data: ListData<Product> = {
        list: initialData,
        nextToken: undefined,
      };
      if (sessionIdWithCategoryId.includes(ALL_CATEGORY_ID)) {
        console.log('QQ: listProductsRandomized return initialData', data);
        return { pages: [data], pageParams: [] };
      }
      /*
        Otherwise, return empty data and
        let the query fetch the first page for specific category
      */
      const emptyData: ListData<Product> = {
        list: [],
        nextToken: undefined,
      };
      console.log('QQ: listProductsRandomized return empty');
      return { pages: [emptyData], pageParams: [] };
    },
    enabled: !!sessionIdWithCategoryId,
    /*
      If we don't set staleTime because when user open this page,
      tanstack will not include first page is initialData
    */
    staleTime: 500,
  });
};

export const useMutateCreateProduct = () => {
  return useMutation({
    mutationKey: ['Product', 'create'],
    mutationFn: (params: CreateProductObjectInput) => productService.createProductObject(params),
  });
};

export const useMutateUpdateProduct = () => {
  return useMutation({
    mutationFn: (params: UpdateProductObjectInput) => productService.updateProduct(params),
  });
};

export const useMutationBatchCreateProductArtwork = () => {
  return useMutation({
    mutationFn: (params: any[]) =>
      Promise.allSettled(params.map((param) => productService.createProductArtwork(param))),
  });
};
export const useMutationBatchDeleteProductArtwork = () => {
  return useMutation({
    mutationFn: (params: any[]) =>
      Promise.allSettled(params.map((param) => productService.updateProductArtwork(param))),
  });
};
