import PrimaryInputField from '@components/input/PrimaryInputField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PriceCategory } from '@src/customGraphql/customModels';
import { convertToDigitOnlyText, formatDigitOnlyText } from '@src/utils/formatText';
import { ExtraInputType } from '@src/utils/prices';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const Menu = dynamic(() => import('@mui/material/Menu'));

const DefaultFixedPriceInputClass = `flex flex-row items-center h-10 px-3
  rounded-lg ring-0 text-s font-normal font-sans
  border border-1 border-greyscale-g3
  hover:shadow-input-focus focus:shadow-input-focus outline-none w-full`;
const ErrorFixedPriceInputClass = `flex flex-row items-center h-10 px-3
  rounded-lg ring-0 text-s font-normal font-sans
  border border-1 border-status-error
  hover:shadow-input-focus-error focus:shadow-input-focus-error outline-none w-full`;
const DefaultRangePriceInputClass = `flex flex-row items-center h-10 px-3 rounded-lg text-s-tall
  border border-1 border-greyscale-g3
  hover:shadow-input-focus focus:shadow-input-focus outline-none`;
const ErrorRangePriceInputClass = `flex flex-row items-center h-10 px-3 rounded-lg text-s-tall
  border border-1 border-status-error
  hover:shadow-input-focus-error focus:shadow-input-focus-error outline-none`;

type CreatePricesExtraTableProps = {
  extraList: ExtraInputType[];
  setExtraList: (extraList: ExtraInputType[]) => void;
};

function CreatePricesExtraTable(props: CreatePricesExtraTableProps) {
  const { extraList, setExtraList } = props;

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(null);
  // We can only create 1 Menu component, not a list of Menu, so need to keep track of which row's category
  // is being edited
  const [rowToChangeCategory, setRowToChangeCategory] = useState<null | ExtraInputType>(null);
  const isCategorySelectionMenuOpen = Boolean(categoryAnchorEl);

  const firstErrorMessage = useMemo(() => {
    const firstErrorExtra = extraList.find(
      (extra) =>
        extra.fixedAmountErrorMessage !== '' ||
        extra.minAmountErrorMessage !== '' ||
        extra.maxAmountErrorMessage !== '',
    );
    // Return the non-empty message between fixedAmountErrorMessage, minAmountErrorMessage, and maxAmountErrorMessage
    return (
      firstErrorExtra?.fixedAmountErrorMessage ||
      firstErrorExtra?.minAmountErrorMessage ||
      firstErrorExtra?.maxAmountErrorMessage
    );
  }, [extraList]);

  const handleOpenCategorySelectionMenu = (
    event: React.MouseEvent<HTMLElement>,
    row: ExtraInputType,
  ) => {
    setRowToChangeCategory(row);
    setCategoryAnchorEl(event.currentTarget);
  };
  const handleCloseCategorySelectionMenu = () => {
    setCategoryAnchorEl(null);
  };

  const onExtraCategoryChange = (category: string) => {
    const newExtraList = extraList.map((extra) => {
      if (extra.id === rowToChangeCategory?.id) {
        return {
          ...extra,
          category: category,
          // Clear the values of the other fields
          fixedAmount: '',
          minAmount: '',
          maxAmount: '',
          percentage: '',
        };
      }
      return extra;
    });
    setExtraList(newExtraList);
  };

  const onExtraFieldChange = (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>,
    field: 'display_name' | 'fixed_amount' | 'min_amount' | 'max_amount' | 'percentage',
  ) => {
    // Verify whether the input is valid
    const newValue = e.target.value;
    // Update extraList with specified field at the given id
    const newExtraList = extraList.map((extra) => {
      if (extra.id === id) {
        switch (field) {
          case 'display_name':
            return { ...extra, displayName: newValue };
          case 'fixed_amount': {
            const formattedNumber = formatDigitOnlyText(newValue);
            const unformattedNumber = convertToDigitOnlyText(newValue);
            if (formattedNumber !== undefined && unformattedNumber !== undefined) {
              return {
                ...extra,
                formattedFixedAmount: formattedNumber,
                unformattedFixedAmount: unformattedNumber,
                fixedAmountErrorMessage: '',
              };
            }
            return extra;
          }
          case 'min_amount': {
            const formattedNumber = formatDigitOnlyText(newValue);
            const unformattedNumber = convertToDigitOnlyText(newValue);
            if (formattedNumber !== undefined && unformattedNumber !== undefined) {
              return {
                ...extra,
                formattedMinAmount: formattedNumber,
                unformattedMinAmount: unformattedNumber,
                minAmountErrorMessage: '', // Clear both min and max error messages
                maxAmountErrorMessage: '',
              };
            }
            return extra;
          }
          case 'max_amount': {
            const formattedNumber = formatDigitOnlyText(newValue);
            const unformattedNumber = convertToDigitOnlyText(newValue);
            if (formattedNumber !== undefined && unformattedNumber !== undefined) {
              return {
                ...extra,
                formattedMaxAmount: formattedNumber,
                unformattedMaxAmount: unformattedNumber,
                minAmountErrorMessage: '', // Clear both min and max error messages
                maxAmountErrorMessage: '',
              };
            }
            return extra;
          }
          case 'percentage':
            return { ...extra, percentage: newValue };
        }
      }
      return extra;
    });
    setExtraList(newExtraList);
  };

  const onAddExtraClick = () => {
    const newExtraList = extraList.concat([
      {
        id: uuidv4(),
        displayName: '',
        category: PriceCategory.FIXED,
        formattedFixedAmount: '',
        unformattedFixedAmount: '',
        formattedMinAmount: '',
        unformattedMinAmount: '',
        formattedMaxAmount: '',
        unformattedMaxAmount: '',
        percentage: '',
        fixedAmountErrorMessage: '',
        minAmountErrorMessage: '',
        maxAmountErrorMessage: '',
        index: extraList.length - 1,
      },
    ]);
    setExtraList(newExtraList);
  };

  const onRemoveExtraClick = (id: string) => {
    const newExtraList = extraList.filter((extra) => extra.id !== id);
    setExtraList(newExtraList);
  };

  const renderExtraPriceField = (row: ExtraInputType) => {
    // Render the price field based on category
    switch (row.category) {
      // Do not use PriceInputField here because of potential conflict with MUI table
      case PriceCategory.FIXED:
        return (
          <div className="flex flex-row justify-between items-center">
            <div
              className={
                row.fixedAmountErrorMessage
                  ? ErrorFixedPriceInputClass
                  : DefaultFixedPriceInputClass
              }
            >
              <input
                // CRITICAL! AVOID type="number" because of scrolling & auto-incrementing issue.
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                className="outline-none w-full placeholder:text-content-t300"
                value={row.formattedFixedAmount}
                onChange={(e) => onExtraFieldChange(row.id, e, 'fixed_amount')}
                placeholder="0"
              />
              <span className="ml-2">₫</span>
            </div>
          </div>
        );
      case PriceCategory.RANGE:
        return (
          <div className="flex flex-row justify-between items-center">
            <div
              className={
                row.minAmountErrorMessage ? ErrorRangePriceInputClass : DefaultRangePriceInputClass
              }
            >
              <input
                // CRITICAL! AVOID type="number" because of scrolling & auto-incrementing issue.
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                className="outline-none w-14 placeholder:text-content-t300"
                value={row.formattedMinAmount}
                onChange={(e) => onExtraFieldChange(row.id, e, 'min_amount')}
                placeholder="0"
              />
              <span className="ml-2 flex-shrink-0">₫</span>
            </div>
            <span className="p-2">-</span>
            <div
              className={
                row.maxAmountErrorMessage ? ErrorRangePriceInputClass : DefaultRangePriceInputClass
              }
            >
              <input
                // CRITICAL! AVOID type="number" because of scrolling & auto-incrementing issue.
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                className="outline-none w-14 placeholder:text-content-t300"
                value={row.formattedMaxAmount}
                onChange={(e) => onExtraFieldChange(row.id, e, 'max_amount')}
                placeholder="0"
              />
              <span className="ml-2 flex-shrink-0">₫</span>
            </div>
          </div>
        );
      case PriceCategory.PERCENTAGE:
        return (
          <div className="flex flex-row justify-between items-center">
            <div
              className="flex flex-row items-center h-10 px-3 rounded-lg ring-0 text-s-tall
              border border-1 border-greyscale-g3
              hover:shadow-input-focus focus:shadow-input-focus outline-none w-full justify-between"
            >
              <input
                // CRITICAL! AVOID type="number" because of scrolling & auto-incrementing issue.
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                className="outline-none w-16"
                value={row.percentage}
                onChange={(e) => onExtraFieldChange(row.id, e, 'percentage')}
                placeholder="0"
              />
              <span className="ml-2">% tổng giá</span>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="flex flex-col">
      <TableContainer>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell width={280} padding="none" sx={{ borderColor: '#CCCCCC' }}>
                <p className="text-m font-sans font-medium">Tên phụ phí</p>
              </TableCell>
              <TableCell width={72} sx={{ borderColor: '#CCCCCC' }}>
                <p className="text-m font-sans font-medium">Loại</p>
              </TableCell>
              <TableCell padding="none" sx={{ borderColor: '#CCCCCC' }}>
                <p className="text-m font-sans font-medium">Giá</p>
              </TableCell>
              <TableCell width={36} padding="none" sx={{ borderColor: '#CCCCCC' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {extraList
              .sort((a, b) => {
                return (a.index || 0) - (b.index || 0);
              })
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    padding="none"
                    sx={{ paddingY: 2, borderColor: '#CCCCCC', borderStyle: 'dotted' }}
                  >
                    <div className="ml-0.5">
                      <PrimaryInputField
                        type="text"
                        placeholder="Background, Character, Phí sửa, v.v."
                        value={row.displayName}
                        onChange={(e) => onExtraFieldChange(row.id, e, 'display_name')}
                        sizeType="xs"
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    padding="none"
                    sx={{ paddingX: 2, borderColor: '#CCCCCC', borderStyle: 'dotted' }}
                  >
                    <button
                      className="rounded-lg border border-greyscale-g4
                      shadow-md hover:shadow-lg h-10 w-10"
                      onClick={(e) => handleOpenCategorySelectionMenu(e, row)}
                    >
                      {row.category === PriceCategory.FIXED
                        ? '₫'
                        : row.category === PriceCategory.RANGE
                        ? '~'
                        : '%'}
                    </button>
                  </TableCell>
                  <TableCell padding="none" sx={{ borderColor: '#CCCCCC', borderStyle: 'dotted' }}>
                    {renderExtraPriceField(row)}
                  </TableCell>
                  <TableCell padding="none" sx={{ borderColor: '#CCCCCC', borderStyle: 'dotted' }}>
                    <button
                      className="flex items-center shrink-0 p-2"
                      onClick={() => onRemoveExtraClick(row.id)}
                    >
                      <Image src="/ic_close.svg" width={20} height={20} />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        anchorEl={categoryAnchorEl}
        open={isCategorySelectionMenuOpen}
        onClose={handleCloseCategorySelectionMenu}
        onClick={handleCloseCategorySelectionMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.20))',
            mt: 1,
            borderRadius: '8px',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <div className="flex flex-col">
          <button
            className="text-s py-2 pl-3 pr-6 hover:bg-greyscale-g1"
            onClick={() => onExtraCategoryChange(PriceCategory.FIXED)}
          >
            Giá cố định (₫)
          </button>
          <button
            className="text-s py-2 pl-3 pr-6 hover:bg-greyscale-g1"
            onClick={() => onExtraCategoryChange(PriceCategory.RANGE)}
          >
            Khoảng giá (~)
          </button>
          <button
            className="text-s py-2 pl-3 pr-6 hover:bg-greyscale-g1"
            onClick={() => onExtraCategoryChange(PriceCategory.PERCENTAGE)}
          >
            Phần trăm (%)
          </button>
        </div>
      </Menu>
      {/* Display the first error message, if there is any error */}
      {!!firstErrorMessage && (
        <span className="text-s text-status-error pt-2">{firstErrorMessage}</span>
      )}
      <button
        className="text-start text-s font-semibold mt-6 mb-4 text-primary-p4"
        onClick={onAddExtraClick}
      >
        + Thêm phụ phí
      </button>
    </div>
  );
}

export default CreatePricesExtraTable;
