import UppyS3UploaderPlugin from '@src/utils/uppyS3UploaderPlugin';
import Uppy, { UppyOptions } from '@uppy/core';
import ImageEditor, { ImageEditorOptions } from '@uppy/image-editor';
import { DashboardModal } from '@uppy/react';
import { ReactNode, useEffect, useState } from 'react';

export function getMeta(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject();
    img.src = url;
  });
}

/**
 * This component is a wrapper around Uppy's DashboardModal component.
 *
 * Important: autoOpenFileEditor has to be fixed when initializing this component
 * (ie, autoOpenFileEditor cannot be a variable or a React state). Other props (e.g. `note`)
 * can be set dynamically.
 *
 * TODO (long): Investigate this, because this seems to be a bug in Uppy.
 */
export default function UppyUploadPhotoButton({
  children,
  isDashboardModalOpen,
  setIsDashboardModalOpen,
  handleS3Key,
  handleCompleteAllResults,
  autoOpenFileEditor = false, // Has to be fixed, cannot be dynamic (probably a bug in library)
  uppyOptions,
  imageEditorOptions,
  note,
}: {
  children: ReactNode;
  isDashboardModalOpen: boolean;
  setIsDashboardModalOpen: (isOpen: boolean) => void;
  handleS3Key: (s3KeyString: string, srcUrl: string) => Promise<void | any> | void;
  handleCompleteAllResults?: (results: any[]) => Promise<void>;
  autoOpenFileEditor?: boolean; // Has to be fixed, cannot be dynamic
  uppyOptions?: Partial<UppyOptions<Record<string, unknown>>>;
  imageEditorOptions?: ImageEditorOptions;
  note?: string;
}) {
  // Initialize Uppy (has to use useState or useRef to prevent recreating Uppy instance)
  // Ref: https://community.transloadit.com/t/auto-start-webcam/16820/11
  const [uppy] = useState(() => {
    const uppyInstance = new Uppy()
      .use(
        UppyS3UploaderPlugin,
        // Default Uppy Core options
        {
          restrictions: {
            maxFileSize: 25 * 1024 * 1024, // 25MB in bytes
            maxNumberOfFiles: 10,
            allowedFileTypes: ['image/*'],
          },
        },
      )
      .use(
        ImageEditor,
        // Default ImageEditor options
        {
          quality: 0.9,
          cropperOptions: {
            croppedCanvasOptions: {},
            modal: false,
          },
        },
      );
    return uppyInstance;
  });

  // Set options for Uppy Core
  useEffect(() => {
    if (uppyOptions) {
      // Set options for Uppy instance dynamically
      uppy.setOptions(uppyOptions);
    }
  }, [uppy, uppyOptions]);

  // Set image editor options for ImageEditor plugin
  useEffect(() => {
    if (imageEditorOptions) {
      // Set options for Uppy instance dynamically
      uppy.getPlugin('ImageEditor')?.setOptions(imageEditorOptions);
    }
  }, [uppy, imageEditorOptions]);

  // Set note for react:DashboardModal plugin
  useEffect(() => {
    if (note) {
      // Set note for Uppy instance dynamically
      uppy.getPlugin('react:DashboardModal')?.setOptions({ note });
    }
  }, [uppy, note]);

  useEffect(() => {
    // We need to put the event handler inside a function so that we can remove it later.
    // Otherwise, every time the component re-renders, a new event handler will be attached.
    // Ref: https://github.com/transloadit/uppy/issues/2692
    const completeEventHandler = async (result: any) => {
      const { s3Keys, type } = result;

      if (type === 'custom') {
        const results = await Promise.all(
          s3Keys.map(async (keyObj: any) => {
            const key = keyObj.key as string;
            // Construct srcUrl
            const srcUrl = `${process.env.NEXT_PUBLIC_S3_BASE_URL}/public/${key}`;
            // Call callback function to handle s3Key
            return await handleS3Key(key, srcUrl);
          }),
        );
        // Handle all results, if necessary
        await handleCompleteAllResults?.(results);
        // Close modal after finishing uploading
        uppy.cancelAll();
        setIsDashboardModalOpen(false);
      }
    };

    // Attach and clean up event listener
    uppy.on('complete', completeEventHandler);
    // Clean up old handler if useEffect is called again
    return () => {
      uppy.off('complete', completeEventHandler);
    };
  }, [uppy, handleS3Key, handleCompleteAllResults, setIsDashboardModalOpen]);

  const handleOnRequestClose = () => {
    uppy.cancelAll();
    setIsDashboardModalOpen(false);
  };

  return (
    <>
      {children}
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside
        open={isDashboardModalOpen}
        onRequestClose={handleOnRequestClose}
        plugins={['StatusBar', 'ProgressBar', 'ImageEditor']}
        disablePageScrollWhenModalOpen={false}
        showProgressDetails={true}
        proudlyDisplayPoweredByUppy={false}
        autoOpenFileEditor={autoOpenFileEditor}
      />
    </>
  );
}
