import {
  Channel,
  CreateChannelInput,
  CreateChannelMutation,
  CreateChannelMutationVariables,
  GetChannelQuery,
  GetChannelQueryVariables,
  UpdateChannelInput,
  UpdateChannelMutation,
  UpdateChannelMutationVariables,
} from '@src/API';
import { createChannel, updateChannel } from '@src/graphql/mutations';
import { getChannel } from '@src/graphql/queries';
import { API } from 'aws-amplify';

export interface IChannelRepo {
  create(channel: CreateChannelInput): Promise<Channel>;
  update(channel: UpdateChannelInput): Promise<Channel>;
  get(channelId: string): Promise<Channel>;
}

export class RealChannelRepo implements IChannelRepo {
  public async create(channel: CreateChannelInput): Promise<Channel> {
    const variables: CreateChannelMutationVariables = {
      input: channel,
    };

    let res = { data: {} } as { data: CreateChannelMutation };
    res = (await API.graphql({
      query: createChannel,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: CreateChannelMutation };

    return res.data.createChannel as Channel;
  }

  public async get(channelId: string): Promise<Channel> {
    const variables: GetChannelQueryVariables = {
      id: channelId,
    };
    const res = (await API.graphql({
      query: getChannel,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: GetChannelQuery };
    return res.data.getChannel as Channel;
  }

  public async update(channel: UpdateChannelInput): Promise<Channel> {
    const variables: UpdateChannelMutationVariables = {
      input: channel,
    };

    const res = (await API.graphql({
      query: updateChannel,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: UpdateChannelMutation };

    return res.data.updateChannel as Channel;
  }
}
