const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const DEFAULT_SLUG_SUFFIX_LENGTH = 8;

export const DefaultSlugifyOptions = {
  lower: false,
  locale: 'vi',
  remove: /[*+~()/'"!:@]/g,
}

export default function generateRandomSlugSuffix(length: number = DEFAULT_SLUG_SUFFIX_LENGTH) {
  // Reference:
  // https://alex7kom.github.io/nano-nanoid-cc/?alphabet=0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ&size=8&speed=100&speedUnit=hour
  let result = '';
  let counter = 0;
  const alphabetSize = ALPHABET.length;
  while (counter < length) {
    result += ALPHABET.charAt(Math.floor(Math.random() * alphabetSize));
    counter += 1;
  }
  return result;
}
