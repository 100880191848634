export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum PriceType {
  CORE = 'CORE',
  EXTRA = 'EXTRA',
}

export enum PriceCategory {
  FIXED = 'FIXED',
  RANGE = 'RANGE',
  PERCENTAGE = 'PERCENTAGE',
}

export enum PriceStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum JobStatus {
  OPEN = 'OPEN', // Default value when buyer creates a new job
  CANCELLED = 'CANCELLED', // If the buyer cancels this job
  SELLER_DECLINED = 'SELLER_DECLINED', // If the seller declines the job (only applicable for EXCLUSIVE job)
  DRAFT = 'DRAFT',
  PRE_PAYMENT = 'PRE_PAYMENT', // In Dreambox, after buyer click on "Thanh toan" but before they actually pay with MoMo
  ACCEPTED = 'ACCEPTED',
}

export enum ProposalStatus {
  OPEN = 'OPEN', // Default value when seller send a new proposal
  ACCEPTED = 'ACCEPTED', // Once the buyer accept the proposal
  REJECTED = 'REJECTED', // If the buyer reject the proposal
  EXPIRED = 'EXPIRED', // If the proposal is not accepted before the expiration date
  CANCELLED = 'CANCELLED', // If the seller cancelled this proposal
}

export enum PayoutStatus {
  REQUESTED = 'REQUESTED', // Default value when seller request a new payout
  COMPLETED = 'COMPLETED', // If the payout is completed
}

export enum ContractStatus {
  OPEN = 'OPEN', // Default value when seller send a new contract
  // If buyer bank-transfers, but we have not confirmed yet
  PENDING_BANK_TRANSFER_CONFIRMATION = 'PENDING_BANK_TRANSFER_CONFIRMATION',
  ACCEPTED = 'ACCEPTED', // Once the buyer accept the contract and send money to Dreamerly Balance
  REJECTED = 'REJECTED', // If the buyer reject the contract
  COMPLETED = 'COMPLETED', // If the contract is completed
  CANCELLED_BEFORE_PAYMENT = 'CANCELLED_BEFORE_PAYMENT', // If the buyer cancelled this contract before user pays
  CANCELLED_PENDING_REFUND = 'CANCELLED_PENDING_REFUND', // Before Dreamerly refund money to buyer
  // After Dreamerly refund money to buyer due to buyer fault
  CANCELLED_SUCCEEDED_REFUND_BUYER_FAULT = 'CANCELLED_SUCCEEDED_REFUND_BUYER_FAULT',
  // After Dreamerly refund money to buyer due to seller fault
  CANCELLED_SUCCEEDED_REFUND_SELLER_FAULT = 'CANCELLED_SUCCEEDED_REFUND_SELLER_FAULT',
  // After Dreamerly refund money to buyer when both buyer and seller agree to cancel the contract
  CANCELLED_SUCCEEDED_REFUND_MUTUALLY = 'CANCELLED_SUCCEEDED_REFUND_MUTUALLY',
  CANCELLED_FAILED_REFUND = 'CANCELLED_FAILED_REFUND',
  SELLER_CANCEL_REQUESTED = 'SELLER_CANCEL_REQUESTED',
  BUYER_CANCEL_REQUESTED = 'BUYER_CANCEL_REQUESTED',
  DELIVERED = 'DELIVERED',
  IN_PROGRESS = 'IN_PROGRESS',
  STOPPED = 'STOPPED',
  REQUESTED = 'REQUESTED',
  REQUEST_REJECTED = 'REQUEST_REJECTED',
  IN_REVISION = 'IN_REVISION',
  IN_DISPUTE = 'IN_DISPUTE',
  PRE_PAYMENT = 'PRE_PAYMENT', // In Dreambox, after buyer click on "Thanh toan" but before they actually pay with MoMo
  PRE_OPEN = 'PRE_OPEN', // In Dreambox, after buyer pay with Momo and before artist confirm the job/contract
  OVER_DEADLINE = 'OVER_DEADLINE',
  WARNING_DEADLINE = 'WARNING_DEADLINE',
}

export enum ContractStateName {
  CONTRACT_OPEN = 'CONTRACT.OPEN',
  CONTRACT_PRE_OPEN = 'CONTRACT.PRE_OPEN',
  CONTRACT_PRE_PAYMENT = 'CONTRACT.PRE_PAYMENT',
  CONTRACT_PENDING_BANK_TRANSFER_CONFIRMATION = 'CONTRACT.PENDING_BANK_TRANSFER_CONFIRMATION',
  CONTRACT_COMPLETED = 'CONTRACT.COMPLETED',
  CONTRACT_CANCELLED_BEFORE_PAYMENT = 'CONTRACT.CANCELLED_BEFORE_PAYMENT',
  CONTRACT_CANCELLED_PENDING_REFUND = 'CONTRACT.CANCELLED_PENDING_REFUND',
  // After Dreamerly refund money to buyer due to buyer fault
  CONTRACT_CANCELLED_SUCCEEDED_REFUND_BUYER_FAULT = 'CONTRACT.CANCELLED_SUCCEEDED_REFUND_BUYER_FAULT',
  // After Dreamerly refund money to buyer due to seller fault
  CONTRACT_CANCELLED_SUCCEEDED_REFUND_SELLER_FAULT = 'CONTRACT.CANCELLED_SUCCEEDED_REFUND_SELLER_FAULT',
  // After Dreamerly refund money to buyer when both buyer and seller agree to cancel the contract
  CONTRACT_CANCELLED_SUCCEEDED_REFUND_MUTUALLY = 'CONTRACT.CANCELLED_SUCCEEDED_REFUND_MUTUALLY',
  CONTRACT_CANCELLED_SUCCEEDED_REFUND = 'CONTRACT.CANCELLED_SUCCEEDED_REFUND', // Deprecated
  CONTRACT_CANCELLED_FAILED_REFUND = 'CONTRACT.CANCELLED_FAILED_REFUND',
  CONTRACT_SELLER_CANCEL_REQUESTED = 'CONTRACT.SELLER_CANCEL_REQUESTED',
  CONTRACT_BUYER_CANCEL_REQUESTED = 'CONTRACT.BUYER_CANCEL_REQUESTED',
  CONTRACT_IN_DISPUTE = 'CONTRACT.IN_DISPUTE',
  MILESTONE_IN_PROGRESS = 'MILESTONE.IN_PROGRESS',
  MILESTONE_DELIVERED = 'MILESTONE.DELIVERED',
  MILESTONE_COMPLETED = 'MILESTONE.COMPLETED',
  MILESTONE_STOPPED = 'MILESTONE.STOPPED',
  REVISION_REQUESTED = 'REVISION.REQUESTED',
  REVISION_REQUEST_REJECTED = 'REVISION.REQUEST_REJECTED',
  REVISION_IN_PROGRESS = 'REVISION.IN_PROGRESS',
  REVISION_DELIVERED = 'REVISION.DELIVERED',
  PAYMENT_INTENT_SUCCEEDED = 'PAYMENT_INTENT.SUCCEEDED',
}

export enum ContractRoleAction {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export enum ContractRole {
  SELLER = 'SELLER',
  BUYER = 'BUYER',
}

export enum ContractResource {
  CONTRACT = 'CONTRACT',
  MILESTONE = 'MILESTONE',
  REVISION = 'REVISION',
}

export enum PaymentIntentStatus {
  REQUIRES_PAYMENT_METHOD = 'REQUIRES_PAYMENT_METHOD',
  REQUIRES_CONFIRMATION = 'REQUIRES_CONFIRMATION',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  PROCESSING = 'PROCESSING',
  SUCCEEDED = 'SUCCEEDED',
  CANCELLED = 'CANCELLED',
}

export enum EventType {
  CONTRACT_OPEN = 'CONTRACT.OPEN',
  CONTRACT_COMPLETED = 'CONTRACT.COMPLETED',
  CONTRACT_CANCELLED_PENDING_REFUND = 'CONTRACT.CANCELLED_PENDING_REFUND',
  CONTRACT_CANCELLED_SUCCEEDED_REFUND = 'CONTRACT.CANCELLED_SUCCEEDED_REFUND',
  CONTRACT_CANCELLED_FAILED_REFUND = 'CONTRACT.CANCELLED_FAILED_REFUND',
  CONTRACT_SELLER_CANCEL_REQUESTED = 'CONTRACT.SELLER_CANCEL_REQUESTED',
  CONTRACT_BUYER_CANCEL_REQUESTED = 'CONTRACT.BUYER_CANCEL_REQUESTED',
  CONTRACT_IN_DISPUTE = 'CONTRACT.IN_DISPUTE',

  // MILESTONE INPROGRESS
  MILESTONE_IN_PROGRESS = 'MILESTONE.IN_PROGRESS',
  MILESTONE_IN_PROGRESS_REQUEST_TO_EXTEND_DEADLINE = 'MILESTONE.IN_PROGRESS.REQUEST_TO_EXTEND_DEADLINE',
  MILESTONE_IN_PROGRESS_ACCEPT_EXTENSION_DEADLINE = 'MILESTONE.IN_PROGRESS.ACCEPT_EXTENSION_DEADLINE',
  MILESTONE_IN_PROGRESS_CANCEL_EXTENSION_DEADLINE = 'MILESTONE.IN_PROGRESS.CANCEL_EXTENSION_DEADLINE',
  MILESTONE_IN_PROGRESS_REJECT_EXTENSION_DEADLINE = 'MILESTONE.IN_PROGRESS.REJECT_EXTENSION_DEADLINE',
  MILESTONE_IN_PROGRESS_EXPIRE_EXTENSION_DEADLINE = 'MILESTONE.IN_PROGRESS.EXPIRE_EXTENSION_DEADLINE',

  MILESTONE_DELIVERED = 'MILESTONE.DELIVERED',
  MILESTONE_COMPLETED = 'MILESTONE.COMPLETED',
  MILESTONE_STOPPED = 'MILESTONE.STOPPED',
  REVISION_REQUESTED = 'REVISION.REQUESTED',
  REVISION_REQUEST_REJECTED = 'REVISION.REQUEST_REJECTED',
  REVISION_IN_PROGRESS = 'REVISION.IN_PROGRESS',
  REVISION_DELIVERED = 'REVISION.DELIVERED',
  PAYMENT_INTENT_SUCCEEDED = 'PAYMENT_INTENT.SUCCEEDED',
  EXTRA_OFFER_SEND = 'EXTRA_OFFER.SEND', // ContractEvent
  EXTRA_OFFER_PAID = 'EXTRA_OFFER.PAID', // ContractEvent
  EXTRA_OFFER_CANCELLED = 'EXTRA_OFFER.CANCELLED', // ContractEvent
  EXTRA_OFFER_REJECTED = 'EXTRA_OFFER.REJECTED', // ContractEvent
}

export enum NotificationType {
  /* BOTH BUYER AND SELLER */
  CREATE_NEW_ACCOUNT = 'CREATE_NEW_ACCOUNT',
  CONTRACT = 'CONTRACT',
  JOB_DETAIL = 'JOB_DETAIL',
  PROPOSAL_DETAIL = 'PROPOSAL_DETAIL',
  USER_DETAIL = 'USER_DETAIL',

  /* ONLY FOR SELLER */
  CONTRACT_FOR_SELLER = 'CONTRACT.FOR_SELLER',
  JOB_ACCEPT_SELLER = 'JOB.ACCEPT_SELLER',

  /* ONLY FOR BUYER */
  CONTRACT_FOR_BUYER = 'CONTRACT.FOR_BUYER',
  JOB_SELLER_DECLINED = 'JOB.SELLER_DECLINED',
}

export enum ReviewType {
  BUYER_REVIEW = 'BUYER_REVIEW',
  SELLER_REVIEW = 'SELLER_REVIEW',
}

export enum PaymentPurpose {
  TIP = 'TIP',
  EXTRA_OFFER = 'EXTRA_OFFER',
  CORE_CONTRACT = 'CORE_CONTRACT',
}

export enum ExtensionDeadlineInfoStatus {
  OPEN = 'OPEN', // Default value when seller send a new request extend deadline
  ACCEPTED = 'ACCEPTED', // Once the buyer accept the request
  REJECTED = 'REJECTED', // If the buyer reject the request
  EXPIRED = 'EXPIRED', // If the request is not accepted before the expiration date
  CANCELLED = 'CANCELLED', // If the seller cancelled this request
}

export type ExtensionDeadlineInfo = {
  id: string;
  deadline_extension_number: number; // export
  deadline_extension_type: string; // # DAY, WEEK, MONTH; default to DAY
  deadline_extension_reason?: string | null | undefined; // Reason why seller wants to extend deadline
  deadline_extension_status: string; // OPEN, ACCEPTED, REJECTED, EXPIRED, CANCELLED (tuong tu Proposal)
  deadline_extension_response_date_time: string; // The date time when buyer accepts or rejects the request
  deadline_extension_request_date_time: string; // The date time when the request is created
};

export enum ProposalType {
  SELLER_SEND_PROPOSAL = 'SELLER_SEND_PROPOSAL',
  BUYER_SEND_PROPOSAL = 'BUYER_SEND_PROPOSAL',
}

export enum EmailActionName {
  CREATE_EXTRA_OFFER = 'CREATE_EXTRA_OFFER',
  CREATE_CONTRACT = 'CREATE_CONTRACT',
  CREATE_PROPOSAL = 'CREATE_PROPOSAL',
  CREATE_EXCLUSIVE_JOB = 'CREATE_EXCLUSIVE_JOB',
  JOB_ACCEPT_SELLER = 'JOB_ACCEPT_SELLER',
  EXTENSION_DEADLINE = 'EXTENSION_DEADLINE',

  JOB_SELLER_DECLINED = 'JOB_SELLER_DECLINED',
}

export enum CharacterType {
  MASCOT = 'MASCOT',
  ORIGINAL_CHARACTER = 'ORIGINAL_CHARACTER', // Also known as OC
}

export enum CharacterItemType {
  ARTWORK = 'ARTWORK',
  JOB = 'JOB',
}
