/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateTagInput = {
  id?: string | null,
  tag_name?: string | null,
  display_name?: string | null,
  status?: string | null,
  total_artwork_quantity?: number | null,
};

export type ModelTagConditionInput = {
  tag_name?: ModelStringInput | null,
  display_name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  total_artwork_quantity?: ModelIntInput | null,
  and?: Array< ModelTagConditionInput | null > | null,
  or?: Array< ModelTagConditionInput | null > | null,
  not?: ModelTagConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Tag = {
  __typename: "Tag",
  id: string,
  tag_name?: string | null,
  display_name?: string | null,
  status?: string | null,
  total_artwork_quantity?: number | null,
  tag_artwork_items?: ModelTagItemConnection | null,
  created_at: string,
  updated_at: string,
};

export type ModelTagItemConnection = {
  __typename: "ModelTagItemConnection",
  items:  Array<TagItem | null >,
  nextToken?: string | null,
};

export type TagItem = {
  __typename: "TagItem",
  id: string,
  tag_id: string,
  tag?: Tag | null,
  artwork_id: string,
  artwork?: Artwork | null,
  tag_type?: string | null,
  created_at: string,
  updated_at: string,
};

export type Artwork = {
  __typename: "Artwork",
  id: string,
  cover_image_height?: number | null,
  cover_image_width?: number | null,
  cover_image_src_url?: string | null,
  cover_image_thumbnail_url?: string | null,
  title?: string | null,
  likes_count?: number | null,
  comments_count?: number | null,
  description?: string | null,
  publication_date_time?: string | null,
  user_id: string,
  user?: User | null,
  buyer_id?: string | null,
  buyer?: User | null,
  is_removed?: number | null,
  images?: ModelImageConnection | null,
  likes?: ModelLikeConnection | null,
  product_artworks?: ModelProductArtworkConnection | null,
  contract_id?: string | null,
  contract?: Contract | null,
  is_buyer_showcase?: number | null,
  is_seller_showcase?: number | null,
  tag_items?: ModelTagItemConnection | null,
  character_items?: ModelCharacterItemConnection | null,
  created_at: string,
  updated_at: string,
};

export type User = {
  __typename: "User",
  id: string,
  display_name?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  username?: string | null,
  email_address?: string | null,
  birth_date?: string | null,
  phone_number?: string | null,
  country_code?: string | null,
  subscriber_number?: string | null,
  bio?: string | null,
  is_artist?: number | null,
  seller_onboarding_stage?: string | null,
  buyer_jobs?: ModelJobConnection | null,
  seller_jobs?: ModelJobConnection | null,
  term_of_service?: string | null,
  cccd?: string | null,
  mst?: string | null,
  cccd_images?: Array< string | null > | null,
  mst_stage?: string | null,
  seller_proposals?: ModelProposalConnection | null,
  profile_picture_url?: string | null,
  cover_picture_url?: string | null,
  balance_amount?: string | null,
  balance_centi_amount?: string | null,
  balance_currency_code?: string | null,
  usd_balance_amount?: string | null,
  usd_balance_centi_amount?: string | null,
  contract_paid_quantity?: number | null,
  seller_star_quantity?: number | null,
  seller_review_quantity?: number | null,
  buyer_star_quantity?: number | null,
  buyer_review_quantity?: number | null,
  commision_started_quantity?: number | null,
  commission_completed_quantity?: number | null,
  commission_completed_on_time_quantity?: number | null,
  commission_cancelled_seller_fault_quantity?: number | null,
  commission_cancelled_buyer_fault_quantity?: number | null,
  commission_cancelled_mutually_quantity?: number | null,
  commission_cumulative_late_seconds?: number | null,
  commission_max_late_seconds?: number | null,
  device_tokens?: Array< string | null > | null,
  is_selling?: number | null,
  is_selling_abroad?: number | null,
  products?: ModelProductConnection | null,
  last_time_open_list_notification?: string | null,
  discount_code?: string | null,
  artworks?: ModelArtworkConnection | null,
  buyer_artworks?: ModelArtworkConnection | null,
  likes?: ModelLikeConnection | null,
  user_channels?: ModelUserChannelConnection | null,
  messages?: ModelMessageConnection | null,
  payment_intents?: ModelPaymentIntentConnection | null,
  payouts?: ModelPayoutConnection | null,
  selling_refunds?: ModelRefundConnection | null,
  buying_refunds?: ModelRefundConnection | null,
  seller_contracts?: ModelContractConnection | null,
  buyer_contracts?: ModelContractConnection | null,
  received_reviews?: ModelReviewConnection | null,
  given_reviews?: ModelReviewConnection | null,
  sent_balance_transfers?: ModelBalanceTransferConnection | null,
  received_balance_transfers?: ModelBalanceTransferConnection | null,
  balance_transactions?: ModelBalanceTransactionConnection | null,
  follower_quantity?: number | null,
  leader_quantity?: number | null,
  followers?: ModelUserFollowConnection | null,
  leaders?: ModelUserFollowConnection | null,
  notifications?: ModelNotificationConnection | null,
  notification_senders?: ModelNotificationConnection | null,
  push_tokens?: ModelPushTokenConnection | null,
  has_unread_commissions?: number | null,
  has_unread_jobs?: number | null,
  message_cumulative_response_seconds?: number | null,
  message_response_on_time_quantity?: number | null,
  message_response_late_quantity?: number | null,
  characters?: ModelCharacterConnection | null,
  bank_name?: string | null,
  account_holder_name?: string | null,
  account_last_four?: string | null,
  address_country_code?: string | null,
  city?: string | null,
  address_line1?: string | null,
  additional_info?: string | null,
  created_at: string,
  updated_at: string,
};

export type ModelJobConnection = {
  __typename: "ModelJobConnection",
  items:  Array<Job | null >,
  nextToken?: string | null,
};

export type Job = {
  __typename: "Job",
  id: string,
  title?: string | null,
  description?: string | null,
  min_budget?: string | null,
  min_centi_budget?: string | null,
  max_budget?: string | null,
  max_centi_budget?: string | null,
  currency_code?: string | null,
  status?: string | null,
  is_exclusive?: number | null,
  publication_date_time?: string | null,
  deadline_date_time?: string | null,
  expiration_date_time?: string | null,
  is_comercial_artwork?: number | null,
  is_dreambox?: number | null,
  is_private_artwork?: number | null,
  file_format?: Array< string | null > | null,
  buyer_id: string,
  buyer?: User | null,
  seller_id: string,
  seller?: User | null,
  proposals?: ModelProposalConnection | null,
  images?: ModelImageConnection | null,
  contracts?: ModelContractConnection | null,
  contacted_seller_ids?: Array< string | null > | null,
  character_items?: ModelCharacterItemConnection | null,
  created_at: string,
  updated_at: string,
};

export type ModelProposalConnection = {
  __typename: "ModelProposalConnection",
  items:  Array<Proposal | null >,
  nextToken?: string | null,
};

export type Proposal = {
  __typename: "Proposal",
  id: string,
  job_id: string,
  job?: Job | null,
  seller_id: string,
  seller?: User | null,
  seller_comment?: string | null,
  status?: string | null,
  publication_date_time?: string | null,
  contracts?: ModelContractConnection | null,
  proposal_type?: string | null,
  created_at: string,
  updated_at: string,
};

export type ModelContractConnection = {
  __typename: "ModelContractConnection",
  items:  Array<Contract | null >,
  nextToken?: string | null,
};

export type Contract = {
  __typename: "Contract",
  id: string,
  status?: string | null,
  publication_date_time?: string | null,
  is_private_artwork?: number | null,
  is_comercial_artwork?: number | null,
  file_formats?: Array< string | null > | null,
  description?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  term_of_service?: string | null,
  state_name?: string | null,
  current_milestone_id?: string | null,
  current_milestone_index?: number | null,
  job_id: string,
  job?: Job | null,
  proposal_id: string,
  proposal?: Proposal | null,
  buyer_id: string,
  buyer?: User | null,
  seller_id: string,
  seller?: User | null,
  contract_products?: ModelContractProductConnection | null,
  payment_intents?: ModelPaymentIntentConnection | null,
  extra_payment_payment_intents?: ModelPaymentIntentConnection | null,
  refunds?: ModelRefundConnection | null,
  balance_transfers?: ModelBalanceTransferConnection | null,
  milestones?: ModelMilestoneConnection | null,
  contract_events?: ModelContractEventConnection | null,
  reviews?: ModelReviewConnection | null,
  extra_offers?: ModelExtraOfferConnection | null,
  artworks?: ModelArtworkConnection | null,
  created_at: string,
  updated_at: string,
};

export type ModelContractProductConnection = {
  __typename: "ModelContractProductConnection",
  items:  Array<ContractProduct | null >,
  nextToken?: string | null,
};

export type ContractProduct = {
  __typename: "ContractProduct",
  id: string,
  contract_id: string,
  contract?: Contract | null,
  product_id: string,
  product?: Product | null,
  product_display_name?: string | null,
  product_description?: string | null,
  contract_product_prices?: ModelContractProductPriceConnection | null,
  extra_contract_product_prices?: ModelContractProductPriceConnection | null,
  created_at: string,
  updated_at: string,
};

export type Product = {
  __typename: "Product",
  id: string,
  artist_id: string,
  artist?: User | null,
  display_name: string,
  description?: string | null,
  status: string,
  prices?: ModelPriceConnection | null,
  slug?: string | null,
  category_id?: string | null,
  category?: Category | null,
  min_price_amount?: string | null,
  max_price_amount?: string | null,
  currency_code?: string | null,
  product_star_quantity?: number | null,
  product_review_quantity?: number | null,
  commission_paid_quantity?: number | null,
  artist_other_artworks?:  Array<Artwork | null > | null,
  reviews?:  Array<Review | null > | null,
  contract_products?: ModelContractProductConnection | null,
  product_artworks?: ModelProductArtworkConnection | null,
  dreambox_response_time?: number | null,
  dreambox_complete_time?: number | null,
  created_at: string,
  updated_at: string,
};

export type ModelPriceConnection = {
  __typename: "ModelPriceConnection",
  items:  Array<Price | null >,
  nextToken?: string | null,
};

export type Price = {
  __typename: "Price",
  id: string,
  product_id: string,
  Product?: Product | null,
  display_name?: string | null,
  description?: string | null,
  status: string,
  type?: string | null,
  category?: string | null,
  fixed_amount?: string | null,
  fixed_centi_amount?: string | null,
  index?: number | null,
  min_amount?: string | null,
  min_centi_amount?: string | null,
  max_amount?: string | null,
  max_centi_amount?: string | null,
  currency_code?: string | null,
  percentage?: number | null,
  contract_product_prices?: ModelContractProductPriceConnection | null,
  extra_offer_prices?: ModelExtraOfferPriceConnection | null,
  created_at: string,
  updated_at: string,
};

export type ModelContractProductPriceConnection = {
  __typename: "ModelContractProductPriceConnection",
  items:  Array<ContractProductPrice | null >,
  nextToken?: string | null,
};

export type ContractProductPrice = {
  __typename: "ContractProductPrice",
  id: string,
  contract_product_id: string,
  contract_product?: ContractProduct | null,
  extra_contract_product_id: string,
  extra_contract_product?: ContractProduct | null,
  price_id: string,
  price?: Price | null,
  price_quantity?: number | null,
  price_display_name?: string | null,
  price_description?: string | null,
  price_type?: string | null,
  price_category?: string | null,
  price_fixed_amount?: string | null,
  price_fixed_centi_amount?: string | null,
  price_currency_code?: string | null,
  price_percentage?: number | null,
  created_at: string,
  updated_at: string,
};

export type ModelExtraOfferPriceConnection = {
  __typename: "ModelExtraOfferPriceConnection",
  items:  Array<ExtraOfferPrice | null >,
  nextToken?: string | null,
};

export type ExtraOfferPrice = {
  __typename: "ExtraOfferPrice",
  id: string,
  extra_offer_id: string,
  extra_offer?: ExtraOffer | null,
  price_id: string,
  price?: Price | null,
  price_quantity: number,
  price_display_name?: string | null,
  price_description?: string | null,
  price_type?: string | null,
  price_category?: string | null,
  price_fixed_amount?: string | null,
  price_fixed_centi_amount?: string | null,
  price_currency_code?: string | null,
  price_percentage?: number | null,
  created_at: string,
  updated_at: string,
};

export type ExtraOffer = {
  __typename: "ExtraOffer",
  id: string,
  contract_id: string,
  contract?: Contract | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  status?: string | null,
  payment_intents?: ModelPaymentIntentConnection | null,
  extra_offer_prices?: ModelExtraOfferPriceConnection | null,
  created_at: string,
  updated_at: string,
};

export type ModelPaymentIntentConnection = {
  __typename: "ModelPaymentIntentConnection",
  items:  Array<PaymentIntent | null >,
  nextToken?: string | null,
};

export type PaymentIntent = {
  __typename: "PaymentIntent",
  id: string,
  buyer_id?: string | null,
  buyer?: User | null,
  contract_id: string,
  contract?: Contract | null,
  extra_payment_contract_id: string,
  extra_payment_contract?: Contract | null,
  extra_offer_id: string,
  extra_offer?: ExtraOffer | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency?: string | null,
  payout_owing_to_seller_amount?: string | null,
  payout_owing_to_seller_centi_amount?: string | null,
  fee_owing_to_momo_amount?: string | null,
  fee_owing_to_momo_centi_amount?: string | null,
  dreamerly_fee_balance_amount?: string | null,
  dreamerly_fee_balance_centi_amount?: string | null,
  display_fee_owing_to_momo_amount?: string | null,
  display_fee_owing_to_momo_centi_amount?: string | null,
  display_dreamerly_fee_balance_amount?: string | null,
  display_dreamerly_fee_balance_centi_amount?: string | null,
  payment_purpose?: string | null,
  billing_details?: string | null,
  description?: string | null,
  last_payment_error?: string | null,
  next_action?: string | null,
  disputed?: number | null,
  disputed_at?: string | null,
  refunded?: number | null,
  refunded_amount?: string | null,
  refunded_centi_amount?: string | null,
  refunded_at?: string | null,
  status?: string | null,
  payment_provider?: string | null,
  payment_method?: string | null,
  paid_at?: string | null,
  cancelled_at?: string | null,
  momo_trans_id?: string | null,
  momo_request_id?: string | null,
  momo_payment_url?: string | null,
  momo_payment_url_expires_at?: string | null,
  stripe_payment_intent_id?: string | null,
  stripe_payment_method_country?: string | null,
  refunds?: ModelRefundConnection | null,
  payment_intent_events?: ModelPaymentIntentEventConnection | null,
  balance_transaction_id?: string | null,
  created_at: string,
  updated_at: string,
};

export type ModelRefundConnection = {
  __typename: "ModelRefundConnection",
  items:  Array<Refund | null >,
  nextToken?: string | null,
};

export type Refund = {
  __typename: "Refund",
  id: string,
  amount?: string | null,
  centi_amount?: string | null,
  currency?: string | null,
  payout_owing_to_seller_amount?: string | null,
  payout_owing_to_seller_centi_amount?: string | null,
  fee_owing_to_momo_amount?: string | null,
  fee_owing_to_momo_centi_amount?: string | null,
  dreamerly_fee_balance_amount?: string | null,
  dreamerly_fee_balance_centi_amount?: string | null,
  billing_details?: string | null,
  buyer_id?: string | null,
  buyer?: User | null,
  seller_id?: string | null,
  seller?: User | null,
  description?: string | null,
  payment_intent_id: string,
  payment_intent?: PaymentIntent | null,
  contract_id: string,
  contract?: Contract | null,
  reason?: string | null,
  status?: string | null,
  response?: string | null,
  payment_provider?: string | null,
  momo_trans_id?: string | null,
  approved_at?: string | null,
  rejected_at?: string | null,
  refunded_at?: string | null,
  balance_transaction_id?: string | null,
  created_at: string,
  updated_at: string,
};

export type ModelPaymentIntentEventConnection = {
  __typename: "ModelPaymentIntentEventConnection",
  items:  Array<PaymentIntentEvent | null >,
  nextToken?: string | null,
};

export type PaymentIntentEvent = {
  __typename: "PaymentIntentEvent",
  id: string,
  payment_intent_id: string,
  payment_intent?: PaymentIntent | null,
  event_id: string,
  event?: Event | null,
  payment_intent_status?: string | null,
  payment_intent_amount?: string | null,
  payment_intent_currency?: string | null,
  created_at: string,
  updated_at: string,
};

export type Event = {
  __typename: "Event",
  id: string,
  type?: string | null,
  note?: string | null,
  contract_events?: ModelContractEventConnection | null,
  payment_intent_events?: ModelPaymentIntentEventConnection | null,
  milestone_events?: ModelMilestoneEventConnection | null,
  created_at: string,
  updated_at: string,
};

export type ModelContractEventConnection = {
  __typename: "ModelContractEventConnection",
  items:  Array<ContractEvent | null >,
  nextToken?: string | null,
};

export type ContractEvent = {
  __typename: "ContractEvent",
  id: string,
  contract_id: string,
  contract?: Contract | null,
  event_id: string,
  event?: Event | null,
  contract_status?: string | null,
  contract_amount?: string | null,
  contract_currency?: string | null,
  extra_offer_status?: string | null,
  extra_offer_amount?: string | null,
  extra_offer_currency?: string | null,
  created_at: string,
  updated_at: string,
};

export type ModelMilestoneEventConnection = {
  __typename: "ModelMilestoneEventConnection",
  items:  Array<MilestoneEvent | null >,
  nextToken?: string | null,
};

export type MilestoneEvent = {
  __typename: "MilestoneEvent",
  id: string,
  milestone_id: string,
  milestone?: Milestone | null,
  event_id: string,
  event?: Event | null,
  milestone_status?: string | null,
  milestone_step_index?: number | null,
  milestone_step_name?: string | null,
  created_at: string,
  updated_at: string,
};

export type Milestone = {
  __typename: "Milestone",
  id: string,
  contract_id: string,
  contract?: Contract | null,
  step_index?: number | null,
  step_name?: string | null,
  deadline_number?: number | null,
  deadline_type?: string | null,
  start_date_time?: string | null,
  end_date_time?: string | null,
  delivery_date_time?: string | null,
  free_revision_count?: number | null,
  current_revision_index?: number | null,
  current_revision_id?: string | null,
  description?: string | null,
  deadline_extensions?: string | null,
  price_percentage?: number | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  status?: string | null,
  milestone_events?: ModelMilestoneEventConnection | null,
  revisions?: ModelRevisionConnection | null,
  images?: ModelImageConnection | null,
  balance_transfers?: ModelBalanceTransferConnection | null,
  created_at: string,
  updated_at: string,
};

export type ModelRevisionConnection = {
  __typename: "ModelRevisionConnection",
  items:  Array<Revision | null >,
  nextToken?: string | null,
};

export type Revision = {
  __typename: "Revision",
  id: string,
  index?: number | null,
  status?: string | null,
  request_message?: string | null,
  request_rejection_message?: string | null,
  milestone_id: string,
  milestone?: Milestone | null,
  additional_deadline_number?: number | null,
  additional_deadline_type?: string | null,
  start_date_time?: string | null,
  end_date_time?: string | null,
  images?: ModelImageConnection | null,
  request_revision_images?: ModelImageConnection | null,
  delivery_date_time?: string | null,
  deadline_extensions?: string | null,
  created_at: string,
  updated_at: string,
};

export type ModelImageConnection = {
  __typename: "ModelImageConnection",
  items:  Array<Image | null >,
  nextToken?: string | null,
};

export type Image = {
  __typename: "Image",
  id: string,
  height?: number | null,
  width?: number | null,
  file_name?: string | null,
  src_url?: string | null,
  thumbnail_url?: string | null,
  raw_src_url?: string | null,
  raw_thumbnail_url?: string | null,
  artwork_id?: string | null,
  artwork?: Artwork | null,
  job_id?: string | null,
  job?: Job | null,
  milestone_id?: string | null,
  milestone?: Milestone | null,
  revision_id?: string | null,
  revision?: Revision | null,
  revision_request_revision_id?: string | null,
  revision_request_revision?: Revision | null,
  message_id?: string | null,
  message?: Message | null,
  created_at: string,
  updated_at: string,
};

export type Message = {
  __typename: "Message",
  id: string,
  channel_id: string,
  channel?: Channel | null,
  sender_id: string,
  sender?: User | null,
  content?: string | null,
  image_url?: string | null,
  publication_date_time?: string | null,
  available_to?: string | null,
  images?: ModelImageConnection | null,
  created_at: string,
  updated_at: string,
};

export type Channel = {
  __typename: "Channel",
  id: string,
  name?: string | null,
  user_channels?: ModelUserChannelConnection | null,
  messages?: ModelMessageConnection | null,
  created_at: string,
  updated_at: string,
};

export type ModelUserChannelConnection = {
  __typename: "ModelUserChannelConnection",
  items:  Array<UserChannel | null >,
  nextToken?: string | null,
};

export type UserChannel = {
  __typename: "UserChannel",
  id: string,
  channel_id: string,
  channel?: Channel | null,
  user_id: string,
  user?: User | null,
  update_date_time?: string | null,
  status?: string | null,
  next_time_should_receive_an_email?: string | null,
  next_time_should_receive_a_late_response?: string | null,
  request_start_date_time?: string | null,
  created_at: string,
  updated_at: string,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type ModelBalanceTransferConnection = {
  __typename: "ModelBalanceTransferConnection",
  items:  Array<BalanceTransfer | null >,
  nextToken?: string | null,
};

export type BalanceTransfer = {
  __typename: "BalanceTransfer",
  id: string,
  reason?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  dreamerly_fee?: string | null,
  dreamerly_centi_fee?: string | null,
  payment_provider_fee?: string | null,
  payment_provider_centi_fee?: string | null,
  net_amount?: string | null,
  net_centi_amount?: string | null,
  payment_provider?: string | null,
  currency_code?: string | null,
  note?: string | null,
  publication_date_time?: string | null,
  milestone_id?: string | null,
  milestone?: Milestone | null,
  contract_id?: string | null,
  contract?: Contract | null,
  from_user_id: string,
  from_user?: User | null,
  to_user_id: string,
  to_user?: User | null,
  from_dreamerly_balance_id: string,
  from_dreamerly_balance?: DreamerlyBalance | null,
  to_dreamerly_balance_id: string,
  to_dreamerly_balance?: DreamerlyBalance | null,
  balance_transaction_id: string,
  created_at: string,
  updated_at: string,
};

export type DreamerlyBalance = {
  __typename: "DreamerlyBalance",
  id: string,
  currency_code?: string | null,
  sent_balance_transfers?: ModelBalanceTransferConnection | null,
  received_balance_transfers?: ModelBalanceTransferConnection | null,
  created_at: string,
  updated_at: string,
};

export type Category = {
  __typename: "Category",
  id: string,
  display_name?: string | null,
  status?: string | null,
  products?: ModelProductConnection | null,
  created_at: string,
  updated_at: string,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type Review = {
  __typename: "Review",
  id: string,
  user_id: string,
  user?: User | null,
  reviewer_id: string,
  reviewer?: User | null,
  contract_id: string,
  contract?: Contract | null,
  rating?: number | null,
  comment?: string | null,
  type?: string | null,
  publication_date_time?: string | null,
  created_at: string,
  updated_at: string,
};

export type ModelProductArtworkConnection = {
  __typename: "ModelProductArtworkConnection",
  items:  Array<ProductArtwork | null >,
  nextToken?: string | null,
};

export type ProductArtwork = {
  __typename: "ProductArtwork",
  id: string,
  product_id: string,
  product?: Product | null,
  artwork_id: string,
  artwork?: Artwork | null,
  is_removed?: number | null,
  index?: number | null,
  created_at: string,
  updated_at: string,
};

export type ModelMilestoneConnection = {
  __typename: "ModelMilestoneConnection",
  items:  Array<Milestone | null >,
  nextToken?: string | null,
};

export type ModelReviewConnection = {
  __typename: "ModelReviewConnection",
  items:  Array<Review | null >,
  nextToken?: string | null,
};

export type ModelExtraOfferConnection = {
  __typename: "ModelExtraOfferConnection",
  items:  Array<ExtraOffer | null >,
  nextToken?: string | null,
};

export type ModelArtworkConnection = {
  __typename: "ModelArtworkConnection",
  items:  Array<Artwork | null >,
  nextToken?: string | null,
};

export type ModelCharacterItemConnection = {
  __typename: "ModelCharacterItemConnection",
  items:  Array<CharacterItem | null >,
  nextToken?: string | null,
};

export type CharacterItem = {
  __typename: "CharacterItem",
  id: string,
  item_type?: string | null,
  character_id: string,
  character?: Character | null,
  artwork_id?: string | null,
  artwork?: Artwork | null,
  job_id?: string | null,
  job?: Job | null,
  created_at: string,
  updated_at: string,
};

export type Character = {
  __typename: "Character",
  id: string,
  display_name?: string | null,
  charname?: string | null,
  character_type?: string | null,
  user_id?: string | null,
  user?: User | null,
  description?: string | null,
  strengths?: string | null,
  flaws?: string | null,
  gender?: string | null,
  race?: string | null,
  birth_date?: string | null,
  age?: string | null,
  role?: string | null,
  hobbies?: string | null,
  dislikes?: string | null,
  profile_picture_url?: string | null,
  cover_photo_url?: string | null,
  character_items?: ModelCharacterItemConnection | null,
  created_at: string,
  updated_at: string,
};

export type ModelLikeConnection = {
  __typename: "ModelLikeConnection",
  items:  Array<Like | null >,
  nextToken?: string | null,
};

export type Like = {
  __typename: "Like",
  id: string,
  artwork_id: string,
  artwork?: Artwork | null,
  user_id: string,
  user?: User | null,
  is_removed?: number | null,
  created_at: string,
  updated_at: string,
};

export type ModelPayoutConnection = {
  __typename: "ModelPayoutConnection",
  items:  Array<Payout | null >,
  nextToken?: string | null,
};

export type Payout = {
  __typename: "Payout",
  id: string,
  amount?: string | null,
  centi_amount?: string | null,
  currency?: string | null,
  billing_details?: string | null,
  seller_id?: string | null,
  seller?: User | null,
  description?: string | null,
  arrival_date_time?: string | null,
  status?: string | null,
  payment_provider?: string | null,
  balance_transaction_id?: string | null,
  request_amount?: string | null,
  request_centi_amount?: string | null,
  request_date_time?: string | null,
  bank_account_id?: string | null,
  created_at: string,
  updated_at: string,
};

export type ModelBalanceTransactionConnection = {
  __typename: "ModelBalanceTransactionConnection",
  items:  Array<BalanceTransaction | null >,
  nextToken?: string | null,
};

export type BalanceTransaction = {
  __typename: "BalanceTransaction",
  id: string,
  payment_intents?: ModelPaymentIntentConnection | null,
  payouts?: ModelPayoutConnection | null,
  refunds?: ModelRefundConnection | null,
  balance_transfers?: ModelBalanceTransferConnection | null,
  payment_provider_fee_payments?: ModelPaymentProviderFeePaymentConnection | null,
  type?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  note?: string | null,
  publication_date_time?: string | null,
  user_id: string,
  user?: User | null,
  created_at: string,
  updated_at: string,
};

export type ModelPaymentProviderFeePaymentConnection = {
  __typename: "ModelPaymentProviderFeePaymentConnection",
  items:  Array<PaymentProviderFeePayment | null >,
  nextToken?: string | null,
};

export type PaymentProviderFeePayment = {
  __typename: "PaymentProviderFeePayment",
  id: string,
  amount: string,
  centi_amount: string,
  currency_code: string,
  publication_date_time?: string | null,
  payment_provider?: string | null,
  description?: string | null,
  invoice_number?: string | null,
  from_dreamerly_balance_id: string,
  to_dreamerly_balance_id: string,
  balance_transaction_id: string,
  created_at: string,
  updated_at: string,
};

export type ModelUserFollowConnection = {
  __typename: "ModelUserFollowConnection",
  items:  Array<UserFollow | null >,
  nextToken?: string | null,
};

export type UserFollow = {
  __typename: "UserFollow",
  id: string,
  leader_id: string,
  follower_id: string,
  leader?: User | null,
  follower?: User | null,
  created_at: string,
  updated_at: string,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  user_id: string,
  user?: User | null,
  notification_sender_id?: string | null,
  notification_sender?: User | null,
  title?: string | null,
  body?: string | null,
  publication_date_time?: string | null,
  is_read?: number | null,
  is_removed?: number | null,
  notification_type?: string | null,
  underlying_object_id?: string | null,
  created_at: string,
  updated_at: string,
};

export type ModelPushTokenConnection = {
  __typename: "ModelPushTokenConnection",
  items:  Array<PushToken | null >,
  nextToken?: string | null,
};

export type PushToken = {
  __typename: "PushToken",
  id: string,
  user_id: string,
  user?: User | null,
  push_token?: string | null,
  device_type?: string | null,
  last_used_at?: string | null,
  status?: string | null,
  created_at: string,
  updated_at: string,
};

export type ModelCharacterConnection = {
  __typename: "ModelCharacterConnection",
  items:  Array<Character | null >,
  nextToken?: string | null,
};

export type UpdateTagInput = {
  id: string,
  tag_name?: string | null,
  display_name?: string | null,
  status?: string | null,
  total_artwork_quantity?: number | null,
};

export type DeleteTagInput = {
  id: string,
};

export type CreateTagItemInput = {
  id?: string | null,
  tag_id: string,
  artwork_id: string,
  tag_type?: string | null,
};

export type ModelTagItemConditionInput = {
  tag_id?: ModelIDInput | null,
  artwork_id?: ModelIDInput | null,
  tag_type?: ModelStringInput | null,
  and?: Array< ModelTagItemConditionInput | null > | null,
  or?: Array< ModelTagItemConditionInput | null > | null,
  not?: ModelTagItemConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateTagItemInput = {
  id: string,
  tag_id?: string | null,
  artwork_id?: string | null,
  tag_type?: string | null,
};

export type DeleteTagItemInput = {
  id: string,
};

export type CreateArtworkInput = {
  id?: string | null,
  cover_image_height?: number | null,
  cover_image_width?: number | null,
  cover_image_src_url?: string | null,
  cover_image_thumbnail_url?: string | null,
  title?: string | null,
  likes_count?: number | null,
  comments_count?: number | null,
  description?: string | null,
  publication_date_time?: string | null,
  user_id: string,
  buyer_id?: string | null,
  is_removed?: number | null,
  contract_id?: string | null,
  is_buyer_showcase?: number | null,
  is_seller_showcase?: number | null,
};

export type ModelArtworkConditionInput = {
  cover_image_height?: ModelIntInput | null,
  cover_image_width?: ModelIntInput | null,
  cover_image_src_url?: ModelStringInput | null,
  cover_image_thumbnail_url?: ModelStringInput | null,
  title?: ModelStringInput | null,
  likes_count?: ModelIntInput | null,
  comments_count?: ModelIntInput | null,
  description?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  user_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  is_removed?: ModelIntInput | null,
  contract_id?: ModelIDInput | null,
  is_buyer_showcase?: ModelIntInput | null,
  is_seller_showcase?: ModelIntInput | null,
  and?: Array< ModelArtworkConditionInput | null > | null,
  or?: Array< ModelArtworkConditionInput | null > | null,
  not?: ModelArtworkConditionInput | null,
};

export type UpdateArtworkInput = {
  id: string,
  cover_image_height?: number | null,
  cover_image_width?: number | null,
  cover_image_src_url?: string | null,
  cover_image_thumbnail_url?: string | null,
  title?: string | null,
  likes_count?: number | null,
  comments_count?: number | null,
  description?: string | null,
  publication_date_time?: string | null,
  user_id?: string | null,
  buyer_id?: string | null,
  is_removed?: number | null,
  contract_id?: string | null,
  is_buyer_showcase?: number | null,
  is_seller_showcase?: number | null,
};

export type DeleteArtworkInput = {
  id: string,
};

export type CreateProductArtworkInput = {
  id?: string | null,
  product_id: string,
  artwork_id: string,
  is_removed?: number | null,
  index?: number | null,
};

export type ModelProductArtworkConditionInput = {
  product_id?: ModelIDInput | null,
  artwork_id?: ModelIDInput | null,
  is_removed?: ModelIntInput | null,
  index?: ModelIntInput | null,
  and?: Array< ModelProductArtworkConditionInput | null > | null,
  or?: Array< ModelProductArtworkConditionInput | null > | null,
  not?: ModelProductArtworkConditionInput | null,
};

export type UpdateProductArtworkInput = {
  id: string,
  product_id?: string | null,
  artwork_id?: string | null,
  is_removed?: number | null,
  index?: number | null,
};

export type DeleteProductArtworkInput = {
  id: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  display_name?: string | null,
  status?: string | null,
};

export type ModelCategoryConditionInput = {
  display_name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryInput = {
  id: string,
  display_name?: string | null,
  status?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type CreateImageInput = {
  id?: string | null,
  height?: number | null,
  width?: number | null,
  file_name?: string | null,
  src_url?: string | null,
  thumbnail_url?: string | null,
  raw_src_url?: string | null,
  raw_thumbnail_url?: string | null,
  artwork_id?: string | null,
  job_id?: string | null,
  milestone_id?: string | null,
  revision_id?: string | null,
  revision_request_revision_id?: string | null,
  message_id?: string | null,
};

export type ModelImageConditionInput = {
  height?: ModelIntInput | null,
  width?: ModelIntInput | null,
  file_name?: ModelStringInput | null,
  src_url?: ModelStringInput | null,
  thumbnail_url?: ModelStringInput | null,
  raw_src_url?: ModelStringInput | null,
  raw_thumbnail_url?: ModelStringInput | null,
  artwork_id?: ModelIDInput | null,
  job_id?: ModelIDInput | null,
  milestone_id?: ModelIDInput | null,
  revision_id?: ModelIDInput | null,
  revision_request_revision_id?: ModelIDInput | null,
  message_id?: ModelIDInput | null,
  and?: Array< ModelImageConditionInput | null > | null,
  or?: Array< ModelImageConditionInput | null > | null,
  not?: ModelImageConditionInput | null,
};

export type UpdateImageInput = {
  id: string,
  height?: number | null,
  width?: number | null,
  file_name?: string | null,
  src_url?: string | null,
  thumbnail_url?: string | null,
  raw_src_url?: string | null,
  raw_thumbnail_url?: string | null,
  artwork_id?: string | null,
  job_id?: string | null,
  milestone_id?: string | null,
  revision_id?: string | null,
  revision_request_revision_id?: string | null,
  message_id?: string | null,
};

export type DeleteImageInput = {
  id: string,
};

export type CreateLikeInput = {
  id?: string | null,
  artwork_id: string,
  user_id: string,
  is_removed?: number | null,
};

export type ModelLikeConditionInput = {
  artwork_id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  is_removed?: ModelIntInput | null,
  and?: Array< ModelLikeConditionInput | null > | null,
  or?: Array< ModelLikeConditionInput | null > | null,
  not?: ModelLikeConditionInput | null,
};

export type UpdateLikeInput = {
  id: string,
  artwork_id?: string | null,
  user_id?: string | null,
  is_removed?: number | null,
};

export type DeleteLikeInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  display_name?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  username?: string | null,
  email_address?: string | null,
  birth_date?: string | null,
  phone_number?: string | null,
  country_code?: string | null,
  subscriber_number?: string | null,
  bio?: string | null,
  is_artist?: number | null,
  seller_onboarding_stage?: string | null,
  term_of_service?: string | null,
  cccd?: string | null,
  mst?: string | null,
  cccd_images?: Array< string | null > | null,
  mst_stage?: string | null,
  profile_picture_url?: string | null,
  cover_picture_url?: string | null,
  balance_amount?: string | null,
  balance_centi_amount?: string | null,
  balance_currency_code?: string | null,
  usd_balance_amount?: string | null,
  usd_balance_centi_amount?: string | null,
  contract_paid_quantity?: number | null,
  seller_star_quantity?: number | null,
  seller_review_quantity?: number | null,
  buyer_star_quantity?: number | null,
  buyer_review_quantity?: number | null,
  commision_started_quantity?: number | null,
  commission_completed_quantity?: number | null,
  commission_completed_on_time_quantity?: number | null,
  commission_cancelled_seller_fault_quantity?: number | null,
  commission_cancelled_buyer_fault_quantity?: number | null,
  commission_cancelled_mutually_quantity?: number | null,
  commission_cumulative_late_seconds?: number | null,
  commission_max_late_seconds?: number | null,
  device_tokens?: Array< string | null > | null,
  is_selling?: number | null,
  is_selling_abroad?: number | null,
  last_time_open_list_notification?: string | null,
  discount_code?: string | null,
  follower_quantity?: number | null,
  leader_quantity?: number | null,
  has_unread_commissions?: number | null,
  has_unread_jobs?: number | null,
  message_cumulative_response_seconds?: number | null,
  message_response_on_time_quantity?: number | null,
  message_response_late_quantity?: number | null,
  bank_name?: string | null,
  account_holder_name?: string | null,
  account_last_four?: string | null,
  address_country_code?: string | null,
  city?: string | null,
  address_line1?: string | null,
  additional_info?: string | null,
};

export type ModelUserConditionInput = {
  display_name?: ModelStringInput | null,
  first_name?: ModelStringInput | null,
  last_name?: ModelStringInput | null,
  username?: ModelStringInput | null,
  email_address?: ModelStringInput | null,
  birth_date?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  country_code?: ModelStringInput | null,
  subscriber_number?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  is_artist?: ModelIntInput | null,
  seller_onboarding_stage?: ModelStringInput | null,
  term_of_service?: ModelStringInput | null,
  cccd?: ModelStringInput | null,
  mst?: ModelStringInput | null,
  cccd_images?: ModelStringInput | null,
  mst_stage?: ModelStringInput | null,
  profile_picture_url?: ModelStringInput | null,
  cover_picture_url?: ModelStringInput | null,
  balance_amount?: ModelStringInput | null,
  balance_centi_amount?: ModelStringInput | null,
  balance_currency_code?: ModelStringInput | null,
  usd_balance_amount?: ModelStringInput | null,
  usd_balance_centi_amount?: ModelStringInput | null,
  contract_paid_quantity?: ModelIntInput | null,
  seller_star_quantity?: ModelIntInput | null,
  seller_review_quantity?: ModelIntInput | null,
  buyer_star_quantity?: ModelIntInput | null,
  buyer_review_quantity?: ModelIntInput | null,
  commision_started_quantity?: ModelIntInput | null,
  commission_completed_quantity?: ModelIntInput | null,
  commission_completed_on_time_quantity?: ModelIntInput | null,
  commission_cancelled_seller_fault_quantity?: ModelIntInput | null,
  commission_cancelled_buyer_fault_quantity?: ModelIntInput | null,
  commission_cancelled_mutually_quantity?: ModelIntInput | null,
  commission_cumulative_late_seconds?: ModelIntInput | null,
  commission_max_late_seconds?: ModelIntInput | null,
  device_tokens?: ModelStringInput | null,
  is_selling?: ModelIntInput | null,
  is_selling_abroad?: ModelIntInput | null,
  last_time_open_list_notification?: ModelStringInput | null,
  discount_code?: ModelStringInput | null,
  follower_quantity?: ModelIntInput | null,
  leader_quantity?: ModelIntInput | null,
  has_unread_commissions?: ModelIntInput | null,
  has_unread_jobs?: ModelIntInput | null,
  message_cumulative_response_seconds?: ModelIntInput | null,
  message_response_on_time_quantity?: ModelIntInput | null,
  message_response_late_quantity?: ModelIntInput | null,
  bank_name?: ModelStringInput | null,
  account_holder_name?: ModelStringInput | null,
  account_last_four?: ModelStringInput | null,
  address_country_code?: ModelStringInput | null,
  city?: ModelStringInput | null,
  address_line1?: ModelStringInput | null,
  additional_info?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type UpdateUserInput = {
  id: string,
  display_name?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  username?: string | null,
  email_address?: string | null,
  birth_date?: string | null,
  phone_number?: string | null,
  country_code?: string | null,
  subscriber_number?: string | null,
  bio?: string | null,
  is_artist?: number | null,
  seller_onboarding_stage?: string | null,
  term_of_service?: string | null,
  cccd?: string | null,
  mst?: string | null,
  cccd_images?: Array< string | null > | null,
  mst_stage?: string | null,
  profile_picture_url?: string | null,
  cover_picture_url?: string | null,
  balance_amount?: string | null,
  balance_centi_amount?: string | null,
  balance_currency_code?: string | null,
  usd_balance_amount?: string | null,
  usd_balance_centi_amount?: string | null,
  contract_paid_quantity?: number | null,
  seller_star_quantity?: number | null,
  seller_review_quantity?: number | null,
  buyer_star_quantity?: number | null,
  buyer_review_quantity?: number | null,
  commision_started_quantity?: number | null,
  commission_completed_quantity?: number | null,
  commission_completed_on_time_quantity?: number | null,
  commission_cancelled_seller_fault_quantity?: number | null,
  commission_cancelled_buyer_fault_quantity?: number | null,
  commission_cancelled_mutually_quantity?: number | null,
  commission_cumulative_late_seconds?: number | null,
  commission_max_late_seconds?: number | null,
  device_tokens?: Array< string | null > | null,
  is_selling?: number | null,
  is_selling_abroad?: number | null,
  last_time_open_list_notification?: string | null,
  discount_code?: string | null,
  follower_quantity?: number | null,
  leader_quantity?: number | null,
  has_unread_commissions?: number | null,
  has_unread_jobs?: number | null,
  message_cumulative_response_seconds?: number | null,
  message_response_on_time_quantity?: number | null,
  message_response_late_quantity?: number | null,
  bank_name?: string | null,
  account_holder_name?: string | null,
  account_last_four?: string | null,
  address_country_code?: string | null,
  city?: string | null,
  address_line1?: string | null,
  additional_info?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateBankAccountInput = {
  id?: string | null,
  user_id: string,
  bank_name?: string | null,
  account_holder_name?: string | null,
  account_number?: string | null,
  currency_code?: string | null,
  country_code?: string | null,
  status?: string | null,
};

export type ModelBankAccountConditionInput = {
  user_id?: ModelIDInput | null,
  bank_name?: ModelStringInput | null,
  account_holder_name?: ModelStringInput | null,
  account_number?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  country_code?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelBankAccountConditionInput | null > | null,
  or?: Array< ModelBankAccountConditionInput | null > | null,
  not?: ModelBankAccountConditionInput | null,
};

export type BankAccount = {
  __typename: "BankAccount",
  id: string,
  user_id: string,
  bank_name?: string | null,
  account_holder_name?: string | null,
  account_number?: string | null,
  currency_code?: string | null,
  country_code?: string | null,
  status?: string | null,
  created_at: string,
  updated_at: string,
};

export type UpdateBankAccountInput = {
  id: string,
  user_id?: string | null,
  bank_name?: string | null,
  account_holder_name?: string | null,
  account_number?: string | null,
  currency_code?: string | null,
  country_code?: string | null,
  status?: string | null,
};

export type DeleteBankAccountInput = {
  id: string,
};

export type CreatePushTokenInput = {
  id?: string | null,
  user_id: string,
  push_token?: string | null,
  device_type?: string | null,
  last_used_at?: string | null,
  status?: string | null,
};

export type ModelPushTokenConditionInput = {
  user_id?: ModelIDInput | null,
  push_token?: ModelStringInput | null,
  device_type?: ModelStringInput | null,
  last_used_at?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelPushTokenConditionInput | null > | null,
  or?: Array< ModelPushTokenConditionInput | null > | null,
  not?: ModelPushTokenConditionInput | null,
};

export type UpdatePushTokenInput = {
  id: string,
  user_id?: string | null,
  push_token?: string | null,
  device_type?: string | null,
  last_used_at?: string | null,
  status?: string | null,
};

export type DeletePushTokenInput = {
  id: string,
};

export type CreateUserBlockInput = {
  id?: string | null,
  user_give_block_id?: string | null,
  user_receive_block_id?: string | null,
  type?: string | null,
  status?: string | null,
};

export type ModelUserBlockConditionInput = {
  user_give_block_id?: ModelIDInput | null,
  user_receive_block_id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelUserBlockConditionInput | null > | null,
  or?: Array< ModelUserBlockConditionInput | null > | null,
  not?: ModelUserBlockConditionInput | null,
};

export type UserBlock = {
  __typename: "UserBlock",
  id: string,
  user_give_block_id?: string | null,
  user_receive_block_id?: string | null,
  type?: string | null,
  status?: string | null,
  created_at: string,
  updated_at: string,
};

export type UpdateUserBlockInput = {
  id: string,
  user_give_block_id?: string | null,
  user_receive_block_id?: string | null,
  type?: string | null,
  status?: string | null,
};

export type DeleteUserBlockInput = {
  id: string,
};

export type CreateProductInput = {
  id?: string | null,
  artist_id: string,
  display_name: string,
  description?: string | null,
  status: string,
  slug?: string | null,
  category_id?: string | null,
  min_price_amount?: string | null,
  max_price_amount?: string | null,
  currency_code?: string | null,
  product_star_quantity?: number | null,
  product_review_quantity?: number | null,
  commission_paid_quantity?: number | null,
  dreambox_response_time?: number | null,
  dreambox_complete_time?: number | null,
};

export type ModelProductConditionInput = {
  artist_id?: ModelIDInput | null,
  display_name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  category_id?: ModelIDInput | null,
  min_price_amount?: ModelStringInput | null,
  max_price_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  product_star_quantity?: ModelIntInput | null,
  product_review_quantity?: ModelIntInput | null,
  commission_paid_quantity?: ModelIntInput | null,
  dreambox_response_time?: ModelIntInput | null,
  dreambox_complete_time?: ModelIntInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
};

export type UpdateProductInput = {
  id: string,
  artist_id?: string | null,
  display_name?: string | null,
  description?: string | null,
  status?: string | null,
  slug?: string | null,
  category_id?: string | null,
  min_price_amount?: string | null,
  max_price_amount?: string | null,
  currency_code?: string | null,
  product_star_quantity?: number | null,
  product_review_quantity?: number | null,
  commission_paid_quantity?: number | null,
  dreambox_response_time?: number | null,
  dreambox_complete_time?: number | null,
};

export type DeleteProductInput = {
  id: string,
};

export type CreatePriceInput = {
  id?: string | null,
  product_id: string,
  display_name?: string | null,
  description?: string | null,
  status: string,
  type?: string | null,
  category?: string | null,
  fixed_amount?: string | null,
  fixed_centi_amount?: string | null,
  index?: number | null,
  min_amount?: string | null,
  min_centi_amount?: string | null,
  max_amount?: string | null,
  max_centi_amount?: string | null,
  currency_code?: string | null,
  percentage?: number | null,
};

export type ModelPriceConditionInput = {
  product_id?: ModelIDInput | null,
  display_name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStringInput | null,
  type?: ModelStringInput | null,
  category?: ModelStringInput | null,
  fixed_amount?: ModelStringInput | null,
  fixed_centi_amount?: ModelStringInput | null,
  index?: ModelIntInput | null,
  min_amount?: ModelStringInput | null,
  min_centi_amount?: ModelStringInput | null,
  max_amount?: ModelStringInput | null,
  max_centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  percentage?: ModelIntInput | null,
  and?: Array< ModelPriceConditionInput | null > | null,
  or?: Array< ModelPriceConditionInput | null > | null,
  not?: ModelPriceConditionInput | null,
};

export type UpdatePriceInput = {
  id: string,
  product_id?: string | null,
  display_name?: string | null,
  description?: string | null,
  status?: string | null,
  type?: string | null,
  category?: string | null,
  fixed_amount?: string | null,
  fixed_centi_amount?: string | null,
  index?: number | null,
  min_amount?: string | null,
  min_centi_amount?: string | null,
  max_amount?: string | null,
  max_centi_amount?: string | null,
  currency_code?: string | null,
  percentage?: number | null,
};

export type DeletePriceInput = {
  id: string,
};

export type CreateJobInput = {
  id?: string | null,
  title?: string | null,
  description?: string | null,
  min_budget?: string | null,
  min_centi_budget?: string | null,
  max_budget?: string | null,
  max_centi_budget?: string | null,
  currency_code?: string | null,
  status?: string | null,
  is_exclusive?: number | null,
  publication_date_time?: string | null,
  deadline_date_time?: string | null,
  expiration_date_time?: string | null,
  is_comercial_artwork?: number | null,
  is_dreambox?: number | null,
  is_private_artwork?: number | null,
  file_format?: Array< string | null > | null,
  buyer_id: string,
  seller_id: string,
  contacted_seller_ids?: Array< string | null > | null,
};

export type ModelJobConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  min_budget?: ModelStringInput | null,
  min_centi_budget?: ModelStringInput | null,
  max_budget?: ModelStringInput | null,
  max_centi_budget?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  status?: ModelStringInput | null,
  is_exclusive?: ModelIntInput | null,
  publication_date_time?: ModelStringInput | null,
  deadline_date_time?: ModelStringInput | null,
  expiration_date_time?: ModelStringInput | null,
  is_comercial_artwork?: ModelIntInput | null,
  is_dreambox?: ModelIntInput | null,
  is_private_artwork?: ModelIntInput | null,
  file_format?: ModelStringInput | null,
  buyer_id?: ModelIDInput | null,
  seller_id?: ModelIDInput | null,
  contacted_seller_ids?: ModelStringInput | null,
  and?: Array< ModelJobConditionInput | null > | null,
  or?: Array< ModelJobConditionInput | null > | null,
  not?: ModelJobConditionInput | null,
};

export type UpdateJobInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  min_budget?: string | null,
  min_centi_budget?: string | null,
  max_budget?: string | null,
  max_centi_budget?: string | null,
  currency_code?: string | null,
  status?: string | null,
  is_exclusive?: number | null,
  publication_date_time?: string | null,
  deadline_date_time?: string | null,
  expiration_date_time?: string | null,
  is_comercial_artwork?: number | null,
  is_dreambox?: number | null,
  is_private_artwork?: number | null,
  file_format?: Array< string | null > | null,
  buyer_id?: string | null,
  seller_id?: string | null,
  contacted_seller_ids?: Array< string | null > | null,
};

export type DeleteJobInput = {
  id: string,
};

export type CreateProposalInput = {
  id?: string | null,
  job_id: string,
  seller_id: string,
  seller_comment?: string | null,
  status?: string | null,
  publication_date_time?: string | null,
  proposal_type?: string | null,
};

export type ModelProposalConditionInput = {
  job_id?: ModelIDInput | null,
  seller_id?: ModelIDInput | null,
  seller_comment?: ModelStringInput | null,
  status?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  proposal_type?: ModelStringInput | null,
  and?: Array< ModelProposalConditionInput | null > | null,
  or?: Array< ModelProposalConditionInput | null > | null,
  not?: ModelProposalConditionInput | null,
};

export type UpdateProposalInput = {
  id: string,
  job_id?: string | null,
  seller_id?: string | null,
  seller_comment?: string | null,
  status?: string | null,
  publication_date_time?: string | null,
  proposal_type?: string | null,
};

export type DeleteProposalInput = {
  id: string,
};

export type CreateChannelInput = {
  id?: string | null,
  name?: string | null,
};

export type ModelChannelConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelChannelConditionInput | null > | null,
  or?: Array< ModelChannelConditionInput | null > | null,
  not?: ModelChannelConditionInput | null,
};

export type UpdateChannelInput = {
  id: string,
  name?: string | null,
};

export type DeleteChannelInput = {
  id: string,
};

export type CreateUserChannelInput = {
  id?: string | null,
  channel_id: string,
  user_id: string,
  update_date_time?: string | null,
  status?: string | null,
  next_time_should_receive_an_email?: string | null,
  next_time_should_receive_a_late_response?: string | null,
  request_start_date_time?: string | null,
};

export type ModelUserChannelConditionInput = {
  channel_id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  update_date_time?: ModelStringInput | null,
  status?: ModelStringInput | null,
  next_time_should_receive_an_email?: ModelStringInput | null,
  next_time_should_receive_a_late_response?: ModelStringInput | null,
  request_start_date_time?: ModelStringInput | null,
  and?: Array< ModelUserChannelConditionInput | null > | null,
  or?: Array< ModelUserChannelConditionInput | null > | null,
  not?: ModelUserChannelConditionInput | null,
};

export type UpdateUserChannelInput = {
  id: string,
  channel_id?: string | null,
  user_id?: string | null,
  update_date_time?: string | null,
  status?: string | null,
  next_time_should_receive_an_email?: string | null,
  next_time_should_receive_a_late_response?: string | null,
  request_start_date_time?: string | null,
};

export type DeleteUserChannelInput = {
  id: string,
};

export type CreateMessageInput = {
  id?: string | null,
  channel_id: string,
  sender_id: string,
  content?: string | null,
  image_url?: string | null,
  publication_date_time?: string | null,
  available_to?: string | null,
};

export type ModelMessageConditionInput = {
  channel_id?: ModelIDInput | null,
  sender_id?: ModelIDInput | null,
  content?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  available_to?: ModelStringInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type UpdateMessageInput = {
  id: string,
  channel_id?: string | null,
  sender_id?: string | null,
  content?: string | null,
  image_url?: string | null,
  publication_date_time?: string | null,
  available_to?: string | null,
};

export type DeleteMessageInput = {
  id: string,
};

export type CreatePaymentIntentInput = {
  id?: string | null,
  buyer_id?: string | null,
  contract_id: string,
  extra_payment_contract_id: string,
  extra_offer_id: string,
  amount?: string | null,
  centi_amount?: string | null,
  currency?: string | null,
  payout_owing_to_seller_amount?: string | null,
  payout_owing_to_seller_centi_amount?: string | null,
  fee_owing_to_momo_amount?: string | null,
  fee_owing_to_momo_centi_amount?: string | null,
  dreamerly_fee_balance_amount?: string | null,
  dreamerly_fee_balance_centi_amount?: string | null,
  display_fee_owing_to_momo_amount?: string | null,
  display_fee_owing_to_momo_centi_amount?: string | null,
  display_dreamerly_fee_balance_amount?: string | null,
  display_dreamerly_fee_balance_centi_amount?: string | null,
  payment_purpose?: string | null,
  billing_details?: string | null,
  description?: string | null,
  last_payment_error?: string | null,
  next_action?: string | null,
  disputed?: number | null,
  disputed_at?: string | null,
  refunded?: number | null,
  refunded_amount?: string | null,
  refunded_centi_amount?: string | null,
  refunded_at?: string | null,
  status?: string | null,
  payment_provider?: string | null,
  payment_method?: string | null,
  paid_at?: string | null,
  cancelled_at?: string | null,
  momo_trans_id?: string | null,
  momo_request_id?: string | null,
  momo_payment_url?: string | null,
  momo_payment_url_expires_at?: string | null,
  stripe_payment_intent_id?: string | null,
  stripe_payment_method_country?: string | null,
  balance_transaction_id?: string | null,
};

export type ModelPaymentIntentConditionInput = {
  buyer_id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  extra_payment_contract_id?: ModelIDInput | null,
  extra_offer_id?: ModelIDInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  payout_owing_to_seller_amount?: ModelStringInput | null,
  payout_owing_to_seller_centi_amount?: ModelStringInput | null,
  fee_owing_to_momo_amount?: ModelStringInput | null,
  fee_owing_to_momo_centi_amount?: ModelStringInput | null,
  dreamerly_fee_balance_amount?: ModelStringInput | null,
  dreamerly_fee_balance_centi_amount?: ModelStringInput | null,
  display_fee_owing_to_momo_amount?: ModelStringInput | null,
  display_fee_owing_to_momo_centi_amount?: ModelStringInput | null,
  display_dreamerly_fee_balance_amount?: ModelStringInput | null,
  display_dreamerly_fee_balance_centi_amount?: ModelStringInput | null,
  payment_purpose?: ModelStringInput | null,
  billing_details?: ModelStringInput | null,
  description?: ModelStringInput | null,
  last_payment_error?: ModelStringInput | null,
  next_action?: ModelStringInput | null,
  disputed?: ModelIntInput | null,
  disputed_at?: ModelStringInput | null,
  refunded?: ModelIntInput | null,
  refunded_amount?: ModelStringInput | null,
  refunded_centi_amount?: ModelStringInput | null,
  refunded_at?: ModelStringInput | null,
  status?: ModelStringInput | null,
  payment_provider?: ModelStringInput | null,
  payment_method?: ModelStringInput | null,
  paid_at?: ModelStringInput | null,
  cancelled_at?: ModelStringInput | null,
  momo_trans_id?: ModelStringInput | null,
  momo_request_id?: ModelStringInput | null,
  momo_payment_url?: ModelStringInput | null,
  momo_payment_url_expires_at?: ModelStringInput | null,
  stripe_payment_intent_id?: ModelStringInput | null,
  stripe_payment_method_country?: ModelStringInput | null,
  balance_transaction_id?: ModelIDInput | null,
  and?: Array< ModelPaymentIntentConditionInput | null > | null,
  or?: Array< ModelPaymentIntentConditionInput | null > | null,
  not?: ModelPaymentIntentConditionInput | null,
};

export type UpdatePaymentIntentInput = {
  id: string,
  buyer_id?: string | null,
  contract_id?: string | null,
  extra_payment_contract_id?: string | null,
  extra_offer_id?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency?: string | null,
  payout_owing_to_seller_amount?: string | null,
  payout_owing_to_seller_centi_amount?: string | null,
  fee_owing_to_momo_amount?: string | null,
  fee_owing_to_momo_centi_amount?: string | null,
  dreamerly_fee_balance_amount?: string | null,
  dreamerly_fee_balance_centi_amount?: string | null,
  display_fee_owing_to_momo_amount?: string | null,
  display_fee_owing_to_momo_centi_amount?: string | null,
  display_dreamerly_fee_balance_amount?: string | null,
  display_dreamerly_fee_balance_centi_amount?: string | null,
  payment_purpose?: string | null,
  billing_details?: string | null,
  description?: string | null,
  last_payment_error?: string | null,
  next_action?: string | null,
  disputed?: number | null,
  disputed_at?: string | null,
  refunded?: number | null,
  refunded_amount?: string | null,
  refunded_centi_amount?: string | null,
  refunded_at?: string | null,
  status?: string | null,
  payment_provider?: string | null,
  payment_method?: string | null,
  paid_at?: string | null,
  cancelled_at?: string | null,
  momo_trans_id?: string | null,
  momo_request_id?: string | null,
  momo_payment_url?: string | null,
  momo_payment_url_expires_at?: string | null,
  stripe_payment_intent_id?: string | null,
  stripe_payment_method_country?: string | null,
  balance_transaction_id?: string | null,
};

export type DeletePaymentIntentInput = {
  id: string,
};

export type CreatePayoutInput = {
  id?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency?: string | null,
  billing_details?: string | null,
  seller_id?: string | null,
  description?: string | null,
  arrival_date_time?: string | null,
  status?: string | null,
  payment_provider?: string | null,
  balance_transaction_id?: string | null,
  request_amount?: string | null,
  request_centi_amount?: string | null,
  request_date_time?: string | null,
  bank_account_id?: string | null,
};

export type ModelPayoutConditionInput = {
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  billing_details?: ModelStringInput | null,
  seller_id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  arrival_date_time?: ModelStringInput | null,
  status?: ModelStringInput | null,
  payment_provider?: ModelStringInput | null,
  balance_transaction_id?: ModelIDInput | null,
  request_amount?: ModelStringInput | null,
  request_centi_amount?: ModelStringInput | null,
  request_date_time?: ModelStringInput | null,
  bank_account_id?: ModelIDInput | null,
  and?: Array< ModelPayoutConditionInput | null > | null,
  or?: Array< ModelPayoutConditionInput | null > | null,
  not?: ModelPayoutConditionInput | null,
};

export type UpdatePayoutInput = {
  id: string,
  amount?: string | null,
  centi_amount?: string | null,
  currency?: string | null,
  billing_details?: string | null,
  seller_id?: string | null,
  description?: string | null,
  arrival_date_time?: string | null,
  status?: string | null,
  payment_provider?: string | null,
  balance_transaction_id?: string | null,
  request_amount?: string | null,
  request_centi_amount?: string | null,
  request_date_time?: string | null,
  bank_account_id?: string | null,
};

export type DeletePayoutInput = {
  id: string,
};

export type CreateRefundInput = {
  id?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency?: string | null,
  payout_owing_to_seller_amount?: string | null,
  payout_owing_to_seller_centi_amount?: string | null,
  fee_owing_to_momo_amount?: string | null,
  fee_owing_to_momo_centi_amount?: string | null,
  dreamerly_fee_balance_amount?: string | null,
  dreamerly_fee_balance_centi_amount?: string | null,
  billing_details?: string | null,
  buyer_id?: string | null,
  seller_id?: string | null,
  description?: string | null,
  payment_intent_id: string,
  contract_id: string,
  reason?: string | null,
  status?: string | null,
  response?: string | null,
  payment_provider?: string | null,
  momo_trans_id?: string | null,
  approved_at?: string | null,
  rejected_at?: string | null,
  refunded_at?: string | null,
  balance_transaction_id?: string | null,
};

export type ModelRefundConditionInput = {
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  payout_owing_to_seller_amount?: ModelStringInput | null,
  payout_owing_to_seller_centi_amount?: ModelStringInput | null,
  fee_owing_to_momo_amount?: ModelStringInput | null,
  fee_owing_to_momo_centi_amount?: ModelStringInput | null,
  dreamerly_fee_balance_amount?: ModelStringInput | null,
  dreamerly_fee_balance_centi_amount?: ModelStringInput | null,
  billing_details?: ModelStringInput | null,
  buyer_id?: ModelIDInput | null,
  seller_id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  payment_intent_id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  reason?: ModelStringInput | null,
  status?: ModelStringInput | null,
  response?: ModelStringInput | null,
  payment_provider?: ModelStringInput | null,
  momo_trans_id?: ModelStringInput | null,
  approved_at?: ModelStringInput | null,
  rejected_at?: ModelStringInput | null,
  refunded_at?: ModelStringInput | null,
  balance_transaction_id?: ModelIDInput | null,
  and?: Array< ModelRefundConditionInput | null > | null,
  or?: Array< ModelRefundConditionInput | null > | null,
  not?: ModelRefundConditionInput | null,
};

export type UpdateRefundInput = {
  id: string,
  amount?: string | null,
  centi_amount?: string | null,
  currency?: string | null,
  payout_owing_to_seller_amount?: string | null,
  payout_owing_to_seller_centi_amount?: string | null,
  fee_owing_to_momo_amount?: string | null,
  fee_owing_to_momo_centi_amount?: string | null,
  dreamerly_fee_balance_amount?: string | null,
  dreamerly_fee_balance_centi_amount?: string | null,
  billing_details?: string | null,
  buyer_id?: string | null,
  seller_id?: string | null,
  description?: string | null,
  payment_intent_id?: string | null,
  contract_id?: string | null,
  reason?: string | null,
  status?: string | null,
  response?: string | null,
  payment_provider?: string | null,
  momo_trans_id?: string | null,
  approved_at?: string | null,
  rejected_at?: string | null,
  refunded_at?: string | null,
  balance_transaction_id?: string | null,
};

export type DeleteRefundInput = {
  id: string,
};

export type CreateContractInput = {
  id?: string | null,
  status?: string | null,
  publication_date_time?: string | null,
  is_private_artwork?: number | null,
  is_comercial_artwork?: number | null,
  file_formats?: Array< string | null > | null,
  description?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  term_of_service?: string | null,
  state_name?: string | null,
  current_milestone_id?: string | null,
  current_milestone_index?: number | null,
  job_id: string,
  proposal_id: string,
  buyer_id: string,
  seller_id: string,
};

export type ModelContractConditionInput = {
  status?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  is_private_artwork?: ModelIntInput | null,
  is_comercial_artwork?: ModelIntInput | null,
  file_formats?: ModelStringInput | null,
  description?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  term_of_service?: ModelStringInput | null,
  state_name?: ModelStringInput | null,
  current_milestone_id?: ModelIDInput | null,
  current_milestone_index?: ModelIntInput | null,
  job_id?: ModelIDInput | null,
  proposal_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  seller_id?: ModelIDInput | null,
  and?: Array< ModelContractConditionInput | null > | null,
  or?: Array< ModelContractConditionInput | null > | null,
  not?: ModelContractConditionInput | null,
};

export type UpdateContractInput = {
  id: string,
  status?: string | null,
  publication_date_time?: string | null,
  is_private_artwork?: number | null,
  is_comercial_artwork?: number | null,
  file_formats?: Array< string | null > | null,
  description?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  term_of_service?: string | null,
  state_name?: string | null,
  current_milestone_id?: string | null,
  current_milestone_index?: number | null,
  job_id?: string | null,
  proposal_id?: string | null,
  buyer_id?: string | null,
  seller_id?: string | null,
};

export type DeleteContractInput = {
  id: string,
};

export type CreateContractProductInput = {
  id?: string | null,
  contract_id: string,
  product_id: string,
  product_display_name?: string | null,
  product_description?: string | null,
};

export type ModelContractProductConditionInput = {
  contract_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_display_name?: ModelStringInput | null,
  product_description?: ModelStringInput | null,
  and?: Array< ModelContractProductConditionInput | null > | null,
  or?: Array< ModelContractProductConditionInput | null > | null,
  not?: ModelContractProductConditionInput | null,
};

export type UpdateContractProductInput = {
  id: string,
  contract_id?: string | null,
  product_id?: string | null,
  product_display_name?: string | null,
  product_description?: string | null,
};

export type DeleteContractProductInput = {
  id: string,
};

export type CreateContractProductPriceInput = {
  id?: string | null,
  contract_product_id: string,
  extra_contract_product_id: string,
  price_id: string,
  price_quantity?: number | null,
  price_display_name?: string | null,
  price_description?: string | null,
  price_type?: string | null,
  price_category?: string | null,
  price_fixed_amount?: string | null,
  price_fixed_centi_amount?: string | null,
  price_currency_code?: string | null,
  price_percentage?: number | null,
};

export type ModelContractProductPriceConditionInput = {
  contract_product_id?: ModelIDInput | null,
  extra_contract_product_id?: ModelIDInput | null,
  price_id?: ModelIDInput | null,
  price_quantity?: ModelIntInput | null,
  price_display_name?: ModelStringInput | null,
  price_description?: ModelStringInput | null,
  price_type?: ModelStringInput | null,
  price_category?: ModelStringInput | null,
  price_fixed_amount?: ModelStringInput | null,
  price_fixed_centi_amount?: ModelStringInput | null,
  price_currency_code?: ModelStringInput | null,
  price_percentage?: ModelIntInput | null,
  and?: Array< ModelContractProductPriceConditionInput | null > | null,
  or?: Array< ModelContractProductPriceConditionInput | null > | null,
  not?: ModelContractProductPriceConditionInput | null,
};

export type UpdateContractProductPriceInput = {
  id: string,
  contract_product_id?: string | null,
  extra_contract_product_id?: string | null,
  price_id?: string | null,
  price_quantity?: number | null,
  price_display_name?: string | null,
  price_description?: string | null,
  price_type?: string | null,
  price_category?: string | null,
  price_fixed_amount?: string | null,
  price_fixed_centi_amount?: string | null,
  price_currency_code?: string | null,
  price_percentage?: number | null,
};

export type DeleteContractProductPriceInput = {
  id: string,
};

export type CreateExtraOfferInput = {
  id?: string | null,
  contract_id: string,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  status?: string | null,
};

export type ModelExtraOfferConditionInput = {
  contract_id?: ModelIDInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelExtraOfferConditionInput | null > | null,
  or?: Array< ModelExtraOfferConditionInput | null > | null,
  not?: ModelExtraOfferConditionInput | null,
};

export type UpdateExtraOfferInput = {
  id: string,
  contract_id?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  status?: string | null,
};

export type DeleteExtraOfferInput = {
  id: string,
};

export type CreateExtraOfferPriceInput = {
  id?: string | null,
  extra_offer_id: string,
  price_id: string,
  price_quantity: number,
  price_display_name?: string | null,
  price_description?: string | null,
  price_type?: string | null,
  price_category?: string | null,
  price_fixed_amount?: string | null,
  price_fixed_centi_amount?: string | null,
  price_currency_code?: string | null,
  price_percentage?: number | null,
};

export type ModelExtraOfferPriceConditionInput = {
  extra_offer_id?: ModelIDInput | null,
  price_id?: ModelIDInput | null,
  price_quantity?: ModelIntInput | null,
  price_display_name?: ModelStringInput | null,
  price_description?: ModelStringInput | null,
  price_type?: ModelStringInput | null,
  price_category?: ModelStringInput | null,
  price_fixed_amount?: ModelStringInput | null,
  price_fixed_centi_amount?: ModelStringInput | null,
  price_currency_code?: ModelStringInput | null,
  price_percentage?: ModelIntInput | null,
  and?: Array< ModelExtraOfferPriceConditionInput | null > | null,
  or?: Array< ModelExtraOfferPriceConditionInput | null > | null,
  not?: ModelExtraOfferPriceConditionInput | null,
};

export type UpdateExtraOfferPriceInput = {
  id: string,
  extra_offer_id?: string | null,
  price_id?: string | null,
  price_quantity?: number | null,
  price_display_name?: string | null,
  price_description?: string | null,
  price_type?: string | null,
  price_category?: string | null,
  price_fixed_amount?: string | null,
  price_fixed_centi_amount?: string | null,
  price_currency_code?: string | null,
  price_percentage?: number | null,
};

export type DeleteExtraOfferPriceInput = {
  id: string,
};

export type CreateMilestoneInput = {
  id?: string | null,
  contract_id: string,
  step_index?: number | null,
  step_name?: string | null,
  deadline_number?: number | null,
  deadline_type?: string | null,
  start_date_time?: string | null,
  end_date_time?: string | null,
  delivery_date_time?: string | null,
  free_revision_count?: number | null,
  current_revision_index?: number | null,
  current_revision_id?: string | null,
  description?: string | null,
  deadline_extensions?: string | null,
  price_percentage?: number | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  status?: string | null,
};

export type ModelMilestoneConditionInput = {
  contract_id?: ModelIDInput | null,
  step_index?: ModelIntInput | null,
  step_name?: ModelStringInput | null,
  deadline_number?: ModelIntInput | null,
  deadline_type?: ModelStringInput | null,
  start_date_time?: ModelStringInput | null,
  end_date_time?: ModelStringInput | null,
  delivery_date_time?: ModelStringInput | null,
  free_revision_count?: ModelIntInput | null,
  current_revision_index?: ModelIntInput | null,
  current_revision_id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  deadline_extensions?: ModelStringInput | null,
  price_percentage?: ModelIntInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelMilestoneConditionInput | null > | null,
  or?: Array< ModelMilestoneConditionInput | null > | null,
  not?: ModelMilestoneConditionInput | null,
};

export type UpdateMilestoneInput = {
  id: string,
  contract_id?: string | null,
  step_index?: number | null,
  step_name?: string | null,
  deadline_number?: number | null,
  deadline_type?: string | null,
  start_date_time?: string | null,
  end_date_time?: string | null,
  delivery_date_time?: string | null,
  free_revision_count?: number | null,
  current_revision_index?: number | null,
  current_revision_id?: string | null,
  description?: string | null,
  deadline_extensions?: string | null,
  price_percentage?: number | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  status?: string | null,
};

export type DeleteMilestoneInput = {
  id: string,
};

export type CreateRevisionInput = {
  id?: string | null,
  index?: number | null,
  status?: string | null,
  request_message?: string | null,
  request_rejection_message?: string | null,
  milestone_id: string,
  additional_deadline_number?: number | null,
  additional_deadline_type?: string | null,
  start_date_time?: string | null,
  end_date_time?: string | null,
  delivery_date_time?: string | null,
  deadline_extensions?: string | null,
};

export type ModelRevisionConditionInput = {
  index?: ModelIntInput | null,
  status?: ModelStringInput | null,
  request_message?: ModelStringInput | null,
  request_rejection_message?: ModelStringInput | null,
  milestone_id?: ModelIDInput | null,
  additional_deadline_number?: ModelIntInput | null,
  additional_deadline_type?: ModelStringInput | null,
  start_date_time?: ModelStringInput | null,
  end_date_time?: ModelStringInput | null,
  delivery_date_time?: ModelStringInput | null,
  deadline_extensions?: ModelStringInput | null,
  and?: Array< ModelRevisionConditionInput | null > | null,
  or?: Array< ModelRevisionConditionInput | null > | null,
  not?: ModelRevisionConditionInput | null,
};

export type UpdateRevisionInput = {
  id: string,
  index?: number | null,
  status?: string | null,
  request_message?: string | null,
  request_rejection_message?: string | null,
  milestone_id?: string | null,
  additional_deadline_number?: number | null,
  additional_deadline_type?: string | null,
  start_date_time?: string | null,
  end_date_time?: string | null,
  delivery_date_time?: string | null,
  deadline_extensions?: string | null,
};

export type DeleteRevisionInput = {
  id: string,
};

export type CreateContractEventInput = {
  id?: string | null,
  contract_id: string,
  event_id: string,
  contract_status?: string | null,
  contract_amount?: string | null,
  contract_currency?: string | null,
  extra_offer_status?: string | null,
  extra_offer_amount?: string | null,
  extra_offer_currency?: string | null,
};

export type ModelContractEventConditionInput = {
  contract_id?: ModelIDInput | null,
  event_id?: ModelIDInput | null,
  contract_status?: ModelStringInput | null,
  contract_amount?: ModelStringInput | null,
  contract_currency?: ModelStringInput | null,
  extra_offer_status?: ModelStringInput | null,
  extra_offer_amount?: ModelStringInput | null,
  extra_offer_currency?: ModelStringInput | null,
  and?: Array< ModelContractEventConditionInput | null > | null,
  or?: Array< ModelContractEventConditionInput | null > | null,
  not?: ModelContractEventConditionInput | null,
};

export type UpdateContractEventInput = {
  id: string,
  contract_id?: string | null,
  event_id?: string | null,
  contract_status?: string | null,
  contract_amount?: string | null,
  contract_currency?: string | null,
  extra_offer_status?: string | null,
  extra_offer_amount?: string | null,
  extra_offer_currency?: string | null,
};

export type DeleteContractEventInput = {
  id: string,
};

export type CreatePaymentIntentEventInput = {
  id?: string | null,
  payment_intent_id: string,
  event_id: string,
  payment_intent_status?: string | null,
  payment_intent_amount?: string | null,
  payment_intent_currency?: string | null,
};

export type ModelPaymentIntentEventConditionInput = {
  payment_intent_id?: ModelIDInput | null,
  event_id?: ModelIDInput | null,
  payment_intent_status?: ModelStringInput | null,
  payment_intent_amount?: ModelStringInput | null,
  payment_intent_currency?: ModelStringInput | null,
  and?: Array< ModelPaymentIntentEventConditionInput | null > | null,
  or?: Array< ModelPaymentIntentEventConditionInput | null > | null,
  not?: ModelPaymentIntentEventConditionInput | null,
};

export type UpdatePaymentIntentEventInput = {
  id: string,
  payment_intent_id?: string | null,
  event_id?: string | null,
  payment_intent_status?: string | null,
  payment_intent_amount?: string | null,
  payment_intent_currency?: string | null,
};

export type DeletePaymentIntentEventInput = {
  id: string,
};

export type CreateMilestoneEventInput = {
  id?: string | null,
  milestone_id: string,
  event_id: string,
  milestone_status?: string | null,
  milestone_step_index?: number | null,
  milestone_step_name?: string | null,
};

export type ModelMilestoneEventConditionInput = {
  milestone_id?: ModelIDInput | null,
  event_id?: ModelIDInput | null,
  milestone_status?: ModelStringInput | null,
  milestone_step_index?: ModelIntInput | null,
  milestone_step_name?: ModelStringInput | null,
  and?: Array< ModelMilestoneEventConditionInput | null > | null,
  or?: Array< ModelMilestoneEventConditionInput | null > | null,
  not?: ModelMilestoneEventConditionInput | null,
};

export type UpdateMilestoneEventInput = {
  id: string,
  milestone_id?: string | null,
  event_id?: string | null,
  milestone_status?: string | null,
  milestone_step_index?: number | null,
  milestone_step_name?: string | null,
};

export type DeleteMilestoneEventInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  type?: string | null,
  note?: string | null,
};

export type ModelEventConditionInput = {
  type?: ModelStringInput | null,
  note?: ModelStringInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type UpdateEventInput = {
  id: string,
  type?: string | null,
  note?: string | null,
};

export type DeleteEventInput = {
  id: string,
};

export type CreateReviewInput = {
  id?: string | null,
  user_id: string,
  reviewer_id: string,
  contract_id: string,
  rating?: number | null,
  comment?: string | null,
  type?: string | null,
  publication_date_time?: string | null,
};

export type ModelReviewConditionInput = {
  user_id?: ModelIDInput | null,
  reviewer_id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  rating?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  type?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  and?: Array< ModelReviewConditionInput | null > | null,
  or?: Array< ModelReviewConditionInput | null > | null,
  not?: ModelReviewConditionInput | null,
};

export type UpdateReviewInput = {
  id: string,
  user_id?: string | null,
  reviewer_id?: string | null,
  contract_id?: string | null,
  rating?: number | null,
  comment?: string | null,
  type?: string | null,
  publication_date_time?: string | null,
};

export type DeleteReviewInput = {
  id: string,
};

export type CreateUserFollowInput = {
  id?: string | null,
  leader_id: string,
  follower_id: string,
  created_at?: string | null,
  updated_at?: string | null,
};

export type ModelUserFollowConditionInput = {
  leader_id?: ModelIDInput | null,
  follower_id?: ModelIDInput | null,
  created_at?: ModelStringInput | null,
  updated_at?: ModelStringInput | null,
  and?: Array< ModelUserFollowConditionInput | null > | null,
  or?: Array< ModelUserFollowConditionInput | null > | null,
  not?: ModelUserFollowConditionInput | null,
};

export type UpdateUserFollowInput = {
  id: string,
  leader_id?: string | null,
  follower_id?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type DeleteUserFollowInput = {
  id: string,
};

export type CreateDreamerlyBalanceInput = {
  id?: string | null,
  currency_code?: string | null,
};

export type ModelDreamerlyBalanceConditionInput = {
  currency_code?: ModelStringInput | null,
  and?: Array< ModelDreamerlyBalanceConditionInput | null > | null,
  or?: Array< ModelDreamerlyBalanceConditionInput | null > | null,
  not?: ModelDreamerlyBalanceConditionInput | null,
};

export type UpdateDreamerlyBalanceInput = {
  id: string,
  currency_code?: string | null,
};

export type DeleteDreamerlyBalanceInput = {
  id: string,
};

export type CreateBalanceTransferInput = {
  id?: string | null,
  reason?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  dreamerly_fee?: string | null,
  dreamerly_centi_fee?: string | null,
  payment_provider_fee?: string | null,
  payment_provider_centi_fee?: string | null,
  net_amount?: string | null,
  net_centi_amount?: string | null,
  payment_provider?: string | null,
  currency_code?: string | null,
  note?: string | null,
  publication_date_time?: string | null,
  milestone_id?: string | null,
  contract_id?: string | null,
  from_user_id: string,
  to_user_id: string,
  from_dreamerly_balance_id: string,
  to_dreamerly_balance_id: string,
  balance_transaction_id: string,
};

export type ModelBalanceTransferConditionInput = {
  reason?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  dreamerly_fee?: ModelStringInput | null,
  dreamerly_centi_fee?: ModelStringInput | null,
  payment_provider_fee?: ModelStringInput | null,
  payment_provider_centi_fee?: ModelStringInput | null,
  net_amount?: ModelStringInput | null,
  net_centi_amount?: ModelStringInput | null,
  payment_provider?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  note?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  milestone_id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  from_user_id?: ModelIDInput | null,
  to_user_id?: ModelIDInput | null,
  from_dreamerly_balance_id?: ModelIDInput | null,
  to_dreamerly_balance_id?: ModelIDInput | null,
  balance_transaction_id?: ModelIDInput | null,
  and?: Array< ModelBalanceTransferConditionInput | null > | null,
  or?: Array< ModelBalanceTransferConditionInput | null > | null,
  not?: ModelBalanceTransferConditionInput | null,
};

export type UpdateBalanceTransferInput = {
  id: string,
  reason?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  dreamerly_fee?: string | null,
  dreamerly_centi_fee?: string | null,
  payment_provider_fee?: string | null,
  payment_provider_centi_fee?: string | null,
  net_amount?: string | null,
  net_centi_amount?: string | null,
  payment_provider?: string | null,
  currency_code?: string | null,
  note?: string | null,
  publication_date_time?: string | null,
  milestone_id?: string | null,
  contract_id?: string | null,
  from_user_id?: string | null,
  to_user_id?: string | null,
  from_dreamerly_balance_id?: string | null,
  to_dreamerly_balance_id?: string | null,
  balance_transaction_id?: string | null,
};

export type DeleteBalanceTransferInput = {
  id: string,
};

export type CreatePaymentProviderFeePaymentInput = {
  id?: string | null,
  amount: string,
  centi_amount: string,
  currency_code: string,
  publication_date_time?: string | null,
  payment_provider?: string | null,
  description?: string | null,
  invoice_number?: string | null,
  from_dreamerly_balance_id: string,
  to_dreamerly_balance_id: string,
  balance_transaction_id: string,
};

export type ModelPaymentProviderFeePaymentConditionInput = {
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  payment_provider?: ModelStringInput | null,
  description?: ModelStringInput | null,
  invoice_number?: ModelStringInput | null,
  from_dreamerly_balance_id?: ModelIDInput | null,
  to_dreamerly_balance_id?: ModelIDInput | null,
  balance_transaction_id?: ModelIDInput | null,
  and?: Array< ModelPaymentProviderFeePaymentConditionInput | null > | null,
  or?: Array< ModelPaymentProviderFeePaymentConditionInput | null > | null,
  not?: ModelPaymentProviderFeePaymentConditionInput | null,
};

export type UpdatePaymentProviderFeePaymentInput = {
  id: string,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  publication_date_time?: string | null,
  payment_provider?: string | null,
  description?: string | null,
  invoice_number?: string | null,
  from_dreamerly_balance_id?: string | null,
  to_dreamerly_balance_id?: string | null,
  balance_transaction_id?: string | null,
};

export type DeletePaymentProviderFeePaymentInput = {
  id: string,
};

export type CreateBalanceTransactionInput = {
  id?: string | null,
  type?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  note?: string | null,
  publication_date_time?: string | null,
  user_id: string,
};

export type ModelBalanceTransactionConditionInput = {
  type?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  note?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  user_id?: ModelIDInput | null,
  and?: Array< ModelBalanceTransactionConditionInput | null > | null,
  or?: Array< ModelBalanceTransactionConditionInput | null > | null,
  not?: ModelBalanceTransactionConditionInput | null,
};

export type UpdateBalanceTransactionInput = {
  id: string,
  type?: string | null,
  amount?: string | null,
  centi_amount?: string | null,
  currency_code?: string | null,
  note?: string | null,
  publication_date_time?: string | null,
  user_id?: string | null,
};

export type DeleteBalanceTransactionInput = {
  id: string,
};

export type CreateNotificationInput = {
  id?: string | null,
  user_id: string,
  notification_sender_id?: string | null,
  title?: string | null,
  body?: string | null,
  publication_date_time?: string | null,
  is_read?: number | null,
  is_removed?: number | null,
  notification_type?: string | null,
  underlying_object_id?: string | null,
};

export type ModelNotificationConditionInput = {
  user_id?: ModelIDInput | null,
  notification_sender_id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  is_read?: ModelIntInput | null,
  is_removed?: ModelIntInput | null,
  notification_type?: ModelStringInput | null,
  underlying_object_id?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationInput = {
  id: string,
  user_id?: string | null,
  notification_sender_id?: string | null,
  title?: string | null,
  body?: string | null,
  publication_date_time?: string | null,
  is_read?: number | null,
  is_removed?: number | null,
  notification_type?: string | null,
  underlying_object_id?: string | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type CreateCharacterInput = {
  id?: string | null,
  display_name?: string | null,
  charname?: string | null,
  character_type?: string | null,
  user_id?: string | null,
  description?: string | null,
  strengths?: string | null,
  flaws?: string | null,
  gender?: string | null,
  race?: string | null,
  birth_date?: string | null,
  age?: string | null,
  role?: string | null,
  hobbies?: string | null,
  dislikes?: string | null,
  profile_picture_url?: string | null,
  cover_photo_url?: string | null,
};

export type ModelCharacterConditionInput = {
  display_name?: ModelStringInput | null,
  charname?: ModelStringInput | null,
  character_type?: ModelStringInput | null,
  user_id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  strengths?: ModelStringInput | null,
  flaws?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  race?: ModelStringInput | null,
  birth_date?: ModelStringInput | null,
  age?: ModelStringInput | null,
  role?: ModelStringInput | null,
  hobbies?: ModelStringInput | null,
  dislikes?: ModelStringInput | null,
  profile_picture_url?: ModelStringInput | null,
  cover_photo_url?: ModelStringInput | null,
  and?: Array< ModelCharacterConditionInput | null > | null,
  or?: Array< ModelCharacterConditionInput | null > | null,
  not?: ModelCharacterConditionInput | null,
};

export type UpdateCharacterInput = {
  id: string,
  display_name?: string | null,
  charname?: string | null,
  character_type?: string | null,
  user_id?: string | null,
  description?: string | null,
  strengths?: string | null,
  flaws?: string | null,
  gender?: string | null,
  race?: string | null,
  birth_date?: string | null,
  age?: string | null,
  role?: string | null,
  hobbies?: string | null,
  dislikes?: string | null,
  profile_picture_url?: string | null,
  cover_photo_url?: string | null,
};

export type DeleteCharacterInput = {
  id: string,
};

export type CreateCharacterItemInput = {
  id?: string | null,
  item_type?: string | null,
  character_id: string,
  artwork_id?: string | null,
  job_id?: string | null,
};

export type ModelCharacterItemConditionInput = {
  item_type?: ModelStringInput | null,
  character_id?: ModelIDInput | null,
  artwork_id?: ModelIDInput | null,
  job_id?: ModelIDInput | null,
  and?: Array< ModelCharacterItemConditionInput | null > | null,
  or?: Array< ModelCharacterItemConditionInput | null > | null,
  not?: ModelCharacterItemConditionInput | null,
};

export type UpdateCharacterItemInput = {
  id: string,
  item_type?: string | null,
  character_id?: string | null,
  artwork_id?: string | null,
  job_id?: string | null,
};

export type DeleteCharacterItemInput = {
  id: string,
};

export type DreamerlySaveBankAccountOutput = {
  __typename: "DreamerlySaveBankAccountOutput",
  bank_account_id: string,
};

export type DreamerlySavePayoutRequestOutput = {
  __typename: "DreamerlySavePayoutRequestOutput",
  payout_id: string,
};

export type ModelTagFilterInput = {
  id?: ModelIDInput | null,
  tag_name?: ModelStringInput | null,
  display_name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  total_artwork_quantity?: ModelIntInput | null,
  and?: Array< ModelTagFilterInput | null > | null,
  or?: Array< ModelTagFilterInput | null > | null,
  not?: ModelTagFilterInput | null,
};

export type ModelTagConnection = {
  __typename: "ModelTagConnection",
  items:  Array<Tag | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTagItemFilterInput = {
  id?: ModelIDInput | null,
  tag_id?: ModelIDInput | null,
  artwork_id?: ModelIDInput | null,
  tag_type?: ModelStringInput | null,
  and?: Array< ModelTagItemFilterInput | null > | null,
  or?: Array< ModelTagItemFilterInput | null > | null,
  not?: ModelTagItemFilterInput | null,
};

export type ModelArtworkFilterInput = {
  id?: ModelIDInput | null,
  cover_image_height?: ModelIntInput | null,
  cover_image_width?: ModelIntInput | null,
  cover_image_src_url?: ModelStringInput | null,
  cover_image_thumbnail_url?: ModelStringInput | null,
  title?: ModelStringInput | null,
  likes_count?: ModelIntInput | null,
  comments_count?: ModelIntInput | null,
  description?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  user_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  is_removed?: ModelIntInput | null,
  contract_id?: ModelIDInput | null,
  is_buyer_showcase?: ModelIntInput | null,
  is_seller_showcase?: ModelIntInput | null,
  and?: Array< ModelArtworkFilterInput | null > | null,
  or?: Array< ModelArtworkFilterInput | null > | null,
  not?: ModelArtworkFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelProductArtworkFilterInput = {
  id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  artwork_id?: ModelIDInput | null,
  is_removed?: ModelIntInput | null,
  index?: ModelIntInput | null,
  and?: Array< ModelProductArtworkFilterInput | null > | null,
  or?: Array< ModelProductArtworkFilterInput | null > | null,
  not?: ModelProductArtworkFilterInput | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  display_name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type ModelImageFilterInput = {
  id?: ModelIDInput | null,
  height?: ModelIntInput | null,
  width?: ModelIntInput | null,
  file_name?: ModelStringInput | null,
  src_url?: ModelStringInput | null,
  thumbnail_url?: ModelStringInput | null,
  raw_src_url?: ModelStringInput | null,
  raw_thumbnail_url?: ModelStringInput | null,
  artwork_id?: ModelIDInput | null,
  job_id?: ModelIDInput | null,
  milestone_id?: ModelIDInput | null,
  revision_id?: ModelIDInput | null,
  revision_request_revision_id?: ModelIDInput | null,
  message_id?: ModelIDInput | null,
  and?: Array< ModelImageFilterInput | null > | null,
  or?: Array< ModelImageFilterInput | null > | null,
  not?: ModelImageFilterInput | null,
};

export type ModelLikeFilterInput = {
  id?: ModelIDInput | null,
  artwork_id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  is_removed?: ModelIntInput | null,
  and?: Array< ModelLikeFilterInput | null > | null,
  or?: Array< ModelLikeFilterInput | null > | null,
  not?: ModelLikeFilterInput | null,
};

export type ModelLikeByArtworkIdUserIdIsRemovedCompositeKeyConditionInput = {
  eq?: ModelLikeByArtworkIdUserIdIsRemovedCompositeKeyInput | null,
  le?: ModelLikeByArtworkIdUserIdIsRemovedCompositeKeyInput | null,
  lt?: ModelLikeByArtworkIdUserIdIsRemovedCompositeKeyInput | null,
  ge?: ModelLikeByArtworkIdUserIdIsRemovedCompositeKeyInput | null,
  gt?: ModelLikeByArtworkIdUserIdIsRemovedCompositeKeyInput | null,
  between?: Array< ModelLikeByArtworkIdUserIdIsRemovedCompositeKeyInput | null > | null,
  beginsWith?: ModelLikeByArtworkIdUserIdIsRemovedCompositeKeyInput | null,
};

export type ModelLikeByArtworkIdUserIdIsRemovedCompositeKeyInput = {
  user_id?: string | null,
  is_removed?: number | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  display_name?: ModelStringInput | null,
  first_name?: ModelStringInput | null,
  last_name?: ModelStringInput | null,
  username?: ModelStringInput | null,
  email_address?: ModelStringInput | null,
  birth_date?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  country_code?: ModelStringInput | null,
  subscriber_number?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  is_artist?: ModelIntInput | null,
  seller_onboarding_stage?: ModelStringInput | null,
  term_of_service?: ModelStringInput | null,
  cccd?: ModelStringInput | null,
  mst?: ModelStringInput | null,
  cccd_images?: ModelStringInput | null,
  mst_stage?: ModelStringInput | null,
  profile_picture_url?: ModelStringInput | null,
  cover_picture_url?: ModelStringInput | null,
  balance_amount?: ModelStringInput | null,
  balance_centi_amount?: ModelStringInput | null,
  balance_currency_code?: ModelStringInput | null,
  usd_balance_amount?: ModelStringInput | null,
  usd_balance_centi_amount?: ModelStringInput | null,
  contract_paid_quantity?: ModelIntInput | null,
  seller_star_quantity?: ModelIntInput | null,
  seller_review_quantity?: ModelIntInput | null,
  buyer_star_quantity?: ModelIntInput | null,
  buyer_review_quantity?: ModelIntInput | null,
  commision_started_quantity?: ModelIntInput | null,
  commission_completed_quantity?: ModelIntInput | null,
  commission_completed_on_time_quantity?: ModelIntInput | null,
  commission_cancelled_seller_fault_quantity?: ModelIntInput | null,
  commission_cancelled_buyer_fault_quantity?: ModelIntInput | null,
  commission_cancelled_mutually_quantity?: ModelIntInput | null,
  commission_cumulative_late_seconds?: ModelIntInput | null,
  commission_max_late_seconds?: ModelIntInput | null,
  device_tokens?: ModelStringInput | null,
  is_selling?: ModelIntInput | null,
  is_selling_abroad?: ModelIntInput | null,
  last_time_open_list_notification?: ModelStringInput | null,
  discount_code?: ModelStringInput | null,
  follower_quantity?: ModelIntInput | null,
  leader_quantity?: ModelIntInput | null,
  has_unread_commissions?: ModelIntInput | null,
  has_unread_jobs?: ModelIntInput | null,
  message_cumulative_response_seconds?: ModelIntInput | null,
  message_response_on_time_quantity?: ModelIntInput | null,
  message_response_late_quantity?: ModelIntInput | null,
  bank_name?: ModelStringInput | null,
  account_holder_name?: ModelStringInput | null,
  account_last_four?: ModelStringInput | null,
  address_country_code?: ModelStringInput | null,
  city?: ModelStringInput | null,
  address_line1?: ModelStringInput | null,
  additional_info?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelBankAccountFilterInput = {
  id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  bank_name?: ModelStringInput | null,
  account_holder_name?: ModelStringInput | null,
  account_number?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  country_code?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelBankAccountFilterInput | null > | null,
  or?: Array< ModelBankAccountFilterInput | null > | null,
  not?: ModelBankAccountFilterInput | null,
};

export type ModelBankAccountConnection = {
  __typename: "ModelBankAccountConnection",
  items:  Array<BankAccount | null >,
  nextToken?: string | null,
};

export type ModelPushTokenFilterInput = {
  id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  push_token?: ModelStringInput | null,
  device_type?: ModelStringInput | null,
  last_used_at?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelPushTokenFilterInput | null > | null,
  or?: Array< ModelPushTokenFilterInput | null > | null,
  not?: ModelPushTokenFilterInput | null,
};

export type ModelUserBlockFilterInput = {
  id?: ModelIDInput | null,
  user_give_block_id?: ModelIDInput | null,
  user_receive_block_id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelUserBlockFilterInput | null > | null,
  or?: Array< ModelUserBlockFilterInput | null > | null,
  not?: ModelUserBlockFilterInput | null,
};

export type ModelUserBlockConnection = {
  __typename: "ModelUserBlockConnection",
  items:  Array<UserBlock | null >,
  nextToken?: string | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  artist_id?: ModelIDInput | null,
  display_name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  category_id?: ModelIDInput | null,
  min_price_amount?: ModelStringInput | null,
  max_price_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  product_star_quantity?: ModelIntInput | null,
  product_review_quantity?: ModelIntInput | null,
  commission_paid_quantity?: ModelIntInput | null,
  dreambox_response_time?: ModelIntInput | null,
  dreambox_complete_time?: ModelIntInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
};

export type ModelPriceFilterInput = {
  id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  display_name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStringInput | null,
  type?: ModelStringInput | null,
  category?: ModelStringInput | null,
  fixed_amount?: ModelStringInput | null,
  fixed_centi_amount?: ModelStringInput | null,
  index?: ModelIntInput | null,
  min_amount?: ModelStringInput | null,
  min_centi_amount?: ModelStringInput | null,
  max_amount?: ModelStringInput | null,
  max_centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  percentage?: ModelIntInput | null,
  and?: Array< ModelPriceFilterInput | null > | null,
  or?: Array< ModelPriceFilterInput | null > | null,
  not?: ModelPriceFilterInput | null,
};

export type ModelJobFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  min_budget?: ModelStringInput | null,
  min_centi_budget?: ModelStringInput | null,
  max_budget?: ModelStringInput | null,
  max_centi_budget?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  status?: ModelStringInput | null,
  is_exclusive?: ModelIntInput | null,
  publication_date_time?: ModelStringInput | null,
  deadline_date_time?: ModelStringInput | null,
  expiration_date_time?: ModelStringInput | null,
  is_comercial_artwork?: ModelIntInput | null,
  is_dreambox?: ModelIntInput | null,
  is_private_artwork?: ModelIntInput | null,
  file_format?: ModelStringInput | null,
  buyer_id?: ModelIDInput | null,
  seller_id?: ModelIDInput | null,
  contacted_seller_ids?: ModelStringInput | null,
  and?: Array< ModelJobFilterInput | null > | null,
  or?: Array< ModelJobFilterInput | null > | null,
  not?: ModelJobFilterInput | null,
};

export type ModelJobByStatusIsExclusivePublicationDateTimeCompositeKeyConditionInput = {
  eq?: ModelJobByStatusIsExclusivePublicationDateTimeCompositeKeyInput | null,
  le?: ModelJobByStatusIsExclusivePublicationDateTimeCompositeKeyInput | null,
  lt?: ModelJobByStatusIsExclusivePublicationDateTimeCompositeKeyInput | null,
  ge?: ModelJobByStatusIsExclusivePublicationDateTimeCompositeKeyInput | null,
  gt?: ModelJobByStatusIsExclusivePublicationDateTimeCompositeKeyInput | null,
  between?: Array< ModelJobByStatusIsExclusivePublicationDateTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelJobByStatusIsExclusivePublicationDateTimeCompositeKeyInput | null,
};

export type ModelJobByStatusIsExclusivePublicationDateTimeCompositeKeyInput = {
  is_exclusive?: number | null,
  publication_date_time?: string | null,
};

export type ModelJobByStatusIsExclusiveExpirationDateTimeCompositeKeyConditionInput = {
  eq?: ModelJobByStatusIsExclusiveExpirationDateTimeCompositeKeyInput | null,
  le?: ModelJobByStatusIsExclusiveExpirationDateTimeCompositeKeyInput | null,
  lt?: ModelJobByStatusIsExclusiveExpirationDateTimeCompositeKeyInput | null,
  ge?: ModelJobByStatusIsExclusiveExpirationDateTimeCompositeKeyInput | null,
  gt?: ModelJobByStatusIsExclusiveExpirationDateTimeCompositeKeyInput | null,
  between?: Array< ModelJobByStatusIsExclusiveExpirationDateTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelJobByStatusIsExclusiveExpirationDateTimeCompositeKeyInput | null,
};

export type ModelJobByStatusIsExclusiveExpirationDateTimeCompositeKeyInput = {
  is_exclusive?: number | null,
  expiration_date_time?: string | null,
};

export type ModelJobByBuyerIdStatusPublicationDateTimeCompositeKeyConditionInput = {
  eq?: ModelJobByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
  le?: ModelJobByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
  lt?: ModelJobByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
  ge?: ModelJobByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
  gt?: ModelJobByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
  between?: Array< ModelJobByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelJobByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
};

export type ModelJobByBuyerIdStatusPublicationDateTimeCompositeKeyInput = {
  status?: string | null,
  publication_date_time?: string | null,
};

export type ModelJobBySellerIdStatusPublicationDateTimeCompositeKeyConditionInput = {
  eq?: ModelJobBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  le?: ModelJobBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  lt?: ModelJobBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  ge?: ModelJobBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  gt?: ModelJobBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  between?: Array< ModelJobBySellerIdStatusPublicationDateTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelJobBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
};

export type ModelJobBySellerIdStatusPublicationDateTimeCompositeKeyInput = {
  status?: string | null,
  publication_date_time?: string | null,
};

export type ModelProposalFilterInput = {
  id?: ModelIDInput | null,
  job_id?: ModelIDInput | null,
  seller_id?: ModelIDInput | null,
  seller_comment?: ModelStringInput | null,
  status?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  proposal_type?: ModelStringInput | null,
  and?: Array< ModelProposalFilterInput | null > | null,
  or?: Array< ModelProposalFilterInput | null > | null,
  not?: ModelProposalFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyConditionInput = {
  eq?: ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  le?: ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  lt?: ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  ge?: ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  gt?: ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  between?: Array< ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
};

export type ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyInput = {
  status?: string | null,
  publication_date_time?: string | null,
};

export type ModelChannelFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelChannelFilterInput | null > | null,
  or?: Array< ModelChannelFilterInput | null > | null,
  not?: ModelChannelFilterInput | null,
};

export type ModelChannelConnection = {
  __typename: "ModelChannelConnection",
  items:  Array<Channel | null >,
  nextToken?: string | null,
};

export type ModelUserChannelFilterInput = {
  id?: ModelIDInput | null,
  channel_id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  update_date_time?: ModelStringInput | null,
  status?: ModelStringInput | null,
  next_time_should_receive_an_email?: ModelStringInput | null,
  next_time_should_receive_a_late_response?: ModelStringInput | null,
  request_start_date_time?: ModelStringInput | null,
  and?: Array< ModelUserChannelFilterInput | null > | null,
  or?: Array< ModelUserChannelFilterInput | null > | null,
  not?: ModelUserChannelFilterInput | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  channel_id?: ModelIDInput | null,
  sender_id?: ModelIDInput | null,
  content?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  available_to?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelPaymentIntentFilterInput = {
  id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  extra_payment_contract_id?: ModelIDInput | null,
  extra_offer_id?: ModelIDInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  payout_owing_to_seller_amount?: ModelStringInput | null,
  payout_owing_to_seller_centi_amount?: ModelStringInput | null,
  fee_owing_to_momo_amount?: ModelStringInput | null,
  fee_owing_to_momo_centi_amount?: ModelStringInput | null,
  dreamerly_fee_balance_amount?: ModelStringInput | null,
  dreamerly_fee_balance_centi_amount?: ModelStringInput | null,
  display_fee_owing_to_momo_amount?: ModelStringInput | null,
  display_fee_owing_to_momo_centi_amount?: ModelStringInput | null,
  display_dreamerly_fee_balance_amount?: ModelStringInput | null,
  display_dreamerly_fee_balance_centi_amount?: ModelStringInput | null,
  payment_purpose?: ModelStringInput | null,
  billing_details?: ModelStringInput | null,
  description?: ModelStringInput | null,
  last_payment_error?: ModelStringInput | null,
  next_action?: ModelStringInput | null,
  disputed?: ModelIntInput | null,
  disputed_at?: ModelStringInput | null,
  refunded?: ModelIntInput | null,
  refunded_amount?: ModelStringInput | null,
  refunded_centi_amount?: ModelStringInput | null,
  refunded_at?: ModelStringInput | null,
  status?: ModelStringInput | null,
  payment_provider?: ModelStringInput | null,
  payment_method?: ModelStringInput | null,
  paid_at?: ModelStringInput | null,
  cancelled_at?: ModelStringInput | null,
  momo_trans_id?: ModelStringInput | null,
  momo_request_id?: ModelStringInput | null,
  momo_payment_url?: ModelStringInput | null,
  momo_payment_url_expires_at?: ModelStringInput | null,
  stripe_payment_intent_id?: ModelStringInput | null,
  stripe_payment_method_country?: ModelStringInput | null,
  balance_transaction_id?: ModelIDInput | null,
  and?: Array< ModelPaymentIntentFilterInput | null > | null,
  or?: Array< ModelPaymentIntentFilterInput | null > | null,
  not?: ModelPaymentIntentFilterInput | null,
};

export type ModelPayoutFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  billing_details?: ModelStringInput | null,
  seller_id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  arrival_date_time?: ModelStringInput | null,
  status?: ModelStringInput | null,
  payment_provider?: ModelStringInput | null,
  balance_transaction_id?: ModelIDInput | null,
  request_amount?: ModelStringInput | null,
  request_centi_amount?: ModelStringInput | null,
  request_date_time?: ModelStringInput | null,
  bank_account_id?: ModelIDInput | null,
  and?: Array< ModelPayoutFilterInput | null > | null,
  or?: Array< ModelPayoutFilterInput | null > | null,
  not?: ModelPayoutFilterInput | null,
};

export type ModelRefundFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  payout_owing_to_seller_amount?: ModelStringInput | null,
  payout_owing_to_seller_centi_amount?: ModelStringInput | null,
  fee_owing_to_momo_amount?: ModelStringInput | null,
  fee_owing_to_momo_centi_amount?: ModelStringInput | null,
  dreamerly_fee_balance_amount?: ModelStringInput | null,
  dreamerly_fee_balance_centi_amount?: ModelStringInput | null,
  billing_details?: ModelStringInput | null,
  buyer_id?: ModelIDInput | null,
  seller_id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  payment_intent_id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  reason?: ModelStringInput | null,
  status?: ModelStringInput | null,
  response?: ModelStringInput | null,
  payment_provider?: ModelStringInput | null,
  momo_trans_id?: ModelStringInput | null,
  approved_at?: ModelStringInput | null,
  rejected_at?: ModelStringInput | null,
  refunded_at?: ModelStringInput | null,
  balance_transaction_id?: ModelIDInput | null,
  and?: Array< ModelRefundFilterInput | null > | null,
  or?: Array< ModelRefundFilterInput | null > | null,
  not?: ModelRefundFilterInput | null,
};

export type ModelContractFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  is_private_artwork?: ModelIntInput | null,
  is_comercial_artwork?: ModelIntInput | null,
  file_formats?: ModelStringInput | null,
  description?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  term_of_service?: ModelStringInput | null,
  state_name?: ModelStringInput | null,
  current_milestone_id?: ModelIDInput | null,
  current_milestone_index?: ModelIntInput | null,
  job_id?: ModelIDInput | null,
  proposal_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  seller_id?: ModelIDInput | null,
  and?: Array< ModelContractFilterInput | null > | null,
  or?: Array< ModelContractFilterInput | null > | null,
  not?: ModelContractFilterInput | null,
};

export type ModelContractByBuyerIdStatusPublicationDateTimeCompositeKeyConditionInput = {
  eq?: ModelContractByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
  le?: ModelContractByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
  lt?: ModelContractByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
  ge?: ModelContractByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
  gt?: ModelContractByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
  between?: Array< ModelContractByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelContractByBuyerIdStatusPublicationDateTimeCompositeKeyInput | null,
};

export type ModelContractByBuyerIdStatusPublicationDateTimeCompositeKeyInput = {
  status?: string | null,
  publication_date_time?: string | null,
};

export type ModelContractBySellerIdStatusPublicationDateTimeCompositeKeyConditionInput = {
  eq?: ModelContractBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  le?: ModelContractBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  lt?: ModelContractBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  ge?: ModelContractBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  gt?: ModelContractBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
  between?: Array< ModelContractBySellerIdStatusPublicationDateTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelContractBySellerIdStatusPublicationDateTimeCompositeKeyInput | null,
};

export type ModelContractBySellerIdStatusPublicationDateTimeCompositeKeyInput = {
  status?: string | null,
  publication_date_time?: string | null,
};

export type ModelContractProductFilterInput = {
  id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_display_name?: ModelStringInput | null,
  product_description?: ModelStringInput | null,
  and?: Array< ModelContractProductFilterInput | null > | null,
  or?: Array< ModelContractProductFilterInput | null > | null,
  not?: ModelContractProductFilterInput | null,
};

export type ModelContractProductPriceFilterInput = {
  id?: ModelIDInput | null,
  contract_product_id?: ModelIDInput | null,
  extra_contract_product_id?: ModelIDInput | null,
  price_id?: ModelIDInput | null,
  price_quantity?: ModelIntInput | null,
  price_display_name?: ModelStringInput | null,
  price_description?: ModelStringInput | null,
  price_type?: ModelStringInput | null,
  price_category?: ModelStringInput | null,
  price_fixed_amount?: ModelStringInput | null,
  price_fixed_centi_amount?: ModelStringInput | null,
  price_currency_code?: ModelStringInput | null,
  price_percentage?: ModelIntInput | null,
  and?: Array< ModelContractProductPriceFilterInput | null > | null,
  or?: Array< ModelContractProductPriceFilterInput | null > | null,
  not?: ModelContractProductPriceFilterInput | null,
};

export type ModelExtraOfferFilterInput = {
  id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelExtraOfferFilterInput | null > | null,
  or?: Array< ModelExtraOfferFilterInput | null > | null,
  not?: ModelExtraOfferFilterInput | null,
};

export type ModelExtraOfferPriceFilterInput = {
  id?: ModelIDInput | null,
  extra_offer_id?: ModelIDInput | null,
  price_id?: ModelIDInput | null,
  price_quantity?: ModelIntInput | null,
  price_display_name?: ModelStringInput | null,
  price_description?: ModelStringInput | null,
  price_type?: ModelStringInput | null,
  price_category?: ModelStringInput | null,
  price_fixed_amount?: ModelStringInput | null,
  price_fixed_centi_amount?: ModelStringInput | null,
  price_currency_code?: ModelStringInput | null,
  price_percentage?: ModelIntInput | null,
  and?: Array< ModelExtraOfferPriceFilterInput | null > | null,
  or?: Array< ModelExtraOfferPriceFilterInput | null > | null,
  not?: ModelExtraOfferPriceFilterInput | null,
};

export type ModelMilestoneFilterInput = {
  id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  step_index?: ModelIntInput | null,
  step_name?: ModelStringInput | null,
  deadline_number?: ModelIntInput | null,
  deadline_type?: ModelStringInput | null,
  start_date_time?: ModelStringInput | null,
  end_date_time?: ModelStringInput | null,
  delivery_date_time?: ModelStringInput | null,
  free_revision_count?: ModelIntInput | null,
  current_revision_index?: ModelIntInput | null,
  current_revision_id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  deadline_extensions?: ModelStringInput | null,
  price_percentage?: ModelIntInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelMilestoneFilterInput | null > | null,
  or?: Array< ModelMilestoneFilterInput | null > | null,
  not?: ModelMilestoneFilterInput | null,
};

export type ModelRevisionFilterInput = {
  id?: ModelIDInput | null,
  index?: ModelIntInput | null,
  status?: ModelStringInput | null,
  request_message?: ModelStringInput | null,
  request_rejection_message?: ModelStringInput | null,
  milestone_id?: ModelIDInput | null,
  additional_deadline_number?: ModelIntInput | null,
  additional_deadline_type?: ModelStringInput | null,
  start_date_time?: ModelStringInput | null,
  end_date_time?: ModelStringInput | null,
  delivery_date_time?: ModelStringInput | null,
  deadline_extensions?: ModelStringInput | null,
  and?: Array< ModelRevisionFilterInput | null > | null,
  or?: Array< ModelRevisionFilterInput | null > | null,
  not?: ModelRevisionFilterInput | null,
};

export type ModelContractEventFilterInput = {
  id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  event_id?: ModelIDInput | null,
  contract_status?: ModelStringInput | null,
  contract_amount?: ModelStringInput | null,
  contract_currency?: ModelStringInput | null,
  extra_offer_status?: ModelStringInput | null,
  extra_offer_amount?: ModelStringInput | null,
  extra_offer_currency?: ModelStringInput | null,
  and?: Array< ModelContractEventFilterInput | null > | null,
  or?: Array< ModelContractEventFilterInput | null > | null,
  not?: ModelContractEventFilterInput | null,
};

export type ModelPaymentIntentEventFilterInput = {
  id?: ModelIDInput | null,
  payment_intent_id?: ModelIDInput | null,
  event_id?: ModelIDInput | null,
  payment_intent_status?: ModelStringInput | null,
  payment_intent_amount?: ModelStringInput | null,
  payment_intent_currency?: ModelStringInput | null,
  and?: Array< ModelPaymentIntentEventFilterInput | null > | null,
  or?: Array< ModelPaymentIntentEventFilterInput | null > | null,
  not?: ModelPaymentIntentEventFilterInput | null,
};

export type ModelMilestoneEventFilterInput = {
  id?: ModelIDInput | null,
  milestone_id?: ModelIDInput | null,
  event_id?: ModelIDInput | null,
  milestone_status?: ModelStringInput | null,
  milestone_step_index?: ModelIntInput | null,
  milestone_step_name?: ModelStringInput | null,
  and?: Array< ModelMilestoneEventFilterInput | null > | null,
  or?: Array< ModelMilestoneEventFilterInput | null > | null,
  not?: ModelMilestoneEventFilterInput | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  note?: ModelStringInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
};

export type ModelReviewFilterInput = {
  id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  reviewer_id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  rating?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  type?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  and?: Array< ModelReviewFilterInput | null > | null,
  or?: Array< ModelReviewFilterInput | null > | null,
  not?: ModelReviewFilterInput | null,
};

export type ModelReviewByUserIdTypePublicationDateTimeCompositeKeyConditionInput = {
  eq?: ModelReviewByUserIdTypePublicationDateTimeCompositeKeyInput | null,
  le?: ModelReviewByUserIdTypePublicationDateTimeCompositeKeyInput | null,
  lt?: ModelReviewByUserIdTypePublicationDateTimeCompositeKeyInput | null,
  ge?: ModelReviewByUserIdTypePublicationDateTimeCompositeKeyInput | null,
  gt?: ModelReviewByUserIdTypePublicationDateTimeCompositeKeyInput | null,
  between?: Array< ModelReviewByUserIdTypePublicationDateTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelReviewByUserIdTypePublicationDateTimeCompositeKeyInput | null,
};

export type ModelReviewByUserIdTypePublicationDateTimeCompositeKeyInput = {
  type?: string | null,
  publication_date_time?: string | null,
};

export type ModelReviewByReviewerIdTypePublicationDateTimeCompositeKeyConditionInput = {
  eq?: ModelReviewByReviewerIdTypePublicationDateTimeCompositeKeyInput | null,
  le?: ModelReviewByReviewerIdTypePublicationDateTimeCompositeKeyInput | null,
  lt?: ModelReviewByReviewerIdTypePublicationDateTimeCompositeKeyInput | null,
  ge?: ModelReviewByReviewerIdTypePublicationDateTimeCompositeKeyInput | null,
  gt?: ModelReviewByReviewerIdTypePublicationDateTimeCompositeKeyInput | null,
  between?: Array< ModelReviewByReviewerIdTypePublicationDateTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelReviewByReviewerIdTypePublicationDateTimeCompositeKeyInput | null,
};

export type ModelReviewByReviewerIdTypePublicationDateTimeCompositeKeyInput = {
  type?: string | null,
  publication_date_time?: string | null,
};

export type ModelUserFollowFilterInput = {
  id?: ModelIDInput | null,
  leader_id?: ModelIDInput | null,
  follower_id?: ModelIDInput | null,
  created_at?: ModelStringInput | null,
  updated_at?: ModelStringInput | null,
  and?: Array< ModelUserFollowFilterInput | null > | null,
  or?: Array< ModelUserFollowFilterInput | null > | null,
  not?: ModelUserFollowFilterInput | null,
};

export type ModelDreamerlyBalanceFilterInput = {
  id?: ModelIDInput | null,
  currency_code?: ModelStringInput | null,
  and?: Array< ModelDreamerlyBalanceFilterInput | null > | null,
  or?: Array< ModelDreamerlyBalanceFilterInput | null > | null,
  not?: ModelDreamerlyBalanceFilterInput | null,
};

export type ModelDreamerlyBalanceConnection = {
  __typename: "ModelDreamerlyBalanceConnection",
  items:  Array<DreamerlyBalance | null >,
  nextToken?: string | null,
};

export type ModelBalanceTransferFilterInput = {
  id?: ModelIDInput | null,
  reason?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  dreamerly_fee?: ModelStringInput | null,
  dreamerly_centi_fee?: ModelStringInput | null,
  payment_provider_fee?: ModelStringInput | null,
  payment_provider_centi_fee?: ModelStringInput | null,
  net_amount?: ModelStringInput | null,
  net_centi_amount?: ModelStringInput | null,
  payment_provider?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  note?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  milestone_id?: ModelIDInput | null,
  contract_id?: ModelIDInput | null,
  from_user_id?: ModelIDInput | null,
  to_user_id?: ModelIDInput | null,
  from_dreamerly_balance_id?: ModelIDInput | null,
  to_dreamerly_balance_id?: ModelIDInput | null,
  balance_transaction_id?: ModelIDInput | null,
  and?: Array< ModelBalanceTransferFilterInput | null > | null,
  or?: Array< ModelBalanceTransferFilterInput | null > | null,
  not?: ModelBalanceTransferFilterInput | null,
};

export type ModelPaymentProviderFeePaymentFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  payment_provider?: ModelStringInput | null,
  description?: ModelStringInput | null,
  invoice_number?: ModelStringInput | null,
  from_dreamerly_balance_id?: ModelIDInput | null,
  to_dreamerly_balance_id?: ModelIDInput | null,
  balance_transaction_id?: ModelIDInput | null,
  and?: Array< ModelPaymentProviderFeePaymentFilterInput | null > | null,
  or?: Array< ModelPaymentProviderFeePaymentFilterInput | null > | null,
  not?: ModelPaymentProviderFeePaymentFilterInput | null,
};

export type ModelBalanceTransactionFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  centi_amount?: ModelStringInput | null,
  currency_code?: ModelStringInput | null,
  note?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  user_id?: ModelIDInput | null,
  and?: Array< ModelBalanceTransactionFilterInput | null > | null,
  or?: Array< ModelBalanceTransactionFilterInput | null > | null,
  not?: ModelBalanceTransactionFilterInput | null,
};

export type ModelBalanceTransactionByUserIdTypePublicationDateTimeCompositeKeyConditionInput = {
  eq?: ModelBalanceTransactionByUserIdTypePublicationDateTimeCompositeKeyInput | null,
  le?: ModelBalanceTransactionByUserIdTypePublicationDateTimeCompositeKeyInput | null,
  lt?: ModelBalanceTransactionByUserIdTypePublicationDateTimeCompositeKeyInput | null,
  ge?: ModelBalanceTransactionByUserIdTypePublicationDateTimeCompositeKeyInput | null,
  gt?: ModelBalanceTransactionByUserIdTypePublicationDateTimeCompositeKeyInput | null,
  between?: Array< ModelBalanceTransactionByUserIdTypePublicationDateTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelBalanceTransactionByUserIdTypePublicationDateTimeCompositeKeyInput | null,
};

export type ModelBalanceTransactionByUserIdTypePublicationDateTimeCompositeKeyInput = {
  type?: string | null,
  publication_date_time?: string | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  notification_sender_id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  publication_date_time?: ModelStringInput | null,
  is_read?: ModelIntInput | null,
  is_removed?: ModelIntInput | null,
  notification_type?: ModelStringInput | null,
  underlying_object_id?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationByUserIdIsReadPublicationDateTimeCompositeKeyConditionInput = {
  eq?: ModelNotificationByUserIdIsReadPublicationDateTimeCompositeKeyInput | null,
  le?: ModelNotificationByUserIdIsReadPublicationDateTimeCompositeKeyInput | null,
  lt?: ModelNotificationByUserIdIsReadPublicationDateTimeCompositeKeyInput | null,
  ge?: ModelNotificationByUserIdIsReadPublicationDateTimeCompositeKeyInput | null,
  gt?: ModelNotificationByUserIdIsReadPublicationDateTimeCompositeKeyInput | null,
  between?: Array< ModelNotificationByUserIdIsReadPublicationDateTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelNotificationByUserIdIsReadPublicationDateTimeCompositeKeyInput | null,
};

export type ModelNotificationByUserIdIsReadPublicationDateTimeCompositeKeyInput = {
  is_read?: number | null,
  publication_date_time?: string | null,
};

export type ModelCharacterFilterInput = {
  id?: ModelIDInput | null,
  display_name?: ModelStringInput | null,
  charname?: ModelStringInput | null,
  character_type?: ModelStringInput | null,
  user_id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  strengths?: ModelStringInput | null,
  flaws?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  race?: ModelStringInput | null,
  birth_date?: ModelStringInput | null,
  age?: ModelStringInput | null,
  role?: ModelStringInput | null,
  hobbies?: ModelStringInput | null,
  dislikes?: ModelStringInput | null,
  profile_picture_url?: ModelStringInput | null,
  cover_photo_url?: ModelStringInput | null,
  and?: Array< ModelCharacterFilterInput | null > | null,
  or?: Array< ModelCharacterFilterInput | null > | null,
  not?: ModelCharacterFilterInput | null,
};

export type ModelCharacterItemFilterInput = {
  id?: ModelIDInput | null,
  item_type?: ModelStringInput | null,
  character_id?: ModelIDInput | null,
  artwork_id?: ModelIDInput | null,
  job_id?: ModelIDInput | null,
  and?: Array< ModelCharacterItemFilterInput | null > | null,
  or?: Array< ModelCharacterItemFilterInput | null > | null,
  not?: ModelCharacterItemFilterInput | null,
};

export type ModelSubscriptionTagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tag_name?: ModelSubscriptionStringInput | null,
  display_name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  total_artwork_quantity?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionTagFilterInput | null > | null,
  or?: Array< ModelSubscriptionTagFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionTagItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tag_id?: ModelSubscriptionIDInput | null,
  artwork_id?: ModelSubscriptionIDInput | null,
  tag_type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTagItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionTagItemFilterInput | null > | null,
};

export type ModelSubscriptionArtworkFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cover_image_height?: ModelSubscriptionIntInput | null,
  cover_image_width?: ModelSubscriptionIntInput | null,
  cover_image_src_url?: ModelSubscriptionStringInput | null,
  cover_image_thumbnail_url?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  likes_count?: ModelSubscriptionIntInput | null,
  comments_count?: ModelSubscriptionIntInput | null,
  description?: ModelSubscriptionStringInput | null,
  publication_date_time?: ModelSubscriptionStringInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  buyer_id?: ModelSubscriptionIDInput | null,
  is_removed?: ModelSubscriptionIntInput | null,
  contract_id?: ModelSubscriptionIDInput | null,
  is_buyer_showcase?: ModelSubscriptionIntInput | null,
  is_seller_showcase?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionArtworkFilterInput | null > | null,
  or?: Array< ModelSubscriptionArtworkFilterInput | null > | null,
};

export type ModelSubscriptionProductArtworkFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  product_id?: ModelSubscriptionIDInput | null,
  artwork_id?: ModelSubscriptionIDInput | null,
  is_removed?: ModelSubscriptionIntInput | null,
  index?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionProductArtworkFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductArtworkFilterInput | null > | null,
};

export type ModelSubscriptionCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  display_name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
};

export type ModelSubscriptionImageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  height?: ModelSubscriptionIntInput | null,
  width?: ModelSubscriptionIntInput | null,
  file_name?: ModelSubscriptionStringInput | null,
  src_url?: ModelSubscriptionStringInput | null,
  thumbnail_url?: ModelSubscriptionStringInput | null,
  raw_src_url?: ModelSubscriptionStringInput | null,
  raw_thumbnail_url?: ModelSubscriptionStringInput | null,
  artwork_id?: ModelSubscriptionIDInput | null,
  job_id?: ModelSubscriptionIDInput | null,
  milestone_id?: ModelSubscriptionIDInput | null,
  revision_id?: ModelSubscriptionIDInput | null,
  revision_request_revision_id?: ModelSubscriptionIDInput | null,
  message_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionImageFilterInput | null > | null,
  or?: Array< ModelSubscriptionImageFilterInput | null > | null,
};

export type ModelSubscriptionLikeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  artwork_id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  is_removed?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionLikeFilterInput | null > | null,
  or?: Array< ModelSubscriptionLikeFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  display_name?: ModelSubscriptionStringInput | null,
  first_name?: ModelSubscriptionStringInput | null,
  last_name?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  email_address?: ModelSubscriptionStringInput | null,
  birth_date?: ModelSubscriptionStringInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  country_code?: ModelSubscriptionStringInput | null,
  subscriber_number?: ModelSubscriptionStringInput | null,
  bio?: ModelSubscriptionStringInput | null,
  is_artist?: ModelSubscriptionIntInput | null,
  seller_onboarding_stage?: ModelSubscriptionStringInput | null,
  term_of_service?: ModelSubscriptionStringInput | null,
  cccd?: ModelSubscriptionStringInput | null,
  mst?: ModelSubscriptionStringInput | null,
  cccd_images?: ModelSubscriptionStringInput | null,
  mst_stage?: ModelSubscriptionStringInput | null,
  profile_picture_url?: ModelSubscriptionStringInput | null,
  cover_picture_url?: ModelSubscriptionStringInput | null,
  balance_amount?: ModelSubscriptionStringInput | null,
  balance_centi_amount?: ModelSubscriptionStringInput | null,
  balance_currency_code?: ModelSubscriptionStringInput | null,
  usd_balance_amount?: ModelSubscriptionStringInput | null,
  usd_balance_centi_amount?: ModelSubscriptionStringInput | null,
  contract_paid_quantity?: ModelSubscriptionIntInput | null,
  seller_star_quantity?: ModelSubscriptionIntInput | null,
  seller_review_quantity?: ModelSubscriptionIntInput | null,
  buyer_star_quantity?: ModelSubscriptionIntInput | null,
  buyer_review_quantity?: ModelSubscriptionIntInput | null,
  commision_started_quantity?: ModelSubscriptionIntInput | null,
  commission_completed_quantity?: ModelSubscriptionIntInput | null,
  commission_completed_on_time_quantity?: ModelSubscriptionIntInput | null,
  commission_cancelled_seller_fault_quantity?: ModelSubscriptionIntInput | null,
  commission_cancelled_buyer_fault_quantity?: ModelSubscriptionIntInput | null,
  commission_cancelled_mutually_quantity?: ModelSubscriptionIntInput | null,
  commission_cumulative_late_seconds?: ModelSubscriptionIntInput | null,
  commission_max_late_seconds?: ModelSubscriptionIntInput | null,
  device_tokens?: ModelSubscriptionStringInput | null,
  is_selling?: ModelSubscriptionIntInput | null,
  is_selling_abroad?: ModelSubscriptionIntInput | null,
  last_time_open_list_notification?: ModelSubscriptionStringInput | null,
  discount_code?: ModelSubscriptionStringInput | null,
  follower_quantity?: ModelSubscriptionIntInput | null,
  leader_quantity?: ModelSubscriptionIntInput | null,
  has_unread_commissions?: ModelSubscriptionIntInput | null,
  has_unread_jobs?: ModelSubscriptionIntInput | null,
  message_cumulative_response_seconds?: ModelSubscriptionIntInput | null,
  message_response_on_time_quantity?: ModelSubscriptionIntInput | null,
  message_response_late_quantity?: ModelSubscriptionIntInput | null,
  bank_name?: ModelSubscriptionStringInput | null,
  account_holder_name?: ModelSubscriptionStringInput | null,
  account_last_four?: ModelSubscriptionStringInput | null,
  address_country_code?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  address_line1?: ModelSubscriptionStringInput | null,
  additional_info?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionBankAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  bank_name?: ModelSubscriptionStringInput | null,
  account_holder_name?: ModelSubscriptionStringInput | null,
  account_number?: ModelSubscriptionStringInput | null,
  currency_code?: ModelSubscriptionStringInput | null,
  country_code?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBankAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionBankAccountFilterInput | null > | null,
};

export type ModelSubscriptionPushTokenFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  push_token?: ModelSubscriptionStringInput | null,
  device_type?: ModelSubscriptionStringInput | null,
  last_used_at?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPushTokenFilterInput | null > | null,
  or?: Array< ModelSubscriptionPushTokenFilterInput | null > | null,
};

export type ModelSubscriptionUserBlockFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_give_block_id?: ModelSubscriptionIDInput | null,
  user_receive_block_id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserBlockFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserBlockFilterInput | null > | null,
};

export type ModelSubscriptionProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  artist_id?: ModelSubscriptionIDInput | null,
  display_name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  category_id?: ModelSubscriptionIDInput | null,
  min_price_amount?: ModelSubscriptionStringInput | null,
  max_price_amount?: ModelSubscriptionStringInput | null,
  currency_code?: ModelSubscriptionStringInput | null,
  product_star_quantity?: ModelSubscriptionIntInput | null,
  product_review_quantity?: ModelSubscriptionIntInput | null,
  commission_paid_quantity?: ModelSubscriptionIntInput | null,
  dreambox_response_time?: ModelSubscriptionIntInput | null,
  dreambox_complete_time?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductFilterInput | null > | null,
};

export type ModelSubscriptionPriceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  product_id?: ModelSubscriptionIDInput | null,
  display_name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  fixed_amount?: ModelSubscriptionStringInput | null,
  fixed_centi_amount?: ModelSubscriptionStringInput | null,
  index?: ModelSubscriptionIntInput | null,
  min_amount?: ModelSubscriptionStringInput | null,
  min_centi_amount?: ModelSubscriptionStringInput | null,
  max_amount?: ModelSubscriptionStringInput | null,
  max_centi_amount?: ModelSubscriptionStringInput | null,
  currency_code?: ModelSubscriptionStringInput | null,
  percentage?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionPriceFilterInput | null > | null,
  or?: Array< ModelSubscriptionPriceFilterInput | null > | null,
};

export type ModelSubscriptionJobFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  min_budget?: ModelSubscriptionStringInput | null,
  min_centi_budget?: ModelSubscriptionStringInput | null,
  max_budget?: ModelSubscriptionStringInput | null,
  max_centi_budget?: ModelSubscriptionStringInput | null,
  currency_code?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  is_exclusive?: ModelSubscriptionIntInput | null,
  publication_date_time?: ModelSubscriptionStringInput | null,
  deadline_date_time?: ModelSubscriptionStringInput | null,
  expiration_date_time?: ModelSubscriptionStringInput | null,
  is_comercial_artwork?: ModelSubscriptionIntInput | null,
  is_dreambox?: ModelSubscriptionIntInput | null,
  is_private_artwork?: ModelSubscriptionIntInput | null,
  file_format?: ModelSubscriptionStringInput | null,
  buyer_id?: ModelSubscriptionIDInput | null,
  seller_id?: ModelSubscriptionIDInput | null,
  contacted_seller_ids?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionJobFilterInput | null > | null,
  or?: Array< ModelSubscriptionJobFilterInput | null > | null,
};

export type ModelSubscriptionProposalFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  job_id?: ModelSubscriptionIDInput | null,
  seller_id?: ModelSubscriptionIDInput | null,
  seller_comment?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  publication_date_time?: ModelSubscriptionStringInput | null,
  proposal_type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProposalFilterInput | null > | null,
  or?: Array< ModelSubscriptionProposalFilterInput | null > | null,
};

export type ModelSubscriptionChannelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChannelFilterInput | null > | null,
  or?: Array< ModelSubscriptionChannelFilterInput | null > | null,
};

export type ModelSubscriptionUserChannelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  channel_id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  update_date_time?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  next_time_should_receive_an_email?: ModelSubscriptionStringInput | null,
  next_time_should_receive_a_late_response?: ModelSubscriptionStringInput | null,
  request_start_date_time?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserChannelFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserChannelFilterInput | null > | null,
};

export type ModelSubscriptionMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  channel_id?: ModelSubscriptionIDInput | null,
  sender_id?: ModelSubscriptionIDInput | null,
  content?: ModelSubscriptionStringInput | null,
  image_url?: ModelSubscriptionStringInput | null,
  publication_date_time?: ModelSubscriptionStringInput | null,
  available_to?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMessageFilterInput | null > | null,
  or?: Array< ModelSubscriptionMessageFilterInput | null > | null,
};

export type ModelSubscriptionPaymentIntentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  buyer_id?: ModelSubscriptionIDInput | null,
  contract_id?: ModelSubscriptionIDInput | null,
  extra_payment_contract_id?: ModelSubscriptionIDInput | null,
  extra_offer_id?: ModelSubscriptionIDInput | null,
  amount?: ModelSubscriptionStringInput | null,
  centi_amount?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  payout_owing_to_seller_amount?: ModelSubscriptionStringInput | null,
  payout_owing_to_seller_centi_amount?: ModelSubscriptionStringInput | null,
  fee_owing_to_momo_amount?: ModelSubscriptionStringInput | null,
  fee_owing_to_momo_centi_amount?: ModelSubscriptionStringInput | null,
  dreamerly_fee_balance_amount?: ModelSubscriptionStringInput | null,
  dreamerly_fee_balance_centi_amount?: ModelSubscriptionStringInput | null,
  display_fee_owing_to_momo_amount?: ModelSubscriptionStringInput | null,
  display_fee_owing_to_momo_centi_amount?: ModelSubscriptionStringInput | null,
  display_dreamerly_fee_balance_amount?: ModelSubscriptionStringInput | null,
  display_dreamerly_fee_balance_centi_amount?: ModelSubscriptionStringInput | null,
  payment_purpose?: ModelSubscriptionStringInput | null,
  billing_details?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  last_payment_error?: ModelSubscriptionStringInput | null,
  next_action?: ModelSubscriptionStringInput | null,
  disputed?: ModelSubscriptionIntInput | null,
  disputed_at?: ModelSubscriptionStringInput | null,
  refunded?: ModelSubscriptionIntInput | null,
  refunded_amount?: ModelSubscriptionStringInput | null,
  refunded_centi_amount?: ModelSubscriptionStringInput | null,
  refunded_at?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  payment_provider?: ModelSubscriptionStringInput | null,
  payment_method?: ModelSubscriptionStringInput | null,
  paid_at?: ModelSubscriptionStringInput | null,
  cancelled_at?: ModelSubscriptionStringInput | null,
  momo_trans_id?: ModelSubscriptionStringInput | null,
  momo_request_id?: ModelSubscriptionStringInput | null,
  momo_payment_url?: ModelSubscriptionStringInput | null,
  momo_payment_url_expires_at?: ModelSubscriptionStringInput | null,
  stripe_payment_intent_id?: ModelSubscriptionStringInput | null,
  stripe_payment_method_country?: ModelSubscriptionStringInput | null,
  balance_transaction_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPaymentIntentFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaymentIntentFilterInput | null > | null,
};

export type ModelSubscriptionPayoutFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  amount?: ModelSubscriptionStringInput | null,
  centi_amount?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  billing_details?: ModelSubscriptionStringInput | null,
  seller_id?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  arrival_date_time?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  payment_provider?: ModelSubscriptionStringInput | null,
  balance_transaction_id?: ModelSubscriptionIDInput | null,
  request_amount?: ModelSubscriptionStringInput | null,
  request_centi_amount?: ModelSubscriptionStringInput | null,
  request_date_time?: ModelSubscriptionStringInput | null,
  bank_account_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPayoutFilterInput | null > | null,
  or?: Array< ModelSubscriptionPayoutFilterInput | null > | null,
};

export type ModelSubscriptionRefundFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  amount?: ModelSubscriptionStringInput | null,
  centi_amount?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  payout_owing_to_seller_amount?: ModelSubscriptionStringInput | null,
  payout_owing_to_seller_centi_amount?: ModelSubscriptionStringInput | null,
  fee_owing_to_momo_amount?: ModelSubscriptionStringInput | null,
  fee_owing_to_momo_centi_amount?: ModelSubscriptionStringInput | null,
  dreamerly_fee_balance_amount?: ModelSubscriptionStringInput | null,
  dreamerly_fee_balance_centi_amount?: ModelSubscriptionStringInput | null,
  billing_details?: ModelSubscriptionStringInput | null,
  buyer_id?: ModelSubscriptionIDInput | null,
  seller_id?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  payment_intent_id?: ModelSubscriptionIDInput | null,
  contract_id?: ModelSubscriptionIDInput | null,
  reason?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  response?: ModelSubscriptionStringInput | null,
  payment_provider?: ModelSubscriptionStringInput | null,
  momo_trans_id?: ModelSubscriptionStringInput | null,
  approved_at?: ModelSubscriptionStringInput | null,
  rejected_at?: ModelSubscriptionStringInput | null,
  refunded_at?: ModelSubscriptionStringInput | null,
  balance_transaction_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionRefundFilterInput | null > | null,
  or?: Array< ModelSubscriptionRefundFilterInput | null > | null,
};

export type ModelSubscriptionContractFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  publication_date_time?: ModelSubscriptionStringInput | null,
  is_private_artwork?: ModelSubscriptionIntInput | null,
  is_comercial_artwork?: ModelSubscriptionIntInput | null,
  file_formats?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionStringInput | null,
  centi_amount?: ModelSubscriptionStringInput | null,
  currency_code?: ModelSubscriptionStringInput | null,
  term_of_service?: ModelSubscriptionStringInput | null,
  state_name?: ModelSubscriptionStringInput | null,
  current_milestone_id?: ModelSubscriptionIDInput | null,
  current_milestone_index?: ModelSubscriptionIntInput | null,
  job_id?: ModelSubscriptionIDInput | null,
  proposal_id?: ModelSubscriptionIDInput | null,
  buyer_id?: ModelSubscriptionIDInput | null,
  seller_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionContractFilterInput | null > | null,
  or?: Array< ModelSubscriptionContractFilterInput | null > | null,
};

export type ModelSubscriptionContractProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  contract_id?: ModelSubscriptionIDInput | null,
  product_id?: ModelSubscriptionIDInput | null,
  product_display_name?: ModelSubscriptionStringInput | null,
  product_description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContractProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionContractProductFilterInput | null > | null,
};

export type ModelSubscriptionContractProductPriceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  contract_product_id?: ModelSubscriptionIDInput | null,
  extra_contract_product_id?: ModelSubscriptionIDInput | null,
  price_id?: ModelSubscriptionIDInput | null,
  price_quantity?: ModelSubscriptionIntInput | null,
  price_display_name?: ModelSubscriptionStringInput | null,
  price_description?: ModelSubscriptionStringInput | null,
  price_type?: ModelSubscriptionStringInput | null,
  price_category?: ModelSubscriptionStringInput | null,
  price_fixed_amount?: ModelSubscriptionStringInput | null,
  price_fixed_centi_amount?: ModelSubscriptionStringInput | null,
  price_currency_code?: ModelSubscriptionStringInput | null,
  price_percentage?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionContractProductPriceFilterInput | null > | null,
  or?: Array< ModelSubscriptionContractProductPriceFilterInput | null > | null,
};

export type ModelSubscriptionExtraOfferFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  contract_id?: ModelSubscriptionIDInput | null,
  amount?: ModelSubscriptionStringInput | null,
  centi_amount?: ModelSubscriptionStringInput | null,
  currency_code?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionExtraOfferFilterInput | null > | null,
  or?: Array< ModelSubscriptionExtraOfferFilterInput | null > | null,
};

export type ModelSubscriptionExtraOfferPriceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  extra_offer_id?: ModelSubscriptionIDInput | null,
  price_id?: ModelSubscriptionIDInput | null,
  price_quantity?: ModelSubscriptionIntInput | null,
  price_display_name?: ModelSubscriptionStringInput | null,
  price_description?: ModelSubscriptionStringInput | null,
  price_type?: ModelSubscriptionStringInput | null,
  price_category?: ModelSubscriptionStringInput | null,
  price_fixed_amount?: ModelSubscriptionStringInput | null,
  price_fixed_centi_amount?: ModelSubscriptionStringInput | null,
  price_currency_code?: ModelSubscriptionStringInput | null,
  price_percentage?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionExtraOfferPriceFilterInput | null > | null,
  or?: Array< ModelSubscriptionExtraOfferPriceFilterInput | null > | null,
};

export type ModelSubscriptionMilestoneFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  contract_id?: ModelSubscriptionIDInput | null,
  step_index?: ModelSubscriptionIntInput | null,
  step_name?: ModelSubscriptionStringInput | null,
  deadline_number?: ModelSubscriptionIntInput | null,
  deadline_type?: ModelSubscriptionStringInput | null,
  start_date_time?: ModelSubscriptionStringInput | null,
  end_date_time?: ModelSubscriptionStringInput | null,
  delivery_date_time?: ModelSubscriptionStringInput | null,
  free_revision_count?: ModelSubscriptionIntInput | null,
  current_revision_index?: ModelSubscriptionIntInput | null,
  current_revision_id?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  deadline_extensions?: ModelSubscriptionStringInput | null,
  price_percentage?: ModelSubscriptionIntInput | null,
  amount?: ModelSubscriptionStringInput | null,
  centi_amount?: ModelSubscriptionStringInput | null,
  currency_code?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMilestoneFilterInput | null > | null,
  or?: Array< ModelSubscriptionMilestoneFilterInput | null > | null,
};

export type ModelSubscriptionRevisionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  index?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  request_message?: ModelSubscriptionStringInput | null,
  request_rejection_message?: ModelSubscriptionStringInput | null,
  milestone_id?: ModelSubscriptionIDInput | null,
  additional_deadline_number?: ModelSubscriptionIntInput | null,
  additional_deadline_type?: ModelSubscriptionStringInput | null,
  start_date_time?: ModelSubscriptionStringInput | null,
  end_date_time?: ModelSubscriptionStringInput | null,
  delivery_date_time?: ModelSubscriptionStringInput | null,
  deadline_extensions?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRevisionFilterInput | null > | null,
  or?: Array< ModelSubscriptionRevisionFilterInput | null > | null,
};

export type ModelSubscriptionContractEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  contract_id?: ModelSubscriptionIDInput | null,
  event_id?: ModelSubscriptionIDInput | null,
  contract_status?: ModelSubscriptionStringInput | null,
  contract_amount?: ModelSubscriptionStringInput | null,
  contract_currency?: ModelSubscriptionStringInput | null,
  extra_offer_status?: ModelSubscriptionStringInput | null,
  extra_offer_amount?: ModelSubscriptionStringInput | null,
  extra_offer_currency?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContractEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionContractEventFilterInput | null > | null,
};

export type ModelSubscriptionPaymentIntentEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  payment_intent_id?: ModelSubscriptionIDInput | null,
  event_id?: ModelSubscriptionIDInput | null,
  payment_intent_status?: ModelSubscriptionStringInput | null,
  payment_intent_amount?: ModelSubscriptionStringInput | null,
  payment_intent_currency?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPaymentIntentEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaymentIntentEventFilterInput | null > | null,
};

export type ModelSubscriptionMilestoneEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  milestone_id?: ModelSubscriptionIDInput | null,
  event_id?: ModelSubscriptionIDInput | null,
  milestone_status?: ModelSubscriptionStringInput | null,
  milestone_step_index?: ModelSubscriptionIntInput | null,
  milestone_step_name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMilestoneEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionMilestoneEventFilterInput | null > | null,
};

export type ModelSubscriptionEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionEventFilterInput | null > | null,
};

export type ModelSubscriptionReviewFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  reviewer_id?: ModelSubscriptionIDInput | null,
  contract_id?: ModelSubscriptionIDInput | null,
  rating?: ModelSubscriptionIntInput | null,
  comment?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  publication_date_time?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionReviewFilterInput | null > | null,
};

export type ModelSubscriptionUserFollowFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  leader_id?: ModelSubscriptionIDInput | null,
  follower_id?: ModelSubscriptionIDInput | null,
  created_at?: ModelSubscriptionStringInput | null,
  updated_at?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFollowFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFollowFilterInput | null > | null,
};

export type ModelSubscriptionDreamerlyBalanceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  currency_code?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDreamerlyBalanceFilterInput | null > | null,
  or?: Array< ModelSubscriptionDreamerlyBalanceFilterInput | null > | null,
};

export type ModelSubscriptionBalanceTransferFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  reason?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionStringInput | null,
  centi_amount?: ModelSubscriptionStringInput | null,
  dreamerly_fee?: ModelSubscriptionStringInput | null,
  dreamerly_centi_fee?: ModelSubscriptionStringInput | null,
  payment_provider_fee?: ModelSubscriptionStringInput | null,
  payment_provider_centi_fee?: ModelSubscriptionStringInput | null,
  net_amount?: ModelSubscriptionStringInput | null,
  net_centi_amount?: ModelSubscriptionStringInput | null,
  payment_provider?: ModelSubscriptionStringInput | null,
  currency_code?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  publication_date_time?: ModelSubscriptionStringInput | null,
  milestone_id?: ModelSubscriptionIDInput | null,
  contract_id?: ModelSubscriptionIDInput | null,
  from_user_id?: ModelSubscriptionIDInput | null,
  to_user_id?: ModelSubscriptionIDInput | null,
  from_dreamerly_balance_id?: ModelSubscriptionIDInput | null,
  to_dreamerly_balance_id?: ModelSubscriptionIDInput | null,
  balance_transaction_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionBalanceTransferFilterInput | null > | null,
  or?: Array< ModelSubscriptionBalanceTransferFilterInput | null > | null,
};

export type ModelSubscriptionPaymentProviderFeePaymentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  amount?: ModelSubscriptionStringInput | null,
  centi_amount?: ModelSubscriptionStringInput | null,
  currency_code?: ModelSubscriptionStringInput | null,
  publication_date_time?: ModelSubscriptionStringInput | null,
  payment_provider?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  invoice_number?: ModelSubscriptionStringInput | null,
  from_dreamerly_balance_id?: ModelSubscriptionIDInput | null,
  to_dreamerly_balance_id?: ModelSubscriptionIDInput | null,
  balance_transaction_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPaymentProviderFeePaymentFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaymentProviderFeePaymentFilterInput | null > | null,
};

export type ModelSubscriptionBalanceTransactionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionStringInput | null,
  centi_amount?: ModelSubscriptionStringInput | null,
  currency_code?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  publication_date_time?: ModelSubscriptionStringInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionBalanceTransactionFilterInput | null > | null,
  or?: Array< ModelSubscriptionBalanceTransactionFilterInput | null > | null,
};

export type ModelSubscriptionNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  notification_sender_id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  body?: ModelSubscriptionStringInput | null,
  publication_date_time?: ModelSubscriptionStringInput | null,
  is_read?: ModelSubscriptionIntInput | null,
  is_removed?: ModelSubscriptionIntInput | null,
  notification_type?: ModelSubscriptionStringInput | null,
  underlying_object_id?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
};

export type ModelSubscriptionCharacterFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  display_name?: ModelSubscriptionStringInput | null,
  charname?: ModelSubscriptionStringInput | null,
  character_type?: ModelSubscriptionStringInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  strengths?: ModelSubscriptionStringInput | null,
  flaws?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  race?: ModelSubscriptionStringInput | null,
  birth_date?: ModelSubscriptionStringInput | null,
  age?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  hobbies?: ModelSubscriptionStringInput | null,
  dislikes?: ModelSubscriptionStringInput | null,
  profile_picture_url?: ModelSubscriptionStringInput | null,
  cover_photo_url?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCharacterFilterInput | null > | null,
  or?: Array< ModelSubscriptionCharacterFilterInput | null > | null,
};

export type ModelSubscriptionCharacterItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  item_type?: ModelSubscriptionStringInput | null,
  character_id?: ModelSubscriptionIDInput | null,
  artwork_id?: ModelSubscriptionIDInput | null,
  job_id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCharacterItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionCharacterItemFilterInput | null > | null,
};

export type CreateTagMutationVariables = {
  input: CreateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type CreateTagMutation = {
  createTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    display_name?: string | null,
    status?: string | null,
    total_artwork_quantity?: number | null,
    tag_artwork_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateTagMutationVariables = {
  input: UpdateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type UpdateTagMutation = {
  updateTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    display_name?: string | null,
    status?: string | null,
    total_artwork_quantity?: number | null,
    tag_artwork_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteTagMutationVariables = {
  input: DeleteTagInput,
  condition?: ModelTagConditionInput | null,
};

export type DeleteTagMutation = {
  deleteTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    display_name?: string | null,
    status?: string | null,
    total_artwork_quantity?: number | null,
    tag_artwork_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateTagItemMutationVariables = {
  input: CreateTagItemInput,
  condition?: ModelTagItemConditionInput | null,
};

export type CreateTagItemMutation = {
  createTagItem?:  {
    __typename: "TagItem",
    id: string,
    tag_id: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      tag_name?: string | null,
      display_name?: string | null,
      status?: string | null,
      total_artwork_quantity?: number | null,
      tag_artwork_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    tag_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateTagItemMutationVariables = {
  input: UpdateTagItemInput,
  condition?: ModelTagItemConditionInput | null,
};

export type UpdateTagItemMutation = {
  updateTagItem?:  {
    __typename: "TagItem",
    id: string,
    tag_id: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      tag_name?: string | null,
      display_name?: string | null,
      status?: string | null,
      total_artwork_quantity?: number | null,
      tag_artwork_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    tag_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteTagItemMutationVariables = {
  input: DeleteTagItemInput,
  condition?: ModelTagItemConditionInput | null,
};

export type DeleteTagItemMutation = {
  deleteTagItem?:  {
    __typename: "TagItem",
    id: string,
    tag_id: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      tag_name?: string | null,
      display_name?: string | null,
      status?: string | null,
      total_artwork_quantity?: number | null,
      tag_artwork_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    tag_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateArtworkMutationVariables = {
  input: CreateArtworkInput,
  condition?: ModelArtworkConditionInput | null,
};

export type CreateArtworkMutation = {
  createArtwork?:  {
    __typename: "Artwork",
    id: string,
    cover_image_height?: number | null,
    cover_image_width?: number | null,
    cover_image_src_url?: string | null,
    cover_image_thumbnail_url?: string | null,
    title?: string | null,
    likes_count?: number | null,
    comments_count?: number | null,
    description?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_buyer_showcase?: number | null,
    is_seller_showcase?: number | null,
    tag_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateArtworkMutationVariables = {
  input: UpdateArtworkInput,
  condition?: ModelArtworkConditionInput | null,
};

export type UpdateArtworkMutation = {
  updateArtwork?:  {
    __typename: "Artwork",
    id: string,
    cover_image_height?: number | null,
    cover_image_width?: number | null,
    cover_image_src_url?: string | null,
    cover_image_thumbnail_url?: string | null,
    title?: string | null,
    likes_count?: number | null,
    comments_count?: number | null,
    description?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_buyer_showcase?: number | null,
    is_seller_showcase?: number | null,
    tag_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteArtworkMutationVariables = {
  input: DeleteArtworkInput,
  condition?: ModelArtworkConditionInput | null,
};

export type DeleteArtworkMutation = {
  deleteArtwork?:  {
    __typename: "Artwork",
    id: string,
    cover_image_height?: number | null,
    cover_image_width?: number | null,
    cover_image_src_url?: string | null,
    cover_image_thumbnail_url?: string | null,
    title?: string | null,
    likes_count?: number | null,
    comments_count?: number | null,
    description?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_buyer_showcase?: number | null,
    is_seller_showcase?: number | null,
    tag_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateProductArtworkMutationVariables = {
  input: CreateProductArtworkInput,
  condition?: ModelProductArtworkConditionInput | null,
};

export type CreateProductArtworkMutation = {
  createProductArtwork?:  {
    __typename: "ProductArtwork",
    id: string,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    index?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateProductArtworkMutationVariables = {
  input: UpdateProductArtworkInput,
  condition?: ModelProductArtworkConditionInput | null,
};

export type UpdateProductArtworkMutation = {
  updateProductArtwork?:  {
    __typename: "ProductArtwork",
    id: string,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    index?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteProductArtworkMutationVariables = {
  input: DeleteProductArtworkInput,
  condition?: ModelProductArtworkConditionInput | null,
};

export type DeleteProductArtworkMutation = {
  deleteProductArtwork?:  {
    __typename: "ProductArtwork",
    id: string,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    index?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    display_name?: string | null,
    status?: string | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    display_name?: string | null,
    status?: string | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    display_name?: string | null,
    status?: string | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateImageMutationVariables = {
  input: CreateImageInput,
  condition?: ModelImageConditionInput | null,
};

export type CreateImageMutation = {
  createImage?:  {
    __typename: "Image",
    id: string,
    height?: number | null,
    width?: number | null,
    file_name?: string | null,
    src_url?: string | null,
    thumbnail_url?: string | null,
    raw_src_url?: string | null,
    raw_thumbnail_url?: string | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_id?: string | null,
    revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_request_revision_id?: string | null,
    revision_request_revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    message_id?: string | null,
    message?:  {
      __typename: "Message",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      sender_id: string,
      sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      content?: string | null,
      image_url?: string | null,
      publication_date_time?: string | null,
      available_to?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateImageMutationVariables = {
  input: UpdateImageInput,
  condition?: ModelImageConditionInput | null,
};

export type UpdateImageMutation = {
  updateImage?:  {
    __typename: "Image",
    id: string,
    height?: number | null,
    width?: number | null,
    file_name?: string | null,
    src_url?: string | null,
    thumbnail_url?: string | null,
    raw_src_url?: string | null,
    raw_thumbnail_url?: string | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_id?: string | null,
    revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_request_revision_id?: string | null,
    revision_request_revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    message_id?: string | null,
    message?:  {
      __typename: "Message",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      sender_id: string,
      sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      content?: string | null,
      image_url?: string | null,
      publication_date_time?: string | null,
      available_to?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteImageMutationVariables = {
  input: DeleteImageInput,
  condition?: ModelImageConditionInput | null,
};

export type DeleteImageMutation = {
  deleteImage?:  {
    __typename: "Image",
    id: string,
    height?: number | null,
    width?: number | null,
    file_name?: string | null,
    src_url?: string | null,
    thumbnail_url?: string | null,
    raw_src_url?: string | null,
    raw_thumbnail_url?: string | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_id?: string | null,
    revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_request_revision_id?: string | null,
    revision_request_revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    message_id?: string | null,
    message?:  {
      __typename: "Message",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      sender_id: string,
      sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      content?: string | null,
      image_url?: string | null,
      publication_date_time?: string | null,
      available_to?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateLikeMutationVariables = {
  input: CreateLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type CreateLikeMutation = {
  createLike?:  {
    __typename: "Like",
    id: string,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateLikeMutationVariables = {
  input: UpdateLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type UpdateLikeMutation = {
  updateLike?:  {
    __typename: "Like",
    id: string,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteLikeMutationVariables = {
  input: DeleteLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type DeleteLikeMutation = {
  deleteLike?:  {
    __typename: "Like",
    id: string,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    display_name?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    username?: string | null,
    email_address?: string | null,
    birth_date?: string | null,
    phone_number?: string | null,
    country_code?: string | null,
    subscriber_number?: string | null,
    bio?: string | null,
    is_artist?: number | null,
    seller_onboarding_stage?: string | null,
    buyer_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    term_of_service?: string | null,
    cccd?: string | null,
    mst?: string | null,
    cccd_images?: Array< string | null > | null,
    mst_stage?: string | null,
    seller_proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    profile_picture_url?: string | null,
    cover_picture_url?: string | null,
    balance_amount?: string | null,
    balance_centi_amount?: string | null,
    balance_currency_code?: string | null,
    usd_balance_amount?: string | null,
    usd_balance_centi_amount?: string | null,
    contract_paid_quantity?: number | null,
    seller_star_quantity?: number | null,
    seller_review_quantity?: number | null,
    buyer_star_quantity?: number | null,
    buyer_review_quantity?: number | null,
    commision_started_quantity?: number | null,
    commission_completed_quantity?: number | null,
    commission_completed_on_time_quantity?: number | null,
    commission_cancelled_seller_fault_quantity?: number | null,
    commission_cancelled_buyer_fault_quantity?: number | null,
    commission_cancelled_mutually_quantity?: number | null,
    commission_cumulative_late_seconds?: number | null,
    commission_max_late_seconds?: number | null,
    device_tokens?: Array< string | null > | null,
    is_selling?: number | null,
    is_selling_abroad?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    last_time_open_list_notification?: string | null,
    discount_code?: string | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    selling_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buying_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    given_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transactions?:  {
      __typename: "ModelBalanceTransactionConnection",
      items:  Array< {
        __typename: "BalanceTransaction",
        id: string,
        type?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    follower_quantity?: number | null,
    leader_quantity?: number | null,
    followers?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    leaders?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notification_senders?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    push_tokens?:  {
      __typename: "ModelPushTokenConnection",
      items:  Array< {
        __typename: "PushToken",
        id: string,
        user_id: string,
        push_token?: string | null,
        device_type?: string | null,
        last_used_at?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    has_unread_commissions?: number | null,
    has_unread_jobs?: number | null,
    message_cumulative_response_seconds?: number | null,
    message_response_on_time_quantity?: number | null,
    message_response_late_quantity?: number | null,
    characters?:  {
      __typename: "ModelCharacterConnection",
      items:  Array< {
        __typename: "Character",
        id: string,
        display_name?: string | null,
        charname?: string | null,
        character_type?: string | null,
        user_id?: string | null,
        description?: string | null,
        strengths?: string | null,
        flaws?: string | null,
        gender?: string | null,
        race?: string | null,
        birth_date?: string | null,
        age?: string | null,
        role?: string | null,
        hobbies?: string | null,
        dislikes?: string | null,
        profile_picture_url?: string | null,
        cover_photo_url?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_last_four?: string | null,
    address_country_code?: string | null,
    city?: string | null,
    address_line1?: string | null,
    additional_info?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    display_name?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    username?: string | null,
    email_address?: string | null,
    birth_date?: string | null,
    phone_number?: string | null,
    country_code?: string | null,
    subscriber_number?: string | null,
    bio?: string | null,
    is_artist?: number | null,
    seller_onboarding_stage?: string | null,
    buyer_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    term_of_service?: string | null,
    cccd?: string | null,
    mst?: string | null,
    cccd_images?: Array< string | null > | null,
    mst_stage?: string | null,
    seller_proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    profile_picture_url?: string | null,
    cover_picture_url?: string | null,
    balance_amount?: string | null,
    balance_centi_amount?: string | null,
    balance_currency_code?: string | null,
    usd_balance_amount?: string | null,
    usd_balance_centi_amount?: string | null,
    contract_paid_quantity?: number | null,
    seller_star_quantity?: number | null,
    seller_review_quantity?: number | null,
    buyer_star_quantity?: number | null,
    buyer_review_quantity?: number | null,
    commision_started_quantity?: number | null,
    commission_completed_quantity?: number | null,
    commission_completed_on_time_quantity?: number | null,
    commission_cancelled_seller_fault_quantity?: number | null,
    commission_cancelled_buyer_fault_quantity?: number | null,
    commission_cancelled_mutually_quantity?: number | null,
    commission_cumulative_late_seconds?: number | null,
    commission_max_late_seconds?: number | null,
    device_tokens?: Array< string | null > | null,
    is_selling?: number | null,
    is_selling_abroad?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    last_time_open_list_notification?: string | null,
    discount_code?: string | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    selling_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buying_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    given_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transactions?:  {
      __typename: "ModelBalanceTransactionConnection",
      items:  Array< {
        __typename: "BalanceTransaction",
        id: string,
        type?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    follower_quantity?: number | null,
    leader_quantity?: number | null,
    followers?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    leaders?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notification_senders?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    push_tokens?:  {
      __typename: "ModelPushTokenConnection",
      items:  Array< {
        __typename: "PushToken",
        id: string,
        user_id: string,
        push_token?: string | null,
        device_type?: string | null,
        last_used_at?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    has_unread_commissions?: number | null,
    has_unread_jobs?: number | null,
    message_cumulative_response_seconds?: number | null,
    message_response_on_time_quantity?: number | null,
    message_response_late_quantity?: number | null,
    characters?:  {
      __typename: "ModelCharacterConnection",
      items:  Array< {
        __typename: "Character",
        id: string,
        display_name?: string | null,
        charname?: string | null,
        character_type?: string | null,
        user_id?: string | null,
        description?: string | null,
        strengths?: string | null,
        flaws?: string | null,
        gender?: string | null,
        race?: string | null,
        birth_date?: string | null,
        age?: string | null,
        role?: string | null,
        hobbies?: string | null,
        dislikes?: string | null,
        profile_picture_url?: string | null,
        cover_photo_url?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_last_four?: string | null,
    address_country_code?: string | null,
    city?: string | null,
    address_line1?: string | null,
    additional_info?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    display_name?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    username?: string | null,
    email_address?: string | null,
    birth_date?: string | null,
    phone_number?: string | null,
    country_code?: string | null,
    subscriber_number?: string | null,
    bio?: string | null,
    is_artist?: number | null,
    seller_onboarding_stage?: string | null,
    buyer_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    term_of_service?: string | null,
    cccd?: string | null,
    mst?: string | null,
    cccd_images?: Array< string | null > | null,
    mst_stage?: string | null,
    seller_proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    profile_picture_url?: string | null,
    cover_picture_url?: string | null,
    balance_amount?: string | null,
    balance_centi_amount?: string | null,
    balance_currency_code?: string | null,
    usd_balance_amount?: string | null,
    usd_balance_centi_amount?: string | null,
    contract_paid_quantity?: number | null,
    seller_star_quantity?: number | null,
    seller_review_quantity?: number | null,
    buyer_star_quantity?: number | null,
    buyer_review_quantity?: number | null,
    commision_started_quantity?: number | null,
    commission_completed_quantity?: number | null,
    commission_completed_on_time_quantity?: number | null,
    commission_cancelled_seller_fault_quantity?: number | null,
    commission_cancelled_buyer_fault_quantity?: number | null,
    commission_cancelled_mutually_quantity?: number | null,
    commission_cumulative_late_seconds?: number | null,
    commission_max_late_seconds?: number | null,
    device_tokens?: Array< string | null > | null,
    is_selling?: number | null,
    is_selling_abroad?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    last_time_open_list_notification?: string | null,
    discount_code?: string | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    selling_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buying_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    given_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transactions?:  {
      __typename: "ModelBalanceTransactionConnection",
      items:  Array< {
        __typename: "BalanceTransaction",
        id: string,
        type?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    follower_quantity?: number | null,
    leader_quantity?: number | null,
    followers?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    leaders?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notification_senders?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    push_tokens?:  {
      __typename: "ModelPushTokenConnection",
      items:  Array< {
        __typename: "PushToken",
        id: string,
        user_id: string,
        push_token?: string | null,
        device_type?: string | null,
        last_used_at?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    has_unread_commissions?: number | null,
    has_unread_jobs?: number | null,
    message_cumulative_response_seconds?: number | null,
    message_response_on_time_quantity?: number | null,
    message_response_late_quantity?: number | null,
    characters?:  {
      __typename: "ModelCharacterConnection",
      items:  Array< {
        __typename: "Character",
        id: string,
        display_name?: string | null,
        charname?: string | null,
        character_type?: string | null,
        user_id?: string | null,
        description?: string | null,
        strengths?: string | null,
        flaws?: string | null,
        gender?: string | null,
        race?: string | null,
        birth_date?: string | null,
        age?: string | null,
        role?: string | null,
        hobbies?: string | null,
        dislikes?: string | null,
        profile_picture_url?: string | null,
        cover_photo_url?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_last_four?: string | null,
    address_country_code?: string | null,
    city?: string | null,
    address_line1?: string | null,
    additional_info?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateBankAccountMutationVariables = {
  input: CreateBankAccountInput,
  condition?: ModelBankAccountConditionInput | null,
};

export type CreateBankAccountMutation = {
  createBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    user_id: string,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_number?: string | null,
    currency_code?: string | null,
    country_code?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateBankAccountMutationVariables = {
  input: UpdateBankAccountInput,
  condition?: ModelBankAccountConditionInput | null,
};

export type UpdateBankAccountMutation = {
  updateBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    user_id: string,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_number?: string | null,
    currency_code?: string | null,
    country_code?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteBankAccountMutationVariables = {
  input: DeleteBankAccountInput,
  condition?: ModelBankAccountConditionInput | null,
};

export type DeleteBankAccountMutation = {
  deleteBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    user_id: string,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_number?: string | null,
    currency_code?: string | null,
    country_code?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreatePushTokenMutationVariables = {
  input: CreatePushTokenInput,
  condition?: ModelPushTokenConditionInput | null,
};

export type CreatePushTokenMutation = {
  createPushToken?:  {
    __typename: "PushToken",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    push_token?: string | null,
    device_type?: string | null,
    last_used_at?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdatePushTokenMutationVariables = {
  input: UpdatePushTokenInput,
  condition?: ModelPushTokenConditionInput | null,
};

export type UpdatePushTokenMutation = {
  updatePushToken?:  {
    __typename: "PushToken",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    push_token?: string | null,
    device_type?: string | null,
    last_used_at?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeletePushTokenMutationVariables = {
  input: DeletePushTokenInput,
  condition?: ModelPushTokenConditionInput | null,
};

export type DeletePushTokenMutation = {
  deletePushToken?:  {
    __typename: "PushToken",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    push_token?: string | null,
    device_type?: string | null,
    last_used_at?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateUserBlockMutationVariables = {
  input: CreateUserBlockInput,
  condition?: ModelUserBlockConditionInput | null,
};

export type CreateUserBlockMutation = {
  createUserBlock?:  {
    __typename: "UserBlock",
    id: string,
    user_give_block_id?: string | null,
    user_receive_block_id?: string | null,
    type?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateUserBlockMutationVariables = {
  input: UpdateUserBlockInput,
  condition?: ModelUserBlockConditionInput | null,
};

export type UpdateUserBlockMutation = {
  updateUserBlock?:  {
    __typename: "UserBlock",
    id: string,
    user_give_block_id?: string | null,
    user_receive_block_id?: string | null,
    type?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteUserBlockMutationVariables = {
  input: DeleteUserBlockInput,
  condition?: ModelUserBlockConditionInput | null,
};

export type DeleteUserBlockMutation = {
  deleteUserBlock?:  {
    __typename: "UserBlock",
    id: string,
    user_give_block_id?: string | null,
    user_receive_block_id?: string | null,
    type?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    artist_id: string,
    artist?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name: string,
    description?: string | null,
    status: string,
    prices?:  {
      __typename: "ModelPriceConnection",
      items:  Array< {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    slug?: string | null,
    category_id?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      display_name?: string | null,
      status?: string | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    min_price_amount?: string | null,
    max_price_amount?: string | null,
    currency_code?: string | null,
    product_star_quantity?: number | null,
    product_review_quantity?: number | null,
    commission_paid_quantity?: number | null,
    artist_other_artworks?:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    reviews?:  Array< {
      __typename: "Review",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      reviewer_id: string,
      reviewer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      rating?: number | null,
      comment?: string | null,
      type?: string | null,
      publication_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    dreambox_response_time?: number | null,
    dreambox_complete_time?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    artist_id: string,
    artist?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name: string,
    description?: string | null,
    status: string,
    prices?:  {
      __typename: "ModelPriceConnection",
      items:  Array< {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    slug?: string | null,
    category_id?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      display_name?: string | null,
      status?: string | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    min_price_amount?: string | null,
    max_price_amount?: string | null,
    currency_code?: string | null,
    product_star_quantity?: number | null,
    product_review_quantity?: number | null,
    commission_paid_quantity?: number | null,
    artist_other_artworks?:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    reviews?:  Array< {
      __typename: "Review",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      reviewer_id: string,
      reviewer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      rating?: number | null,
      comment?: string | null,
      type?: string | null,
      publication_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    dreambox_response_time?: number | null,
    dreambox_complete_time?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    artist_id: string,
    artist?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name: string,
    description?: string | null,
    status: string,
    prices?:  {
      __typename: "ModelPriceConnection",
      items:  Array< {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    slug?: string | null,
    category_id?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      display_name?: string | null,
      status?: string | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    min_price_amount?: string | null,
    max_price_amount?: string | null,
    currency_code?: string | null,
    product_star_quantity?: number | null,
    product_review_quantity?: number | null,
    commission_paid_quantity?: number | null,
    artist_other_artworks?:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    reviews?:  Array< {
      __typename: "Review",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      reviewer_id: string,
      reviewer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      rating?: number | null,
      comment?: string | null,
      type?: string | null,
      publication_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    dreambox_response_time?: number | null,
    dreambox_complete_time?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreatePriceMutationVariables = {
  input: CreatePriceInput,
  condition?: ModelPriceConditionInput | null,
};

export type CreatePriceMutation = {
  createPrice?:  {
    __typename: "Price",
    id: string,
    product_id: string,
    Product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name?: string | null,
    description?: string | null,
    status: string,
    type?: string | null,
    category?: string | null,
    fixed_amount?: string | null,
    fixed_centi_amount?: string | null,
    index?: number | null,
    min_amount?: string | null,
    min_centi_amount?: string | null,
    max_amount?: string | null,
    max_centi_amount?: string | null,
    currency_code?: string | null,
    percentage?: number | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdatePriceMutationVariables = {
  input: UpdatePriceInput,
  condition?: ModelPriceConditionInput | null,
};

export type UpdatePriceMutation = {
  updatePrice?:  {
    __typename: "Price",
    id: string,
    product_id: string,
    Product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name?: string | null,
    description?: string | null,
    status: string,
    type?: string | null,
    category?: string | null,
    fixed_amount?: string | null,
    fixed_centi_amount?: string | null,
    index?: number | null,
    min_amount?: string | null,
    min_centi_amount?: string | null,
    max_amount?: string | null,
    max_centi_amount?: string | null,
    currency_code?: string | null,
    percentage?: number | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeletePriceMutationVariables = {
  input: DeletePriceInput,
  condition?: ModelPriceConditionInput | null,
};

export type DeletePriceMutation = {
  deletePrice?:  {
    __typename: "Price",
    id: string,
    product_id: string,
    Product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name?: string | null,
    description?: string | null,
    status: string,
    type?: string | null,
    category?: string | null,
    fixed_amount?: string | null,
    fixed_centi_amount?: string | null,
    index?: number | null,
    min_amount?: string | null,
    min_centi_amount?: string | null,
    max_amount?: string | null,
    max_centi_amount?: string | null,
    currency_code?: string | null,
    percentage?: number | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateJobMutationVariables = {
  input: CreateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type CreateJobMutation = {
  createJob?:  {
    __typename: "Job",
    id: string,
    title?: string | null,
    description?: string | null,
    min_budget?: string | null,
    min_centi_budget?: string | null,
    max_budget?: string | null,
    max_centi_budget?: string | null,
    currency_code?: string | null,
    status?: string | null,
    is_exclusive?: number | null,
    publication_date_time?: string | null,
    deadline_date_time?: string | null,
    expiration_date_time?: string | null,
    is_comercial_artwork?: number | null,
    is_dreambox?: number | null,
    is_private_artwork?: number | null,
    file_format?: Array< string | null > | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacted_seller_ids?: Array< string | null > | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateJobMutationVariables = {
  input: UpdateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type UpdateJobMutation = {
  updateJob?:  {
    __typename: "Job",
    id: string,
    title?: string | null,
    description?: string | null,
    min_budget?: string | null,
    min_centi_budget?: string | null,
    max_budget?: string | null,
    max_centi_budget?: string | null,
    currency_code?: string | null,
    status?: string | null,
    is_exclusive?: number | null,
    publication_date_time?: string | null,
    deadline_date_time?: string | null,
    expiration_date_time?: string | null,
    is_comercial_artwork?: number | null,
    is_dreambox?: number | null,
    is_private_artwork?: number | null,
    file_format?: Array< string | null > | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacted_seller_ids?: Array< string | null > | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteJobMutationVariables = {
  input: DeleteJobInput,
  condition?: ModelJobConditionInput | null,
};

export type DeleteJobMutation = {
  deleteJob?:  {
    __typename: "Job",
    id: string,
    title?: string | null,
    description?: string | null,
    min_budget?: string | null,
    min_centi_budget?: string | null,
    max_budget?: string | null,
    max_centi_budget?: string | null,
    currency_code?: string | null,
    status?: string | null,
    is_exclusive?: number | null,
    publication_date_time?: string | null,
    deadline_date_time?: string | null,
    expiration_date_time?: string | null,
    is_comercial_artwork?: number | null,
    is_dreambox?: number | null,
    is_private_artwork?: number | null,
    file_format?: Array< string | null > | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacted_seller_ids?: Array< string | null > | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateProposalMutationVariables = {
  input: CreateProposalInput,
  condition?: ModelProposalConditionInput | null,
};

export type CreateProposalMutation = {
  createProposal?:  {
    __typename: "Proposal",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_comment?: string | null,
    status?: string | null,
    publication_date_time?: string | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    proposal_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateProposalMutationVariables = {
  input: UpdateProposalInput,
  condition?: ModelProposalConditionInput | null,
};

export type UpdateProposalMutation = {
  updateProposal?:  {
    __typename: "Proposal",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_comment?: string | null,
    status?: string | null,
    publication_date_time?: string | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    proposal_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteProposalMutationVariables = {
  input: DeleteProposalInput,
  condition?: ModelProposalConditionInput | null,
};

export type DeleteProposalMutation = {
  deleteProposal?:  {
    __typename: "Proposal",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_comment?: string | null,
    status?: string | null,
    publication_date_time?: string | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    proposal_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateChannelMutationVariables = {
  input: CreateChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type CreateChannelMutation = {
  createChannel?:  {
    __typename: "Channel",
    id: string,
    name?: string | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateChannelMutationVariables = {
  input: UpdateChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type UpdateChannelMutation = {
  updateChannel?:  {
    __typename: "Channel",
    id: string,
    name?: string | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteChannelMutationVariables = {
  input: DeleteChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type DeleteChannelMutation = {
  deleteChannel?:  {
    __typename: "Channel",
    id: string,
    name?: string | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateUserChannelMutationVariables = {
  input: CreateUserChannelInput,
  condition?: ModelUserChannelConditionInput | null,
};

export type CreateUserChannelMutation = {
  createUserChannel?:  {
    __typename: "UserChannel",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    update_date_time?: string | null,
    status?: string | null,
    next_time_should_receive_an_email?: string | null,
    next_time_should_receive_a_late_response?: string | null,
    request_start_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateUserChannelMutationVariables = {
  input: UpdateUserChannelInput,
  condition?: ModelUserChannelConditionInput | null,
};

export type UpdateUserChannelMutation = {
  updateUserChannel?:  {
    __typename: "UserChannel",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    update_date_time?: string | null,
    status?: string | null,
    next_time_should_receive_an_email?: string | null,
    next_time_should_receive_a_late_response?: string | null,
    request_start_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteUserChannelMutationVariables = {
  input: DeleteUserChannelInput,
  condition?: ModelUserChannelConditionInput | null,
};

export type DeleteUserChannelMutation = {
  deleteUserChannel?:  {
    __typename: "UserChannel",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    update_date_time?: string | null,
    status?: string | null,
    next_time_should_receive_an_email?: string | null,
    next_time_should_receive_a_late_response?: string | null,
    request_start_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    sender_id: string,
    sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    content?: string | null,
    image_url?: string | null,
    publication_date_time?: string | null,
    available_to?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    sender_id: string,
    sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    content?: string | null,
    image_url?: string | null,
    publication_date_time?: string | null,
    available_to?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    sender_id: string,
    sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    content?: string | null,
    image_url?: string | null,
    publication_date_time?: string | null,
    available_to?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreatePaymentIntentMutationVariables = {
  input: CreatePaymentIntentInput,
  condition?: ModelPaymentIntentConditionInput | null,
};

export type CreatePaymentIntentMutation = {
  createPaymentIntent?:  {
    __typename: "PaymentIntent",
    id: string,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_payment_contract_id: string,
    extra_payment_contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    display_fee_owing_to_momo_amount?: string | null,
    display_fee_owing_to_momo_centi_amount?: string | null,
    display_dreamerly_fee_balance_amount?: string | null,
    display_dreamerly_fee_balance_centi_amount?: string | null,
    payment_purpose?: string | null,
    billing_details?: string | null,
    description?: string | null,
    last_payment_error?: string | null,
    next_action?: string | null,
    disputed?: number | null,
    disputed_at?: string | null,
    refunded?: number | null,
    refunded_amount?: string | null,
    refunded_centi_amount?: string | null,
    refunded_at?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    payment_method?: string | null,
    paid_at?: string | null,
    cancelled_at?: string | null,
    momo_trans_id?: string | null,
    momo_request_id?: string | null,
    momo_payment_url?: string | null,
    momo_payment_url_expires_at?: string | null,
    stripe_payment_intent_id?: string | null,
    stripe_payment_method_country?: string | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdatePaymentIntentMutationVariables = {
  input: UpdatePaymentIntentInput,
  condition?: ModelPaymentIntentConditionInput | null,
};

export type UpdatePaymentIntentMutation = {
  updatePaymentIntent?:  {
    __typename: "PaymentIntent",
    id: string,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_payment_contract_id: string,
    extra_payment_contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    display_fee_owing_to_momo_amount?: string | null,
    display_fee_owing_to_momo_centi_amount?: string | null,
    display_dreamerly_fee_balance_amount?: string | null,
    display_dreamerly_fee_balance_centi_amount?: string | null,
    payment_purpose?: string | null,
    billing_details?: string | null,
    description?: string | null,
    last_payment_error?: string | null,
    next_action?: string | null,
    disputed?: number | null,
    disputed_at?: string | null,
    refunded?: number | null,
    refunded_amount?: string | null,
    refunded_centi_amount?: string | null,
    refunded_at?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    payment_method?: string | null,
    paid_at?: string | null,
    cancelled_at?: string | null,
    momo_trans_id?: string | null,
    momo_request_id?: string | null,
    momo_payment_url?: string | null,
    momo_payment_url_expires_at?: string | null,
    stripe_payment_intent_id?: string | null,
    stripe_payment_method_country?: string | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeletePaymentIntentMutationVariables = {
  input: DeletePaymentIntentInput,
  condition?: ModelPaymentIntentConditionInput | null,
};

export type DeletePaymentIntentMutation = {
  deletePaymentIntent?:  {
    __typename: "PaymentIntent",
    id: string,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_payment_contract_id: string,
    extra_payment_contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    display_fee_owing_to_momo_amount?: string | null,
    display_fee_owing_to_momo_centi_amount?: string | null,
    display_dreamerly_fee_balance_amount?: string | null,
    display_dreamerly_fee_balance_centi_amount?: string | null,
    payment_purpose?: string | null,
    billing_details?: string | null,
    description?: string | null,
    last_payment_error?: string | null,
    next_action?: string | null,
    disputed?: number | null,
    disputed_at?: string | null,
    refunded?: number | null,
    refunded_amount?: string | null,
    refunded_centi_amount?: string | null,
    refunded_at?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    payment_method?: string | null,
    paid_at?: string | null,
    cancelled_at?: string | null,
    momo_trans_id?: string | null,
    momo_request_id?: string | null,
    momo_payment_url?: string | null,
    momo_payment_url_expires_at?: string | null,
    stripe_payment_intent_id?: string | null,
    stripe_payment_method_country?: string | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreatePayoutMutationVariables = {
  input: CreatePayoutInput,
  condition?: ModelPayoutConditionInput | null,
};

export type CreatePayoutMutation = {
  createPayout?:  {
    __typename: "Payout",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    billing_details?: string | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    arrival_date_time?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    balance_transaction_id?: string | null,
    request_amount?: string | null,
    request_centi_amount?: string | null,
    request_date_time?: string | null,
    bank_account_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdatePayoutMutationVariables = {
  input: UpdatePayoutInput,
  condition?: ModelPayoutConditionInput | null,
};

export type UpdatePayoutMutation = {
  updatePayout?:  {
    __typename: "Payout",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    billing_details?: string | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    arrival_date_time?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    balance_transaction_id?: string | null,
    request_amount?: string | null,
    request_centi_amount?: string | null,
    request_date_time?: string | null,
    bank_account_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeletePayoutMutationVariables = {
  input: DeletePayoutInput,
  condition?: ModelPayoutConditionInput | null,
};

export type DeletePayoutMutation = {
  deletePayout?:  {
    __typename: "Payout",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    billing_details?: string | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    arrival_date_time?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    balance_transaction_id?: string | null,
    request_amount?: string | null,
    request_centi_amount?: string | null,
    request_date_time?: string | null,
    bank_account_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateRefundMutationVariables = {
  input: CreateRefundInput,
  condition?: ModelRefundConditionInput | null,
};

export type CreateRefundMutation = {
  createRefund?:  {
    __typename: "Refund",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    billing_details?: string | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    reason?: string | null,
    status?: string | null,
    response?: string | null,
    payment_provider?: string | null,
    momo_trans_id?: string | null,
    approved_at?: string | null,
    rejected_at?: string | null,
    refunded_at?: string | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateRefundMutationVariables = {
  input: UpdateRefundInput,
  condition?: ModelRefundConditionInput | null,
};

export type UpdateRefundMutation = {
  updateRefund?:  {
    __typename: "Refund",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    billing_details?: string | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    reason?: string | null,
    status?: string | null,
    response?: string | null,
    payment_provider?: string | null,
    momo_trans_id?: string | null,
    approved_at?: string | null,
    rejected_at?: string | null,
    refunded_at?: string | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteRefundMutationVariables = {
  input: DeleteRefundInput,
  condition?: ModelRefundConditionInput | null,
};

export type DeleteRefundMutation = {
  deleteRefund?:  {
    __typename: "Refund",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    billing_details?: string | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    reason?: string | null,
    status?: string | null,
    response?: string | null,
    payment_provider?: string | null,
    momo_trans_id?: string | null,
    approved_at?: string | null,
    rejected_at?: string | null,
    refunded_at?: string | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateContractMutationVariables = {
  input: CreateContractInput,
  condition?: ModelContractConditionInput | null,
};

export type CreateContractMutation = {
  createContract?:  {
    __typename: "Contract",
    id: string,
    status?: string | null,
    publication_date_time?: string | null,
    is_private_artwork?: number | null,
    is_comercial_artwork?: number | null,
    file_formats?: Array< string | null > | null,
    description?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    term_of_service?: string | null,
    state_name?: string | null,
    current_milestone_id?: string | null,
    current_milestone_index?: number | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposal_id: string,
    proposal?:  {
      __typename: "Proposal",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_comment?: string | null,
      status?: string | null,
      publication_date_time?: string | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      proposal_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_payment_payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestones?:  {
      __typename: "ModelMilestoneConnection",
      items:  Array< {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offers?:  {
      __typename: "ModelExtraOfferConnection",
      items:  Array< {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateContractMutationVariables = {
  input: UpdateContractInput,
  condition?: ModelContractConditionInput | null,
};

export type UpdateContractMutation = {
  updateContract?:  {
    __typename: "Contract",
    id: string,
    status?: string | null,
    publication_date_time?: string | null,
    is_private_artwork?: number | null,
    is_comercial_artwork?: number | null,
    file_formats?: Array< string | null > | null,
    description?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    term_of_service?: string | null,
    state_name?: string | null,
    current_milestone_id?: string | null,
    current_milestone_index?: number | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposal_id: string,
    proposal?:  {
      __typename: "Proposal",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_comment?: string | null,
      status?: string | null,
      publication_date_time?: string | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      proposal_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_payment_payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestones?:  {
      __typename: "ModelMilestoneConnection",
      items:  Array< {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offers?:  {
      __typename: "ModelExtraOfferConnection",
      items:  Array< {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteContractMutationVariables = {
  input: DeleteContractInput,
  condition?: ModelContractConditionInput | null,
};

export type DeleteContractMutation = {
  deleteContract?:  {
    __typename: "Contract",
    id: string,
    status?: string | null,
    publication_date_time?: string | null,
    is_private_artwork?: number | null,
    is_comercial_artwork?: number | null,
    file_formats?: Array< string | null > | null,
    description?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    term_of_service?: string | null,
    state_name?: string | null,
    current_milestone_id?: string | null,
    current_milestone_index?: number | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposal_id: string,
    proposal?:  {
      __typename: "Proposal",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_comment?: string | null,
      status?: string | null,
      publication_date_time?: string | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      proposal_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_payment_payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestones?:  {
      __typename: "ModelMilestoneConnection",
      items:  Array< {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offers?:  {
      __typename: "ModelExtraOfferConnection",
      items:  Array< {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateContractProductMutationVariables = {
  input: CreateContractProductInput,
  condition?: ModelContractProductConditionInput | null,
};

export type CreateContractProductMutation = {
  createContractProduct?:  {
    __typename: "ContractProduct",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_display_name?: string | null,
    product_description?: string | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateContractProductMutationVariables = {
  input: UpdateContractProductInput,
  condition?: ModelContractProductConditionInput | null,
};

export type UpdateContractProductMutation = {
  updateContractProduct?:  {
    __typename: "ContractProduct",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_display_name?: string | null,
    product_description?: string | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteContractProductMutationVariables = {
  input: DeleteContractProductInput,
  condition?: ModelContractProductConditionInput | null,
};

export type DeleteContractProductMutation = {
  deleteContractProduct?:  {
    __typename: "ContractProduct",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_display_name?: string | null,
    product_description?: string | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateContractProductPriceMutationVariables = {
  input: CreateContractProductPriceInput,
  condition?: ModelContractProductPriceConditionInput | null,
};

export type CreateContractProductPriceMutation = {
  createContractProductPrice?:  {
    __typename: "ContractProductPrice",
    id: string,
    contract_product_id: string,
    contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_contract_product_id: string,
    extra_contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity?: number | null,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateContractProductPriceMutationVariables = {
  input: UpdateContractProductPriceInput,
  condition?: ModelContractProductPriceConditionInput | null,
};

export type UpdateContractProductPriceMutation = {
  updateContractProductPrice?:  {
    __typename: "ContractProductPrice",
    id: string,
    contract_product_id: string,
    contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_contract_product_id: string,
    extra_contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity?: number | null,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteContractProductPriceMutationVariables = {
  input: DeleteContractProductPriceInput,
  condition?: ModelContractProductPriceConditionInput | null,
};

export type DeleteContractProductPriceMutation = {
  deleteContractProductPrice?:  {
    __typename: "ContractProductPrice",
    id: string,
    contract_product_id: string,
    contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_contract_product_id: string,
    extra_contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity?: number | null,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateExtraOfferMutationVariables = {
  input: CreateExtraOfferInput,
  condition?: ModelExtraOfferConditionInput | null,
};

export type CreateExtraOfferMutation = {
  createExtraOffer?:  {
    __typename: "ExtraOffer",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateExtraOfferMutationVariables = {
  input: UpdateExtraOfferInput,
  condition?: ModelExtraOfferConditionInput | null,
};

export type UpdateExtraOfferMutation = {
  updateExtraOffer?:  {
    __typename: "ExtraOffer",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteExtraOfferMutationVariables = {
  input: DeleteExtraOfferInput,
  condition?: ModelExtraOfferConditionInput | null,
};

export type DeleteExtraOfferMutation = {
  deleteExtraOffer?:  {
    __typename: "ExtraOffer",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateExtraOfferPriceMutationVariables = {
  input: CreateExtraOfferPriceInput,
  condition?: ModelExtraOfferPriceConditionInput | null,
};

export type CreateExtraOfferPriceMutation = {
  createExtraOfferPrice?:  {
    __typename: "ExtraOfferPrice",
    id: string,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity: number,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateExtraOfferPriceMutationVariables = {
  input: UpdateExtraOfferPriceInput,
  condition?: ModelExtraOfferPriceConditionInput | null,
};

export type UpdateExtraOfferPriceMutation = {
  updateExtraOfferPrice?:  {
    __typename: "ExtraOfferPrice",
    id: string,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity: number,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteExtraOfferPriceMutationVariables = {
  input: DeleteExtraOfferPriceInput,
  condition?: ModelExtraOfferPriceConditionInput | null,
};

export type DeleteExtraOfferPriceMutation = {
  deleteExtraOfferPrice?:  {
    __typename: "ExtraOfferPrice",
    id: string,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity: number,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateMilestoneMutationVariables = {
  input: CreateMilestoneInput,
  condition?: ModelMilestoneConditionInput | null,
};

export type CreateMilestoneMutation = {
  createMilestone?:  {
    __typename: "Milestone",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    step_index?: number | null,
    step_name?: string | null,
    deadline_number?: number | null,
    deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    delivery_date_time?: string | null,
    free_revision_count?: number | null,
    current_revision_index?: number | null,
    current_revision_id?: string | null,
    description?: string | null,
    deadline_extensions?: string | null,
    price_percentage?: number | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    revisions?:  {
      __typename: "ModelRevisionConnection",
      items:  Array< {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateMilestoneMutationVariables = {
  input: UpdateMilestoneInput,
  condition?: ModelMilestoneConditionInput | null,
};

export type UpdateMilestoneMutation = {
  updateMilestone?:  {
    __typename: "Milestone",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    step_index?: number | null,
    step_name?: string | null,
    deadline_number?: number | null,
    deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    delivery_date_time?: string | null,
    free_revision_count?: number | null,
    current_revision_index?: number | null,
    current_revision_id?: string | null,
    description?: string | null,
    deadline_extensions?: string | null,
    price_percentage?: number | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    revisions?:  {
      __typename: "ModelRevisionConnection",
      items:  Array< {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteMilestoneMutationVariables = {
  input: DeleteMilestoneInput,
  condition?: ModelMilestoneConditionInput | null,
};

export type DeleteMilestoneMutation = {
  deleteMilestone?:  {
    __typename: "Milestone",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    step_index?: number | null,
    step_name?: string | null,
    deadline_number?: number | null,
    deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    delivery_date_time?: string | null,
    free_revision_count?: number | null,
    current_revision_index?: number | null,
    current_revision_id?: string | null,
    description?: string | null,
    deadline_extensions?: string | null,
    price_percentage?: number | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    revisions?:  {
      __typename: "ModelRevisionConnection",
      items:  Array< {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateRevisionMutationVariables = {
  input: CreateRevisionInput,
  condition?: ModelRevisionConditionInput | null,
};

export type CreateRevisionMutation = {
  createRevision?:  {
    __typename: "Revision",
    id: string,
    index?: number | null,
    status?: string | null,
    request_message?: string | null,
    request_rejection_message?: string | null,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    additional_deadline_number?: number | null,
    additional_deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    request_revision_images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    delivery_date_time?: string | null,
    deadline_extensions?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateRevisionMutationVariables = {
  input: UpdateRevisionInput,
  condition?: ModelRevisionConditionInput | null,
};

export type UpdateRevisionMutation = {
  updateRevision?:  {
    __typename: "Revision",
    id: string,
    index?: number | null,
    status?: string | null,
    request_message?: string | null,
    request_rejection_message?: string | null,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    additional_deadline_number?: number | null,
    additional_deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    request_revision_images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    delivery_date_time?: string | null,
    deadline_extensions?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteRevisionMutationVariables = {
  input: DeleteRevisionInput,
  condition?: ModelRevisionConditionInput | null,
};

export type DeleteRevisionMutation = {
  deleteRevision?:  {
    __typename: "Revision",
    id: string,
    index?: number | null,
    status?: string | null,
    request_message?: string | null,
    request_rejection_message?: string | null,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    additional_deadline_number?: number | null,
    additional_deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    request_revision_images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    delivery_date_time?: string | null,
    deadline_extensions?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateContractEventMutationVariables = {
  input: CreateContractEventInput,
  condition?: ModelContractEventConditionInput | null,
};

export type CreateContractEventMutation = {
  createContractEvent?:  {
    __typename: "ContractEvent",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_status?: string | null,
    contract_amount?: string | null,
    contract_currency?: string | null,
    extra_offer_status?: string | null,
    extra_offer_amount?: string | null,
    extra_offer_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateContractEventMutationVariables = {
  input: UpdateContractEventInput,
  condition?: ModelContractEventConditionInput | null,
};

export type UpdateContractEventMutation = {
  updateContractEvent?:  {
    __typename: "ContractEvent",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_status?: string | null,
    contract_amount?: string | null,
    contract_currency?: string | null,
    extra_offer_status?: string | null,
    extra_offer_amount?: string | null,
    extra_offer_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteContractEventMutationVariables = {
  input: DeleteContractEventInput,
  condition?: ModelContractEventConditionInput | null,
};

export type DeleteContractEventMutation = {
  deleteContractEvent?:  {
    __typename: "ContractEvent",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_status?: string | null,
    contract_amount?: string | null,
    contract_currency?: string | null,
    extra_offer_status?: string | null,
    extra_offer_amount?: string | null,
    extra_offer_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreatePaymentIntentEventMutationVariables = {
  input: CreatePaymentIntentEventInput,
  condition?: ModelPaymentIntentEventConditionInput | null,
};

export type CreatePaymentIntentEventMutation = {
  createPaymentIntentEvent?:  {
    __typename: "PaymentIntentEvent",
    id: string,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    payment_intent_status?: string | null,
    payment_intent_amount?: string | null,
    payment_intent_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdatePaymentIntentEventMutationVariables = {
  input: UpdatePaymentIntentEventInput,
  condition?: ModelPaymentIntentEventConditionInput | null,
};

export type UpdatePaymentIntentEventMutation = {
  updatePaymentIntentEvent?:  {
    __typename: "PaymentIntentEvent",
    id: string,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    payment_intent_status?: string | null,
    payment_intent_amount?: string | null,
    payment_intent_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeletePaymentIntentEventMutationVariables = {
  input: DeletePaymentIntentEventInput,
  condition?: ModelPaymentIntentEventConditionInput | null,
};

export type DeletePaymentIntentEventMutation = {
  deletePaymentIntentEvent?:  {
    __typename: "PaymentIntentEvent",
    id: string,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    payment_intent_status?: string | null,
    payment_intent_amount?: string | null,
    payment_intent_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateMilestoneEventMutationVariables = {
  input: CreateMilestoneEventInput,
  condition?: ModelMilestoneEventConditionInput | null,
};

export type CreateMilestoneEventMutation = {
  createMilestoneEvent?:  {
    __typename: "MilestoneEvent",
    id: string,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_status?: string | null,
    milestone_step_index?: number | null,
    milestone_step_name?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateMilestoneEventMutationVariables = {
  input: UpdateMilestoneEventInput,
  condition?: ModelMilestoneEventConditionInput | null,
};

export type UpdateMilestoneEventMutation = {
  updateMilestoneEvent?:  {
    __typename: "MilestoneEvent",
    id: string,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_status?: string | null,
    milestone_step_index?: number | null,
    milestone_step_name?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteMilestoneEventMutationVariables = {
  input: DeleteMilestoneEventInput,
  condition?: ModelMilestoneEventConditionInput | null,
};

export type DeleteMilestoneEventMutation = {
  deleteMilestoneEvent?:  {
    __typename: "MilestoneEvent",
    id: string,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_status?: string | null,
    milestone_step_index?: number | null,
    milestone_step_name?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    type?: string | null,
    note?: string | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    type?: string | null,
    note?: string | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    type?: string | null,
    note?: string | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    reviewer_id: string,
    reviewer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    rating?: number | null,
    comment?: string | null,
    type?: string | null,
    publication_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input: UpdateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    reviewer_id: string,
    reviewer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    rating?: number | null,
    comment?: string | null,
    type?: string | null,
    publication_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteReviewMutationVariables = {
  input: DeleteReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    reviewer_id: string,
    reviewer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    rating?: number | null,
    comment?: string | null,
    type?: string | null,
    publication_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateUserFollowMutationVariables = {
  input: CreateUserFollowInput,
  condition?: ModelUserFollowConditionInput | null,
};

export type CreateUserFollowMutation = {
  createUserFollow?:  {
    __typename: "UserFollow",
    id: string,
    leader_id: string,
    follower_id: string,
    leader?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    follower?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateUserFollowMutationVariables = {
  input: UpdateUserFollowInput,
  condition?: ModelUserFollowConditionInput | null,
};

export type UpdateUserFollowMutation = {
  updateUserFollow?:  {
    __typename: "UserFollow",
    id: string,
    leader_id: string,
    follower_id: string,
    leader?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    follower?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteUserFollowMutationVariables = {
  input: DeleteUserFollowInput,
  condition?: ModelUserFollowConditionInput | null,
};

export type DeleteUserFollowMutation = {
  deleteUserFollow?:  {
    __typename: "UserFollow",
    id: string,
    leader_id: string,
    follower_id: string,
    leader?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    follower?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateDreamerlyBalanceMutationVariables = {
  input: CreateDreamerlyBalanceInput,
  condition?: ModelDreamerlyBalanceConditionInput | null,
};

export type CreateDreamerlyBalanceMutation = {
  createDreamerlyBalance?:  {
    __typename: "DreamerlyBalance",
    id: string,
    currency_code?: string | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateDreamerlyBalanceMutationVariables = {
  input: UpdateDreamerlyBalanceInput,
  condition?: ModelDreamerlyBalanceConditionInput | null,
};

export type UpdateDreamerlyBalanceMutation = {
  updateDreamerlyBalance?:  {
    __typename: "DreamerlyBalance",
    id: string,
    currency_code?: string | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteDreamerlyBalanceMutationVariables = {
  input: DeleteDreamerlyBalanceInput,
  condition?: ModelDreamerlyBalanceConditionInput | null,
};

export type DeleteDreamerlyBalanceMutation = {
  deleteDreamerlyBalance?:  {
    __typename: "DreamerlyBalance",
    id: string,
    currency_code?: string | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateBalanceTransferMutationVariables = {
  input: CreateBalanceTransferInput,
  condition?: ModelBalanceTransferConditionInput | null,
};

export type CreateBalanceTransferMutation = {
  createBalanceTransfer?:  {
    __typename: "BalanceTransfer",
    id: string,
    reason?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    dreamerly_fee?: string | null,
    dreamerly_centi_fee?: string | null,
    payment_provider_fee?: string | null,
    payment_provider_centi_fee?: string | null,
    net_amount?: string | null,
    net_centi_amount?: string | null,
    payment_provider?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_user_id: string,
    from_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_user_id: string,
    to_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_dreamerly_balance_id: string,
    from_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_dreamerly_balance_id: string,
    to_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateBalanceTransferMutationVariables = {
  input: UpdateBalanceTransferInput,
  condition?: ModelBalanceTransferConditionInput | null,
};

export type UpdateBalanceTransferMutation = {
  updateBalanceTransfer?:  {
    __typename: "BalanceTransfer",
    id: string,
    reason?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    dreamerly_fee?: string | null,
    dreamerly_centi_fee?: string | null,
    payment_provider_fee?: string | null,
    payment_provider_centi_fee?: string | null,
    net_amount?: string | null,
    net_centi_amount?: string | null,
    payment_provider?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_user_id: string,
    from_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_user_id: string,
    to_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_dreamerly_balance_id: string,
    from_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_dreamerly_balance_id: string,
    to_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteBalanceTransferMutationVariables = {
  input: DeleteBalanceTransferInput,
  condition?: ModelBalanceTransferConditionInput | null,
};

export type DeleteBalanceTransferMutation = {
  deleteBalanceTransfer?:  {
    __typename: "BalanceTransfer",
    id: string,
    reason?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    dreamerly_fee?: string | null,
    dreamerly_centi_fee?: string | null,
    payment_provider_fee?: string | null,
    payment_provider_centi_fee?: string | null,
    net_amount?: string | null,
    net_centi_amount?: string | null,
    payment_provider?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_user_id: string,
    from_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_user_id: string,
    to_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_dreamerly_balance_id: string,
    from_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_dreamerly_balance_id: string,
    to_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreatePaymentProviderFeePaymentMutationVariables = {
  input: CreatePaymentProviderFeePaymentInput,
  condition?: ModelPaymentProviderFeePaymentConditionInput | null,
};

export type CreatePaymentProviderFeePaymentMutation = {
  createPaymentProviderFeePayment?:  {
    __typename: "PaymentProviderFeePayment",
    id: string,
    amount: string,
    centi_amount: string,
    currency_code: string,
    publication_date_time?: string | null,
    payment_provider?: string | null,
    description?: string | null,
    invoice_number?: string | null,
    from_dreamerly_balance_id: string,
    to_dreamerly_balance_id: string,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdatePaymentProviderFeePaymentMutationVariables = {
  input: UpdatePaymentProviderFeePaymentInput,
  condition?: ModelPaymentProviderFeePaymentConditionInput | null,
};

export type UpdatePaymentProviderFeePaymentMutation = {
  updatePaymentProviderFeePayment?:  {
    __typename: "PaymentProviderFeePayment",
    id: string,
    amount: string,
    centi_amount: string,
    currency_code: string,
    publication_date_time?: string | null,
    payment_provider?: string | null,
    description?: string | null,
    invoice_number?: string | null,
    from_dreamerly_balance_id: string,
    to_dreamerly_balance_id: string,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeletePaymentProviderFeePaymentMutationVariables = {
  input: DeletePaymentProviderFeePaymentInput,
  condition?: ModelPaymentProviderFeePaymentConditionInput | null,
};

export type DeletePaymentProviderFeePaymentMutation = {
  deletePaymentProviderFeePayment?:  {
    __typename: "PaymentProviderFeePayment",
    id: string,
    amount: string,
    centi_amount: string,
    currency_code: string,
    publication_date_time?: string | null,
    payment_provider?: string | null,
    description?: string | null,
    invoice_number?: string | null,
    from_dreamerly_balance_id: string,
    to_dreamerly_balance_id: string,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateBalanceTransactionMutationVariables = {
  input: CreateBalanceTransactionInput,
  condition?: ModelBalanceTransactionConditionInput | null,
};

export type CreateBalanceTransactionMutation = {
  createBalanceTransaction?:  {
    __typename: "BalanceTransaction",
    id: string,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_provider_fee_payments?:  {
      __typename: "ModelPaymentProviderFeePaymentConnection",
      items:  Array< {
        __typename: "PaymentProviderFeePayment",
        id: string,
        amount: string,
        centi_amount: string,
        currency_code: string,
        publication_date_time?: string | null,
        payment_provider?: string | null,
        description?: string | null,
        invoice_number?: string | null,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    type?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateBalanceTransactionMutationVariables = {
  input: UpdateBalanceTransactionInput,
  condition?: ModelBalanceTransactionConditionInput | null,
};

export type UpdateBalanceTransactionMutation = {
  updateBalanceTransaction?:  {
    __typename: "BalanceTransaction",
    id: string,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_provider_fee_payments?:  {
      __typename: "ModelPaymentProviderFeePaymentConnection",
      items:  Array< {
        __typename: "PaymentProviderFeePayment",
        id: string,
        amount: string,
        centi_amount: string,
        currency_code: string,
        publication_date_time?: string | null,
        payment_provider?: string | null,
        description?: string | null,
        invoice_number?: string | null,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    type?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteBalanceTransactionMutationVariables = {
  input: DeleteBalanceTransactionInput,
  condition?: ModelBalanceTransactionConditionInput | null,
};

export type DeleteBalanceTransactionMutation = {
  deleteBalanceTransaction?:  {
    __typename: "BalanceTransaction",
    id: string,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_provider_fee_payments?:  {
      __typename: "ModelPaymentProviderFeePaymentConnection",
      items:  Array< {
        __typename: "PaymentProviderFeePayment",
        id: string,
        amount: string,
        centi_amount: string,
        currency_code: string,
        publication_date_time?: string | null,
        payment_provider?: string | null,
        description?: string | null,
        invoice_number?: string | null,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    type?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    notification_sender_id?: string | null,
    notification_sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    title?: string | null,
    body?: string | null,
    publication_date_time?: string | null,
    is_read?: number | null,
    is_removed?: number | null,
    notification_type?: string | null,
    underlying_object_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    notification_sender_id?: string | null,
    notification_sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    title?: string | null,
    body?: string | null,
    publication_date_time?: string | null,
    is_read?: number | null,
    is_removed?: number | null,
    notification_type?: string | null,
    underlying_object_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    notification_sender_id?: string | null,
    notification_sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    title?: string | null,
    body?: string | null,
    publication_date_time?: string | null,
    is_read?: number | null,
    is_removed?: number | null,
    notification_type?: string | null,
    underlying_object_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateCharacterMutationVariables = {
  input: CreateCharacterInput,
  condition?: ModelCharacterConditionInput | null,
};

export type CreateCharacterMutation = {
  createCharacter?:  {
    __typename: "Character",
    id: string,
    display_name?: string | null,
    charname?: string | null,
    character_type?: string | null,
    user_id?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    strengths?: string | null,
    flaws?: string | null,
    gender?: string | null,
    race?: string | null,
    birth_date?: string | null,
    age?: string | null,
    role?: string | null,
    hobbies?: string | null,
    dislikes?: string | null,
    profile_picture_url?: string | null,
    cover_photo_url?: string | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateCharacterMutationVariables = {
  input: UpdateCharacterInput,
  condition?: ModelCharacterConditionInput | null,
};

export type UpdateCharacterMutation = {
  updateCharacter?:  {
    __typename: "Character",
    id: string,
    display_name?: string | null,
    charname?: string | null,
    character_type?: string | null,
    user_id?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    strengths?: string | null,
    flaws?: string | null,
    gender?: string | null,
    race?: string | null,
    birth_date?: string | null,
    age?: string | null,
    role?: string | null,
    hobbies?: string | null,
    dislikes?: string | null,
    profile_picture_url?: string | null,
    cover_photo_url?: string | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteCharacterMutationVariables = {
  input: DeleteCharacterInput,
  condition?: ModelCharacterConditionInput | null,
};

export type DeleteCharacterMutation = {
  deleteCharacter?:  {
    __typename: "Character",
    id: string,
    display_name?: string | null,
    charname?: string | null,
    character_type?: string | null,
    user_id?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    strengths?: string | null,
    flaws?: string | null,
    gender?: string | null,
    race?: string | null,
    birth_date?: string | null,
    age?: string | null,
    role?: string | null,
    hobbies?: string | null,
    dislikes?: string | null,
    profile_picture_url?: string | null,
    cover_photo_url?: string | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateCharacterItemMutationVariables = {
  input: CreateCharacterItemInput,
  condition?: ModelCharacterItemConditionInput | null,
};

export type CreateCharacterItemMutation = {
  createCharacterItem?:  {
    __typename: "CharacterItem",
    id: string,
    item_type?: string | null,
    character_id: string,
    character?:  {
      __typename: "Character",
      id: string,
      display_name?: string | null,
      charname?: string | null,
      character_type?: string | null,
      user_id?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      strengths?: string | null,
      flaws?: string | null,
      gender?: string | null,
      race?: string | null,
      birth_date?: string | null,
      age?: string | null,
      role?: string | null,
      hobbies?: string | null,
      dislikes?: string | null,
      profile_picture_url?: string | null,
      cover_photo_url?: string | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateCharacterItemMutationVariables = {
  input: UpdateCharacterItemInput,
  condition?: ModelCharacterItemConditionInput | null,
};

export type UpdateCharacterItemMutation = {
  updateCharacterItem?:  {
    __typename: "CharacterItem",
    id: string,
    item_type?: string | null,
    character_id: string,
    character?:  {
      __typename: "Character",
      id: string,
      display_name?: string | null,
      charname?: string | null,
      character_type?: string | null,
      user_id?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      strengths?: string | null,
      flaws?: string | null,
      gender?: string | null,
      race?: string | null,
      birth_date?: string | null,
      age?: string | null,
      role?: string | null,
      hobbies?: string | null,
      dislikes?: string | null,
      profile_picture_url?: string | null,
      cover_photo_url?: string | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteCharacterItemMutationVariables = {
  input: DeleteCharacterItemInput,
  condition?: ModelCharacterItemConditionInput | null,
};

export type DeleteCharacterItemMutation = {
  deleteCharacterItem?:  {
    __typename: "CharacterItem",
    id: string,
    item_type?: string | null,
    character_id: string,
    character?:  {
      __typename: "Character",
      id: string,
      display_name?: string | null,
      charname?: string | null,
      character_type?: string | null,
      user_id?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      strengths?: string | null,
      flaws?: string | null,
      gender?: string | null,
      race?: string | null,
      birth_date?: string | null,
      age?: string | null,
      role?: string | null,
      hobbies?: string | null,
      dislikes?: string | null,
      profile_picture_url?: string | null,
      cover_photo_url?: string | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DreamerlySaveBankAccountMutationVariables = {
  user_id: string,
  bank_name: string,
  account_holder_name: string,
  account_number: string,
};

export type DreamerlySaveBankAccountMutation = {
  dreamerlySaveBankAccount?:  {
    __typename: "DreamerlySaveBankAccountOutput",
    bank_account_id: string,
  } | null,
};

export type DreamerlySavePayoutRequestMutationVariables = {
  seller_id: string,
  amount: string,
  currency_code?: string | null,
};

export type DreamerlySavePayoutRequestMutation = {
  dreamerlySavePayoutRequest?:  {
    __typename: "DreamerlySavePayoutRequestOutput",
    payout_id: string,
  } | null,
};

export type GetTagQueryVariables = {
  id: string,
};

export type GetTagQuery = {
  getTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    display_name?: string | null,
    status?: string | null,
    total_artwork_quantity?: number | null,
    tag_artwork_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListTagsQueryVariables = {
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTagsQuery = {
  listTags?:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      tag_name?: string | null,
      display_name?: string | null,
      status?: string | null,
      total_artwork_quantity?: number | null,
      tag_artwork_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TagByTagNameStatusQueryVariables = {
  tag_name: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TagByTagNameStatusQuery = {
  tagByTagNameStatus?:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      tag_name?: string | null,
      display_name?: string | null,
      status?: string | null,
      total_artwork_quantity?: number | null,
      tag_artwork_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTagItemQueryVariables = {
  id: string,
};

export type GetTagItemQuery = {
  getTagItem?:  {
    __typename: "TagItem",
    id: string,
    tag_id: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      tag_name?: string | null,
      display_name?: string | null,
      status?: string | null,
      total_artwork_quantity?: number | null,
      tag_artwork_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    tag_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListTagItemsQueryVariables = {
  filter?: ModelTagItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTagItemsQuery = {
  listTagItems?:  {
    __typename: "ModelTagItemConnection",
    items:  Array< {
      __typename: "TagItem",
      id: string,
      tag_id: string,
      tag?:  {
        __typename: "Tag",
        id: string,
        tag_name?: string | null,
        display_name?: string | null,
        status?: string | null,
        total_artwork_quantity?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      artwork_id: string,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      tag_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TagItemByTagIdQueryVariables = {
  tag_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTagItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TagItemByTagIdQuery = {
  tagItemByTagId?:  {
    __typename: "ModelTagItemConnection",
    items:  Array< {
      __typename: "TagItem",
      id: string,
      tag_id: string,
      tag?:  {
        __typename: "Tag",
        id: string,
        tag_name?: string | null,
        display_name?: string | null,
        status?: string | null,
        total_artwork_quantity?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      artwork_id: string,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      tag_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TagItemByArtworkIdQueryVariables = {
  artwork_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTagItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TagItemByArtworkIdQuery = {
  tagItemByArtworkId?:  {
    __typename: "ModelTagItemConnection",
    items:  Array< {
      __typename: "TagItem",
      id: string,
      tag_id: string,
      tag?:  {
        __typename: "Tag",
        id: string,
        tag_name?: string | null,
        display_name?: string | null,
        status?: string | null,
        total_artwork_quantity?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      artwork_id: string,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      tag_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetArtworkQueryVariables = {
  id: string,
};

export type GetArtworkQuery = {
  getArtwork?:  {
    __typename: "Artwork",
    id: string,
    cover_image_height?: number | null,
    cover_image_width?: number | null,
    cover_image_src_url?: string | null,
    cover_image_thumbnail_url?: string | null,
    title?: string | null,
    likes_count?: number | null,
    comments_count?: number | null,
    description?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_buyer_showcase?: number | null,
    is_seller_showcase?: number | null,
    tag_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListArtworksQueryVariables = {
  filter?: ModelArtworkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArtworksQuery = {
  listArtworks?:  {
    __typename: "ModelArtworkConnection",
    items:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ArtworkByUserIdQueryVariables = {
  user_id: string,
  is_removed?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelArtworkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ArtworkByUserIdQuery = {
  artworkByUserId?:  {
    __typename: "ModelArtworkConnection",
    items:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ArtworkByBuyerIdIsRemovedQueryVariables = {
  buyer_id: string,
  is_removed?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelArtworkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ArtworkByBuyerIdIsRemovedQuery = {
  artworkByBuyerIdIsRemoved?:  {
    __typename: "ModelArtworkConnection",
    items:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ArtworkByIsRemovedPublicationDateTimeQueryVariables = {
  is_removed: number,
  publication_date_time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelArtworkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ArtworkByIsRemovedPublicationDateTimeQuery = {
  artworkByIsRemovedPublicationDateTime?:  {
    __typename: "ModelArtworkConnection",
    items:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ArtworkByContractIdQueryVariables = {
  contract_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelArtworkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ArtworkByContractIdQuery = {
  artworkByContractId?:  {
    __typename: "ModelArtworkConnection",
    items:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductArtworkQueryVariables = {
  id: string,
};

export type GetProductArtworkQuery = {
  getProductArtwork?:  {
    __typename: "ProductArtwork",
    id: string,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    index?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListProductArtworksQueryVariables = {
  filter?: ModelProductArtworkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductArtworksQuery = {
  listProductArtworks?:  {
    __typename: "ModelProductArtworkConnection",
    items:  Array< {
      __typename: "ProductArtwork",
      id: string,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      artwork_id: string,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      index?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductArtworkByProductIdQueryVariables = {
  product_id: string,
  is_removed?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductArtworkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductArtworkByProductIdQuery = {
  productArtworkByProductId?:  {
    __typename: "ModelProductArtworkConnection",
    items:  Array< {
      __typename: "ProductArtwork",
      id: string,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      artwork_id: string,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      index?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductArtworkByArtworkIdQueryVariables = {
  artwork_id: string,
  is_removed?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductArtworkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductArtworkByArtworkIdQuery = {
  productArtworkByArtworkId?:  {
    __typename: "ModelProductArtworkConnection",
    items:  Array< {
      __typename: "ProductArtwork",
      id: string,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      artwork_id: string,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      index?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    display_name?: string | null,
    status?: string | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      display_name?: string | null,
      status?: string | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetImageQueryVariables = {
  id: string,
};

export type GetImageQuery = {
  getImage?:  {
    __typename: "Image",
    id: string,
    height?: number | null,
    width?: number | null,
    file_name?: string | null,
    src_url?: string | null,
    thumbnail_url?: string | null,
    raw_src_url?: string | null,
    raw_thumbnail_url?: string | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_id?: string | null,
    revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_request_revision_id?: string | null,
    revision_request_revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    message_id?: string | null,
    message?:  {
      __typename: "Message",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      sender_id: string,
      sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      content?: string | null,
      image_url?: string | null,
      publication_date_time?: string | null,
      available_to?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListImagesQueryVariables = {
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImagesQuery = {
  listImages?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      height?: number | null,
      width?: number | null,
      file_name?: string | null,
      src_url?: string | null,
      thumbnail_url?: string | null,
      raw_src_url?: string | null,
      raw_thumbnail_url?: string | null,
      artwork_id?: string | null,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      job_id?: string | null,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_id?: string | null,
      revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_request_revision_id?: string | null,
      revision_request_revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      message_id?: string | null,
      message?:  {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageByArtworkIdQueryVariables = {
  artwork_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageByArtworkIdQuery = {
  imageByArtworkId?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      height?: number | null,
      width?: number | null,
      file_name?: string | null,
      src_url?: string | null,
      thumbnail_url?: string | null,
      raw_src_url?: string | null,
      raw_thumbnail_url?: string | null,
      artwork_id?: string | null,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      job_id?: string | null,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_id?: string | null,
      revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_request_revision_id?: string | null,
      revision_request_revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      message_id?: string | null,
      message?:  {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageByJobIdQueryVariables = {
  job_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageByJobIdQuery = {
  imageByJobId?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      height?: number | null,
      width?: number | null,
      file_name?: string | null,
      src_url?: string | null,
      thumbnail_url?: string | null,
      raw_src_url?: string | null,
      raw_thumbnail_url?: string | null,
      artwork_id?: string | null,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      job_id?: string | null,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_id?: string | null,
      revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_request_revision_id?: string | null,
      revision_request_revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      message_id?: string | null,
      message?:  {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageByMilestoneIdQueryVariables = {
  milestone_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageByMilestoneIdQuery = {
  imageByMilestoneId?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      height?: number | null,
      width?: number | null,
      file_name?: string | null,
      src_url?: string | null,
      thumbnail_url?: string | null,
      raw_src_url?: string | null,
      raw_thumbnail_url?: string | null,
      artwork_id?: string | null,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      job_id?: string | null,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_id?: string | null,
      revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_request_revision_id?: string | null,
      revision_request_revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      message_id?: string | null,
      message?:  {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageByRevisionIdQueryVariables = {
  revision_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageByRevisionIdQuery = {
  imageByRevisionId?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      height?: number | null,
      width?: number | null,
      file_name?: string | null,
      src_url?: string | null,
      thumbnail_url?: string | null,
      raw_src_url?: string | null,
      raw_thumbnail_url?: string | null,
      artwork_id?: string | null,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      job_id?: string | null,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_id?: string | null,
      revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_request_revision_id?: string | null,
      revision_request_revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      message_id?: string | null,
      message?:  {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageByRevisionRequestRevisionIdQueryVariables = {
  revision_request_revision_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageByRevisionRequestRevisionIdQuery = {
  imageByRevisionRequestRevisionId?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      height?: number | null,
      width?: number | null,
      file_name?: string | null,
      src_url?: string | null,
      thumbnail_url?: string | null,
      raw_src_url?: string | null,
      raw_thumbnail_url?: string | null,
      artwork_id?: string | null,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      job_id?: string | null,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_id?: string | null,
      revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_request_revision_id?: string | null,
      revision_request_revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      message_id?: string | null,
      message?:  {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageByMessageIdQueryVariables = {
  message_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageByMessageIdQuery = {
  imageByMessageId?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      height?: number | null,
      width?: number | null,
      file_name?: string | null,
      src_url?: string | null,
      thumbnail_url?: string | null,
      raw_src_url?: string | null,
      raw_thumbnail_url?: string | null,
      artwork_id?: string | null,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      job_id?: string | null,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_id?: string | null,
      revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      revision_request_revision_id?: string | null,
      revision_request_revision?:  {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      message_id?: string | null,
      message?:  {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLikeQueryVariables = {
  id: string,
};

export type GetLikeQuery = {
  getLike?:  {
    __typename: "Like",
    id: string,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListLikesQueryVariables = {
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLikesQuery = {
  listLikes?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      artwork_id: string,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikeByArtworkIdUserIdIsRemovedQueryVariables = {
  artwork_id: string,
  user_idIs_removed?: ModelLikeByArtworkIdUserIdIsRemovedCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikeByArtworkIdUserIdIsRemovedQuery = {
  likeByArtworkIdUserIdIsRemoved?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      artwork_id: string,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikeByUserIdQueryVariables = {
  user_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikeByUserIdQuery = {
  likeByUserId?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      artwork_id: string,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    display_name?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    username?: string | null,
    email_address?: string | null,
    birth_date?: string | null,
    phone_number?: string | null,
    country_code?: string | null,
    subscriber_number?: string | null,
    bio?: string | null,
    is_artist?: number | null,
    seller_onboarding_stage?: string | null,
    buyer_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    term_of_service?: string | null,
    cccd?: string | null,
    mst?: string | null,
    cccd_images?: Array< string | null > | null,
    mst_stage?: string | null,
    seller_proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    profile_picture_url?: string | null,
    cover_picture_url?: string | null,
    balance_amount?: string | null,
    balance_centi_amount?: string | null,
    balance_currency_code?: string | null,
    usd_balance_amount?: string | null,
    usd_balance_centi_amount?: string | null,
    contract_paid_quantity?: number | null,
    seller_star_quantity?: number | null,
    seller_review_quantity?: number | null,
    buyer_star_quantity?: number | null,
    buyer_review_quantity?: number | null,
    commision_started_quantity?: number | null,
    commission_completed_quantity?: number | null,
    commission_completed_on_time_quantity?: number | null,
    commission_cancelled_seller_fault_quantity?: number | null,
    commission_cancelled_buyer_fault_quantity?: number | null,
    commission_cancelled_mutually_quantity?: number | null,
    commission_cumulative_late_seconds?: number | null,
    commission_max_late_seconds?: number | null,
    device_tokens?: Array< string | null > | null,
    is_selling?: number | null,
    is_selling_abroad?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    last_time_open_list_notification?: string | null,
    discount_code?: string | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    selling_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buying_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    given_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transactions?:  {
      __typename: "ModelBalanceTransactionConnection",
      items:  Array< {
        __typename: "BalanceTransaction",
        id: string,
        type?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    follower_quantity?: number | null,
    leader_quantity?: number | null,
    followers?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    leaders?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notification_senders?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    push_tokens?:  {
      __typename: "ModelPushTokenConnection",
      items:  Array< {
        __typename: "PushToken",
        id: string,
        user_id: string,
        push_token?: string | null,
        device_type?: string | null,
        last_used_at?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    has_unread_commissions?: number | null,
    has_unread_jobs?: number | null,
    message_cumulative_response_seconds?: number | null,
    message_response_on_time_quantity?: number | null,
    message_response_late_quantity?: number | null,
    characters?:  {
      __typename: "ModelCharacterConnection",
      items:  Array< {
        __typename: "Character",
        id: string,
        display_name?: string | null,
        charname?: string | null,
        character_type?: string | null,
        user_id?: string | null,
        description?: string | null,
        strengths?: string | null,
        flaws?: string | null,
        gender?: string | null,
        race?: string | null,
        birth_date?: string | null,
        age?: string | null,
        role?: string | null,
        hobbies?: string | null,
        dislikes?: string | null,
        profile_picture_url?: string | null,
        cover_photo_url?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_last_four?: string | null,
    address_country_code?: string | null,
    city?: string | null,
    address_line1?: string | null,
    additional_info?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByUsernameQueryVariables = {
  username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByUsernameQuery = {
  userByUsername?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByEmailAddressQueryVariables = {
  email_address: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByEmailAddressQuery = {
  userByEmailAddress?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByPhoneNumberQueryVariables = {
  phone_number: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByPhoneNumberQuery = {
  userByPhoneNumber?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBankAccountQueryVariables = {
  id: string,
};

export type GetBankAccountQuery = {
  getBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    user_id: string,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_number?: string | null,
    currency_code?: string | null,
    country_code?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListBankAccountsQueryVariables = {
  filter?: ModelBankAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBankAccountsQuery = {
  listBankAccounts?:  {
    __typename: "ModelBankAccountConnection",
    items:  Array< {
      __typename: "BankAccount",
      id: string,
      user_id: string,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_number?: string | null,
      currency_code?: string | null,
      country_code?: string | null,
      status?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BankAccountByUserIdStatusQueryVariables = {
  user_id: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBankAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BankAccountByUserIdStatusQuery = {
  bankAccountByUserIdStatus?:  {
    __typename: "ModelBankAccountConnection",
    items:  Array< {
      __typename: "BankAccount",
      id: string,
      user_id: string,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_number?: string | null,
      currency_code?: string | null,
      country_code?: string | null,
      status?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPushTokenQueryVariables = {
  id: string,
};

export type GetPushTokenQuery = {
  getPushToken?:  {
    __typename: "PushToken",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    push_token?: string | null,
    device_type?: string | null,
    last_used_at?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListPushTokensQueryVariables = {
  filter?: ModelPushTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPushTokensQuery = {
  listPushTokens?:  {
    __typename: "ModelPushTokenConnection",
    items:  Array< {
      __typename: "PushToken",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      push_token?: string | null,
      device_type?: string | null,
      last_used_at?: string | null,
      status?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PushTokenByUserIdQueryVariables = {
  user_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPushTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PushTokenByUserIdQuery = {
  pushTokenByUserId?:  {
    __typename: "ModelPushTokenConnection",
    items:  Array< {
      __typename: "PushToken",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      push_token?: string | null,
      device_type?: string | null,
      last_used_at?: string | null,
      status?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserBlockQueryVariables = {
  id: string,
};

export type GetUserBlockQuery = {
  getUserBlock?:  {
    __typename: "UserBlock",
    id: string,
    user_give_block_id?: string | null,
    user_receive_block_id?: string | null,
    type?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListUserBlocksQueryVariables = {
  filter?: ModelUserBlockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserBlocksQuery = {
  listUserBlocks?:  {
    __typename: "ModelUserBlockConnection",
    items:  Array< {
      __typename: "UserBlock",
      id: string,
      user_give_block_id?: string | null,
      user_receive_block_id?: string | null,
      type?: string | null,
      status?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserBlockByUserGiveBlockIdQueryVariables = {
  user_give_block_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserBlockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserBlockByUserGiveBlockIdQuery = {
  userBlockByUserGiveBlockId?:  {
    __typename: "ModelUserBlockConnection",
    items:  Array< {
      __typename: "UserBlock",
      id: string,
      user_give_block_id?: string | null,
      user_receive_block_id?: string | null,
      type?: string | null,
      status?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserBlockByUserReceiveBlockIdQueryVariables = {
  user_receive_block_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserBlockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserBlockByUserReceiveBlockIdQuery = {
  userBlockByUserReceiveBlockId?:  {
    __typename: "ModelUserBlockConnection",
    items:  Array< {
      __typename: "UserBlock",
      id: string,
      user_give_block_id?: string | null,
      user_receive_block_id?: string | null,
      type?: string | null,
      status?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    artist_id: string,
    artist?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name: string,
    description?: string | null,
    status: string,
    prices?:  {
      __typename: "ModelPriceConnection",
      items:  Array< {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    slug?: string | null,
    category_id?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      display_name?: string | null,
      status?: string | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    min_price_amount?: string | null,
    max_price_amount?: string | null,
    currency_code?: string | null,
    product_star_quantity?: number | null,
    product_review_quantity?: number | null,
    commission_paid_quantity?: number | null,
    artist_other_artworks?:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    reviews?:  Array< {
      __typename: "Review",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      reviewer_id: string,
      reviewer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      rating?: number | null,
      comment?: string | null,
      type?: string | null,
      publication_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    dreambox_response_time?: number | null,
    dreambox_complete_time?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductByArtistIdStatusQueryVariables = {
  artist_id: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductByArtistIdStatusQuery = {
  productByArtistIdStatus?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductBySlugQueryVariables = {
  slug: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductBySlugQuery = {
  productBySlug?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductByCategoryIdQueryVariables = {
  category_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductByCategoryIdQuery = {
  productByCategoryId?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPriceQueryVariables = {
  id: string,
};

export type GetPriceQuery = {
  getPrice?:  {
    __typename: "Price",
    id: string,
    product_id: string,
    Product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name?: string | null,
    description?: string | null,
    status: string,
    type?: string | null,
    category?: string | null,
    fixed_amount?: string | null,
    fixed_centi_amount?: string | null,
    index?: number | null,
    min_amount?: string | null,
    min_centi_amount?: string | null,
    max_amount?: string | null,
    max_centi_amount?: string | null,
    currency_code?: string | null,
    percentage?: number | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListPricesQueryVariables = {
  filter?: ModelPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPricesQuery = {
  listPrices?:  {
    __typename: "ModelPriceConnection",
    items:  Array< {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PriceByProductIdStatusQueryVariables = {
  product_id: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PriceByProductIdStatusQuery = {
  priceByProductIdStatus?:  {
    __typename: "ModelPriceConnection",
    items:  Array< {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobQueryVariables = {
  id: string,
};

export type GetJobQuery = {
  getJob?:  {
    __typename: "Job",
    id: string,
    title?: string | null,
    description?: string | null,
    min_budget?: string | null,
    min_centi_budget?: string | null,
    max_budget?: string | null,
    max_centi_budget?: string | null,
    currency_code?: string | null,
    status?: string | null,
    is_exclusive?: number | null,
    publication_date_time?: string | null,
    deadline_date_time?: string | null,
    expiration_date_time?: string | null,
    is_comercial_artwork?: number | null,
    is_dreambox?: number | null,
    is_private_artwork?: number | null,
    file_format?: Array< string | null > | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacted_seller_ids?: Array< string | null > | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListJobsQueryVariables = {
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobsQuery = {
  listJobs?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type JobByStatusIsExclusivePublicationDateTimeQueryVariables = {
  status: string,
  is_exclusivePublication_date_time?: ModelJobByStatusIsExclusivePublicationDateTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobByStatusIsExclusivePublicationDateTimeQuery = {
  jobByStatusIsExclusivePublicationDateTime?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type JobByStatusIsExclusiveExpirationDateTimeQueryVariables = {
  status: string,
  is_exclusiveExpiration_date_time?: ModelJobByStatusIsExclusiveExpirationDateTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobByStatusIsExclusiveExpirationDateTimeQuery = {
  jobByStatusIsExclusiveExpirationDateTime?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type JobByBuyerIdStatusPublicationDateTimeQueryVariables = {
  buyer_id: string,
  statusPublication_date_time?: ModelJobByBuyerIdStatusPublicationDateTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobByBuyerIdStatusPublicationDateTimeQuery = {
  jobByBuyerIdStatusPublicationDateTime?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type JobBySellerIdStatusPublicationDateTimeQueryVariables = {
  seller_id: string,
  statusPublication_date_time?: ModelJobBySellerIdStatusPublicationDateTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobBySellerIdStatusPublicationDateTimeQuery = {
  jobBySellerIdStatusPublicationDateTime?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProposalQueryVariables = {
  id: string,
};

export type GetProposalQuery = {
  getProposal?:  {
    __typename: "Proposal",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_comment?: string | null,
    status?: string | null,
    publication_date_time?: string | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    proposal_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListProposalsQueryVariables = {
  filter?: ModelProposalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProposalsQuery = {
  listProposals?:  {
    __typename: "ModelProposalConnection",
    items:  Array< {
      __typename: "Proposal",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_comment?: string | null,
      status?: string | null,
      publication_date_time?: string | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      proposal_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProposalByJobIdSellerIdQueryVariables = {
  job_id: string,
  seller_id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProposalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProposalByJobIdSellerIdQuery = {
  proposalByJobIdSellerId?:  {
    __typename: "ModelProposalConnection",
    items:  Array< {
      __typename: "Proposal",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_comment?: string | null,
      status?: string | null,
      publication_date_time?: string | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      proposal_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProposalBySellerIdStatusPublicationDateTimeQueryVariables = {
  seller_id: string,
  statusPublication_date_time?: ModelProposalBySellerIdStatusPublicationDateTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProposalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProposalBySellerIdStatusPublicationDateTimeQuery = {
  proposalBySellerIdStatusPublicationDateTime?:  {
    __typename: "ModelProposalConnection",
    items:  Array< {
      __typename: "Proposal",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_comment?: string | null,
      status?: string | null,
      publication_date_time?: string | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      proposal_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChannelQueryVariables = {
  id: string,
};

export type GetChannelQuery = {
  getChannel?:  {
    __typename: "Channel",
    id: string,
    name?: string | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListChannelsQueryVariables = {
  filter?: ModelChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChannelsQuery = {
  listChannels?:  {
    __typename: "ModelChannelConnection",
    items:  Array< {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserChannelQueryVariables = {
  id: string,
};

export type GetUserChannelQuery = {
  getUserChannel?:  {
    __typename: "UserChannel",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    update_date_time?: string | null,
    status?: string | null,
    next_time_should_receive_an_email?: string | null,
    next_time_should_receive_a_late_response?: string | null,
    request_start_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListUserChannelsQueryVariables = {
  filter?: ModelUserChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserChannelsQuery = {
  listUserChannels?:  {
    __typename: "ModelUserChannelConnection",
    items:  Array< {
      __typename: "UserChannel",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      update_date_time?: string | null,
      status?: string | null,
      next_time_should_receive_an_email?: string | null,
      next_time_should_receive_a_late_response?: string | null,
      request_start_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserChannelByChannelIdStatusQueryVariables = {
  channel_id: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserChannelByChannelIdStatusQuery = {
  userChannelByChannelIdStatus?:  {
    __typename: "ModelUserChannelConnection",
    items:  Array< {
      __typename: "UserChannel",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      update_date_time?: string | null,
      status?: string | null,
      next_time_should_receive_an_email?: string | null,
      next_time_should_receive_a_late_response?: string | null,
      request_start_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserChannelByUserIdStatusQueryVariables = {
  user_id: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserChannelByUserIdStatusQuery = {
  userChannelByUserIdStatus?:  {
    __typename: "ModelUserChannelConnection",
    items:  Array< {
      __typename: "UserChannel",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      update_date_time?: string | null,
      status?: string | null,
      next_time_should_receive_an_email?: string | null,
      next_time_should_receive_a_late_response?: string | null,
      request_start_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    sender_id: string,
    sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    content?: string | null,
    image_url?: string | null,
    publication_date_time?: string | null,
    available_to?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      sender_id: string,
      sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      content?: string | null,
      image_url?: string | null,
      publication_date_time?: string | null,
      available_to?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MessageByChannelIdPublicationDateTimeQueryVariables = {
  channel_id: string,
  publication_date_time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessageByChannelIdPublicationDateTimeQuery = {
  messageByChannelIdPublicationDateTime?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      sender_id: string,
      sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      content?: string | null,
      image_url?: string | null,
      publication_date_time?: string | null,
      available_to?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MessageBySenderIdQueryVariables = {
  sender_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessageBySenderIdQuery = {
  messageBySenderId?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      sender_id: string,
      sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      content?: string | null,
      image_url?: string | null,
      publication_date_time?: string | null,
      available_to?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentIntentQueryVariables = {
  id: string,
};

export type GetPaymentIntentQuery = {
  getPaymentIntent?:  {
    __typename: "PaymentIntent",
    id: string,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_payment_contract_id: string,
    extra_payment_contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    display_fee_owing_to_momo_amount?: string | null,
    display_fee_owing_to_momo_centi_amount?: string | null,
    display_dreamerly_fee_balance_amount?: string | null,
    display_dreamerly_fee_balance_centi_amount?: string | null,
    payment_purpose?: string | null,
    billing_details?: string | null,
    description?: string | null,
    last_payment_error?: string | null,
    next_action?: string | null,
    disputed?: number | null,
    disputed_at?: string | null,
    refunded?: number | null,
    refunded_amount?: string | null,
    refunded_centi_amount?: string | null,
    refunded_at?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    payment_method?: string | null,
    paid_at?: string | null,
    cancelled_at?: string | null,
    momo_trans_id?: string | null,
    momo_request_id?: string | null,
    momo_payment_url?: string | null,
    momo_payment_url_expires_at?: string | null,
    stripe_payment_intent_id?: string | null,
    stripe_payment_method_country?: string | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListPaymentIntentsQueryVariables = {
  filter?: ModelPaymentIntentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentIntentsQuery = {
  listPaymentIntents?:  {
    __typename: "ModelPaymentIntentConnection",
    items:  Array< {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentIntentByBuyerIdQueryVariables = {
  buyer_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentIntentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentIntentByBuyerIdQuery = {
  paymentIntentByBuyerId?:  {
    __typename: "ModelPaymentIntentConnection",
    items:  Array< {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentIntentByContractIdQueryVariables = {
  contract_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentIntentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentIntentByContractIdQuery = {
  paymentIntentByContractId?:  {
    __typename: "ModelPaymentIntentConnection",
    items:  Array< {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentIntentByExtraPaymentContractIdQueryVariables = {
  extra_payment_contract_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentIntentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentIntentByExtraPaymentContractIdQuery = {
  paymentIntentByExtraPaymentContractId?:  {
    __typename: "ModelPaymentIntentConnection",
    items:  Array< {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentIntentByExtraOfferIdQueryVariables = {
  extra_offer_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentIntentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentIntentByExtraOfferIdQuery = {
  paymentIntentByExtraOfferId?:  {
    __typename: "ModelPaymentIntentConnection",
    items:  Array< {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentIntentByBalanceTransactionIdQueryVariables = {
  balance_transaction_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentIntentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentIntentByBalanceTransactionIdQuery = {
  paymentIntentByBalanceTransactionId?:  {
    __typename: "ModelPaymentIntentConnection",
    items:  Array< {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPayoutQueryVariables = {
  id: string,
};

export type GetPayoutQuery = {
  getPayout?:  {
    __typename: "Payout",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    billing_details?: string | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    arrival_date_time?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    balance_transaction_id?: string | null,
    request_amount?: string | null,
    request_centi_amount?: string | null,
    request_date_time?: string | null,
    bank_account_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListPayoutsQueryVariables = {
  filter?: ModelPayoutFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPayoutsQuery = {
  listPayouts?:  {
    __typename: "ModelPayoutConnection",
    items:  Array< {
      __typename: "Payout",
      id: string,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      billing_details?: string | null,
      seller_id?: string | null,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      arrival_date_time?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      balance_transaction_id?: string | null,
      request_amount?: string | null,
      request_centi_amount?: string | null,
      request_date_time?: string | null,
      bank_account_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PayoutBySellerIdQueryVariables = {
  seller_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPayoutFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PayoutBySellerIdQuery = {
  payoutBySellerId?:  {
    __typename: "ModelPayoutConnection",
    items:  Array< {
      __typename: "Payout",
      id: string,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      billing_details?: string | null,
      seller_id?: string | null,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      arrival_date_time?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      balance_transaction_id?: string | null,
      request_amount?: string | null,
      request_centi_amount?: string | null,
      request_date_time?: string | null,
      bank_account_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PayoutByBalanceTransactionIdQueryVariables = {
  balance_transaction_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPayoutFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PayoutByBalanceTransactionIdQuery = {
  payoutByBalanceTransactionId?:  {
    __typename: "ModelPayoutConnection",
    items:  Array< {
      __typename: "Payout",
      id: string,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      billing_details?: string | null,
      seller_id?: string | null,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      arrival_date_time?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      balance_transaction_id?: string | null,
      request_amount?: string | null,
      request_centi_amount?: string | null,
      request_date_time?: string | null,
      bank_account_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRefundQueryVariables = {
  id: string,
};

export type GetRefundQuery = {
  getRefund?:  {
    __typename: "Refund",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    billing_details?: string | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    reason?: string | null,
    status?: string | null,
    response?: string | null,
    payment_provider?: string | null,
    momo_trans_id?: string | null,
    approved_at?: string | null,
    rejected_at?: string | null,
    refunded_at?: string | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListRefundsQueryVariables = {
  filter?: ModelRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRefundsQuery = {
  listRefunds?:  {
    __typename: "ModelRefundConnection",
    items:  Array< {
      __typename: "Refund",
      id: string,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      billing_details?: string | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id?: string | null,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      payment_intent_id: string,
      payment_intent?:  {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      reason?: string | null,
      status?: string | null,
      response?: string | null,
      payment_provider?: string | null,
      momo_trans_id?: string | null,
      approved_at?: string | null,
      rejected_at?: string | null,
      refunded_at?: string | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RefundByBuyerIdQueryVariables = {
  buyer_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RefundByBuyerIdQuery = {
  refundByBuyerId?:  {
    __typename: "ModelRefundConnection",
    items:  Array< {
      __typename: "Refund",
      id: string,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      billing_details?: string | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id?: string | null,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      payment_intent_id: string,
      payment_intent?:  {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      reason?: string | null,
      status?: string | null,
      response?: string | null,
      payment_provider?: string | null,
      momo_trans_id?: string | null,
      approved_at?: string | null,
      rejected_at?: string | null,
      refunded_at?: string | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RefundBySellerIdQueryVariables = {
  seller_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RefundBySellerIdQuery = {
  refundBySellerId?:  {
    __typename: "ModelRefundConnection",
    items:  Array< {
      __typename: "Refund",
      id: string,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      billing_details?: string | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id?: string | null,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      payment_intent_id: string,
      payment_intent?:  {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      reason?: string | null,
      status?: string | null,
      response?: string | null,
      payment_provider?: string | null,
      momo_trans_id?: string | null,
      approved_at?: string | null,
      rejected_at?: string | null,
      refunded_at?: string | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RefundByPaymentIntentIdQueryVariables = {
  payment_intent_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RefundByPaymentIntentIdQuery = {
  refundByPaymentIntentId?:  {
    __typename: "ModelRefundConnection",
    items:  Array< {
      __typename: "Refund",
      id: string,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      billing_details?: string | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id?: string | null,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      payment_intent_id: string,
      payment_intent?:  {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      reason?: string | null,
      status?: string | null,
      response?: string | null,
      payment_provider?: string | null,
      momo_trans_id?: string | null,
      approved_at?: string | null,
      rejected_at?: string | null,
      refunded_at?: string | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RefundByContractIdQueryVariables = {
  contract_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RefundByContractIdQuery = {
  refundByContractId?:  {
    __typename: "ModelRefundConnection",
    items:  Array< {
      __typename: "Refund",
      id: string,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      billing_details?: string | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id?: string | null,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      payment_intent_id: string,
      payment_intent?:  {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      reason?: string | null,
      status?: string | null,
      response?: string | null,
      payment_provider?: string | null,
      momo_trans_id?: string | null,
      approved_at?: string | null,
      rejected_at?: string | null,
      refunded_at?: string | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RefundByBalanceTransactionIdQueryVariables = {
  balance_transaction_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RefundByBalanceTransactionIdQuery = {
  refundByBalanceTransactionId?:  {
    __typename: "ModelRefundConnection",
    items:  Array< {
      __typename: "Refund",
      id: string,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      billing_details?: string | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id?: string | null,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      payment_intent_id: string,
      payment_intent?:  {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      reason?: string | null,
      status?: string | null,
      response?: string | null,
      payment_provider?: string | null,
      momo_trans_id?: string | null,
      approved_at?: string | null,
      rejected_at?: string | null,
      refunded_at?: string | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContractQueryVariables = {
  id: string,
};

export type GetContractQuery = {
  getContract?:  {
    __typename: "Contract",
    id: string,
    status?: string | null,
    publication_date_time?: string | null,
    is_private_artwork?: number | null,
    is_comercial_artwork?: number | null,
    file_formats?: Array< string | null > | null,
    description?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    term_of_service?: string | null,
    state_name?: string | null,
    current_milestone_id?: string | null,
    current_milestone_index?: number | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposal_id: string,
    proposal?:  {
      __typename: "Proposal",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_comment?: string | null,
      status?: string | null,
      publication_date_time?: string | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      proposal_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_payment_payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestones?:  {
      __typename: "ModelMilestoneConnection",
      items:  Array< {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offers?:  {
      __typename: "ModelExtraOfferConnection",
      items:  Array< {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListContractsQueryVariables = {
  filter?: ModelContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContractsQuery = {
  listContracts?:  {
    __typename: "ModelContractConnection",
    items:  Array< {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractByJobIdQueryVariables = {
  job_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractByJobIdQuery = {
  contractByJobId?:  {
    __typename: "ModelContractConnection",
    items:  Array< {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractByProposalIdQueryVariables = {
  proposal_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractByProposalIdQuery = {
  contractByProposalId?:  {
    __typename: "ModelContractConnection",
    items:  Array< {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractByBuyerIdStatusPublicationDateTimeQueryVariables = {
  buyer_id: string,
  statusPublication_date_time?: ModelContractByBuyerIdStatusPublicationDateTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractByBuyerIdStatusPublicationDateTimeQuery = {
  contractByBuyerIdStatusPublicationDateTime?:  {
    __typename: "ModelContractConnection",
    items:  Array< {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractBySellerIdStatusPublicationDateTimeQueryVariables = {
  seller_id: string,
  statusPublication_date_time?: ModelContractBySellerIdStatusPublicationDateTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractBySellerIdStatusPublicationDateTimeQuery = {
  contractBySellerIdStatusPublicationDateTime?:  {
    __typename: "ModelContractConnection",
    items:  Array< {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContractProductQueryVariables = {
  id: string,
};

export type GetContractProductQuery = {
  getContractProduct?:  {
    __typename: "ContractProduct",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_display_name?: string | null,
    product_description?: string | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListContractProductsQueryVariables = {
  filter?: ModelContractProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContractProductsQuery = {
  listContractProducts?:  {
    __typename: "ModelContractProductConnection",
    items:  Array< {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractProductByContractIdQueryVariables = {
  contract_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractProductByContractIdQuery = {
  contractProductByContractId?:  {
    __typename: "ModelContractProductConnection",
    items:  Array< {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractProductByProductIdQueryVariables = {
  product_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractProductByProductIdQuery = {
  contractProductByProductId?:  {
    __typename: "ModelContractProductConnection",
    items:  Array< {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContractProductPriceQueryVariables = {
  id: string,
};

export type GetContractProductPriceQuery = {
  getContractProductPrice?:  {
    __typename: "ContractProductPrice",
    id: string,
    contract_product_id: string,
    contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_contract_product_id: string,
    extra_contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity?: number | null,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListContractProductPricesQueryVariables = {
  filter?: ModelContractProductPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContractProductPricesQuery = {
  listContractProductPrices?:  {
    __typename: "ModelContractProductPriceConnection",
    items:  Array< {
      __typename: "ContractProductPrice",
      id: string,
      contract_product_id: string,
      contract_product?:  {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      extra_contract_product_id: string,
      extra_contract_product?:  {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_id: string,
      price?:  {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_quantity?: number | null,
      price_display_name?: string | null,
      price_description?: string | null,
      price_type?: string | null,
      price_category?: string | null,
      price_fixed_amount?: string | null,
      price_fixed_centi_amount?: string | null,
      price_currency_code?: string | null,
      price_percentage?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractProductPriceByContractProductIdQueryVariables = {
  contract_product_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractProductPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractProductPriceByContractProductIdQuery = {
  contractProductPriceByContractProductId?:  {
    __typename: "ModelContractProductPriceConnection",
    items:  Array< {
      __typename: "ContractProductPrice",
      id: string,
      contract_product_id: string,
      contract_product?:  {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      extra_contract_product_id: string,
      extra_contract_product?:  {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_id: string,
      price?:  {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_quantity?: number | null,
      price_display_name?: string | null,
      price_description?: string | null,
      price_type?: string | null,
      price_category?: string | null,
      price_fixed_amount?: string | null,
      price_fixed_centi_amount?: string | null,
      price_currency_code?: string | null,
      price_percentage?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractProductPriceByExtraContractProductIdQueryVariables = {
  extra_contract_product_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractProductPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractProductPriceByExtraContractProductIdQuery = {
  contractProductPriceByExtraContractProductId?:  {
    __typename: "ModelContractProductPriceConnection",
    items:  Array< {
      __typename: "ContractProductPrice",
      id: string,
      contract_product_id: string,
      contract_product?:  {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      extra_contract_product_id: string,
      extra_contract_product?:  {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_id: string,
      price?:  {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_quantity?: number | null,
      price_display_name?: string | null,
      price_description?: string | null,
      price_type?: string | null,
      price_category?: string | null,
      price_fixed_amount?: string | null,
      price_fixed_centi_amount?: string | null,
      price_currency_code?: string | null,
      price_percentage?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractProductPriceByPriceIdQueryVariables = {
  price_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractProductPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractProductPriceByPriceIdQuery = {
  contractProductPriceByPriceId?:  {
    __typename: "ModelContractProductPriceConnection",
    items:  Array< {
      __typename: "ContractProductPrice",
      id: string,
      contract_product_id: string,
      contract_product?:  {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      extra_contract_product_id: string,
      extra_contract_product?:  {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_id: string,
      price?:  {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_quantity?: number | null,
      price_display_name?: string | null,
      price_description?: string | null,
      price_type?: string | null,
      price_category?: string | null,
      price_fixed_amount?: string | null,
      price_fixed_centi_amount?: string | null,
      price_currency_code?: string | null,
      price_percentage?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExtraOfferQueryVariables = {
  id: string,
};

export type GetExtraOfferQuery = {
  getExtraOffer?:  {
    __typename: "ExtraOffer",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListExtraOffersQueryVariables = {
  filter?: ModelExtraOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExtraOffersQuery = {
  listExtraOffers?:  {
    __typename: "ModelExtraOfferConnection",
    items:  Array< {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ExtraOfferByContractIdQueryVariables = {
  contract_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExtraOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExtraOfferByContractIdQuery = {
  extraOfferByContractId?:  {
    __typename: "ModelExtraOfferConnection",
    items:  Array< {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExtraOfferPriceQueryVariables = {
  id: string,
};

export type GetExtraOfferPriceQuery = {
  getExtraOfferPrice?:  {
    __typename: "ExtraOfferPrice",
    id: string,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity: number,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListExtraOfferPricesQueryVariables = {
  filter?: ModelExtraOfferPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExtraOfferPricesQuery = {
  listExtraOfferPrices?:  {
    __typename: "ModelExtraOfferPriceConnection",
    items:  Array< {
      __typename: "ExtraOfferPrice",
      id: string,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_id: string,
      price?:  {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_quantity: number,
      price_display_name?: string | null,
      price_description?: string | null,
      price_type?: string | null,
      price_category?: string | null,
      price_fixed_amount?: string | null,
      price_fixed_centi_amount?: string | null,
      price_currency_code?: string | null,
      price_percentage?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ExtraOfferPriceByExtraOfferIdQueryVariables = {
  extra_offer_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExtraOfferPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExtraOfferPriceByExtraOfferIdQuery = {
  extraOfferPriceByExtraOfferId?:  {
    __typename: "ModelExtraOfferPriceConnection",
    items:  Array< {
      __typename: "ExtraOfferPrice",
      id: string,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_id: string,
      price?:  {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_quantity: number,
      price_display_name?: string | null,
      price_description?: string | null,
      price_type?: string | null,
      price_category?: string | null,
      price_fixed_amount?: string | null,
      price_fixed_centi_amount?: string | null,
      price_currency_code?: string | null,
      price_percentage?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ExtraOfferPriceByPriceIdQueryVariables = {
  price_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExtraOfferPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExtraOfferPriceByPriceIdQuery = {
  extraOfferPriceByPriceId?:  {
    __typename: "ModelExtraOfferPriceConnection",
    items:  Array< {
      __typename: "ExtraOfferPrice",
      id: string,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_id: string,
      price?:  {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      price_quantity: number,
      price_display_name?: string | null,
      price_description?: string | null,
      price_type?: string | null,
      price_category?: string | null,
      price_fixed_amount?: string | null,
      price_fixed_centi_amount?: string | null,
      price_currency_code?: string | null,
      price_percentage?: number | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMilestoneQueryVariables = {
  id: string,
};

export type GetMilestoneQuery = {
  getMilestone?:  {
    __typename: "Milestone",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    step_index?: number | null,
    step_name?: string | null,
    deadline_number?: number | null,
    deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    delivery_date_time?: string | null,
    free_revision_count?: number | null,
    current_revision_index?: number | null,
    current_revision_id?: string | null,
    description?: string | null,
    deadline_extensions?: string | null,
    price_percentage?: number | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    revisions?:  {
      __typename: "ModelRevisionConnection",
      items:  Array< {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListMilestonesQueryVariables = {
  filter?: ModelMilestoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMilestonesQuery = {
  listMilestones?:  {
    __typename: "ModelMilestoneConnection",
    items:  Array< {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MilestoneByContractIdQueryVariables = {
  contract_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMilestoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MilestoneByContractIdQuery = {
  milestoneByContractId?:  {
    __typename: "ModelMilestoneConnection",
    items:  Array< {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRevisionQueryVariables = {
  id: string,
};

export type GetRevisionQuery = {
  getRevision?:  {
    __typename: "Revision",
    id: string,
    index?: number | null,
    status?: string | null,
    request_message?: string | null,
    request_rejection_message?: string | null,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    additional_deadline_number?: number | null,
    additional_deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    request_revision_images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    delivery_date_time?: string | null,
    deadline_extensions?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListRevisionsQueryVariables = {
  filter?: ModelRevisionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRevisionsQuery = {
  listRevisions?:  {
    __typename: "ModelRevisionConnection",
    items:  Array< {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RevisionByMilestoneIdQueryVariables = {
  milestone_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRevisionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RevisionByMilestoneIdQuery = {
  revisionByMilestoneId?:  {
    __typename: "ModelRevisionConnection",
    items:  Array< {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContractEventQueryVariables = {
  id: string,
};

export type GetContractEventQuery = {
  getContractEvent?:  {
    __typename: "ContractEvent",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_status?: string | null,
    contract_amount?: string | null,
    contract_currency?: string | null,
    extra_offer_status?: string | null,
    extra_offer_amount?: string | null,
    extra_offer_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListContractEventsQueryVariables = {
  filter?: ModelContractEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContractEventsQuery = {
  listContractEvents?:  {
    __typename: "ModelContractEventConnection",
    items:  Array< {
      __typename: "ContractEvent",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      event_id: string,
      event?:  {
        __typename: "Event",
        id: string,
        type?: string | null,
        note?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_status?: string | null,
      contract_amount?: string | null,
      contract_currency?: string | null,
      extra_offer_status?: string | null,
      extra_offer_amount?: string | null,
      extra_offer_currency?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractEventByContractIdQueryVariables = {
  contract_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractEventByContractIdQuery = {
  contractEventByContractId?:  {
    __typename: "ModelContractEventConnection",
    items:  Array< {
      __typename: "ContractEvent",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      event_id: string,
      event?:  {
        __typename: "Event",
        id: string,
        type?: string | null,
        note?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_status?: string | null,
      contract_amount?: string | null,
      contract_currency?: string | null,
      extra_offer_status?: string | null,
      extra_offer_amount?: string | null,
      extra_offer_currency?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractEventByEventIdQueryVariables = {
  event_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractEventByEventIdQuery = {
  contractEventByEventId?:  {
    __typename: "ModelContractEventConnection",
    items:  Array< {
      __typename: "ContractEvent",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      event_id: string,
      event?:  {
        __typename: "Event",
        id: string,
        type?: string | null,
        note?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_status?: string | null,
      contract_amount?: string | null,
      contract_currency?: string | null,
      extra_offer_status?: string | null,
      extra_offer_amount?: string | null,
      extra_offer_currency?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentIntentEventQueryVariables = {
  id: string,
};

export type GetPaymentIntentEventQuery = {
  getPaymentIntentEvent?:  {
    __typename: "PaymentIntentEvent",
    id: string,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    payment_intent_status?: string | null,
    payment_intent_amount?: string | null,
    payment_intent_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListPaymentIntentEventsQueryVariables = {
  filter?: ModelPaymentIntentEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentIntentEventsQuery = {
  listPaymentIntentEvents?:  {
    __typename: "ModelPaymentIntentEventConnection",
    items:  Array< {
      __typename: "PaymentIntentEvent",
      id: string,
      payment_intent_id: string,
      payment_intent?:  {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      event_id: string,
      event?:  {
        __typename: "Event",
        id: string,
        type?: string | null,
        note?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      payment_intent_status?: string | null,
      payment_intent_amount?: string | null,
      payment_intent_currency?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentIntentEventByPaymentIntentIdQueryVariables = {
  payment_intent_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentIntentEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentIntentEventByPaymentIntentIdQuery = {
  paymentIntentEventByPaymentIntentId?:  {
    __typename: "ModelPaymentIntentEventConnection",
    items:  Array< {
      __typename: "PaymentIntentEvent",
      id: string,
      payment_intent_id: string,
      payment_intent?:  {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      event_id: string,
      event?:  {
        __typename: "Event",
        id: string,
        type?: string | null,
        note?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      payment_intent_status?: string | null,
      payment_intent_amount?: string | null,
      payment_intent_currency?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentIntentEventByEventIdQueryVariables = {
  event_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentIntentEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentIntentEventByEventIdQuery = {
  paymentIntentEventByEventId?:  {
    __typename: "ModelPaymentIntentEventConnection",
    items:  Array< {
      __typename: "PaymentIntentEvent",
      id: string,
      payment_intent_id: string,
      payment_intent?:  {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      event_id: string,
      event?:  {
        __typename: "Event",
        id: string,
        type?: string | null,
        note?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      payment_intent_status?: string | null,
      payment_intent_amount?: string | null,
      payment_intent_currency?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMilestoneEventQueryVariables = {
  id: string,
};

export type GetMilestoneEventQuery = {
  getMilestoneEvent?:  {
    __typename: "MilestoneEvent",
    id: string,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_status?: string | null,
    milestone_step_index?: number | null,
    milestone_step_name?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListMilestoneEventsQueryVariables = {
  filter?: ModelMilestoneEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMilestoneEventsQuery = {
  listMilestoneEvents?:  {
    __typename: "ModelMilestoneEventConnection",
    items:  Array< {
      __typename: "MilestoneEvent",
      id: string,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      event_id: string,
      event?:  {
        __typename: "Event",
        id: string,
        type?: string | null,
        note?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      milestone_status?: string | null,
      milestone_step_index?: number | null,
      milestone_step_name?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MilestoneEventByMilestoneIdQueryVariables = {
  milestone_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMilestoneEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MilestoneEventByMilestoneIdQuery = {
  milestoneEventByMilestoneId?:  {
    __typename: "ModelMilestoneEventConnection",
    items:  Array< {
      __typename: "MilestoneEvent",
      id: string,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      event_id: string,
      event?:  {
        __typename: "Event",
        id: string,
        type?: string | null,
        note?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      milestone_status?: string | null,
      milestone_step_index?: number | null,
      milestone_step_name?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MilestoneEventByEventIdQueryVariables = {
  event_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMilestoneEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MilestoneEventByEventIdQuery = {
  milestoneEventByEventId?:  {
    __typename: "ModelMilestoneEventConnection",
    items:  Array< {
      __typename: "MilestoneEvent",
      id: string,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      event_id: string,
      event?:  {
        __typename: "Event",
        id: string,
        type?: string | null,
        note?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      milestone_status?: string | null,
      milestone_step_index?: number | null,
      milestone_step_name?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    type?: string | null,
    note?: string | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReviewQueryVariables = {
  id: string,
};

export type GetReviewQuery = {
  getReview?:  {
    __typename: "Review",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    reviewer_id: string,
    reviewer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    rating?: number | null,
    comment?: string | null,
    type?: string | null,
    publication_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsQuery = {
  listReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      reviewer_id: string,
      reviewer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      rating?: number | null,
      comment?: string | null,
      type?: string | null,
      publication_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewByUserIdTypePublicationDateTimeQueryVariables = {
  user_id: string,
  typePublication_date_time?: ModelReviewByUserIdTypePublicationDateTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewByUserIdTypePublicationDateTimeQuery = {
  reviewByUserIdTypePublicationDateTime?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      reviewer_id: string,
      reviewer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      rating?: number | null,
      comment?: string | null,
      type?: string | null,
      publication_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewByReviewerIdTypePublicationDateTimeQueryVariables = {
  reviewer_id: string,
  typePublication_date_time?: ModelReviewByReviewerIdTypePublicationDateTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewByReviewerIdTypePublicationDateTimeQuery = {
  reviewByReviewerIdTypePublicationDateTime?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      reviewer_id: string,
      reviewer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      rating?: number | null,
      comment?: string | null,
      type?: string | null,
      publication_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewByContractIdQueryVariables = {
  contract_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewByContractIdQuery = {
  reviewByContractId?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      reviewer_id: string,
      reviewer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      rating?: number | null,
      comment?: string | null,
      type?: string | null,
      publication_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserFollowQueryVariables = {
  id: string,
};

export type GetUserFollowQuery = {
  getUserFollow?:  {
    __typename: "UserFollow",
    id: string,
    leader_id: string,
    follower_id: string,
    leader?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    follower?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListUserFollowsQueryVariables = {
  filter?: ModelUserFollowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserFollowsQuery = {
  listUserFollows?:  {
    __typename: "ModelUserFollowConnection",
    items:  Array< {
      __typename: "UserFollow",
      id: string,
      leader_id: string,
      follower_id: string,
      leader?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      follower?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserFollowByLeaderIdCreatedAtQueryVariables = {
  leader_id: string,
  created_at?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFollowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserFollowByLeaderIdCreatedAtQuery = {
  userFollowByLeaderIdCreatedAt?:  {
    __typename: "ModelUserFollowConnection",
    items:  Array< {
      __typename: "UserFollow",
      id: string,
      leader_id: string,
      follower_id: string,
      leader?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      follower?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserFollowByLeaderIdFollowerIdQueryVariables = {
  leader_id: string,
  follower_id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFollowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserFollowByLeaderIdFollowerIdQuery = {
  userFollowByLeaderIdFollowerId?:  {
    __typename: "ModelUserFollowConnection",
    items:  Array< {
      __typename: "UserFollow",
      id: string,
      leader_id: string,
      follower_id: string,
      leader?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      follower?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserFollowByFollowerIdCreatedAtQueryVariables = {
  follower_id: string,
  created_at?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFollowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserFollowByFollowerIdCreatedAtQuery = {
  userFollowByFollowerIdCreatedAt?:  {
    __typename: "ModelUserFollowConnection",
    items:  Array< {
      __typename: "UserFollow",
      id: string,
      leader_id: string,
      follower_id: string,
      leader?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      follower?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDreamerlyBalanceQueryVariables = {
  id: string,
};

export type GetDreamerlyBalanceQuery = {
  getDreamerlyBalance?:  {
    __typename: "DreamerlyBalance",
    id: string,
    currency_code?: string | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListDreamerlyBalancesQueryVariables = {
  filter?: ModelDreamerlyBalanceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDreamerlyBalancesQuery = {
  listDreamerlyBalances?:  {
    __typename: "ModelDreamerlyBalanceConnection",
    items:  Array< {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBalanceTransferQueryVariables = {
  id: string,
};

export type GetBalanceTransferQuery = {
  getBalanceTransfer?:  {
    __typename: "BalanceTransfer",
    id: string,
    reason?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    dreamerly_fee?: string | null,
    dreamerly_centi_fee?: string | null,
    payment_provider_fee?: string | null,
    payment_provider_centi_fee?: string | null,
    net_amount?: string | null,
    net_centi_amount?: string | null,
    payment_provider?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_user_id: string,
    from_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_user_id: string,
    to_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_dreamerly_balance_id: string,
    from_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_dreamerly_balance_id: string,
    to_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListBalanceTransfersQueryVariables = {
  filter?: ModelBalanceTransferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBalanceTransfersQuery = {
  listBalanceTransfers?:  {
    __typename: "ModelBalanceTransferConnection",
    items:  Array< {
      __typename: "BalanceTransfer",
      id: string,
      reason?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      dreamerly_fee?: string | null,
      dreamerly_centi_fee?: string | null,
      payment_provider_fee?: string | null,
      payment_provider_centi_fee?: string | null,
      net_amount?: string | null,
      net_centi_amount?: string | null,
      payment_provider?: string | null,
      currency_code?: string | null,
      note?: string | null,
      publication_date_time?: string | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      from_user_id: string,
      from_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_user_id: string,
      to_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      from_dreamerly_balance_id: string,
      from_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_dreamerly_balance_id: string,
      to_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      balance_transaction_id: string,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BalanceTransferByMilestoneIdPublicationDateTimeQueryVariables = {
  milestone_id: string,
  publication_date_time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBalanceTransferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BalanceTransferByMilestoneIdPublicationDateTimeQuery = {
  balanceTransferByMilestoneIdPublicationDateTime?:  {
    __typename: "ModelBalanceTransferConnection",
    items:  Array< {
      __typename: "BalanceTransfer",
      id: string,
      reason?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      dreamerly_fee?: string | null,
      dreamerly_centi_fee?: string | null,
      payment_provider_fee?: string | null,
      payment_provider_centi_fee?: string | null,
      net_amount?: string | null,
      net_centi_amount?: string | null,
      payment_provider?: string | null,
      currency_code?: string | null,
      note?: string | null,
      publication_date_time?: string | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      from_user_id: string,
      from_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_user_id: string,
      to_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      from_dreamerly_balance_id: string,
      from_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_dreamerly_balance_id: string,
      to_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      balance_transaction_id: string,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BalanceTransferByContractIdPublicationDateTimeQueryVariables = {
  contract_id: string,
  publication_date_time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBalanceTransferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BalanceTransferByContractIdPublicationDateTimeQuery = {
  balanceTransferByContractIdPublicationDateTime?:  {
    __typename: "ModelBalanceTransferConnection",
    items:  Array< {
      __typename: "BalanceTransfer",
      id: string,
      reason?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      dreamerly_fee?: string | null,
      dreamerly_centi_fee?: string | null,
      payment_provider_fee?: string | null,
      payment_provider_centi_fee?: string | null,
      net_amount?: string | null,
      net_centi_amount?: string | null,
      payment_provider?: string | null,
      currency_code?: string | null,
      note?: string | null,
      publication_date_time?: string | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      from_user_id: string,
      from_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_user_id: string,
      to_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      from_dreamerly_balance_id: string,
      from_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_dreamerly_balance_id: string,
      to_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      balance_transaction_id: string,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BalanceTransferByFromUserIdPublicationDateTimeQueryVariables = {
  from_user_id: string,
  publication_date_time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBalanceTransferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BalanceTransferByFromUserIdPublicationDateTimeQuery = {
  balanceTransferByFromUserIdPublicationDateTime?:  {
    __typename: "ModelBalanceTransferConnection",
    items:  Array< {
      __typename: "BalanceTransfer",
      id: string,
      reason?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      dreamerly_fee?: string | null,
      dreamerly_centi_fee?: string | null,
      payment_provider_fee?: string | null,
      payment_provider_centi_fee?: string | null,
      net_amount?: string | null,
      net_centi_amount?: string | null,
      payment_provider?: string | null,
      currency_code?: string | null,
      note?: string | null,
      publication_date_time?: string | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      from_user_id: string,
      from_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_user_id: string,
      to_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      from_dreamerly_balance_id: string,
      from_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_dreamerly_balance_id: string,
      to_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      balance_transaction_id: string,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BalanceTransferByToUserIdPublicationDateTimeQueryVariables = {
  to_user_id: string,
  publication_date_time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBalanceTransferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BalanceTransferByToUserIdPublicationDateTimeQuery = {
  balanceTransferByToUserIdPublicationDateTime?:  {
    __typename: "ModelBalanceTransferConnection",
    items:  Array< {
      __typename: "BalanceTransfer",
      id: string,
      reason?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      dreamerly_fee?: string | null,
      dreamerly_centi_fee?: string | null,
      payment_provider_fee?: string | null,
      payment_provider_centi_fee?: string | null,
      net_amount?: string | null,
      net_centi_amount?: string | null,
      payment_provider?: string | null,
      currency_code?: string | null,
      note?: string | null,
      publication_date_time?: string | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      from_user_id: string,
      from_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_user_id: string,
      to_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      from_dreamerly_balance_id: string,
      from_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_dreamerly_balance_id: string,
      to_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      balance_transaction_id: string,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BalanceTransferByFromDreamerlyBalanceIdTypePublicationDateTimeQueryVariables = {
  from_dreamerly_balance_id: string,
  publication_date_time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBalanceTransferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BalanceTransferByFromDreamerlyBalanceIdTypePublicationDateTimeQuery = {
  balanceTransferByFromDreamerlyBalanceIdTypePublicationDateTime?:  {
    __typename: "ModelBalanceTransferConnection",
    items:  Array< {
      __typename: "BalanceTransfer",
      id: string,
      reason?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      dreamerly_fee?: string | null,
      dreamerly_centi_fee?: string | null,
      payment_provider_fee?: string | null,
      payment_provider_centi_fee?: string | null,
      net_amount?: string | null,
      net_centi_amount?: string | null,
      payment_provider?: string | null,
      currency_code?: string | null,
      note?: string | null,
      publication_date_time?: string | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      from_user_id: string,
      from_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_user_id: string,
      to_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      from_dreamerly_balance_id: string,
      from_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_dreamerly_balance_id: string,
      to_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      balance_transaction_id: string,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BalanceTransferByToDreamerlyBalanceIdPublicationDateTimeQueryVariables = {
  to_dreamerly_balance_id: string,
  publication_date_time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBalanceTransferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BalanceTransferByToDreamerlyBalanceIdPublicationDateTimeQuery = {
  balanceTransferByToDreamerlyBalanceIdPublicationDateTime?:  {
    __typename: "ModelBalanceTransferConnection",
    items:  Array< {
      __typename: "BalanceTransfer",
      id: string,
      reason?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      dreamerly_fee?: string | null,
      dreamerly_centi_fee?: string | null,
      payment_provider_fee?: string | null,
      payment_provider_centi_fee?: string | null,
      net_amount?: string | null,
      net_centi_amount?: string | null,
      payment_provider?: string | null,
      currency_code?: string | null,
      note?: string | null,
      publication_date_time?: string | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      from_user_id: string,
      from_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_user_id: string,
      to_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      from_dreamerly_balance_id: string,
      from_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_dreamerly_balance_id: string,
      to_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      balance_transaction_id: string,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BalanceTransferByBalanceTransactionIdQueryVariables = {
  balance_transaction_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBalanceTransferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BalanceTransferByBalanceTransactionIdQuery = {
  balanceTransferByBalanceTransactionId?:  {
    __typename: "ModelBalanceTransferConnection",
    items:  Array< {
      __typename: "BalanceTransfer",
      id: string,
      reason?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      dreamerly_fee?: string | null,
      dreamerly_centi_fee?: string | null,
      payment_provider_fee?: string | null,
      payment_provider_centi_fee?: string | null,
      net_amount?: string | null,
      net_centi_amount?: string | null,
      payment_provider?: string | null,
      currency_code?: string | null,
      note?: string | null,
      publication_date_time?: string | null,
      milestone_id?: string | null,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      from_user_id: string,
      from_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_user_id: string,
      to_user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      from_dreamerly_balance_id: string,
      from_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      to_dreamerly_balance_id: string,
      to_dreamerly_balance?:  {
        __typename: "DreamerlyBalance",
        id: string,
        currency_code?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      balance_transaction_id: string,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentProviderFeePaymentQueryVariables = {
  id: string,
};

export type GetPaymentProviderFeePaymentQuery = {
  getPaymentProviderFeePayment?:  {
    __typename: "PaymentProviderFeePayment",
    id: string,
    amount: string,
    centi_amount: string,
    currency_code: string,
    publication_date_time?: string | null,
    payment_provider?: string | null,
    description?: string | null,
    invoice_number?: string | null,
    from_dreamerly_balance_id: string,
    to_dreamerly_balance_id: string,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListPaymentProviderFeePaymentsQueryVariables = {
  filter?: ModelPaymentProviderFeePaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentProviderFeePaymentsQuery = {
  listPaymentProviderFeePayments?:  {
    __typename: "ModelPaymentProviderFeePaymentConnection",
    items:  Array< {
      __typename: "PaymentProviderFeePayment",
      id: string,
      amount: string,
      centi_amount: string,
      currency_code: string,
      publication_date_time?: string | null,
      payment_provider?: string | null,
      description?: string | null,
      invoice_number?: string | null,
      from_dreamerly_balance_id: string,
      to_dreamerly_balance_id: string,
      balance_transaction_id: string,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentProviderFeePaymentByBalanceTransactionIdQueryVariables = {
  balance_transaction_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentProviderFeePaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentProviderFeePaymentByBalanceTransactionIdQuery = {
  paymentProviderFeePaymentByBalanceTransactionId?:  {
    __typename: "ModelPaymentProviderFeePaymentConnection",
    items:  Array< {
      __typename: "PaymentProviderFeePayment",
      id: string,
      amount: string,
      centi_amount: string,
      currency_code: string,
      publication_date_time?: string | null,
      payment_provider?: string | null,
      description?: string | null,
      invoice_number?: string | null,
      from_dreamerly_balance_id: string,
      to_dreamerly_balance_id: string,
      balance_transaction_id: string,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBalanceTransactionQueryVariables = {
  id: string,
};

export type GetBalanceTransactionQuery = {
  getBalanceTransaction?:  {
    __typename: "BalanceTransaction",
    id: string,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_provider_fee_payments?:  {
      __typename: "ModelPaymentProviderFeePaymentConnection",
      items:  Array< {
        __typename: "PaymentProviderFeePayment",
        id: string,
        amount: string,
        centi_amount: string,
        currency_code: string,
        publication_date_time?: string | null,
        payment_provider?: string | null,
        description?: string | null,
        invoice_number?: string | null,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    type?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListBalanceTransactionsQueryVariables = {
  filter?: ModelBalanceTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBalanceTransactionsQuery = {
  listBalanceTransactions?:  {
    __typename: "ModelBalanceTransactionConnection",
    items:  Array< {
      __typename: "BalanceTransaction",
      id: string,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      payment_provider_fee_payments?:  {
        __typename: "ModelPaymentProviderFeePaymentConnection",
        nextToken?: string | null,
      } | null,
      type?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      note?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BalanceTransactionByUserIdTypePublicationDateTimeQueryVariables = {
  user_id: string,
  typePublication_date_time?: ModelBalanceTransactionByUserIdTypePublicationDateTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBalanceTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BalanceTransactionByUserIdTypePublicationDateTimeQuery = {
  balanceTransactionByUserIdTypePublicationDateTime?:  {
    __typename: "ModelBalanceTransactionConnection",
    items:  Array< {
      __typename: "BalanceTransaction",
      id: string,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      payment_provider_fee_payments?:  {
        __typename: "ModelPaymentProviderFeePaymentConnection",
        nextToken?: string | null,
      } | null,
      type?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      note?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    notification_sender_id?: string | null,
    notification_sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    title?: string | null,
    body?: string | null,
    publication_date_time?: string | null,
    is_read?: number | null,
    is_removed?: number | null,
    notification_type?: string | null,
    underlying_object_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      notification_sender_id?: string | null,
      notification_sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      title?: string | null,
      body?: string | null,
      publication_date_time?: string | null,
      is_read?: number | null,
      is_removed?: number | null,
      notification_type?: string | null,
      underlying_object_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationByUserIdPublicationDateTimeQueryVariables = {
  user_id: string,
  publication_date_time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationByUserIdPublicationDateTimeQuery = {
  notificationByUserIdPublicationDateTime?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      notification_sender_id?: string | null,
      notification_sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      title?: string | null,
      body?: string | null,
      publication_date_time?: string | null,
      is_read?: number | null,
      is_removed?: number | null,
      notification_type?: string | null,
      underlying_object_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationByUserIdIsReadPublicationDateTimeQueryVariables = {
  user_id: string,
  is_readPublication_date_time?: ModelNotificationByUserIdIsReadPublicationDateTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationByUserIdIsReadPublicationDateTimeQuery = {
  notificationByUserIdIsReadPublicationDateTime?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      notification_sender_id?: string | null,
      notification_sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      title?: string | null,
      body?: string | null,
      publication_date_time?: string | null,
      is_read?: number | null,
      is_removed?: number | null,
      notification_type?: string | null,
      underlying_object_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationByNotificationSenderIdPublicationDateTimeQueryVariables = {
  notification_sender_id: string,
  publication_date_time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationByNotificationSenderIdPublicationDateTimeQuery = {
  notificationByNotificationSenderIdPublicationDateTime?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      notification_sender_id?: string | null,
      notification_sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      title?: string | null,
      body?: string | null,
      publication_date_time?: string | null,
      is_read?: number | null,
      is_removed?: number | null,
      notification_type?: string | null,
      underlying_object_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCharacterQueryVariables = {
  id: string,
};

export type GetCharacterQuery = {
  getCharacter?:  {
    __typename: "Character",
    id: string,
    display_name?: string | null,
    charname?: string | null,
    character_type?: string | null,
    user_id?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    strengths?: string | null,
    flaws?: string | null,
    gender?: string | null,
    race?: string | null,
    birth_date?: string | null,
    age?: string | null,
    role?: string | null,
    hobbies?: string | null,
    dislikes?: string | null,
    profile_picture_url?: string | null,
    cover_photo_url?: string | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListCharactersQueryVariables = {
  filter?: ModelCharacterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCharactersQuery = {
  listCharacters?:  {
    __typename: "ModelCharacterConnection",
    items:  Array< {
      __typename: "Character",
      id: string,
      display_name?: string | null,
      charname?: string | null,
      character_type?: string | null,
      user_id?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      strengths?: string | null,
      flaws?: string | null,
      gender?: string | null,
      race?: string | null,
      birth_date?: string | null,
      age?: string | null,
      role?: string | null,
      hobbies?: string | null,
      dislikes?: string | null,
      profile_picture_url?: string | null,
      cover_photo_url?: string | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CharacterByCharnameQueryVariables = {
  charname: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCharacterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CharacterByCharnameQuery = {
  characterByCharname?:  {
    __typename: "ModelCharacterConnection",
    items:  Array< {
      __typename: "Character",
      id: string,
      display_name?: string | null,
      charname?: string | null,
      character_type?: string | null,
      user_id?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      strengths?: string | null,
      flaws?: string | null,
      gender?: string | null,
      race?: string | null,
      birth_date?: string | null,
      age?: string | null,
      role?: string | null,
      hobbies?: string | null,
      dislikes?: string | null,
      profile_picture_url?: string | null,
      cover_photo_url?: string | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CharacterByUserIdQueryVariables = {
  user_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCharacterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CharacterByUserIdQuery = {
  characterByUserId?:  {
    __typename: "ModelCharacterConnection",
    items:  Array< {
      __typename: "Character",
      id: string,
      display_name?: string | null,
      charname?: string | null,
      character_type?: string | null,
      user_id?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      strengths?: string | null,
      flaws?: string | null,
      gender?: string | null,
      race?: string | null,
      birth_date?: string | null,
      age?: string | null,
      role?: string | null,
      hobbies?: string | null,
      dislikes?: string | null,
      profile_picture_url?: string | null,
      cover_photo_url?: string | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCharacterItemQueryVariables = {
  id: string,
};

export type GetCharacterItemQuery = {
  getCharacterItem?:  {
    __typename: "CharacterItem",
    id: string,
    item_type?: string | null,
    character_id: string,
    character?:  {
      __typename: "Character",
      id: string,
      display_name?: string | null,
      charname?: string | null,
      character_type?: string | null,
      user_id?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      strengths?: string | null,
      flaws?: string | null,
      gender?: string | null,
      race?: string | null,
      birth_date?: string | null,
      age?: string | null,
      role?: string | null,
      hobbies?: string | null,
      dislikes?: string | null,
      profile_picture_url?: string | null,
      cover_photo_url?: string | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListCharacterItemsQueryVariables = {
  filter?: ModelCharacterItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCharacterItemsQuery = {
  listCharacterItems?:  {
    __typename: "ModelCharacterItemConnection",
    items:  Array< {
      __typename: "CharacterItem",
      id: string,
      item_type?: string | null,
      character_id: string,
      character?:  {
        __typename: "Character",
        id: string,
        display_name?: string | null,
        charname?: string | null,
        character_type?: string | null,
        user_id?: string | null,
        description?: string | null,
        strengths?: string | null,
        flaws?: string | null,
        gender?: string | null,
        race?: string | null,
        birth_date?: string | null,
        age?: string | null,
        role?: string | null,
        hobbies?: string | null,
        dislikes?: string | null,
        profile_picture_url?: string | null,
        cover_photo_url?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      artwork_id?: string | null,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      job_id?: string | null,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CharacterItemByCharacterIdItemTypeQueryVariables = {
  character_id: string,
  item_type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCharacterItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CharacterItemByCharacterIdItemTypeQuery = {
  characterItemByCharacterIdItemType?:  {
    __typename: "ModelCharacterItemConnection",
    items:  Array< {
      __typename: "CharacterItem",
      id: string,
      item_type?: string | null,
      character_id: string,
      character?:  {
        __typename: "Character",
        id: string,
        display_name?: string | null,
        charname?: string | null,
        character_type?: string | null,
        user_id?: string | null,
        description?: string | null,
        strengths?: string | null,
        flaws?: string | null,
        gender?: string | null,
        race?: string | null,
        birth_date?: string | null,
        age?: string | null,
        role?: string | null,
        hobbies?: string | null,
        dislikes?: string | null,
        profile_picture_url?: string | null,
        cover_photo_url?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      artwork_id?: string | null,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      job_id?: string | null,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CharacterItemByArtworkIdQueryVariables = {
  artwork_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCharacterItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CharacterItemByArtworkIdQuery = {
  characterItemByArtworkId?:  {
    __typename: "ModelCharacterItemConnection",
    items:  Array< {
      __typename: "CharacterItem",
      id: string,
      item_type?: string | null,
      character_id: string,
      character?:  {
        __typename: "Character",
        id: string,
        display_name?: string | null,
        charname?: string | null,
        character_type?: string | null,
        user_id?: string | null,
        description?: string | null,
        strengths?: string | null,
        flaws?: string | null,
        gender?: string | null,
        race?: string | null,
        birth_date?: string | null,
        age?: string | null,
        role?: string | null,
        hobbies?: string | null,
        dislikes?: string | null,
        profile_picture_url?: string | null,
        cover_photo_url?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      artwork_id?: string | null,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      job_id?: string | null,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CharacterItemByJobIdQueryVariables = {
  job_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCharacterItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CharacterItemByJobIdQuery = {
  characterItemByJobId?:  {
    __typename: "ModelCharacterItemConnection",
    items:  Array< {
      __typename: "CharacterItem",
      id: string,
      item_type?: string | null,
      character_id: string,
      character?:  {
        __typename: "Character",
        id: string,
        display_name?: string | null,
        charname?: string | null,
        character_type?: string | null,
        user_id?: string | null,
        description?: string | null,
        strengths?: string | null,
        flaws?: string | null,
        gender?: string | null,
        race?: string | null,
        birth_date?: string | null,
        age?: string | null,
        role?: string | null,
        hobbies?: string | null,
        dislikes?: string | null,
        profile_picture_url?: string | null,
        cover_photo_url?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      artwork_id?: string | null,
      artwork?:  {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      job_id?: string | null,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      created_at: string,
      updated_at: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnCreateTagSubscription = {
  onCreateTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    display_name?: string | null,
    status?: string | null,
    total_artwork_quantity?: number | null,
    tag_artwork_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnUpdateTagSubscription = {
  onUpdateTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    display_name?: string | null,
    status?: string | null,
    total_artwork_quantity?: number | null,
    tag_artwork_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnDeleteTagSubscription = {
  onDeleteTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    display_name?: string | null,
    status?: string | null,
    total_artwork_quantity?: number | null,
    tag_artwork_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateTagItemSubscriptionVariables = {
  filter?: ModelSubscriptionTagItemFilterInput | null,
};

export type OnCreateTagItemSubscription = {
  onCreateTagItem?:  {
    __typename: "TagItem",
    id: string,
    tag_id: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      tag_name?: string | null,
      display_name?: string | null,
      status?: string | null,
      total_artwork_quantity?: number | null,
      tag_artwork_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    tag_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateTagItemSubscriptionVariables = {
  filter?: ModelSubscriptionTagItemFilterInput | null,
};

export type OnUpdateTagItemSubscription = {
  onUpdateTagItem?:  {
    __typename: "TagItem",
    id: string,
    tag_id: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      tag_name?: string | null,
      display_name?: string | null,
      status?: string | null,
      total_artwork_quantity?: number | null,
      tag_artwork_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    tag_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteTagItemSubscriptionVariables = {
  filter?: ModelSubscriptionTagItemFilterInput | null,
};

export type OnDeleteTagItemSubscription = {
  onDeleteTagItem?:  {
    __typename: "TagItem",
    id: string,
    tag_id: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      tag_name?: string | null,
      display_name?: string | null,
      status?: string | null,
      total_artwork_quantity?: number | null,
      tag_artwork_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    tag_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateArtworkSubscriptionVariables = {
  filter?: ModelSubscriptionArtworkFilterInput | null,
};

export type OnCreateArtworkSubscription = {
  onCreateArtwork?:  {
    __typename: "Artwork",
    id: string,
    cover_image_height?: number | null,
    cover_image_width?: number | null,
    cover_image_src_url?: string | null,
    cover_image_thumbnail_url?: string | null,
    title?: string | null,
    likes_count?: number | null,
    comments_count?: number | null,
    description?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_buyer_showcase?: number | null,
    is_seller_showcase?: number | null,
    tag_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateArtworkSubscriptionVariables = {
  filter?: ModelSubscriptionArtworkFilterInput | null,
};

export type OnUpdateArtworkSubscription = {
  onUpdateArtwork?:  {
    __typename: "Artwork",
    id: string,
    cover_image_height?: number | null,
    cover_image_width?: number | null,
    cover_image_src_url?: string | null,
    cover_image_thumbnail_url?: string | null,
    title?: string | null,
    likes_count?: number | null,
    comments_count?: number | null,
    description?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_buyer_showcase?: number | null,
    is_seller_showcase?: number | null,
    tag_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteArtworkSubscriptionVariables = {
  filter?: ModelSubscriptionArtworkFilterInput | null,
};

export type OnDeleteArtworkSubscription = {
  onDeleteArtwork?:  {
    __typename: "Artwork",
    id: string,
    cover_image_height?: number | null,
    cover_image_width?: number | null,
    cover_image_src_url?: string | null,
    cover_image_thumbnail_url?: string | null,
    title?: string | null,
    likes_count?: number | null,
    comments_count?: number | null,
    description?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_buyer_showcase?: number | null,
    is_seller_showcase?: number | null,
    tag_items?:  {
      __typename: "ModelTagItemConnection",
      items:  Array< {
        __typename: "TagItem",
        id: string,
        tag_id: string,
        artwork_id: string,
        tag_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateProductArtworkSubscriptionVariables = {
  filter?: ModelSubscriptionProductArtworkFilterInput | null,
};

export type OnCreateProductArtworkSubscription = {
  onCreateProductArtwork?:  {
    __typename: "ProductArtwork",
    id: string,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    index?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateProductArtworkSubscriptionVariables = {
  filter?: ModelSubscriptionProductArtworkFilterInput | null,
};

export type OnUpdateProductArtworkSubscription = {
  onUpdateProductArtwork?:  {
    __typename: "ProductArtwork",
    id: string,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    index?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteProductArtworkSubscriptionVariables = {
  filter?: ModelSubscriptionProductArtworkFilterInput | null,
};

export type OnDeleteProductArtworkSubscription = {
  onDeleteProductArtwork?:  {
    __typename: "ProductArtwork",
    id: string,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    index?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    display_name?: string | null,
    status?: string | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    display_name?: string | null,
    status?: string | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    display_name?: string | null,
    status?: string | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateImageSubscriptionVariables = {
  filter?: ModelSubscriptionImageFilterInput | null,
};

export type OnCreateImageSubscription = {
  onCreateImage?:  {
    __typename: "Image",
    id: string,
    height?: number | null,
    width?: number | null,
    file_name?: string | null,
    src_url?: string | null,
    thumbnail_url?: string | null,
    raw_src_url?: string | null,
    raw_thumbnail_url?: string | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_id?: string | null,
    revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_request_revision_id?: string | null,
    revision_request_revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    message_id?: string | null,
    message?:  {
      __typename: "Message",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      sender_id: string,
      sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      content?: string | null,
      image_url?: string | null,
      publication_date_time?: string | null,
      available_to?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateImageSubscriptionVariables = {
  filter?: ModelSubscriptionImageFilterInput | null,
};

export type OnUpdateImageSubscription = {
  onUpdateImage?:  {
    __typename: "Image",
    id: string,
    height?: number | null,
    width?: number | null,
    file_name?: string | null,
    src_url?: string | null,
    thumbnail_url?: string | null,
    raw_src_url?: string | null,
    raw_thumbnail_url?: string | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_id?: string | null,
    revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_request_revision_id?: string | null,
    revision_request_revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    message_id?: string | null,
    message?:  {
      __typename: "Message",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      sender_id: string,
      sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      content?: string | null,
      image_url?: string | null,
      publication_date_time?: string | null,
      available_to?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteImageSubscriptionVariables = {
  filter?: ModelSubscriptionImageFilterInput | null,
};

export type OnDeleteImageSubscription = {
  onDeleteImage?:  {
    __typename: "Image",
    id: string,
    height?: number | null,
    width?: number | null,
    file_name?: string | null,
    src_url?: string | null,
    thumbnail_url?: string | null,
    raw_src_url?: string | null,
    raw_thumbnail_url?: string | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_id?: string | null,
    revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    revision_request_revision_id?: string | null,
    revision_request_revision?:  {
      __typename: "Revision",
      id: string,
      index?: number | null,
      status?: string | null,
      request_message?: string | null,
      request_rejection_message?: string | null,
      milestone_id: string,
      milestone?:  {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      additional_deadline_number?: number | null,
      additional_deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      request_revision_images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      delivery_date_time?: string | null,
      deadline_extensions?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    message_id?: string | null,
    message?:  {
      __typename: "Message",
      id: string,
      channel_id: string,
      channel?:  {
        __typename: "Channel",
        id: string,
        name?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      sender_id: string,
      sender?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      content?: string | null,
      image_url?: string | null,
      publication_date_time?: string | null,
      available_to?: string | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateLikeSubscriptionVariables = {
  filter?: ModelSubscriptionLikeFilterInput | null,
};

export type OnCreateLikeSubscription = {
  onCreateLike?:  {
    __typename: "Like",
    id: string,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateLikeSubscriptionVariables = {
  filter?: ModelSubscriptionLikeFilterInput | null,
};

export type OnUpdateLikeSubscription = {
  onUpdateLike?:  {
    __typename: "Like",
    id: string,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteLikeSubscriptionVariables = {
  filter?: ModelSubscriptionLikeFilterInput | null,
};

export type OnDeleteLikeSubscription = {
  onDeleteLike?:  {
    __typename: "Like",
    id: string,
    artwork_id: string,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    is_removed?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    display_name?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    username?: string | null,
    email_address?: string | null,
    birth_date?: string | null,
    phone_number?: string | null,
    country_code?: string | null,
    subscriber_number?: string | null,
    bio?: string | null,
    is_artist?: number | null,
    seller_onboarding_stage?: string | null,
    buyer_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    term_of_service?: string | null,
    cccd?: string | null,
    mst?: string | null,
    cccd_images?: Array< string | null > | null,
    mst_stage?: string | null,
    seller_proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    profile_picture_url?: string | null,
    cover_picture_url?: string | null,
    balance_amount?: string | null,
    balance_centi_amount?: string | null,
    balance_currency_code?: string | null,
    usd_balance_amount?: string | null,
    usd_balance_centi_amount?: string | null,
    contract_paid_quantity?: number | null,
    seller_star_quantity?: number | null,
    seller_review_quantity?: number | null,
    buyer_star_quantity?: number | null,
    buyer_review_quantity?: number | null,
    commision_started_quantity?: number | null,
    commission_completed_quantity?: number | null,
    commission_completed_on_time_quantity?: number | null,
    commission_cancelled_seller_fault_quantity?: number | null,
    commission_cancelled_buyer_fault_quantity?: number | null,
    commission_cancelled_mutually_quantity?: number | null,
    commission_cumulative_late_seconds?: number | null,
    commission_max_late_seconds?: number | null,
    device_tokens?: Array< string | null > | null,
    is_selling?: number | null,
    is_selling_abroad?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    last_time_open_list_notification?: string | null,
    discount_code?: string | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    selling_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buying_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    given_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transactions?:  {
      __typename: "ModelBalanceTransactionConnection",
      items:  Array< {
        __typename: "BalanceTransaction",
        id: string,
        type?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    follower_quantity?: number | null,
    leader_quantity?: number | null,
    followers?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    leaders?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notification_senders?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    push_tokens?:  {
      __typename: "ModelPushTokenConnection",
      items:  Array< {
        __typename: "PushToken",
        id: string,
        user_id: string,
        push_token?: string | null,
        device_type?: string | null,
        last_used_at?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    has_unread_commissions?: number | null,
    has_unread_jobs?: number | null,
    message_cumulative_response_seconds?: number | null,
    message_response_on_time_quantity?: number | null,
    message_response_late_quantity?: number | null,
    characters?:  {
      __typename: "ModelCharacterConnection",
      items:  Array< {
        __typename: "Character",
        id: string,
        display_name?: string | null,
        charname?: string | null,
        character_type?: string | null,
        user_id?: string | null,
        description?: string | null,
        strengths?: string | null,
        flaws?: string | null,
        gender?: string | null,
        race?: string | null,
        birth_date?: string | null,
        age?: string | null,
        role?: string | null,
        hobbies?: string | null,
        dislikes?: string | null,
        profile_picture_url?: string | null,
        cover_photo_url?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_last_four?: string | null,
    address_country_code?: string | null,
    city?: string | null,
    address_line1?: string | null,
    additional_info?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    display_name?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    username?: string | null,
    email_address?: string | null,
    birth_date?: string | null,
    phone_number?: string | null,
    country_code?: string | null,
    subscriber_number?: string | null,
    bio?: string | null,
    is_artist?: number | null,
    seller_onboarding_stage?: string | null,
    buyer_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    term_of_service?: string | null,
    cccd?: string | null,
    mst?: string | null,
    cccd_images?: Array< string | null > | null,
    mst_stage?: string | null,
    seller_proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    profile_picture_url?: string | null,
    cover_picture_url?: string | null,
    balance_amount?: string | null,
    balance_centi_amount?: string | null,
    balance_currency_code?: string | null,
    usd_balance_amount?: string | null,
    usd_balance_centi_amount?: string | null,
    contract_paid_quantity?: number | null,
    seller_star_quantity?: number | null,
    seller_review_quantity?: number | null,
    buyer_star_quantity?: number | null,
    buyer_review_quantity?: number | null,
    commision_started_quantity?: number | null,
    commission_completed_quantity?: number | null,
    commission_completed_on_time_quantity?: number | null,
    commission_cancelled_seller_fault_quantity?: number | null,
    commission_cancelled_buyer_fault_quantity?: number | null,
    commission_cancelled_mutually_quantity?: number | null,
    commission_cumulative_late_seconds?: number | null,
    commission_max_late_seconds?: number | null,
    device_tokens?: Array< string | null > | null,
    is_selling?: number | null,
    is_selling_abroad?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    last_time_open_list_notification?: string | null,
    discount_code?: string | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    selling_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buying_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    given_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transactions?:  {
      __typename: "ModelBalanceTransactionConnection",
      items:  Array< {
        __typename: "BalanceTransaction",
        id: string,
        type?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    follower_quantity?: number | null,
    leader_quantity?: number | null,
    followers?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    leaders?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notification_senders?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    push_tokens?:  {
      __typename: "ModelPushTokenConnection",
      items:  Array< {
        __typename: "PushToken",
        id: string,
        user_id: string,
        push_token?: string | null,
        device_type?: string | null,
        last_used_at?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    has_unread_commissions?: number | null,
    has_unread_jobs?: number | null,
    message_cumulative_response_seconds?: number | null,
    message_response_on_time_quantity?: number | null,
    message_response_late_quantity?: number | null,
    characters?:  {
      __typename: "ModelCharacterConnection",
      items:  Array< {
        __typename: "Character",
        id: string,
        display_name?: string | null,
        charname?: string | null,
        character_type?: string | null,
        user_id?: string | null,
        description?: string | null,
        strengths?: string | null,
        flaws?: string | null,
        gender?: string | null,
        race?: string | null,
        birth_date?: string | null,
        age?: string | null,
        role?: string | null,
        hobbies?: string | null,
        dislikes?: string | null,
        profile_picture_url?: string | null,
        cover_photo_url?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_last_four?: string | null,
    address_country_code?: string | null,
    city?: string | null,
    address_line1?: string | null,
    additional_info?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    display_name?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    username?: string | null,
    email_address?: string | null,
    birth_date?: string | null,
    phone_number?: string | null,
    country_code?: string | null,
    subscriber_number?: string | null,
    bio?: string | null,
    is_artist?: number | null,
    seller_onboarding_stage?: string | null,
    buyer_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    term_of_service?: string | null,
    cccd?: string | null,
    mst?: string | null,
    cccd_images?: Array< string | null > | null,
    mst_stage?: string | null,
    seller_proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    profile_picture_url?: string | null,
    cover_picture_url?: string | null,
    balance_amount?: string | null,
    balance_centi_amount?: string | null,
    balance_currency_code?: string | null,
    usd_balance_amount?: string | null,
    usd_balance_centi_amount?: string | null,
    contract_paid_quantity?: number | null,
    seller_star_quantity?: number | null,
    seller_review_quantity?: number | null,
    buyer_star_quantity?: number | null,
    buyer_review_quantity?: number | null,
    commision_started_quantity?: number | null,
    commission_completed_quantity?: number | null,
    commission_completed_on_time_quantity?: number | null,
    commission_cancelled_seller_fault_quantity?: number | null,
    commission_cancelled_buyer_fault_quantity?: number | null,
    commission_cancelled_mutually_quantity?: number | null,
    commission_cumulative_late_seconds?: number | null,
    commission_max_late_seconds?: number | null,
    device_tokens?: Array< string | null > | null,
    is_selling?: number | null,
    is_selling_abroad?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    last_time_open_list_notification?: string | null,
    discount_code?: string | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        artwork_id: string,
        user_id: string,
        is_removed?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    selling_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buying_refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    seller_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyer_contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    given_reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transactions?:  {
      __typename: "ModelBalanceTransactionConnection",
      items:  Array< {
        __typename: "BalanceTransaction",
        id: string,
        type?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    follower_quantity?: number | null,
    leader_quantity?: number | null,
    followers?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    leaders?:  {
      __typename: "ModelUserFollowConnection",
      items:  Array< {
        __typename: "UserFollow",
        id: string,
        leader_id: string,
        follower_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notification_senders?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        user_id: string,
        notification_sender_id?: string | null,
        title?: string | null,
        body?: string | null,
        publication_date_time?: string | null,
        is_read?: number | null,
        is_removed?: number | null,
        notification_type?: string | null,
        underlying_object_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    push_tokens?:  {
      __typename: "ModelPushTokenConnection",
      items:  Array< {
        __typename: "PushToken",
        id: string,
        user_id: string,
        push_token?: string | null,
        device_type?: string | null,
        last_used_at?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    has_unread_commissions?: number | null,
    has_unread_jobs?: number | null,
    message_cumulative_response_seconds?: number | null,
    message_response_on_time_quantity?: number | null,
    message_response_late_quantity?: number | null,
    characters?:  {
      __typename: "ModelCharacterConnection",
      items:  Array< {
        __typename: "Character",
        id: string,
        display_name?: string | null,
        charname?: string | null,
        character_type?: string | null,
        user_id?: string | null,
        description?: string | null,
        strengths?: string | null,
        flaws?: string | null,
        gender?: string | null,
        race?: string | null,
        birth_date?: string | null,
        age?: string | null,
        role?: string | null,
        hobbies?: string | null,
        dislikes?: string | null,
        profile_picture_url?: string | null,
        cover_photo_url?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_last_four?: string | null,
    address_country_code?: string | null,
    city?: string | null,
    address_line1?: string | null,
    additional_info?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateBankAccountSubscriptionVariables = {
  filter?: ModelSubscriptionBankAccountFilterInput | null,
};

export type OnCreateBankAccountSubscription = {
  onCreateBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    user_id: string,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_number?: string | null,
    currency_code?: string | null,
    country_code?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateBankAccountSubscriptionVariables = {
  filter?: ModelSubscriptionBankAccountFilterInput | null,
};

export type OnUpdateBankAccountSubscription = {
  onUpdateBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    user_id: string,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_number?: string | null,
    currency_code?: string | null,
    country_code?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteBankAccountSubscriptionVariables = {
  filter?: ModelSubscriptionBankAccountFilterInput | null,
};

export type OnDeleteBankAccountSubscription = {
  onDeleteBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    user_id: string,
    bank_name?: string | null,
    account_holder_name?: string | null,
    account_number?: string | null,
    currency_code?: string | null,
    country_code?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreatePushTokenSubscriptionVariables = {
  filter?: ModelSubscriptionPushTokenFilterInput | null,
};

export type OnCreatePushTokenSubscription = {
  onCreatePushToken?:  {
    __typename: "PushToken",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    push_token?: string | null,
    device_type?: string | null,
    last_used_at?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdatePushTokenSubscriptionVariables = {
  filter?: ModelSubscriptionPushTokenFilterInput | null,
};

export type OnUpdatePushTokenSubscription = {
  onUpdatePushToken?:  {
    __typename: "PushToken",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    push_token?: string | null,
    device_type?: string | null,
    last_used_at?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeletePushTokenSubscriptionVariables = {
  filter?: ModelSubscriptionPushTokenFilterInput | null,
};

export type OnDeletePushTokenSubscription = {
  onDeletePushToken?:  {
    __typename: "PushToken",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    push_token?: string | null,
    device_type?: string | null,
    last_used_at?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateUserBlockSubscriptionVariables = {
  filter?: ModelSubscriptionUserBlockFilterInput | null,
};

export type OnCreateUserBlockSubscription = {
  onCreateUserBlock?:  {
    __typename: "UserBlock",
    id: string,
    user_give_block_id?: string | null,
    user_receive_block_id?: string | null,
    type?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateUserBlockSubscriptionVariables = {
  filter?: ModelSubscriptionUserBlockFilterInput | null,
};

export type OnUpdateUserBlockSubscription = {
  onUpdateUserBlock?:  {
    __typename: "UserBlock",
    id: string,
    user_give_block_id?: string | null,
    user_receive_block_id?: string | null,
    type?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteUserBlockSubscriptionVariables = {
  filter?: ModelSubscriptionUserBlockFilterInput | null,
};

export type OnDeleteUserBlockSubscription = {
  onDeleteUserBlock?:  {
    __typename: "UserBlock",
    id: string,
    user_give_block_id?: string | null,
    user_receive_block_id?: string | null,
    type?: string | null,
    status?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    artist_id: string,
    artist?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name: string,
    description?: string | null,
    status: string,
    prices?:  {
      __typename: "ModelPriceConnection",
      items:  Array< {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    slug?: string | null,
    category_id?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      display_name?: string | null,
      status?: string | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    min_price_amount?: string | null,
    max_price_amount?: string | null,
    currency_code?: string | null,
    product_star_quantity?: number | null,
    product_review_quantity?: number | null,
    commission_paid_quantity?: number | null,
    artist_other_artworks?:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    reviews?:  Array< {
      __typename: "Review",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      reviewer_id: string,
      reviewer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      rating?: number | null,
      comment?: string | null,
      type?: string | null,
      publication_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    dreambox_response_time?: number | null,
    dreambox_complete_time?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    artist_id: string,
    artist?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name: string,
    description?: string | null,
    status: string,
    prices?:  {
      __typename: "ModelPriceConnection",
      items:  Array< {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    slug?: string | null,
    category_id?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      display_name?: string | null,
      status?: string | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    min_price_amount?: string | null,
    max_price_amount?: string | null,
    currency_code?: string | null,
    product_star_quantity?: number | null,
    product_review_quantity?: number | null,
    commission_paid_quantity?: number | null,
    artist_other_artworks?:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    reviews?:  Array< {
      __typename: "Review",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      reviewer_id: string,
      reviewer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      rating?: number | null,
      comment?: string | null,
      type?: string | null,
      publication_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    dreambox_response_time?: number | null,
    dreambox_complete_time?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    artist_id: string,
    artist?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name: string,
    description?: string | null,
    status: string,
    prices?:  {
      __typename: "ModelPriceConnection",
      items:  Array< {
        __typename: "Price",
        id: string,
        product_id: string,
        display_name?: string | null,
        description?: string | null,
        status: string,
        type?: string | null,
        category?: string | null,
        fixed_amount?: string | null,
        fixed_centi_amount?: string | null,
        index?: number | null,
        min_amount?: string | null,
        min_centi_amount?: string | null,
        max_amount?: string | null,
        max_centi_amount?: string | null,
        currency_code?: string | null,
        percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    slug?: string | null,
    category_id?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      display_name?: string | null,
      status?: string | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    min_price_amount?: string | null,
    max_price_amount?: string | null,
    currency_code?: string | null,
    product_star_quantity?: number | null,
    product_review_quantity?: number | null,
    commission_paid_quantity?: number | null,
    artist_other_artworks?:  Array< {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    reviews?:  Array< {
      __typename: "Review",
      id: string,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      reviewer_id: string,
      reviewer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      rating?: number | null,
      comment?: string | null,
      type?: string | null,
      publication_date_time?: string | null,
      created_at: string,
      updated_at: string,
    } | null > | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    product_artworks?:  {
      __typename: "ModelProductArtworkConnection",
      items:  Array< {
        __typename: "ProductArtwork",
        id: string,
        product_id: string,
        artwork_id: string,
        is_removed?: number | null,
        index?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    dreambox_response_time?: number | null,
    dreambox_complete_time?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreatePriceSubscriptionVariables = {
  filter?: ModelSubscriptionPriceFilterInput | null,
};

export type OnCreatePriceSubscription = {
  onCreatePrice?:  {
    __typename: "Price",
    id: string,
    product_id: string,
    Product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name?: string | null,
    description?: string | null,
    status: string,
    type?: string | null,
    category?: string | null,
    fixed_amount?: string | null,
    fixed_centi_amount?: string | null,
    index?: number | null,
    min_amount?: string | null,
    min_centi_amount?: string | null,
    max_amount?: string | null,
    max_centi_amount?: string | null,
    currency_code?: string | null,
    percentage?: number | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdatePriceSubscriptionVariables = {
  filter?: ModelSubscriptionPriceFilterInput | null,
};

export type OnUpdatePriceSubscription = {
  onUpdatePrice?:  {
    __typename: "Price",
    id: string,
    product_id: string,
    Product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name?: string | null,
    description?: string | null,
    status: string,
    type?: string | null,
    category?: string | null,
    fixed_amount?: string | null,
    fixed_centi_amount?: string | null,
    index?: number | null,
    min_amount?: string | null,
    min_centi_amount?: string | null,
    max_amount?: string | null,
    max_centi_amount?: string | null,
    currency_code?: string | null,
    percentage?: number | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeletePriceSubscriptionVariables = {
  filter?: ModelSubscriptionPriceFilterInput | null,
};

export type OnDeletePriceSubscription = {
  onDeletePrice?:  {
    __typename: "Price",
    id: string,
    product_id: string,
    Product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    display_name?: string | null,
    description?: string | null,
    status: string,
    type?: string | null,
    category?: string | null,
    fixed_amount?: string | null,
    fixed_centi_amount?: string | null,
    index?: number | null,
    min_amount?: string | null,
    min_centi_amount?: string | null,
    max_amount?: string | null,
    max_centi_amount?: string | null,
    currency_code?: string | null,
    percentage?: number | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnCreateJobSubscription = {
  onCreateJob?:  {
    __typename: "Job",
    id: string,
    title?: string | null,
    description?: string | null,
    min_budget?: string | null,
    min_centi_budget?: string | null,
    max_budget?: string | null,
    max_centi_budget?: string | null,
    currency_code?: string | null,
    status?: string | null,
    is_exclusive?: number | null,
    publication_date_time?: string | null,
    deadline_date_time?: string | null,
    expiration_date_time?: string | null,
    is_comercial_artwork?: number | null,
    is_dreambox?: number | null,
    is_private_artwork?: number | null,
    file_format?: Array< string | null > | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacted_seller_ids?: Array< string | null > | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnUpdateJobSubscription = {
  onUpdateJob?:  {
    __typename: "Job",
    id: string,
    title?: string | null,
    description?: string | null,
    min_budget?: string | null,
    min_centi_budget?: string | null,
    max_budget?: string | null,
    max_centi_budget?: string | null,
    currency_code?: string | null,
    status?: string | null,
    is_exclusive?: number | null,
    publication_date_time?: string | null,
    deadline_date_time?: string | null,
    expiration_date_time?: string | null,
    is_comercial_artwork?: number | null,
    is_dreambox?: number | null,
    is_private_artwork?: number | null,
    file_format?: Array< string | null > | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacted_seller_ids?: Array< string | null > | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnDeleteJobSubscription = {
  onDeleteJob?:  {
    __typename: "Job",
    id: string,
    title?: string | null,
    description?: string | null,
    min_budget?: string | null,
    min_centi_budget?: string | null,
    max_budget?: string | null,
    max_centi_budget?: string | null,
    currency_code?: string | null,
    status?: string | null,
    is_exclusive?: number | null,
    publication_date_time?: string | null,
    deadline_date_time?: string | null,
    expiration_date_time?: string | null,
    is_comercial_artwork?: number | null,
    is_dreambox?: number | null,
    is_private_artwork?: number | null,
    file_format?: Array< string | null > | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposals?:  {
      __typename: "ModelProposalConnection",
      items:  Array< {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacted_seller_ids?: Array< string | null > | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateProposalSubscriptionVariables = {
  filter?: ModelSubscriptionProposalFilterInput | null,
};

export type OnCreateProposalSubscription = {
  onCreateProposal?:  {
    __typename: "Proposal",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_comment?: string | null,
    status?: string | null,
    publication_date_time?: string | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    proposal_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateProposalSubscriptionVariables = {
  filter?: ModelSubscriptionProposalFilterInput | null,
};

export type OnUpdateProposalSubscription = {
  onUpdateProposal?:  {
    __typename: "Proposal",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_comment?: string | null,
    status?: string | null,
    publication_date_time?: string | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    proposal_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteProposalSubscriptionVariables = {
  filter?: ModelSubscriptionProposalFilterInput | null,
};

export type OnDeleteProposalSubscription = {
  onDeleteProposal?:  {
    __typename: "Proposal",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_comment?: string | null,
    status?: string | null,
    publication_date_time?: string | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    proposal_type?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnCreateChannelSubscription = {
  onCreateChannel?:  {
    __typename: "Channel",
    id: string,
    name?: string | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnUpdateChannelSubscription = {
  onUpdateChannel?:  {
    __typename: "Channel",
    id: string,
    name?: string | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnDeleteChannelSubscription = {
  onDeleteChannel?:  {
    __typename: "Channel",
    id: string,
    name?: string | null,
    user_channels?:  {
      __typename: "ModelUserChannelConnection",
      items:  Array< {
        __typename: "UserChannel",
        id: string,
        channel_id: string,
        user_id: string,
        update_date_time?: string | null,
        status?: string | null,
        next_time_should_receive_an_email?: string | null,
        next_time_should_receive_a_late_response?: string | null,
        request_start_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        channel_id: string,
        sender_id: string,
        content?: string | null,
        image_url?: string | null,
        publication_date_time?: string | null,
        available_to?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateUserChannelSubscriptionVariables = {
  filter?: ModelSubscriptionUserChannelFilterInput | null,
};

export type OnCreateUserChannelSubscription = {
  onCreateUserChannel?:  {
    __typename: "UserChannel",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    update_date_time?: string | null,
    status?: string | null,
    next_time_should_receive_an_email?: string | null,
    next_time_should_receive_a_late_response?: string | null,
    request_start_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateUserChannelSubscriptionVariables = {
  filter?: ModelSubscriptionUserChannelFilterInput | null,
};

export type OnUpdateUserChannelSubscription = {
  onUpdateUserChannel?:  {
    __typename: "UserChannel",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    update_date_time?: string | null,
    status?: string | null,
    next_time_should_receive_an_email?: string | null,
    next_time_should_receive_a_late_response?: string | null,
    request_start_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteUserChannelSubscriptionVariables = {
  filter?: ModelSubscriptionUserChannelFilterInput | null,
};

export type OnDeleteUserChannelSubscription = {
  onDeleteUserChannel?:  {
    __typename: "UserChannel",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    update_date_time?: string | null,
    status?: string | null,
    next_time_should_receive_an_email?: string | null,
    next_time_should_receive_a_late_response?: string | null,
    request_start_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    sender_id: string,
    sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    content?: string | null,
    image_url?: string | null,
    publication_date_time?: string | null,
    available_to?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    sender_id: string,
    sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    content?: string | null,
    image_url?: string | null,
    publication_date_time?: string | null,
    available_to?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    channel_id: string,
    channel?:  {
      __typename: "Channel",
      id: string,
      name?: string | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    sender_id: string,
    sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    content?: string | null,
    image_url?: string | null,
    publication_date_time?: string | null,
    available_to?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreatePaymentIntentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentIntentFilterInput | null,
};

export type OnCreatePaymentIntentSubscription = {
  onCreatePaymentIntent?:  {
    __typename: "PaymentIntent",
    id: string,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_payment_contract_id: string,
    extra_payment_contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    display_fee_owing_to_momo_amount?: string | null,
    display_fee_owing_to_momo_centi_amount?: string | null,
    display_dreamerly_fee_balance_amount?: string | null,
    display_dreamerly_fee_balance_centi_amount?: string | null,
    payment_purpose?: string | null,
    billing_details?: string | null,
    description?: string | null,
    last_payment_error?: string | null,
    next_action?: string | null,
    disputed?: number | null,
    disputed_at?: string | null,
    refunded?: number | null,
    refunded_amount?: string | null,
    refunded_centi_amount?: string | null,
    refunded_at?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    payment_method?: string | null,
    paid_at?: string | null,
    cancelled_at?: string | null,
    momo_trans_id?: string | null,
    momo_request_id?: string | null,
    momo_payment_url?: string | null,
    momo_payment_url_expires_at?: string | null,
    stripe_payment_intent_id?: string | null,
    stripe_payment_method_country?: string | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdatePaymentIntentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentIntentFilterInput | null,
};

export type OnUpdatePaymentIntentSubscription = {
  onUpdatePaymentIntent?:  {
    __typename: "PaymentIntent",
    id: string,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_payment_contract_id: string,
    extra_payment_contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    display_fee_owing_to_momo_amount?: string | null,
    display_fee_owing_to_momo_centi_amount?: string | null,
    display_dreamerly_fee_balance_amount?: string | null,
    display_dreamerly_fee_balance_centi_amount?: string | null,
    payment_purpose?: string | null,
    billing_details?: string | null,
    description?: string | null,
    last_payment_error?: string | null,
    next_action?: string | null,
    disputed?: number | null,
    disputed_at?: string | null,
    refunded?: number | null,
    refunded_amount?: string | null,
    refunded_centi_amount?: string | null,
    refunded_at?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    payment_method?: string | null,
    paid_at?: string | null,
    cancelled_at?: string | null,
    momo_trans_id?: string | null,
    momo_request_id?: string | null,
    momo_payment_url?: string | null,
    momo_payment_url_expires_at?: string | null,
    stripe_payment_intent_id?: string | null,
    stripe_payment_method_country?: string | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeletePaymentIntentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentIntentFilterInput | null,
};

export type OnDeletePaymentIntentSubscription = {
  onDeletePaymentIntent?:  {
    __typename: "PaymentIntent",
    id: string,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_payment_contract_id: string,
    extra_payment_contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    display_fee_owing_to_momo_amount?: string | null,
    display_fee_owing_to_momo_centi_amount?: string | null,
    display_dreamerly_fee_balance_amount?: string | null,
    display_dreamerly_fee_balance_centi_amount?: string | null,
    payment_purpose?: string | null,
    billing_details?: string | null,
    description?: string | null,
    last_payment_error?: string | null,
    next_action?: string | null,
    disputed?: number | null,
    disputed_at?: string | null,
    refunded?: number | null,
    refunded_amount?: string | null,
    refunded_centi_amount?: string | null,
    refunded_at?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    payment_method?: string | null,
    paid_at?: string | null,
    cancelled_at?: string | null,
    momo_trans_id?: string | null,
    momo_request_id?: string | null,
    momo_payment_url?: string | null,
    momo_payment_url_expires_at?: string | null,
    stripe_payment_intent_id?: string | null,
    stripe_payment_method_country?: string | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreatePayoutSubscriptionVariables = {
  filter?: ModelSubscriptionPayoutFilterInput | null,
};

export type OnCreatePayoutSubscription = {
  onCreatePayout?:  {
    __typename: "Payout",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    billing_details?: string | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    arrival_date_time?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    balance_transaction_id?: string | null,
    request_amount?: string | null,
    request_centi_amount?: string | null,
    request_date_time?: string | null,
    bank_account_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdatePayoutSubscriptionVariables = {
  filter?: ModelSubscriptionPayoutFilterInput | null,
};

export type OnUpdatePayoutSubscription = {
  onUpdatePayout?:  {
    __typename: "Payout",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    billing_details?: string | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    arrival_date_time?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    balance_transaction_id?: string | null,
    request_amount?: string | null,
    request_centi_amount?: string | null,
    request_date_time?: string | null,
    bank_account_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeletePayoutSubscriptionVariables = {
  filter?: ModelSubscriptionPayoutFilterInput | null,
};

export type OnDeletePayoutSubscription = {
  onDeletePayout?:  {
    __typename: "Payout",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    billing_details?: string | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    arrival_date_time?: string | null,
    status?: string | null,
    payment_provider?: string | null,
    balance_transaction_id?: string | null,
    request_amount?: string | null,
    request_centi_amount?: string | null,
    request_date_time?: string | null,
    bank_account_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateRefundSubscriptionVariables = {
  filter?: ModelSubscriptionRefundFilterInput | null,
};

export type OnCreateRefundSubscription = {
  onCreateRefund?:  {
    __typename: "Refund",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    billing_details?: string | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    reason?: string | null,
    status?: string | null,
    response?: string | null,
    payment_provider?: string | null,
    momo_trans_id?: string | null,
    approved_at?: string | null,
    rejected_at?: string | null,
    refunded_at?: string | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateRefundSubscriptionVariables = {
  filter?: ModelSubscriptionRefundFilterInput | null,
};

export type OnUpdateRefundSubscription = {
  onUpdateRefund?:  {
    __typename: "Refund",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    billing_details?: string | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    reason?: string | null,
    status?: string | null,
    response?: string | null,
    payment_provider?: string | null,
    momo_trans_id?: string | null,
    approved_at?: string | null,
    rejected_at?: string | null,
    refunded_at?: string | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteRefundSubscriptionVariables = {
  filter?: ModelSubscriptionRefundFilterInput | null,
};

export type OnDeleteRefundSubscription = {
  onDeleteRefund?:  {
    __typename: "Refund",
    id: string,
    amount?: string | null,
    centi_amount?: string | null,
    currency?: string | null,
    payout_owing_to_seller_amount?: string | null,
    payout_owing_to_seller_centi_amount?: string | null,
    fee_owing_to_momo_amount?: string | null,
    fee_owing_to_momo_centi_amount?: string | null,
    dreamerly_fee_balance_amount?: string | null,
    dreamerly_fee_balance_centi_amount?: string | null,
    billing_details?: string | null,
    buyer_id?: string | null,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id?: string | null,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    reason?: string | null,
    status?: string | null,
    response?: string | null,
    payment_provider?: string | null,
    momo_trans_id?: string | null,
    approved_at?: string | null,
    rejected_at?: string | null,
    refunded_at?: string | null,
    balance_transaction_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateContractSubscriptionVariables = {
  filter?: ModelSubscriptionContractFilterInput | null,
};

export type OnCreateContractSubscription = {
  onCreateContract?:  {
    __typename: "Contract",
    id: string,
    status?: string | null,
    publication_date_time?: string | null,
    is_private_artwork?: number | null,
    is_comercial_artwork?: number | null,
    file_formats?: Array< string | null > | null,
    description?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    term_of_service?: string | null,
    state_name?: string | null,
    current_milestone_id?: string | null,
    current_milestone_index?: number | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposal_id: string,
    proposal?:  {
      __typename: "Proposal",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_comment?: string | null,
      status?: string | null,
      publication_date_time?: string | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      proposal_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_payment_payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestones?:  {
      __typename: "ModelMilestoneConnection",
      items:  Array< {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offers?:  {
      __typename: "ModelExtraOfferConnection",
      items:  Array< {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateContractSubscriptionVariables = {
  filter?: ModelSubscriptionContractFilterInput | null,
};

export type OnUpdateContractSubscription = {
  onUpdateContract?:  {
    __typename: "Contract",
    id: string,
    status?: string | null,
    publication_date_time?: string | null,
    is_private_artwork?: number | null,
    is_comercial_artwork?: number | null,
    file_formats?: Array< string | null > | null,
    description?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    term_of_service?: string | null,
    state_name?: string | null,
    current_milestone_id?: string | null,
    current_milestone_index?: number | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposal_id: string,
    proposal?:  {
      __typename: "Proposal",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_comment?: string | null,
      status?: string | null,
      publication_date_time?: string | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      proposal_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_payment_payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestones?:  {
      __typename: "ModelMilestoneConnection",
      items:  Array< {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offers?:  {
      __typename: "ModelExtraOfferConnection",
      items:  Array< {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteContractSubscriptionVariables = {
  filter?: ModelSubscriptionContractFilterInput | null,
};

export type OnDeleteContractSubscription = {
  onDeleteContract?:  {
    __typename: "Contract",
    id: string,
    status?: string | null,
    publication_date_time?: string | null,
    is_private_artwork?: number | null,
    is_comercial_artwork?: number | null,
    file_formats?: Array< string | null > | null,
    description?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    term_of_service?: string | null,
    state_name?: string | null,
    current_milestone_id?: string | null,
    current_milestone_index?: number | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    proposal_id: string,
    proposal?:  {
      __typename: "Proposal",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_comment?: string | null,
      status?: string | null,
      publication_date_time?: string | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      proposal_type?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    buyer_id: string,
    buyer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    seller_id: string,
    seller?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_products?:  {
      __typename: "ModelContractProductConnection",
      items:  Array< {
        __typename: "ContractProduct",
        id: string,
        contract_id: string,
        product_id: string,
        product_display_name?: string | null,
        product_description?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_payment_payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestones?:  {
      __typename: "ModelMilestoneConnection",
      items:  Array< {
        __typename: "Milestone",
        id: string,
        contract_id: string,
        step_index?: number | null,
        step_name?: string | null,
        deadline_number?: number | null,
        deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        free_revision_count?: number | null,
        current_revision_index?: number | null,
        current_revision_id?: string | null,
        description?: string | null,
        deadline_extensions?: string | null,
        price_percentage?: number | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offers?:  {
      __typename: "ModelExtraOfferConnection",
      items:  Array< {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    artworks?:  {
      __typename: "ModelArtworkConnection",
      items:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateContractProductSubscriptionVariables = {
  filter?: ModelSubscriptionContractProductFilterInput | null,
};

export type OnCreateContractProductSubscription = {
  onCreateContractProduct?:  {
    __typename: "ContractProduct",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_display_name?: string | null,
    product_description?: string | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateContractProductSubscriptionVariables = {
  filter?: ModelSubscriptionContractProductFilterInput | null,
};

export type OnUpdateContractProductSubscription = {
  onUpdateContractProduct?:  {
    __typename: "ContractProduct",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_display_name?: string | null,
    product_description?: string | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteContractProductSubscriptionVariables = {
  filter?: ModelSubscriptionContractProductFilterInput | null,
};

export type OnDeleteContractProductSubscription = {
  onDeleteContractProduct?:  {
    __typename: "ContractProduct",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_id: string,
    product?:  {
      __typename: "Product",
      id: string,
      artist_id: string,
      artist?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name: string,
      description?: string | null,
      status: string,
      prices?:  {
        __typename: "ModelPriceConnection",
        nextToken?: string | null,
      } | null,
      slug?: string | null,
      category_id?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        display_name?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      min_price_amount?: string | null,
      max_price_amount?: string | null,
      currency_code?: string | null,
      product_star_quantity?: number | null,
      product_review_quantity?: number | null,
      commission_paid_quantity?: number | null,
      artist_other_artworks?:  Array< {
        __typename: "Artwork",
        id: string,
        cover_image_height?: number | null,
        cover_image_width?: number | null,
        cover_image_src_url?: string | null,
        cover_image_thumbnail_url?: string | null,
        title?: string | null,
        likes_count?: number | null,
        comments_count?: number | null,
        description?: string | null,
        publication_date_time?: string | null,
        user_id: string,
        buyer_id?: string | null,
        is_removed?: number | null,
        contract_id?: string | null,
        is_buyer_showcase?: number | null,
        is_seller_showcase?: number | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      reviews?:  Array< {
        __typename: "Review",
        id: string,
        user_id: string,
        reviewer_id: string,
        contract_id: string,
        rating?: number | null,
        comment?: string | null,
        type?: string | null,
        publication_date_time?: string | null,
        created_at: string,
        updated_at: string,
      } | null > | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      dreambox_response_time?: number | null,
      dreambox_complete_time?: number | null,
      created_at: string,
      updated_at: string,
    } | null,
    product_display_name?: string | null,
    product_description?: string | null,
    contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_contract_product_prices?:  {
      __typename: "ModelContractProductPriceConnection",
      items:  Array< {
        __typename: "ContractProductPrice",
        id: string,
        contract_product_id: string,
        extra_contract_product_id: string,
        price_id: string,
        price_quantity?: number | null,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateContractProductPriceSubscriptionVariables = {
  filter?: ModelSubscriptionContractProductPriceFilterInput | null,
};

export type OnCreateContractProductPriceSubscription = {
  onCreateContractProductPrice?:  {
    __typename: "ContractProductPrice",
    id: string,
    contract_product_id: string,
    contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_contract_product_id: string,
    extra_contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity?: number | null,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateContractProductPriceSubscriptionVariables = {
  filter?: ModelSubscriptionContractProductPriceFilterInput | null,
};

export type OnUpdateContractProductPriceSubscription = {
  onUpdateContractProductPrice?:  {
    __typename: "ContractProductPrice",
    id: string,
    contract_product_id: string,
    contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_contract_product_id: string,
    extra_contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity?: number | null,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteContractProductPriceSubscriptionVariables = {
  filter?: ModelSubscriptionContractProductPriceFilterInput | null,
};

export type OnDeleteContractProductPriceSubscription = {
  onDeleteContractProductPrice?:  {
    __typename: "ContractProductPrice",
    id: string,
    contract_product_id: string,
    contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    extra_contract_product_id: string,
    extra_contract_product?:  {
      __typename: "ContractProduct",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      product_id: string,
      product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      product_display_name?: string | null,
      product_description?: string | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity?: number | null,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateExtraOfferSubscriptionVariables = {
  filter?: ModelSubscriptionExtraOfferFilterInput | null,
};

export type OnCreateExtraOfferSubscription = {
  onCreateExtraOffer?:  {
    __typename: "ExtraOffer",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateExtraOfferSubscriptionVariables = {
  filter?: ModelSubscriptionExtraOfferFilterInput | null,
};

export type OnUpdateExtraOfferSubscription = {
  onUpdateExtraOffer?:  {
    __typename: "ExtraOffer",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteExtraOfferSubscriptionVariables = {
  filter?: ModelSubscriptionExtraOfferFilterInput | null,
};

export type OnDeleteExtraOfferSubscription = {
  onDeleteExtraOffer?:  {
    __typename: "ExtraOffer",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    extra_offer_prices?:  {
      __typename: "ModelExtraOfferPriceConnection",
      items:  Array< {
        __typename: "ExtraOfferPrice",
        id: string,
        extra_offer_id: string,
        price_id: string,
        price_quantity: number,
        price_display_name?: string | null,
        price_description?: string | null,
        price_type?: string | null,
        price_category?: string | null,
        price_fixed_amount?: string | null,
        price_fixed_centi_amount?: string | null,
        price_currency_code?: string | null,
        price_percentage?: number | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateExtraOfferPriceSubscriptionVariables = {
  filter?: ModelSubscriptionExtraOfferPriceFilterInput | null,
};

export type OnCreateExtraOfferPriceSubscription = {
  onCreateExtraOfferPrice?:  {
    __typename: "ExtraOfferPrice",
    id: string,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity: number,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateExtraOfferPriceSubscriptionVariables = {
  filter?: ModelSubscriptionExtraOfferPriceFilterInput | null,
};

export type OnUpdateExtraOfferPriceSubscription = {
  onUpdateExtraOfferPrice?:  {
    __typename: "ExtraOfferPrice",
    id: string,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity: number,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteExtraOfferPriceSubscriptionVariables = {
  filter?: ModelSubscriptionExtraOfferPriceFilterInput | null,
};

export type OnDeleteExtraOfferPriceSubscription = {
  onDeleteExtraOfferPrice?:  {
    __typename: "ExtraOfferPrice",
    id: string,
    extra_offer_id: string,
    extra_offer?:  {
      __typename: "ExtraOffer",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_id: string,
    price?:  {
      __typename: "Price",
      id: string,
      product_id: string,
      Product?:  {
        __typename: "Product",
        id: string,
        artist_id: string,
        display_name: string,
        description?: string | null,
        status: string,
        slug?: string | null,
        category_id?: string | null,
        min_price_amount?: string | null,
        max_price_amount?: string | null,
        currency_code?: string | null,
        product_star_quantity?: number | null,
        product_review_quantity?: number | null,
        commission_paid_quantity?: number | null,
        dreambox_response_time?: number | null,
        dreambox_complete_time?: number | null,
        created_at: string,
        updated_at: string,
      } | null,
      display_name?: string | null,
      description?: string | null,
      status: string,
      type?: string | null,
      category?: string | null,
      fixed_amount?: string | null,
      fixed_centi_amount?: string | null,
      index?: number | null,
      min_amount?: string | null,
      min_centi_amount?: string | null,
      max_amount?: string | null,
      max_centi_amount?: string | null,
      currency_code?: string | null,
      percentage?: number | null,
      contract_product_prices?:  {
        __typename: "ModelContractProductPriceConnection",
        nextToken?: string | null,
      } | null,
      extra_offer_prices?:  {
        __typename: "ModelExtraOfferPriceConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    price_quantity: number,
    price_display_name?: string | null,
    price_description?: string | null,
    price_type?: string | null,
    price_category?: string | null,
    price_fixed_amount?: string | null,
    price_fixed_centi_amount?: string | null,
    price_currency_code?: string | null,
    price_percentage?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateMilestoneSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneFilterInput | null,
};

export type OnCreateMilestoneSubscription = {
  onCreateMilestone?:  {
    __typename: "Milestone",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    step_index?: number | null,
    step_name?: string | null,
    deadline_number?: number | null,
    deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    delivery_date_time?: string | null,
    free_revision_count?: number | null,
    current_revision_index?: number | null,
    current_revision_id?: string | null,
    description?: string | null,
    deadline_extensions?: string | null,
    price_percentage?: number | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    revisions?:  {
      __typename: "ModelRevisionConnection",
      items:  Array< {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateMilestoneSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneFilterInput | null,
};

export type OnUpdateMilestoneSubscription = {
  onUpdateMilestone?:  {
    __typename: "Milestone",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    step_index?: number | null,
    step_name?: string | null,
    deadline_number?: number | null,
    deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    delivery_date_time?: string | null,
    free_revision_count?: number | null,
    current_revision_index?: number | null,
    current_revision_id?: string | null,
    description?: string | null,
    deadline_extensions?: string | null,
    price_percentage?: number | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    revisions?:  {
      __typename: "ModelRevisionConnection",
      items:  Array< {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteMilestoneSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneFilterInput | null,
};

export type OnDeleteMilestoneSubscription = {
  onDeleteMilestone?:  {
    __typename: "Milestone",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    step_index?: number | null,
    step_name?: string | null,
    deadline_number?: number | null,
    deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    delivery_date_time?: string | null,
    free_revision_count?: number | null,
    current_revision_index?: number | null,
    current_revision_id?: string | null,
    description?: string | null,
    deadline_extensions?: string | null,
    price_percentage?: number | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    status?: string | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    revisions?:  {
      __typename: "ModelRevisionConnection",
      items:  Array< {
        __typename: "Revision",
        id: string,
        index?: number | null,
        status?: string | null,
        request_message?: string | null,
        request_rejection_message?: string | null,
        milestone_id: string,
        additional_deadline_number?: number | null,
        additional_deadline_type?: string | null,
        start_date_time?: string | null,
        end_date_time?: string | null,
        delivery_date_time?: string | null,
        deadline_extensions?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateRevisionSubscriptionVariables = {
  filter?: ModelSubscriptionRevisionFilterInput | null,
};

export type OnCreateRevisionSubscription = {
  onCreateRevision?:  {
    __typename: "Revision",
    id: string,
    index?: number | null,
    status?: string | null,
    request_message?: string | null,
    request_rejection_message?: string | null,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    additional_deadline_number?: number | null,
    additional_deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    request_revision_images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    delivery_date_time?: string | null,
    deadline_extensions?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateRevisionSubscriptionVariables = {
  filter?: ModelSubscriptionRevisionFilterInput | null,
};

export type OnUpdateRevisionSubscription = {
  onUpdateRevision?:  {
    __typename: "Revision",
    id: string,
    index?: number | null,
    status?: string | null,
    request_message?: string | null,
    request_rejection_message?: string | null,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    additional_deadline_number?: number | null,
    additional_deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    request_revision_images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    delivery_date_time?: string | null,
    deadline_extensions?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteRevisionSubscriptionVariables = {
  filter?: ModelSubscriptionRevisionFilterInput | null,
};

export type OnDeleteRevisionSubscription = {
  onDeleteRevision?:  {
    __typename: "Revision",
    id: string,
    index?: number | null,
    status?: string | null,
    request_message?: string | null,
    request_rejection_message?: string | null,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    additional_deadline_number?: number | null,
    additional_deadline_type?: string | null,
    start_date_time?: string | null,
    end_date_time?: string | null,
    images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    request_revision_images?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        height?: number | null,
        width?: number | null,
        file_name?: string | null,
        src_url?: string | null,
        thumbnail_url?: string | null,
        raw_src_url?: string | null,
        raw_thumbnail_url?: string | null,
        artwork_id?: string | null,
        job_id?: string | null,
        milestone_id?: string | null,
        revision_id?: string | null,
        revision_request_revision_id?: string | null,
        message_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    delivery_date_time?: string | null,
    deadline_extensions?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateContractEventSubscriptionVariables = {
  filter?: ModelSubscriptionContractEventFilterInput | null,
};

export type OnCreateContractEventSubscription = {
  onCreateContractEvent?:  {
    __typename: "ContractEvent",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_status?: string | null,
    contract_amount?: string | null,
    contract_currency?: string | null,
    extra_offer_status?: string | null,
    extra_offer_amount?: string | null,
    extra_offer_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateContractEventSubscriptionVariables = {
  filter?: ModelSubscriptionContractEventFilterInput | null,
};

export type OnUpdateContractEventSubscription = {
  onUpdateContractEvent?:  {
    __typename: "ContractEvent",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_status?: string | null,
    contract_amount?: string | null,
    contract_currency?: string | null,
    extra_offer_status?: string | null,
    extra_offer_amount?: string | null,
    extra_offer_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteContractEventSubscriptionVariables = {
  filter?: ModelSubscriptionContractEventFilterInput | null,
};

export type OnDeleteContractEventSubscription = {
  onDeleteContractEvent?:  {
    __typename: "ContractEvent",
    id: string,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_status?: string | null,
    contract_amount?: string | null,
    contract_currency?: string | null,
    extra_offer_status?: string | null,
    extra_offer_amount?: string | null,
    extra_offer_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreatePaymentIntentEventSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentIntentEventFilterInput | null,
};

export type OnCreatePaymentIntentEventSubscription = {
  onCreatePaymentIntentEvent?:  {
    __typename: "PaymentIntentEvent",
    id: string,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    payment_intent_status?: string | null,
    payment_intent_amount?: string | null,
    payment_intent_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdatePaymentIntentEventSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentIntentEventFilterInput | null,
};

export type OnUpdatePaymentIntentEventSubscription = {
  onUpdatePaymentIntentEvent?:  {
    __typename: "PaymentIntentEvent",
    id: string,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    payment_intent_status?: string | null,
    payment_intent_amount?: string | null,
    payment_intent_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeletePaymentIntentEventSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentIntentEventFilterInput | null,
};

export type OnDeletePaymentIntentEventSubscription = {
  onDeletePaymentIntentEvent?:  {
    __typename: "PaymentIntentEvent",
    id: string,
    payment_intent_id: string,
    payment_intent?:  {
      __typename: "PaymentIntent",
      id: string,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_payment_contract_id: string,
      extra_payment_contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      extra_offer_id: string,
      extra_offer?:  {
        __typename: "ExtraOffer",
        id: string,
        contract_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        status?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency?: string | null,
      payout_owing_to_seller_amount?: string | null,
      payout_owing_to_seller_centi_amount?: string | null,
      fee_owing_to_momo_amount?: string | null,
      fee_owing_to_momo_centi_amount?: string | null,
      dreamerly_fee_balance_amount?: string | null,
      dreamerly_fee_balance_centi_amount?: string | null,
      display_fee_owing_to_momo_amount?: string | null,
      display_fee_owing_to_momo_centi_amount?: string | null,
      display_dreamerly_fee_balance_amount?: string | null,
      display_dreamerly_fee_balance_centi_amount?: string | null,
      payment_purpose?: string | null,
      billing_details?: string | null,
      description?: string | null,
      last_payment_error?: string | null,
      next_action?: string | null,
      disputed?: number | null,
      disputed_at?: string | null,
      refunded?: number | null,
      refunded_amount?: string | null,
      refunded_centi_amount?: string | null,
      refunded_at?: string | null,
      status?: string | null,
      payment_provider?: string | null,
      payment_method?: string | null,
      paid_at?: string | null,
      cancelled_at?: string | null,
      momo_trans_id?: string | null,
      momo_request_id?: string | null,
      momo_payment_url?: string | null,
      momo_payment_url_expires_at?: string | null,
      stripe_payment_intent_id?: string | null,
      stripe_payment_method_country?: string | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      balance_transaction_id?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    payment_intent_status?: string | null,
    payment_intent_amount?: string | null,
    payment_intent_currency?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateMilestoneEventSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneEventFilterInput | null,
};

export type OnCreateMilestoneEventSubscription = {
  onCreateMilestoneEvent?:  {
    __typename: "MilestoneEvent",
    id: string,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_status?: string | null,
    milestone_step_index?: number | null,
    milestone_step_name?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateMilestoneEventSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneEventFilterInput | null,
};

export type OnUpdateMilestoneEventSubscription = {
  onUpdateMilestoneEvent?:  {
    __typename: "MilestoneEvent",
    id: string,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_status?: string | null,
    milestone_step_index?: number | null,
    milestone_step_name?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteMilestoneEventSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneEventFilterInput | null,
};

export type OnDeleteMilestoneEventSubscription = {
  onDeleteMilestoneEvent?:  {
    __typename: "MilestoneEvent",
    id: string,
    milestone_id: string,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    event_id: string,
    event?:  {
      __typename: "Event",
      id: string,
      type?: string | null,
      note?: string | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      payment_intent_events?:  {
        __typename: "ModelPaymentIntentEventConnection",
        nextToken?: string | null,
      } | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    milestone_status?: string | null,
    milestone_step_index?: number | null,
    milestone_step_name?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    type?: string | null,
    note?: string | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    type?: string | null,
    note?: string | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    type?: string | null,
    note?: string | null,
    contract_events?:  {
      __typename: "ModelContractEventConnection",
      items:  Array< {
        __typename: "ContractEvent",
        id: string,
        contract_id: string,
        event_id: string,
        contract_status?: string | null,
        contract_amount?: string | null,
        contract_currency?: string | null,
        extra_offer_status?: string | null,
        extra_offer_amount?: string | null,
        extra_offer_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_intent_events?:  {
      __typename: "ModelPaymentIntentEventConnection",
      items:  Array< {
        __typename: "PaymentIntentEvent",
        id: string,
        payment_intent_id: string,
        event_id: string,
        payment_intent_status?: string | null,
        payment_intent_amount?: string | null,
        payment_intent_currency?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    milestone_events?:  {
      __typename: "ModelMilestoneEventConnection",
      items:  Array< {
        __typename: "MilestoneEvent",
        id: string,
        milestone_id: string,
        event_id: string,
        milestone_status?: string | null,
        milestone_step_index?: number | null,
        milestone_step_name?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnCreateReviewSubscription = {
  onCreateReview?:  {
    __typename: "Review",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    reviewer_id: string,
    reviewer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    rating?: number | null,
    comment?: string | null,
    type?: string | null,
    publication_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnUpdateReviewSubscription = {
  onUpdateReview?:  {
    __typename: "Review",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    reviewer_id: string,
    reviewer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    rating?: number | null,
    comment?: string | null,
    type?: string | null,
    publication_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnDeleteReviewSubscription = {
  onDeleteReview?:  {
    __typename: "Review",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    reviewer_id: string,
    reviewer?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id: string,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    rating?: number | null,
    comment?: string | null,
    type?: string | null,
    publication_date_time?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateUserFollowSubscriptionVariables = {
  filter?: ModelSubscriptionUserFollowFilterInput | null,
};

export type OnCreateUserFollowSubscription = {
  onCreateUserFollow?:  {
    __typename: "UserFollow",
    id: string,
    leader_id: string,
    follower_id: string,
    leader?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    follower?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateUserFollowSubscriptionVariables = {
  filter?: ModelSubscriptionUserFollowFilterInput | null,
};

export type OnUpdateUserFollowSubscription = {
  onUpdateUserFollow?:  {
    __typename: "UserFollow",
    id: string,
    leader_id: string,
    follower_id: string,
    leader?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    follower?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteUserFollowSubscriptionVariables = {
  filter?: ModelSubscriptionUserFollowFilterInput | null,
};

export type OnDeleteUserFollowSubscription = {
  onDeleteUserFollow?:  {
    __typename: "UserFollow",
    id: string,
    leader_id: string,
    follower_id: string,
    leader?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    follower?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateDreamerlyBalanceSubscriptionVariables = {
  filter?: ModelSubscriptionDreamerlyBalanceFilterInput | null,
};

export type OnCreateDreamerlyBalanceSubscription = {
  onCreateDreamerlyBalance?:  {
    __typename: "DreamerlyBalance",
    id: string,
    currency_code?: string | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateDreamerlyBalanceSubscriptionVariables = {
  filter?: ModelSubscriptionDreamerlyBalanceFilterInput | null,
};

export type OnUpdateDreamerlyBalanceSubscription = {
  onUpdateDreamerlyBalance?:  {
    __typename: "DreamerlyBalance",
    id: string,
    currency_code?: string | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteDreamerlyBalanceSubscriptionVariables = {
  filter?: ModelSubscriptionDreamerlyBalanceFilterInput | null,
};

export type OnDeleteDreamerlyBalanceSubscription = {
  onDeleteDreamerlyBalance?:  {
    __typename: "DreamerlyBalance",
    id: string,
    currency_code?: string | null,
    sent_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    received_balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateBalanceTransferSubscriptionVariables = {
  filter?: ModelSubscriptionBalanceTransferFilterInput | null,
};

export type OnCreateBalanceTransferSubscription = {
  onCreateBalanceTransfer?:  {
    __typename: "BalanceTransfer",
    id: string,
    reason?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    dreamerly_fee?: string | null,
    dreamerly_centi_fee?: string | null,
    payment_provider_fee?: string | null,
    payment_provider_centi_fee?: string | null,
    net_amount?: string | null,
    net_centi_amount?: string | null,
    payment_provider?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_user_id: string,
    from_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_user_id: string,
    to_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_dreamerly_balance_id: string,
    from_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_dreamerly_balance_id: string,
    to_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateBalanceTransferSubscriptionVariables = {
  filter?: ModelSubscriptionBalanceTransferFilterInput | null,
};

export type OnUpdateBalanceTransferSubscription = {
  onUpdateBalanceTransfer?:  {
    __typename: "BalanceTransfer",
    id: string,
    reason?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    dreamerly_fee?: string | null,
    dreamerly_centi_fee?: string | null,
    payment_provider_fee?: string | null,
    payment_provider_centi_fee?: string | null,
    net_amount?: string | null,
    net_centi_amount?: string | null,
    payment_provider?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_user_id: string,
    from_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_user_id: string,
    to_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_dreamerly_balance_id: string,
    from_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_dreamerly_balance_id: string,
    to_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteBalanceTransferSubscriptionVariables = {
  filter?: ModelSubscriptionBalanceTransferFilterInput | null,
};

export type OnDeleteBalanceTransferSubscription = {
  onDeleteBalanceTransfer?:  {
    __typename: "BalanceTransfer",
    id: string,
    reason?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    dreamerly_fee?: string | null,
    dreamerly_centi_fee?: string | null,
    payment_provider_fee?: string | null,
    payment_provider_centi_fee?: string | null,
    net_amount?: string | null,
    net_centi_amount?: string | null,
    payment_provider?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    milestone_id?: string | null,
    milestone?:  {
      __typename: "Milestone",
      id: string,
      contract_id: string,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      step_index?: number | null,
      step_name?: string | null,
      deadline_number?: number | null,
      deadline_type?: string | null,
      start_date_time?: string | null,
      end_date_time?: string | null,
      delivery_date_time?: string | null,
      free_revision_count?: number | null,
      current_revision_index?: number | null,
      current_revision_id?: string | null,
      description?: string | null,
      deadline_extensions?: string | null,
      price_percentage?: number | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      status?: string | null,
      milestone_events?:  {
        __typename: "ModelMilestoneEventConnection",
        nextToken?: string | null,
      } | null,
      revisions?:  {
        __typename: "ModelRevisionConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    contract_id?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      status?: string | null,
      publication_date_time?: string | null,
      is_private_artwork?: number | null,
      is_comercial_artwork?: number | null,
      file_formats?: Array< string | null > | null,
      description?: string | null,
      amount?: string | null,
      centi_amount?: string | null,
      currency_code?: string | null,
      term_of_service?: string | null,
      state_name?: string | null,
      current_milestone_id?: string | null,
      current_milestone_index?: number | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        title?: string | null,
        description?: string | null,
        min_budget?: string | null,
        min_centi_budget?: string | null,
        max_budget?: string | null,
        max_centi_budget?: string | null,
        currency_code?: string | null,
        status?: string | null,
        is_exclusive?: number | null,
        publication_date_time?: string | null,
        deadline_date_time?: string | null,
        expiration_date_time?: string | null,
        is_comercial_artwork?: number | null,
        is_dreambox?: number | null,
        is_private_artwork?: number | null,
        file_format?: Array< string | null > | null,
        buyer_id: string,
        seller_id: string,
        contacted_seller_ids?: Array< string | null > | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposal_id: string,
      proposal?:  {
        __typename: "Proposal",
        id: string,
        job_id: string,
        seller_id: string,
        seller_comment?: string | null,
        status?: string | null,
        publication_date_time?: string | null,
        proposal_type?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      contract_products?:  {
        __typename: "ModelContractProductConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      extra_payment_payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      milestones?:  {
        __typename: "ModelMilestoneConnection",
        nextToken?: string | null,
      } | null,
      contract_events?:  {
        __typename: "ModelContractEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      extra_offers?:  {
        __typename: "ModelExtraOfferConnection",
        nextToken?: string | null,
      } | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_user_id: string,
    from_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_user_id: string,
    to_user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    from_dreamerly_balance_id: string,
    from_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    to_dreamerly_balance_id: string,
    to_dreamerly_balance?:  {
      __typename: "DreamerlyBalance",
      id: string,
      currency_code?: string | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreatePaymentProviderFeePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentProviderFeePaymentFilterInput | null,
};

export type OnCreatePaymentProviderFeePaymentSubscription = {
  onCreatePaymentProviderFeePayment?:  {
    __typename: "PaymentProviderFeePayment",
    id: string,
    amount: string,
    centi_amount: string,
    currency_code: string,
    publication_date_time?: string | null,
    payment_provider?: string | null,
    description?: string | null,
    invoice_number?: string | null,
    from_dreamerly_balance_id: string,
    to_dreamerly_balance_id: string,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdatePaymentProviderFeePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentProviderFeePaymentFilterInput | null,
};

export type OnUpdatePaymentProviderFeePaymentSubscription = {
  onUpdatePaymentProviderFeePayment?:  {
    __typename: "PaymentProviderFeePayment",
    id: string,
    amount: string,
    centi_amount: string,
    currency_code: string,
    publication_date_time?: string | null,
    payment_provider?: string | null,
    description?: string | null,
    invoice_number?: string | null,
    from_dreamerly_balance_id: string,
    to_dreamerly_balance_id: string,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeletePaymentProviderFeePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentProviderFeePaymentFilterInput | null,
};

export type OnDeletePaymentProviderFeePaymentSubscription = {
  onDeletePaymentProviderFeePayment?:  {
    __typename: "PaymentProviderFeePayment",
    id: string,
    amount: string,
    centi_amount: string,
    currency_code: string,
    publication_date_time?: string | null,
    payment_provider?: string | null,
    description?: string | null,
    invoice_number?: string | null,
    from_dreamerly_balance_id: string,
    to_dreamerly_balance_id: string,
    balance_transaction_id: string,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateBalanceTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionBalanceTransactionFilterInput | null,
};

export type OnCreateBalanceTransactionSubscription = {
  onCreateBalanceTransaction?:  {
    __typename: "BalanceTransaction",
    id: string,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_provider_fee_payments?:  {
      __typename: "ModelPaymentProviderFeePaymentConnection",
      items:  Array< {
        __typename: "PaymentProviderFeePayment",
        id: string,
        amount: string,
        centi_amount: string,
        currency_code: string,
        publication_date_time?: string | null,
        payment_provider?: string | null,
        description?: string | null,
        invoice_number?: string | null,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    type?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateBalanceTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionBalanceTransactionFilterInput | null,
};

export type OnUpdateBalanceTransactionSubscription = {
  onUpdateBalanceTransaction?:  {
    __typename: "BalanceTransaction",
    id: string,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_provider_fee_payments?:  {
      __typename: "ModelPaymentProviderFeePaymentConnection",
      items:  Array< {
        __typename: "PaymentProviderFeePayment",
        id: string,
        amount: string,
        centi_amount: string,
        currency_code: string,
        publication_date_time?: string | null,
        payment_provider?: string | null,
        description?: string | null,
        invoice_number?: string | null,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    type?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteBalanceTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionBalanceTransactionFilterInput | null,
};

export type OnDeleteBalanceTransactionSubscription = {
  onDeleteBalanceTransaction?:  {
    __typename: "BalanceTransaction",
    id: string,
    payment_intents?:  {
      __typename: "ModelPaymentIntentConnection",
      items:  Array< {
        __typename: "PaymentIntent",
        id: string,
        buyer_id?: string | null,
        contract_id: string,
        extra_payment_contract_id: string,
        extra_offer_id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        display_fee_owing_to_momo_amount?: string | null,
        display_fee_owing_to_momo_centi_amount?: string | null,
        display_dreamerly_fee_balance_amount?: string | null,
        display_dreamerly_fee_balance_centi_amount?: string | null,
        payment_purpose?: string | null,
        billing_details?: string | null,
        description?: string | null,
        last_payment_error?: string | null,
        next_action?: string | null,
        disputed?: number | null,
        disputed_at?: string | null,
        refunded?: number | null,
        refunded_amount?: string | null,
        refunded_centi_amount?: string | null,
        refunded_at?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        payment_method?: string | null,
        paid_at?: string | null,
        cancelled_at?: string | null,
        momo_trans_id?: string | null,
        momo_request_id?: string | null,
        momo_payment_url?: string | null,
        momo_payment_url_expires_at?: string | null,
        stripe_payment_intent_id?: string | null,
        stripe_payment_method_country?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payouts?:  {
      __typename: "ModelPayoutConnection",
      items:  Array< {
        __typename: "Payout",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        billing_details?: string | null,
        seller_id?: string | null,
        description?: string | null,
        arrival_date_time?: string | null,
        status?: string | null,
        payment_provider?: string | null,
        balance_transaction_id?: string | null,
        request_amount?: string | null,
        request_centi_amount?: string | null,
        request_date_time?: string | null,
        bank_account_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    refunds?:  {
      __typename: "ModelRefundConnection",
      items:  Array< {
        __typename: "Refund",
        id: string,
        amount?: string | null,
        centi_amount?: string | null,
        currency?: string | null,
        payout_owing_to_seller_amount?: string | null,
        payout_owing_to_seller_centi_amount?: string | null,
        fee_owing_to_momo_amount?: string | null,
        fee_owing_to_momo_centi_amount?: string | null,
        dreamerly_fee_balance_amount?: string | null,
        dreamerly_fee_balance_centi_amount?: string | null,
        billing_details?: string | null,
        buyer_id?: string | null,
        seller_id?: string | null,
        description?: string | null,
        payment_intent_id: string,
        contract_id: string,
        reason?: string | null,
        status?: string | null,
        response?: string | null,
        payment_provider?: string | null,
        momo_trans_id?: string | null,
        approved_at?: string | null,
        rejected_at?: string | null,
        refunded_at?: string | null,
        balance_transaction_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    balance_transfers?:  {
      __typename: "ModelBalanceTransferConnection",
      items:  Array< {
        __typename: "BalanceTransfer",
        id: string,
        reason?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        dreamerly_fee?: string | null,
        dreamerly_centi_fee?: string | null,
        payment_provider_fee?: string | null,
        payment_provider_centi_fee?: string | null,
        net_amount?: string | null,
        net_centi_amount?: string | null,
        payment_provider?: string | null,
        currency_code?: string | null,
        note?: string | null,
        publication_date_time?: string | null,
        milestone_id?: string | null,
        contract_id?: string | null,
        from_user_id: string,
        to_user_id: string,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    payment_provider_fee_payments?:  {
      __typename: "ModelPaymentProviderFeePaymentConnection",
      items:  Array< {
        __typename: "PaymentProviderFeePayment",
        id: string,
        amount: string,
        centi_amount: string,
        currency_code: string,
        publication_date_time?: string | null,
        payment_provider?: string | null,
        description?: string | null,
        invoice_number?: string | null,
        from_dreamerly_balance_id: string,
        to_dreamerly_balance_id: string,
        balance_transaction_id: string,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    type?: string | null,
    amount?: string | null,
    centi_amount?: string | null,
    currency_code?: string | null,
    note?: string | null,
    publication_date_time?: string | null,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    notification_sender_id?: string | null,
    notification_sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    title?: string | null,
    body?: string | null,
    publication_date_time?: string | null,
    is_read?: number | null,
    is_removed?: number | null,
    notification_type?: string | null,
    underlying_object_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    notification_sender_id?: string | null,
    notification_sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    title?: string | null,
    body?: string | null,
    publication_date_time?: string | null,
    is_read?: number | null,
    is_removed?: number | null,
    notification_type?: string | null,
    underlying_object_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    user_id: string,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    notification_sender_id?: string | null,
    notification_sender?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    title?: string | null,
    body?: string | null,
    publication_date_time?: string | null,
    is_read?: number | null,
    is_removed?: number | null,
    notification_type?: string | null,
    underlying_object_id?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateCharacterSubscriptionVariables = {
  filter?: ModelSubscriptionCharacterFilterInput | null,
};

export type OnCreateCharacterSubscription = {
  onCreateCharacter?:  {
    __typename: "Character",
    id: string,
    display_name?: string | null,
    charname?: string | null,
    character_type?: string | null,
    user_id?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    strengths?: string | null,
    flaws?: string | null,
    gender?: string | null,
    race?: string | null,
    birth_date?: string | null,
    age?: string | null,
    role?: string | null,
    hobbies?: string | null,
    dislikes?: string | null,
    profile_picture_url?: string | null,
    cover_photo_url?: string | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateCharacterSubscriptionVariables = {
  filter?: ModelSubscriptionCharacterFilterInput | null,
};

export type OnUpdateCharacterSubscription = {
  onUpdateCharacter?:  {
    __typename: "Character",
    id: string,
    display_name?: string | null,
    charname?: string | null,
    character_type?: string | null,
    user_id?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    strengths?: string | null,
    flaws?: string | null,
    gender?: string | null,
    race?: string | null,
    birth_date?: string | null,
    age?: string | null,
    role?: string | null,
    hobbies?: string | null,
    dislikes?: string | null,
    profile_picture_url?: string | null,
    cover_photo_url?: string | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteCharacterSubscriptionVariables = {
  filter?: ModelSubscriptionCharacterFilterInput | null,
};

export type OnDeleteCharacterSubscription = {
  onDeleteCharacter?:  {
    __typename: "Character",
    id: string,
    display_name?: string | null,
    charname?: string | null,
    character_type?: string | null,
    user_id?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      display_name?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      username?: string | null,
      email_address?: string | null,
      birth_date?: string | null,
      phone_number?: string | null,
      country_code?: string | null,
      subscriber_number?: string | null,
      bio?: string | null,
      is_artist?: number | null,
      seller_onboarding_stage?: string | null,
      buyer_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      seller_jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
      } | null,
      term_of_service?: string | null,
      cccd?: string | null,
      mst?: string | null,
      cccd_images?: Array< string | null > | null,
      mst_stage?: string | null,
      seller_proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      profile_picture_url?: string | null,
      cover_picture_url?: string | null,
      balance_amount?: string | null,
      balance_centi_amount?: string | null,
      balance_currency_code?: string | null,
      usd_balance_amount?: string | null,
      usd_balance_centi_amount?: string | null,
      contract_paid_quantity?: number | null,
      seller_star_quantity?: number | null,
      seller_review_quantity?: number | null,
      buyer_star_quantity?: number | null,
      buyer_review_quantity?: number | null,
      commision_started_quantity?: number | null,
      commission_completed_quantity?: number | null,
      commission_completed_on_time_quantity?: number | null,
      commission_cancelled_seller_fault_quantity?: number | null,
      commission_cancelled_buyer_fault_quantity?: number | null,
      commission_cancelled_mutually_quantity?: number | null,
      commission_cumulative_late_seconds?: number | null,
      commission_max_late_seconds?: number | null,
      device_tokens?: Array< string | null > | null,
      is_selling?: number | null,
      is_selling_abroad?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
      } | null,
      last_time_open_list_notification?: string | null,
      discount_code?: string | null,
      artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      buyer_artworks?:  {
        __typename: "ModelArtworkConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      user_channels?:  {
        __typename: "ModelUserChannelConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      payment_intents?:  {
        __typename: "ModelPaymentIntentConnection",
        nextToken?: string | null,
      } | null,
      payouts?:  {
        __typename: "ModelPayoutConnection",
        nextToken?: string | null,
      } | null,
      selling_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      buying_refunds?:  {
        __typename: "ModelRefundConnection",
        nextToken?: string | null,
      } | null,
      seller_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      buyer_contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      received_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      given_reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      sent_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      received_balance_transfers?:  {
        __typename: "ModelBalanceTransferConnection",
        nextToken?: string | null,
      } | null,
      balance_transactions?:  {
        __typename: "ModelBalanceTransactionConnection",
        nextToken?: string | null,
      } | null,
      follower_quantity?: number | null,
      leader_quantity?: number | null,
      followers?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      leaders?:  {
        __typename: "ModelUserFollowConnection",
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      notification_senders?:  {
        __typename: "ModelNotificationConnection",
        nextToken?: string | null,
      } | null,
      push_tokens?:  {
        __typename: "ModelPushTokenConnection",
        nextToken?: string | null,
      } | null,
      has_unread_commissions?: number | null,
      has_unread_jobs?: number | null,
      message_cumulative_response_seconds?: number | null,
      message_response_on_time_quantity?: number | null,
      message_response_late_quantity?: number | null,
      characters?:  {
        __typename: "ModelCharacterConnection",
        nextToken?: string | null,
      } | null,
      bank_name?: string | null,
      account_holder_name?: string | null,
      account_last_four?: string | null,
      address_country_code?: string | null,
      city?: string | null,
      address_line1?: string | null,
      additional_info?: string | null,
      created_at: string,
      updated_at: string,
    } | null,
    description?: string | null,
    strengths?: string | null,
    flaws?: string | null,
    gender?: string | null,
    race?: string | null,
    birth_date?: string | null,
    age?: string | null,
    role?: string | null,
    hobbies?: string | null,
    dislikes?: string | null,
    profile_picture_url?: string | null,
    cover_photo_url?: string | null,
    character_items?:  {
      __typename: "ModelCharacterItemConnection",
      items:  Array< {
        __typename: "CharacterItem",
        id: string,
        item_type?: string | null,
        character_id: string,
        artwork_id?: string | null,
        job_id?: string | null,
        created_at: string,
        updated_at: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateCharacterItemSubscriptionVariables = {
  filter?: ModelSubscriptionCharacterItemFilterInput | null,
};

export type OnCreateCharacterItemSubscription = {
  onCreateCharacterItem?:  {
    __typename: "CharacterItem",
    id: string,
    item_type?: string | null,
    character_id: string,
    character?:  {
      __typename: "Character",
      id: string,
      display_name?: string | null,
      charname?: string | null,
      character_type?: string | null,
      user_id?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      strengths?: string | null,
      flaws?: string | null,
      gender?: string | null,
      race?: string | null,
      birth_date?: string | null,
      age?: string | null,
      role?: string | null,
      hobbies?: string | null,
      dislikes?: string | null,
      profile_picture_url?: string | null,
      cover_photo_url?: string | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnUpdateCharacterItemSubscriptionVariables = {
  filter?: ModelSubscriptionCharacterItemFilterInput | null,
};

export type OnUpdateCharacterItemSubscription = {
  onUpdateCharacterItem?:  {
    __typename: "CharacterItem",
    id: string,
    item_type?: string | null,
    character_id: string,
    character?:  {
      __typename: "Character",
      id: string,
      display_name?: string | null,
      charname?: string | null,
      character_type?: string | null,
      user_id?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      strengths?: string | null,
      flaws?: string | null,
      gender?: string | null,
      race?: string | null,
      birth_date?: string | null,
      age?: string | null,
      role?: string | null,
      hobbies?: string | null,
      dislikes?: string | null,
      profile_picture_url?: string | null,
      cover_photo_url?: string | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnDeleteCharacterItemSubscriptionVariables = {
  filter?: ModelSubscriptionCharacterItemFilterInput | null,
};

export type OnDeleteCharacterItemSubscription = {
  onDeleteCharacterItem?:  {
    __typename: "CharacterItem",
    id: string,
    item_type?: string | null,
    character_id: string,
    character?:  {
      __typename: "Character",
      id: string,
      display_name?: string | null,
      charname?: string | null,
      character_type?: string | null,
      user_id?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      description?: string | null,
      strengths?: string | null,
      flaws?: string | null,
      gender?: string | null,
      race?: string | null,
      birth_date?: string | null,
      age?: string | null,
      role?: string | null,
      hobbies?: string | null,
      dislikes?: string | null,
      profile_picture_url?: string | null,
      cover_photo_url?: string | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    artwork_id?: string | null,
    artwork?:  {
      __typename: "Artwork",
      id: string,
      cover_image_height?: number | null,
      cover_image_width?: number | null,
      cover_image_src_url?: string | null,
      cover_image_thumbnail_url?: string | null,
      title?: string | null,
      likes_count?: number | null,
      comments_count?: number | null,
      description?: string | null,
      publication_date_time?: string | null,
      user_id: string,
      user?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      buyer_id?: string | null,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      is_removed?: number | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
      product_artworks?:  {
        __typename: "ModelProductArtworkConnection",
        nextToken?: string | null,
      } | null,
      contract_id?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        status?: string | null,
        publication_date_time?: string | null,
        is_private_artwork?: number | null,
        is_comercial_artwork?: number | null,
        file_formats?: Array< string | null > | null,
        description?: string | null,
        amount?: string | null,
        centi_amount?: string | null,
        currency_code?: string | null,
        term_of_service?: string | null,
        state_name?: string | null,
        current_milestone_id?: string | null,
        current_milestone_index?: number | null,
        job_id: string,
        proposal_id: string,
        buyer_id: string,
        seller_id: string,
        created_at: string,
        updated_at: string,
      } | null,
      is_buyer_showcase?: number | null,
      is_seller_showcase?: number | null,
      tag_items?:  {
        __typename: "ModelTagItemConnection",
        nextToken?: string | null,
      } | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    job_id?: string | null,
    job?:  {
      __typename: "Job",
      id: string,
      title?: string | null,
      description?: string | null,
      min_budget?: string | null,
      min_centi_budget?: string | null,
      max_budget?: string | null,
      max_centi_budget?: string | null,
      currency_code?: string | null,
      status?: string | null,
      is_exclusive?: number | null,
      publication_date_time?: string | null,
      deadline_date_time?: string | null,
      expiration_date_time?: string | null,
      is_comercial_artwork?: number | null,
      is_dreambox?: number | null,
      is_private_artwork?: number | null,
      file_format?: Array< string | null > | null,
      buyer_id: string,
      buyer?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      seller_id: string,
      seller?:  {
        __typename: "User",
        id: string,
        display_name?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        username?: string | null,
        email_address?: string | null,
        birth_date?: string | null,
        phone_number?: string | null,
        country_code?: string | null,
        subscriber_number?: string | null,
        bio?: string | null,
        is_artist?: number | null,
        seller_onboarding_stage?: string | null,
        term_of_service?: string | null,
        cccd?: string | null,
        mst?: string | null,
        cccd_images?: Array< string | null > | null,
        mst_stage?: string | null,
        profile_picture_url?: string | null,
        cover_picture_url?: string | null,
        balance_amount?: string | null,
        balance_centi_amount?: string | null,
        balance_currency_code?: string | null,
        usd_balance_amount?: string | null,
        usd_balance_centi_amount?: string | null,
        contract_paid_quantity?: number | null,
        seller_star_quantity?: number | null,
        seller_review_quantity?: number | null,
        buyer_star_quantity?: number | null,
        buyer_review_quantity?: number | null,
        commision_started_quantity?: number | null,
        commission_completed_quantity?: number | null,
        commission_completed_on_time_quantity?: number | null,
        commission_cancelled_seller_fault_quantity?: number | null,
        commission_cancelled_buyer_fault_quantity?: number | null,
        commission_cancelled_mutually_quantity?: number | null,
        commission_cumulative_late_seconds?: number | null,
        commission_max_late_seconds?: number | null,
        device_tokens?: Array< string | null > | null,
        is_selling?: number | null,
        is_selling_abroad?: number | null,
        last_time_open_list_notification?: string | null,
        discount_code?: string | null,
        follower_quantity?: number | null,
        leader_quantity?: number | null,
        has_unread_commissions?: number | null,
        has_unread_jobs?: number | null,
        message_cumulative_response_seconds?: number | null,
        message_response_on_time_quantity?: number | null,
        message_response_late_quantity?: number | null,
        bank_name?: string | null,
        account_holder_name?: string | null,
        account_last_four?: string | null,
        address_country_code?: string | null,
        city?: string | null,
        address_line1?: string | null,
        additional_info?: string | null,
        created_at: string,
        updated_at: string,
      } | null,
      proposals?:  {
        __typename: "ModelProposalConnection",
        nextToken?: string | null,
      } | null,
      images?:  {
        __typename: "ModelImageConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      contacted_seller_ids?: Array< string | null > | null,
      character_items?:  {
        __typename: "ModelCharacterItemConnection",
        nextToken?: string | null,
      } | null,
      created_at: string,
      updated_at: string,
    } | null,
    created_at: string,
    updated_at: string,
  } | null,
};
