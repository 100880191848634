import { Message, UpdateUserChannelInput, UserChannel } from '@src/API';
import { ListData } from '@src/libs/models';
import { IChannelRepo, RealChannelRepo } from '@src/repositories/channelRepo';
import { IMessageRepo, RealMessageRepo } from '@src/repositories/messageRepo';
import { IUserChannelRepo, RealUserChannelRepo } from '@src/repositories/userChannelRepo';
import { v4 as uuidv4 } from 'uuid';

export interface IChatService {
  startChat(senderId: string, receiverId: string): Promise<UserChannel>;
  sendMessage(
    msgId: string,
    channelId: string,
    senderId: string,
    content?: string,
    imageUrl?: string,
    availableTo?: string,
  ): Promise<Message>;
  getUserChannelsByUserId(userId: string): Promise<ListData<UserChannel>>;
  listMessagesByChannelId(channelId: string, nextToken?: string): Promise<ListData<Message>>;
  getUserChannelsByChannelIdUserId({
    channelId,
    userId,
  }: {
    channelId: string;
    userId?: string | null | undefined;
  }): Promise<ListData<UserChannel>>;
  hasUserChannel({ channelId, userId }: { channelId: string; userId: string }): Promise<boolean>;
  saveDateTimeReadMessage(userChannelId: string): Promise<void>;
  updateNextTimeShouldReceiveAnEmail({
    receiverUserChannelId,
    dateTime,
  }: {
    receiverUserChannelId: string;
    dateTime: string | undefined | null;
  }): Promise<void>;
  getUserChannel(userChannelId: string): Promise<UserChannel>;
  updateUserChannel(input: UpdateUserChannelInput): Promise<UserChannel>;
}

export class RealChatService implements IChatService {
  private channelRepo: IChannelRepo;
  private userChannelRepo: IUserChannelRepo;
  private messageRepo: IMessageRepo;

  public constructor() {
    this.channelRepo = new RealChannelRepo();
    this.userChannelRepo = new RealUserChannelRepo();
    this.messageRepo = new RealMessageRepo();
  }

  public async startChat(senderId: string, receiverId: string): Promise<UserChannel> {
    // Check if there is already a channel between sender and receiver
    // by fetching all user channels of sender, then find receiverId
    const senderUserChannels = await this.userChannelRepo.getUserChannelsByUserId(senderId);

    const senderUserChannelConnectToReceiver = senderUserChannels.list?.find((userChannel) => {
      const userChannelsOfChannel = userChannel.channel?.user_channels?.items || [];
      return userChannelsOfChannel.some((item) => item?.user_id === receiverId);
    });

    // If found a user channel, return it
    if (senderUserChannelConnectToReceiver) {
      return senderUserChannelConnectToReceiver;
    }

    // Create a new channel
    const channelId = uuidv4();

    // In parallel: Create channel, sender user channel, receiver user channel
    const [, senderUserChannel] = await Promise.all([
      this.channelRepo.create({
        id: channelId,
        name: 'Chat',
      }),
      this.userChannelRepo.create({
        channel_id: channelId,
        user_id: senderId,
        update_date_time: new Date().toISOString(),
        status: 'OPEN',
      }),
      this.userChannelRepo.create({
        channel_id: channelId,
        user_id: receiverId,
        update_date_time: new Date().toISOString(),
        status: 'OPEN',
      }),
    ]);

    // Re-fetch sender user channel to get channel and other user data
    const refreshSenderUserChannel = await this.userChannelRepo.get(senderUserChannel.id);
    return refreshSenderUserChannel;
  }

  public async sendMessage(
    msgId: string,
    channelId: string,
    senderId: string,
    content?: string,
    imageUrl?: string,
    availableTo?: string,
  ): Promise<Message> {
    const message: Message = await this.messageRepo.create({
      id: msgId,
      channel_id: channelId,
      sender_id: senderId,
      content: content,
      publication_date_time: new Date().toISOString(),
      image_url: imageUrl,
      available_to: availableTo,
    });
    return message;
  }

  public async getUserChannelsByUserId(userId: string): Promise<ListData<UserChannel>> {
    return await this.userChannelRepo.getUserChannelsByUserId(userId);
  }

  public async listMessagesByChannelId(
    channelId: string,
    nextToken?: string,
  ): Promise<ListData<Message>> {
    return this.messageRepo.listMessages(channelId, nextToken);
  }

  public async getUserChannelsByChannelIdUserId({
    channelId,
    userId,
  }: {
    channelId: string;
    userId?: string | null | undefined;
  }): Promise<ListData<UserChannel>> {
    return this.userChannelRepo.getUserChannelsByChannelIdUserId({ channelId, userId });
  }

  /**
   * Whether there exists at least one user channel with the given channel id and user id.
   */
  public async hasUserChannel({
    channelId,
    userId,
  }: {
    channelId: string;
    userId: string;
  }): Promise<boolean> {
    return this.userChannelRepo.hasUserChannel({ channelId, userId });
  }

  public async saveDateTimeReadMessage(userChannelId: string): Promise<void> {
    const userChannel = await this.userChannelRepo.get(userChannelId);
    await this.userChannelRepo.update({
      id: userChannelId,
      channel_id: userChannel?.channel_id,
      user_id: userChannel?.user_id,
      status: userChannel?.status,
      update_date_time: new Date().toISOString(),
      next_time_should_receive_an_email: null,
    });
    return;
  }

  public async updateNextTimeShouldReceiveAnEmail({
    receiverUserChannelId,
    dateTime,
  }: {
    receiverUserChannelId: string;
    dateTime: string | undefined | null;
  }): Promise<void> {
    await this.userChannelRepo.update({
      id: receiverUserChannelId,
      next_time_should_receive_an_email: dateTime,
    });
    return;
  }

  public async getUserChannel(userChannelId: string): Promise<UserChannel> {
    const userChannel = await this.userChannelRepo.get(userChannelId);
    return userChannel;
  }

  public async updateUserChannel(input: UpdateUserChannelInput): Promise<UserChannel> {
    const userChannel = await this.userChannelRepo.update(input);
    return userChannel;
  }
}
