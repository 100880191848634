export const customCreateUserChannel = /* GraphQL */ `
  mutation CreateUserChannel(
    $input: CreateUserChannelInput!
    $condition: ModelUserChannelConditionInput
  ) {
    createUserChannel(input: $input, condition: $condition) {
      id
      channel_id
      user_id
      update_date_time
      status
      next_time_should_receive_an_email
      next_time_should_receive_a_late_response
      request_start_date_time
      created_at
      updated_at
    }
  }
`;
