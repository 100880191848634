import { number } from 'yup';

declare module 'yup' {
  interface NumberSchema {
    isValidVNDPrice(message: string): NumberSchema;
  }
}

number().constructor.prototype.isValidVNDPrice = function (message: string) {
  return this.test('isValidVNDPrice', message, function customCheck(this: any, value: number) {
    const { path, createError } = this;
    if (Number(value) % 1000 !== 0) {
      return createError({ path, message });
    }
    return true;
  });
};
