import WizardHeader from '@components/header/WizardHeader';
import { DreamerlyUser } from '@src/providers/AuthProvider';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';

function RequestCodeCompleted({ user }: { user: DreamerlyUser }) {
  // Header loading states
  const [isExitLoading, setIsExitLoading] = useState(false);

  const router = useRouter();

  const onExitClick = () => {
    setIsExitLoading(true);
    // Change loading state before changing stage to avoid unmounted setState
    setIsExitLoading(false);
    router.push('/');
  };

  return (
    <div>
      <WizardHeader handleOnExitClick={onExitClick} isExitLoading={isExitLoading} />
      <div className="flex w-full justify-center pb-24">
        <div className="flex-col w-152">
          <div className="mt-16 mb-4">
            <span className="text-l font-medium">Đăng ký nhận mã tham gia thành công ❤️</span>
          </div>
          <h1 className="text-8xl mb-6 font-semibold">Vui lòng chờ Dreamerly review nhé</h1>
          <p className="text-l-tall">
            Cám ơn bạn đã cung cấp thông tin để nhận mã tham gia với vai trò họa sĩ trên nền tảng
            của Dreamerly. Đội ngũ phát triển Dreamerly đang tiến hành review thông tin bạn cung cấp
            và sẽ gửi mã tham gia tới email của bạn trong thời gian sớm nhất nhé.
          </p>
          <div className="flex flex-row mt-6">
            <Image
              src={'/ic-request-code-completed.svg'}
              height={120}
              width={128}
              alt="ic-request-code-completed"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestCodeCompleted;
