import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

// Thanh 10/01/2024: Removed import and instantiation of RealNotificationService and return empty to reduce cost.

export const useListNotificationByUserIdQuery = (userId: string | null | undefined) => {
  return useQuery({
    queryKey: ['notificationService', 'listNotificationByUserIdQuery', userId],
    refetchInterval: 1000 * 60 * 1, // 2 minutes
    queryFn: async () => {
      // Return an empty result instead of calling the service
      return {
        list: [],
        nextToken: null,
      };
    },
    enabled: !!userId,
  });
};

export const useListNotificationByUserIdInfiniteQuery = (userId: string | null | undefined) => {
  return useInfiniteQuery({
    queryKey: ['notificationService', 'listNotificationByUserIdInfiniteQuery', userId],
    queryFn: async ({ pageParam }) => {
      // Return an empty result instead of calling the service
      return {
        list: [],
        nextToken: null,
      };
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.nextToken;
    },
    enabled: !!userId,
  });
};
