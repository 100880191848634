import WizardBottomAppBar from '@components/bottom/WizardBottomAppBar';
import WizardHeader from '@components/header/WizardHeader';
import { Price, Product } from '@src/API';
import { PriceCategory, PriceType, ProductStatus } from '@src/customGraphql/customModels';
import { SellerOnboardingStage } from '@src/pages/become-a-seller';
import { DreamerlyUser } from '@src/providers/AuthProvider';
import captureException from '@src/services/loggerService';
import { IPriceService, RealPriceService } from '@src/services/priceService';
import { IUserService, RealUserService } from '@src/services/userService';
import { MIN_PRODUCT_PRICE } from '@src/utils/constants';
import { formatDigitOnlyText } from '@src/utils/formatText';
import {
  CoreInputType,
  ExtraInputType,
  getPricesToAdd,
  getPricesToDelete,
  getPricesToUpdate,
} from '@src/utils/prices';
import { useQueryClient } from '@tanstack/react-query';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CreatePricesCoreTable from './CreatePricesCoreTable';
import CreatePricesExtraTable from './CreatePricesExtraTable';

type CreatePricesProps = {
  user: DreamerlyUser;
  refreshUser: () => Promise<DreamerlyUser | null | undefined>;
  setStage: (stage: SellerOnboardingStage) => void;
  productToAddPrices: Product;
  setProductToAddPrices: (product: Product) => void;
};

function CreatePrices(props: CreatePricesProps) {
  const { user, refreshUser, setStage, productToAddPrices } = props;
  const [coreList, setCoreList] = useState<CoreInputType[]>([]);
  const [extraList, setExtraList] = useState<ExtraInputType[]>([]);
  const priceService: IPriceService = new RealPriceService();
  // Header and BottomAppBar loading states
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isBackLoading, setIsBackLoading] = useState(false);
  const [isExitLoading, setIsExitLoading] = useState(false);
  const router = useRouter();
  const queryClient = useQueryClient();
  const userService: IUserService = new RealUserService();

  // console.log(user);
  // console.log('productToAddPrices');
  // console.log(productToAddPrices);

  const existingPrices = useMemo(
    () => (productToAddPrices?.prices?.items || []).filter((price) => !!price) as Price[],
    [productToAddPrices],
  );

  useEffect(() => {
    // Get the initial core list from the product's CORE prices
    const initialCoreList = existingPrices
      .filter((price) => price.type === PriceType.CORE)
      .map((price) => {
        return {
          id: price.id,
          displayName: price.display_name || '',
          formattedFixedAmount: formatDigitOnlyText(price.fixed_amount || '') || '',
          unformattedFixedAmount: price.fixed_amount || '',
          errorMessage: '',
          index: price.index || 0,
        };
      });
    console.log('QQ: initialCoreList', initialCoreList);
    // If initalCoreList is empty, add an empty row
    if (initialCoreList.length === 0) {
      initialCoreList.push({
        id: uuidv4(),
        displayName: '',
        formattedFixedAmount: '',
        unformattedFixedAmount: '',
        errorMessage: '',
        index: 0,
      });
    }
    setCoreList(initialCoreList);

    // Get the initial extra list from the product's EXTRA prices
    const initialExtraList = existingPrices
      .filter((price) => price.type === PriceType.EXTRA)
      .map((price) => {
        return {
          id: price.id,
          displayName: price.display_name || '',
          category: price.category || '',
          formattedFixedAmount: formatDigitOnlyText(price.fixed_amount || '') || '',
          unformattedFixedAmount: price.fixed_amount || '',
          formattedMinAmount: formatDigitOnlyText(price.min_amount || '') || '',
          unformattedMinAmount: price.min_amount || '',
          formattedMaxAmount: formatDigitOnlyText(price.max_amount || '') || '',
          unformattedMaxAmount: price.max_amount || '',
          percentage: price.percentage?.toString() || '',
          fixedAmountErrorMessage: '',
          minAmountErrorMessage: '',
          maxAmountErrorMessage: '',
          index: price.index || 0,
        };
      });
    // If initalExtraList is empty, add an empty row
    if (initialExtraList.length === 0) {
      initialExtraList.push({
        id: uuidv4(),
        displayName: '',
        category: PriceCategory.FIXED,
        formattedFixedAmount: '',
        unformattedFixedAmount: '',
        formattedMinAmount: '',
        unformattedMinAmount: '',
        formattedMaxAmount: '',
        unformattedMaxAmount: '',
        percentage: '',
        fixedAmountErrorMessage: '',
        minAmountErrorMessage: '',
        maxAmountErrorMessage: '',
        index: 0,
      });
    }
    setExtraList(initialExtraList);
  }, [productToAddPrices, existingPrices]);

  /**
   * Helper function to save current inputs to DDB.
   */
  const saveToDDB = async () => {
    // Get a list of prices to add, update, and delete
    const coreListIncludeIndex = coreList.map((price, index) => {
      return {
        ...price,
        index,
      };
    });
    const extraListIncludeIndex = extraList.map((price, index) => {
      return {
        ...price,
        index,
      };
    });
    const pricesToAdd = getPricesToAdd(coreListIncludeIndex, extraListIncludeIndex, existingPrices);
    const pricesToUpdate = getPricesToUpdate(
      coreListIncludeIndex,
      extraListIncludeIndex,
      existingPrices,
    );
    const pricesToDelete = getPricesToDelete(
      coreListIncludeIndex,
      extraListIncludeIndex,
      existingPrices,
    );

    // Create parallel call to create & update prices
    await Promise.all(
      pricesToAdd.core
        .map((price) => {
          // Calculate fixedCentiAmount from fixedAmount
          const fixedCentiAmount = price.unformattedFixedAmount
            ? (Number(price.unformattedFixedAmount) * 100).toString()
            : undefined;

          return priceService.createPrice({
            productId: productToAddPrices.id,
            displayName: price.displayName,
            type: PriceType.CORE,
            category: PriceCategory.FIXED,
            fixedAmount: price.unformattedFixedAmount,
            fixedCentiAmount: fixedCentiAmount,
            index: price.index,
          });
        })
        .concat(
          pricesToAdd.extra.map((price) => {
            switch (price.category) {
              case PriceCategory.FIXED: {
                // Calculate fixedCentiAmount from fixedAmount
                const fixedCentiAmount = price.unformattedFixedAmount
                  ? (Number(price.unformattedFixedAmount) * 100).toString()
                  : undefined;
                return priceService.createPrice({
                  productId: productToAddPrices.id,
                  displayName: price.displayName,
                  type: PriceType.EXTRA,
                  category: price.category,
                  fixedAmount: price.unformattedFixedAmount,
                  fixedCentiAmount: fixedCentiAmount,
                  index: price.index,
                });
              }
              case PriceCategory.RANGE: {
                const minCentiAmount = price.unformattedMinAmount
                  ? (Number(price.unformattedMinAmount) * 100).toString()
                  : undefined;
                const maxCentiAmount = price.unformattedMaxAmount
                  ? (Number(price.unformattedMaxAmount) * 100).toString()
                  : undefined;
                return priceService.createPrice({
                  productId: productToAddPrices.id,
                  displayName: price.displayName,
                  type: PriceType.EXTRA,
                  category: price.category,
                  minAmount: price.unformattedMinAmount,
                  minCentiAmount: minCentiAmount,
                  maxAmount: price.unformattedMaxAmount,
                  maxCentiAmount: maxCentiAmount,
                  index: price.index,
                });
              }
              default: {
                // Default case is PERCENTAGE
                const percentage = price.percentage === '' ? undefined : Number(price.percentage);
                return priceService.createPrice({
                  productId: productToAddPrices.id,
                  displayName: price.displayName,
                  type: PriceType.EXTRA,
                  category: PriceCategory.PERCENTAGE,
                  percentage: percentage,
                  index: price.index,
                });
              }
            }
          }),
        )
        .concat(
          pricesToUpdate.core.map((price) => {
            return priceService.updatePrice({
              id: price.id,
              displayName: price.displayName,
              fixedAmount: price.unformattedFixedAmount,
              index: price.index,
            });
          }),
        )
        .concat(
          pricesToUpdate.extra.map((price) => {
            const fixedAmount =
              price.unformattedFixedAmount === '' ? undefined : price.unformattedFixedAmount;
            const minAmount =
              price.unformattedMinAmount === '' ? undefined : price.unformattedMinAmount;
            const maxAmount =
              price.unformattedMaxAmount === '' ? undefined : price.unformattedMaxAmount;
            const percentage = price.percentage === '' ? undefined : Number(price.percentage);

            return priceService.updatePrice({
              id: price.id,
              displayName: price.displayName,
              fixedAmount: fixedAmount,
              minAmount: minAmount,
              maxAmount: maxAmount,
              percentage: percentage,
              index: price.index,
            });
          }),
        ),
    );

    // Delete prices
    await Promise.all(pricesToDelete.map((price) => priceService.deletePrice(price.id)));
  };

  const onNextClick = async () => {
    // Validate input
    let isValid = true;
    // Check all CORE price inputs to make sure that they are multiple of 1000
    const newCoreList = coreList.map((price) => {
      if (price.unformattedFixedAmount && Number(price.unformattedFixedAmount) % 1000 !== 0) {
        isValid = false;
        return {
          ...price,
          errorMessage: 'Cần điền đơn vị nghìn đồng.',
        };
      }
      if (
        price.unformattedFixedAmount &&
        Number(price.unformattedFixedAmount) < MIN_PRODUCT_PRICE
      ) {
        isValid = false;
        return {
          ...price,
          errorMessage: 'Cần ít nhất ₫100,000.',
        };
      }
      return price;
    });
    setCoreList(newCoreList);
    // Check all EXTRA price inputs to make sure that if they are of type
    // FIXED or RANGE, they are multiple of 1000
    const newExtraList = extraList.map((price) => {
      if (
        price.category === PriceCategory.FIXED &&
        price.unformattedFixedAmount &&
        Number(price.unformattedFixedAmount) % 1000 !== 0
      ) {
        isValid = false;
        return {
          ...price,
          fixedAmountErrorMessage: 'Cần điền đơn vị nghìn đồng.',
        };
      }
      if (price.category === PriceCategory.RANGE) {
        let minAmountErrorMessage = '';
        let maxAmountErrorMessage = '';
        // If minAmount is >= maxAmount, show error message
        if (
          price.unformattedMinAmount &&
          price.unformattedMaxAmount &&
          Number(price.unformattedMinAmount) >= Number(price.unformattedMaxAmount)
        ) {
          isValid = false;
          minAmountErrorMessage = 'Khoảng giá không hợp lệ.';
          maxAmountErrorMessage = 'Khoảng giá không hợp lệ.';
        }

        // If the prices are not multiple of 1000, show error message
        if (price.unformattedMinAmount && Number(price.unformattedMinAmount) % 1000 !== 0) {
          isValid = false;
          minAmountErrorMessage = 'Cần điền đơn vị nghìn đồng.';
        }
        if (price.unformattedMaxAmount && Number(price.unformattedMaxAmount) % 1000 !== 0) {
          isValid = false;
          maxAmountErrorMessage = 'Cần điền đơn vị nghìn đồng.';
        }
        if (minAmountErrorMessage || maxAmountErrorMessage) {
          return {
            ...price,
            minAmountErrorMessage,
            maxAmountErrorMessage,
          };
        }
      }
      return price;
    });
    setExtraList(newExtraList);
    if (!isValid) return;

    // Button state
    setIsNextLoading(true);

    try {
      // Save current inputs to DDB
      await saveToDDB();

      await Promise.all([
        // Refetch productToAddPrices by invalidating the query
        queryClient.invalidateQueries([
          'productService',
          'getProductsByArtistIdStatus',
          user.id,
          ProductStatus.ACTIVE,
        ]),
        // Update User table
        userService.updateSellerOnboardingStage(
          user.id,
          SellerOnboardingStage.CreatePrices.toString(),
        ),
      ]);

      // Change loading state before changing stage to avoid unmounted setState
      setIsNextLoading(false);

      // Move to the next screen
      setStage(SellerOnboardingStage.UpdateInfo);
    } catch (error) {
      captureException(error);
      setIsNextLoading(false);
    }
  };

  const onBackClick = async () => {
    setIsBackLoading(true);
    try {
      // Update User table
      await userService.updateSellerOnboardingStage(
        user.id,
        SellerOnboardingStage.CreateProducts.toString(),
      );
    } catch (error) {
      captureException(error);
    }
    // Change loading state before changing stage to avoid unmounted setState
    setIsBackLoading(false);
    // Navigate back to the previous screen
    setStage(SellerOnboardingStage.CreateProducts);
  };

  const onExitClick = async () => {
    // Button state
    setIsExitLoading(true);

    // Save current inputs to DDB
    try {
      await saveToDDB();

      await Promise.all([
        // Invalidate the query to clear the cache in case user returns to
        // the onboarding flow
        queryClient.invalidateQueries([
          'productService',
          'getProductsByArtistIdStatus',
          user.id,
          ProductStatus.ACTIVE,
        ]),
        // Refresh user, so that the onboarding stage is updated in AuthProvider
        refreshUser(),
      ]);
    } catch (error) {
      captureException(error);
    }
    // Change loading state before changing stage to avoid unmounted setState
    setIsExitLoading(false);
    // Do not use router.back() here in case this is a new tab (no history)
    router.push('/');
  };

  return (
    <div>
      <WizardHeader handleOnExitClick={onExitClick} isExitLoading={isExitLoading} />
      <div className="flex w-full justify-center pb-24">
        <div className="flex-col w-152">
          <h1 className="text-4xl mb-4 font-medium">
            Tạo bảng giá tranh {productToAddPrices.display_name}
          </h1>
          <CreatePricesCoreTable coreList={coreList} setCoreList={setCoreList} />
          <div className="flex flex-row mt-8 mb-4">
            <h2 className="text-xl font-medium mr-4">Phụ phí</h2>
            <Image src="/ic-chevron-up.svg" width={20} height={20} />
          </div>
          <h2 className="text-m mb-4 text-content-t200">
            Thêm phụ phí cho background, character... nếu cần. Ấn nút ở cột{' '}
            <span className="font-semibold">Loại</span> để tạo giá theo phần trăm hoặc khoảng giá
            nhé.
          </h2>
          <CreatePricesExtraTable extraList={extraList} setExtraList={setExtraList} />
        </div>
      </div>
      <WizardBottomAppBar
        progressValue={70}
        handleOnBackClick={onBackClick}
        isBackLoading={isBackLoading}
        handleOnNextClick={onNextClick}
        isNextLoading={isNextLoading}
      />
    </div>
  );
}

export default CreatePrices;
