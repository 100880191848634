import PrimaryButton from '@components/buttons/primary/PrimaryButton';
import { AppBar, LinearProgress } from '@mui/material';

type WizardBottomAppBarProps = {
  progressValue: number;
  handleOnBackClick: () => void;
  isBackLoading: boolean;
  handleOnNextClick: () => void;
  isNextLoading: boolean;
};

function WizardBottomAppBar(props: WizardBottomAppBarProps) {
  const { progressValue, handleOnNextClick, isBackLoading, handleOnBackClick, isNextLoading } =
    props;

  return (
    <AppBar
      position="fixed"
      color="inherit"
      elevation={0}
      sx={{
        top: 'auto',
        bottom: 0,
        zIndex: 1000,
      }}
    >
      <div className="flex flex-col">
        <LinearProgress
          variant="determinate"
          value={progressValue}
          sx={{
            height: 6,
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#212121',
            },
            backgroundColor: '#CCCCCC',
          }}
        />
        <div className="flex flex-row items-center w-full px-12 py-4 justify-between">
          <button
            className="flex flex-row py-2 px-4 rounded-lg font-semibold
            hover:bg-greyscale-g1 underline text-m items-center justify-center"
            onClick={handleOnBackClick}
            disabled={isBackLoading}
          >
            {/* If is loading, keep the text invisible to keep the same button size */}
            <div className={isBackLoading ? 'invisible' : ''}>
              <span>Quay lại</span>
            </div>
            {/* Loading animation */}
            {isBackLoading && (
              <div
                className="h-5 w-5 animate-spin rounded-full border-2
                border-greyscale-g5 border-r-transparent absolute"
              />
            )}
          </button>
          <PrimaryButton onClick={handleOnNextClick} sizeType="lg" isLoading={isNextLoading}>
            Tiếp tục
          </PrimaryButton>
        </div>
      </div>
    </AppBar>
  );
}

export default WizardBottomAppBar;
