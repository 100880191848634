import { Price } from '@src/API';
import { PriceCategory, PriceType } from '@src/customGraphql/customModels';

export type CoreInputType = {
  id: string;
  displayName: string;
  // We maintain two versions of the fixed amount: formatted and unformatted.
  // The formatted version is used for display purposes, while the unformatted
  // version is used for DDB update.
  formattedFixedAmount: string; // Formatted with thousand separators
  unformattedFixedAmount: string; // Unformatted, without thousand separators
  errorMessage: string;
  index?: number;
};

export type ExtraInputType = {
  id: string;
  displayName: string;
  category: string;
  // We maintain two versions of the amount: formatted and unformatted.
  // The formatted version is used for display purposes, while the unformatted
  // version is used for DDB update.
  formattedFixedAmount: string;
  unformattedFixedAmount: string;
  formattedMinAmount: string;
  unformattedMinAmount: string;
  formattedMaxAmount: string;
  unformattedMaxAmount: string;
  percentage: string;
  fixedAmountErrorMessage: string;
  minAmountErrorMessage: string;
  maxAmountErrorMessage: string;
  index?: number;
};

/**
 * Helper function to determine if the coreInput is valid.
 */
export const isCoreInputValid = (coreInput: CoreInputType) => {
  return (
    !!coreInput.displayName &&
    !!coreInput.formattedFixedAmount &&
    !!coreInput.unformattedFixedAmount
  );
};

/**
 * Helper function to determine if the coreInput is different from an existing price.
 */
export const isCoreInputDifferentFromPrice = (
  coreInput: CoreInputType,
  existingPrice: Price | undefined,
) => {
  if (!existingPrice || existingPrice.type !== PriceType.CORE) return false;

  return (
    existingPrice.category !== PriceCategory.FIXED ||
    coreInput.displayName !== existingPrice.display_name ||
    coreInput.unformattedFixedAmount !== existingPrice.fixed_amount ||
    coreInput.index !== existingPrice.index
  );
};

/**
 * Helper function to determine if the extraInput is valid.
 */
export const isExtraInputValid = (extraInput: ExtraInputType) => {
  switch (extraInput.category) {
    case PriceCategory.FIXED:
      return (
        !!extraInput.displayName &&
        !!extraInput.formattedFixedAmount &&
        !!extraInput.unformattedFixedAmount &&
        extraInput.formattedMinAmount === '' &&
        extraInput.unformattedMinAmount === '' &&
        extraInput.formattedMaxAmount === '' &&
        extraInput.unformattedMaxAmount === '' &&
        extraInput.percentage === ''
      );
    case PriceCategory.RANGE:
      return (
        !!extraInput.displayName &&
        !!extraInput.formattedMinAmount &&
        !!extraInput.unformattedMinAmount &&
        !!extraInput.formattedMaxAmount &&
        !!extraInput.unformattedMaxAmount &&
        extraInput.formattedFixedAmount === '' &&
        extraInput.unformattedFixedAmount === '' &&
        extraInput.percentage === ''
      );
    case PriceCategory.PERCENTAGE:
      return (
        !!extraInput.displayName &&
        !!extraInput.percentage &&
        extraInput.formattedFixedAmount === '' &&
        extraInput.unformattedFixedAmount === '' &&
        extraInput.formattedMinAmount === '' &&
        extraInput.unformattedMinAmount === '' &&
        extraInput.formattedMaxAmount === '' &&
        extraInput.unformattedMaxAmount === ''
      );
    default:
      return false;
  }
};

/**
 * Helper function to determine if the extraInput is different from an existing price.
 */
export const isExtraInputDifferentFromPrice = (
  extraInput: ExtraInputType,
  existingPrice: Price | undefined,
) => {
  if (!existingPrice || existingPrice.type !== PriceType.EXTRA) return false;

  const existingPercentage = existingPrice.percentage?.toString() || '';

  switch (extraInput.category) {
    case PriceCategory.FIXED:
      return (
        extraInput.displayName !== existingPrice.display_name ||
        extraInput.unformattedFixedAmount !== existingPrice.fixed_amount ||
        extraInput.index !== existingPrice.index
      );
    case PriceCategory.RANGE:
      return (
        extraInput.displayName !== existingPrice.display_name ||
        extraInput.unformattedMinAmount !== existingPrice.min_amount ||
        extraInput.unformattedMaxAmount !== existingPrice.max_amount ||
        extraInput.index !== existingPrice.index
      );
    case PriceCategory.PERCENTAGE:
      return (
        extraInput.displayName !== existingPrice.display_name ||
        extraInput.percentage !== existingPercentage ||
        extraInput.index !== existingPrice.index
      );
    default:
      return false;
  }
};

/**
 * Helper function to get the list of prices to update. These are the prices
 * that have the same id as the existing prices, but different values
 */
export const getPricesToUpdate = (
  coreInputs: CoreInputType[],
  extraInputs: ExtraInputType[],
  existingPrices: Price[],
) => {
  // Get the list of CORE prices to update: prices with the same id as the
  // existing prices, but different display_name, index or fixed_amount. The new
  // display_name and fixed_amount must not be empty
  const corePricesToUpdate = coreInputs
    .filter((coreInput) => {
      const existingPrice = existingPrices.find((price) => price.id === coreInput.id);
      return isCoreInputValid(coreInput) && isCoreInputDifferentFromPrice(coreInput, existingPrice);
    })
    .map((coreInput) => {
      return {
        id: coreInput.id,
        displayName: coreInput.displayName,
        unformattedFixedAmount: coreInput.unformattedFixedAmount,
        index: coreInput.index,
      };
    });

  // Get the list of EXTRA prices to update: prices with the same id as the
  // existing prices, but different display_name, category, fixed_amount,
  // min_amount, max_amount, or percentage
  const extraPricesToUpdate = extraInputs
    .filter((extraInput) => {
      const existingPrice = existingPrices.find((price) => price.id === extraInput.id);
      return (
        isExtraInputValid(extraInput) && isExtraInputDifferentFromPrice(extraInput, existingPrice)
      );
    })
    .map((extraInput) => {
      return {
        id: extraInput.id,
        displayName: extraInput.displayName,
        category: extraInput.category,
        unformattedFixedAmount: extraInput.unformattedFixedAmount,
        unformattedMinAmount: extraInput.unformattedMinAmount,
        unformattedMaxAmount: extraInput.unformattedMaxAmount,
        percentage: extraInput.percentage,
        index: extraInput.index,
      };
    });

  return {
    core: corePricesToUpdate,
    extra: extraPricesToUpdate,
  };
};

/**
 * Helper function to get the list of prices to add. These are the prices
 * whose ids are not included in the existing prices
 */
export const getPricesToAdd = (
  coreInputs: CoreInputType[],
  extraInputs: ExtraInputType[],
  existingPrices: Price[],
) => {
  // Get the list of CORE prices to add: valid inputs whose id is not included in
  // the existing prices
  const corePricesToAdd = coreInputs
    .filter(
      (coreInput) =>
        !existingPrices.find((price) => price.id === coreInput.id) && isCoreInputValid(coreInput),
    )
    .map((coreInput) => {
      return {
        displayName: coreInput.displayName,
        unformattedFixedAmount: coreInput.unformattedFixedAmount,
        index: coreInput.index,
      };
    });

  // Get the list of EXTRA prices to add: valid inputs whose id is not included in
  // the existing prices
  const extraPricesToAdd = extraInputs
    .filter((extraInput) => {
      return (
        !existingPrices.find((price) => price.id === extraInput.id) && isExtraInputValid(extraInput)
      );
    })
    .map((extraInput) => {
      return {
        displayName: extraInput.displayName,
        category: extraInput.category,
        unformattedFixedAmount: extraInput.unformattedFixedAmount,
        unformattedMinAmount: extraInput.unformattedMinAmount,
        unformattedMaxAmount: extraInput.unformattedMaxAmount,
        percentage: extraInput.percentage,
        index: extraInput.index,
      };
    });
  // console.log('extraPricesToAdd', extraPricesToAdd);
  // console.log('corePricesToAdd', corePricesToAdd);
  // console.log('existingPrices', existingPrices);
  // console.log('extraInputs', extraInputs);
  // console.log('coreInputs', coreInputs);

  return {
    core: corePricesToAdd,
    extra: extraPricesToAdd,
  };
};

/**
 * Helper function to get the list of prices to delete. These are the
 * existing prices whose ids are not present in the inputs
 */
export const getPricesToDelete = (
  coreInputs: CoreInputType[],
  extraInputs: ExtraInputType[],
  existingPrices: Price[],
) => {
  // Get the list of CORE prices to delete: existing prices whose ids are not
  // present in either the CORE or EXTRA inputs
  const pricesToDelete = existingPrices.filter(
    (price) =>
      !coreInputs.find((coreInput) => coreInput.id === price.id) &&
      !extraInputs.find((extraInput) => extraInput.id === price.id),
  );

  return pricesToDelete;
};
