import {
  CreatePriceInput,
  CreatePriceMutation,
  CreatePriceMutationVariables,
  DeletePriceMutationVariables,
  Price,
  PriceByProductIdStatusQuery,
  PriceByProductIdStatusQueryVariables,
  UpdatePriceInput,
  UpdatePriceMutation,
  UpdatePriceMutationVariables,
} from '@src/API';
import { createPrice, deletePrice, updatePrice } from '@src/graphql/mutations';
import { priceByProductIdStatus } from '@src/graphql/queries';
import { ListData } from '@src/libs/models';
import captureException from '@src/services/loggerService';
import { API } from 'aws-amplify';

export interface IPriceRepo {
  create(price: CreatePriceInput): Promise<Price>;
  update(price: UpdatePriceInput): Promise<Price>;
  delete(id: string): Promise<void>;
  getPricesByProductIdStatus(productId: string, status?: string): Promise<ListData<Price>>;
}

export class RealPriceRepo implements IPriceRepo {
  public async create(price: CreatePriceInput): Promise<Price> {
    const variables: CreatePriceMutationVariables = {
      input: price,
    };

    let res = { data: {} } as { data: CreatePriceMutation };
    try {
      res = (await API.graphql({
        query: createPrice,
        authMode: 'AWS_IAM',
        variables,
      })) as { data: CreatePriceMutation };
    } catch (e) {
      captureException(e);
    }

    return res.data.createPrice as Price;
  }

  public async update(price: UpdatePriceInput): Promise<Price> {
    const variables: UpdatePriceMutationVariables = {
      input: price,
    };

    const res = (await API.graphql({
      query: updatePrice,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: UpdatePriceMutation };

    return res.data.updatePrice as Price;
  }

  public async delete(id: string) {
    const variables: DeletePriceMutationVariables = {
      input: { id: id },
    };

    await API.graphql({
      query: deletePrice,
      authMode: 'AWS_IAM',
      variables,
    });
  }

  public async getPricesByProductIdStatus(
    productId: string,
    status?: string,
  ): Promise<ListData<Price>> {
    const variables: PriceByProductIdStatusQueryVariables = {
      product_id: productId,
      status: { eq: status },
    };
    const res = (await API.graphql({
      query: priceByProductIdStatus,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: PriceByProductIdStatusQuery };

    // Filter out null items
    const prices = (res.data.priceByProductIdStatus?.items || []).filter((item) => item !== null);
    const result: ListData<Price> = {
      list: prices as Price[],
      nextToken: res.data.priceByProductIdStatus?.nextToken || '',
    };
    return result;
  }
}
