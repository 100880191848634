import { IChatService, RealChatService } from '@src/services/chatService';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

const chatService: IChatService = new RealChatService();
export const ChatServiceQueryKey = 'chatService';
export const GetUserChannelsByUserIdQueryKey = 'getUserChannelsByUserId';
export const ListMessagesByChannelIdQueryKey = 'listMessagesByChannelId';
export const ListMessagesByChannelIdInfiniteQueryKey = 'listMessagesByChannelIdInfinite';

export const getGetUserChannelsByUserIdQueryKeys = (userId: string | null | undefined) => {
  return [ChatServiceQueryKey, GetUserChannelsByUserIdQueryKey, userId];
};
export const getListMessagesByChannelIdQueryKeys = (channelId: string | null | undefined) => {
  return [ChatServiceQueryKey, ListMessagesByChannelIdQueryKey, channelId];
};

/**
 * React Query hook to list user channel.
 */
export const useGetUserChannelsByUserIdQuery = (userId: string | null | undefined) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: getGetUserChannelsByUserIdQueryKeys(userId),
    queryFn: async () => {
      if (!userId) return null; // Have to return null, not undefined
      return await chatService.getUserChannelsByUserId(userId);
    },
    enabled: !!userId,
  });
};

export const useListMessagesByChannelIdInfiniteQuery = ({
  channelId,
}: {
  channelId: string | null | undefined;
}) => {
  return useInfiniteQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: getListMessagesByChannelIdQueryKeys(channelId),
    queryFn: async ({ pageParam }) => {
      if (!channelId) return { list: [], nextToken: undefined };
      // When this hook is first triggered, pageParam is undefined
      if (!pageParam && !!channelId) {
        return await chatService.listMessagesByChannelId(channelId);
      }
      // When this hook is triggered the second time onwards, pageParam is defined,
      // so we fetch data using its nextToken (if pageParam is string).
      // If pageParam is undefined, it means there is no data to be fetched.
      if (pageParam) {
        return await chatService.listMessagesByChannelId(channelId, pageParam);
      }
      // In other cases, return empty list
      return { list: [], nextToken: undefined };
    },
    // Get next page params
    getNextPageParam: (lastPage) => {
      return lastPage?.nextToken; // pageParam is nextToken
    },
    enabled: !!channelId,
  });
};

/**
 * React Query hook to list channel messages.
 */
export const useListMessagesByChanneIdlQuery = ({
  channelId,
  nextToken,
}: {
  channelId: string | null | undefined;
  nextToken?: string;
}) => {
  return useQuery({
    // Query keys: name of service, name of method, arguments
    queryKey: [ChatServiceQueryKey, ListMessagesByChannelIdQueryKey, channelId, nextToken],
    queryFn: async () => {
      if (!channelId) return null; // Have to return null, not undefined
      return await chatService.listMessagesByChannelId(channelId, nextToken);
    },
    enabled: !!channelId,
  });
};
