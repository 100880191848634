export function isValidEmail(email: string): boolean {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email);
}

export function isValidPassword(password: string): boolean {
  // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
  // return passwordRegex.test(password);
  return password.length >= 8;
}

export function isValidSubscriberNumber(number: string): boolean {
  // Only contains digits and have at most 11 digits and at least 6 digits
  const numberRegex = /^\d{6,11}$/;
  return numberRegex.test(number);
}

export function isValidName(name: string): boolean {
  // Is not empty
  return name.length > 0;
}

export default isValidEmail;
