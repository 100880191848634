import {
  NotificationContextValue,
  useNotificationState,
} from '@src/providers/NotificationProvider';
import Head from 'next/head';

/**
 * Reference:
 * https://cheatcode.co/tutorials/how-to-handle-seo-metadata-in-next-js
 */
type MetaProps = {
  title?: string | undefined;
  url?: string | undefined;
  description?: string | undefined;
  image?: string | undefined;
  type?: string | undefined;
};

const DefaultTitle = 'Nền tảng mua bán commission của Việt Nam | Dreamerly';
const DefaultMetaDescription = `Dreamerly là nền tảng mua bán commission minh bạch,
  an toàn cho khách hàng và hoạ sĩ Việt: Chibi, Anime, Vtuber, YCH, 3D, và hơn
  thế nữa.`;

const socialTags = ({ title, url, description, image, type }: MetaProps) => {
  const ogImagePath = image || new URL('img-og.webp', process.env.NEXT_PUBLIC_DOMAIN || '').href;
  const twitterCardImagePath =
    image || new URL('img-twitter-card.png', process.env.NEXT_PUBLIC_DOMAIN || '').href;

  const metaTags = [
    // TODO (long): Uncomment the missing tags
    { name: 'twitter:card', content: 'summary' }, // For blog, we may want summary_large_image
    {
      name: 'twitter:site:id',
      content: '1577893585114267648',
    },
    {
      name: 'twitter:creator:id',
      content: '1577893585114267648',
    },
    { name: 'twitter:title', content: title || DefaultTitle },
    { name: 'twitter:description', content: description || DefaultMetaDescription },
    {
      name: 'twitter:image',
      content: twitterCardImagePath,
    },
    { name: 'og:title', content: title },
    { name: 'og:type', content: type || 'website' }, // Default to website
    { name: 'og:url', content: url },
    { name: 'og:image', content: ogImagePath },
    { name: 'og:description', content: description },
    {
      name: 'og:site_name',
      content: 'Dreamerly: Nền tảng mua bán commission của Việt Nam',
    },
    // {
    //   name: 'og:published_time',
    //   content: createdAt || new Date().toISOString(),
    // },
    // {
    //   name: 'og:modified_time',
    //   content: updatedAt || new Date().toISOString(),
    // },
  ];

  return metaTags;
};

export default function Meta({ title, url, description, image, type }: MetaProps) {
  const { unreadNotificationNumber, hasNewNotification } =
    useNotificationState() as NotificationContextValue;

  const total = hasNewNotification ? unreadNotificationNumber : 0;

  return (
    <div>
      <Head>
        <title>
          {total > 0 ? `(${total}) ` : ''}
          {title}
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={description} key="description" />
        <meta itemProp="name" content={title} key="name" />
        <meta itemProp="description" content={description} key="itemPropDescription" />
        <meta itemProp="image" content={image} key="image" />
        {socialTags({
          title,
          url,
          description,
          image,
          type,
        })
          .filter(({ content }) => !!content)
          .map(({ name, content }) => {
            if (name.slice(0, 2) === 'og') {
              return <meta key={name} property={name} content={content} />;
            }
            return <meta key={name} name={name} content={content} />;
          })}
      </Head>
    </div>
  );
}
